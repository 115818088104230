import { CareerNames } from "jobjump-types/Careers";
import React from "react";
import CareerImages from "../assets/CareerImages";
import CareerImagesSmall from "../assets/CareerImageSmall";
import Careers from "../data/Careers";
import FilterableGrid from "./FilterableGrid";
import "./careerGrid.css"

type Props = {
    numPerRow:number // if unset, don't show grid
    onCareerSelect:((career:CareerNames)=>void)
}

export const CareerChip : React.FC<{careerName:string, hideImage?:boolean, label?:string}&React.HTMLProps<HTMLDivElement>> = ({careerName, hideImage, label, className, children, ...props}) =>
    <div className={`careerOption ${className}`} {...props}>
        {!hideImage && <div className="multiValueImage ml-8 mr-8">
            <img src={careerName && CareerImagesSmall[careerName.toUpperCase()]} />
        </div>}
        <div className={`${hideImage ? "ml-8" : ""}`}>{label || careerName}</div>
        {children} 
    </div>

const CareerGrid : React.FC<Props> = ({numPerRow, onCareerSelect}) => {
    const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
    const [usersCareers, setUsersCareers] = React.useState<CareerNames[]>([]);
    React.useEffect(() => {
        Careers.getCareerList().then(setCareerList);
    }, []);
    React.useEffect(() => {
        Careers.getUsersCareers().then(setUsersCareers);
    }, []);
    return <FilterableGrid
        numPerRow={numPerRow}
        data={careerList.map(d => ({
            imgUrl: CareerImages[d.name.toUpperCase()] || "",
            text:d.name,
            onClick: () => {
                onCareerSelect(d)
            }
        }))}>
        {usersCareers.length > 0 && <div className="ml-16 pt-16 pb-16" style={{display:"flex", flexWrap:"wrap", gap:"8px"}}>
            <div>Based On Your Previous Choices:</div>
            {usersCareers.map((u, i) => <CareerChip key={i} className={"ml-8 career-chip"} careerName={u.name} onClick={()=>onCareerSelect(u)} />)}
        </div>}
    </FilterableGrid>

}

export default CareerGrid;