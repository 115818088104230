import {CwizResponse} from "jobjump-types/Cwiz";

export const initalResults : CwizResponse = {
    category:undefined,
    personality:undefined,
    subject:undefined,
    tertiary:undefined,
    skills:[],
    values:[],
}

export type CwizStates = "home" | "categories" | "pictures" | "skills" | "values" | "tertiary" | "subjects" | "personality" | "results" | "prefs";

export function isCwizState (key:string) : key is CwizStates {
    return ["home", "categories", "pictures", "skills", "values", "tertiary", "subjects", "personality", "results", "prefs"].includes(key);
}

export type CwizProps = {
    response: CwizResponse,
    updateResponse: (r:CwizResponse, pageToGoTo?:CwizStates)=>void,
    goToPage: (currentState:CwizStates)=>void
}