import React from "react";

import { ResumeFormChildrenProps, ShowCareerInfoDetails } from "./ResumeCommonTypes";
import Subjects from "../../data/Subjects";
import { Form, Table, Button, Overlay, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { CareerText } from "jobjump-types/Careers";

const EducationHistory: React.FC<ResumeFormChildrenProps> =
    ({ generalResume, updateGeneralResume, careerInfo }) => {
        const target = React.useRef();
        const [toggle, setToggle] = React.useState(false);
        const handleATARChange = (e: React.FormEvent<HTMLInputElement>) => {
            const want_atar = e.currentTarget.value as "yes" | "no" | "";
            updateGeneralResume({
                ...generalResume,
                generic_subjects: {
                    ...generalResume.generic_subjects,
                    want_atar
                }
            })
        }
        const [subjects, setSubjects] = React.useState<string[]>([]);
        React.useEffect(() => {
            Subjects.getSubjects().then(l => setSubjects(l.map(s => s.name)));
        }, []);
        return <>
            <h5>Do you want an ATAR? <span ref={target as unknown as any} onClick={() => setToggle(!toggle)}><FontAwesomeIcon icon={faQuestionCircle} /></span></h5>
            <Overlay placement="right" target={target.current} show={toggle}>
                <Tooltip id={"tooltop-atar-des"}>
                    An ATAR is a your rank between 0-99.95 in your Year 12 results used to determine entry into most university courses. If you are unsure if you want an ATAR, leave this question blank.
                </Tooltip>
            </Overlay>
            <div>
                <Form.Check inline type="radio" id="want-atar" value="yes" label="Yes"
                    checked={generalResume.generic_subjects.want_atar === "yes"} onChange={handleATARChange} />
                <Form.Check inline type="radio" id="want-atar" value="no" label="No"
                    checked={generalResume.generic_subjects.want_atar === "no"} onChange={handleATARChange} />
            </div>
            <h5>Enter the subjects you study in the table below.</h5>
            <p>You can also enter any marks you think are impressive</p>
            <p>Do you know the subjects offered at your school? Refer to
                their list as you make your entries below. Remember to put your subjects choices
                in priority order.</p>
            {careerInfo && careerInfo.careerText.find(s=>s.kind==="subject") !== undefined && <ShowCareerInfoDetails
                toggleSection={({onToggle}) =>
                    <p><Button variant="outline-secondary" onClick={onToggle}>Click here</Button>&nbsp; to see the recommended subjects for this field.</p>
                }
            >
                <p>Your subject selection should be discussed with your Careers Adviser.</p>
                <strong><span dangerouslySetInnerHTML={{__html:(careerInfo.careerText.find(s=>s.kind==="subject") as CareerText).content}}></span></strong>
                <p>You may be able to study and develop skills in your career through a EVET or VET course as part of your HSC. 
                    Check with your Careers Adviser or Principal if that is possible and your options if you wish an ATAR.</p>
            </ShowCareerInfoDetails>}
            <Table>
                <thead>
                    <tr>
                        <td>Subject</td>
                        <td>Year 9/10 or Snr Units</td>
                        <td>Mark <small>(optional)</small></td>
                        <td>Comment <small>(optional)</small></td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    {generalResume.generic_subjects.subjects.map((s, i) =>
                        <tr key={i}>
                            <td><Typeahead
                                selected={s.subject_name !== "" ? [s.subject_name] : []}
                                id={`subjects-${i}`}
                                options={subjects}
                                onChange={s => {
                                    const subjects = [...generalResume.generic_subjects.subjects];
                                    subjects[i].subject_name = s[0] || "";
                                    updateGeneralResume({
                                        ...generalResume,
                                        generic_subjects: { ...generalResume.generic_subjects, subjects }
                                    });
                                }}
                            />
                            </td>
                            <td>
                                <Form.Control as="select"
                                    value={s.units}
                                    onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                        const subjects = [...generalResume.generic_subjects.subjects];
                                        subjects[i].units = e.currentTarget.value;
                                        updateGeneralResume({
                                            ...generalResume,
                                            generic_subjects: { ...generalResume.generic_subjects, subjects }
                                        });
                                    }}
                                >
                                    <option value="9">Year 9</option>
                                    <option value="0">Year 10</option>
                                    <option value="1">1 Unit (Year 11/12)</option>
                                    <option value="2">2 Unit (Year 11/12)</option>
                                </Form.Control>
                            </td>
                            <td><Form.Control
                                type="text"
                                value={s.mark}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    const subjects = [...generalResume.generic_subjects.subjects];
                                    subjects[i].mark = e.currentTarget.value;
                                    updateGeneralResume({
                                        ...generalResume,
                                        generic_subjects: { ...generalResume.generic_subjects, subjects }
                                    });
                                }} />
                            </td>
                            <td><Form.Control
                                type="textarea"
                                value={s.subject_comment}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    const subjects = [...generalResume.generic_subjects.subjects];
                                    subjects[i].subject_comment = e.currentTarget.value;
                                    updateGeneralResume({
                                        ...generalResume,
                                        generic_subjects: { ...generalResume.generic_subjects, subjects }
                                    });
                                }} />
                            </td>
                            <td>
                                <FontAwesomeIcon icon={faTimesCircle} onClick={() => {
                                    const subjects = [...generalResume.generic_subjects.subjects];
                                    subjects.splice(i, 1);
                                    updateGeneralResume({
                                        ...generalResume,
                                        generic_subjects: { ...generalResume.generic_subjects, subjects }
                                    });
                                }} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Button variant="success" type="button" onClick={() => {
                const subjects = [...generalResume.generic_subjects.subjects];
                subjects.push({ subject_category: "", units: "2", subject_comment: "", subject_name: "", mark: "" });
                updateGeneralResume({
                    ...generalResume,
                    generic_subjects: {
                        ...generalResume.generic_subjects,
                        subjects
                    }
                });
            }}>Add Subject</Button>
        </>;
    }

export default EducationHistory;