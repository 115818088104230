import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import { SchoolDetails, UserDetails } from "jobjump-types/User";
import User from "../data/User";
import { Col, Row } from "../components/grid";
import Button from "../components/Button";
import { Label, Select, TextField } from "../components/Form";
import Alert from "../components/Alert";

interface ForgotPasswordProps extends RouteComponentProps {
    setUserDetails : (d:UserDetails)=>void
}

const ForgotPassword : React.FC<ForgotPasswordProps> =
({location:{search}, history, setUserDetails}) => {
    const {token, subscriber} = qs.parse(search);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordTooShort, setPasswordTooShort] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [loginFailure, setLoginFailure] = useState(false);
    const [email, setEmail] = useState("");
    const [school, setSchool] = useState<SchoolDetails>();
    const [schoolList, setSchoolList] = useState<SchoolDetails[]>([]);
    const [formIncomplete, setFormIncomplete] = useState(false);
    useEffect(() => {
        User.getSchoolDetails().then(setSchoolList);
    }, []);
    if (typeof token !== "string" || typeof subscriber !== "string") {
        return <Row><Col>
            <p>Invalid URL. If you copied this link from an email, please ensure you copy the full link.</p>
        </Col></Row>
    }
    const submitClick = () => {
        if (email === "" || newPassword === "" || confirmPassword === "" || school === undefined) {
            setFormIncomplete(true);
            return;
        }
        setFormIncomplete(false);
        if (newPassword.length < 6) {
            setPasswordTooShort(true);
            return;
        }
        setPasswordTooShort(false);
        if (newPassword !== confirmPassword) {
            setPasswordMismatch(true);
            return;
        }
        setPasswordMismatch(false);
        User.resetPassword(subscriber, email, school.id, token, newPassword).then(() => {
            User.login(email, newPassword).then((d)=> {
                setUserDetails(d);
                history.push("/");
            }, ()=>{setLoginFailure(true)})
        }, () => setLoginFailure(true))
    }
   return <>
        <Row>
            <Col>
                <h2>Forgot Password</h2>
                <p>Complete the form below to reset your password. You will then be able to login to JobJump with your new password.</p>
                {formIncomplete && <Alert textOnly variant="negative" text="Please complete all the fields below." />}
                {loginFailure && <Alert textOnly variant="negative" text="Password reset failed. Please ensure that your email address and school is correct." />}
            </Col>
        </Row>
        <Row>
            <Col>
                <form>
                    <Row><Col>
                        <Label>
                            Email
                        </Label>
                        <TextField emailField value={email} onChange={setEmail} />
                    </Col></Row>
                    <Row><Col>
                        <Label>
                            School
                        </Label>
                        <Select
                            placeholder="Type to find your school"
                            options={schoolList.map(s =>({label:s.name, value:s.id}))}
                            onChange={(v) => v && setSchool({id:v.value, name:v.label})} />
                    </Col></Row>
                    <Row><Col>
                        {passwordTooShort && <Alert textOnly variant="negative" text="The password must be at least 6 characters." />}
                        <Label>New Password</Label>
                        <TextField passwordField value={newPassword} onChange={setNewPassword} />
                    </Col></Row>
                    <Row><Col>
                        {passwordMismatch && <Alert textOnly variant="negative" text="The passwords do not match." />}
                        <Label>Confirm Password</Label>
                        <TextField passwordField value={confirmPassword} onChange={setConfirmPassword} />
                    </Col></Row>
                </form> 
            </Col>
        </Row>
        <Row>
            <Col className="text-center">
                <Button variant="active" onClick={submitClick}>
                    Submit
                </Button>
            </Col>
        </Row>
    </>
}

export default ForgotPassword;