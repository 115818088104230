import React from "react";
import "./badge.css";

type Props = {
    variant: "active"|"negative"
}

const Badge : React.FC<Props> = ({variant, children}) => {
    return <div className={`jobjump-badge jobjump-badge-${variant}`}>{children}</div>
}

export default Badge;