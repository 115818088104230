import { onLoad } from "@sentry/browser";
import { Document, Paragraph, TextRun, HeadingLevel, AlignmentType, Table, TableRow, TableCell, Packer, WidthType, BorderStyle } from "docx";
import { Resume } from "jobjump-types/Resume";
import { DefaultSectionNames } from "./sections/common";

const generateResume = (resume:Resume, handleBlob:(blob:Blob)=>void) => {
    const tableCellStyle = {
        top: {style : BorderStyle.NONE, color: "#000000", size: 0 },
        left: {style : BorderStyle.NONE, color: "#000000", size: 0  },
        right: {style : BorderStyle.NONE, color: "#000000", size: 0  },
        bottom: {style : BorderStyle.SINGLE, color: "#000000", size: 2  },
    }

    const makeSectionHeading = (title:string) => 
        new Paragraph({
            heading: HeadingLevel.HEADING_2,
            border : {
                bottom: { style: BorderStyle.SINGLE, color: resume.styleConfig.primaryColor, size: 1}
            },
            children: [new TextRun({text: title, color: resume.styleConfig.primaryColor})]
        })

    const heading = [new Paragraph({
        heading: HeadingLevel.TITLE,
        alignment : AlignmentType.CENTER,
        children: [ new TextRun({text:resume.personal_details.name || "", color: resume.styleConfig.primaryColor})]
    })]


    const contactDetails = ([
            resume.personal_details.address && new Paragraph({
                text: resume.personal_details.address 
            }),
            resume.personal_details.phone && new Paragraph({
                text : `P: ${resume.personal_details.phone}`
            }),
            resume.personal_details.email && new Paragraph({
                text : `E: ${resume.personal_details.email}`
            }),
        ].filter(p => p !== "" && p !== undefined) as Paragraph[]).concat((resume.personal_details.other || []).map(o =>
            new Paragraph({
                text: `${o.key}: ${o.value}`
            })
        ))

    const careerGoal = (title:string) => [
        makeSectionHeading(title), 
        new Paragraph({
            text: resume.career_goal
        })
    ]

    const education = (title:string) => {
        const hasMarks = resume.education_history.subjects.find(s => s.mark !== "")
        const subjectList = hasMarks ? [new Table({
            width : {
                type : WidthType.PERCENTAGE,
                size: 100
            },
            rows : [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph("Subject")],
                            borders : tableCellStyle
                        }),
                        new TableCell({
                            children: [new Paragraph("Mark")],
                            borders : tableCellStyle
                            // 1805
                        })
                    ]
                })
            ].concat(resume.education_history.subjects.map(s=>
                new TableRow({
                    children : [
                        new TableCell({children: [new Paragraph(s.name)], borders: tableCellStyle }),
                        new TableCell({children: [new Paragraph(s.mark || "")], borders: tableCellStyle }),
                    ]
                })
            ))
        })]
    :
        resume.education_history.subjects.map(s=> 
            new Paragraph({
                text: s.name,
                bullet : {
                    level: 0
                }
            })
        );
        return [
            makeSectionHeading(title), 
                new Paragraph({
                    text: `School: ${resume.education_history.schoolName}`
                }),
                ...subjectList
        ]
    }

    const eWorkExperience = (title:string) => [
        makeSectionHeading(title),
        new Paragraph({
            text: "In my spare time, I've completed a project relating to this career. I have:"
        }),
        ...(resume.eWorkExperience.map(m => new Paragraph({text:m.text, bullet:{level:0}})))
    ]


    const employment = (title:string) => [
            makeSectionHeading(title), 
            new Table({
                width : {
                    type : WidthType.PERCENTAGE,
                    size: 100
                },
                rows : [
                    new TableRow({
                        children : [
                            new TableCell({ children: [new Paragraph("Dates")], borders: tableCellStyle }),
                            new TableCell({ children: [new Paragraph("Company")], borders: tableCellStyle  }), 
                            new TableCell({ children: [new Paragraph("Description")], borders: tableCellStyle  }), 
                        ]
                    })
                ].concat(resume.employment_details.map(h=>
                    new TableRow({
                        children : [
                            new TableCell({ children: [new Paragraph(`${h.dateStart}-${h.dateEnd}`)], borders: tableCellStyle  }),
                            new TableCell({ children: [new Paragraph(h.company)], borders: tableCellStyle  }),
                            new TableCell({ children: [new Paragraph(h.description)], borders: tableCellStyle  }),
 
                        ]
                    })
                ))
            })
        ];

    const qualities = (title:string) => [
            makeSectionHeading(title), 
        ].concat(resume.skills.reduce((a, q) =>
            q.hidden ? a : a.concat(new Paragraph({
                children: [
                    new TextRun({text:q.title, bold:true}),
                    new TextRun(q.details ? `\n${q.details}` : ""),
                    new TextRun("\n")
                ],
                bullet: { level: 0 }
            }))
        , [] as Paragraph[]));

    const awards = (title:string) => (resume.awards.length > 0) ?
        [ makeSectionHeading(title),]
            .concat(resume.awards.map(a =>
                new Paragraph({
                    text: `${a.title}${a.details ? " - "+a.details : ""}`,
                    bullet : { level : 0 }
                })    
            ))
        :
            [];

    const achievements = (title:string) => (resume.achievements.length > 0) ?
        [ makeSectionHeading(title),]
            .concat(resume.achievements.map(a =>
                new Paragraph({
                    text: `${a.title}${a.details ? " - "+a.details : ""}`,
                    bullet : { level : 0 }
                })    
            ))
        :
            [];

    const qualifications = (title:string) => (resume.qualifications.length > 0) ?
        [ makeSectionHeading(title),]
            .concat(resume.qualifications.map(a =>
                new Paragraph({
                    text: `${a.title}${a.details ? " - "+a.details : ""}`,
                    bullet : { level : 0 }
                })    
            ))
        :
            [];

    const interests = (title:string) => (resume.hobbies.length > 0) ?
        [ makeSectionHeading(title),]
            .concat(resume.hobbies.map(a =>
                new Paragraph({
                    text: `${a.title}${a.details ? " - "+a.details : ""}`,
                    bullet : { level : 0 }
                })    
            ))
        :
            [];

    const referees = (title:string) => [
        makeSectionHeading(title)
        ].concat(resume.referees.map(r => [
            new Paragraph({
                text:`${r.name || ""} - ${r.company || ""} ${r.position ? `(${r.position})` : ""}`
            }),
            new Paragraph({
                text:`${r.email ? `E: ${r.email} ` : ""}${r.phone ? `P: ${r.phone}` : ""}`
            }),
        ]).flat());

    const content : (Paragraph | Table)[] = resume.order.reduce((a, o) => {
        const title = o.alias || DefaultSectionNames[o.name]
        if (o.hidden) {
            return a;
        }
        if (o.name === "career_goal") {
            return a.concat([ new Paragraph(""), ...careerGoal(title) ])
        }
        if (o.name === "awards") {
            return a.concat([ new Paragraph(""), ...awards(title) ])
        }
        if (o.name === "achievements") {
            return a.concat([ new Paragraph(""), ...achievements(title) ])
        }
        if (o.name === "qualifications") {
            return a.concat([ new Paragraph(""), ...qualifications(title) ])
        }
        if (o.name === "education_history") {
            return a.concat([ new Paragraph(""), ...education(title) ])
        }
        if (o.name === "employment_details") {
            return a.concat([ new Paragraph(""), ...employment(title) ])
        }
        if (o.name === "hobbies") {
            return a.concat([ new Paragraph(""), ...interests(title) ])
        }
        if (o.name === "referees") {
            return a.concat([ new Paragraph(""), ...referees(title) ])
        }
        if (o.name === "skills") {
            return a.concat([ new Paragraph(""), ...qualities(title) ])
        }
        if (o.name === "eWorkExperience" && resume.eWorkExperience.length > 0) {
            return a.concat([ new Paragraph(""), ...eWorkExperience(title)])
        }
        return a;
    }, [] as (Paragraph|Table)[]);

    const doc = new Document({
        sections: [
            {
                children: [
                    ...heading,
                    ...contactDetails,
                    ...content
                ]
            }
        ]
    });
    Packer.toBlob(doc).then(handleBlob);

}

export {generateResume};