import React from "react";
import { StudentCareerRecord } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import PageSectionRow from "../../components/PageSectionRow";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import { Col } from "../../components/grid";

const CaCareerTable : React.FC<RouteComponentProps> =
    (props) => {
        const [data, setData] = React.useState<StudentCareerRecord[]>([]);
        React.useEffect(() => {
            CaStudentDetails.studentCareers().then(setData);
        }, []);
        return <CaAdminLayout active="careers" title="Students Careers" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Careers</h3>
                    <span>Below are the careers your students have expressed interest in.</span>
                    <span>You can search for specific careers in the search box. You can select rows by clicking on them, or using the "Select All" button and then send a message to the selected students.</span>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        additionalColumns={[
                                {
                                    Header:"Career", 
                                    accessor:"career"
                                }
                        ]} />
                </Col>
            </PageSectionRow>
        </CaAdminLayout>;
    }

export default CaCareerTable;