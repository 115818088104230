import React from "react";
import CaAdminLayout from "./CaAdminLayout";
import VideoSelection from "../../components/VideoSelection";
import { RouteComponentProps } from "react-router";

const videos = [
    {
        title : "Setup Your School Careers Website",
        subtitle: "",
        key:"schoolSite",
        service: 'vimeo',
        videoId: "518457966",
    },
    {
        title : "Find Students Unsure of Their Career",
        subtitle: "",
        key:"unsure",
        service: 'vimeo',
        videoId: "544140173"
    },
    {
        title : "Find Students Careers of Interest",
        subtitle: "",
        key:"careersOfInterest",
        service: 'vimeo',
        videoId: "544140188"
    },
    {
        title : "Add a Message to my Careers Noticeboard",
        subtitle: "",
        key:"noticeboard",
        service: 'vimeo',
        videoId: "544140202"
    },
    {
        title : "Message All Students",
        subtitle: "",
        key:"messageAll",
        service: 'vimeo',
        videoId: "544140243"
    },
    {
        title : "Message A Student",
        subtitle: "",
        key:"messageStudent",
        service: 'vimeo',
        videoId: "544140262"
    },
    {
        title : "Message Students Interested in a Particular Career",
        subtitle: "",
        key:"messageCareer",
        service: 'vimeo',
        videoId: "544140285"
    },
    {
        title : "Record Students Interview",
        subtitle: "",
        key:"recordInterview",
        service: 'vimeo',
        videoId: "544140302"
    },    {
        title : "View and Edit Student Resume",
        subtitle: "",
        key:"resume",
        service: 'vimeo',
        videoId: "544140318"
    },    {
        title : "See Student Course Preferences",
        subtitle: "",
        key:"coursePrefs",
        service: 'vimeo',
        videoId: "544140333"
    },
    {
        title : "Message A Year Group",
        subtitle: "",
        key:"messageYear",
        service: 'vimeo',
        videoId: "544140359"
    },    {
        title : "Send Individualised Message to Universities",
        subtitle: "",
        key:"tertiarycontact",
        service: 'vimeo',
        videoId: "544140383"
    },    {
        title : "Upload a Bulletin or Document",
        subtitle: "",
        key:"uploadDoc",
        service: 'vimeo',
        videoId: "544140420"
    },    {
        title : "JobJump Registration and Login",
        subtitle: "",
        key:"rego",
        service: 'vimeo',
        videoId: "544140486"
    }, 
    {
        title : "My ATAR",
        subtitle: "",
        key:"atar",
        videoId: "579038354",
    },
]

const HelpVideos : React.FC<RouteComponentProps> =
(props) => 
    <CaAdminLayout 
        active=""
        title={"Careers Adviser Help Videos"} 
        subtitle={["Need to know how to do something with JobJump? These videos will help you make the most of JobJump."]}
        breadcrumbTitle="Help Videos" {...props}>
        <VideoSelection videos={videos} {...props} />
    </CaAdminLayout>

export default HelpVideos;