import React from "react";
import {Col, Row} from "../../components/grid";
import { Student, StudentAtarPreferences } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { Institute } from "jobjump-types/ATAR";
import AtarAPI  from "../../data/ATAR";
import PageSectionRow from "../../components/PageSectionRow";
import { RouteComponentProps } from "react-router";
import { Label, Select, TextField } from "../../components/Form";
import { CSVLink } from "react-csv";
import Button from "../../components/Button";

type InstituteMap = {
    NSW : Institute[],
    VIC : Institute[],
    QLD : Institute[],
    TAFE : Institute[]
}

const CaAtarPrefs : React.FC<RouteComponentProps> =
    (props) => {
        const [data, setData] = React.useState<StudentAtarPreferences[]>([]);
        const [searchdData, setSearchdData] = React.useState<StudentAtarPreferences[]>([]);
        const [insts,setInsts] = React.useState<InstituteMap>({NSW:[],VIC:[],QLD:[],TAFE:[]});
        const [searchState, setSearchState] = React.useState<keyof InstituteMap | "All">("All");
        const [searchInst, setSearchInst] = React.useState<string>("All");
        const [searchTerm, setSearchTerm] = React.useState("");
        const filterData = React.useCallback((data:StudentAtarPreferences[]) =>
            (data || []).filter((d) => 
                d.preference.uniCourse ?
                    (searchState === "All" || d.preference.uniCourse.state === searchState) && 
                    (searchInst === "All" || d.preference.uniCourse.institution === searchInst) &&
                    (searchTerm === "" || d.preference.uniCourse.name.match(`.*${searchTerm}.*`))
                : d.preference.tafeCourse &&
                    (searchState === "All" || searchState === "TAFE") &&
                    (searchInst === "All" || searchInst === d.preference.tafeCourse.college) &&
                    (searchTerm === "" || d.preference.tafeCourse.course_name.match(`.*${searchTerm}.*`))
            )
        , [searchState, searchInst, searchTerm])
        React.useEffect(() => {
            CaStudentDetails.studentAtarPrefs().then(d => {
                setData(d || []);
                const tafeColleges = (d || []).reduce((a, c) => {
                    if (!c.preference.tafeCourse) { return a }
                    return {...a, [c.preference.tafeCourse.college]:true}
                }, {} as {[k:string]:boolean})
                setInsts(i => ({...i, "TAFE":Object.keys(tafeColleges).map(c =>({id:c, name:c, state:"NSW"}))}));
                setSearchdData(filterData(d));
            });
        }, [filterData]);
        React.useEffect(() => {
            AtarAPI.getInstitutes().then(insts => {
                setInsts(i =>
                    insts.reduce((c:InstituteMap, i:Institute) => {
                        if (["NSW","VIC","QLD"].includes(i.state)) {
                            const inst = i.state as "NSW"|"VIC"|"QLD";
                            c[inst].push(i);
                        }
                        return c;
                    }, ({NSW:[],VIC:[],QLD:[],TAFE:i["TAFE"]}) as InstituteMap))
            });
        }, []);
        React.useEffect(() => {
            setSearchdData(filterData(data));
        }, [searchState, searchInst, searchTerm, data, filterData])
        return <CaAdminLayout 
            title="Student Course Preferences"
            active="prefs"
            subtitle={[
                "Find all your students preferences for institutes or degrees.",
            ]} {...props}>
                <PageSectionRow>
                    <Col className="text-right">
                        <CSVLink 
                            className="csvlink"
                            data={data.map(d => ({
                                first:d.student.first_name, 
                                last:d.student.last_name, 
                                year:d.student.year, 
                                email:d.student.email, 
                                course:d.preference.uniCourse?.name || d.preference.tafeCourse?.course_name || "",
                                institute:d.preference.uniCourse?.institution || d.preference.tafeCourse?.institute || "",
                                campus:d.preference.uniCourse?.campus || d.preference.tafeCourse?.college || "",
                                atar:d.preference.uniCourse?.atar || ""
                            }))} 
                            headers={[
                                {key:"first", label:"First Name"},
                                {key:"last", label:"Last Name"},
                                {key:"year", label:"Year"},
                                {key:"email", label:"Email"},
                                {key:"course", label:"Course Name"},
                                {key:"institute", label:"Institute"},
                                {key:"campus", label:"Campus"},
                                {key:"atar", label:"ATAR"},
                            ]}>
                            <Button variant="active" className="ml-16">Download All Preferences Spreadsheet</Button>
                        </CSVLink>
                    </Col>
                </PageSectionRow>
                <PageSectionRow>
                    <Col className="text-center">
                        <h3>Student Course Preferences</h3>
                        <span>Use the search boxes below to find your students interested in going to a particular institute or studying a particular degree.</span>
                        <span>Note: Your students must use the heart icon in ATAR Course Search or TAFE Course Search before they are listed here.</span>
                    </Col>
                </PageSectionRow>
                <PageSectionRow>
                    <Col sm={6}>
                        <h4>Search By Institute</h4>
                        <Row>
                            <Col>
                                <Label>State</Label>
                                <Select
                                    placeholder="Select state..."
                                    options={[{value:"All", label:"All"}].concat(Object.keys(insts).map(s => ({value:s, label:s})))}
                                    onChange={v=>{v&&setSearchInst("All"); v&&setSearchState(v.value as keyof InstituteMap)}} />
                            </Col>
                        </Row>
                        {searchState !== "All" &&
                            <Row>
                                <Col>
                                    <Label>Institute</Label>
                                    <Select
                                        placeholder="Select institute..."
                                        options={[{value:"All", label:"All"}].concat(
                                            insts[searchState].map(i=>({value:i.name, label:i.name}))
                                        )}
                                        onChange={v=>v && setSearchInst(v.value)} />
                                </Col>
                            </Row>
                        }
                    </Col>
                    <Col sm={6}>
                        <h4>Search By Degree Name</h4>
                        <Label>Degree Name</Label>
                        <TextField placeholder="Degree Name" value={searchTerm} onChange={setSearchTerm} />
                    </Col>
                </PageSectionRow>
                <CaStudentTable
                    data={Object.values(searchdData.reduce((a, s) => {
                        if (s.student.id in s) {
                            return a;
                        }
                        return { ...a, [s.student.id] : {student:s.student} }; 
                    }, {} as {[k:string]:{student:Student}}))}
                    additionalColumns={[]}
                />
        </CaAdminLayout>
    }

export default CaAtarPrefs;