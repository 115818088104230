import { SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import { Radio, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import Button from "../../../components/Button";
import Dropzone from "react-dropzone";

type SchoolDetailsProps = {
    schoolConfig : SchoolHomepage,
    updateConfig : React.Dispatch<React.SetStateAction<SchoolHomepage>>,
    prefix: string,
    showAddress?:boolean,
    save: (newConfig:SchoolHomepage)=>Promise<Response>,
    logoUrl:string,
    logoFile?:File,
    setLogoFile:React.Dispatch<React.SetStateAction<File|undefined>>,
    uploadLogo:()=>void

}

const SchoolDetails : React.FC<SchoolDetailsProps> = 
({schoolConfig, updateConfig, save, prefix, showAddress, logoUrl, logoFile, setLogoFile, uploadLogo}) => {
    React.useEffect(() => {
        uploadLogo();
    }, [logoFile]);
    return <Row><Col>
        <Row><Col>
            <h2>School Name</h2>
            <p>This is the school name as it will appear throughout your school careers site.</p>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.schoolName} onChange={(schoolName)=>updateConfig(c=>({...c, schoolName}))} />
        </Col></Row>
        {showAddress && <Row><Col>
            <h2>School Careers Site Address</h2>
            <p>This is the address people will use to access your careers website.</p>
            <p><strong>This cannot be changed once set.</strong></p>
            <Row>
                <Col sm={1} className="text-right" style={{"paddingTop":"10px"}}>https://</Col>
                <Col sm={3}><TextField value={schoolConfig.shortName} onChange={(shortName)=>updateConfig(c=>({...c,shortName}))} /></Col>
                <Col className="text-left" style={{"paddingTop":"10px"}}>.jobjump.com.au</Col>
            </Row>
        </Col> </Row>}

        <Row><Col>
            <h2>School Logo</h2>
            <p>Upload your school logo here to have it display on your school's careers site.</p>
            {logoUrl !== "" && <Row>
                <Col className="text-center">
                    Current Logo:<br />
                    <img src={logoUrl} width="100" />
                </Col>
            </Row>}
            <Dropzone onDrop={(fs) =>setLogoFile(fs[0])}>
                {({getRootProps, getInputProps}) => (
                    <>{!logoFile && <div {...getRootProps()} className="upload-zone">
                        <input {...getInputProps()} />
                        <p>Drag your logo here, or click here to select a file.</p>
                    </div>}
                    {logoFile && <div className="upload-zone">
                        <Button variant="neutral" onClick={()=>setLogoFile(undefined)}>Clear</Button>
                    </div>}
                    </>
                )}
            </Dropzone>
        </Col></Row>
        <Row><Col>
            <h2>Careers Noticeboard</h2>
            <p>The Careers Noticeboard is a section that appears on your school's homepage. It allows you to post news and information for students to see. You can target news by year group, or send to all students.</p>
            <Radio name={`${prefix}-noticeboard`} className="block" label="I want a Careers Noticeboard." onBlur={()=>save(schoolConfig)} checked={schoolConfig.hasNoticeBoard} onChange={()=>updateConfig(c=>({...c, hasNoticeBoard:true}))} />
            <Radio name={`${prefix}-noticeboard`} className="block" label="I do not want a Careers Noticeboard." onBlur={()=>save(schoolConfig)}  checked={!schoolConfig.hasNoticeBoard} onChange={()=>updateConfig(c=>({...c, hasNoticeBoard:false}))} />
        </Col></Row>
        <Row><Col>
            <h2>Careers News Bulletins</h2>
            <p>This enables the "My Bulletins" section of JobJump for your school. This allows you to have 
                Careers Bulletins uploaded to your school's site for your students and parents. You can have
                JobJump automatically upload the Master and KLA Bulletins. Additionally, you can upload your own in the
                "School Documents/Bulletins" section. Please select which option you want below:</p>
            <Radio 
                name={`${prefix}-bulletin`} 
                className="block" 
                label="JobJump Bulletin - I want ONLY the JobJump Master and KLA Bulletin to be uploaded to my school site." 
                onBlur={()=>save(schoolConfig)} 
                checked={schoolConfig.hasBulletin && !schoolConfig.hasOwnBulletins} 
                onChange={()=>updateConfig(c=>({...c, hasBulletin:true, hasOwnBulletins:false}))} />
            <Radio 
                name={`${prefix}-bulletin`} 
                className="block" 
                label="My Own Bulletin - I ONLY want to be able to upload my own bulletin." 
                onBlur={()=>save(schoolConfig)} 
                checked={!schoolConfig.hasBulletin && schoolConfig.hasOwnBulletins} 
                onChange={()=>updateConfig(c=>({...c, hasBulletin:false, hasOwnBulletins:true}))} />
            <Radio 
                name={`${prefix}-bulletin`} 
                className="block" 
                label="Both Bulletins - I want BOTH the JobJump Bulletins and the ability to upload my own." 
                onBlur={()=>save(schoolConfig)} 
                checked={schoolConfig.hasBulletin && schoolConfig.hasOwnBulletins} 
                onChange={()=>updateConfig(c=>({...c, hasBulletin:true, hasOwnBulletins:true}))} />
            <Radio 
                name={`${prefix}-bulletin`} 
                className="block" 
                label="I do not want any careers bulletins on my school's site." 
                onBlur={()=>save(schoolConfig)} 
                checked={!schoolConfig.hasBulletin && !schoolConfig.hasOwnBulletins} 
                onChange={()=>updateConfig(c=>({...c, hasBulletin:false, hasOwnBulletins:false}))} />
        </Col></Row>
        <Row><Col>
            <h2>My School Files</h2>
            <p>You can enable the "My Files" section, which allows you to upload important documents for you students and 
                parents to access. Files are uploaded using the "Schools Document/Bulletins" section in your dashboard.</p>
            <Radio 
                name={`${prefix}-files`} 
                className="block" 
                label="I want to upload documents for students and parents." 
                onBlur={()=>save(schoolConfig)} 
                checked={schoolConfig.hasFiles} 
                onChange={()=>updateConfig(c=>({...c, hasFiles:true}))} />
            <Radio 
                name={`${prefix}-files`} 
                className="block" 
                label="I do not want the My Files section on my careers site." 
                onBlur={()=>save(schoolConfig)}  
                checked={!schoolConfig.hasFiles} 
                onChange={()=>updateConfig(c=>({...c, hasFiles:false}))} />
        </Col></Row>
 
    </Col></Row>
}

export default SchoolDetails;