const SubjectImages : {[k:string]:string} = {
    "Agriculture": require("./quiz-images/subject_buttons/agriculture.jpg"),
    "Automotive": require("./quiz-images/subject_buttons/automotive.jpg"),
    "Biology": require("./quiz-images/subject_buttons/biology.jpg"),
    "Business Studies": require("./quiz-images/subject_buttons/business.jpg"),
    "Chemistry": require("./quiz-images/subject_buttons/chemistry.jpg"),
    "Computing & IT": require("./quiz-images/subject_buttons/computing.jpg"),
    "Earth and Environmental Science": require("./quiz-images/subject_buttons/earth-env.jpg"),
    "Economics": require("./quiz-images/subject_buttons/economics.jpg"),
    "Electro-technology": require("./quiz-images/subject_buttons/electro.jpg"),
    "Engineering Studies": require("./quiz-images/subject_buttons/engineering.jpg"),
    "English": require("./quiz-images/subject_buttons/english.jpg"),
    "Entertainment": require("./quiz-images/subject_buttons/entertainment.jpg"),
    "Food Technology": require("./quiz-images/subject_buttons/food-tech.jpg"),
    "Geography": require("./quiz-images/subject_buttons/geography.jpg"),
    "Health": require("./quiz-images/subject_buttons/health.jpg"),
    "History - Ancient and Modern": require("./quiz-images/subject_buttons/history.jpg"),
    "Home Economics": require("./quiz-images/subject_buttons/home-ec.jpg"),
    "Hospitality": require("./quiz-images/subject_buttons/hospitality.jpg"),
    "Industrial Technology/Design and Technology": require("./quiz-images/subject_buttons/industrial-tech.jpg"),
    "Languages": require("./quiz-images/subject_buttons/languages.jpg"),
    "Media Studies": require("./quiz-images/subject_buttons/media.jpg"),
    "Music": require("./quiz-images/subject_buttons/music.jpg"),
    "Outdoor Education": require("./quiz-images/subject_buttons/outdoor-ed.jpg"),
    "Performing Arts - Dance and Drama": require("./quiz-images/subject_buttons/performing-arts.jpg"),
    "Personal Development, Health and Physical Education": require("./quiz-images/subject_buttons/pe.jpg"),
    "Physics": require("./quiz-images/subject_buttons/physics.jpg"),
    "Retail": require("./quiz-images/subject_buttons/retail.jpg"),
    "Society and Culture": require("./quiz-images/subject_buttons/sac.jpg"),
    "Textiles and Design": require("./quiz-images/subject_buttons/textiles.jpg"),
    "Visual Arts": require("./quiz-images/subject_buttons/visual-arts.jpg"),

}

export {SubjectImages}