import copy from "copy-to-clipboard";
import { HeroConfiguration, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import { RouteComponentProps } from "react-router";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import { Col, Row } from "../../../components/grid";
import Modal from "../../../components/Modal";
import PageSectionRow from "../../../components/PageSectionRow";
import CaSchoolHomepage from "../../../data/CaSchoolHomepage";
import UserDetailsAPI from "../../../data/UserDetails";
import CaAdminLayout from "../CaAdminLayout";
import PageContents from "./PageContents";
import PreviewSchoolSite from "./Preview";
import SchoolDetails from "./SchoolDetails";
import { IconClipboard } from "@tabler/icons";
import axios from "axios";
import { uploadFileWithSignedPOST } from "../../../data/FileUploadUtil";

const newSchoolConfig : SchoolHomepage = {
    schoolName: "",
    shortName:"",
    active: true,
    links : [
        {toPage:"quiz", title:"Find My Career Quiz"},
        {toPage:"careerExplore", title:"Explore My Career"},
        {toPage:"helpfulLinks", title:"It's All Here"},
        {toPage:"bullseyes", title:"Bullseyes"},
        {toPage:"atar", title:"Find My Uni Course"},
        {toPage:"tafe", title:"Find My TAFE Course"},
    ],
    caName:"",
    caAddress:"",
    hasBulletin:true,
    hasNoticeBoard:false,
    hasFacebook:false,
    hasOwnBulletins:false,
    hasFiles:false
}

const newSchoolHeroConfig : HeroConfiguration = {
    kind: "video",
    loopName: "JobJumpMain"
}

const suggestShortName = (name:string) => name.split(" ")
    .filter(s => !(["school", "high", "college"]).includes(s.toLowerCase()))
    .map(s => s.toLocaleLowerCase().replace(/[^0-9a-z]/gi, ''))
    .filter(s => s !== "")
    .join("-")

const SchoolHomePageConfig : React.FC<RouteComponentProps> = (props) => {
    const [newConfig, setNewConfig] = React.useState<SchoolHomepage>({...newSchoolConfig}); 
    const [currentSection, setCurrentSection] = React.useState<"details"|"content"|"preview">("details");
    const [showNew, setShowNew] = React.useState(false);
    const [showFinished, setShowFinished] = React.useState(false);
    const [shortNameTaken, setShortNameTaken] = React.useState(false);
    const [schoolId, setSchoolId] = React.useState("");
    const [logoUrl, setLogoUrl] = React.useState("");
    const [schoolHero, setSchoolHero] = React.useState({...newSchoolHeroConfig});
    const [logoFile, setLogoFile] = React.useState<File>();
    React.useEffect(() => {
        CaSchoolHomepage.getLogoLink().then(l => l && setLogoUrl(l));
    }, []);
    React.useEffect(() => {
        CaSchoolHomepage.getSchoolPageHero().then(r => r && setSchoolHero(r));
    }, [])
    React.useEffect(() => {
        CaSchoolHomepage.schoolSiteConfig().then(c => {
            if (c) {
                setNewConfig(c.homepage);
                setSchoolId(c.schoolId);
            } else {
                UserDetailsAPI.getDetails().then(u => {
                    setNewConfig(d=>({...d, schoolName:u.school_name,shortName:suggestShortName(u.school_name)}))
                    setSchoolId(u.school_id);
                });
                setShowNew(true);
            }
        });
    }, [setNewConfig]);
    const save = React.useCallback((schoolConfig:SchoolHomepage) => {
        return CaSchoolHomepage.updateSchoolSite(schoolConfig);
    }, [newConfig]);
    const uploadLogo = React.useCallback(() => {
        if (!logoFile) {
            return;
        }
        CaSchoolHomepage.getDocumentUploadLink({mimeType:logoFile.type, fileName:logoFile.name, fileSize:logoFile.size}).catch(e => console.log(e)).then(details => {
            if (!details) { return; }
            const {uploadDetails, key} = details;
            uploadFileWithSignedPOST(uploadDetails, logoFile).then(() => {
                CaSchoolHomepage.confirmLogoUpload({ key }).then(({logoUrl}) => {
                    setLogoUrl(logoUrl);
                });
            }).catch(console.log);
        });
    }, [logoFile, setLogoUrl]);
    const checkInitAndSave = React.useCallback(() => {
        if (newConfig.shortName === "" || newConfig.schoolName === "") {
            console.log(newConfig, "invalid")
            return;
        }
        CaSchoolHomepage.checkShortName(newConfig.shortName).then(b => {
            if (!b) {
                save(newConfig).then(()=>uploadLogo());
                setShowNew(false);
                setShowFinished(true);
            } else {
                setShortNameTaken(true);
            }
        })
    }, [newConfig, uploadLogo]);
    return <CaAdminLayout
        active="admin" 
        title="School Homepage Settings"
        subtitle={["Use the form below to configure your school's careers homepage."]} {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <Button className="ml-8" variant={currentSection === "details" ? "active" : "neutral"} glowing={currentSection==="details"} onClick={()=>setCurrentSection("details")}>
                        School Details
                    </Button>
                    <Button className="ml-8" variant={currentSection === "content" ? "active" : "neutral"} glowing={currentSection==="content"} onClick={()=>setCurrentSection("content")}>
                        Other Options
                    </Button>
                    <Button className="ml-8" variant={currentSection === "preview" ? "active" : "neutral"} glowing={currentSection==="preview"} onClick={()=>setCurrentSection("preview")}>
                       Preview 
                    </Button>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col md={8}>
                    <h5><strong>Your School's JobJump Address: <a href={`https://${newConfig.shortName}.jobjump.com.au`}>{`https://${newConfig.shortName}.jobjump.com.au`}</a> 
                    &nbsp;<IconClipboard 
                        style={{cursor:"pointer"}} 
                        onClick={()=>copy(`https://${newConfig.shortName}.jobjump.com.au`)} />
                    </strong></h5>
                    <p>Provide this link for all your students and parents to use your schools careers website.</p>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    {currentSection === "details" && <SchoolDetails save={save} schoolConfig={newConfig} 
                        logoUrl={logoUrl} logoFile={logoFile} setLogoFile={setLogoFile} uploadLogo={uploadLogo}
                        updateConfig={setNewConfig} prefix="update" />}
                    {currentSection === "content" && <PageContents heroSettings={schoolHero} setHeroSettings={setSchoolHero} save={save} schoolConfig={newConfig} updateConfig={setNewConfig} />}
                    {currentSection === "preview" && <PreviewSchoolSite heroConfig={schoolHero} schoolId={schoolId} schoolConfig={newConfig} logoUrl={logoUrl} {...props} />}
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col className="text-center">
                    <Button className="ml-8" variant={currentSection === "details" ? "active" : "neutral"} glowing={currentSection==="details"} onClick={()=>setCurrentSection("details")}>
                        School Details
                    </Button>
                    <Button className="ml-8" variant={currentSection === "content" ? "active" : "neutral"} glowing={currentSection==="content"} onClick={()=>setCurrentSection("content")}>
                        Other Options
                    </Button>
                    <Button className="ml-8" variant={currentSection === "preview" ? "active" : "neutral"} glowing={currentSection==="preview"} onClick={()=>setCurrentSection("preview")}>
                       Preview 
                    </Button>
                </Col>
            </PageSectionRow>
            <Modal title="School Careers Site Setup" show={showNew} onHide={()=>{}} disallowClose>
                <Row>
                    <Col>
                        <h4>This section of the Careers Advisers Dashboard sets up your public facing school careers website.</h4>
                        <h4>In order to get started, we need you to confirm a few details.</h4>
                    </Col>
                </Row>
                <SchoolDetails 
                    logoUrl={logoUrl} logoFile={logoFile} setLogoFile={setLogoFile} uploadLogo={uploadLogo}
                    save={()=>{return Promise.resolve(new Response())}} schoolConfig={newConfig} updateConfig={setNewConfig} prefix="onboard" showAddress />
                {shortNameTaken && <Row><Col><Alert textOnly variant="negative" text="This address is already taken" /></Col></Row>}
                <Row>
                    <Col className="text-right">
                        <Button variant="active" onClick={checkInitAndSave}>Continue</Button>
                        <Button variant="negative" className="ml-8" onClick={window.history.back}>Back</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal title="Your School Careers Site Is Ready!" show={showFinished} onHide={()=>setShowFinished(false)} disallowClose>
                <Row><Col>
                    <h4>Your School Careers Site is ready for you to use.</h4>
                    <h5><strong>Use this link to share your site with parents and students:</strong></h5> 
                    <h5><a href={`https://${newConfig.shortName}.jobjump.com.au`}>{`https://${newConfig.shortName}.jobjump.com.au`}</a> 
                    &nbsp;<IconClipboard 
                        style={{cursor:"pointer"}} 
                        onClick={()=>copy(`https://${newConfig.shortName}.jobjump.com.au`)} />
                    </h5>               
                    <h5>You can now go to a preview of your new site, or make further advanced customisations. These include changing colours, adding a contact section and changing the links that appears on your homepage.</h5>
                </Col></Row>
                <Row><Col className="text-right">
                    <Button variant="neutral" onClick={()=>{setCurrentSection("content"); setShowFinished(false);}}>Other Options</Button>
                    <Button className="ml-8" variant="neutral" onClick={()=>{setCurrentSection("preview"); setShowFinished(false);}}>Preview My Site</Button>
                </Col></Row>
            </Modal>
    </CaAdminLayout>
}

export default SchoolHomePageConfig;