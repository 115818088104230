import React, { useEffect } from 'react';
import {CareerNames, CareerInfo} from "jobjump-types/Careers";
import Careers from "../data/Careers";
import { Row, Col } from "../components/grid";
import CareerImages from "../assets/CareerImages";
import FilterableGrid from '../components/FilterableGrid';
import { RouteComponentProps } from 'react-router';
import { JobVacanciesContents } from './my_career/JobVacancies';
import Modal from '../components/Modal';
import ContentWrapper from '../components/ContentWrapper';
import { EventTracking } from '../data/EventTracking';

const FindMyJobAds : React.FC<RouteComponentProps<{career?:string}>> =
    (props) => {
        const {match : {params: {career}}} = props;
        const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
        const [showModal, setShowModal] = React.useState(false);
        const [selectedCareer, setSelectedCareer] = React.useState<CareerNames>();
        const [selectedCareerInfo, setSelectedCareerInfo] = React.useState<CareerInfo>();
        useEffect(() => {
            Careers.getCareerList().then((data) => {
                setCareerList(data)
            })
        }, []);
        useEffect(() => {
            if (career && careerList.length > 0) {
                const careerObj = careerList.find(c=>c.id === Number(career));
                if (!careerObj) { return; }
                Careers.getCareerDetails(Number(career)).then((info) => {
                    setSelectedCareer(careerObj);
                    setSelectedCareerInfo(info);
                    setShowModal(true);
                });
            }
        }, [career, careerList]);
        return (
            <ContentWrapper
                    title="Current Job Vacancies"
                    subtitle={["Ready to find a job? Or just looking at what you could do in the future? Select a career below to get started."]}>
                <Row><Col>
                    <FilterableGrid
                        numPerRow={3}
                        data={careerList.map(d => ({
                            imgUrl: CareerImages[d.name.toUpperCase()] || "",
                            text:d.name,
                            onClick:()=>{
                                Careers.getCareerDetails(d.id).then((info) => {
                                    setSelectedCareer(d);
                                    setSelectedCareerInfo(info);
                                    setShowModal(true);
                                });
                            }
                        }))}
                    />
                </Col></Row>
                <Modal show={showModal} 
                    onHide={()=>setShowModal(false)} 
                    size="lg" 
                    title={`Find Me A Job As ${selectedCareer && ["A","E","I","O","U"].includes(selectedCareer.name[0]) ? "An" : "A"} ${selectedCareer && selectedCareer.name}`}>
                    <Row>
                        <Col>
                            {selectedCareerInfo && selectedCareer && <JobVacanciesContents
                                careerId={selectedCareer.id}
                                jobLinks={selectedCareerInfo.careerLinks.filter(f => f.kind === "job")}
                                sydneyJobs={selectedCareerInfo.careerLinks.filter(f => f.kind === "capital_city_jobs")}
                                yellowPages={selectedCareerInfo.careerText.find(f => f.kind === "yellow_pages")}
                                otherJobText={selectedCareerInfo.careerText.find(f => f.kind === "job")}
                            />}
                        </Col>
                    </Row>
                </Modal>
            </ContentWrapper>
        );
    }

export default FindMyJobAds;