import { ResumeFormChildrenProps, YesNoComponent, ShowCareerInfoDetails } from "./ResumeCommonTypes";

import React from "react";

import { Row, Col, ButtonGroup, Button, Form, FormGroup } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";

const EducationPlans: React.FC<ResumeFormChildrenProps> =
    ({ careerResume, updateCareerResume, generalResume, updateGeneralResume, careerInfo }) => {
        const atar = careerInfo ? careerInfo.atarLink : [];
        const tafeLinks = careerInfo ? careerInfo.careerLinks.filter(s=>s.kind==="tafe") : [];
        const privateCollge = careerInfo ? careerInfo.careerLinks.filter(s=>s.kind==="tertiary") : [];
        const haveTertInfo = (atar.length > 0) || (tafeLinks.length) > 0 || (privateCollge.length > 0);
        return <>
            {haveTertInfo && <ShowCareerInfoDetails
                toggleSection={({onToggle})=>
                    <p><Button variant="outline-secondary" onClick={onToggle}>Click Here</Button> to see
                    tertiary options for your career.</p>
                }
            >
                {atar.length > 0 && <>
                    <p>University</p>
                    <ul>{atar.map(a => 
                        <li key={a.atarLink}><Link target="_blank" to={`/atar/${a.atarLink}`}>Click Here</Link></li>
                    )}</ul>
                </>}
                {tafeLinks.length > 0 && <>
                    <p>TAFE</p>
                    <ul>{tafeLinks.map((a,i) => 
                        <li key={i}><a href={a.url} rel="noopener noreferrer" target="_blank">Click Here</a></li>
                    )}</ul>
                </>}
                {privateCollge.length > 0 && <>
                    <p>Private College</p>
                    <ul>{privateCollge.map((a,i) => 
                        <li key={i}><a href={a.url} rel="noopener noreferrer" target="_blank">Click Here</a></li>
                    )}</ul>
                </>}
            </ShowCareerInfoDetails>}
            {careerResume.career_tertiary.map((t, i) => {
                return t.tertiary_tertiary_type === "" ?
                    <Row key={i}>
                        <Col xs={5}>
                            <p>I want to study either Full-Time or Part-Time at:</p>
                            <ButtonGroup>
                                <Button variant="outline-secondary"
                                    onClick={() => {
                                        const career_tertiary = [...careerResume.career_tertiary];
                                        career_tertiary[i].tertiary_tertiary_type = "university";
                                        updateCareerResume({
                                            ...careerResume,
                                            career_tertiary
                                        });
                                    }}
                                >University</Button>
                                <Button variant="outline-secondary"
                                    onClick={() => {
                                        const career_tertiary = [...careerResume.career_tertiary];
                                        career_tertiary[i].tertiary_tertiary_type = "tafe";
                                        updateCareerResume({
                                            ...careerResume,
                                            career_tertiary
                                        });
                                    }}
                                >TAFE</Button>
                                <Button variant="outline-secondary"
                                    onClick={() => {
                                        const career_tertiary = [...careerResume.career_tertiary];
                                        career_tertiary[i].tertiary_tertiary_type = "private";
                                        updateCareerResume({
                                            ...careerResume,
                                            career_tertiary
                                        });

                                    }}
                                >Private College</Button>
                            </ButtonGroup>
                        </Col>
                        <Col xs={2}>
                            <h3>OR</h3>
                        </Col>
                        <Col xs={5}>
                            <p>I want to do an:</p>
                            <Button variant="outline-secondary"
                                onClick={() => {
                                    const career_tertiary = [...careerResume.career_tertiary];
                                    career_tertiary[i].tertiary_tertiary_type = "apprentice";
                                    updateCareerResume({
                                        ...careerResume,
                                        career_tertiary
                                    });

                                }}
                            >Apprenticeship</Button>
                        </Col>
                    </Row>
                    :
                    <><Row>
                        <Col xs={10}>
                            <p>{t.tertiary_tertiary_type === "apprentice" ?
                                "For doing an Apprenticeship"
                                : (t.tertiary_tertiary_type === "university" ? "For going to university"
                                    : (t.tertiary_tertiary_type === "tafe" ? "For going to TAFE" :
                                        (t.tertiary_tertiary_type === "private" ? "Foring goint to a Private College" : "")))}</p>
                        </Col>
                        <Col xs={2}>
                            <FontAwesomeIcon icon={faTimesCircle} onClick={() => {
                                const career_tertiary = [...careerResume.career_tertiary];
                                career_tertiary.splice(i, 1);
                                updateCareerResume({
                                    ...careerResume,
                                    career_tertiary
                                });
                            }} />
                        </Col>
                    </Row>
                        <Row>
                            <Col>
                                <Form>
                                    <FormGroup>
                                        <Col xs={3}><label>Course Type</label></Col>
                                        <Col>
                                            <Form.Control as="select" value={t.tertiary_degree_type} onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                                const career_tertiary = [...careerResume.career_tertiary];
                                                career_tertiary[i].tertiary_degree_type = e.currentTarget.value;
                                                updateCareerResume({
                                                    ...careerResume,
                                                    career_tertiary
                                                });
                                            }}>
                                                <option value="certificate_2">Certificate II</option>
                                                <option value="certificate_3">Certificate III</option>
                                                <option value="certificate_4">Certificate IV</option>
                                                <option value="dip">Diploma</option>
                                                <option value="advanceddip">Advanced Diploma</option>
                                                <option value="bachelor">Bachelor's Degree</option>
                                            </Form.Control>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col xs={3}><label>Course Name</label></Col>
                                        <Col>
                                            <Form.Control type="text" value={t.tertiary_course_name} onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                                const career_tertiary = [...careerResume.career_tertiary];
                                                career_tertiary[i].tertiary_course_name = e.currentTarget.value;
                                                updateCareerResume({
                                                    ...careerResume,
                                                    career_tertiary
                                                });
                                            }} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup>
                                        <Col xs={3}><label>Course Institute</label></Col>
                                        <Col>
                                            <Form.Control type="text" value={t.tertiary_institute_name} onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                                                const career_tertiary = [...careerResume.career_tertiary];
                                                career_tertiary[i].tertiary_institute_name = e.currentTarget.value;
                                                updateCareerResume({
                                                    ...careerResume,
                                                    career_tertiary
                                                });
                                            }} />
                                        </Col>
                                    </FormGroup>
                                    <h5>I have checked entry requirements for this course. E.g. ATAR, assumed knowledge, pre-requisites,
                                course costs, deadline dates and contact details :</h5>
                                    <YesNoComponent
                                        value={t.tertiary_check_requirements}
                                        name={String(i) + "-check-requirements"}
                                        onChange={(v) => {
                                            const career_tertiary = [...careerResume.career_tertiary];
                                            career_tertiary[i].tertiary_check_requirements = v;
                                            updateCareerResume({
                                                ...careerResume,
                                                career_tertiary
                                            });
                                        }}
                                    />
                                    <p>If no, you may need to speak to your careers advisor for an interview.</p>
                                    <h5>I may need to find accommodation for this institute :</h5>
                                    <YesNoComponent
                                        value={t.tertiary_accomodation}
                                        name={String(i) + "-accommodation"}
                                        onChange={(v) => {
                                            const career_tertiary = [...careerResume.career_tertiary];
                                            career_tertiary[i].tertiary_accomodation = v;
                                            updateCareerResume({
                                                ...careerResume,
                                                career_tertiary
                                            });
                                        }}
                                    />
                                    {t.tertiary_tertiary_type === "university" ?
                                        <p>You may find this link useful: <Link to="/uni/accommodation">Click Here</Link></p>
                                        : <></>}
                                    {t.tertiary_tertiary_type === "apprentice" ?
                                        <>
                                            <h4>Have you done the following?</h4>
                                            <h5>I have checked my suitability for an apprenticeship</h5>
                                            <YesNoComponent
                                                value={t.tertiary_done_quizzes}
                                                name={String(i) + "-quizzes"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_done_quizzes = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <p>If no, try using <Link to="/apprenticeships">these</Link> quizzes.</p>
                                            <h5>I know that I will need to be contacting employers from July of my final year at school</h5>
                                            <YesNoComponent
                                                value={t.tertiary_contact_employers}
                                                name={String(i) + "-contact"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_contact_employers = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <h5>I have looked at job vacancies in my area</h5>
                                            <YesNoComponent
                                                value={t.tertiary_job_vacancies}
                                                name={String(i) + "-vacancies"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_job_vacancies = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <h5>In the future, I will need to find employers to contact by searching for employers' names, addresses, email and phone numbers. I have also checked with
                                        relatives and other contacts</h5>
                                            <YesNoComponent
                                                value={t.tertiary_find_employers}
                                                name={String(i) + "-find-emp"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_find_employers = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <h5>I know how to talk to an employer over the phone or by visiting them</h5>
                                            <YesNoComponent
                                                value={t.tertiary_know_how_to_talk}
                                                name={String(i) + "-how-to-talk"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_know_how_to_talk = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <p>You may find this link useful: <Link to="/onThePhone" target="_blank">Click Here</Link></p>
                                            <h5>I know how to present myself to an employer</h5>
                                            <YesNoComponent
                                                value={t.tertiary_know_how_to_present}
                                                name={String(i) + "-how-to-present"}
                                                onChange={(v) => {
                                                    const career_tertiary = [...careerResume.career_tertiary];
                                                    career_tertiary[i].tertiary_know_how_to_present = v;
                                                    updateCareerResume({
                                                        ...careerResume,
                                                        career_tertiary
                                                    });
                                                }}
                                            />
                                            <p>You may find this link useful: <Link to="/meetingBoss" target="_blank">Click here</Link></p>
                                        </>
                                        : <></>}
                                </Form>
                            </Col>
                        </Row></>
            })}
            <Button variant="success" onClick={() => {
                const career_tertiary = [...careerResume.career_tertiary];
                const emptyYesNo = {
                    value: "" as "",
                    what: "",
                    who: "",
                    when: ""
                };
                career_tertiary.push({
                    tertiary_tertiary_type: "",
                    tertiary_degree_type: "",
                    tertiary_course_name: "",
                    tertiary_institute_name: "",
                    tertiary_check_requirements: { ...emptyYesNo },
                    tertiary_accomodation: { ...emptyYesNo },
                    tertiary_done_quizzes: { ...emptyYesNo },
                    tertiary_find_employers: { ...emptyYesNo },
                    tertiary_contact_employers: { ...emptyYesNo },
                    tertiary_job_vacancies: { ...emptyYesNo },
                    tertiary_know_how_to_talk: { ...emptyYesNo },
                    tertiary_know_how_to_present: { ...emptyYesNo }
                });
                updateCareerResume({
                    ...careerResume,
                    career_tertiary
                });
            }}>Add Another Option</Button>
            {careerResume.career_tertiary.find(s => s.tertiary_tertiary_type === "university") ?
                <Row><Col>
                    <Form.Check inline label="I have difficulties affecting my studies."
                        checked={generalResume.generic_tertiary.eas}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateGeneralResume({
                                ...generalResume,
                                generic_tertiary: {
                                    ...generalResume.generic_tertiary,
                                    eas: e.currentTarget.checked
                                }
                            })
                        }
                    />
                    <p>See <a rel="noopener noreferrer" target="_blank" href="https://www.uac.edu.au/current-applicants/educational-access-schemes">here</a> for information to help you to apply for EAS</p>
                    <Form.Check inline label="I would like to obtain a scholarship."
                        checked={generalResume.generic_tertiary.scholarship}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateGeneralResume({
                                ...generalResume,
                                generic_tertiary: {
                                    ...generalResume.generic_tertiary,
                                    scholarship: e.currentTarget.checked
                                }
                            })
                        }
                    />
                    <p>See <Link to="/uni/scholarships">here</Link> for information about scholarships.
                    Scholarships are often awarded to people who can show proof that they have achieved excellent results,
                    done voluntary work for others in their school or community, demonstrated a passion for their career
                    field and demonstrated responsibility or leadership in or out of school with some notable achievements.
                        </p>
                    {generalResume.generic_tertiary.scholarship ?
                        <>
                            <strong>Describe below where you have demonstrated responsibility or leadership, either in or
                                    out of school and any success or results below</strong>
                            <Form.Control
                                as="textarea"
                                value={generalResume.generic_tertiary.scholarship_desc}
                                onChange={(e: React.FormEvent<HTMLTextAreaElement>) => {
                                    updateGeneralResume({
                                        ...generalResume,
                                        generic_tertiary: {
                                            ...generalResume.generic_tertiary,
                                            scholarship_desc: e.currentTarget.value
                                        }
                                    })
                                }}
                            />
                        </>
                        : <></>}
                    <Form.Check inline label="I would like to get an early offer for university."
                        checked={generalResume.generic_tertiary.principal_recommend}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateGeneralResume({
                                ...generalResume,
                                generic_tertiary: {
                                    ...generalResume.generic_tertiary,
                                    principal_recommend: e.currentTarget.checked
                                }
                            })
                        }
                    />
                    <p>See <Link to="/uni/srs">here</Link> for information to help you apply for the Schools Recommendation Scheme and other university options.</p>
                </Col></Row>
                : <></>}
        </>
    }

export default EducationPlans;