import React from "react";
import "./button.css";
import { makeClassName } from "../util";

interface ButtonProps extends React.ComponentProps<"button"> {
    variant : "active"|"neutral"|"negative",
    fullWidth?:boolean,
    textButton?:boolean,
    glowing?:boolean
}

const Button : React.FC<ButtonProps> = ({variant, fullWidth, textButton, className, disabled, glowing, children, ...props}) => {
    const classStr = makeClassName(className, [
        "jobjump-button",
        `jobjump-button-${disabled ? "disabled" : variant}`, 
        fullWidth ? "jobjump-button-full-width" : "",
        glowing ? "jobjump-button-glowing" : "",
        textButton ? "jobjump-button-text" : "pl-32 pr-32"
    ]);
    return <button disabled={disabled} className={classStr} {...props}>
        {children}
    </button>
}


export default Button;