import React from "react";
import { RouteComponentProps } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";
import CategorySelect from "./CategorySelect";
import CourseSelect from "./CourseSelect";
import DetailsCourseSelect from "./DetailsCourseSelect";
import InstituteSelect from "./InstituteSelect";
import qs from "query-string";
import UniContactPreferences, { UniResponse } from "../../data/UniContactPreferences";

type Stages = "insts"|"categories"|"courses"|"confirmDetails";

const UniSelection : React.FC<RouteComponentProps & UserDetailsProps> = (props) => {
    const [response, setResponse] = React.useState<UniResponse>({
                selectedInsitutes:[],
                additionalInformation:[],
                categories:[],
                additionalComments:"",
                contactConsent:true
            });
    const [stage, setStage] = React.useState<Stages>("categories");
    React.useEffect(() => {
        if (props.location.search) {
            setResponse(props.location.state)
        } else {
            UniContactPreferences.getPreferences().then(d => setResponse(d || {
                selectedInsitutes:[],
                additionalInformation:[],
                categories:[],
                additionalComments:"",
                contactConsent:true
            }));
        }
        setStage((qs.parse(props.location.search).stage as Stages|undefined) || "categories");
    }, [props.location])
    const continueToStage = React.useCallback((stage:Stages) => {
        response && UniContactPreferences.setPreferences(response).then(() => 
            props.history.push({pathname:props.location.pathname, search:qs.stringify({stage}), state:response})
        )
    }, [response, props])
    const finish = React.useCallback(() => {
        response && UniContactPreferences.setPreferences(response).then(() => {
            props.history.push("/");
        })
    }, [response, props])
    return <ContentWrapper title="Considering Uni?" subtitle={["Get on your way to where you want to go!"]}>
        {response && stage === "categories" && <CategorySelect response={response} setResponse={setResponse} userDetails={props.userDetails} onContinue={()=>continueToStage("insts")} />}
        {response && stage === "insts" && <InstituteSelect response={response} setResponse={setResponse} userDetails={props.userDetails} onContinue={()=>continueToStage("courses")} />}
        {response && stage === "courses" && <CourseSelect response={response} setResponse={setResponse} {...props} onContinue={()=>continueToStage("confirmDetails")} />}
        {response && stage === "confirmDetails" && <DetailsCourseSelect response={response} setResponse={setResponse} onContinue={finish} userDetails={props.userDetails} />}
    </ContentWrapper>;
};

export default UniSelection;