import React, { useState } from "react";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import User from "../data/User";
import PageHero from "../components/PageHero";
import { Col, Row } from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";
import { Label, Select, TextField } from "../components/Form";
import Button from "../components/Button";
import Alert from "../components/Alert";
import ContentWrapper from "../components/ContentWrapper";

const SupplementaryRegistration : React.FC<RouteComponentProps> =
    ({history, location : {search}}) => {
        const {token, id} = qs.parse(search);
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [password, setPassword] = useState("");
        const [confirmPassword, setConfirmPassword] = useState("");
        const [gender, setGender] = useState("m");
        const [passwordMismatch, setPasswordMismatch] = useState(false);
        const [formIncomplete, setFormIncomplete] = useState(false);
        if (typeof token !== "string" || typeof id !== "string") {
            return <></>
        }
        const handleSubmit = () => {
            if (firstName === "" || lastName === "" || password === "") {
                setFormIncomplete(true);
            } else if (password !== confirmPassword) {
                setPasswordMismatch(true);
            } else {
                User.partialRegistration({id, token, firstName, lastName, password, gender}).then(() =>{
                    history.push("/");
                });
            }
        }

        return <ContentWrapper
                title="Registration"
                subtitle={[
                    "To complete your registration, please complete the form below. Once you have complete it, you will be taken back to JobJump to login."
                ]}>
            <Row><Col>
                {passwordMismatch && <Alert textOnly variant="negative" text="Passwords do not match." />}
                {formIncomplete && <Alert textOnly variant="negative" text="Please complete all the fields." />}
            </Col></Row>
            <PageSectionRow><Col><form>
                <Row><Col>
                    <Label>First Name</Label>
                    <TextField value={firstName} onChange={setFirstName} />
                </Col></Row>
                <Row><Col>
                    <Label>Last Name</Label>
                    <TextField value={lastName} onChange={setLastName} />
                </Col></Row>
                <Row><Col>
                    <Label>Password</Label>
                    <TextField passwordField value={password} onChange={setPassword} />
                </Col></Row>
                <Row><Col>
                    <Label>Confirm Password</Label>
                    <TextField passwordField value={confirmPassword} onChange={setConfirmPassword} />
                </Col></Row> 
                <Row><Col>
                    <Label>Gender</Label>
                    <Select onChange={(v)=>v&&setGender(v.value)} options={[{label:"Male", value:"m"}, {label:"Female", value:"f"}]} /> 
                </Col></Row> 
            </form></Col></PageSectionRow>
            <Row>
                <Col className="text-center">
                    <Button variant="active" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Col>
            </Row>
        </ContentWrapper>;
    }

export default SupplementaryRegistration