import React from "react";
import { StudentSubject } from "jobjump-types/CaStudents"
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";

const CaSubjectTable : React.FC<RouteComponentProps> = (props) => {
    const [data, setData] = React.useState<StudentSubject[]>([]);
    React.useEffect(() => {
        CaStudentDetails.studentSubjects().then(setData);
    }, []);
    return <CaAdminLayout active="subjects" title="Student Subjects" {...props}>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Student Subjects</h3>
                <span>Below are senior subjects that students have indicated they are currently or are intending to study in their Resume/Plan.</span>
            </Col>
        </PageSectionRow>
        <PageSectionRow> 
            <Col>
                <CaStudentTable
                    data={data}
                    additionalColumns={[
                        { Header:"Subject Name", accessor:"subject_name", width:3 },
                        { Header:"Category", accessor:"subject_category", width:1 },
                    ]} />
            </Col>
        </PageSectionRow>
    </CaAdminLayout>
}

export default CaSubjectTable;