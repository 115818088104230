import { CaNewsItem } from "jobjump-types/CaNews";
import { HeroConfiguration, JobJumpBulletin, SchoolHomepageResponse, SchoolUploadedFile } from "jobjump-types/SchoolHomePage";
import qs from "query-string";

const SchoolHomepageAPI = {
    getSiteConfig : (args:{shortCode?:string,url?:string,schoolId?:string}) : Promise<SchoolHomepageResponse|undefined> =>
        fetch(`/api/schoolHomepage/site?${qs.stringify(args)}`).then(r=>r.json().catch(()=>{return undefined})),
    getLogo :(args:{shortCode?:string,url?:string}) : Promise<string|undefined> =>
        fetch(`/api/schoolHomepage/logo?${qs.stringify(args)}`).then(r=>r.text().catch(()=>{return undefined})),
    getHero :(args:{shortCode?:string,url?:string}) : Promise<HeroConfiguration|undefined> =>
        fetch(`/api/schoolHomepage/homepageHero?${qs.stringify(args)}`).then(r=>r.json().catch(()=>{return undefined})),
    getSchoolNews : (school:string) : Promise<CaNewsItem[]> =>
        fetch("/api/schoolHomepage/news/"+school).then(r=>r.json()),
    getFileDownloadLink : (school:string, id:string) =>
        fetch(`/api/schoolHomepage/file/${school}/${id}`).then(r=>r.text()),
    getFiles : (school:string) : Promise<SchoolUploadedFile[]> =>
        fetch(`/api/schoolHomepage/files/${school}`).then(r=>r.json()),
    getJobjumpBulletins : (school:string) : Promise<JobJumpBulletin[]> =>
        fetch(`/api/schoolHomepage/jjBulletins/${school}`).then(r=>r.json()),
    openJobjumpBulletin : (school:string, id:string, kind:string) =>
        fetch(`/api/schoolHomepage/jjBulletins/${school}/${id}/${kind}`).then(r=>r.text())
}

export default SchoolHomepageAPI;