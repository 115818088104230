import { UserDetails } from "jobjump-types/User";
import _ from "lodash";
import React from "react";
import Button from "../components/Button";
import ContentWrapper from "../components/ContentWrapper";
import {Col} from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";

type LinkSection = {
    states: string[],
    content: {text:string, link:string, states:string[]}[]
}

const LinkContent : {[k:string]:LinkSection} = {
    "ATAR" : {
        states:["NSW", "VIC"],
        content: [
            {
                text: "Frequently asked questions",
                link: "https://www.uac.edu.au/future-applicants/faqs-and-forms",
                states: ["NSW"]
            },
            {
                text: "All About Your ATAR",
                link: "https://www.uac.edu.au/future-applicants/atar/how-your-atar-is-calculated",
                states: ["NSW"]
            },
            {
                text: "What is the ATAR?",
                link: "https://www.vtac.edu.au/results-offers/atar-explained.html",
                states: ["VIC"]
            },
             {
                text: "Year 12 Results and Getting Your ATAR",
                link: "https://www.vtac.edu.au/results-offers/y12-atar.html",
                states: ["VIC"]
            },
             {
                text: "Scaling - What it is",
                link: "https://www.vtac.edu.au/atar-scaling-guide-2022.html#item-2",
                states: ["VIC"]
            },
 
        ]
    },
    "Advice" : {
        states:["NSW", "VIC"],
        content: [
            {
                text: "Skills Road",
                link: "http://www.skillsroad.com.au/home",
                states: ["NSW", "VIC"]
            },
            {
                text: "Help Line for Teens",
                link: "http://www.kidshelpline.com.au/teens/",
                states: ["NSW", "VIC"]
            },
            {
                text: "Beyond Blue",
                link: "https://www.beyondblue.org.au/",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Apprenticeships and traineeships" : {
        states:["NSW", "VIC"],
        content: [
            {
                link: "https://www.aapathways.com.au/complex-search?explorerMode=true",
                text: "Apprenticeship Pathways AAP",
                states: ["NSW", "VIC"]
            },
 
            {
                link: "http://www.australianapprenticeships.gov.au/",
                text: "Australian Apprenticeships",
                states: ["NSW", "VIC"]
            },
            {
                link: "https://www.aapathways.com.au/careers-for-australian-apprenticeships-traineesh/job-pathways",
                text: "Apprenticeships Job Pathways Charts ",
                states: ["NSW", "VIC"]
            },
            {
                link: "https://1300apprentice.com.au/women-in-trades/?gclid=Cj0KCQiAmeKQBhDvARIsAHJ7mF6cLGfxxorJgH7KpSvIi7A3UHkiboVLHovK6O2e6pJkfceOJ594eU8aAormEALw_wcB",
                text: "Girls in trades",
                states: ["NSW"]
            },
            {
                link:"https://www.grouptrainingdirectory.com.au/",
                text: "Group Training Info",
                states: ["NSW"]
            },
            {
                link:"https://www.skillsroad.com.au/training-study/apprenticeships",
                text: "Info on",
                states:["NSW"]
            },
            {
                link:"https://www.training.nsw.gov.au/",
                text:"NSW Information",
                states:["NSW"]
            },
            {
                link:"http://www.aapathways.com.au/Career-Resources/Job-Pathway-Charts-Link/Job-Pathways-Charts-PDF",
                text:"Pathways to go higher",
                states:["NSW", "VIC"]
            },
            {
                link:"http://www.skillsone.com.au/",
                text: "Trade videos",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.migas.com.au/",
                text: "Migas Apprenticeships",
                states:["NSW", "VIC"]
            }
        ]
    },
    "Calendar" : {
        states:["VIC"],
        content: [
            {
                link: "https://www.vcaa.vic.edu.au/administration/Key-dates/Pages/Index.aspx",
                text: "VCE Key Dates",
                states: ["VIC"]
            }
        ]
    },
    "Career Fields" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://careerswithstem.com.au/#gsc.tab=0",
                text: "STEM Careers",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.careerharvest.com.au/",
                text: "Agriculture Careers",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.myhealthcareer.com.au/",
                text: "Health Careers",
                states: ["NSW", "VIC"]
            },
            {
                text:"Build Your Career",
                link: "http://www.buildyourcareer.com.au/",
                states:["NSW", "VIC"]
            },
            {
                text: "Careers in Design",
                link: "https://www.design.org.au/designindustry/design-disciplines",
                states:["NSW", "VIC"]
            },
            {
                text:"ICT Careers Wheel",
                link:"https://www.careersfoundation.com.au/",
                states:["NSW", "VIC"]
            },
            {
                link:"https://www.teach.nsw.edu.au/",
                text: "Education Careers",
                states: ["NSW"]
            }
        ]
    },
    "Career Research": {
        states:["NSW", "VIC"],
        content:[
            {
                link:"https://www.gooduniversitiesguide.com.au/careers-guide",
                text: "Good Careers Guide",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://myfuture.edu.au/",
                text: "My Future",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.education.gov.au/australian-blueprint-career-development",
                text: "Worksheets and Activities",
                states: ["NSW", "VIC"]
            },
            {
                text:"Job Jumpstart",
                link:"https://www.jobjumpstart.gov.au/",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Disability Education" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.boardofstudies.nsw.edu.au/special_ed",
                text: "Special Education NESA",
                states: ["NSW"]
            },
            {
                text:"Programs and Support",
                link:"https://www.education.vic.gov.au/school/teachers/learningneeds/Pages/programs-support.aspx",
                states:["VIC"]
            }
        ]
    },
    "Disability Employment" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.endeavour.com.au",
                text: "Endeavour Foundation",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://jobaccess.gov.au/home",
                text: "Job Access",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.novaemployment.com.au/",
                text: "Nova",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.tafensw.edu.au/student-services/disability-services",
                text: "TAFE Support",
                states: ["NSW"]
            }
        ]
    },
    "Education in NSW" : {
        states: ["NSW"],
        content: [
            {
                link:"http://ace.bos.nsw.edu.au",
                text: "ACE Manual",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/naplan",
                text: "Naplan Test",
                states: ["NSW"]
            }
        ]
    },
    "Employment Advice" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.youthcentral.vic.gov.au/jobs-careers/applying-for-jobs",
                text: "Applying for work",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://iworkfor.nsw.gov.au/",
                text: "NSW Government Jobs",
                states: ["NSW"]
            },
            {
                link:"http://www.youthcentral.vic.gov.au/jobs-careers/starting-out-and-finishing-up",
                text: "Starting work",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.fairwork.gov.au/find-help-for/young-workers-and-students?utm_medium=email&utm_campaign=Online+Learning+-+Schools+and+Tafes&utm_content=Online+Learning+-+Schools+and+Tafes+CID_b546640dbe79cc4e72869af74a6d0bc5&utm_source=eNewsletter%20%20email%20u",
                text: "Know Your Rights",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Employment Sites" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.careerone.com.au/",
                text:"CareerOne",
                states:["NSW", "VIC"]
            },
            {
                link:"http://au.indeed.com",
                text: "indeed.com",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.jobsearch.gov.au/",
                text: "JobActive",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://au.jobted.com/",
                text: "JobTed",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.adzuna.com.au/",
                text: "adzuna.com.au",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.seek.com.au/",
                text: "seek.com.au",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.careerjet.com.au/",
                text: "careerjet.com",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.applydirect.com.au/",
                text: "applydirect.com.au",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.careersonline.com.au/jswindex.html",
                text: "careersonline.com.au",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://talent.com",
                text: "talent.com",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.pixujob.com",
                text: "PixuJob",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Employment Trends" : {
        states: ["NSW", "VIC"], 
        content: [
            {
                link:"http://joboutlook.gov.au/",
                text: "Job Outlook",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://lmip.gov.au/",
                text: "Labour Market Info",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Financial Assistance" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://studyassist.gov.au/sites/studyassist/helppayingmyfees/fee-help/pages/fee-help-",
                text: "FEE-HELP",
                states:["NSW", "VIC"]
            },
            {
                link:"https://www.studyassist.gov.au/",
                text: "Government assistance",
                states:["NSW", "VIC"]
            },
            {
                link:"https://www.moneysmart.gov.au/life-events-and-you/under-25s",
                text: "Money Smart",
                states:["NSW", "VIC"]
            },
            {
                link:"http://studyassist.gov.au/sites/StudyAssist/MyTertiaryStudyOptions/pages/what-am-i-eligible-for",
                text: "Study Assist - Eligibilty",
                states:["NSW", "VIC"]
            },
            {
                link:"http://www.humanservices.gov.au/customer/services/centrelink/youth-allowance?utm_id=7",
                text: "Youth Allowance",
                states:["NSW", "VIC"]
            },
            {
                text:"Payments to help you while you study or complete an Australian Apprenticeship",
                link:"https://www.humanservices.gov.au/individuals/subjects/payments-students-and-trainees",
                states:["NSW", "VIC"]
            }
        ]
    },
    "Guest Speakers" : {
        states: ["NSW"],
        content: [
            {
                link:"https://www.inspiringthefuture.org.au/page/homepage",
                text: "Inspiring The Future",
                states: ["NSW"]
            }
        ]
    },
    "HSC" : {
        states : ["NSW"],
        content: [
            {
                link:"http://amow.boardofstudies.nsw.edu.au/",
                text: "All My Own Work",
                states: ["NSW"]
            },
            {
                link:"http://www.boredofstudies.org/",
                text: "Bored of Studies",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/parents",
                text: "Parent Advice",
                states: ["NSW"]
            },
            {
                link:"http://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/Understanding-the-curriculum/resources/hsc-exam-papers",
                text: "Past HSC Papers",
                states: ["NSW"]
            },
            {
                link:"http://www4.boardofstudies.nsw.edu.au/hsc.cfm",
                text: "Practice HSC Multiple Choice Qs",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/hsc-results",
                text: "Results",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/rosa/",
                text: "RoSA",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/exhibitions",
                text: "Showcase Exhibitions/Performances",
                states: ["NSW"]
            },
            {
                link:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/subject-selection",
                text: "Subjects",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/syllabus_hsc/",
                text: "Syllabi",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/voc_ed/",
                text: "Vocational Education",
                states: ["NSW"]
            }
        ]
    },
    "HSC - Illness or Misadventure" : {
        states: ["NSW"], 
        content: [
            {
                link:"http://www.boardofstudies.nsw.edu.au/hsc_exams/illness-misadventure.html",
                text: "Illness on the exam day",
                states: ["NSW"]
            }
        ]
    },
    "HSC - Disability" : {
        states: ["NSW"],
        content: [
            {
                link:"http://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/disability-provisions",
                text: "HSC Disability",
                states: ["NSW"]
            }
        ]
    },

    "International" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.studyinaustralia.gov.au/",
                text: "Study in Australia",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/international-year-12-students",
                text: "UAC International Students",
                states: ["NSW"]
            },
            {
                link:"http://www.studyintafe.edu.au/study/tafe",
                text: "TAFE International Students",
                states: ["NSW"]
            }
        ]
    },
    "NSW Educations Standards Authority" : {
        states:["NSW"],
        content:[
            {
                link:"http://www.boardofstudies.nsw.edu.au/",
                text: "Main Page",
                states: ["NSW"]
            },
            {
                link:"https://educationstandards.nsw.edu.au/wps/portal/nesa/about/news/all-news",
                text: "News",
                states: ["NSW"]
            },
            {
                link:"http://studentsonline.bos.nsw.edu.au/",
                text: "Students on line",
                states: ["NSW"]
            },
            {
                link:"http://www.boardofstudies.nsw.edu.au/syllabus_hsc/glossary_keywords.html",
                text: "Terms and Keywords",
                states: ["NSW"]
            },
            {
                link:"http://studentsonline.bos.nsw.edu.au/go/help/",
                text: "Students on line - Help",
                states: ["NSW"]
            }
        ]
    },
    "Parent Help" : {
        states:["VIC"],
        content: [
            {
                text: "Parent Advice for Helping Students",
                link:"https://www.education.vic.gov.au/parents/career-planning/Pages/default.aspx",
                states:["VIC"]
            }
        ]
    },
    "Quizzes" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.carecareers.com.au/quiz/career-quiz",
                text: "Care Careers Quiz",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://joboutlook.gov.au/careerquiz.aspx",
                text: "Job Outlook Careers Quiz",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.lifelauncher.nsw.gov.au/",
                text: "Life Launcher",
                states: ["NSW"]
            },
            {
                link:"https://www.skillsroad.com.au/my-account/my-careers-quiz",
                text: "Skills Road",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Registered Training Organisations" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.myskills.gov.au/registeredtrainers/search/",
                text: "Training Provider Search",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.training.com.au/rto/",
                text: "What is an RTO?",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "Scholarships" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.gooduniversitiesguide.com.au/scholarships",
                text: "Search Australia-wide",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.uac.edu.au/equity",
                text: "Equity Scholarships",
                states: ["NSW"]
            },
            {
                link:"https://cef.org.au/students/scholarships/",
                text: "Scholarships Country Education Foundation",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "School-based apprenticeships and traineeships": {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://education.nsw.gov.au/schooling/students/career-and-study-pathways/school-based-apprenticeships-and-traineeships/apprenticeships",
                text: "School Based Apprenticeships",
                states: ["NSW"]
            },
            {
                link:"https://education.nsw.gov.au/schooling/students/career-and-study-pathways/school-based-apprenticeships-and-traineeships/traineeships",
                text: "School Based Traineeships",
                states: ["NSW"]
            },
            {
                link:"https://education.nsw.gov.au/schooling/students/career-and-study-pathways/school-based-apprenticeships-and-traineeships/getting-started/sbat-faq",
                text: "Frequently asked questions",
                states: ["NSW"]
            },
            {
                link:"https://education.nsw.gov.au/schooling/students/career-and-study-pathways/school-based-apprenticeships-and-traineeships/getting-started/students",
                text: "Info for Students",
                states: ["NSW"]
            },
            {
                text:"School Based Apprenticeships and Traineeships",
                link:"https://www.vcaa.vic.edu.au/curriculum/vet/apprenticeships/Pages/index.aspx",
                states:["VIC"]
            },
            {
                text:"School Based Traineeships",
                link:"https://www.vcaa.vic.edu.au/studentguides/getvet/Pages/School-basedApprenticeshiporTraineeship.aspx",
                states:["VIC"]
            }
        ]
    },
    "Security Service and Police Careers": {
        states:["VIC"],
        content:[
            {
                text:"AFP",
                link:"https://www.afp.gov.au/careers",
                states:["VIC"]
            },
            {
                text:"Victorian Police",
                link:"https://www.police.vic.gov.au/careers",
                states:["VIC"]
            },
            {
                text:"Ambulance",
                link:"https://www.ambulance.vic.gov.au/careers/",
                states:["VIC"]
            },
            {
                text:"Fire Fighter",
                link:"https://firefighter.vic.gov.au/recruitment-process/",
                states:["VIC"]
            }
        ]
    },
    "Smart and Skilled" : {
        states: ["NSW"],
        content: [
            {
                link:"https://smartandskilled.nsw.gov.au/about",
                text: "About",
                states: ["NSW"]
            },
            {
                link:"https://smartandskilled.nsw.gov.au/are-you-eligible",
                text: "Eligibility",
                states: ["NSW"]
            },
            {
                link:"https://smartandskilled.nsw.gov.au/sands/find-a-course",
                text: "Find a Course",
                states: ["NSW"]
            },
            {
                link:"https://smartandskilled.nsw.gov.au/for-students",
                text: "For Students",
                states: ["NSW"]
            }
        ]
    },
    "Studying After High School" : {
        states:["NSW", "VIC"],
        content: [
            {
                link:"http://studylink.com/study-in-australia/",
                text: "Australia and overseas",
                states: ["NSW", "VIC"]
            },
            {
                text:"Beyond School Study Guide",
                link:"https://www.studyassist.gov.au/sites/studyassist/files/beyond_school_study-guide_interactive.pdf",
                states:["NSW", "VIC"]
            },
            {
                text:"Where to Now Guide",
                link:"https://www.vcaa.vic.edu.au/studentguides/where-to-now/Pages/Index.aspx?Redirect=2",
                states:["VIC"]
            }
        ]
    },
    "Studying" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.youthcentral.vic.gov.au/study-and-training/help-with-study/how-to-study-better",
                text: "Tips",
                states: ["NSW", "VIC"]
            },
            {
                text:"Exam Tips",
                link:"https://www.youthcentral.vic.gov.au/study-and-training/help-with-study/how-to-study-better/top-10-exam-tips",
                states:["NSW", "VIC"]
            },
            {
                text:"Exam Handling Stress",
                link:"https://au.reachout.com/everyday-issues/exam-stress",
                states:["NSW", "VIC"]
            }
        ]
    },
    "TAFE" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.tafensw.edu.au/course-areas/",
                text: "Courses and Careers ",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/counselling-career-development-services",
                text: "Careers counsellors",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/study/types-courses/degrees",
                text: "Degrees",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/student-services/disability-services",
                text: "Disability support",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/vet-student-loans",
                text: "VET Student Loans",
                states: ["NSW"]
            },
            {
                link:"http://www.studyintafe.edu.au/study/tafe",
                text: "International",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/study/pathways/school-to-tafe",
                text: "School to TAFE",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/study/pathways/tafe-to-university",
                text: "TAFE to Uni",
                states: ["NSW"]
            },
            {
                link:"https://www.tafensw.edu.au/enrol/payment-funding/smart-skilled",
                text: "Smart and Skilled",
                states: ["NSW"]
            },
            {
                link: "https://skills.education.nsw.gov.au/jobtrainer#mainContent",
                text: "JobTrainer",
                states: ["NSW"]
            },
            {
                text:"GoTAFE",
                link:"https://www.gotafe.vic.edu.au/",
                states:["VIC"]
            },
            {
                text:"Bendigo",
                link:"http://www.bendigotafe.edu.au/Pages/default.aspx",
                states:["VIC"]
            },
            {
                text:"Box Hill",
                link:"https://www.boxhill.edu.au/",
                states:["VIC"]
            },
            {
                text:"Chisholm",
                link:"https://www.chisholm.edu.au/",
                states:["VIC"]
            },
            {
                text:"Gippsland",
                link:"https://www.tafegippsland.edu.au/",
                states:["VIC"]
            },
            {
                text:"Federation University",
                link:"https://federation.edu.au/",
                states:["VIC"]
            },
            {
                text:"The Gordon",
                link:"https://www.thegordon.edu.au/",
                states:["VIC"]
            },
            {
                text:"Holmesglen",
                link:"https://holmesglen.edu.au/",
                states:["VIC"]
            },
            {
                text:"Kangan",
                link:"https://www.kangan.edu.au/",
                states:["VIC"]
            },
            {
                text:"Melbourne Polytechnic",
                link:"https://www.melbournepolytechnic.edu.au/",
                states:["VIC"]
            },
            {
                text:"RMIT",
                link:"https://www.rmit.edu.au/",
                states:["VIC"]
            },
            {
                text:"South West TAFE",
                link:"https://swtafe.edu.au/",
                states:["VIC"]
            },
            {
                text:"SuniTAFE",
                link:"https://www.sunitafe.edu.au/",
                states:["VIC"]
            },
            {
                text:"Victoria Polytechnic",
                link:"https://www.vupolytechnic.edu.au/",
                states:["VIC"]
            },
            {
                text:"Swinburne",
                link:"https://www.swinburne.edu.au/",
                states:["VIC"]
            },
            {
                text:"William Angliss Institute",
                link:"https://www.angliss.edu.au/",
                states:["VIC"]
            },
            {
                text:"Wodonga",
                link:"https://www.wodongatafe.edu.au/",
                states:["VIC"]
            }
        ]
    },
    "TAFE To Uni" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://credittransfer.tafensw.edu.au/?_ga=2.114514375.1793567103.1537656820-180608526.1467675412&_gac=1.12388992.1537663477.EAIaIQobChMI8unr5fLP3QIV3AMqCh2aJAxnEAAYASAAEgISQPD_BwE",
                text: "Credit transfer",
                states: ["NSW"]
            },
            { 
                text:"Pathways to Uni",
                link:"https://www.gotafe.vic.edu.au/courses/pathways-to-university",
                states:["VIC"]
            },
            {
                text:"TAFE to Uni Pathway Courses",
                link:"https://gotafe-craft-prod.s3.amazonaws.com/documents/2020-GOTAFE_Pathways_to_university.pdf",
                states:["VIC"]
            }
        ]
    },
    "Tax File Number" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.ato.gov.au/Individuals/Tax-file-number/Apply-for-a-TFN/",
                text: "Apply for a TFN",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.taxsuperandyou.gov.au/",
                text: "Tax, Super and You",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "UAC": {
        states: ["NSW"],
        content: [
            {
                link:"https://www.uac.edu.au/future-applicants/admission-criteria/university-selection-rank-adjustments",
                text: "Adjustment Factors (Bonus Points)",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/how-to-apply-for-uni",
                text: "Applying",
                states: ["NSW"]
            },
            {
                link:"http://www.uac.edu.au/undergraduate/fees/uac-charges.shtml",
                text: "Charges/Fees",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/search-for-courses",
                text: "Course search",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/current-applicants/educational-access-schemes",
                text: "EAS - Educational Access Scheme",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/faqs-and-forms/educational-access-schemes",
                text: "EAS Frequently asked questions",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/international-year-12-students",
                text: "International Students",
                states: ["NSW"]
            },
            {
                link:"http://www.uac.edu.au",
                text: "Main page",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/about/contact",
                text: "Make an enquiry",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/institutions",
                text: "Participating Institutions",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/admission-criteria/pathways-to-university",
                text: "Pathway to Uni",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/faqs-and-forms/uac-application-number-and-pin",
                text: "PIN - Frequently asked questions",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/media-centre",
                text: "Social media",
                states: ["NSW"]
            },
            {
                link:"https://stat.acer.edu.au/",
                text: "STAT",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/subject-compass",
                text: "Subject Compass",
                states: ["NSW"]
            },
            {
                link:"http://www.uac.edu.au/undergraduate/",
                text: "Undergraduate Admission",
                states: ["NSW"]
            },
            {
                link:"http://www.uac.edu.au/schoolink/year-10.shtml",
                text: "Year 10 advice",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/future-applicants/atar/how-to-get-your-atar",
                text: "Year 12 advice - Getting Results",
                states: ["NSW"]
            },
            {
                link:"https://www.uac.edu.au/assets/documents/scaling-reports/preliminary-report-on-scaling-2021.pdf",
                text: "Scaling Report",
                states:["NSW"]
            }
        ]
    },
    "UCAT" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.ucat.edu.au/",
                text: "Overview",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.ucat.edu.au/ucat-anz/universities/",
                text: "Universites and Degrees",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "University" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"https://www.qilt.edu.au/",
                text: "Australian Universities - QILT",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.uac.edu.au/undergraduate/fees/course-costs.shtml",
                text: "Course costs",
                states: ["NSW"]
            },
            {
                link:"https://www.courseseeker.edu.au/",
                text: "Course Seeker",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.uac.edu.au/undergraduate/offers/defer.shtml",
                text: "Deferring Frequently asked questions",
                states: ["NSW"]
            },
            {
                link:"https://www.gooduniversitiesguide.com.au/education-blogs/tertiary-study/to-defer-or-not-to-defer",
                text: "Deferring Pros and Cons",
                states: ["NSW", "VIC"]
            },
            {
                link:"http://www.uac.edu.au/equity",
                text: "Equity Scholarships",
                states: ["NSW"]
            },
            {
                link:"http://www.uac.edu.au/undergraduate/fees/financial-assistance.shtml",
                text: "Financial assistance",
                states: ["NSW"]
            },
            {
                link:"https://www.nagcas.org.au/resources/gca",
                text: "Graduate Careers Info",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.studyassist.gov.au/while-youre-studying-providers-offer-commonwealth-assistance/universities-and-other-higher-education",
                text: "Study Assist - Tertiary providers",
                states: ["NSW", "VIC"]
            },
            {
                link:"https://www.gooduniversitiesguide.com.au/education-blogs/student-life/an-insider-s-guide-to-life-at-uni",
                text: "What is Uni Like?",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "USI" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.usi.gov.au/Students/Pages/default.aspx",
                text: "USI",
                states: ["NSW", "VIC"]
            }
        ]
    },
    "VCAL" : {
        states: ["VIC"],
        content: [
            {
                text:"VCE Curriculum",
                link:"https://www.vcaa.vic.edu.au/curriculum/vce/Pages/Index.aspx?Redirect=1",
                states:["VIC"]
            },
            {
                text:"VCE Past Exams Papers",
                link:"https://www.vcaa.vic.edu.au/assessment/vce-assessment/past-examinations/Pages/index.aspx?Redirect=1",
                states:["VIC"]
            },
            {
                text:"VCAL - All About",
                link:"https://www.vcaa.vic.edu.au/curriculum/vcal/Pages/AboutVCAL.aspx",
                states:["VIC"]
            },
            {
                text:"VCAL Video",
                link:"https://www.youtube.com/watch?time_continue=4&v=bB8m7t4cJHQ&feature=emb_logo",
                states:["VIC"]
            }
        ]
    },
    "VCE - Illness or Disability" : {
        states:["VIC"],
        content: [
            {
                text:"Special Arrangements",
                link:"https://www.vcaa.vic.edu.au/administration/special-provision/Pages/SpecialExaminationArrangements.aspx",
                states:["VIC"]
            }
        ]
    },
    "VET Course Finder": {
        states:["NSW", "VIC"],
        content: [
            {
                text:"My Skills",
                link:"https://www.myskills.gov.au/",
                states:["NSW", "VIC"]
            },
            {
                text:"Course Finder",
                link:"https://www.myskills.gov.au/courses/search/",
                states:["NSW", "VIC"]
            },
            {
                text:"Training Guarantee - Skills Gateway",
                link:"https://www.skills.vic.gov.au/s/",
                states:["VIC"]
            },
            {
                text:"Skills and Jobs Centres",
                link:"https://www.education.vic.gov.au/about/programs/pathways/skillsandjobscentres/Pages/default.aspx",
                states:["VIC"]
            }
        ]
    },
    "VTAC" : {
        states:["VIC"],
        content: [
            {
                text:"Am I Eligible",
                link:"http://www.vtac.edu.au/who.html",
                states:["VIC"]
            },
            {
                text:"Yr12 Guide",
                link:"https://www.vtac.edu.au/y12guide.html",
                states:["VIC"]
                        },
            {
                text:"Yr10 Guide to Choosing Subjects",
                link:"http://vtac.edu.au/files/pdf/publications/2019_year_10_guide.pdf",
                states:["VIC"]
            },
            {
                text:"Course Prerequisites 2022",
                link:"http://www.vtac.edu.au/files/pdf/publications/prerequisites-2022.pdf",
                states:["VIC"]
            },
            {
                text:"Course Prerequisites 2021",
                link:"http://vtac.edu.au/files/pdf/publications/prerequisites-2021.pdf",
                states:["VIC"]
            },
            {
                text:"Course Search",
                link:"https://delta.vtac.edu.au/CourseSearch/searchguide.htm",
                states:["VIC"]
            },
            {
                text:"Course and Prerequisite Planner",
                link:"https://delta.vtac.edu.au/CourseSearch/prerequisiteplanner.htm",
                states:["VIC"]
            },
            {
                text:"Subject Bonus Tool",
                link:"https://delta.vtac.edu.au/CourseSearch/bonuscalc.htm",
                states:["VIC"]
            },
            {
                text:"Application ",
                link:"https://www.vtac.edu.au/applying.html",
                states:["VIC"]
            },
            {
                text:"User Account",
                link:"https://www.vtac.edu.au/applying/need/id.html",
                states:["VIC"]
            },
        ]
    },
    "Workplace Health & Safety" : {
        states: ["NSW", "VIC"],
        content: [
            {
                link:"http://www.safework.nsw.gov.au/",
                text: "SafeWork NSW",
                states: ["NSW"]
            },
            {
                text:"Work Safe",
                link:"https://www.worksafe.vic.gov.au/",
                states:["VIC"]
            },
        ]
    },
    "Workplacement": {
        states: ["VIC"],
        content: [
            {
                text:"Workplacement",
                link:"http://www.workplacements.education.vic.gov.au/",
                states:["VIC"]
            },
            {
                text:"Safe@Work Module",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/generalmodule.aspx",
                states:["VIC"]
            },
            {
                text:"PDF Student Guide",
                link:"https://www.education.vic.gov.au/Documents/school/students/beyond/printablestudentguide.pdf",
                states:["VIC"]
            },
            {
                text:"Safe@Work Industry Modules",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/industrymodule.aspx",
                states:["VIC"]
            },
            {
                text:"Safe@Work Self Assessment Test",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/quiz.aspx",
                states:["VIC"]
            },
            {
                text:"Safe@Work Review Module",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/reviewmodule.aspx",
                states:["VIC"]
            },
            {
                text:"Safe@Work Review Module PDF",
                link:"https://www.education.vic.gov.au/Documents/school/students/beyond/printablereviewmodule.pdf",
                states:["VIC"]
            },
            {
                text:"Workplace Hazards",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/workplacehazardfact.aspx",
                states:["VIC"]
            },
        ]
    },
    "Workplace Forms": {
        states: ["VIC"],
        content:[
            {
                text:"The Work Experience Arrangement Form",
                link:"https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/WEarrangefrmamended.pdf",
                states:["VIC"]
            },
            {
                text:"Work Experience Travel/AccommodationArrangements Form",
                link:"https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/WEtravaccfrmamended.pdf",
                states:["VIC"]
            },
            {
                text:"Workplace Hazards Facts Sheet",
                link:"https://www.education.vic.gov.au/school/students/beyond/Pages/workplacehazardfact.aspx",
                states:["VIC"]
            },
            {
                text:"The Structured Workplace Learning Arrangement Form",
                link:"https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/SWLarrangefrmamended.pdf",
                states:["VIC"]
            },
            {
                text:"The Structured Workplace Travel/Accommodation Arrangements Form",
                link:"https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/SWLtravaccfrmamended.pdf",
                states:["VIC"]
            },
            {
                text:"The Parent/Carers Guide to Work Experience",
                link:"https://www.education.vic.gov.au/Documents/school/students/beyond/wltworkexp.pdf",
                states:["VIC"]
            },
            {
                text:"Work Experience Guidelines for Employers",
                link:"https://www2.education.vic.gov.au/pal/work-experience/resources",
                states:["VIC"]
            },
            {
                text:"Child Safe Standards and Workplace Learning - A Fact Sheet for Employers",
                link:"https://www.education.vic.gov.au/PAL/structured-workplace-learning-child-safe-standards-and-workplace-learning-fact-sheet-for-employers.pdf",
                states:["VIC"]
            },
            {
                text:"Working With Animals - Summary of Student Experience",
                link:"https://www2.education.vic.gov.au/pal/work-experience/resources",
                states:["VIC"]
            },
        ]
    }

}

const getKeysForState = (state:string|undefined) => {
    const keys = Object.keys(LinkContent).filter(l => !state || LinkContent[l].states.includes(state))
    return _.chunk(keys, Math.ceil(keys.length / 3));
}

const getLinkListForKey = (key:string, state:string|undefined) => {
    const links = LinkContent[key].content;
    const filteredLinks = links.filter(l=>!state || l.states.includes(state));
    return _.sortBy(filteredLinks, l => l.text);
}


const UsefulLinks : React.FC<{userDetails?:UserDetails}> = ({userDetails}) => {
    const [currentUsersState, setCurrentUsersState] = React.useState(userDetails?.state);
    const [currentKeys, setCurrentKeys] = React.useState<string[][]>([]);
    React.useEffect(() => {
        setCurrentKeys(getKeysForState(currentUsersState));
    }, [currentUsersState]);
    return <ContentWrapper 
                title="Need to find something career related?"
                subtitle={["Here's a list of handy links"]}>
        <PageSectionRow>
            <Col className="text-center">
                <Button variant={!currentUsersState ? "active" : "neutral"} glowing={!currentUsersState} onClick={()=>setCurrentUsersState(undefined)}>
                    All
                </Button>
                <Button className="ml-8" variant={currentUsersState === "NSW" ? "active" : "neutral"} glowing={currentUsersState === "NSW"} onClick={()=>setCurrentUsersState("NSW")}>
                    NSW
                </Button>
                <Button className="ml-8" variant={currentUsersState === "VIC" ? "active" : "neutral"} glowing={currentUsersState === "VIC"} onClick={()=>setCurrentUsersState("VIC")}>
                    VIC
                </Button>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            {currentKeys.map(keys => 
                <Col md={4}>
                    {keys.map(key => 
                        <><h3 className="link-section-heading" {...{key}}>{key} {!currentUsersState && LinkContent[key].states.length === 1 && `(${LinkContent[key].states[0]})`}</h3>
                        <ul>
                            {getLinkListForKey(key, currentUsersState).map(l => 
                                <li key={l.link}><a target="_blank" href={l.link}>{l.text} {!currentUsersState && l.states.length === 1 && `(${l.states[0]})`}</a></li> 
                            )}
                        </ul></>
                    )}
                </Col>
            )}
        </PageSectionRow>
    </ContentWrapper>
} 

export default UsefulLinks;