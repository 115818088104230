import { TablerIcon } from "@tabler/icons";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { openLinkInNewTab } from "../../util";
import "./secondaryMenu.css";

type MenuItem = {
    name:string,
    icon:TablerIcon|JSX.Element,
    highlighted:boolean,
    borderTop:boolean,
    linkTo?:{link?:string, localLink?:string},
    subItems?:{name:string, link:string}[],
    pageKey:string
}

interface SecondaryMenuProps extends RouteComponentProps {
    title: string,
    secondaryLink : {name:string, link:string}[],
    menuItems: MenuItem[],
    activePage:string
}

const SecondaryMenuItem : React.FC<{item:MenuItem, active:string} & RouteComponentProps> = ({item, history, active}) => {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const onClick = React.useCallback(() => {
        if (item.subItems) {
            setShowDropdown(d => !d);
            return;
        }
        if (item.linkTo) {
            if (item.linkTo.localLink) {
                history.push(item.linkTo.localLink)
            } else if (item.linkTo.link) {
                openLinkInNewTab(item.linkTo.link);
            }
        }
    }, [item]);
    return <div onClick={onClick} className={[
            "jobjump-secondary-menu-item",
            item.highlighted?"jobjump-secondary-menu-highlight":"jobjump-secondary-menu-dull",
            item.borderTop?"jobjump-secondary-menu-progress":"",
            item.pageKey === active ? "active" : ""
        ].join(" ")}>
        {item.subItems && <div className={"jobjump-base-card jobjump-secondary-dropdown jobjump-secondary-dropdown-"+(showDropdown?"show":"hide")}>
            {item.subItems.map((s, i) => <div key={`${item.name}-${i}`} className="jobjump-secondary-dropdown-menu-item">
                <Link to={s.link}>{s.name}</Link>
            </div>)}
        </div>}
        <div className="jobjump-secondary-menu-button">
            <div className="jobjump-secondary-menu-icon">
                {item.icon}
            </div>
            <div className="jobjump-secondary-menu-text">{item.name}</div>
        </div>
    </div>
}

const SecondaryMenu : React.FC<SecondaryMenuProps> = ({title, secondaryLink, menuItems, activePage, ...props}) => 
    <div className="jobjump-secondary-menu">
        <div className="jobjump-secondary-menu-title-row">
            <div className="jobjump-secondary-menu-title">{title}</div>
            {secondaryLink.map((l, i)=> 
                <div key={`sec-${i}`} className="jobjump-secondary-menu-secondary"><Link to={l.link}>{l.name}</Link></div>
            )}
        </div>
        <div className="jobjump-secondary-menu-item-row">
            {menuItems.map(i => <SecondaryMenuItem active={activePage} item={i} key={i.name} {...props} />)}
        </div>
    </div>

export default SecondaryMenu;