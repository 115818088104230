import React from "react";
import {Row, Col} from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";
import ContentWrapper from "../components/ContentWrapper";
import YesNoQuiz, { QuestionsInput } from "../components/YesNoQuiz";

const questions : QuestionsInput[] = [
    {
        title: "Follow Safety Instructions",
        certificate: "Can follow safety instructions",
        correct: "If I'm told not to go into a particular room or area, I will follow that instruction.",
        incorrect: "If the boss tells me not to go somewhere, he's probably not really serious.",
        key: "instruction"
    },
    {
        title: "Safety Requirements",
        correct:"If my supervisor gives me instructions about my safety requirements, I will follow them.",
        incorrect:"I might listen to my supervisor giving me safety instructions, but if it gets the job done faster I'll just ignore them.",
        certificate: "Can follow workplace safety requirements",
        key: "requirements"
    },
    {
        title: "Safety of Others",
        correct: "I'm always careful about what I do and if it risks the safety of my co-workers.",
        incorrect: "I might think about the safety of others, but only if it doesn't put me out.",
        certificate: "Respects the safety of others in the workplace",
        key: "others"
    },
    {
        title: "Responsibility of Safety",
        certificate: "Takes responsibility for own health and safety",
        correct: "I take care of my own safety on the way to work, at work and on the way home.",
        incorrect:"It's someone else's job to look after my safety.",
        key: "ownSafety"
    },
    {
        title: "Use of Personal Protective Equipment",
        certificate: "Takes care of and approriately uses PPE",
        correct: "When protective equipment is provided to me, I will learn how to use it, care for it and use it approriately.",
        incorrect:"Protective equipment just gets in the way of me doing my job. If I'm given it, I'll just leave it somewhere.",
        key: "ppe"
    },
    {
        title: "Use of Hearing Protection",
        correct: "Hearing protection should be used as a temporary measure if the the noise cannot be controlled.",
        incorrect:"Hearing protection is used when it's hard to concentrate on a task.",
        key: "hearing"
    },
    {
        title: "Fixing a Hazard",
        certificate: "Approriately deals with hazards",
        correct: "The best way to fix a hazard is to remove it.",
        incorrect:"If you can use something to work around the hazard, that's better than removing it.",
        key: "hazard"
    },
    {
        title: "Workplace Health and Safety Law",
        certificate: "Understands who Workplace Safety Laws apply to",
        correct: "Workplace safety laws apply to everyone at a workplace.",
        incorrect:"Only the boss has to follow workplace safety laws.",
        key: "laws"
    },
    {
        title: "Common Workplace Hazards",
        certificate: "Can identify common workplace hazards",
        correct: "Common workplace hazards include: electrical hazards, slips and trips and excessive noise.",
        incorrect:"Common workplace hazards include: having too much work to do and people not showing up on time.",
        key: "commonHazards"
    },
    {
        title: "When To Use Personal Protective Equipment",
        correct: "Personal protective equipment be used if other methods of controlling a hazard are not practical, or if measures have not reduced the risk sufficiently.",
        incorrect:"Personal protective equipment should be the only thing you use to control a hazard.",
        key: "usePpe"
    },

]

const WHSQuiz : React.FC = () => {
    return <ContentWrapper title="WHS Quiz" >
        <PageSectionRow>
            <Col>
                <h3>Take this quick quiz to see if you're ready to start work safely.</h3>
                <p>Select the most approriate sentence for each of the categories below</p>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                <YesNoQuiz
                    questions={questions}
                    completedEventTag={"completeWHSQuiz"}
                    certificateTitle={"Certificate for WHS Skills"}
                    certificateSecondLine={"has achieved an understanding of the following WHS skills:"} />
        </Col>
        </Row>
    </ContentWrapper>
}

export default WHSQuiz;