import React from "react";
import { Link } from "react-router-dom";
import DecoratedRow, { DecorationType } from "../DecoratedRow";
import { Col } from "../grid";
import "./pageHero.css";

type BreadcrumbLocation = {name:string, link:string}

export interface PageHeroProps {
    decorationType?: DecorationType,
    title: string,
    subtitle?: string[],
    breadcrumbs: BreadcrumbLocation[] //Breadcrumbs exclude home
}

const PageHero : React.FC<PageHeroProps> = ({decorationType, title, subtitle, breadcrumbs, children}) =>
    <DecoratedRow decorationType={decorationType || "confetti"} className="mb-32 mt-64">
        <div className="text-center page-hero-wrapper">
            <span className="jobjump-breadcrumb">
                {[{name:"Home", link:"/"}].concat(breadcrumbs).map((b, i) => 
                    <><Link to={b.link}>{b.name}</Link>{i<(breadcrumbs.length) && <a> &gt; </a>}</>)}
            </span>
            {children}
            <h1 className="mt-32">{title}</h1>
            {(subtitle || []).map((s,i) => <h4 key={`subtitle-${i}`}>{s}</h4>)}
        </div>
    </DecoratedRow>

export default PageHero;