import React from "react";
import { RouteComponentProps } from "react-router";
import WorkCheckTemplate from "./WorkCheckSubpageTemplate";
import ModalVideo from "react-modal-video";

import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import ColdCalling from "../../assets/my_job_ads/cold-calling.jpg";
import Books from "../../assets/homepage/tiles/atar-choosing-hsc-subjects.jpg";
import Project from "../../assets/homepage/tiles/studying-at-tafe.jpg";
import Resume from "../../assets/homepage/tiles/my-resume-for-careers.jpg";
import SoftSkillsImg from "../../assets/homepage/tiles/getting-you-tax-file-number.jpg";
import CommonQuestions from "../../assets/my_job_ads/common-interview-question.jpg";
import MeetingEmployer from "../../assets/my_job_ads/meeting-employer.jpg";
import ExampleResume from "../../assets/my_job_ads/example-resume.jpg";
import MyCareer from "../../assets/school-homepage/my-career.png";
import FindMyCareer from "../../assets/homepage/tiles/find-my-career-tool.jpg";
import TileImageStore from "../../assets/JobJumpPageImages";
import { EventTracking } from "../../data/EventTracking";
import { openLinkInNewTab } from "../../util";
import WorkSafetyQuiz from "../../assets/homepage/tiles/work-safety-quiz.jpg"

const ColdCanvassingWorkCheck : React.FC<RouteComponentProps> = (props) => {
    const [showColdCalling, setShowColdCalling] = React.useState(false);
    return <>
        <WorkCheckTemplate
            title="Cold Canvassing"
            videoId="547069383"
            worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationColdCanvassing.pdf")}
            blurb={[`Approaching an employer looking for work?`, `Learn how to do it right.` ]}
            completionText={"watch the Cold Calling video and read through Calling A Boss"}
            links={[
                {text:"Cold Calling Video", onClick:()=>{ EventTracking.trackEvent("watchColdCalling", "");setShowColdCalling(true)}, imgUrl:ColdCalling},
                {text:"Calling A Boss", internalLink:"/onThePhone", imgUrl:CallingABoss}
            ]} {...props} />
        <ModalVideo 
            channel="youtube" 
            videoId="uiSYKFN1P5E" 
            isOpen={showColdCalling} 
            onClose={()=>setShowColdCalling(false)} /> 
    </>
}

const LiteracyAndNumeracyCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="Literacy and Numeracy"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationLiteracyandNumeracy.pdf")}
        videoId="547069462"
        completionText={"at least one of the quizzes"}
        blurb={[`Get familiar with the language and calcuations you need.`]}
        links={[
            {text:"Quizzes", internalLink:"/apprenticeships", imgUrl:Books}
        ]} {...props} />        

const EWorkExperienceCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="eWork Experience Project"
        videoId="547069993"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationeWorkExperience.pdf")}
        completionText={"upload your eWork Experience project"}
        blurb={[`Want to make a good impression with a future employer?`, `Do eWork Experience and show your career passion.` ]}
        links={[
            {text:"eWork Experience Projects", internalLink:"/eWorkExperience", imgUrl:Project}
        ]} {...props} />

const KnowingYourCareerCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="Know your Career"
        videoId="547069438"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationKnowingYourCareer.pdf")}
        blurb={[`What do you know about the career you are heading towards?` ]}
        completionText={"use My Career to watch a Career Video, or search your career in the Good Universities Careers Guide"}
        links={[
            {text:"My Career", internalLink:"/myCareer", imgUrl:MyCareer},
            {text:"Good Universities Careers Guide", onClick:()=>{
                EventTracking.trackEvent("goToGoodEduCareersGuide", "").then(() => {
                    openLinkInNewTab("https://www.gooduniversitiesguide.com.au/careers-guide")
                }); 
            }, imgUrl:FindMyCareer }
        ]} {...props} />

const EmploymentSkillsCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="Employment Skills"
        videoId="547069413"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationEmploymentSkills.pdf")}
        completionText={"complete the Employment Skills section of either the General Resume or in the Resume Builder"}
        blurb={[`Do you have the skills you need to get into your future career?` ]}
        links={[
            {text:"General Resume", internalLink:"/resume/unsure", imgUrl:ExampleResume},
            {text:"Resume Builder", internalLink:"/resume", imgUrl:Resume},
            {text:"Job Ads", internalLink:"/jobs", imgUrl:TileImageStore["SearchingForAJob"]},
        ]} {...props} />

const SoftSkillsCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="Soft Skills"
        videoId="547069514"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationSoftSkills.pdf")}
        completionText={"complete the Soft Skills quiz"}
        blurb={[`Will you settle in quickly to your future career?`, `Check you have the skills.` ]}
        links={[
            {text:"Soft Skills", internalLink:"/softSkills", imgUrl:SoftSkillsImg},
        ]} {...props} />

const ResumeCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="Resume"
        videoId="547069486"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationResumeBuilder.pdf")}
        blurb={[`Get started creating a resume to impress any employer.`
        ]}
        completionText={"complete a resume in the Resume Builder"}
        links={[
            {text:"Resume Builder", internalLink:"/resume", imgUrl:Resume},
        ]} {...props} />

const TheInterviewCheck : React.FC<RouteComponentProps> = (props) =>{
    const [showInterviewQuestions, setShowInterviewQuestions] = React.useState(false);
    const [showPreparing, setShowPreparing] = React.useState(false);
    return <><WorkCheckTemplate
        title="The Interview"
        worksheetLink={require("../../assets/docs/pre-work-check/WorkExperiencePreparationTheInterview.pdf")}
        videoId="547069541"
        blurb={[`What should you expect in a job interview?`, `How do I answer the most common questions?`]}
        completionText={"watch the following videos"}
        links={[
                {text:"Commonly Asked Questions", onClick:()=>{EventTracking.trackEvent("watchCommonQuestions", ""); setShowInterviewQuestions(true)}, imgUrl:CommonQuestions},
                {text:"Preparing For The Interview", onClick:()=>{EventTracking.trackEvent("watchInterviewPrep", ""); setShowPreparing(true)}, imgUrl:MeetingEmployer},
        ]} {...props} />
        <ModalVideo 
            channel="youtube" 
            videoId="OA8sZMkKuy0" 
            isOpen={showInterviewQuestions} 
            onClose={()=>setShowInterviewQuestions(false)} /> 
        <ModalVideo 
            channel="youtube" 
            videoId="Y6GRl0Sv1WY" 
            isOpen={showPreparing} 
            onClose={()=>setShowPreparing(false)} /> 
    </>
}

const WHSCheck : React.FC<RouteComponentProps> = (props) =>
    <WorkCheckTemplate
        title="WHS"
        blurb={["How do I make sure I work safely?"]}
        links={[{
            text:"WHS Quiz", internalLink:"/whsQuiz", imgUrl:WorkSafetyQuiz
        }]} {...props} />

export {ColdCanvassingWorkCheck, LiteracyAndNumeracyCheck, EWorkExperienceCheck, KnowingYourCareerCheck, EmploymentSkillsCheck, SoftSkillsCheck, ResumeCheck, TheInterviewCheck, WHSCheck}