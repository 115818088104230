import React from "react";
import {Col} from "../../components/grid";
import { Accordion, Card, AccordionToggle, AccordionCollapse} from "react-bootstrap";
import { CareerCategory, CareerNames } from "jobjump-types/Careers";
import Careers from "../../data/Careers";
import {SavedResume} from "jobjump-types/OldResume";
import Resume from "../../data/OldResume";
import CareerImages from "../../assets/CareerImages";
import { RouteChildrenProps } from "react-router";
import ImageCard from "../../components/cards/ImageCard";
import CardGrid from "../../components/CardGrid";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";

const ResumeSelector : React.FC<RouteChildrenProps> =
    ({history}) => {
        const [careerCategories, setCareerCategories] = React.useState<CareerCategory[]>([]);
        const [savedResumes, setSavedResumes] = React.useState<SavedResume[]>([]);
        const [selectedCategory, setSelectedCategory] = React.useState<null|CareerCategory>(null);

        React.useEffect(() => {
            Careers.getCareerCategories().then(setCareerCategories);
        }, []);

        React.useEffect(() => {
            Resume.getUserResumes().then(setSavedResumes);
        }, []);

        const makeResumeLink = (categoryID?:string, careerID?:string) => {
            let kind = "unsure";
            if (careerID) { 
                kind = "career"; 
            } else if (categoryID) {
                kind = "category";
            }
            return "/plan/"+([kind,categoryID,careerID].filter((c) => c !== undefined).join("/"));
        }

        return <ContentWrapper
                title="My Plan"
                subtitle={["Select a career category to get started, or load a saved plan."]} >
            <PageSectionRow>
                <Col className="text-center">
                    <Card>
                        <Accordion>
                            <AccordionToggle as={Card.Header} eventKey="0">
                                <h4>Saved Plan</h4>
                            </AccordionToggle>
                            <AccordionCollapse eventKey="0">
                                <Card.Body>
                                    <CardGrid prefix="saved-resumes" numPerRow={3}>
                                        {savedResumes.map(r =>
                                            <ImageCard 
                                                imageUrl={CareerImages[r.career_name.toUpperCase()] || require("../../assets/my_job_ads/make-resume-jj.jpg")}
                                                key={`${r.career_name}-saved-resume`}
                                                text={r.career_name === "Basic Resume" ? "General Plan" : r.career_name}
                                                onClick={()=> history.push(makeResumeLink(r.category_id, r.career_id))} />
                                        )}
                                    </CardGrid>
                                </Card.Body>
                            </AccordionCollapse>
                        </Accordion>
                    </Card>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                        {!selectedCategory ?
                            <CardGrid numPerRow={3} prefix="resume-category">
                                {careerCategories.map(c =>
                                    <ImageCard 
                                        imageUrl={CareerImages[c.name.toUpperCase()] || ""} 
                                        key={`${c.name}-resume`} 
                                        text={c.name}
                                        onClick={()=>setSelectedCategory(c)} />
                                )}
                                <ImageCard 
                                    imageUrl={require("../../assets/my_job_ads/make-resume-jj.jpg")}
                                    key="basic-resume"
                                    text="General Plan"
                                    onClick={()=>{history.push(makeResumeLink())}} />
                            </CardGrid>
                            :
                            <CardGrid numPerRow={3} prefix="resume-career">
                                {selectedCategory.careers
                                    .sort((a: CareerNames, b: CareerNames) => a.name > b.name ? 1 : -1)
                                    .map((c: CareerNames) =>
                                    <ImageCard 
                                        imageUrl={CareerImages[c.name.toUpperCase()] || ""} 
                                        key={`${c.name}-resume`} 
                                        text={c.name}
                                        onClick={()=>history.push(makeResumeLink(selectedCategory.id, String(c.id)))} />
                                    )}
                                    <ImageCard 
                                        imageUrl={CareerImages[selectedCategory.name.toUpperCase()] || ""}
                                        text={`General Plan - ${selectedCategory.name}`}
                                        onClick={()=>history.push(makeResumeLink(selectedCategory.id))} />
                            </CardGrid>
                        }
                </Col>
            </PageSectionRow>
        </ContentWrapper>;
    }

export default ResumeSelector;