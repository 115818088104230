import React, { useEffect } from "react";
import {Row, Col} from "../components/grid";
import {EarningsEntry} from "jobjump-types/Earnings";
import EarningsAPI from "../data/Earnings";
import FilterableGrid from "../components/FilterableGrid";
import "./earnings.css"
import PageSectionRow from "../components/PageSectionRow";
import ImageCard from "../components/cards/ImageCard";
import TFN from "../assets/homepage/tiles/getting-you-tax-file-number.jpg"
import Appr from "../assets/homepage/tiles/traineeships-and-aprenticeships.jpg"
import Haneds from "../assets/hands.png";
import Modal from "../components/Modal";
import ContentWrapper from "../components/ContentWrapper";

const Earnings : React.FC<{}> =
    () => {
        const [chosenEarnings, setChosenEarnings] = React.useState<EarningsEntry|null>(null);
        const [showEarnings, setShowEarnings] = React.useState(false);
        const [earnings, setEarnings] = React.useState<EarningsEntry[]>([]);
        useEffect(() => {
            EarningsAPI.getEarnings().then((d) => {setEarnings(d)});
        }, []);
        return (
            <ContentWrapper
                    title="What Will I Earn?"
                    breadcrumbTitle="Earnings"
                    subtitle={["Want to know how much you'll earn? Choose your career below to see the average earnings, from junior to senior positions across Australia" ]} >
                <PageSectionRow>
                    <Col>
                        <h2>Some Words of Advice</h2>
                        <h5>Consider the following words of advice. If you follow these tips you will find a great career.</h5>
                    </Col>
                </PageSectionRow>
                <Row>
                    <Col md={4}>
                        <ImageCard
                            imageUrl={TFN}
                            text="Choose a job you love and you'll never have to work a day in your life."
                        />
                    </Col>
                    <Col md={4}>
                        <ImageCard
                            imageUrl={Haneds}
                            text="There is no greater satisfaction than knowing when your job has helped somebody else."
                        />
                     </Col>
                    <Col md={4}>
                        <ImageCard
                            imageUrl={Appr}
                            text="Find a career that uses your skills. If you choose a high paying job that doesn't suit you, you will end up doing poor quality work. As a result you'll get less business and less money."
                        />
                     </Col>
                </Row>
                <PageSectionRow><Col>
                    <h2>Job Earnings By Career</h2>
                </Col></PageSectionRow>
                <Row><Col>
                    <FilterableGrid
                        numPerRow={4} 
                        data={earnings.map(e => ({
                            text:e.name,
                            onClick:() => {
                                setChosenEarnings(e);
                                setShowEarnings(true);
                            }
                        }))}
                    />
                </Col></Row>
                <Modal size="sm" show={showEarnings} onHide={() => setShowEarnings(false)} title={chosenEarnings ? chosenEarnings.name : ""}>
                    <Row>
                        <Col>
                            <h3>Weekly: ${chosenEarnings? Math.round(chosenEarnings.weekly) : ""}</h3>
                            <h3>Yearly: ${chosenEarnings? Math.round(chosenEarnings.yearly) : ""}</h3>
                            <p>Source: ABS Statistics</p>
                            <p>Figures above are an average across Australia from junior to senior workers.</p>
                        </Col>
                    </Row>
                </Modal>
            </ContentWrapper>
        )
    }

export default Earnings;