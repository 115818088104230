import {TafeCategory, TafeCourseEntry, TafeCourseOffering} from "jobjump-types/TAFE";
import { fetchWithAuth } from "./Utils";

const filterOfferingList = (l:TafeCourseOffering[]) => {
    const now = new Date(Date.now());
    return Object.values(l.reduce((a, o) => {
        const inPast = new Date(o.startDate).getMilliseconds() < now.getMilliseconds();
        const key = inPast ? o.mode : `${o.mode}-${o.startDate}`;
        if (key in a) {
            return {
                ...a,
                [key] : new Date(a[key].startDate).getMilliseconds() > new Date(o.startDate).getMilliseconds() ? a[key] : o
            }
        }
        return {
            ...a,
            [key]:o
        }
    }, {} as {[k:string]:TafeCourseOffering}));    
}

const TafeAPI = {
    getTafeCategories : () : Promise<TafeCategory[]> => 
        fetchWithAuth("/api/tafe/categories").then(r => r.json()).catch(console.log),
    getTafeCourses : (category:string) : Promise<TafeCourseEntry[]> => 
        fetchWithAuth(`/api/tafe/courses/${category}`).then(r=>r.json()),
    getTafeCourseSubcategories : (category:string) : Promise<{name:string}[]> =>
        fetchWithAuth(`/api/tafe/subcategories/${category}`).then(r=>r.json()),
};

export default TafeAPI;
export {filterOfferingList};