import React from "react";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import { Col, Row } from "../components/grid";
import DestinationSurveyAPI from "../data/DestinationSurvey";
import PageSectionRow from "../components/PageSectionRow";
import Button from "../components/Button";
import ContentWrapper from "../components/ContentWrapper";

const datesToSend = [
    "29th January",
    "12th February",
    "26th February"
];

const SetDestinationSurvey : React.FC<RouteComponentProps> = (props) => {
    const {school, token} = qs.parse(props.location.search)
    const [optionPicked, setOptionPicked] = React.useState<number|null>(null);
    React.useEffect(() => {
        if (typeof school === "string" && typeof token === "string") {
            DestinationSurveyAPI.getCurrentFrequency(school, token).then(({numSends})=>setOptionPicked(numSends));        
        }
    })
    const pickDate = (numSends:number) => {
        if (typeof school === "string" && typeof token === "string") {
            DestinationSurveyAPI.submitSendingFrequency(school, token, numSends).then(()=>setOptionPicked(numSends));
        }
    }
    return <ContentWrapper title="School Destination Survey Setup" >
        {optionPicked === null && <PageSectionRow>
            <Col className="text-center">
                <h3><strong>The dates on which I would like my schools destination survey to be sent. Select one option below:</strong></h3>
            </Col>
        </PageSectionRow>}
        {optionPicked === null && <Row className="mb-32">
            <Col className="text-center">
                <Button variant="neutral" onClick={()=>pickDate(3)}>
                    {datesToSend[0]}, {datesToSend[1]} and {datesToSend[2]}
                </Button>
                <h4 className="mr-16 ml-16" style={{display:"inline-block"}}>Or</h4>
                <Button variant="neutral" onClick={()=>pickDate(2)}>
                    {datesToSend[0]} and {datesToSend[1]}
                </Button>
                <h4 className="mr-16 ml-16" style={{display:"inline-block"}}>Or</h4>
                <Button variant="neutral" onClick={()=>pickDate(1)}>
                    {datesToSend[0]} ONLY
                </Button>
            </Col>
        </Row>}
        {optionPicked !== null && <Row className="mt-64">
            <Col className="text-center">
                <h3><strong>Success! Destination survey has been set up for your school.</strong></h3>
                {optionPicked === 3 && <h3>Your destination survey will be sent on {datesToSend[0]}, {datesToSend[1]} and {datesToSend[2]}.</h3>}
                {optionPicked === 2 && <h3>Your destination survey will be sent on {datesToSend[0]} and {datesToSend[1]}.</h3>}
                {optionPicked === 1 && <h3>Your destination survey will be sent on {datesToSend[0]} only.</h3>}
                <h3>Student responses will automatically appear in the Destination Survey section of your Careers Advisers Dashboard.</h3>
            </Col>
        </Row>}
        <Row className="mt-4">
            <Col className="text-center">
                <h3><strong><a href="https://vimeo.com/643127007">Click Here</a> to see how to make use of the destination survey.</strong></h3>
            </Col>
        </Row>
    </ContentWrapper>
}

export default SetDestinationSurvey;