const UniLogos = {
    "ACU": require("./uni-logos/acu.png"),
    "ADFA": require("./uni-logos/adfa.png"),
    "ANU": require("./uni-logos/anu.png"),
    "CIT": require("./uni-logos/CIT.jpg"),
    "CQ": require("./uni-logos/cq.png"),
    "CSU": require("./uni-logos/csu.png"),
    "Deakin": require("./uni-logos/deakin.jpg"),
    "Federation": require("./uni-logos/federationuni.png"),
    "Griffith": require("./uni-logos/griffith.png"),
    "LaTrobe": require("./uni-logos/latrobe.png"),
    "Melbourne": require("./uni-logos/melbourneuni.png"),
    "MIT": require("./uni-logos/mit.png"),
    "Monash": require("./uni-logos/monash.png"),
    "Mq": require("./uni-logos/mq.png"),
    "NAS": require("./uni-logos/nas.png"),
    "Newcastle": require("./uni-logos/newcastle.png"),
    "NotreDame": require("./uni-logos/notre-dame.png"),
    "RMIT": require("./uni-logos/rmit.png"),
    "SouthernCross": require("./uni-logos/southern-cross.png"),
    "Swinburne": require("./uni-logos/swinburne.png"),
    "UNE": require("./uni-logos/une.png"),
    "UNSW": require("./uni-logos/unsw.png"),
    "Canberra": require("./uni-logos/uoc.png"),
    "Wollongong": require("./uni-logos/uow.png"),
    "USYD": require("./uni-logos/usyd.png"),
    "UTAS": require("./uni-logos/utas.svg"),
    "UTS": require("./uni-logos/uts.png"),
    "Victoria": require("./uni-logos/victoriauni.png"),
    "WSU": require("./uni-logos/wsu.png"),
    "BendigoTAFE": require("./uni-logos/bendigo-tafe.jpg"),
    "BoxHillTAFE": require("./uni-logos/box-hill-tafe.png"),
    "ChisholmTAFE": require("./uni-logos/chisholm-tafe.png"),
    "GippslandTAFE": require("./uni-logos/gippsland-tafe.png"),
    "HolmseglenTAFE": require("./uni-logos/holmesglen-tafe.png"),
    "MelbourenPoly": require("./uni-logos/mel-polytech.jpg"),
    "SuniTAFE": require("./uni-logos/suni-tafe.png"),
    "TheGordon": require("./uni-logos/the-gordon.jpg"),
    "TAFESouthWestVic": require("./uni-logos/vic-tafe-south-west.png"),
    "VictoriaPoly": require("./uni-logos/vu-ploytech.png"),
    "WilAngliss": require("./uni-logos/wil-angliss.jpg"),
    "WodongaTAFE": require("./uni-logos/wodonga-tafe.jpg"),
    "Kangan": require("./uni-logos/kangan.jpg"),
    "NESA": require("./uni-logos/nesa.png"),
    "UAC": require("./uni-logos/uac.png"),
    "Adelaide": require("./uni-logos/adelaide.png"),
    "AMC": require("./uni-logos/amc.png"),
    "Bond": require("./uni-logos/bond.jpeg"),
    "CharlesDarwin": require("./uni-logos/charles_darwin.png"),
    "CHC": require("./uni-logos/chc.png"),
    "Curtin": require("./uni-logos/curtin.png"),
    "Edith": require("./uni-logos/edith.png"),
    "EIT": require("./uni-logos/eit.webp"),
    "Flinders": require("./uni-logos/flinders.png"),
    "JamesCook": require("./uni-logos/jamesCook.png"),
    "Murdoch": require("./uni-logos/murdoch.png"),
    "UQLD": require("./uni-logos/qld.png"),
    "QUT": require("./uni-logos/qut.jpg"),
    "USA": require("./uni-logos/sa.png"),
    "SunshinCoast": require("./uni-logos/sunshine-coast.png"),
    "Torrens": require("./uni-logos/torrens.png"),
    "USQ": require("./uni-logos/unisq-logo.svg"),
    "UWA": require("./uni-logos/wa.png"),
}

export default UniLogos;