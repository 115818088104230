import { IconBulb, IconEye, IconEyeOff } from "@tabler/icons";
import arrayMove from "array-move";
import { Skill } from "jobjump-types/Resume";
import React from "react";
import { SortableElement, SortableContainer } from "react-sortable-hoc";
import { Label, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { SubsectionProps, SubsectionTemplate,  deleteFromList, updateObjectIndex, SortableItemTemplate } from "./common";

type SortableListItems = Skill & {
    originalOrder: number
};

const SortableItem = SortableElement(
    ({ data, onChange, onDelete }: {data:SortableListItems, onChange:(t:Skill)=>void, onDelete:()=>void}) => {
        return <SortableItemTemplate 
            onDelete={data.skillSource === "user" ? onDelete : ()=>onChange({...data, hidden:!data.hidden})}
            AlternativeDeleteIcon={data.skillSource === "career" ? 
                ({onClick})=><>{data.hidden ? <IconEyeOff onClick={onClick} /> : <IconEye onClick={onClick} />}</> 
            : undefined}>

            <Row className={"mt-8"+((data.hidden===true)?" hidden-resume-section":"")}>
                <Col sm={4}>
                    {data.skillSource === "career" ?
                        `${data.title}` :
                        <TextField onChange={v=>onChange({...data, title:v})} value={data.title} />
                    }
                </Col>
                <Col>
                    <TextField value={data.details} onChange={v=>onChange({...data, details:v})} />
                </Col>
            </Row>
        </SortableItemTemplate>
    }
)

const SortableList = SortableContainer(
    (props:{data:SortableListItems[], section:Skill[], setSection:(r:Skill[])=>void}) => <div>
        {props.data.map((t: SortableListItems, i: number) => {
            return <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                <SortableItem 
                    index={i} 
                    data={t} 
                    onChange={(t)=>props.setSection(updateObjectIndex(props.section, t, i))} 
                    onDelete={()=>props.setSection(deleteFromList(props.section, i))} />
            </div>
        })}
    </div>
);

const SkillsSection : React.FC<SubsectionProps<"skills">> = ({section, content, setSection, sectionConfig, setSectionConfig}) =>
    <SubsectionTemplate title="My Skills"
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}
        buttons={[
            {text:"Skill", onClick:()=>setSection([...section, {title:"", skillSource:"user"}])}
        ]}>
            <Row style={{paddingLeft:"56px", paddingRight:"56px"}}>
                <Col sm={4}>
                    <Label>Skill</Label>
                </Col>
                <Col>
                    <Label>Example</Label>
                </Col>
            </Row>
            <SortableList
                axis="xy"
                data={section.map((s,i) => ({...s, originalOrder:i}))}
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(section, oldIndex, newIndex);
                    setSection(order);
                }} 
                section={section}
                setSection={setSection} />  
            <div className="mt-16">
                <small><IconBulb /> If you find a job you want to apply for, add the skills listed in the job ad here using the "Skills" button below.</small>
            </div>

    </SubsectionTemplate>

export default SkillsSection;