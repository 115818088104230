import React from "react";
import { Form, Button } from "react-bootstrap";
import { ResumeFormChildrenProps, YesNoComponent, ShowCareerInfoDetails } from "./ResumeCommonTypes";
import { CareerText } from "jobjump-types/Careers";

const CareerGoalsPlanSection : React.FC<ResumeFormChildrenProps> =
    ({ generalResume, resumeKind, careerResume, updateGeneralResume, updateCareerResume, careerInfo }) => {
        return <Form>
            <h5>Year you wish to leave school:</h5>
            <Form.Control id="#leavingYear" as="select"
                value={generalResume.generic_goals.leaving_year}
                onChange={(e: React.FormEvent<HTMLSelectElement>) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_goals: {
                            ...generalResume.generic_goals,
                            leaving_year: e.currentTarget.value
                        }
                    })
                }
            >
                <option value="">---</option>
                <option value="10">Year 10</option>
                <option value="11">Year 11</option>
                <option value="12">Year 12</option>
            </Form.Control>
            {careerInfo && resumeKind === "career" ? <>
                <p>Have you researched this career by doing the following?</p>
                <h5>I have watched a video for this career:</h5>
                <YesNoComponent
                    name="watch-video"
                    value={careerResume.career_goals.video}
                    onChange={(video) => {
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                video
                            }
                        })
                    }} />
                {careerInfo.careerLinks.filter(f => f.kind === "video")
                    .map(f => <p>You can watch a video <a href={f.url} rel="noopener noreferrer" target="_blank">here</a></p>)[0]
                    || <></>}
                <h5>I have done work experience on this career:</h5>
                <YesNoComponent
                    name="work-experience"
                    value={careerResume.career_goals.work_exp}
                    onChange={(work_exp) => {
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                work_exp
                            }
                        })
                    }} />
                <ShowCareerInfoDetails
                    toggleSection={({onToggle}) =>
                        <><p>If no, you could do work experience in your spare time. You may need to talk to your parents to set this up.</p>
                        <Button className="mb-2" onClick={onToggle} variant="outline-secondary">See Work Experience Information</Button></>
                    }>
                    <p>
                        If you are not in school and the Boss needs Insurance Coverage, then say you can arrange 
                        it by contacting Work Health Options (WHO - previously I.C. Frith). 
                        <a rel="noopener noreferrer" target="_blank" href="http://www.workhealthoptions.com.au/services/broking-services/work-experience">Click Here</a>
                        for details. Don't forget to ask for a reference from the Boss at the end of the Work Experience. 
                        You will need to show it to future Bosses when seeking employment.
                    </p>
                    <p>
                        Another useful tip to remember is that most courses at TAFE, University or a 
                        private college will include work experience. That way you get the training 
                        you need and the experience all together. Check out the courses you should do below.
                    </p>
                </ShowCareerInfoDetails>
                <h5>I have made something on this career (e.g. hobby project):</h5>
                <YesNoComponent
                    name="project"
                    value={careerResume.career_goals.project}
                    onChange={(project) => {
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                project
                            }
                        })
                    }} />
                {(careerInfo.careerText.find(s=>s.kind==="project") !== undefined) &&
                    <ShowCareerInfoDetails
                        toggleSection={({onToggle})=>
                            <p>You may find this link useful: <Button variant="outline-secondary" onClick={onToggle}>Click here</Button></p>
                        }
                    >
                        <p>Employers really like to see someone who has shown real passion and interest in their chosen career. 
                            Here are some examples of ways you could prove this.</p>
                        <span dangerouslySetInnerHTML={{__html:(careerInfo.careerText.find(s=>s.kind==="project") as CareerText).content}}></span>  
                    </ShowCareerInfoDetails>
                }
                <h5>Do you know the employment prospects for this career?</h5>
                <p>Click the link(s) below to see the current job listings. This is a good indicator of the demand for this career.</p>
                <p>To see a list of jobs available around you:</p>
                <ul>
                    {careerInfo.careerLinks.filter(s => s.kind === "job")
                        .map((s, i) => <li key={i}><a href={s.url} rel="noopener noreferrer" target="_blank">Click Here</a></li>)}
                </ul>
                {careerInfo.careerLinks.find(s=>s.kind==="capital_city_jobs") !== undefined &&
                    <p>If you cannot find jobs around you, you may need to look for work in the Sydney metropolitan area:</p> 
                }
                {careerInfo.careerLinks.filter(s => s.kind === "capital_city_jobs").map((s,i)=> 
                    <li key={i}><a href={s.url} rel="noopener noreferrer" target="_blank">Click Here</a></li>
                )}
                <p>Now, select what you think the prospects are based on the job listings:</p>
                <Form.Check inline type="radio" name="prospects" value="high" label="High (more than 100 listings)"
                    checked={careerResume.career_goals.prospect === "high"} onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                prospect: e.currentTarget.value
                            }
                        })
                    } />
                <Form.Check inline type="radio" name="prospects" value="medium" label="Medium (between 50 and 100 listings)"
                    checked={careerResume.career_goals.prospect === "medium"} onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                prospect: e.currentTarget.value
                            }
                        })
                    } />
                <Form.Check inline type="radio" name="prospects" value="low" label="Low (below 50 listings)"
                    checked={careerResume.career_goals.prospect === "low"} onChange={(e: React.FormEvent<HTMLInputElement>) =>
                        updateCareerResume({
                            ...careerResume,
                            career_goals: {
                                ...careerResume.career_goals,
                                prospect: e.currentTarget.value
                            }
                        })
                    } />
                <h5>Do you need a USI?</h5>
                <YesNoComponent name="usi" value={generalResume.generic_goals.have_usi} onChange={(have_usi) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_goals: {
                            ...generalResume.generic_goals,
                            have_usi
                        }
                    })
                } />
                <p>You may find this link useful: <a href="https://www.usi.gov.au/students" rel="noopener noreferrer" target="_blank">Click Here</a> (If you aren't sure, ask you Careers Adviser)</p>
                <h5>Do you need a TFN?</h5>
                <YesNoComponent name="tfn" value={generalResume.generic_goals.have_tfn} onChange={(have_tfn) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_goals: {
                            ...generalResume.generic_goals,
                            have_tfn
                        }
                    })
                } />
                <p>You may find this link useful: <a href="https://smarteform.com.au/onlineforms/ato/tax-file-number-application-or-enquiry-for-individuals/" rel="noopener noreferrer" target="_blank">Click Here</a> (If you aren't sure, ask you Careers Adviser)</p>
                <h5>Would you like to do an RSA (Responsible Service of Alcohol) and RCG (Responsible Conduct of Gambling) course? When you're over 18, this may be useful for hospitality jobs:</h5>
                <YesNoComponent name="rsa" value={careerResume.career_goals.rsa} onChange={(rsa) =>
                    updateCareerResume({
                        ...careerResume,
                        career_goals: { ...careerResume.career_goals, rsa }
                    })
                } />
                {careerInfo.specificQuestions.find(s => s === "white-card") ?
                    <>
                        <h5>For you chosen career, you should have a White Card. Would you like a white card?</h5>
                        <YesNoComponent name="white-card" value={careerResume.career_goals.white_card} onChange={(white_card) =>
                            updateCareerResume({
                                ...careerResume,
                                career_goals: { ...careerResume.career_goals, white_card }
                            })
                        } />
                    </>
                    : <></>}
                {careerInfo.specificQuestions.find(s => s === "first-aid") ?
                    <>
                        <h5>For you chosen career, you should have completed a First Aid course. Would you like a First Aid certificate?</h5>
                        <YesNoComponent name="first-aid" value={careerResume.career_goals.first_aid} onChange={(first_aid) =>
                            updateCareerResume({
                                ...careerResume,
                                career_goals: { ...careerResume.career_goals, first_aid }
                            })
                        } />
                        <p>You may find these links useful:
                        <a href="http://stjohn.org.au/first-aid-training" rel="noopener noreferrer" target="_blank">St John First Aid</a>
                            and <a href="https://redcross.kineoportal.com.au/" rel="noopener noreferrer" target="_blank">Red Cross First Aid</a></p>
                    </>
                    : <></>}
                {careerInfo.specificQuestions.find(s => s === "umat") ?
                    <>
                        <h5> For you chosen career, you must register to sit the UCAT exam. Would you like to sit for the UCAT test?</h5>
                        <YesNoComponent name="umat" value={careerResume.career_goals.umat} onChange={(umat) =>
                            updateCareerResume({
                                ...careerResume,
                                career_goals: { ...careerResume.career_goals, umat }
                            })
                        } />
                        <p>You may find the following link useful: <a href="https://www.ucat.edu.au/" rel="noopener noreferrer" target="_blank">Click Here</a></p>
                    </>
                    : <></>}
            </> : <></>}
        </Form>
    }

export default CareerGoalsPlanSection;