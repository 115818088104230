import React from "react";
import { Row, Col } from "../components/grid";
import ImageCard from "./cards/ImageCard";
import CardGrid from "./CardGrid";
import { Select, TextField } from "./Form";

interface FilterableGridData {
    imgUrl?:string,
    text:string,
    subcategories?:string[],
    onClick:()=>void,
    extraClass?:string
}

interface FilterableGridProps {
    data : FilterableGridData[]
    numPerRow : number,
    categories?: string[],
    noun? : string,
    hideHeading?:boolean,
    hideSearch?:boolean
    hideText?:boolean
}

const FilterableGrid : React.FC<FilterableGridProps> =
    ({data, numPerRow, noun, hideHeading, hideSearch, hideText, categories}) => {
        const [searchTerm, setSearchTerm] = React.useState("");
        const [filteredData, setFilteredData] = React.useState(data);
        const [selectedFilterCategories, setSelectedFilterCategories] = React.useState<string>();
        React.useEffect(() => {
            setFilteredData(data.filter(d => {
                if (categories && d.subcategories && selectedFilterCategories) {
                    console.log(d);
                    return d.subcategories.find(s => s.toUpperCase().match(`.*${selectedFilterCategories.toUpperCase()}.*`)) !== undefined || 
                        d.text.toUpperCase().match(`.*${selectedFilterCategories.toUpperCase()}.*`);
                }
                if (categories && selectedFilterCategories && !d.subcategories) {
                        return d.text.toUpperCase().match(`.*${selectedFilterCategories.toUpperCase()}.*`);
                }
                return d.text.toUpperCase().match(`.*${searchTerm.toUpperCase()}.*`);
            }))
        }, [data, searchTerm, selectedFilterCategories])
        return <>
            {categories && !hideSearch && <Row>
                <Col className="text-center">
                    {!hideHeading && <h4>Search your {noun || "career"} below</h4>}
                    <Select
                        onChange={(value, action)=> (console.log(action), setSelectedFilterCategories(value && value.value !== "" ? value.value : undefined))}
                        options={[{label:"All", value:""}].concat(categories.map(c=>({label:c, value:c})))} />
                </Col>
            </Row>}
            {!categories && !hideSearch && <Row>
                <Col>
                    {!hideHeading && <h4>Type in your {noun || "career"} below</h4>}
                    <TextField placeholder="Search..." value={searchTerm} onChange={setSearchTerm} />
                </Col>
            </Row>}
            <Row>
                <Col>
                    <CardGrid numPerRow={numPerRow} prefix="filterable-grid">
                        {filteredData.map((d, i) =>
                            <ImageCard className={d.extraClass} key={`fiilter-tile-${i}`} imageUrl={d.imgUrl} text={hideText ? undefined : d.text} onClick={d.onClick} /> 
                        )}
                    </CardGrid>
                </Col>
            </Row>
        </>
    }

export default FilterableGrid;