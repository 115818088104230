import { IconDiamond, IconHeart } from "@tabler/icons";
import { CwizResponse } from "jobjump-types/Cwiz";
import React from "react";
import { GenericImages } from "../../assets/GenericImages";
import TileImageStore from "../../assets/JobJumpPageImages";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import CardButton from "../../components/cards/CardButton";
import ImageCard from "../../components/cards/ImageCard";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { CareerPreference } from "../../data/CareerPreference";
import { CwizResult } from "../../data/Cwiz";
import { CwizProps, CwizStates, initalResults } from "./common";

type MainProps = {
    currentCategory?:string,
    currentSubject?:string,
    currentPersonality?:string,
    currentTertiary?:string,
    results:CwizResult[],
    careerPrefs:CareerPreference[],
    updateResponse: (r:CwizResponse, pageToGoTo?:CwizStates)=>void,
}

const CwizMain : React.FC<CwizProps & MainProps> = ({response, currentCategory, currentSubject, currentPersonality, currentTertiary, results, careerPrefs, updateResponse, goToPage}) => {
    const [threeCount, setThreeCount] = React.useState(0);
    const [twoCount, setTwoCount] = React.useState(0);
    const [oneCount, setOneCount] = React.useState(0);
    React.useEffect(() => {
        setThreeCount(results.filter(r => r.count === 3).length);
        setTwoCount(results.filter(r => r.count === 2).length);
        setOneCount(results.filter(r => r.count === 1).length);
    }, [results]);
    return <>{/*<Row verySmall className="mt-8">
        <Col className="text-center">
            <h3>Your Career Score</h3>
            <Row>
                <Col sm={4}><IconDiamond className="cwiz-svg" /><IconDiamond className="cwiz-svg" /></Col>
                <Col>{threeCount} Very Strong Choices</Col>
            </Row>
            <Row>
                <Col sm={4}><IconDiamond className="cwiz-svg" /><IconDiamond className="cwiz-svg" /></Col>
                <Col>{twoCount} Strong Choices</Col>
            </Row>
            <Row>
                <Col sm={4}><IconDiamond className="cwiz-svg" /></Col>
                <Col>{oneCount} Great Choices</Col>
            </Row>
        </Col>
    </Row>*/}
    {results.length > 0 && <Row small>
        <Col className="text-center"><h4>My Score:</h4></Col>
        <Col className="text-center score-col"><IconDiamond size={32} className="cwiz-svg" /><IconDiamond size={32} className="cwiz-svg" /><IconDiamond size={32} className="cwiz-svg" /> {threeCount}</Col>
        <Col className="text-center score-col"><IconDiamond size={32} className="cwiz-svg" /><IconDiamond size={32} className="cwiz-svg" /> {twoCount}</Col>
        <Col className="text-center score-col"><IconDiamond size={32} className="cwiz-svg" /> {oneCount}</Col>
    </Row>}
    {results.length > 0 && <Row small>
        <Col className="text-center"><h4><Button textButton variant="active" onClick={()=>goToPage("results")}>See My Results</Button></h4></Col>
    </Row>}
    <Row small>
        <Col className="text-center mt-32">
            {results.length === 0 && <h3>Select an option below</h3>}
            {results.length > 0 && <h3>Keep going to get more <IconDiamond className="cwiz-svg" size={48} /></h3>}
        </Col>
    </Row>
    <PageSectionRow>
        <Col>
            <CardGrid prefix="main-select" numPerRow={3}>
                <ImageCard
                    imageUrl={GenericImages["Collaboration"]}
                    className={"cwiz-option-card"+(currentCategory ? " completed-quiz-tile" : "")}
                    text="My Skills & Values"
                    onClick={()=>goToPage("categories")}>
                    {currentCategory && <div>Current Selection: {currentCategory}</div>}
                </ImageCard>
                <ImageCard
                    imageUrl={require("../../assets/personality.png")}
                    className={"cwiz-option-card"+(currentPersonality ? " completed-quiz-tile" : "")}
                    text="My Personality"
                    onClick={()=>goToPage("personality")}>
                    {currentPersonality && <div>Current Selection: {currentPersonality}</div>}
                </ImageCard>
                <ImageCard
                    imageUrl={TileImageStore["AtarChoosingHscSubjects"]}
                    className={"cwiz-option-card"+(currentSubject ? " completed-quiz-tile" : "")}
                    text="My Subjects"
                    onClick={()=>goToPage("subjects")}>
                    {currentSubject && <div>Current Selection: {currentSubject}</div>}
                </ImageCard>
            </CardGrid>
        </Col>
    </PageSectionRow>
    <Row small>
        <Col className="mt-16 text-center">
            <strong><Button textButton variant="active" onClick={()=>updateResponse(initalResults, "home")}><h4 style={{color:"var(--primary-color)"}}>Reset My Choices</h4></Button></strong>
        </Col>
    </Row>
    <Row small>
        <Col className="mt-16 text-center">
            {currentTertiary && <h4>Current Tertiary Level: {currentTertiary} {response.tertiary && <Button textButton className="ml-8" variant="active" onClick={()=>goToPage("tertiary")}>Change</Button>}</h4>}
        </Col>
    </Row> 
    {results.length > 0 && <Row small>
        <Col className="mt-32">
            <CardButton variant="active" className="text-center" onClick={()=>goToPage("results")}>
                <h4>See My Results</h4>
            </CardButton>
        </Col>
    </Row>}
    {careerPrefs.length > 0 && <Row small>
        <Col className="mt-16">
            <CardButton variant="active" className="text-center" onClick={()=>goToPage("prefs")}>
                <h4>See My <IconHeart className="fill-icon" /> Careers</h4>
            </CardButton>
        </Col>
    </Row>}
    </>
}

export default CwizMain;