import React from "react";
import UniLogos from "../../assets/UniLogos";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import ContentWrapper from "../../components/ContentWrapper";
import Select from "../../components/Form/Select";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { openLinkInNewTab } from "../../util";

type TableData = {
    kind:string,
    date?: Date,
    title:string,
    url:string,
    image:string
}

const data : TableData[] = [
    /*{
        kind: "UAC Applications",
        date: new Date("2021-04-01"),
        title: "Applications Open. Pins issued.",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },*/
    {
        kind: "Early Entry",
        date: new Date("2022-05-22"),
        title: "ANU Direct Application",
        url:"https://www.anu.edu.au/study/apply/domestic-applications-anu-undergraduate",
        image: UniLogos.ANU
    },
    {
        kind: "Early Entry",
        date: new Date("2022-06-12"),
        title: "UNSW Gateway Program",
        url:"https://www.futurestudents.unsw.edu.au/gateway-winter-program-year-12",
        image: UniLogos.UNSW
    },
    {
        kind: "Early Entry",
        title: "University of Wollongong Early Entry",
        url:"https://www.uow.edu.au/early-admission/",
        image: UniLogos.Wollongong
    },
    {
        kind: "Early Entry",
        title: "University of Western Sydney True Reward First Round Deadline",
        url:"https://www.westernsydney.edu.au/future/study/application-pathways/hsc-true-reward",
        image: UniLogos.WSU
    },
    {
        kind: "Early Entry",
        title: "Macquarie Leaders and Achievers",
        url:"https://www.mq.edu.au/study/admissions/entry-requirements/domestic/macquarie-entry/macquarie-leaders-and-achievers",
        image: UniLogos.Mq
    },
    /*{
        kind: "HSC",
        date: new Date("2021-08-21"),
        title: "HSC languages oral examinations begin",
        url:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/key-dates-exam-timetables/hsc-languages-oral-examinations",
        image: UniLogos.NESA
    },*/
    {
        kind: "Early Entry",
        date: new Date("2022-03-31"),
        title: "CSU Advantage Round 1",
        url:"https://study.csu.edu.au/study-options/pathways/charles-sturt-advantage",
        image: UniLogos.CSU
    },
    {
        kind: "Early Entry",
        date: new Date("2022-06-24"),
        title: "CSU Advantage Round 2",
        url:"https://study.csu.edu.au/study-options/pathways/charles-sturt-advantage",
        image: UniLogos.CSU
    },
    {
        kind: "Early Entry",
        date: new Date("2022-08-31"),
        title: "CSU Advantage Round 3",
        url:"https://study.csu.edu.au/study-options/pathways/charles-sturt-advantage",
        image: UniLogos.CSU
    },
    {
        kind: "Early Entry",
        title: "Southern Cross University STAR",
        url:"https://www.scu.edu.au/star-early-offer/",
        image: UniLogos.SouthernCross
    },
    {
        kind: "Early Entry",
        date: new Date("2022-09-12"),
        title: "UTS Early Entry",
        url:"https://www.uts.edu.au/study/undergraduate/admission-requirements/admissions-schemes/uts-early-entry-program",
        image: UniLogos.UTS
    },
    {
        kind: "Early Entry",
        title: "La Trobe University Aspire",
        url:"https://www.latrobe.edu.au/study/aspire",
        image: UniLogos.LaTrobe
    },
    {
        kind: "Early Entry",
        date: new Date("2021-09-23"),
        title: "University of New England Early Entry",
        url:"https://www.une.edu.au/study/study-on-campus/get-early-entry",
        image: UniLogos.UNE
    },
    /*{
        kind: "HSC",
        date: new Date("2021-09-18"),
        title: "HSC languages oral examinations finish",
        url:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/key-dates-exam-timetables/hsc-languages-oral-examinations",
        image: UniLogos.NESA
    },*/
    /*{
        kind: "SRS",
        date: new Date("2021-09-19"),
        title: "SRS applications close",
        url:"https://www.uac.edu.au/future-applicants/scholarships-and-schemes/schools-recommendation-schemes",
        image: UniLogos.UAC
    },*/
    /*{
        kind: "Early Entry",
        date: new Date("2021-09-19"),
        title: "Macquarie SRS",
        url:"https://www.mq.edu.au/study/admissions/entry-requirements/domestic/macquarie-entry/schools-recommendation-schemes",
        image: UniLogos.Mq
    },*/
    /*{
        kind: "Early Entry",
        date: new Date("2021-09-19"),
        title: "University of Canberra SRS",
        url:"https://www.canberra.edu.au/future-students/get-into-uc/admissions-programs/schools-recommendation-scheme",
        image: UniLogos.Canberra
    },*/
    {
        kind: "Early Entry",
        title: "University of Sydney E12",
        url:"https://www.sydney.edu.au/study/how-to-apply/undergraduate/admission-pathways/early-offer-year-12-scheme.html",
        image: UniLogos.USYD
    },
    {
        kind: "Early Entry",
        title: "ACU Guarantee",
        url:"https://www.acu.edu.au/study-at-acu/admission-pathways/acu-guarantee",
        image: UniLogos.ACU
    },
    /*{
        kind: "UAC Applications",
        date: new Date("2021-09-30"),
        title: "Early Bird Applications Deadline",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },*/
    {
        kind: "Early Entry",
        title: "University of Notre Dame Young Achievers Early Offer",
        url:"https://www.notredame.edu.au/study/early-offer/early-offer-2022",
        image: UniLogos.NotreDame
    },
    /*
    {
        kind: "HSC",
        date: new Date("2021-10-19"),
        title: "HSC written examinations begin",
        url:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/key-dates-exam-timetables/hsc-written-exam-timetable",
        image: UniLogos.NESA
    },
    {
        kind: "SRS",
        date: new Date("2021-11-04"),
        title: "Deadline to change preferences for Schools Recommendation Scheme offers. ",
        url:"https://www.uac.edu.au/future-applicants/scholarships-and-schemes/schools-recommendation-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "HSC",
        date: new Date("2021-11-11"),
        title: "HSC written examinations finish",
        url:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/key-dates-exam-timetables/hsc-written-exam-timetable",
        image: UniLogos.NESA
    },
    {
        kind: "EAS",
        date: new Date("2021-11-11"),
        title: "EAS: Eligibility letters released for December Round 2",
        url:"https://www.uac.edu.au/current-applicants/educational-access-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "SRS",
        date: new Date("2021-11-12"),
        title: "Schools Recommendation Scheme offers released",
        url:"https://www.uac.edu.au/future-applicants/scholarships-and-schemes/schools-recommendation-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "EAS",
        date: new Date("2021-11-22"),
        title: "Closing date for EAS applications for December Round 2",
        url:"https://www.uac.edu.au/current-applicants/educational-access-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "Early Entry",
        date: new Date("2021-12-10"),
        title: "University of Western Sydney True Reward Final Round Deadline",
        url:"https://www.westernsydney.edu.au/future/study/application-pathways/hsc-true-reward",
        image: UniLogos.UAC
    },
    {
        kind: "HSC",
        date: new Date("2021-12-17"),
        title: "HSC results and ATARs released",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },
    {
        kind: "UAC Applications",
        date: new Date("2021-12-18"),
        title: "Change of Preferences for December Round 2 Deadline",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },
    {
        kind: "UAC Applications",
        date: new Date("2021-12-22"),
        title: "December Round 2 offers released (first main offer round for Year 12 students) ",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },
    {
        kind: "EAS",
        date: new Date("2021-12-24"),
        title: "EAS: Closing date for EAS applications for January Round 1",
        url:"https://www.uac.edu.au/current-applicants/educational-access-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "UAC Applications",
        date: new Date("2022-01-06"),
        title: "Deadline to change preferences for January Round 1 offers",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },
    {
        kind: "UAC Applications",
        date: new Date("2022-01-13"),
        title: "January Round 1 offers released (second main offer round for Year 12 students)",
        url:"https://www.uac.edu.au/key-dates",
        image: UniLogos.UAC
    },
    {
        kind: "EAS",
        date: new Date("2022-02-04"),
        title: "EAS: Closing date for EAS applications for semester 1, 2022",
        url:"https://www.uac.edu.au/current-applicants/educational-access-schemes",
        image: UniLogos.UAC
    },
    {
        kind: "HSC",
        title: "HSC important dates for practical and performance examinations",
        url:"https://educationstandards.nsw.edu.au/wps/portal/nesa/11-12/hsc/key-dates-exam-timetables/hsc-important-dates-practical-and-performance-exam",
        image: UniLogos.NESA
    },
    */

]

const KeyDate : React.FC = () => {
    return <ContentWrapper title="Key Dates" subtitle={["All the key dates for HSC, Uni Applictions and Early Entry"]}>
        <PageSectionRow>
            <Col>
                <CardTable
                    data={data}
                    initialState={{
                        sortBy:[
                            {id:"date", desc:true}
                        ]
                    }}
                    renderFilters={(globalFilter, setGlobalFilter, filters, setFilters) => <Row>
                        <Col>
                            <Select placeholder="Filter By Event Type" onChange={(val)=>setFilters("kind", val && val.value !== "" ? val.value : undefined)} 
                                options={[{value:"", label:"All Events"}].concat([
                                    "Early Entry",
                                    "EAS",
                                    "HSC",
                                    "SRS",
                                    "UAC Applications",
                                ].map(v=>({value:v, label:v})))} />

                        </Col>
                    </Row>}
                    columns={[
                        {
                            id:"Logo",
                            Cell:({row:{original}}) => <div className="text-left">
                                <img src={original.image} width="60%" />
                            </div>,
                            width:2,
                            disableSortBy:true
                        },
                        {
                            Header:"Details",
                            accessor:"title",
                            width:4,
                            disableSortBy:true,
                        },
                        {
                            id: "date",
                            Header:"Date",
                            accessor: (d)=> d.date ? d.date.toDateString() : "",
                            width:2,
                            sortType: (rowA, rowB) => {
                                const dateA = rowA.original.date;
                                const dateB = rowB.original.date;
                                if (dateB === undefined) {
                                    return 1
                                } 
                                if (dateA === undefined) {
                                    return -1;
                                }
                                if (dateA.getTime() === dateB.getTime()) {
                                    return 0;
                                }
                                if (dateA.getTime() > dateB.getTime()) {
                                    return -1
                                }
                                return 1;
                            }
                        },
                        {
                            Header:"",
                            id:"kind",
                            accessor:"kind",
                            width:2,
                            defaultCanSort:false,
                            disableSortBy:true
                        },

                        {
                            id:"link",
                            disableSortBy:true,
                            Cell: ({row:{original}})=><div className="text-right">
                                <Button variant="active" onClick={()=>openLinkInNewTab(original.url)}>See More</Button>
                            </div>,
                            width:2
                        }
                    ]}
                    />
            </Col>
        </PageSectionRow>
    </ContentWrapper>
}

export default KeyDate;