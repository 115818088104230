import React from "react";
import {Col} from "../components/grid";
import ModalVideo from "react-modal-video";
import { openLinkInNewTab, getYoutubeParam } from '../util';
import { CareerCategory, CareerLink } from "jobjump-types/Careers";
import Careers from "../data/Careers";
import queryString from "query-string";
import CareerImages from "../assets/CareerImages";
import { RouteComponentProps } from "react-router";
import CardGrid from "../components/CardGrid";
import ImageCard from "../components/cards/ImageCard";
import PageSectionRow from "../components/PageSectionRow";
import ContentWrapper from "../components/ContentWrapper";

const CareerVR : React.FC<RouteComponentProps> = 
({location, history}) => {
    const [youTubeID, setYouTubeId] = React.useState<string>();
    const [showModal, setShowModal] = React.useState(false);
    const [chosenCategory, setChosenCategory] = React.useState<CareerCategory>();
    const [categories, setCategories] = React.useState<CareerCategory[]>([]);
    const [videoLinks, setVideoLinks] = React.useState<CareerLink[]>([]);
    const args = queryString.parse(location.search);
    const category = args.category;
    React.useEffect(() => {
        if (typeof category == "string" && categories.length > 0) {
            setChosenCategory(categories.find(c => c.id === category));
            Careers.getCareerCategoryDetails(category).then(r => setVideoLinks(r.careerLinks.filter(f => f.kind === 'vr_video')));
        }
    }, [category, categories]);
    React.useEffect(() => {
        Careers.getCareerCategories({hasLinksFor:["vr_video"]}).then(setCategories);
    }, []);
    if (!chosenCategory) {
        return <ContentWrapper
            title="CareerVR" 
            subtitle={["Explore your favourite career by going on a 360 VR tour. Select a category to get started."]}>
            <PageSectionRow><Col>
                <CardGrid numPerRow={3} prefix="careers-vr-career">
                {categories.map(c =>
                        <ImageCard
                            key={`${c.id}-video`}
                            imageUrl={CareerImages[c.name.toUpperCase()] || ""}    
                            text={c.name}
                            onClick={()=> history.push("/careerVR?"+queryString.stringify({"category" : c.id}))}
                        />
                    )} 
                </CardGrid>
            </Col></PageSectionRow>
        </ContentWrapper>
    }
    if (chosenCategory && videoLinks.length > 0) {
        return <ContentWrapper
            title={`${chosenCategory.name} VR`} 
            subtitle={["Select a video below to go on a full 360 VR tour of that career!"]}
            breadcrumbs={[{name:"CareerVR", link:"/careerVR"}]}>
                <PageSectionRow><Col>
                    <CardGrid numPerRow={videoLinks.length > 3 ? 3 : videoLinks.length} prefix="careers-vr-vids">
                        {videoLinks.map(c =>
                            <ImageCard
                                key={`${c.url}-video`}
                                imageUrl={c.imageUrl}    
                                text={c.title || "Click Here"}
                                onClick={()=> {
                                    const param = getYoutubeParam(c.url);
                                    if (param) {
                                        setYouTubeId(param);
                                        setShowModal(true);
                                    } else {
                                        openLinkInNewTab(c.url);
                                    }
                                }}
                            />
                        )}
                    </CardGrid>
                </Col></PageSectionRow>
                <ModalVideo channel='youtube' videoId={youTubeID} isOpen={showModal} onClose={()=>setShowModal(false)} />
        </ContentWrapper>
    }
    return <></>;
}

export default CareerVR;