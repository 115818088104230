import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import {Row, Col} from '../../components/grid';
import { EventTracking } from "../../data/EventTracking";

const MyQuestions : React.FC<{}> =
() => {
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            EventTracking.trackEvent("interviewPrepView", "myQuestions");
        }, 1000*30); //Spend 30 seconds on page
        return () => clearTimeout(timeout);
    }, []);
    return <ContentWrapper 
            title="My Questions To Ask at an Interview"
            subtitle={["Interviews aren't just about being asked questions, you should have some ready too! Here's some suggestions"]}
            breadcrumbs={[
                {name:"Contacting an Employer", link:"/contactEmployer"}, 
            ]}>
        <Row>
            <Col>
               <p>
                    Here is a handy list to start you off that you can print off and place in the back of your Personal Folder
                    that you take to the interview.
                </p>
                <ol>
                    <li>Can you describe my role I would perform in the company?</li>
                    <li>What training would I receive for my role?</li>
                    <li>What are the hours?</li>
                    <li>Is there any chance for overtime?</li>
                    <li>Is there a code of dress or uniform I need to wear?</li>
                    <li>Who might be my supervisor?</li>
                    <li>Are there any safety rules I need to know about?</li>
                    <li>Are there opportunities to try different roles in the company or to seek advancement?</li>
                    <li>How could I go about advancing myself in the company?</li>
                    <li>What would be the pay rate for my role?</li>
                    <li>What would be the superannuation that would apply?</li>
                </ol>
            </Col>
        </Row>
    </ContentWrapper>
}

export default MyQuestions;