import React from "react";

type RGBColor = {
  r:number,
  g:number,
  b:number
}

function rgba2rgb(startColor:RGBColor, alpha:number, background:RGBColor)
{
    return {
        r: Math.round(background.r + (startColor.r-background.r)*alpha),
        g: Math.round(background.g + (startColor.g-background.g)*alpha),
        b: Math.round(background.b + (startColor.b-background.b)*alpha),
    }
}

const hexToRGB = (h:string) => {
    let r = "", g = "", b = "";
  
    // 3 digits
    if (h.length == 4) {
      r = "0x" + h[1] + h[1];
      g = "0x" + h[2] + h[2];
      b = "0x" + h[3] + h[3];
  
    // 6 digits
    } else if (h.length == 7) {
      r = "0x" + h[1] + h[2];
      g = "0x" + h[3] + h[4];
      b = "0x" + h[5] + h[6];
    }
    return {r:parseInt(r),g:parseInt(g),b:parseInt(b)}
}

const d2h = (d:number) => {
  var h = (+d).toString(16);
  return h.length === 1 ? '0' + h : h;
}

const RGBToHex = (color:RGBColor) => {
  return `#${d2h(color.r)}${d2h(color.g)}${d2h(color.b)}`
}

const ThemeProvider : React.FC<{primaryColor?:string, onlyToChildren?:boolean}> = ({primaryColor, onlyToChildren, children}) => {
    const white = hexToRGB("#FFFFFF");
    const primaryColor100 = hexToRGB(primaryColor || "#49c4c4");
    const primaryColor50 = rgba2rgb(primaryColor100, 0.5, white);
    const primaryColor30 = rgba2rgb(primaryColor100, 0.3, white);
    const primaryColor10 = rgba2rgb(primaryColor100, 0.1, white);
 
    React.useEffect(() => {
        if (onlyToChildren) {
          return;
        }
        document.documentElement.style.setProperty("--primary-color", RGBToHex(primaryColor100));
        document.documentElement.style.setProperty("--primary-color-50", RGBToHex(primaryColor50));
        document.documentElement.style.setProperty("--primary-color-30",  RGBToHex(primaryColor30));
        document.documentElement.style.setProperty("--primary-color-10",  RGBToHex(primaryColor10));
    }, [primaryColor, onlyToChildren]);
    const style = !onlyToChildren ? {} :
      {
        "--primary-color": RGBToHex(primaryColor100),
        "--primary-color-50": RGBToHex(primaryColor50),
        "--primary-color-30": RGBToHex(primaryColor30),
        "--primary-color-10": RGBToHex(primaryColor10),
      }
    return <span style={style as React.CSSProperties}>{children}</span>
}

export default ThemeProvider;