import React from "react";
import { RouteComponentProps } from "react-router";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { Col } from "../../components/grid";
import { UniContentEntry } from "../../data/Uni";
import { getYoutubeParam, openLinkInNewTab } from "../../util";
import { uniConfig, uniCategoryPrettyNames, uniCategoryImages, CategoryTypes } from "./UniLinkData";
import ModalVideo from "react-modal-video";
import Uni from "../../data/Uni";
import { AtarCategory } from "jobjump-types/ATAR";
import AtarAPI from "../../data/ATAR";
import _ from "lodash";
import AtarImages from "../../assets/AtarImages";
import qs from "query-string";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";

interface UniProfileDetailsMatch {
    name: string
}

const UniProfileDetails : React.FC<RouteComponentProps<UniProfileDetailsMatch>> = ({history, match:{params:{name}}}) => {
    const [uniName, config] = Object.entries(uniConfig).find(([n, c]) => c.id === name) || [undefined, undefined];
    const [stories, setStories] = React.useState<UniContentEntry[]>([]);
    const [videoID, setVideoID] = React.useState<string>();
    const [showVideo, setShowVideo] = React.useState(false);
    const [categories, setCategories] = React.useState<AtarCategory[]>([]);
    React.useEffect(() => {
       Uni.getContentForInst(Number(name)).then(r => setStories(r));
    }, [name])
    React.useEffect(() => {
        if (config) {
            AtarAPI.getAtarCategoriesForInst(config.id).then(setCategories);
        }
    }, [config])
    if (!uniName || !config) {
        return <></>;
    }
    return <ContentWrapper title={`${uniName}`} 
        breadcrumbs={[ {name:"University Profiles", link: "/uni/profiles"} ]}
        heroChildren={()=><img className="block" style={{marginLeft:"auto", marginRight:"auto", maxHeight:"100px"}} src={config.image} />} >
        <PageSectionRow>
            <Col>
                <h2>Directory - All You Need To Know</h2>
            </Col>
        </PageSectionRow>
        <CardGrid prefix="uni-list" numPerRow={3}>
            {(Object.entries(uniCategoryPrettyNames) as [CategoryTypes, string][]).map(([id, name])=>
                config[id].map((l, i) => 
                    <ImageCard key={`${name}-${i}`} imageUrl={uniCategoryImages[id][i]} text={`${name}${l.text ? `- ${l.text}`:"" }`} onClick={()=>openLinkInNewTab(l.link)} />
                )
            )}
        </CardGrid>
        {stories.length > 0 && <>
        <PageSectionRow>
            <Col>
                <h2>Stories - What's it like to study at {uniName}?</h2>
            </Col>
        </PageSectionRow>
        <CardGrid prefix="stories" numPerRow={3}>
            {stories.map(s=><ImageCard imageUrl={s.image} text={s.title} onClick={()=>{
                const param = getYoutubeParam(s.url);
                if (param === null) {
                    openLinkInNewTab(s.url);
                    return;
                }
                setVideoID(param);
                setShowVideo(true);
            }} />)}
        </CardGrid>
        <ModalVideo channel='youtube' videoId={videoID} isOpen={showVideo} onClose={()=>setShowVideo(false)} />
        </>}
        {categories.length > 0 && <PageSectionRow>
            <Col>
                <h2>Courses - What can I study?</h2>
                <CardGrid prefix="courses" numPerRow={3}>
                    {_.sortBy(categories, (c)=>c.name).map(c=>
                        <ImageCard
                            imageUrl={AtarImages[c.name.toUpperCase()]}
                            text={c.name}
                            onClick={()=>history.push(`/atar/${c.id}?${qs.stringify({inst:name})}`)} />
                    )}
                </CardGrid>
            </Col>
        </PageSectionRow>}
    </ContentWrapper>
}

export default UniProfileDetails;