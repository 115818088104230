import { IconHeart } from "@tabler/icons";
import { AtarResult } from "jobjump-types/ATAR";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import AtarAPI from "../../data/ATAR";
import ATARTable from "../my_atar/ATARTable";
import { StagePropsBase } from "./common";

const CourseSelect : React.FC<StagePropsBase & RouteComponentProps> = ({response, onContinue, ...props}) => {
    const [data, setData] = React.useState<AtarResult[]>([]); 
    const [chosenStates, setChosenStates] = React.useState<string[]>([]);
    React.useEffect(() => {
        if (chosenStates.length === 0) {
            return;
        }
        AtarAPI.getAtarList(response.categories, chosenStates, response.selectedInsitutes.map(i=>Number(i))).then(setData);
    }, [response.categories, chosenStates]);
    return <>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Find up to 3 courses you're interested in below. Select it with the the <IconHeart />.</h3>
            </Col>
        </PageSectionRow>
        <Row>
            <Col className="mt-8 text-right">
                <Button  variant="active" onClick={onContinue}>Continue</Button>
            </Col>
        </Row>
        <Row>
            <Col className="mt-8 text-right">
                <Button variant="negative" textButton onClick={onContinue}>I'm not sure yet</Button>
            </Col>
        </Row>

        <PageSectionRow>
            <Col>
                <ATARTable showSearch data={data} chosenStates={chosenStates} setChosenStates={setChosenStates} simpleView {...props} />
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col className="text-right">
                <Button variant="active" onClick={onContinue}>Continue</Button>
            </Col>
        </PageSectionRow>
    </>
}

export default CourseSelect;