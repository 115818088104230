import React from "react";
import { RouteComponentProps } from "react-router";
import TileImageStore from "../../assets/JobJumpPageImages";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { openLinkInNewTab } from "../../util";
import CaAdminLayout from "./CaAdminLayout";

const Resources : React.FC<RouteComponentProps> = ({history, ...props}) =>
    <CaAdminLayout
        history={history}
        active=""
        title="Careers Advisers Resources"
        {...props}>
        <PageSectionRow>
            <Col>
                <CardGrid numPerRow={3} prefix="ca-res">
                    <ImageCard imageUrl={TileImageStore["HscLessonPlans"]} text="Lesson Plans" 
                        onClick={()=>history.push("/lessonPlans")} />
                    <ImageCard imageUrl={TileImageStore["SearchingForAJob"]} text="Pre-Work Check Resources" 
                        onClick={()=>history.push("/admin/workPrepRes")} />
                    <ImageCard imageUrl={TileImageStore["MyCareersFacebook"]} text="My Careers Facebook" 
                        onClick={()=>history.push("/facebook_page")} />
                    {<ImageCard imageUrl={TileImageStore["ParentHighSchoolCareer"]} text="JobJump School Flyer" 
                        onClick={()=>openLinkInNewTab(require("../../assets/docs/Student Registering on JobJump.docx"))} />}
                    <ImageCard imageUrl={TileImageStore["WorkExperiance"]} text="Work Experience"
                        onClick={()=>history.push("/workExperience/papers")} />
                    <ImageCard imageUrl={TileImageStore["WorkSafetyQuiz"]} text="Work Safety Quiz" 
                        onClick={()=>openLinkInNewTab("https://smartmove.safetyline.wa.gov.au/")}/>
                </CardGrid>
            </Col>
        </PageSectionRow>
    </CaAdminLayout>

export default Resources;