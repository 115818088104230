import _ from "lodash";
import React from "react";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import CardButton from "../../components/cards/CardButton";
import { Col, Row } from "../../components/grid";
import { CwizProps } from "./common";

const Values = [
    "To be paid to use this skill",
    "To learn more about this interest",
    "To be appreciated by others because you are good at this skill",
    "To think you could make a difference and to help others by using this skill",
    "To feel you have a sense of achievement when you use this skill",
    "To be happy working with others who also have this interest",
]


const CwizValues : React.FC<CwizProps> = ({response: results, updateResponse, goToPage}) => {
    const [selectedValues, setSelectedValues] = React.useState<string[]>(results.values);
    const [showWarning, setShowWarning] = React.useState(false);
    return <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Skills &amp; Values</h2>
                <h5>You picked the following skills: 
                    <ul className="text-left">
                        {results.skills.map(s=><li key={s}>{s}</li>)}
                    </ul></h5>
                <h3>If you were to use your skills in a future job, would you like?</h3>
                {showWarning && <div>
                    <Alert textOnly variant="negative" text="You must pick at least one of the values below. If you cannot find a skill that suites you, you may wish to pick another category." />
                    <Button variant="neutral" onClick={()=>goToPage("categories")}>Pick Another Category</Button>
                </div>}
            </Col>
        </Row>
        <Row small>
            <Col className={"mt-32"}>
                {Values.map(s => {
                    const inSet = selectedValues.includes(s);
                    return <CardButton className="mb-16" variant="active" isSelected={inSet} onClick={()=>setSelectedValues(!inSet?[...selectedValues, s]:selectedValues.filter(x=>x!==s))}>
                        {s}
                    </CardButton>
                })}
                <div className="text-right">
                    <Button onClick={()=>selectedValues.length > 0 ? updateResponse({...results, values:selectedValues}) : setShowWarning(true)} variant="active">Continue</Button>
                </div>
            </Col>
        </Row>
    </>;
}

export default CwizValues;