import React from "react";
import Button from "../components/Button";
import {Row, Col} from "../components/grid";

const CareersFacebookPage : React.FC<{}> =
() => {
    const [hasFacebookAccount, setHasFacebookAccount] = React.useState<boolean>();
    return <>
        <Row><Col>
            <h4>Here's some handy instructions to create your own careers Facebook page. It's fast, simple and 
                allows you to keep your students and parents up-to-date with careers news for your school. Follow the 
                instructions below.</h4>
            <h4><strong>If you feel any of the steps below are a bit tricky for you, ask your school's IT support for help. 
                They'll have your Careers Facebook Page finished for you in no time at all.</strong></h4>
            <h4>Before you begin, we recommend getting a copy of your school's emblem, so you can use it as your 
                page's profile picture.</h4>
            <h4>
                <strong>NOTE: You should also check you have received permission from your Principal.</strong>
            </h4>
        </Col></Row>
        {hasFacebookAccount === undefined && <Row><Col>
            <h4>Firstly, do you already have a Facebook account?</h4>
            <Row>
                <Col sm={6} className="text-center">
                    <Button variant="active" onClick={()=>setHasFacebookAccount(true)}>
                        <h5>Yes</h5>
                    </Button>
                </Col>
                <Col sm={6} className="text-center">
                    <Button variant="negative" onClick={()=>setHasFacebookAccount(false)}>
                        <h5>No</h5>
                    </Button>
                </Col>
            </Row>
        </Col></Row>}
        {hasFacebookAccount === false && <>
            <Row>
            <Col>
              <h1>Setting Up A Facebook Account</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Step 1</h2>
              <p className="lead">To start, go to <a href="http://www.facebook.com" rel="noopener noreferrer" target="_blank">facebook.com</a>, where you'll see this screen. Under &quot;Sign Up&quot;, fill in your details (name, email address and date of birth). Then press the &quot;Sign Up&quot; button at the bottom of the page.</p> 
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img alt="Facebook Homepage" style={{width:"100%"}} className="img-responsive img-center" src={require("../assets/facebook/facebook-homepage.png")} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Step 2</h2>
              <p className="lead">Facebook will send you a confirmation email. In that email you will see a button like the one shown in the image. Press that button to confirm your account. You're now in and ready to go!</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img alt="Confirm Registration" style={{width:"100%"}} className="img-responsive img-center" src={require("../assets/facebook/confirm-rego.png")} />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Step 3</h2>
              <p className="lead">When you first log in to Facebook, you may be presented with this &quot;Find Friends&quot; page. If you do not want to do this, simply press the &quot;Next&quot; button without entering any details, then select the &quot;Skip&quot; option.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img alt="Find Friends" style={{width:"100%"}} className="img-responsive img-center" src={require("../assets/facebook/find-friends.png")} />
            </Col>
          </Row>   
        </>}
        {hasFacebookAccount !== undefined && <>
            <Row>
            <Col>
              <h1>Setting Up A Facebook Page</h1>
            </Col>
          </Row> 
          <Row>
            <Col>
              <h2>Step 1</h2>
              <p className="lead">If you are not already, Login to <a href="http://www.facebook.com" rel="noopener noreferrer" target="_blank">Facebook</a>. You can do so by entering your email address and password into the Login box in the top right, that looks like this.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img alt="Facebook Login" style={{width:"100%"}} className="img-responsive img-center" src={require("../assets/facebook/facebook-login.png")} />
            </Col> 
          </Row>
          <Row>
            <Col>
              <h2>Step 2</h2>
              <p className="lead">In the top right of the screen, there is an icon with an arrow (inverted triangle) next to it. Press the arrow to open up a menu.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img alt="Facebook Dropdown Menu" style={{width:"100%"}} className="img-responsive img-center" src={require("../assets/facebook/dropdown-menu.png")} />
            </Col> 
          </Row> 
          <Row>
            <Col>
              <h2>Step 3</h2>
              <p className="lead">From that menu, select the &quot;Create Page&quot; option.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Create Page Option" className="img-responsive img-center" src={require("../assets/facebook/create-page-dropdown.png")} />
            </Col> 
          </Row>  
          <Row>
            <Col>
              <h2>Step 4</h2>
              <p className="lead">Now you will be presented with a selection of categories for pages. Choose the &quot;Company, Organisation or Institute&quot; option, as shown here.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Page Category Selection" className="img-responsive img-center" src={require("../assets/facebook/facebook-page-category.png")} />
            </Col> 
          </Row>   
          <Row>
            <Col>
              <h2>Step 5</h2>
              <p className="lead">You will now need to fill in the basic details for your page. First select the &quot;Education&quot; option from the drop-down menu. Then, you may enter a name for your page. You will want to give your page a name that is easy to search, so if you were from Example High School you will want to call it something like &quot;Example High Careers Page&quot;. Once you have done this, press &quot;Get Started&quot;</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Completed Category" className="img-responsive img-center" src={require("../assets/facebook/category-filled-in.png")} />
            </Col> 
          </Row>    
          <Row>
            <Col>
              <h2>Step 6</h2>
              <p className="lead">You now need to enter a description for your page. Do so in the box then press &quot;Save Info&quot;</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Create Page Wizard Step 1" className="img-responsive img-center" src={require("../assets/facebook/create-page-wizard-1.png")} />
            </Col> 
          </Row>     
          <Row>
            <Col>
              <h2>Step 7</h2>
              <p className="lead">You can now upload your school's emblem to be your page's profile picture. Select &quot;Upload From Computer&quot;, find the file and select it, then press &quot;Save Photo&quot;. If you do not have an image you want to use as your career page's profile picture you can select &quot;Skip&quot; in the bottom right. You can always come back and upload a picture later.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Create Page Wizard Step 2" className="img-responsive img-center" src={require("../assets/facebook/create-page-wizard2.png")} />
            </Col> 
          </Row>      
          <Row>
            <Col>
              <h2>Step 8</h2>
              <p className="lead">You will then be presented with this screen. If you wish, you may select the demographics of people viewing your page. This makes it easier for people to find your page. We recommend that you at least enter a location in the first field. Select &quot;Save&quot;.</p>
              <p className="lead">If you are happy to do so, Facebook automatically selects Everyone in this current location of your school to be the location used. Simply leave the text as it is. 
Select the lower age level you wish for your audience such as 15 . The age upper limit is up to you to select. You may want to involve your parents in the Careers News on Facebook. If so, perhaps select 65+. If you only wish to have only your students use your Careers News on Facebook then perhaps select the upper limit age of 18. Then click &quot;Save&quot;.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Create Page Wizard Step 3" className="img-responsive img-center" src={require("../assets/facebook/create-page-wizard3.png")} />
            </Col> 
          </Row>       
          <Row>
            <Col>
              <h2>Step 9</h2>
              <p className="lead">The next step is to set up your Facebook page to be one-way, so that only you can post content to it. To do so, first go to the settings of your page, as shown below.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Page Settings" className="img-responsive img-center" src={require("../assets/facebook/page-settings.png")} />
            </Col>  
          </Row>           
          <Row>
            <Col>
              <h2>Step 10</h2>
              <p className="lead">Now, select the edit button next to the &quot;Visitor Posts&quot; item, and change the setting from &quot;Allow visitors to the Page to publish posts&quot; to &quot;Disable posts by other people on the Page&quot;, as shown below. Then press &quot;Save Changes&quot;.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Vistor Posts" className="img-responsive img-center" src={require("../assets/facebook/visitor-posts.png")} />
            </Col>  
          </Row>            
          <Row>
            <Col>
              <h2>Step 11</h2>
              <p className="lead">Then, select the edit button next to the &quot;Messages&quot; item, and uncheck the check box that says &quot;Allow people to contact my Page privately by showing the Message button&quot;. Press the &quot;Save Changes&quot; button.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Messages" className="img-responsive img-center" src={require("../assets/facebook/messages.png")} />
            </Col>  
          </Row>             
          <Row>
            <Col>
              <p className="lead">That's it. Press the &quot;Page&quot; button in the top right to go back to your page.</p>
            </Col>
          </Row> 
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Page Button" className="img-responsive img-center" src={require("../assets/facebook/page-button.png")} />
            </Col>  
          </Row>             
          <Row>
            <Col>
              <p className="lead"><strong>You now have your own careers Facebook page!</strong></p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>How can I add a photo at the top of my page? (Cover Photo)</h1>
              <p className="lead">If you do not understand the following, please speak to your IT person. You can always come back and upload this photo later. This will not stop you from starting your Careers Facebook Page straight away.</p>
              <p className="lead">JobJump provides some nice photos as options for you, however you may use your own if you wish. <a href="img/facebook/jj-facebook-cover-photo.zip">Click Here</a> To download JobJump's Facebook Cover Photos. You will need to un-zip the files to somewhere on your computer. Then, to upload the photo, select the camera icon in the top left corner and press &quot;Upload Photo...&quot;. You should then find the photo you wish to use on your computer and select it for upload.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Cover" className="img-responsive img-center" src={require("../assets/facebook/cover-photo.png")} />
            </Col>  
          </Row>
          <Row>
            <Col>
              <h1>How do I get student to start using our school's Facebook page?</h1>
              <p className="lead"><strong>You can now tell your students, parents or anyone else in your school community to search for the name of your page (e.g. Example High Career Page) on Facebook. Make sure to ask them to &quot;Like&quot; your page. To do so, you could even ask them to briefly go onto Facebook for a minute or two in class from their phones if they are allowed to.</strong></p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>How do I come back to my Careers Facebook Page?</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="lead">If you later want to come back and add new information to your career's Facebook page, simply go to <a href="http://www.facebook.com" rel="noopener noreferrer" target="_blank">Facebook</a>, login and then select your careers page from the right hand side. Then you can add any new updates that you wish.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Find Created Page" className="img-responsive img-center" src={require("../assets/facebook/find-created-page.png")} />
            </Col> 
          </Row>         
          <Row>
            <Col>
              <h1>How do I post news on my Facebook Page?</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="lead">Once you have accessed your Facebook page, you can click on the box with &quot;Write Something&quot; inside it. You will then be able to type the message you wish to post on your Facebook page. Once you have done this, press the &quot;Publish&quot; button.</p>
              <p className="lead">You could also copy text with link of news that you like from somewhere such as a bulletin. To do so, highlight the text and copy it. Then, paste it in the &quot;Write Something&quot; box.</p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <img style={{width:"100%"}} alt="Newly Created Page" className="img-responsive img-center" src={require("../assets/facebook/new-created-page.png")} />
            </Col> 
          </Row>         
          <Row>
            <Col>
              <small>JobJump Pty Ltd takes no responsibility for the completeness and accuracy of this information. The user shall verify the accuracy and completeness of the information, in addition to the suitability of any particular product or services for the user's needs and the needs of your students/clients. JobJump Pty Ltd does not recommend or endorse any particular product, service, course or provider. The information contained in this guide may be used by the user at their own discretion.</small>
            </Col>
          </Row>
        </>}
    </>;
}

export default CareersFacebookPage;