import React from "react";
import { RouteComponentProps } from "react-router";
import CareerImages from "../assets/CareerImages";
import TileImageStore from "../assets/JobJumpPageImages";
import ContentWrapper from "../components/ContentWrapper";
import VideoSelection, { VideoList } from "../components/VideoSelection";
import PersonUsingGoogle from "../assets/homepage/person-using-google.png";

export const StudentVideos : VideoList[] = [
    {
        title: "eWork Experience",
        subtitle:"Do fun activities on your career and prove your passion for it",
        key:"eWorkExp",
        videoId: "672654516",
        imageUrl: PersonUsingGoogle
    },
    {
        title: "Real Work Experience Directory",
        subtitle:"Find places to get real experience",
        key:"realWorkExp",
        videoId: "672654385",
        imageUrl:TileImageStore["CallingABoss"]
    },

    {
        title: "Gap Year Options",
        subtitle:"Consider life experiences before starting your future tertiary studies",
        key:"gapYear",
        videoId: "512450402",
        imageUrl:TileImageStore["UniversityOverseasExchange"]
    },
    /*{
        title: "Helpful Links",
        subtitle:"Everything you need in one place",
        key:"helpfulLinks",
        service: "vimeo",
        videoId: "396205477"
    },*/
    {
        title: "Interstate or Overseas Study Options",
        subtitle:"Find your favourite degree in new locations",
        key:"interstateOverseas",
        videoId: "511905447",
        imageUrl:TileImageStore["UniversityInterstateAndOverseas"]
    },

    {
        title: "My ATAR",
        subtitle:"Find my Uni degrees in seconds, check my HSC subjects and save your favourite degrees",
        key:"myAtar",
        videoId: "579038354",
        imageUrl:TileImageStore["UniversityOpenDays"]
    },
    {
        title: "Register and Login to JobJump",
        subtitle:"Do it in seconds and start your career happening",
        key:"register",
        imageUrl:TileImageStore["CareerNews"],
        videoId: "528192768"
    },
    {
        title: "Overseas Exchange",
        subtitle:"Why not study part of your degree overseas",
        key:"overseasExchange",
        videoId: "511905625",
        imageUrl:TileImageStore["UniversityOverseasExchange"]
    },

    {
        title: "My Money",
        subtitle:"How to budget my money for all the things you want",
        key:"myMoney",
        videoId: "512450336",
        imageUrl:TileImageStore["StudentMoneyHelp"]
    },

    {
        title: "My Career",
        subtitle:"In easy to follow steps",
        key:"myCareer",
        videoId: "511905399",
        imageUrl:TileImageStore["StudentFindingACareer"]
    },

    {
        title: "Resume Builder",
        subtitle:"100+ careers or basic resume Fast and fun to do",
        key:"resumeBuilder",
        videoId: "627725112",
        imageUrl:TileImageStore["MyResumeForCareers"]
    },

    {
        title: "Scholarships at Uni",
        subtitle:"Where to find the ones you want",
        key:"scholarships",
        videoId: "511905805",
        imageUrl:TileImageStore["UniversityScholarships"]
    },

    {
        title: "Find Average Earnings",
        subtitle:"Find what different jobs earn across Australia",
        key:"earnings",
        videoId: "512450535",
        imageUrl:TileImageStore["JobGrowthDecline"]
    },

    {
        title: "Subject Selection",
        subtitle:"What subjects for my career",
        key:"subjectSelection",
        videoId: "945443508",
        imageUrl:TileImageStore["AtarChoosingHscSubjects"]
    },

    /*{
        title: "TAFE",
        subtitle:"Find my course and where I can study it",
        key:"tafe",
        service: "vimeo",
        videoId: "396205842"
    },*/

    {
        title: "Talking to an employer",
        subtitle:"Find my job and how to get results. Easy to use step by step help",
        key:"talkingToAnEmployer",
        videoId: "511905311",
        imageUrl:TileImageStore["CallingABoss"]
    },

    {
        title: "Tax File Number (TFN)",
        subtitle:"Apply with easy to follow steps",
        key:"tfn",
        videoId: "512450370",
        imageUrl:TileImageStore["GettingYouTaxFileNumber"]
    },

    /*{
        title: "The Interview",
        subtitle:"Meeting an employer and knowing how to handle it",
        key:"interview",
        service: "vimeo",
        videoId: "396205890"
    },*/

    /*{
        title: "UCAT",
        subtitle:"Get your info for Medicine, Dentistry, Clinical Sciences for Unis in Aus and NZ",
        key:"ucat",
        service: "vimeo",
        videoId: "396205913"
    },*/

    {
        title: "USI Number",
        subtitle:"How to easily obtain your USI Number",
        key:"usi",
        videoId: "512450614",
        imageUrl:TileImageStore["UniqueStudentIdentifier"]
    },

    /*{
        title: "Work Experience Papers",
        subtitle:"For Government Schools",
        key:"workExperience",
        service: "vimeo",
        videoId: "396205953"
    },*/

    {
        title: "Aboriginal and Torres Strait Islander at TAFE",
        subtitle:"Find all the information you need",
        key:"first-nations-tafe",
        videoId: "511905367",
        imageUrl:TileImageStore["UniversityAtsi"]
    },
    {
        title: "Aboriginal and Torres Strait Islander at University",
        subtitle:"Find all the information you need",
        key:"first-nation-uni",
        videoId: "511905672",
        imageUrl:TileImageStore["UniversityEarlyEntrySrs"]
    },
    {
        title: "Accommodation at Uni",
        subtitle:"Find what you need and ask the right questions",
        key:"accommodation",
        videoId: "511905646",
        imageUrl:TileImageStore["UniversityAccommodation"]
    },
    {
        title: "Adjustment Factors (Bonus Points)",
        subtitle:"What can I be awarded by doing well in my HSC Subjects",
        key:"bonusPoints",
        videoId: "511905761",
        imageUrl:TileImageStore["UniversityAdjustmentFactors"]
    },
    {
        title: "Alternative Entry to Your Uni",
        subtitle:"Find the best way that suits you",
        key:"alternativeEntry",
        videoId: "511905715",
        imageUrl:TileImageStore["UniversityAlternativeEntry"]
    },
    {
        title: "Apprenticeships and Traineeships Quizzes",
        subtitle:"How I prove I have the skills in literacy and numeracy for my career",
        key:"apprenticeship",
        videoId: "512450504",
        imageUrl:TileImageStore["WorkSafetyQuiz"]
    },
    {
        title: "Apprenticeships and Traineeships",
        subtitle:"What are they and where can they lead me",
        key:"whatApprenticeship",
        videoId: "512450572",
        imageUrl:TileImageStore["TraineeshipsAndAprenticeships"]

    },
    /*{
        title: "Bullseyes",
        subtitle:"HSC Subjects and my career options in seconds",
        key:"bullseyes",
        service: "vimeo",
        videoId: "396639291"
    },*/
    {
        title: "Career Videos",
        subtitle:"See what it's really like",
        key:"careerVideos",
        videoId: "511906012",
        imageUrl:TileImageStore["CareerVideos"]
    },
    {
        title: "Deferring my Uni Course I was Offered",
        subtitle:"Advice and planning to make it work for you",
        key:"deferring",
        videoId: "511905539",
        imageUrl:TileImageStore["UniversityDeferment"]
    },
    /*{
        title: "Early Entry to University",
        subtitle:"How to apply and what to do",
        key:"earlyEntry",
        service: "vimeo",
        videoId: "396639543"
    },*/
    {
        title: "EAS Educational Access Scheme",
        subtitle:"HSC study hardship - uni entry support",
        key:"eas",
        videoId: "511905595",
        imageUrl:TileImageStore["UniversityEarlyAccessScheme"]
    },
    /*{
        title : "Career Wiz Quiz",
        subtitle: "Find all your careers in 3 easy steps.",
        key:"cwiz",
        service: "vimeo",
        videoId: "397698233"
    }*/
    {
        title: "Soft Skills",
        subtitle:"Be ready for the workplace",
        key:"softskills",
        videoId: "512450475",
        imageUrl:TileImageStore["CommonInterviewQuestions"]
    },
    {
        title: "Community Service",
        subtitle:"Get experience and help others",
        key:"community",
        videoId: "512450437",
        imageUrl:TileImageStore["LearningCprAndFirstAid"]
    },
    {
        title: "Career VR",
        subtitle:"See your career in 360",
        key:"careervr",
        videoId: "511906047",
        imageUrl:CareerImages["ANIMATOR"]
    },
    {
        title: "Uni Student Stories",
        subtitle:"Hear from real students about their courses",
        key:"unistories",
        videoId: "511905893",
        imageUrl:TileImageStore["StudentsTalking"]
    },
    {
        title: "University Insitute Information",
        subtitle:"Find out everything about an insitute",
        key:"uniinst",
        videoId: "511905854",
        imageUrl:TileImageStore["UniCampus1"]
    },
    {
        title: "University Virtual Tours",
        subtitle:"Explore a campus without leaving home",
        key:"campusvirtualtour",
        videoId: "511905500",
        imageUrl:TileImageStore["UniCampus2"]
    },
    {
        title: "Find A Job Ad",
        subtitle:"Up to the minute job listings",
        key:"jobAd",
        videoId: "511905339",
        imageUrl:TileImageStore["SearchingForAJob"]
    },

]


const StudentHelpVideos : React.FC<RouteComponentProps> =
(props) => <ContentWrapper
        title="Fast Help Videos"
        subtitle={["Search and select a help topic to view a helpful video about it."]}
        >
    <VideoSelection videos={StudentVideos} {...props} />
</ContentWrapper>


export default StudentHelpVideos;