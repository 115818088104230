import React from "react";
import {Badge, Accordion} from "react-bootstrap";
import { AtarResult } from "jobjump-types/ATAR";
import LabelGroup from "./LabelGroup";
import {Row, Col} from "../../../components/grid";
import { Student, TertiaryEmailInfo } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../../data/CaStudentDetails";
import { Check, Label, Radio, Select, TextArea, TextField } from "../../../components/Form";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import BaseCard from "../../../components/cards/BaseCard";
import { IconCirclePlus, IconQuestionMark } from "@tabler/icons";
import Alert from "../../../components/Alert";

const extraInfoCategories = [
    "Aboriginal Support", 
    "Accommodation", 
    "Adjustment Factors",
    "Alternative Entry",
    "Deferment",
    "Early Offer",
    "Open Days",
    "Overseas Exchange",
    "Scholarships"
];

const Letter : React.FC<{
    courses:AtarResult[], 
    emails:string[], 
    extraInfo:string[], 
    comment:string, 
    studentName:string, 
    studentYear:string
    caName:string,
    schoolName:string }> 
= ({courses, emails, extraInfo, comment, studentName, studentYear, caName, schoolName}) =>
    courses.length === 0 ? <></> : <Row><Col>
        <Row><Col>
            <Label>To:</Label>
            <Badge pill variant="light">
                Course Information Officer - {courses[0].institution}
            </Badge>
        </Col></Row>
        <Row><Col>
            <Label>CC:</Label>
            {emails.map(e => <Badge pill variant="light" key={e}>{e}</Badge>)}
        </Col></Row>
        <Row><Col>
            <Label>Subject:</Label>
            <TextField disabled onChange={()=>{}} value="Request for course information for student" />
        </Col></Row>
        <Row><Col>
            <Label>Message:</Label>
            <div className="jobjump-form-component" style={{height:"auto"}}>
                <p>Dear Course Information Officer,</p>
                <p>My student {studentName} of Year {studentYear} would like information about the following course(s):</p>
                <ul>
                    {courses.map(c => <li key={c.jj_id}>{c.name} ({c.id})</li>)}
                </ul>
                {extraInfo.length > 0 && <p>Could you also provide information about:</p>}
                {extraInfo.length > 0 && <ul>
                    {extraInfo.map(s => <li key={s}>{s}</li>)}
                </ul>}
                {comment !== "" && <p>{comment}</p>}
                <p><strong>Note:</strong> I ask you to &quot;<strong>Reply All</strong>&quot; to this email so that all 
                    the relevant parties receive the information, to best support my student.</p>
                <p>Kind Regards,</p>
                <p>{caName}</p>
                <p>Careers Adviser</p>
                <p>{schoolName}</p>
            </div>
        </Col></Row>
    </Col></Row>

interface ContactInstsModal {
    courseSelections : AtarResult[],
    setCourseSelections : (a:AtarResult[])=>void
    studentDetails : Student,
    show : boolean,
    onHide : ()=>void,
    sendingDetails : TertiaryEmailInfo,
    onSuccess : ()=>void
}

const ContactInstsModal : React.FC<ContactInstsModal> =
    ({courseSelections, setCourseSelections, studentDetails, sendingDetails, show, onHide, onSuccess}) => {
        const [whoToSend, setWhoToSend] = React.useState<string>();
        const [over18, setOver18] = React.useState<boolean>();
        const [havePermission, setHavePermission] = React.useState<boolean>(false);
        const [sendList, setSendList] = React.useState<string[]>([]);
        const [emailToAdd, setEmailToAdd] = React.useState("");
        const [studentName, setStudentName] = React.useState(`${studentDetails.first_name} ${studentDetails.last_name}`);
        const [studentYear, setStudentYear] = React.useState(studentDetails.year);
        const [extraInfo, setExtraInfo] = React.useState<string[]>([]);
        const [additionalComment, setAdditionalComment] = React.useState("");
        const [showPreview, setShowPreview] = React.useState(false);
        const [noAdditionalDetails, setNoAdditionalDetails] = React.useState(false);
        const sendMessage = React.useCallback(() => {
            CaStudentDetails.sendTertiaryMessage(
                studentDetails.id,
                sendingDetails.caName,
                studentName,
                studentYear,
                sendList,
                courseSelections.map(i=>i.jj_id),
                extraInfo,
                additionalComment
            ).then(() => {
                setShowPreview(false);
                onHide();
                onSuccess();
            });
        }, [sendList, studentName, studentYear, extraInfo, additionalComment, 
            courseSelections, onHide, sendingDetails.caName, studentDetails.id]);
        const previewMessageButton = React.useCallback(() => {
            if (extraInfo.length === 0 && additionalComment === "") {setNoAdditionalDetails(true); return;}
            setNoAdditionalDetails(false);
            setShowPreview(true);
        }, [extraInfo, additionalComment, setShowPreview, setNoAdditionalDetails])
        React.useEffect(() => {
            if (courseSelections.length === 0) { onHide() }
        }, [courseSelections, onHide]);
        React.useEffect(() => {
            if (whoToSend === "ca") {
                setSendList([sendingDetails.caEmail]);
            } else if (whoToSend === "student") {
                setSendList([sendingDetails.caEmail, studentDetails.email]);
            }
        }, [whoToSend, sendingDetails.caEmail, studentDetails.email])
        return <Modal show={show} onHide={onHide} size="lg" title="Sent Message to Institutes">
            <Row><Col>
                {!showPreview && <Row><Col>
                    <Row><Col>
                        <Label>I would like the message to come to</Label>
                        <Radio name="who" label="Just myself (the Careers Adviser), or"
                            className="block"
                            checked={whoToSend === "ca"}
                            onChange={(checked) => checked && setWhoToSend("ca")}
                        />
                        <Radio name="who" label="My student and me to be CC'd"
                            className="block"
                            checked={whoToSend === "student"}
                            onChange={(checked) => checked && setWhoToSend("student")}
                        />
                    </Col></Row>
                    {whoToSend === "student" && 
                    <Row><Col>
                        <Label>Is the student over the age of 18?</Label>
                        <Radio name="over18" label="Yes" checked={over18 === true}
                            className="block"
                            onChange={(checked) => checked && setOver18(true)}
                        />
                        <Radio name="over18" label="No"
                            className="block"
                            checked={over18 === false /*Need to check explicitly as could be undefined*/}
                            onChange={(checked) => checked && setOver18(false)}
                        />                
                    </Col></Row>}
                    {whoToSend === "student" && over18 === false && 
                    <Row><Col>
                        <Check
                            label="The student has permission from a parent/guardian for the academic institutes they select to email them information related to their choice."
                            className="block"
                            checked={havePermission}
                            onChange={(checked) => setHavePermission(checked)}
                        />
                    </Col></Row>}
                </Col></Row>}
                {(!showPreview && (whoToSend === "ca" || (whoToSend === "student" && (over18 || havePermission)))) &&
                    <Row><Col>
                        <Row><Col>
                            <Label>Courses:</Label>
                            <LabelGroup
                                data={courseSelections}
                                onDelete={d => setCourseSelections(courseSelections.filter(c => c.id !== (d as AtarResult).id))}
                                textKey="name"
                            />
                        </Col></Row>
                        <Row><Col>
                            <Label>CC:</Label>
                            <LabelGroup
                                data={sendList}
                                onDelete={d => setSendList(sendList.filter(c => c !== d))}
                            />
                        </Col></Row>
                        <Row><Col>
                            <Label>Add CC:</Label>
                            <TextField value={emailToAdd} onChange={setEmailToAdd} />
                            <IconCirclePlus 
                                onClick={()=>{
                                    setSendList(sendList.concat([emailToAdd])); 
                                    setEmailToAdd("");
                                }}
                            />
                        </Col></Row>
                        <Row><Col>
                            <Label>Student Name:</Label>
                            <TextField value={studentName} onChange={setStudentName} />
                        </Col></Row>
                        <Row><Col>
                            <Label>Student Year:</Label>
                            <Select
                                value={{label:`Year ${studentYear}`, value:studentYear}}
                                options={[7,8,9,10,11,12,13].map(y =>({label:`Year ${y}`, value:String(y)}))}
                                onChange={(value)=>value && setStudentYear(value.value)} />
                        </Col></Row>
                        {noAdditionalDetails && <Alert textOnly variant="negative" text="Please select at least one item of extra information or add an additional comment or question." />}
                        <Row><Col>
                            <Label>Extra Information</Label>
                            {extraInfoCategories.map(s => 
                                <Badge variant="light" pill className="ml-2">
                                    <Check
                                        key={s}
                                        checked={extraInfo.includes(s)}
                                        label={s}
                                        onChange={(checked) => {
                                            if (checked) {
                                                setExtraInfo(extraInfo.concat([s]));
                                            } else {
                                                setExtraInfo(extraInfo.filter(i => i !== s));
                                            }
                                        }}
                                    />
                                </Badge>
                            )}
                        </Col></Row>
                        <Row><Col>
                           <Label>Additional Question/Comments</Label>
                           <TextArea value={additionalComment} onChange={setAdditionalComment} />
                            <Accordion>
                                <Accordion.Toggle as={Button} variant="active" className="secondary" eventKey="suggestions">
                                    <IconQuestionMark className="icon-circle" /> Suggestions
                                </Accordion.Toggle> 
                                <Accordion.Collapse eventKey="suggestions">
                                    <ul>
                                        <li>What majors (specialisations) do you offer? In this degree, can I major in ...?</li>
                                        <li>I'm also interested in ... Can I study units as part of this degree?</li>
                                        <li>What double degree options are there?</li>
                                        <li>What experience or internship opportunities are there?</li>
                                        <li>What are the graduate outcomes or employment opportunities for this degree?</li>
                                        <li>What alternative degrees could I study at your university if I was unsuccessful in gaining entry?</li>
                                        <li>Is it possible to shift from full-time to part-time?</li>
                                        <li>Are there master degree options for this degree?</li>
                                    </ul>
                                </Accordion.Collapse>
                            </Accordion>
                        </Col></Row>
                    </Col></Row>}
            {showPreview &&
                <>
                <Alert textOnly variant="negative" text="The following is a preview of the messages to be sent to each institute. 
                    Please scroll and preview these before pressing the <strong>Send All</strong> button below." />
                {Object.entries(courseSelections.reduce((a, c) => ({
                    ...a,
                    [c.institution] : [ ...(a[c.institution] || []), c]
                }), ({} as {[k:string]:AtarResult[]}))).map(([i,v] : [string, AtarResult[]]) =>
                    <BaseCard className="mb-16">
                        <Row><Col><h4>{i}</h4></Col></Row>
                        <Row><Col>
                            <Letter
                                courses={v}
                                emails={sendList}
                                extraInfo={extraInfo}
                                comment={additionalComment}
                                studentName={studentName}
                                studentYear={studentYear}
                                caName={sendingDetails.caName}
                                schoolName={sendingDetails.schoolName}
                            />
                        </Col></Row>
                    </BaseCard>
                )}
                </>
            }
            </Col></Row>
            <Row><Col className="text-right">
                {!showPreview && <>
                    <Button variant="neutral" className="mr-8" onClick={onHide}>Back</Button>
                    <Button variant="active" onClick={previewMessageButton}>Preview Message</Button>
                </>}
                {showPreview && <>
                    <Button variant="neutral" className="mr-8" onClick={()=>setShowPreview(false)}>Back</Button>
                    <Button variant="active" onClick={sendMessage}>Send All</Button>
                </>}
            </Col></Row>
        </Modal>;
    }

export default ContactInstsModal;
