import { fetchWithAuth } from "./Utils";

export type PreworkStatus = {
    doneWhs:boolean,
    doneInterview:boolean,
    doneColdCall:boolean,
    doneCareer:boolean,
    doneLitNum:boolean,
    doneProject:boolean,
    doneEmploymentSkills:boolean,
    doneSoftSkills:boolean,
    doneResume:boolean,
    doneDressing:boolean,
}

const PlanAPI = {
    getPreworkStatus: () : Promise<PreworkStatus> => 
        fetchWithAuth("/api/plan/preWorkCheck").then(r=>r.json())
}

export default PlanAPI;