import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from "react";
import UniLogos from "../../assets/UniLogos";

const partners = [
    UniLogos["UTS"],
    UniLogos["Mq"],
    UniLogos["UNSW"],
    UniLogos["USYD"],
    require("../../assets/uni-logos/tafe-nsw-logo.png"),
    UniLogos["WSU"],
    UniLogos["CSU"],
    UniLogos["UNE"],
    UniLogos["SouthernCross"],
    UniLogos["Newcastle"],
    UniLogos["NotreDame"],
    UniLogos["Wollongong"],
    UniLogos["ACU"],
    UniLogos["Canberra"],
    require("../../assets/uni-logos/det.png"),
]

const PartnerSlider : React.FC = () => {
    return <CarouselProvider
        naturalSlideHeight={256}
        naturalSlideWidth={800}
        isPlaying={true}
        infinite={true}
        interval={2000}
        visibleSlides={6}
        totalSlides={partners.length}>
            <Slider>
                {partners.map((p, i) => <Slide index={i} key={i}><img style={{width:"100%", height:"100%", objectFit:"contain"}} src={p} /></Slide>)}
            </Slider>
    </CarouselProvider>
}

export default PartnerSlider;