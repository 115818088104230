import React from "react";
import Button from "../../components/Button";
import CardButton from "../../components/cards/CardButton";
import { TextArea } from "../../components/Form";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { StagePropsBase } from "./common";

const AdditionalThings = [
    "Scholarships",
    "Alternative Entry",
    "Adjustment Factors",
    "Early Offers/Entry",
    "Accommodation",
    "Aboriginal Support",
    "Deferrment",
    "Open Days"
]

const DetailsCourseSelect : React.FC<StagePropsBase> = ({response, setResponse, onContinue}) => {
    return <>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Is there any other information or questions you have about Uni?</h3>
            </Col>
        </PageSectionRow>
        <Row small>
            <h4>Select any of the following that you are interested in:</h4>
            <Col className="mt-32">
                {AdditionalThings.map(s => 
                    <CardButton 
                        key={s}
                        className="mb-8"
                        variant="active"
                        isSelected={(response.additionalInformation || []).includes(s)} 
                        onClick={()=>setResponse(r=>!r?r:({
                            ...r,
                            additionalInformation:r.additionalInformation.includes(s) ?
                                r.additionalInformation.filter(i=>i!==s) :
                                r.additionalInformation.concat([s])
                        }))}>{s}</CardButton>
                )}
            </Col>
        </Row>
        <Row small>
            <Col className="mt-64">
                <h4>Do you have an additional question? Enter it below:</h4>
                <TextArea value={response.additionalComments} onChange={value=>setResponse(r=>!r?r:({...r, additionalComments:value}))} />
            </Col>
        </Row>
        <Row small>
            <Col className="text-right mt-32">
                <Button variant="active" onClick={onContinue}>Finish</Button>
            </Col>
        </Row>
    </>;
}

export default DetailsCourseSelect;