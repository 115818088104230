import React from "react"
import ContentWrapper from "../components/ContentWrapper";
import { Col } from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";

const LessonPlans: React.FC<{}> =
    () =>
        <ContentWrapper title="Lesson Plans" >
            <PageSectionRow><Col>
                <ul>
                    <li><a href={require("../assets/docs/lessons/Adjustment Factors.pdf")}>Adjustment Factors</a></li>
                    <li><a href={require("../assets/docs/lessons/Cold Canvassing.pdf")}>Cold Canvassing</a></li>
                    <li><a href={require("../assets/docs/lessons/Community Service.pdf")}>Community Service</a></li>
                    <li><a href={require("../assets/docs/lessons/Employment skills for my chosen career.pdf")}>Employment skills for my chosen career</a></li>
                    <li><a href={require("../assets/docs/lessons/Finding my career options.pdf")}>Finding my career options</a></li>
                    <li><a href={require("../assets/docs/lessons/GAP.pdf")}>GAP</a></li>
                    <li><a href={require("../assets/docs/lessons/How to Dress for the Interview.pdf")}>How to Dress for the Interview</a></li>
                    <li><a href={require("../assets/docs/lessons/Knowing Your Career.pdf")}>Knowing Your Career</a></li>
                    <li><a href={require("../assets/docs/lessons/Knowing my workplace literacy and numeracy.pdf")}>Knowing my workplace literacy and numeracy</a></li>
                    <li><a href={require("../assets/docs/lessons/Letter Writing to an Employer.pdf")}>Letter Writing to an Employer</a></li>
                    <li><a href={require("../assets/docs/lessons/Making a Career Plan.pdf")}>Making a Career Plan</a></li>
                    <li><a href={require("../assets/docs/lessons/Resume Plus.pdf")}>Resume Plus</a></li>
                    <li><a href={require("../assets/docs/lessons/Soft skills.pdf")}>Soft skills</a></li>
                    <li><a href={require("../assets/docs/lessons/Subject Selection Year 10s Planning for Year 12.pdf")}>Subject Selection Year 10s Planning for Year 12</a></li>
                    <li><a href={require("../assets/docs/lessons/The Interview.pdf")}>The Interview</a></li>
                    <li><a href={require("../assets/docs/lessons/eWork Experience.pdf")}>eWork Experience</a></li> 
                </ul>
            </Col></PageSectionRow>
        </ContentWrapper>
export default LessonPlans;