import {Resume as ResumeType} from "jobjump-types/Resume";
import { fetchWithAuth } from "./Utils";

type ResumeList = {
    id:number,
    name:string
}

export type ResumeKinds = 'career'|'category'|'general';

export type ResumeResponse = {
    resume?: ResumeType,
    contentId? : {
        kind: ResumeKinds,
        id?: number
    } 
}

const Resume = {
    listResumes : () : Promise<ResumeList[]> =>
        fetchWithAuth("/api/resume/list").then((r) => r.json()).catch(console.log),

    getResume : (id:number) : Promise<ResumeResponse> =>
        fetchWithAuth("/api/resume/"+id).then((r) => r.json()).catch(console.log),

    createResume : (type:ResumeKinds, id?:number) : Promise<number> =>  
        fetchWithAuth(`/api/resume/create/${type}${id?`/${id}`:""}`, {method:"POST"}).then((r) => r.json()).catch(console.log),

    saveResume : (id:number, resume:ResumeType) =>
        fetchWithAuth(`/api/resume/save/${id}`, {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(resume)
        }).then(async (r) => {
            if (r.ok) {
                return;
            }
            throw new Error(await r.text());
        }),
};

export default Resume;