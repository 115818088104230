import React from "react";
import { TafeCourseEntry, TafeCourseOffering } from "jobjump-types/TAFE";
import {filterOfferingList} from "../../data/TAFE";
import {Row, Col} from "../../components/grid";
import { openLinkInNewTab } from "../../util";
import {EventTracking} from "../../data/EventTracking";
import Modal from "../../components/Modal";
import {Select} from "../../components/Form";
import Button from "../../components/Button";

const generateEnquiryLink = (course:TafeCourseEntry, offering:TafeCourseOffering) => {
    const rtoMap : {[k:string]:string} = {
        "OTEN Institute": "online",
        "Hunter Institute": "hunter+%26+central+coast",
        "South Western Sydney Institute": "south+western+sydney", 
        "Riverina Institute": "riverina",
        "Western Sydney Institute": "western+sydney",
        "Western Institute": "western+nsw",
        "The Northern Sydney Institute": "northern+sydney",
        "Sydney Institute": "sydney+metro",
        "New England Institute": "new+england",
        "Illawarra Institute": "illawarra",
        "North Coast Institute": "north+coast"
    };
    const rtoName = rtoMap[course.institute];
    console.log(course, offering, rtoName, `https://tafensw.custhelp.com/app/contact-us/course?rto=${rtoName}&product-code=${course.tafe_id}&calocc=${offering.calendar_occurance_code}&publishedname=${course.course_name}`);
    if (!rtoName) { return undefined }
    return `https://tafensw.custhelp.com/app/contact-us/course?rto=${rtoName}&product-code=${course.tafe_id}&calocc=${offering.calendar_occurance_code}&publishedname=${course.course_name}`;
}

const AskAQuestionModal : React.FC<{show:boolean, onHide:()=>void, course:TafeCourseEntry, unauth?:boolean}> =
({show, onHide, course, unauth}) => { 
    const [offeringID, setOfferingID] = React.useState<string>();
    const [enquireURL, setEnquireUrl] = React.useState<string>();
    const [phone, setPhone] = React.useState<string>();
    React.useEffect(() => {
        const offering = course.offerings.find(o=>o.offering_id === offeringID);
        if (!offering) { setEnquireUrl(undefined); setPhone(undefined); return; }
        setEnquireUrl(generateEnquiryLink(course, offering));
        setPhone(course.phone);
    }, [offeringID, course, setEnquireUrl, setPhone]);
    const now = new Date(Date.now());
    const openLink = React.useCallback(async () => {
        if (enquireURL) {
            if (unauth) {
                await EventTracking.trackUnauthEvent("tafe_enquire", String(course.jj_id));
            } else {
                await EventTracking.trackEvent("tafe_enquire", String(course.jj_id));
            }
            openLinkInNewTab(enquireURL);
        }
    },[enquireURL, unauth]);
    return <Modal show={show} onHide={() => {setOfferingID(undefined); onHide()}} title="Ask A Question">
        <Row><Col>
            <h4>Please select the study mode you wish to enquire about</h4>
            <Select
                placeholder="Study mode..."
                onChange={(v)=>v&&setOfferingID(v.value)}
                options={filterOfferingList(course.offerings).map((d:any) =>
                    ({
                        value:d.offering_id, 
                        label:`${d.mode} ${d.anytime ? "(Anytime)" : (new Date(d.startDate)).getMilliseconds() < now.getMilliseconds() ? "" : `(Start: ${d.startDate})`}`
                    })) 
                } />
            <Row className="mt-16">
                {enquireURL && <Col className="text-center">
                    <Button variant="active" onClick={openLink}>Enquire Online</Button>
                </Col>}
                {enquireURL && phone && <Col sm={1} className="text-center">
                    <h5>OR</h5>
                </Col>}
                {phone && <Col className="text-center">
                    <h5>Phone: {phone}</h5>
                </Col>}
            </Row>
        </Col></Row>
    </Modal>
}

export default AskAQuestionModal;