import React from "react";
import { RouteComponentProps } from "react-router";
import {Row, Col} from "../../components/grid";
import Careers from "../../data/Careers";
import CareerImages from "../../assets/CareerImages";
import { openLinkInNewTab } from "../../util";
import CardGrid from "../../components/CardGrid";
import Button from "../../components/Button";
import ImageCard from "../../components/cards/ImageCard";
import SoftSkillsImg from "../../assets/homepage/tiles/getting-you-tax-file-number.jpg";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";
import ReactMarkdown from "react-markdown";
import StudentFileUpload, { StudentFile } from "../../data/StudentFileUpload";
import Dropzone from "react-dropzone";
import { uploadFileWithSignedPOST } from "../../data/FileUploadUtil";
import Modal from "../../components/Modal";
import MakeResumeJJ from "../../assets/my_job_ads/make-resume-jj.jpg";
import Certificate from "../../assets/homepage/certificate-to-modify.png";
import Checklist from "../../assets/homepage/checklist.jpg";
import MeetingEmployer from "../../assets/my_job_ads/meeting-employer.jpg";
import { WorkExperienceProject, WorkExperienceProjectResponse } from "jobjump-types/WorkExperience";
import WorkExperienceAPI from "../../data/WorkExperience";
import { Check } from "../../components/Form";
import generateCertificate from "./eWorkExperienceCertificate";
import { PDFViewer } from "@react-pdf/renderer";
import UserDetailsAPI from "../../data/UserDetails";
import genereateReference from "./WorkExperienceReference";
import ResumeApi from "../../data/Resume";

interface MatchParams {
    career : string
}

const ProjectSection : React.FC<RouteComponentProps & {careerId:number, careerName:string}> =
    ({careerId, careerName, history}) => {
        const [uploadedProject, setUploadedProject] = React.useState<StudentFile>();
        const [file, setFile] = React.useState<File>();
        const [showConfirm, setShowConfirm] = React.useState(false);
        const [projectContent, setProjectContent] = React.useState<WorkExperienceProject>();
        const [projectProgress, setProjectProgress] = React.useState<WorkExperienceProjectResponse>([]);
        const [showCertificate, setShowCertificate] = React.useState(false);
        const [certificate, setCertificate] = React.useState<JSX.Element>();

        const [showReference, setShowReference] = React.useState(false);
        const [reference, setReference] = React.useState<JSX.Element>();

        const [studentName, setStudentName] = React.useState<string>();
        React.useEffect(() => {
            WorkExperienceAPI.getProject(careerId).then(setProjectContent)
        }, [careerId]);
        React.useEffect(() => {
            UserDetailsAPI.getDetails().then(d => setStudentName(`${d.first} ${d.last}`));
        }, []);
        const getProgress = React.useCallback(() => WorkExperienceAPI.getProjectStatus(careerId).then(setProjectProgress), [careerId])
        React.useEffect(() => {
            getProgress();
        }, [getProgress])
        const updateProject = React.useCallback((step:number) => {
            WorkExperienceAPI.updateStepProgress(careerId, step).then(()=>getProgress());
        }, [getProgress]);
        const getUploadedFile = () => 
            StudentFileUpload.getAllFiles().then(f => 
                setUploadedProject(f.find(i => i.career?.id === careerId && i.category === "workExp"))
            );
        React.useEffect(() => {
            getUploadedFile(); 
        }, [careerId]);
        const downloadCurrent = () => {
            if (!uploadedProject) {
                return;
            }
            StudentFileUpload.getDownloadLink(uploadedProject.id).then(i => i && openLinkInNewTab(i));
        }
        const deleteFile = () => {
            if (!uploadedProject) {
                return;
            }
            StudentFileUpload.deleteFile(uploadedProject.id).then(() => {
                getUploadedFile();
                setShowConfirm(false);
            })
        }
        const uploadFile = () => {
            if (!file) {
                return;
            }
            StudentFileUpload.getUploadLink({fileName:file.name, fileType:file.type}).then(details => {
                if (!details) {
                    return;
                }
                const {key, uploadDetails} = details;
                uploadFileWithSignedPOST(uploadDetails, file).then(() => {
                    StudentFileUpload.confirmUpload({key, category:"workExp", careerId}).then(() => {
                        getUploadedFile();
                    })
                });
            })
        }
        const loadCertificate = React.useCallback(() => {
            if (!uploadedProject || projectProgress.length === 0 || careerName === "" || !studentName) {
                return;
            }
            setCertificate(generateCertificate(studentName, careerName, projectProgress));
            setShowCertificate(true);
        }, [uploadedProject, careerName, projectProgress, studentName]);
        const loadReference = React.useCallback(() => {
            if (!uploadedProject || projectProgress.length === 0 || !studentName) {
                return;
            }
            setReference(genereateReference(studentName, projectProgress));
            setShowReference(true);
        }, [uploadedProject, careerName, projectProgress, studentName]);
        const loadResume = () => {
            ResumeApi.createResume("career", careerId).then(id => history.push(`/resume/${id}`));
        }
        const buttonsDisabled = !uploadedProject || projectProgress.length === 0;
        return <>
            <PageSectionRow>
                <Col>
                    <h2>Your Project</h2>
                </Col>
            </PageSectionRow>
            {projectContent && <Row>
                <Col md={8}>
                    <ReactMarkdown>{projectContent.title}</ReactMarkdown>
                    <p>Follow as many of the steps below as you can. These are real skills for your chosen career that will impress a future employer.</p>
                    <p><ul>
                        {projectContent.currentText.map(c => <li key={`step-${c.id}`}>{c.text}</li>)}
                    </ul></p>
                </Col>
                <Col>
                    <img width="100%" alt={careerName} src={CareerImages[careerName.toUpperCase() || ""]} /> 
                </Col>
            </Row>}
            <PageSectionRow>
                <Col>
                    <h2>Your Results</h2>
                </Col>
            </PageSectionRow>
            {projectContent && <Row>
                <Col className="text-center">
                    <div className="text-left">
                        {!uploadedProject && <Row><Col><p><strong>Finished your project? Complete the checklist below and upload a document containing your work.</strong></p></Col></Row>}
                        {!uploadedProject && <Row><Col><p>You'll then recieve your eWork Experience Certificate to download along with your Real Work Experience Reference and updated Resume.</p></Col></Row>}
                        {uploadedProject && <Row><Col><p><strong>Your eWork Experience project has been uploaded!</strong> You can review the steps you completed and download a copy of your project below.</p></Col></Row>}
                        <Row>
                            <Col><p>
                                I have completed:
                                <div className="ml-16">
                                    {projectContent.pastText.filter(p=>p.text !== "").map(p =>
                                        <Check 
                                            disabled={uploadedProject !== undefined}
                                            checked={projectProgress.find(r => r.id === p.id) !== undefined} 
                                            className="block" 
                                            label={p.text} 
                                            key={`past-tense-step-${p.id}`} 
                                            onChange={()=>{updateProject(p.id)}} />)}
                                </div>
                            </p></Col>
                        </Row>
                        {!uploadedProject && <Row>
                            <Col>
                                <Dropzone onDrop={(fs) =>setFile(fs[0])}>
                                    {({getRootProps, getInputProps}) => (
                                        <>{!file && <div {...getRootProps()} className="upload-zone">
                                            <input {...getInputProps()} />
                                            <p>Drag your file here, or click here to select a file.</p>
                                        </div>}
                                        {file && <div className="upload-zone">
                                            <div>File Name: {file.name}</div>
                                            <Button variant="active" onClick={uploadFile}>Upload My Project</Button>
                                            <Button className="ml-8" variant="neutral" onClick={()=>setFile(undefined)}>Clear</Button>
                                        </div>}
                                        </>
                                    )}
                                </Dropzone>
                            </Col>
                        </Row>}
                        {uploadedProject && <Row>
                            <Col className="text-center">
                                <Button variant="active" onClick={downloadCurrent}>Open My Project</Button>
                                <Button variant="negative" className="ml-8" onClick={()=>setShowConfirm(true)}>Resubmit</Button>
                            </Col>
                        </Row>}
                        </div>
                </Col>
            </Row>}
            <PageSectionRow>
                <Col>
                    <h2>Downloads</h2>
                    <CardGrid numPerRow={3} prefix="success-ework-buttons">
                        <ImageCard disabled={buttonsDisabled} imageUrl={Certificate} text="My eWork Experience Certificate" onClick={loadCertificate} />
                        <ImageCard disabled={buttonsDisabled} imageUrl={Checklist} text="My Real Work Experience Reference" onClick={loadReference} />
                        <ImageCard disabled={buttonsDisabled} imageUrl={MakeResumeJJ} text="My Resume" onClick={loadResume} />
                    </CardGrid>
                </Col>
            </PageSectionRow>
            <Modal title="Certificate" show={showCertificate} onHide={()=>{setShowCertificate(false)}} size="lg">
                {certificate && <Row className="mt-16">
                    <Col>
                        <PDFViewer width="100%" height="800">
                                {certificate}
                        </PDFViewer>
                    </Col>
                </Row>}
            </Modal>
            <Modal title="Reference" show={showReference} onHide={()=>{setShowReference(false)}} size="lg">
                {reference && <Row className="mt-16">
                    <Col>
                        <PDFViewer width="100%" height="800">
                                {reference}
                        </PDFViewer>
                    </Col>
                </Row>}
            </Modal>
            <Modal title="Confirm Resubmit" show={showConfirm} onHide={()=>setShowConfirm(false)}>
                <Row>
                    <Col className="text-center">
                        <h4>Are you sure you want to resubmit? Doing so will delete your currently uploaded project from JobJump.</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="active" onClick={deleteFile}>Confirm</Button>
                        <Button variant="negative" className="ml-8" onClick={deleteFile}>Cancel</Button>
                    </Col>
                </Row>
            </Modal>
        </>
    }

const WorkExperienceProjectPage : React.FC<RouteComponentProps<MatchParams>> =
    (props) => {
        const [careerName, setCareerName] = React.useState("");
        const {match, history} = props;
        React.useEffect(() => {
            Careers.getCareerList().then(l => {
                const c = l.find(c => c.id === Number(match.params.career));
                if (c) { setCareerName(c.name) }
            })
        }, [match.params.career])
        return <ContentWrapper
                title={"eWork Experience - "+careerName}
                breadcrumbTitle={careerName}
                subtitle={["Get involved in your favourite career! Complete the activities below."]}
                breadcrumbs={[{name:"eWork Experience", link:"/eWorkExperience"}]}>
            <ProjectSection careerId={Number(match.params.career)} careerName={careerName} {...props} />
            <PageSectionRow>
                <Col>
                    <h2>What Next?</h2>
                    <CardGrid prefix="whats-next" numPerRow={3}>
                        <ImageCard imageUrl={SoftSkillsImg} text="How to Settle Quickly Into The Workplace" onClick={()=>history.push("/softSkills")} />
                        <ImageCard imageUrl={MeetingEmployer} text="Real Work Experiences" onClick={()=>history.push("/workExperience/"+match.params.career)} />
                    </CardGrid>
                </Col>
            </PageSectionRow>
        </ContentWrapper>
    }

export default WorkExperienceProjectPage;