import { IconSearch } from "@tabler/icons";
import { StudentUniContact } from "jobjump-types/CaStudents";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import deepEqual from "fast-deep-equal";
import Alert from "../../components/Alert";
import CaStudentTable from "./CaStudentTable";
import Modal from "../../components/Modal";
import RowCard from "../../components/RowCard";
import { Check } from "../../components/Form";
import { uniConfig } from "../uni/UniLinkData";

const StudentUniContactPrefs : React.FC<RouteComponentProps> = (props) => {
    const [pendingRequests, setPendingRequests] = React.useState<StudentUniContact[]>([]);
    const [data,setData] = React.useState<StudentUniContact[]>([]);
    const [selectedApprovals, setSelectedApprovals] = React.useState<StudentUniContact[]>([]);
    const [showMoreContact, setShowMoreContact] = React.useState<StudentUniContact>();
    const updateData = () => {
        CaStudentDetails.getStudentUniPrefs().then(setData);
        CaStudentDetails.getStudentUniContactPendingApprovals().then(setPendingRequests)
    }
    React.useEffect(() => {
        updateData();
    }, []);
    const updateApproval = () => {
        CaStudentDetails.approveStudentContact(selectedApprovals.map(d=>({user_id:Number(d.student.id)})))
            .then(() => updateData());
    }
    return <CaAdminLayout 
        active="preferences"
        title="Preliminary Uni" 
        {...props}>
            {pendingRequests.length > 0 && <PageSectionRow>
                <Col className="text-center">
                    <Alert textOnly variant="negative" text="Check and approve your students' interests below." />
                    <h4>Tick the box next to each student's name to check and approve their choices, then click "Submit". Leave unticked students you wish to consult.</h4>
                    <h4>Univeristies can then assist your students directly to meet their specified needs.</h4>
                </Col>
            </PageSectionRow>}
            {pendingRequests.length > 0 && <PageSectionRow>
                <Col>
                    <CardTable
                        data={pendingRequests}
                        renderToolbar={()=><>
                            <Button variant="active" onClick={updateApproval} disabled={selectedApprovals.length === 0}>Submit</Button>
                        </>}
                        columns={[
                            {
                                Header:"Name",
                                Cell:({row:{original}})=><>
                                    <Check 
                                        onChange={() => setSelectedApprovals(s => s.find(i => deepEqual(i,original)) ? 
                                            s.filter(i => !deepEqual(i,original)) : s.concat([original]))
                                        }
                                        label={`${original.student.last_name}, ${original.student.first_name}`}
                                        checked={selectedApprovals.find(i=>deepEqual(i,original)) !== undefined} />
                                </>,
                                width:2
                            },
                            {
                                Header:"Year",
                                accessor:"student.year",
                                width:1
                            },
                            {
                                Header:"University",
                                Cell:({row:{original:{uniChoice,student}}})=><>{uniChoice.institutes.map(i=><div key={`${i}-${student.id}`}>{Object.values(uniConfig).find(c=>c.id === String(i))?.abbr}</div>)}</>,
                                width: 1
                            },
                            {
                                Header:"Category/Degree",
                                Cell:({row:{original:{uniChoice,student}}})=><>
                                    {uniChoice.courses.map(c => <div className="mb-16" key={c.course_id}>{c.course_name} - {Object.values(uniConfig).find(i=>i.id === String(c.institute_id))?.abbr} ({c.course_atar})</div>)}
                                    {uniChoice.categories?.filter(c=>
                                        uniChoice.courses.find(i =>i.category_name === c) === undefined
                                    ).map(i=><div className="mb-16" key={`${i}-${student.id}`}>{i}</div>)}
                                </>,
                                width:6
                            },
                            {
                                Header:"Other Needs",
                                width:2,
                                Cell:({row:{original:{uniChoice,student}}})=><>
                                    {(uniChoice.additionalInformation || []).map(i=><div key={`${i}-${student.id}`}>{i}</div>)}
                                    {uniChoice.additionalComments && uniChoice.additionalComments!=="" && <div>{uniChoice.additionalComments}</div>}
                                </>
                            }
                        ]}
                    />
                </Col>
            </PageSectionRow>}
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Preliminary Uni</h3>
                    <h4>Below are your students' university interests as indicated in the "Preliminary Uni" section of JobJump.</h4>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        hideEmail
                        additionalColumns={[
                            {
                                Header:"University",
                                accessor:(original)=>original.uniChoice.institutes[0]||"",
                                width:3
                            },
                            {
                                Header:"Categories",
                                width:3,
                                accessor:(original)=>(original.uniChoice.categories||[]).join(",")
                            },
                            {
                                Header:"More Info",
                                Cell:({row:{original}})=>
                                    original.uniChoice.additionalComments !== "" || original.uniChoice.additionalInformation.length>0 ?
                                    <IconSearch className="pointer" onClick={()=>setShowMoreContact(original)} /> : <></>
                            }
                        ]} />
                </Col>
            </PageSectionRow>
            {showMoreContact && <Modal show={showMoreContact!==undefined} onHide={()=>setShowMoreContact(undefined)}>
                <Row>
                    <Col className="text-center">
                        <h3>{showMoreContact.student.first_name} {showMoreContact.student.last_name}</h3>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col>
                        <h4>Additional Questions</h4> 
                        {showMoreContact.uniChoice.additionalInformation.map(i => <RowCard key={i}>{i}</RowCard>)}
                        {showMoreContact.uniChoice.additionalComments!=="" && <RowCard>{showMoreContact.uniChoice.additionalComments}</RowCard>}
                    </Col>
                </Row>
            </Modal>}
    </CaAdminLayout>
}

export default StudentUniContactPrefs;