import { CaNewsItem } from "jobjump-types/CaNews";
import React from "react";
import ReactMarkdown from "react-markdown";
import { makeClassName } from "../util";
import BaseCard from "./BaseCard";
import "./noticeboardCard.css";

interface NoticeboardCardProp {
    newsItem: CaNewsItem,
    className: string
}

const NoticeboardCard : React.FC<NoticeboardCardProp> = ({className, newsItem}) => 
    <BaseCard className={makeClassName(className, ["jobjump-noticeboard-card"])}>
        <h4>{newsItem.title}</h4>
        <div><ReactMarkdown>{newsItem.body}</ReactMarkdown></div>
        <small className="jobjump-noticeboard-date">{new Date(newsItem.date).toDateString()}</small>
    </BaseCard>

export default NoticeboardCard;