import _ from "lodash";
import React from "react";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import CardButton from "../../components/cards/CardButton";
import { Col, Row } from "../../components/grid";
import { CwizInfoType } from "../../data/Cwiz";
import { CwizProps } from "./common";

const Skills : {[k:string]:any} = {
  "Agriculture, Animal and Primary Industries" : ["going on bushwalks and camping", "looking after an animal or pet", "growing different types of plants", "learning how to help animals be healthy", "designing or building a landscape", "making the environment better", "learning about the health of an animal I like", "investigating the way animals behave" ],
  "Architecture, Building, Construction and Planning" : ["learning how to design a building ", "drawing plans", "helping in building or fixing a house", "designing or building a landscape", "seeing how bridges or towers are made", "having ideas to make the inside of a room more interesting", "building a small piece of furniture", "enjoying exploring different places around the city", "learning about how an electrician, a carpenter or a plumber fixes things" ],
  "Art, Design, Music and Entertainment" : ["making or helping in making a film", "creating or designing interesting pictures", "having ideas to make the inside of a room more interesting", "taking  interesting photographs ", "dancing or planning dance moves", "making my own website", "playing a musical instrument or making my own music", "planning a special event for a friend or someone in my family" ] ,
  "Business, Administration and Sales" : ["watching or reading about the financial news and how wealth is made", "planning a special event for a friend or someone in my family", "helping with organising or filing things, sending messages, planning meeting people", "keeping an eye on buying things", "reading about interesting places to visit", "looking at homes and land that I might like to buy one day", "learning how to save money and how to budget my money", "planning how to organise things better" ] ,
  "Chemical and Materials" : ["doing scientific experiments and finding out the results", "investigating and learning about different materials we use every day", "learning about how different chemicals or medicines can help us feel better", "finding out clues and collecting evidence to a puzzling question", "knowing what foods we eat can help us " ] ,
  "Computing and Information Technology" : ["reading and learning how to code", "designing my own website", "writing code to make a program ", "planning and creating ideas for computer games or programs", "investigating  the parts of a computer or an electronic machine and seeing how it works", "making an electronic hobby kit", "connecting parts of a computer, printer and other IT devices", "helping others and showing them how to use apps and other mobile devices", "creating designs and images on the computer ", "solving problems in mathematics" ] ,
  "Education" : ["learning more about one or more of the subjects I study", "to help friends with their school work ", "learning how to improve my knowledge", "creating a lesson for younger people in a subject I like studying", "looking for important ideas in the things I read", "researching something special in one of the subjects I like", "to show my friends or family when I learn something new  ", "hearing the news in the world on all things that are happening" ] ,
  "Engineering and Technical" : ["fixing things that are broken", "designing a way to make something work better", "to find out how things work by investigating them or pulling them apart", "designing new shapes of things we can use", "making an electronic hobby kit", "investigating  the parts of a machine and seeing how it works", "working with mechanical parts ", "reading about great designs of bridges, machines, planes or cars.", "doing scientific experiments and finding out the results" ] ,
  "Food Production" : ["learning how different foods can help our bodies", "watching cooking shows and looking out for and trying out new recipes", "doing scientific experiments and finding out the results", "investigating how the chemicals in what we eat can change our health", "buying and trying different foods and ingredients ", "experimenting to see what foods and spices mix well together", "learning how different foods can change our moods", "creating new recipe ideas" ] ,
  "Furniture and Wood" : ["designing plans for furniture I like", "helping in building or fixing a house", "re-designing the inside of a room", "finding out how good furniture is made", "going to a Home Show and seeing the new products", "using wood tools to make a small piece of furniture", "watching how a house frame is being built ", "learning about how a carpenter fixes things" ] ,
  "Health, Medicine, Community and Beauty" : ["investigating the human body and how it works", "helping a person if they needed advice", "to be able to care for someone when they are sick", "researching a disease and one day to help find a cure", "to help find out ways to make someone feel better, healthier or happier", "doing scientific experiments and finding out the results", "learning how the mind works and how people think", "planning ways to become fitter and healthier" ] ,
  "Hospitality and Tourism" : ["trying new recipe ideas from different places", "planning a special event for a friend or someone in my family", "visiting new countries and learning new things about each country", "discovering new places to travel ", "investigating new cultures and understanding their history, customs, language and art", "learning another language", "collecting information on interesting places to go and visit", "helping a person if they needed directions" ] ,
  "Law, Policing and Defence" : ["listening to the news and paying attention to new laws and crime", "helping a person if they needed advice", "learning self defence", "investigating a problem and finding a solution", "being involved in team activities ", "doing scientific experiments and finding out the results", "being part of a debate" ] ,
  "Literature, Arts and Social Science" : ["investigating new cultures and understanding their history, customs, language and art", "writing stories from my own ideas", "learning another language", "listening to the news and paying attention to changes in our own country or other countries", "helping a person if they needed advice", "collecting information on interesting places to go and visit", "going to the museum ", "reading history books or watching history shows and movies" ] ,
  "Metal, Electrical and Automotive" : ["working with mechanical parts ", "finding out how different machines work", "reading about different cars and what makes them special", "making an electronic hobby kit", "doing scientific experiments and finding out the results", "taking apart a broken tool and then putting it back together", "reading diagrams for assembling something like a piece of furniture", "going to our mechanic or to a motor show " ] ,
  "Science and Mathematics" : ["solving mathematical problems or doing puzzles", "doing scientific experiments and finding out the results", "researching a disease and one day to help find a cure", "doing things to make the environment better", "investigating the way animals behave", "looking through a telescope and learning about the stars, planets and the universe", "going to the museum ", "going on bushwalks and camping" ] ,
  "Textiles, Clothing and Footwear" : ["reading fashion magazines or websites", "keeping an eye on the latest fashion trends", "making my own clothes from my own designs", "drawing ideas for new clothes", "going to the shops and looking at clothes and shoes", "reading diagrams for assembling something ", "making colourful patterns", "repair clothes if they need fixing" ] ,
  "Transport and Storage" : ["working with mechanical parts ", "finding out how different machines work", "taking apart a broken tool and then putting it back together", "solving mathematical problems or doing puzzles", "arranging things in a room in a way that is orderly and easy to find", "planning directions on a map ", "visiting new places or countries and learning new things about each country", "investigating how a plane, train, car, bike or truck works" ] 
}

const CwizSkills : React.FC<CwizProps & {categories:CwizInfoType[]}> = ({categories, updateResponse, response: results, goToPage}) => {
    const [selectedSkills, setSelectedSkills] = React.useState<string[]>(results.skills);
    const [currentSkills, setCurrentSkills] = React.useState<string[]>([]);
    const [showWarning, setShowWarning] = React.useState(false);
    React.useEffect(() => {
        const category = categories.find(c => c.id === (results.category || -1));
        console.log(results, categories, category);
        if (!category) {
            setCurrentSkills([]);
            return;
        }
        setCurrentSkills(Skills[category.name] || []);
    }, [categories,setCurrentSkills, results])
    return <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Skills &amp; Values</h2>
                <h3>Select all the options below that you enjoy or are good at</h3>
                {showWarning && <div>
                    <Alert textOnly variant="negative" text="You must pick at least one of the skills below. If you cannot find a skill that suites you, you may wish to pick another category." />
                    <Button variant="neutral" onClick={()=>goToPage("categories")}>Pick Another Category</Button>
                </div>}
            </Col>
        </Row>
        <Row small>
            <Col className={"mt-32"}>
                {currentSkills.map(s => {
                    const inSet = selectedSkills.includes(s);
                    return <CardButton className="mb-16" variant="active" isSelected={inSet} onClick={()=>setSelectedSkills(!inSet?[...selectedSkills, s]:selectedSkills.filter(x=>x!==s))}>
                        {s}
                    </CardButton>
                })}
                <div className="text-right">
                    <Button onClick={()=>selectedSkills.length > 0 ? updateResponse({...results, skills:selectedSkills}, "values") : setShowWarning(true)} variant="active">Continue</Button>
                </div>
            </Col>
        </Row>
    </>;
}

export default CwizSkills;