import React from "react";
//import { Row, Col } from "../../components/grid";
//import FilterableGrid from "../../components/FilterableGrid";
import { RouteComponentProps } from "react-router";
//import {TafeImageMap} from "./TafeImageMap";
//import TafeAPI from "../../data/TAFE"
//import { TafeCategory } from "jobjump-types/TAFE";
//import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";
import StateBasedPage from "../../components/StateBasedPage";
import TafeCareerLinks from "./TafeCareerLinks";

/*const TafeCoursesListingNSW : React.FC<RouteComponentProps<{}>> =
({history}) => {
    const [tafeCategories, setTafeCategories] = React.useState<TafeCategory[]>([]);
    React.useEffect(() => {
        TafeAPI.getTafeCategories().then(setTafeCategories);
    }, []);
    return <ContentWrapper
            title="TAFE Course Search"
            subtitle={["Want to study at TAFE? Select a category below to find your course."]} >
        <Row>
            <Col>
                <FilterableGrid
                    data={tafeCategories.map(c =>
                        ({
                            imgUrl:TafeImageMap[c.name],
                            text:c.name,
                            onClick:()=>{history.push({
                                    pathname: `/tafe-courses/${c.id}`,
                                });}
 
                        }))
                    }
                    numPerRow={3}
                    hideHeading
                />
            </Col>
        </Row>
    </ContentWrapper>
}*/

const TafeCoursesListing : React.FC<RouteComponentProps & UserDetailsProps> = ({userDetails, ...props}) => {
    return <StateBasedPage
        nswContent={<TafeCareerLinks {...props} />}
        //nswContent={<TafeCoursesListingNSW {...props} />}
        vicContent={<TafeCareerLinks {...props} />}
        userDetails={userDetails}
    />
}

export default TafeCoursesListing;