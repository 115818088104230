import _ from "lodash";
import React from "react";
import CardButton from "../../components/cards/CardButton";
import { Col, Row } from "../../components/grid";
import { CwizInfoType } from "../../data/Cwiz";
import { CwizProps } from "./common";

const PersonalityDescriptions : {[k:string]:string} = {
    'Analytic':"You like working out solutions to problems through investigation and experimentation.",
    'Creative':"You like to express yourself and your ideas through your work, often using your imagination and flair.",
    'Helping':"You have an interest in people's welfare, and an understanding and concern for others.",
    'Nature':"You like the natural environment and the physical world.",
    'Organising':"You like gathering facts and figures, and recording and interpreting them.",
    'Persuading':"You like selling, or promoting ideas or a point of view.",
    'Practical':"You like working with tools and equipment, and your hands, to make things."
}

const CwizPersonality : React.FC<CwizProps & {personalities:CwizInfoType[]}> = ({personalities, response: results, updateResponse: updateResults}) => 
    <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Personality</h2>
                <h3>Select which of the following best describes you</h3>
            </Col>
        </Row>
        <Row small>
            <Col className={"mt-32"}>
                {_.sortBy(personalities, (p)=>p.name).map(p =>
                    <CardButton isSelected={results.personality === p.id} variant="active" className="mb-16" onClick={()=>updateResults({...results, personality:p.id})} key={`p-${p.id}`}>
                        <h4>{p.name}</h4>
                        <p>{PersonalityDescriptions[p.name]}</p>
                    </CardButton>
                )}
            </Col>
        </Row>
    </>;

export default CwizPersonality;