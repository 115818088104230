import React from "react";
import {Col} from "../../components/grid";
import {AtarCategory, AtarResult} from "jobjump-types/ATAR";
import AtarAPI from "../../data/ATAR";
import { RouteComponentProps } from "react-router";
import AtarImages from "../../assets/AtarImages";
import FilterableGrid from "../../components/FilterableGrid";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";
import { CoursePreferences } from "jobjump-types/Courses";
import CoursePreferenceAPI from "../../data/CoursePreferences";
import ATARTable from "./ATARTable";

type Props = {
    kind: "scholarships"|"plan-b"
} & UserDetailsProps & RouteComponentProps


const ContactUniCategories : React.FC<Props> =
    (props) => {
        const {history} = props;
        const [atarCaregories, setAtarCategories] = React.useState<AtarCategory[]>([]);
        const [atarKeyWords, setAtarKeywords] = React.useState<string[]>([]);
        const [preferences, setPreferences] = React.useState<CoursePreferences[]>([]);

        React.useEffect(() => {
            CoursePreferenceAPI.getCoursePreferences().then((d) => { setPreferences(d.filter(c => c.uniCourse !== undefined)) });
        }, []);

        React.useEffect(() => {
            AtarAPI.getAtarCategories().then(d => {
                setAtarCategories(d)
            });
        }, []);
        React.useEffect(() => {
            AtarAPI.getAtarSubcategories().then(setAtarKeywords);
        }, []);
        const title = props.kind === "scholarships" ? "Scholarships" : "Plan B";
        const lower = props.kind === "scholarships" ? "scholarships" : "Plan B";
        return <ContentWrapper title={title} subtitle={[`Find ${lower} for your degree.`]} >
            {preferences.length > 0 && <PageSectionRow className="mt-16">
                <Col>
                    <h2>{title} for your preferences</h2>
                    <ATARTable showDetailsOf={props.kind} noDefaultFilter data={preferences.map(p => p.uniCourse) as AtarResult[]} {...props} />
                </Col>
            </PageSectionRow>}
            <PageSectionRow className="mt-64"><Col>
                {preferences.length > 0 && <h2>Find {lower} for another degree</h2>}
                <FilterableGrid
                    categories={atarKeyWords}
                    data={atarCaregories.map(c => 
                        ({
                            imgUrl:AtarImages[c.name.toUpperCase()] || "",
                            text:c.name,
                            subcategories:c.subcategories, 
                            onClick:()=>{history.push({pathname: `/atar/${props.kind}/${c.id}` });}
                        })
                    )}
                    numPerRow={3}
                    hideHeading
                />
            </Col></PageSectionRow>
        </ContentWrapper>
    }

export default ContactUniCategories;