import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";

type PageKey = "details" | "password" | "news";

const UpdateDetailsLayout : React.FC<RouteComponentProps & {title:string, subtitle?:string[], pageKey:PageKey}> =
({title, subtitle, pageKey, children, history}) =>
    <ContentWrapper title={title} subtitle={subtitle}>
        <PageSectionRow>
            <Col className="text-center">
                <Button className="mr-8" variant={pageKey === "details" ? "active" : "neutral"} onClick={()=>history.push("/details")}>Details</Button>
                <Button className="mr-8" variant={pageKey === "password" ? "active" : "neutral"} onClick={()=>history.push("/details/password")}>Password</Button>
                <Button className="mr-8" variant={pageKey === "news" ? "active" : "neutral"} onClick={()=>history.push("/details/news")}>News</Button>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                {children}
            </Col>
        </Row>
    </ContentWrapper>

export default UpdateDetailsLayout;