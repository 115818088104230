import React from "react";
import Button from "../../components/Button";
import RowCard from "../../components/RowCard";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import VideoModal from "../../components/VideoModal";
import { openLinkInNewTab } from "../../util";

const Data = [
    {name:"Know My Career", video:"547069438", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationKnowingYourCareer.pdf")},
    {name:"eWork Experience", video:"547069993", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationeWorkExperience.pdf")},
    {name:"Employment Skills", video:"547069413", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationEmploymentSkills.pdf")},
    {name:"Soft Skills", video:"547069514", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationSoftSkills.pdf")},
    {name:"Literacy & Numeracy", video:"547069462", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationLiteracyandNumeracy.pdf")},
    {name:"Resume", video:"547069486", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationResumeBuilder.pdf")},
    {name:"Cold Canvassing", video:"547069383", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationColdCanvassing.pdf")},
    {name:"The Interview", video:"547069541", worksheet:require("../../assets/docs/pre-work-check/WorkExperiencePreparationTheInterview.pdf")},
    {name:"WHS", video:"", worksheet:""},
]

const WorkPrepResources : React.FC = () => {
    const [videoId, setVideoId] = React.useState<string>();
    return <ContentWrapper title="Pre-Work Check Resources">
        <PageSectionRow>
            <Col>
                {Data.map(({name,video,worksheet}, i) => <RowCard key={`worksheet-${i}`}>
                    <Col sm={8}>{name}</Col>
                    <Col className="text-right">
                        <Button variant="active" onClick={()=>setVideoId(video)}>Video</Button>
                        <Button className="ml-8" variant="active" onClick={()=>openLinkInNewTab(worksheet)}>Worksheet</Button>
                    </Col>
                </RowCard>)}
            </Col>
        </PageSectionRow>
        {videoId && <VideoModal show={videoId!==undefined} onHide={()=>setVideoId(undefined)} vimeoId={videoId} />}
    </ContentWrapper>
}

export default WorkPrepResources;