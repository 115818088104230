import _ from "lodash";
import React from "react";
import CareerImages from "../../assets/CareerImages";
import FilterableGrid from "../../components/FilterableGrid";
import { Col, Row } from "../../components/grid";
import { CwizInfoType } from "../../data/Cwiz";
import { CwizProps } from "./common";

const CwizCategory : React.FC<CwizProps & {categories:CwizInfoType[]}> = ({categories, response: results, updateResponse: updateResults}) => 
    <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Skills &amp; Values</h2>
                <h3>Select one of the tiles below that best represents your interests.</h3>
            </Col>
        </Row>
        <Row>
            <Col className={"mt-32"}>
                <FilterableGrid
                    numPerRow={4}
                    data={categories.map(c => ({
                        imgUrl:CareerImages[c.name.toUpperCase()],
                        text:c.name,
                        onClick:()=>updateResults({...results, category:Number(c.id)}, "pictures")
                    }))}
                />
            </Col>
        </Row>
    </>;

export default CwizCategory;