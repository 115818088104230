import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";

const WhatToWriteWorkExp: React.FC<{}> =
    () => <ContentWrapper
            title="How to write a Cover Letter to get Work Experience"
            subtitle={["Need to prepare a Cover Letter to apply for Work Experience?", "This will get you started."]}
            breadcrumbs={[
                {name:"Work Experience", link:"/workExperience"}, 
            ]}>
        <PageSectionRow>
            <Col>
                <h2>Cover Letter</h2>
                <p className="mb-64"><strong>Here is an example of the main format of a Cover Letter when applying for Work Experience to a company. Note that the advice in the bracketed areas in italics is to guide you on what you may need to write. When you write to a company you can also add a couple of copies of references.</strong></p>
                <p>103 Pleasant Street				<em>(Your number and street address here)</em><br />
                    Townview NSW 2999				<em>	(Your suburb, state and post code here)</em><br />
                    M: 0433 999 888				<em>	(Mobile phone)</em><br />
                    E: jane.student@gmail.com			<em>	(Email address)</em><br />
                </p>
                <p className="mb-64">12 Feburary {(new Date()).getFullYear()}				<em>(The date you are writing this letter)</em><br />
                </p>
                <p className="mb-64">Ms Smith					<em>(Name of person you are writing to)</em><br />
                    The Manager					<em>(The position of person you are writing to)</em><br />
                    ACE Company Pty Ltd				<em>(The company's name)</em><br />
                    298 Best Avenue				<em>(The company's number and street)</em><br />
                    Greatville NSW 2998<em> (The company's suburb, state &amp; post code)</em><br />
                </p>
                <p>Dear Ms Smith,</p>
                <p>I am a student at …………………. <em>(name of your school)</em> and I wish to apply to your company for work experience as a …………………..<em>(name the career eg: mechanic)</em>  for …………….<em>(state here your work experience dates)</em>.</p>
                <p>I have demonstrated the qualities you require for this position. I am a person who is ………………<em>(state here the Personal Requirements that you possess and that the employer is seeking)</em>. </p>
                <p>My true passion has been to work in this field and as proof of this, in my spare time I have …………………..(<em>write here some of the great things you did as described in the eWork Experience project)</em>. </p>
                <p>I am writing to your company as it has a strong reputation for excellence in this field and I look forward to the opportunity to develop my abilities to their fullest potential.</p>
                <p>Find attached a copy of my resume which outlines in more detail the skills and experience I possess.</p>
                <p>I welcome the chance to meet with you to discuss the opportunity of doing work experience with your company. If you wish to contact me please reply using any of the contact details I have provided above.</p>
                <p className="mb-32">Yours sincerely,</p>
                <p>……………………<em>(Sign your name here)</em><br />
                    ……………………<em>(Type your name here)</em></p>
            </Col>
        </PageSectionRow>
    </ContentWrapper>

export default WhatToWriteWorkExp;