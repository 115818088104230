import _ from "lodash";
import React from "react";
import CareerImages from "../../assets/CareerImages";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { Col, Row } from "../../components/grid";
import { CwizProps } from "./common";

const CategoryWords : {[k:number]:string[]} = {
    /*Agriculture*/21: [
        "Animals",
        "Agriculture",
        "Animals",
        "Care",
        "Parks",
        "Nature",
        "Farm",
    ],
    /*Art & Design*/23: [
        "Ideas",
        "Perform",
        "Design",
        "Create",
        "Art",
        "Music"
    ],
    /*Business*/24: [
        "Money",
        "Buy",
        "Organise",
        "Wealth",
        "Selling",
        "Plan"
    ],
    /*Education*/27: [
        "Guide",
        "Help",
        "Learning",
        "Educate",
        "Knowledge",
        "Understanding"
    ],
    /*Enineering*/28: [
        "Experiment",
        "Solve",
        "Design",
        "Fix",
        "Construct",
        "Disassemble"
    ],
    /*Hospitality*/32: [
        "Event",
        "Service",
        "Travel",
        "Helpful",
        "Wecloming",
        "Host"
    ],
    /*Metal*/35: [
        "Electrical",
        "Practical",
        "Repair",
        "Solve",
        "Technical",
        "Fix"
    ],
    /*Textiles*/38: [
        "Style",
        "Design",
        "Create",
        "Patterns",
        "Fabric",
        "Shoes"
    ],
    /*Transport*/39: [
        "Trucks",
        "Trains",
        "Driving",
        "Travelling",
        "Freight",
        "Shipping"
    ],
    /*Architecture & Construction*/22: [
        "Buildings",
        "Design",
        "Cities",
        "Construct",
        "Plan",
        "Assemble"
    ],
    /*Computing*/26: [
        "Code",
        "Networks",
        "Design",
        "Problem Solve",
        "Technology",
        "Analyse"
    ],
    /*Food Production*/29: [
        "Produce",
        "Meat",
        "Cheese",
        "Fruit & Vegetables",
        "Baking",
        "Seafood"
    ],
    /*Health*/31: [
        "Fitness",
        "Medicine", 
        "Care",
        "Support",
        "Research",
        "Health"
    ],
    /*Law*/33: [
        "Defend",
        "Investigate",
        "Justice",
        "Law",
        "Safety",
        "Security"
    ],
    /*Science*/36: [
        "Mathematics",
        "Experiment",
        "Solve",
        "Investigate",
        "Laboratory",
        "Chemicals"
    ],
    /*Arts & Social Sci*/34: [
        "Writing",
        "History",
        "Stories",
        "Journalism",
        "Messaging",
        "Reading"
    ],
}

const CategoryImages : {[k:number]:string[]} = {
    /*Agriculture*/21: [
        CareerImages["ANIMAL ATTENDANT"],
        CareerImages["ENVIRONMENT"],
        CareerImages["FARMING & AGRICULTURE"],
        CareerImages["VETERINARY NURSE"],
        CareerImages["ZOOLOGIST"],
        CareerImages["LANDSCAPER"],
    ],
    /*Art & Design*/23: [
        CareerImages["DANCE"],
        CareerImages["MUSICIAN"],
        CareerImages["FILM DIRECTOR"],
        CareerImages["SOUND TECHNICIAN"],
        CareerImages["GRAPHIC DESIGNER"],
        CareerImages["PHOTOGRAPHER"],
    ],
    /*Business*/24: [
        CareerImages["ACCOUNTANT"],
        CareerImages["LAW CLERK"],
        CareerImages["RETAIL"],
        CareerImages["OFFICE CLERK"],
        CareerImages["MARKETING"],
        CareerImages["REAL ESTATE"],
    ],
    /*Education*/27: [
        CareerImages["CHILDCARE WORKER"],
        CareerImages["PRIMARY TEACHER"],
        require("../../assets/quiz-images/additional-mood-board-images/teacher.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/lecture.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/marking-work.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/science-teacher.jpg"),
    ],
    /*Enineering*/28: [
        CareerImages["AERONAUTICAL MECHANIC"],
        CareerImages["ELECTRICAL ENGINEER"],
        CareerImages["MECHANICAL ENGINEER"],
        CareerImages["MECHATRONIC ENGINEER"],
        CareerImages["MINING"],
        CareerImages["CIVIL ENGINEER"],
    ],
    /*Hospitality*/32: [
        CareerImages["CHEF"],
        CareerImages["BARISTA & DRINKS"],
        CareerImages["CONCIERGE"],
        CareerImages["EVENTS"],
        CareerImages["HOTEL MANAGER"],
        CareerImages["TRAVEL"],
    ],
    /*Metal*/35: [
        CareerImages["DIESEL MECHANIC"],
        CareerImages["MECHANIC"],
        CareerImages["METAL FABRICATOR"],
        CareerImages["TOOL MAKER"],
        CareerImages["PANEL BEATER"],
        CareerImages["MECHANICAL ENGINEER"],
    ],
    /*Textiles*/38: [
        CareerImages["FASHION"],
        require("../../assets/quiz-images/additional-mood-board-images/fabric.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/shoes.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/mannequin.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/sewing.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/tailor.jpg"),
    ],
    /*Transport*/39: [
        CareerImages["PILOT"],
        CareerImages["FREIGHT & LOGISTICS"],
        CareerImages["AIR TRAFFIC CONTROLLER"],
        require("../../assets/quiz-images/additional-mood-board-images/truck.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/train.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/cargo-ship.jpg"),
    ],
    /*Architecture & Construction*/22: [
        CareerImages["ARCHITECT"],
        CareerImages["BUILDER"],
        CareerImages["SURVEYOR"],
        CareerImages["TOWN PLANNER"],
        CareerImages["PLUMBER"],
        CareerImages["TILER"],
    ],
    /*Computing*/26: [
        CareerImages["DIGITAL MEDIA"],
        CareerImages["WEB DESIGNER"],
        CareerImages["ANIMATOR"],
        CareerImages["GAMES DESIGNER"],
        require("../../assets/quiz-images/additional-mood-board-images/networks.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/programming.png"),
    ],
    /*Food Production*/29: [
        require("../../assets/quiz-images/additional-mood-board-images/cheese.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/baker.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/coffee-roaster.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/fruit-market.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/meat.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/wine.jpg"),
    ],
    /*Health*/31: [
        CareerImages["COUNSELLOR"],
        CareerImages["FITNESS"],
        CareerImages["HAIRDRESSER"],
        CareerImages["MEDICINE"],
        CareerImages["MIDWIFE"],
        CareerImages["SOCIAL WORKER"],
    ],
    /*Law*/33: [
        CareerImages["ARMY"],
        CareerImages["LAWYER"],
        CareerImages["NAVY"],
        CareerImages["POLICE OFFICER (STATE)"],
        CareerImages["FORENSICS"],
        CareerImages["FIREFIGHTER"],
    ],
    /*Science*/36: [
        CareerImages["ASTRONOMER"],
        CareerImages["MEDICAL SCIENTIST"],
        CareerImages["LABORATORY ASSISTANT/TECHNICIAN"],
        require("../../assets/quiz-images/additional-mood-board-images/chemicals.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/lab.jpg"),
        require("../../assets/quiz-images/additional-mood-board-images/microscope.jpg"),
    ],
    /*Arts & Social Sci*/34: [
        CareerImages["HISTORIAN"],
        CareerImages["JOURNALIST"],
        CareerImages["MUSEUM CURATOR"],
        CareerImages["WRITER"],
        CareerImages["TRANSLATOR"],
        CareerImages["PRINTER"],
    ],
};

const CwizPictures : React.FC<CwizProps> = ({response: results, goToPage, updateResponse}) => {
    const [selection, setSelection] = React.useState<number[]>([]);
    const [images, setImages] = React.useState<string[]>([]);
    const [words, setWords] = React.useState<string[]>([]);
    const [showAlert, setShowAlert] = React.useState(false);
    React.useEffect(() => {
        setImages(_.shuffle(CategoryImages[results.category || 0] || []));
        setWords(_.shuffle(CategoryWords[results.category || 0] || []));
    }, [results.category])
    const onSelect = (index:number) => {
        setSelection(s => {
            if (s.includes(index)) {
                return s.filter(x => x !== index);
            } else {
                return s.concat([index]);
            }
        });
        setShowAlert(false);
    }
    const onContinue = () => {
        if (selection.length === 0) {
            window.scrollTo({left:0, top:0})
            setShowAlert(true);
        } else {
            updateResponse({...results, skills:[], values:[]}, "skills")
        }
    }
    return <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Skills &amp; Values</h2>
                <h3>Select any of the tiles below that appeal to you.</h3>
            </Col>
        </Row>
        {showAlert && <Row>
            <Col className="text-center mt-32 mb-16">
                <Alert textOnly variant="negative" text="If the images below don't appeal to you, you may wish to consider choosing another field." />
                <Button variant="negative" onClick={()=>updateResponse({...results, skills:[], values:[]}, "skills")}>Continue Anyway</Button>
                <Button variant="active" className="ml-8" onClick={()=>goToPage("categories")}>Go Back</Button>
            </Col>
        </Row>}
        <Row className="mt-64">
            <Col>
                <CardGrid numPerRow={3} prefix="cat-images">
                    {images.map((c, i) =>
                        <ImageCard key={i} onClick={()=>onSelect(i)} imageUrl={c}
                            className={(selection.includes(i) ? "selected-quiz-image" : "unselected-quiz-image")+([1,3,5].includes(i) ? " cwiz-mood-text-tile" : "")}>
                                {i === 1 && <>
                                    <h2 className="cwiz-mood-text upper">{words[0] || ""}</h2>
                                    <h2 className="cwiz-mood-text lower">{words[1] || ""}</h2>
                                </>}
                                {i === 3 && <>
                                    <h2 className="cwiz-mood-text upper">{words[2] || ""}</h2>
                                    <h2 className="cwiz-mood-text lower">{words[3] || ""}</h2>
                                </>}
                                {i === 5 && <>
                                    <h2 className="cwiz-mood-text upper">{words[4] || ""}</h2>
                                    <h2 className="cwiz-mood-text lower">{words[5] || ""}</h2>
                                </>}
                        </ImageCard>
                    )}
                </CardGrid>
            </Col>
        </Row>
        <Row>
            <Col className={"mt-32 text-right"}>
                <Button onClick={onContinue} variant="active">Continue</Button>
            </Col>
        </Row>
    </>;
}

export default CwizPictures;