import { JobJumpBulletin, SchoolHomepage, SchoolUploadedFile } from "jobjump-types/SchoolHomePage";
import React from "react";
import PageSectionRow from "../components/PageSectionRow";
import SchoolHomepageAPI from "../data/SchoolHomepage";
import _ from "lodash";
import ImageCard from "../components/cards/ImageCard";
import { Col } from "../components/grid";
import { getRandomImage } from "../assets/GenericImages";
import ContentWrapper from "../components/ContentWrapper";
import ShowNItemsCardGrid from "../components/ShowNItems"


const MySchoolBulletins : React.FC<{schoolConfig?:SchoolHomepage, schoolId?:string}> = ({schoolConfig, schoolId}) => {
    const [schoolBulletins, setSchoolBulletins] = React.useState<SchoolUploadedFile[]>([])
    const [jobjumpBulletins, setJobjumpBulletins] = React.useState<JobJumpBulletin[]>([]);
    React.useEffect(() => {
        if (!schoolId) { return; }
        if (!schoolConfig || (schoolConfig && !schoolConfig.hasOwnBulletins)) {
            return;
        }
        SchoolHomepageAPI.getFiles(schoolId).then(f => {
            const bulletins = f.filter(i => i.category === "Bulletin");
            setSchoolBulletins(bulletins);
        });
    }, [schoolId, schoolConfig]);
    React.useEffect(() => {
        if (!schoolId) { return; }
        if (!schoolConfig || (schoolConfig && !schoolConfig.hasBulletin)) {
            return;
        }
        SchoolHomepageAPI.getJobjumpBulletins(schoolId).then(f => {
            setJobjumpBulletins(f);
        });
         
    }, [schoolId, schoolConfig]);
    const openFile = (fileId:string) => {
        if (!schoolId) { return; }
        SchoolHomepageAPI.getFileDownloadLink(schoolId, fileId).then(window.open);
    }
    const openJJBulletin = (bulletinId:string, kind:string) => {
        if (!schoolId) { return; }
        SchoolHomepageAPI.openJobjumpBulletin(schoolId, bulletinId, kind).then(window.open);
    }
    return <ContentWrapper title="My Bulletins" >
        {schoolBulletins.length > 0 && <PageSectionRow>
            <Col>
                <h3>School Bulletins</h3>
                <ShowNItemsCardGrid base={8} numPerRow={4} prefix="school-bulletins">
                    {schoolBulletins.map((b, i) => 
                        <ImageCard imageUrl={getRandomImage(i)} key={b.id} text={`${b.filename}`} onClick={()=> openFile(b.id)} />)}
                </ShowNItemsCardGrid>
            </Col>
        </PageSectionRow>}
        {jobjumpBulletins.length > 0 && <PageSectionRow>
            <Col>
                <h3>JobJump Bulletins</h3>
                <ShowNItemsCardGrid base={8} numPerRow={4} prefix="jobjump-bulletins">
                    {jobjumpBulletins.map((b, i) => 
                        <ImageCard imageUrl={getRandomImage(i)} key={b.id} text={`${b.title}`} onClick={()=> openJJBulletin(b.id, "master")} />)}
                </ShowNItemsCardGrid>
            </Col>
        </PageSectionRow>}
    </ContentWrapper>;
}

export default MySchoolBulletins;