import React from "react";
import "./progress.css";

type Steps = {
    title: string,
    active: boolean
}

type Props = {
    steps : Steps[]
}

const Progress : React.FC<Props> = ({steps}) => 
    <ul className="progressbar">
        {steps.map((s, i) => <li key={`step-${i}`} className={s.active ? "active" : ""}>{s.title}</li>)}
    </ul>

export default Progress;