import React from "react";
import YouTube from "react-youtube";
import {Row, Col} from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";
import ContentWrapper from "../components/ContentWrapper";
import YesNoQuiz, { QuestionsInput } from "../components/YesNoQuiz";

const questions : QuestionsInput[] = [
    {
        title: "Attendance and punctuality",
        certificate: "No absences, always punctual. Often arrived early, prepared to stay back if a task had to be completed.",
        correct: "I'm very rarely absent and always punctual. I often arrive early, and am prepared to stay back if a task has to be completed.",
        incorrect: "I don’t set my alarm for planning to get to work on time and there are times I turn up to work on time and other times I don’t. It doesn’t really concern me too much.",
        key: "attendance"
    },
    {
        title: "Appearance and presentation ",
        certificate: "Takes pride in appearance and presentation; sets an example to others.",
        correct: "I take pride in my appearance, what I wear and how I present myself.",
        incorrect: "I have been meaning to go to the hairdressers for a while now and I know my hair is pretty disgusting. It needs a good wash. My clothes look crinkly and are a bit ‘out there’. When I go to work, I just get out of bed and dress and go. No need to wash my face, brush my teeth or do my hair.",
        key: "appearence"
    },
    {
        title: "Working with others",
        certificate: "Actively helped others, showed responsibility.",
        correct: "I'm always willing to help others if they need it and to take responsibility for my work.",
        incorrect: "If people ask for help I will consider it. It depends if they could have asked someone else. I don’t like taking responsibility for anything. If I do happen to be asked to do something I will just try to get someone else to do it for me.",
        key: "others"
    },
    {
        title: "Communication and interpersonal skills  ",
        certificate: "Excellent communication and ‘people skills’. Confident and articulate; listens well.",
        correct: "I have strong communication skills and work well with others. I listen to instructions and ask questions if I'm not sure.",
        incorrect: "If people communicate with me I will communicate with them. I don’t like to talk to people all that much so I keep usually to myself. If I do talk to people it is usually with a one word answer. I often don’t see why I need to focus and pay attention to others talking to me. Do they ever listen to me?",
        key: "communication"
    },
    {
        title: "Time management",
        certificate: "Works well independently. Able to set goals, prioritise tasks and achieve objectives in good time.",
        correct: "I am happy to work independently. I can set goals and am able to plan and prioritise my work in order to achieve them.",
        incorrect: "I am happy to work independently if it means I don’t have to do much. If it gets done, it gets done. I do it in my own time. ",
        key: "time"
    },
    {
        title: "Attitude and motivation",
        certificate: "Highly motivated and keen to take on tasks assigned. Attitude was consistently excellent.",
        correct: "If I'm given a job, I do it with motivation. When I'm finished a job, I ask if there are other things I can do.",
        incorrect: "If I get a job done, I am happy to take it easy and wait to see if the boss tracks me down to give me some more work to do. I wish they didn’t find me to give me more work to do. ",
        key: "attitude"
    },
    {
        title: "Ability to follow instructions and use initiative",
        certificate: "Absorbed instruction well. Inventive, resourceful, able to solve problems.",
        correct: "When I hear my instructions and start doing a job, I can think for myself to try to solve the problem.",
        incorrect: "I find instructions just boring. I prefer if there weren’t instructions. That’s just the boss being bossy. If a problem were to occur, I would try to be as far away as possible so I didn’t get any blame. I don’t like finding a way to solve any problem anytime. Sounds like too much hard work.",
        key: "instructions"
    },
    {
        title: "Ability to learn",
        certificate: "Learns quickly; keen to achieve new skills and to enhance knowledge of workplace tasks.",
        correct: "I'm keen to take on new task to learn new things.",
        incorrect: "I think I have learnt enough.",
        key: "learn"
    },
    {
        title: "Positive self-attitude",
        certificate: "Confident, enthusiastic, energetic and persistent in achieving outcomes.",
        correct: "I feel confident in myself and complete all tasks with enthusiasm.",
        incorrect: "I am enthusiastic if I find the work I do entertaining. I put my energy in to things that are always fun to do as long as it isn’t too much hard work. I have a positive self attitude and if others don’t like me as I am, they have the problem, not me. I don’t like others giving me any advice about myself anytime.",
        key: "positiveAttitude"
    },
    {
        title: "Quality of work",
        certificate: "Work was of consistently high quality; appeared to take pride in completing work well.",
        correct: "I take pride in the work I do. If something needs to be refined I will stick at it to the best of my ability.",
        incorrect: "I will do the job I am told to do and if it happens to turn out okay, then it will do. I like to leave a job once I have done it and not to check if it needs any more refinement. That I will leave for someone else to do.",
        key: "quality"
    },
    {
        title: "Consideration",
        certificate: "Consider other people from different backgrounds.",
        correct: "I am tolerant and understanding of people regardless of their background.",
        incorrect: "If there happens to be people of other skin colour to me, or they dress in some different ethnic style, that’s just weird. I don’t like people who speak with an accent even if they use the same words as me. They make me laugh or I just want to keep as far away from them as possible. I don’t really care if they happen to be nice people or not. It’s not my problem, it’s theirs’.",
        key: "consideration"
    },
          
]

const SoftSkills : React.FC = () => {
    return <ContentWrapper title="Soft Skills" >
        <PageSectionRow>
            <Col>
                <h2>Start The Job Right</h2>
                <h3>Do you have what you need to start the job right? All jobs have required skills. Watch this video to make sure you're starting on the right foot.</h3>
                <span className="text-center">
                    <YouTube videoId="dEJQImzyOZ4" />
                </span>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Skills Check</h2>
                <h3>Take this quick quiz to see if you're ready to start working on the right foot.</h3>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                <YesNoQuiz
                    questions={questions}
                    completedEventTag={"completeSoftSkills"}
                    certificateTitle={"Certificate for Employment Skills"}
                    certificateSecondLine={"has achieved an understanding of the following employment skills:"} />
        </Col>
        </Row>
    </ContentWrapper>
}

export default SoftSkills;