import React from "react";
import {Row, Col} from "../../components/grid";
import ModalVideo from "react-modal-video";
import FilterableGrid from "../../components/FilterableGrid";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import Uni, { UniContentCategory, UniContentEntry } from "../../data/Uni";
import { getYoutubeParam, openLinkInNewTab } from "../../util";
import _ from "lodash";
import CareerImages from "../../assets/CareerImages";
import AtarImages from "../../assets/AtarImages";
import { EventTracking } from "../../data/EventTracking";
import ContentWrapper from "../../components/ContentWrapper";

const ImageMap :{[k:string]:string} = {
    "AGRICULTURE, ANIMALS, ENVIRONMENT": CareerImages["AGRICULTURE, ANIMAL AND PRIMARY INDUSTRIES"],
    "BUSINESS": CareerImages["BUSINESS, ADMINISTRATION AND SALES"],
    "COMMUNICATIONS": AtarImages["COMMUNICATIONS"],
    "CREATIVE, DESIGN, ARCHITECTURE": CareerImages["ART, DESIGN, MUSIC AND ENTERTAINMENT"],
    "ENGINEERING, IT, COMPUTING": CareerImages["ENGINEERING AND TECHNICAL"],
    "HEALTH CAREERS, FITNESS, MEDICAL SCIENCE, MEDICINE, NURSING, MIDWIFERY, PARAMEDICINE": CareerImages["HEALTH, MEDICINE, COMMUNITY AND BEAUTY"] ,
    "LAW, POLICING": CareerImages["LAW, POLICING AND DEFENCE"],
    "SCIENCE": AtarImages["SCIENCE"],
    "TEACHING, SOCIAL SCIENCE, ARTS, LANGUAGES, PSYCHOLOGY": CareerImages["COUNSELLOR"],
}

interface Props extends RouteComponentProps<any> {
    showLoggedInFeatures : boolean
}

const UniStories : React.FC<Props> =
({location:{search}, history, showLoggedInFeatures}) => {
    const [categories, setCategories] = React.useState<{id:number,name:string}[]>([]);
    const [currentCategory, setCurrentCategory] = React.useState<UniContentCategory>();
    const [currentCategoryLinks, setCurrentCategoryLinks] = React.useState<UniContentEntry[]>([]);
    const [videoID, setVideoID] = React.useState<string>();
    const [showVideo, setShowVideo] = React.useState(false);
    const [careers, setCareers] = React.useState<string[]>([]);
    React.useEffect(() => {
        Uni.getCategories().then(setCategories);
    }, []);
    React.useEffect(() => {
        const {category} = qs.parse(search);
        const thisCategory = typeof category !== "string" ? undefined : categories.find(c => c.id === Number(category));
        if (!thisCategory) {
            setCurrentCategory(undefined);
            setCurrentCategoryLinks([]);
            return;
        }
        setCurrentCategory(thisCategory);
        Uni.getContent("stories_video", thisCategory.id).then((d) => {
            setCurrentCategoryLinks(d);
            const careerList = Object.keys(d.reduce((a, c) => {
                const categories = c.career.split(",");
                return categories.reduce((b, cat:string) => ({
                    ...a,
                    [cat.trim()] : true
                }), a);
            }, {} as {[k:string]:boolean}));
            setCareers(careerList.sort());
        });
    }, [search, categories])
    if (!currentCategory) {
        return <ContentWrapper title="What's it like to study...?" 
                subtitle={["Want to hear about degrees from people currently studying them? Search the categories below"]}>
            <Row>
                <Col>
                    <FilterableGrid
                        numPerRow={3}
                        data={categories.map(d => ({
                            imgUrl: ImageMap[d.name.trim()],
                            text: d.name,
                            onClick: ()=>history.push(`/uni/stories?${qs.stringify({category:d.id})}`)
                        }))}
                    />
                </Col>
            </Row></ContentWrapper>
    } else {
        return <ContentWrapper 
            title={`What's it like to study ${currentCategory.name}?`}
            subtitle={["Select the career you're interested in from the list below."]}
            breadcrumbs={[{name:"Stories", link:"/uni/stories"}]}>
        <Row>
            <Col>
                <FilterableGrid
                    numPerRow={3}
                    categories={careers}
                    data={_.sortBy(currentCategoryLinks, l => l.career).map(d => ({
                        text: `${d.title} (${d.institute})`,
                        categoryString: d.career,
                        imgUrl:d.image,
                        onClick: ()=>{
                            const recordFunction = showLoggedInFeatures ? EventTracking.trackEvent : EventTracking.trackUnauthEvent;
                            recordFunction("uni_stories", JSON.stringify({inst:d.institute, url:d.url})).then(() => {
                                const param = getYoutubeParam(d.url);
                                if (param === null) {
                                    openLinkInNewTab(d.url);
                                    return;
                                }
                                setVideoID(param);
                                setShowVideo(true);
                            });
                        }
                    }))}
                />
            </Col>
        <ModalVideo channel='youtube' videoId={videoID} isOpen={showVideo} onClose={()=>setShowVideo(false)} />
        </Row></ContentWrapper>
    }
}

export default UniStories;