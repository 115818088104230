import React from "react";
import { Col, GridSizes, Row } from "./grid";

export interface CardGridProps {
    numPerRow: number,
    prefix:string
}

const CardGrid : React.FC<CardGridProps> = ({numPerRow, prefix, children}) => {
    const childrenArr = React.Children.toArray(children);

    const splitArray = childrenArr.reduce((resultArray : React.ReactNode[][], item, index) => { 
        const chunkIndex = Math.floor(index/numPerRow)

        if(!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
    }, [] as React.ReactNode[][]);
    return <>
        {splitArray.map((ns, i) => <Row justify="center" className="mb-32" key={`${prefix}-${i}`}>
            {ns.map((n,j)=><Col key={`${prefix}-${i}-${j}`} sm={12} md={Math.floor(12/numPerRow) as GridSizes}>{n}</Col>)}
        </Row>)}
    </>
}

export default CardGrid;