import React from "react";
import {Row, Col, GridSizes} from "../../components/grid";
import { Accordion } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { useTable, useSortBy, useGlobalFilter, useExpanded, Row as TableRow } from "react-table";
import { TafeCourseEntry, TafeCourseOffering, TafeCategory } from "jobjump-types/TAFE";
import TafeAPI, {filterOfferingList} from "../../data/TAFE";
import UserDetailsAPI from "../../data/UserDetails";
import { getDistance } from "geolib";
import { UserDetails } from "jobjump-types/UserDetails";
import _ from "lodash";
import { CoursePreferences } from "jobjump-types/Courses";
import CoursePreferenceAPI from "../../data/CoursePreferences";
import AskAQuestionModal from "./ContactModal";
import PageSectionRow from "../../components/PageSectionRow";
import CardGrid from "../../components/CardGrid";
import "../my_atar/atar-icons.css";
import { IconSortDescending, IconSortAscending, IconArrowsSort, IconHeart, IconQuestionMark } from "@tabler/icons";
import RowCard from "../../components/RowCard";
import Modal from "../../components/Modal";
import { Radio, TextField, Label } from "../../components/Form";
import ContentWrapper from "../../components/ContentWrapper";

function escapeRegExp(str:string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

interface UrlParams {
    categoryID: string
}

const getMostRelevantOffering = (data:TafeCourseEntry) => {
    const ranking : {[k:string]:number} = {
        "Full Time": 1,
        "Part Time": 2,
        "Part Time Day": 3,
        "PT Evening": 4,
        "Flexible": 5,
        "Workplace": 6,
        "Online": 7,
        "PTD Connected": 8,
        "PTE Connected": 9
    };
    const o = _.sortBy(data.offerings, (o:{mode:string}) => ranking[o.mode] || 0);
    return o[0];
}

interface TafeCourseListingProps extends RouteComponentProps<UrlParams> {
    showLoggedInFeatures: boolean
}

interface TableEntry extends TafeCourseEntry {
    distance?: number
}


const CourseListing: React.FC<TafeCourseListingProps> =
({showLoggedInFeatures, match}) => {
    const [courseEntries, setCourseEntries] = React.useState<TableEntry[]>([]);
    const [filteredCourseEntries, setFilteredCourseEntries] = React.useState<TableEntry[]>([]);
    const [subcategories, setSubCategories] = React.useState<string[]>([]);
    const [showAskAQuestion, setShowAskAQuestion] = React.useState(false);
    const [currentCourse, setCurrentCourse] = React.useState<TableEntry>();
    const [filterValues, setFilterValues] = React.useState<string[]>([]);
    const [userDetails, setUserDetails] = React.useState<UserDetails>();
    const [preferences, setPreferences] = React.useState<CoursePreferences[]>([]);
    const [gotPrefences, setGotPreferences] = React.useState(false);
    const [tafeCategory, setTafeCategory] = React.useState<TafeCategory|undefined>();
    const [showNotLoggedIn, setShowNotLoggedIn] = React.useState(false);

    React.useEffect(() => {
        TafeAPI.getTafeCategories().then((l) => {
            const c = l.find(c => c.id === Number(match.params.categoryID));
            setTafeCategory(c);
        });
    }, [match.params.categoryID])

    React.useEffect(() => {
        if (showLoggedInFeatures) {
            CoursePreferenceAPI.getCoursePreferences().then((d) => { setPreferences(d); setGotPreferences(true) });
        }
    }, [showLoggedInFeatures]);

    React.useEffect(() => {
        if (gotPrefences && showLoggedInFeatures) {
            CoursePreferenceAPI.saveCoursePreferences(preferences.map((p, i) => {
                return { 
                    course_id: p.uniCourse ? Number(p.uniCourse.jj_id) : undefined, 
                    tafe_id: p.tafeCourse ? Number(p.tafeCourse.jj_id) : undefined, 
                    preference_order: i 
                };
            }));
        }
    }, [preferences, gotPrefences, showLoggedInFeatures])

    React.useEffect(() => {
        if (showLoggedInFeatures) {
            UserDetailsAPI.getDetails().then(setUserDetails);
        }
    }, [showLoggedInFeatures]);

    React.useEffect(() => {
        if (!userDetails && showLoggedInFeatures) {
            return;
        }
        TafeAPI.getTafeCourses(match.params.categoryID).then((c) => {
            const data = c.map(e => ({
                ...e, 
                distance: userDetails && userDetails.school_location && e.location && getDistance(
                    {lat:userDetails.school_location.x, lon:userDetails.school_location.y}, 
                    {lat:e.location.x, lon:e.location.y}
            )}));
            setCourseEntries(data);
            setFilteredCourseEntries(data);
        });
    }, [match.params.categoryID, userDetails]);

    React.useEffect(() => {
        TafeAPI.getTafeCourseSubcategories(match.params.categoryID)
            .then(d => setSubCategories(d.map(({name})=>name)));
    }, [match.params.categoryID]);

    React.useEffect(() => {
        if (filterValues.length === 0 ) {
            setFilteredCourseEntries(courseEntries);
            return;
        }
        const newFilteredValues = courseEntries.filter(c => 
            filterValues.reduce((a, f) => a || (c.course_name.match(`.*${escapeRegExp(f)}`) !== null), false as boolean)
        );
        setFilteredCourseEntries(newFilteredValues);
    }, [courseEntries, filterValues, setFilteredCourseEntries, userDetails])
    const columns = [
        {
            id: "like-course",
            width: 1,
            Cell: ({ row }: { row: any }) => {
                const inPreferences = preferences.find((s) => 
                    s.tafeCourse && s.tafeCourse.jj_id === row.original.jj_id) !== undefined;
                return <span onClick={() => {
                    if (!showLoggedInFeatures) {
                        setShowNotLoggedIn(true);
                        return;
                    }
                    const newPrefs = [...preferences];
                    if (inPreferences) {
                        const idx = newPrefs.findIndex((p) => p.tafeCourse && p.tafeCourse.jj_id === row.original.jj_id);
                        newPrefs.splice(idx, 1);
                    } else {
                        if (preferences.length < 20) {
                            newPrefs.push({ tafeCourse: { ...row.original }, order: newPrefs.length });
                        }
                    }
                    setPreferences(newPrefs);
                }}>
                    <IconHeart className={inPreferences ? "fill-icon" : undefined} />
                </span>
            }
        },
        {
            id: "ask-a-question",
            Header: "Ask or Call",
            width: 2,
            className: "text-center",
            Cell: ({ row }: { row: any }) => 
                row.original.offerings.length > 0 ? <IconQuestionMark className="circle-icon"  onClick={()=>{
                    setCurrentCourse(row.original as TafeCourseEntry);
                    setShowAskAQuestion(true)
                }} /> : <></>
        },
        {
            id: "level",
            width: 2,
            Header: "Level",
            accessor: "level",
            sortType : (rowA:TableRow<TafeCourseEntry>, rowB:TableRow<TafeCourseEntry>) => {
                const levelMap : {[k:string]:number} = {
                    "Graduate Certificate" : 8,
                    "Graduate Diploma": 8,
                    "Bachelor Degree": 7,
                    "Advanced Diploma" : 6,
                    "Diploma" : 5,
                    "Certificate IV" : 4,
                    "Certificate III" : 3,
                    "Certificate II" : 2,
                    "Certificate I" : 1
                };
                const levelA = levelMap[rowA.original.level] || 0;
                const levelB = levelMap[rowB.original.level] || 0;
                if (levelA > levelB) {
                    return -1;
                }
                if (levelB > levelA) {
                    return 1;
                }
                return 0;
            },
        },
        {
            id: "course-name",
            width:4,
            Header: 'Course Name',
            Cell : ({row} : {row:any}) => {
                const now = new Date(Date.now());
                const offering = filterOfferingList(row.original.offerings)
                if (offering.length > 1) {
                    return <Accordion>
                        <Accordion.Toggle as="span" eventKey={`${row.original.jj_id}-links`}>
                            <a style={{color:"#007bff", cursor:"pointer"}}>{row.original.course_name}</a><br />
                            <small>({offering.length} study modes)</small>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`${row.original.jj_id}-links`}>
                            <ul>{offering.map((o:TafeCourseOffering) => 
                                <li><a href={o.url} target="_blank" rel="noopener noreferrer">
                                    {o.mode}&nbps;
                                    {o.anytime ? 
                                        "(Anytime)" : 
                                            (new Date(o.startDate)).getMilliseconds() < now.getMilliseconds() ? "" : `(Start: ${o.startDate})`
                                    }
                                </a></li>
                            )}</ul>
                        </Accordion.Collapse>
                    </Accordion>
                }
                if (offering.length === 1) {
                    return <a href={offering[0].url} target="_blank" rel="noopener noreferrer">
                        {row.original.course_name}
                    </a>
                }
                return <span>{row.original.course_name}<br /><small>No current study modes</small></span>;
            },
            accessor: "course_name",
        },
        {
            Header: "College",
            id : "college",
            accessor : (row:any) => {
                const distance = userDetails && userDetails.school_location && row && row.distance ;
                return <span>
                    {row.college} {row.institute && `(${row.institute.replace(" Institute", "").replace("The ", "")})`}
                    {distance && <><br/><small>({Math.round(distance/1000)} kms away)</small></>}
                </span>
            },
            sortType: (rowA:TableRow<TableEntry>, rowB:TableRow<TableEntry>) => {
                if (!userDetails || !userDetails.school_location) { 
                    return (rowA.original.college.localeCompare(rowB.original.college) as (1|-1|0)) 
                }
                const distanceA = rowA.original.distance;
                const distanceB = rowB.original.distance;
                // Handle undefined location from course
                if (!distanceA && distanceB) {
                    return 1;
                } else if (!distanceB && distanceA) {
                    return -1
                } 
                // Have to do a separate if to appease typescript
                if (!distanceA || !distanceB) {
                    return 0;
                }
                if (distanceA > distanceB) {
                    return 1;
                }
                if (distanceB > distanceA) {
                    return -1;
                }
                return 0;
            },
            width: 3
        }
    ];
    const initialState = {
        sortBy: [{id: 'college', desc:false}, { id: 'level', desc: false }, {id: 'course-name', desc:false}]
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        prepareRow,
        setGlobalFilter,
    } = useTable({ columns, data:filteredCourseEntries, initialState }, useGlobalFilter, useSortBy, useExpanded);

    return <ContentWrapper 
            title={`TAFE Course Search - ${tafeCategory && tafeCategory.name}`}
            breadcrumbTitle={tafeCategory?.name}
            subtitle={["Find the TAFE course you want to study. Select a category that best describes what you want to study. If you know what you want, you can search it in the search box."]}
            breadcrumbs={[
                {name:"TAFE Course Search", link:"/tafe-courses"},
            ]}>
        <PageSectionRow>
            <Col>
                <Label>Search:</Label>
                <TextField value={state.globalFilter} onChange={setGlobalFilter} placeholder="Search" />
                {subcategories.length > 1 && <><h5>Course Categories</h5>
                    <CardGrid prefix="tafe-category" numPerRow={3}>
                        <span className="mb-1" style={{background:"white !important"}}>
                            <Radio name="course-cat" checked={filterValues.length === 0}
                                label="All Courses" onChange={(e:any)=>setFilterValues([])} />
                        </span>
                        {subcategories.map(s => 
                            <span className="mb-1" style={{background:"white !important"}}>
                                <Radio name="course-cat" checked={filterValues.includes(s)} label={s} 
                                    onChange={(checked)=>setFilterValues(checked ? [s] : [])} />
                            </span>
                        )}
                    </CardGrid>
                </>}
            </Col>
        </PageSectionRow>
        <Row className="mt-16" {...getTableProps()}>
            <Col>
                {headerGroups.map((headerGroup,i)=> <Row style={{width:"100%"}}>
                    {headerGroup.headers.map((column, i) => (
                        <Col {...column.getHeaderProps(column.getSortByToggleProps())} md={column.width as GridSizes} className="text-center">
                            {column.render('Header')}
                            {i === 0 || i === 5 || i === 6 || i === 7 ?
                                i === 0 ? `${preferences.length}/20` : ""
                            : <span>
                                &nbsp;{column.isSorted
                                    ? column.isSortedDesc
                                        ? <IconSortDescending />
                                        : <IconSortAscending />
                                    : <IconArrowsSort />}
                            </span>}
                        </Col>
                    ))}
                </Row>)}
                <Row {...getTableBodyProps()}><Col>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return <RowCard {...row.getRowProps()} key={`rows-${i}`}>
                            <Row>
                                {row.cells.map((cell, i) => <Col md={cell.column.width as GridSizes} {...cell.getCellProps()}>{cell.render('Cell')}</Col>)}
                            </Row>
                        </RowCard>})} 
                </Col></Row>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>&copy; JobJump Pty Ltd</p>
            </Col>
        </Row>
        {currentCourse && <AskAQuestionModal 
            unauth={!showLoggedInFeatures}
            show={showAskAQuestion} 
            onHide={()=>setShowAskAQuestion(false)} course={currentCourse} 
        />}
        <Modal show={showNotLoggedIn} onHide={()=>setShowNotLoggedIn(false)} title="Not Logged In" size="sm">
            <Row>
                <Col><h4>Sorry, you can only save a course when you are logged in with your JobJump account.</h4></Col>
            </Row>
        </Modal>
    </ContentWrapper>
}

export default CourseListing;
export {getMostRelevantOffering};