import React from 'react';
import Button from '../Button';
import CardGrid, { CardGridProps } from '../CardGrid';
import { Col, Row } from '../grid';

const ShowNItemsCardGrid : React.FC<{base:number} & CardGridProps> = ({base, children, ...props}) => {
    const [nItems, setNItems] = React.useState(base);
    const childArr = React.Children.toArray(children);
    return <><Row>
        <Col>
            <CardGrid {...props}>
                {childArr.slice(0,nItems)}
            </CardGrid>
        </Col> 
    </Row>
    <Row>
        <Col className="text-right">
            {childArr.length > nItems && <Button variant="active" onClick={()=>setNItems(i => i + base)}>Show More</Button>}
            {nItems > base && <Button className="ml-16" variant="active" onClick={()=>setNItems(base)}>Hide</Button>}
        </Col>
    </Row></>
}

export default ShowNItemsCardGrid;