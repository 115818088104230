import {CareerSubjectRecommendation, Subject} from "jobjump-types/Subjects";
import { fetchWithAuth } from "./Utils";

export type StudentSubjectPreferenceInput = {
    subject_id: number,
    checked: boolean,
    rank: number
}

export type StudentSubjectPreferences = {
    subject_id:number,
    name:string,
    category:string,
    unit:number,
    checked:boolean,
    rank:number
}

const Subjects = {
    getSubjects : () : Promise<Subject[]> =>
        fetchWithAuth("/api/subjects").then(r => r.json()).catch(console.log),
    getSubjectsForSchoolId : (schoolId:string) : Promise<Subject[]> =>
        fetchWithAuth("/api/subjects/school?schoolId="+schoolId).then(r => r.json()).catch(console.log),
    getVETSubjects : () : Promise<Subject[]> =>
        fetchWithAuth("/api/subjects/vet").then(r => r.json()).catch(console.log),
    getSubjectsForCareer : (careerId:string) : Promise<CareerSubjectRecommendation[]> =>
        fetchWithAuth("/api/subjects/career/"+careerId).then(r => r.json()).catch(console.log),
    getStudentPreferences: () : Promise<StudentSubjectPreferences[]> =>
        fetchWithAuth("/api/subjects/preferences").then(r => r.json()).catch(console.log),
    updateStudentPreferences : (preferences:StudentSubjectPreferenceInput[]) =>
        fetchWithAuth("/api/subjects/preferences", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(preferences)
        })
}

export default Subjects;