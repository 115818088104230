import React from "react";
import { RouteComponentProps } from "react-router";
import CareerImages from "../../assets/CareerImages";
import { getRandomImage } from "../../assets/GenericImages";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import ContentWrapper from "../../components/ContentWrapper";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import PageSectionRow from "../../components/PageSectionRow";
import Resume from "../../data/Resume";
import NewResumeForm from "./NewResumeForm";

const SelectResume : React.FC<RouteComponentProps> = (props) => {
    const [savedResumes, setSavedResumes] = React.useState<{id:number, name:string}[]>([]);
    const [showNewResumeModal, setShowNewResumeModal] = React.useState(false);

    React.useEffect(() => {
        Resume.listResumes().then(setSavedResumes)
    }, []);

    return <ContentWrapper title="My Resume">
        {savedResumes.length > 0 && <>
            <PageSectionRow>
                <Col>
                    <h3>Your Saved Resumes</h3>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CardGrid numPerRow={3} prefix={"saved-resumes"}>
                        {savedResumes.map((s, i) => 
                            <ImageCard 
                                key={`saved-resume-${i}`}
                                onClick={()=>props.history.push("/resume/"+s.id)}
                                imageUrl={CareerImages[s.name.toUpperCase()] ||getRandomImage(i)}
                                text={s.name} />
                        )}
                    </CardGrid>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col className="text-center">
                    <Button variant="active" onClick={()=>setShowNewResumeModal(true)}>Start New Resume</Button>
                </Col>
            </PageSectionRow>
        </>}
        {!savedResumes.length && <PageSectionRow>
            <Col>
                <NewResumeForm title="To start creating your resume, make a selection below" {...props} />
            </Col>
        </PageSectionRow>}
        <Modal size={"lg"} show={showNewResumeModal} onHide={()=>setShowNewResumeModal(false)}>
            <Row>
                <Col>
                    <NewResumeForm title="To create a new resume, make a selection below" {...props} />
                </Col>
            </Row>
        </Modal>
    </ContentWrapper>;
}

export default SelectResume;