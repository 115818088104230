import {CoursePreferences} from "jobjump-types/Courses";
import queryString from "query-string";
import { fetchWithAuth } from "./Utils";

const CoursePreferenceAPI = {
    getCoursePreferences : () : Promise<CoursePreferences[]> =>
        fetchWithAuth("/api/coursePreferences/getPreferences").then(r => r.json()).catch(console.log),
    saveCoursePreferences : (preferences : {course_id?:number, tafe_id?:number, preference_order:number}[]) => {
        const queryStr = queryString.stringify({preferences : JSON.stringify(preferences)});
        fetchWithAuth("/api/coursePreferences/savePreferences?"+queryStr).catch(console.log);
    }
};

export default CoursePreferenceAPI;