import React from "react";
import { Row, Col } from "../../components/grid";

const WorkExperienceDetails: React.FC<{}> =
    () =>
        <>
            <Row>
                <Col>
                    <h5>If you are not in school and the Boss needs Insurance Coverage, then say you can arrange it by
                        contacting Work Health Options (WHO - previously I.C. Frith).
                            <a href="http://www.workhealthoptions.com.au/services/broking-services/work-experience">Click Here</a>
                        for details. Don't forget to ask for a reference from the Boss at the end of the Work Experience.
                        You will need to show it to future Bosses when seeking employment.
                        </h5>
                    <h5>Another useful tip to remember is that most courses at TAFE, University or a private college
                        will include work experience. That way you get the training you need and the experience all
                            together. Check out the courses you should do below.</h5>
                </Col>
            </Row>

        </>

export default WorkExperienceDetails;