import { SchoolUploadedFile } from "jobjump-types/SchoolHomePage";
import React from "react";
import PageSectionRow from "../components/PageSectionRow";
import SchoolHomepageAPI from "../data/SchoolHomepage";
import _ from "lodash";
import { Col, Row } from "../components/grid";
import ContentWrapper from "../components/ContentWrapper";

type Props = {
    schoolId?:string
}

const MySchoolFiles : React.FC<Props> = ({schoolId}) => {
    const [categorySplit, setCategorySplit] = React.useState<string[][]>([]);
    const [categoriesMap, setCategoriesMap] = React.useState<{[k:string]:SchoolUploadedFile[]}>({});
    React.useEffect(() => {
        if (!schoolId) {return;}
        SchoolHomepageAPI.getFiles(schoolId).then(f => {
            console.log(f);
            const filteredFiles = f.filter(i=>i.category !== "Bulletin");
            const categoriesMap = filteredFiles.reduce((a, i) => {
                return {...a,
                    [i.category] : (a[i.category] || []).concat(i)
                }
            }, {} as {[k:string]:SchoolUploadedFile[]});
            const categories = Object.keys(categoriesMap);
            const numPerCol = Math.ceil(categories.length / 3);
            console.log(categoriesMap);
            setCategorySplit(_.chunk(categories.sort(), numPerCol));
            setCategoriesMap(categoriesMap);
        });
    }, [schoolId]);
    const openFile = (fileId:string) => {
        if (!schoolId) { 
            return;
        }
        SchoolHomepageAPI.getFileDownloadLink(schoolId, fileId).then(window.open);
    }
    return <ContentWrapper
            title="My School Files"
            subtitle={["Here's the files your Careers Adviser has uploaded."]} >
        <PageSectionRow>
            {categorySplit.map((categories, i) => <Col key={`${i}`}>
                {categories.map((c,j)  =>
                    <Row key={`${i}-${j}`}><Col>
                        <h2>{c}</h2>
                        <ul>
                            {(categoriesMap[c] || []).map((f, k) => 
                                <li key={`${i}-${j}-${k}`}><a href="#" onClick={()=>openFile(f.id)}>{f.filename}</a></li>
                            )}
                        </ul>
                    </Col></Row>
                )}
            </Col>)}
        </PageSectionRow>
    </ContentWrapper>;
}

export default MySchoolFiles;