import { IconAlertTriangle, TablerIcon } from "@tabler/icons";
import React from "react";
import { makeClassName } from "../util";
import "./alert.css";

type Props = {
    text:string, 
    variant:"active"|"negative", 
    textOnly?:boolean, 
    Icon?:TablerIcon, 
    className?:string
}

const Alert : React.FC<Props> = ({text, variant, Icon, className, textOnly}) =>
    <div className={makeClassName(className, ["jj-alert", `jj-alert-${variant}`, textOnly ? "jj-alert-text-only" : "jj-alert-block"])}>
        {textOnly &&<h4>
            {Icon ? <Icon /> : variant === "negative" ? <IconAlertTriangle /> : <></>} 
            {text}
        </h4>}
        {!textOnly && <>
            <div><h2>{Icon ? <Icon height={44} width={44} /> : variant === "negative" ? <IconAlertTriangle height={44} width={44} /> : <></>}</h2></div>
            <h4>{text}</h4>
        </>}
        
    </div>

export default Alert;