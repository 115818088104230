import React from "react";
import AsyncSelect from "react-select/async";
import AtarAPI from "../../data/ATAR";

type Props = {
    institute:string,
    category:string
    value: string,
    onChange: (v:string|null) => void
}

const CourseAsyncSelect : React.FC<Props> = ({value, institute, category, onChange}) => 
    <AsyncSelect
        value={{label:value, value}}
        onChange={(v) => onChange(v ? v.value : null)}
        noOptionsMessage={()=>"Search for your course"}
        className="jobjump-form-select"
        loadOptions={(input) => 
            AtarAPI.courseSearch(institute, category, input).then(d => d.map(c => ({value:c.name, label:c.name})))
        } />

export default CourseAsyncSelect;
