import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from "react-router";
import CaResume from "../../data/CaLegacyPlan";
import ResumeForm from "../plan/ResumeForm";
import { ResumeKinds, GeneralResume, CareerResume } from "jobjump-types/OldResume";
import Careers from "../../data/Careers";

interface LegacyPlanViewerMatchParams {
    userID : string,
    kind : ResumeKinds, 
    id?: string 
}

const LegacyPlanViewer : React.FC<RouteComponentProps<LegacyPlanViewerMatchParams>> = 
({match : {params: {userID, kind, id}}}) => {
    const [generalResume, setGeneralResume] = useState<GeneralResume>();
    const [careerResume, setCareerResume] = useState<CareerResume>();
    const [categoryID, setCategoryID] = useState(kind === "category" ? id : undefined);
    const careerID = kind === "career" ? id : undefined;

    useEffect(() => {
        CaResume.getStudentLegacyGeneralPlan(userID).then(setGeneralResume);
    }, [userID]);
    useEffect(() => {
        CaResume.getStudentLegacyCareerPlan(userID, careerID, categoryID).then(setCareerResume);
    }, [userID, kind, id, careerID, categoryID]);
    useEffect(() => {
        if (kind === "career") {
            Careers.getCareerCategories().then(c => {
                const cat = c.find(i => i.careers.find(j => j.id === Number(id)) !== undefined);
                cat && setCategoryID(cat.id);
            })
        }
    }, [kind, id]);

    const saveResume = useCallback(() => {
        if (generalResume && careerResume) {
            CaResume.saveLegacyPlan(generalResume, careerResume, kind, userID, categoryID, careerID);
        }
    }, [userID, generalResume, careerResume, categoryID, careerID, kind]);

    if (!generalResume || !careerResume || (kind === "career" && !categoryID)) { return <></> }

    return <ResumeForm
        careerID={careerID}
        categoryID={categoryID}
        resumeKind={kind}
        generalResume={generalResume}
        careerResume={careerResume}
        saveResume={saveResume}
        updateGeneralResume={setGeneralResume}
        updateCareerResume={setCareerResume}
    />;
}

export default LegacyPlanViewer;