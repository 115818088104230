import React from "react";
import Button from "../../components/Button";
import {Row, Col} from "../../components/grid";
import Modal from "../../components/Modal";
import { Column, TableState } from "react-table";
import { Student } from "jobjump-types/CaStudents";
import {CSVLink} from "react-csv";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import "./student-table.css";
import CaStudentDetails from "../../data/CaStudentDetails";
import CardTable from "../../components/CardTable";
import {Select, TextField} from "../../components/Form";
import Alert from "../../components/Alert";
import { Data, Headers } from "react-csv/components/CommonPropTypes";


const SendMailModal : React.FC<{show:boolean,onHide:()=>void,selectedStudents:string[]}> =
({show, onHide, selectedStudents}) => {
    const [messageSubject, setMessageSubject] = React.useState("");
    const [messageBody, setMessageBody] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
    const send = React.useCallback(() => {
        if (messageSubject !== "" && messageBody !== "") {
            CaStudentDetails.sendMessage({
                subject:messageSubject, 
                message:messageBody, 
                students:selectedStudents
            }).then(() => { 
                setMessageSubject("");
                setErrorMessage("");
                setMessageBody("");  
                onHide(); 
            });
            return;
        }
        if (messageSubject === "") { setErrorMessage("Please enter a subject"); return; }
        if (messageBody === "") { setErrorMessage("Please enter a message"); return; }
    }, [messageSubject,messageBody,selectedStudents,onHide]);
    return <Modal show={show} onHide={onHide} title="Email Selected Students">
        {selectedStudents.length === 0 && <Row><Col>
            <h4>No students are currently selected. Select students in the table using the 
                Select All button, or by clicking on a students row. You can use the
                search options to filter your message for specific groups of students.
            </h4>
        </Col></Row>}
        {errorMessage !== "" && <Row>
            <Col><Alert textOnly variant="negative" text={errorMessage} /></Col>
        </Row>}
        {selectedStudents.length > 0 && <><Row>
            <Col>
                <TextField placeholder="Subject" value={messageSubject} onChange={setMessageSubject} />
                <ReactQuill className="mt-8" value={messageBody} onChange={setMessageBody} placeholder="Message" />
            </Col>
        </Row>
        <Row className="mt-16">
            <Col className="text-right">
                <Button variant="active" className="mr-8" onClick={send}>
                    Send
                </Button>
                <Button variant="negative" onClick={()=>onHide()}>
                    Close
                </Button>
            </Col>
        </Row></>}
    </Modal>
}

interface Props<A extends {student: Student}> {
    data: A[],
    additionalColumns: Column<A>[],
    hideEmail?:boolean,
    showYear13?:boolean,
    csvExport?: {
        headers : Headers,
        generateData: (d:A[]) => Data 
    }
}

function CaStudentTable<A extends {student: Student}>({data, additionalColumns, hideEmail, showYear13, csvExport}:Props<A>) {
        const [showEmailModal, setShowEmailModal] = React.useState(false);
        const [selectedStudents, setSelectedStudents] = React.useState<string[]>([]);
        let baseColumns : Column<A>[] = [
            {
                Header : "Name",
                id: "student.name",
                accessor:(row)=>`${row.student.first_name} ${row.student.last_name}`,
                Cell : ({row : {original : {student}}}) =>
                    <Link to={`/admin/student/${student.id}`}>{student.first_name} {student.last_name}</Link>,
                width: 2,
            },
            {
                accessor: "student.year",
                Header : "Year",
                width: 1 
            },
        ];
        if (!hideEmail) {
            baseColumns.push( 
            {
                accessor: "student.email",
                Header : "Email",
                width: 5 
            })
        }
        const columns : Column<A>[] = baseColumns.concat(additionalColumns);

        const initialState = {
            sortBy: [
                { id: 'student.year', desc: false },
                { id: "student.last_name", desc: false },
                { id: "student.first_name", desc: false }
            ]
        } as TableState;
        const yearList = [7,8,9,10,11,12].concat(showYear13 ? [13] : []);
        return <>
            <CardTable 
                data={data} 
                columns={columns} 
                initialState={initialState}
                selectFns={{
                    onSelect:(d)=>setSelectedStudents(s => s.includes(d.student.id) ? s.filter(v => v !== d.student.id) : s.concat([d.student.id])),
                    isSelected:(d)=>selectedStudents.includes(d.student.id),
                    selectAll:()=>setSelectedStudents(data.map(d=>d.student.id)),
                    selectNone:()=>setSelectedStudents([]),
                    haveSelection:()=>selectedStudents.length>0,
                    allSelected:()=>selectedStudents.length===data.length
                }}
                renderFilters={(globalFilter, setGlobalFilter, filters, setFilters) => <Row>
                    <Col md={6}>
                        <TextField value={globalFilter} onChange={setGlobalFilter} placeholder="Search..." />
                    </Col>
                    <Col md={6}>
                        <Select placeholder="Select a Year" onChange={(val)=>setFilters("student.year", val && val.value !== "" ? val.value : undefined)} 
                            options={[{value:"", label:"All Years"}].concat(yearList.map(v=>({value:String(v), label:`Year ${v}`})))} />

                    </Col>
                </Row>}
                renderToolbar={(rows)=><>
                    <Button variant="active" onClick={()=>setShowEmailModal(b=>!b)}>
                        Message Students
                    </Button>
                    <CSVLink 
                        className="csvlink"
                        data={csvExport ? csvExport.generateData(rows.map(r=>r.original)) : rows.map(r=>r.values)} 
                        headers={csvExport ? csvExport.headers : columns.map(c => (
                            { 
                                key : (typeof c.accessor === "string") ? c.accessor : (c.id || ""), 
                                label : (typeof c.Header === "string") ? c.Header : ""
                            }
                        ))}>
                        <Button variant="active" className="ml-16">Download Spreadsheet</Button>
                    </CSVLink>
                    <Button variant="active" className="ml-16" onClick={()=>window.print()}>Print</Button>
                </>}
            />
            <SendMailModal show={showEmailModal} onHide={()=>setShowEmailModal(false)} selectedStudents={selectedStudents} />
        </>
    }
        
export default CaStudentTable;