import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { DocumentCell, DocumentGrid, HeaderProps } from "./CommonSections";
import { IconWithText, Pin, Envelope, Phone, otherSectionToIcon } from "./Icons";

const CompactHeader : React.FC<HeaderProps> = ({resume:{styleConfig, personal_details, career_goal}}) => {
    const style = StyleSheet.create({
        name: {
            fontSize: "28pt",
            marginBottom: "16px",
            color: styleConfig.primaryColor,
            textAlign: "center"
        },
        centreText : {
            paddingBottom: "16px"
        }
    });
    return <View style={style.centreText}>
        <Text style={style.name}>{personal_details.name}</Text>
        <DocumentGrid>
            <DocumentCell size={8}>
                <View style={{paddingRight:"8px"}}>
                    {career_goal !== "" ? <Text>{career_goal}</Text> : undefined}
                    {personal_details.languages && personal_details.languages !== "" ? 
                        <Text style={{paddingTop:"16px"}}>Languages Spoken: {personal_details.languages}</Text> : undefined}
                </View>
            </DocumentCell>
            <DocumentCell size={4}>
                <View style={{paddingLeft:"8px", textAlign:"right"}}>
                    {personal_details.address && personal_details.address !== "" ? 
                        <IconWithText height="12pt" Icon={Pin} color={"#000000"}>
                            <Text>{personal_details.address}</Text>
                        </IconWithText> : undefined}
                    {personal_details.email && personal_details.email !== "" ? 
                        <IconWithText height="12pt" Icon={Envelope} color={"#000000"}>
                            <Text>{personal_details.email}</Text>
                        </IconWithText> : undefined}
                    {personal_details.phone && personal_details.phone !== "" ? 
                        <IconWithText height="12pt" Icon={Phone} color={"#000000"}>
                            <Text>{personal_details.phone}</Text>
                        </IconWithText> : undefined}
                    {personal_details.other?.map(o =>
                        o.value !== "" ?
                            <IconWithText height="12pt" Icon={otherSectionToIcon(o.key)} color={"#000000"}>
                                <Text>{o.value}</Text>
                            </IconWithText> : undefined
                    )}
                </View>
            </DocumentCell>
        </DocumentGrid>
    </View>
}

export default CompactHeader;