import { CaNewsItem } from "jobjump-types/CaNews";
import { HeroConfiguration, HomepageLink, HomepageLinkTo, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import { RouteComponentProps } from "react-router";
import { openLinkInNewTab } from "../../util";
import JobJumpMenu from "../../layout/JobJumpMenu";
import "./schoolHomepage.css"
import FindMyCareer from "../../assets/homepage/tiles/find-my-career-tool.jpg";
import HelpfulLinks from "../../assets/homepage/tiles/everything-you-need.jpg"
import TafeLink from "../../assets/homepage/tiles/studying-at-tafe.jpg"
import Bullseyes from "../../assets/homepage/tiles/atar-career-journey-2.jpg"
import MyCareer from "../../assets/school-homepage/my-career.png";
import GradCap from "../../assets/school-homepage/grad-cap.png";
import HeroVideo from "../../components/HeroVideo";
import { Col, Row } from "../../components/grid";
import CardGrid from "../../components/CardGrid";
import DecoratedRow from "../../components/DecoratedRow";
import ImageCard from "../../components/cards/ImageCard";
import Button from "../../components/Button";
import NoticeboardCard from "../../components/cards/NoticeboardCard";
import VideoLoops, { VideoKeys } from "../../assets/VideoLoops";
import SchoolHomepageAPI from "../../data/SchoolHomepage";
import TileImageStore from "../../assets/JobJumpPageImages";
import CareerImages from "../../assets/CareerImages";
import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import Community from "../../assets/community-service-img/vol.jpg";
import SoftSkills from "../../assets/community-service-img/debating.jpg";
import News from "../../assets/community-service-img/expo.jpg";
import Uni from "../../assets/school-homepage/university.jpg";
import { EventTracking } from "../../data/EventTracking";

interface SchoolHomepageLayoutProps extends RouteComponentProps {
    schoolId: string,
    layout : SchoolHomepage,
    heroConfig?:HeroConfiguration,
    inBuilder? : boolean,
    logoUrl?:string
}

type JobJumpLinkInfo = {
    link : {to:string, kind:"internal"|"external"},
    image: string
}

const JobJumpLinkMap : { [k in HomepageLinkTo]: JobJumpLinkInfo } = {
    "atar": {link:{to:"atar", kind:"internal"}, image:GradCap},
    "tafe": {link:{to:"tafe-courses", kind:"internal"}, image:TafeLink},
    "helpfulLinks": {link:{to:"whereis", kind:"internal"}, image:HelpfulLinks},
    "careerExplore": {link:{to:"myCareer", kind:"internal"}, image:MyCareer},
    "quiz": {link:{to:"cwiz", kind:"internal"}, image:FindMyCareer},
    "bullseyes" : {link:{to:"bullseyes", kind:"internal"}, image:Bullseyes},
    "jobAds" : {link:{to:"jobs", kind:"internal"}, image:TileImageStore["SearchingForAJob"]},
    "resume" : {link:{to:"resume", kind:"internal"}, image:TileImageStore["MyResumeForCareers"]},
    "subjects" : {link:{to:"subjectSelection", kind:"internal"}, image:TileImageStore["AtarChoosingHscSubjects"]},
    "earnings" : {link:{to:"earnings", kind:"internal"}, image:TileImageStore["StudentMoneyHelp"]},
    "careersVideos" : {link:{to:"careerVideos", kind:"internal"}, image:TileImageStore["CareerVideos"]},
    "universityInstitutes" : {link:{to:"uni/profiles", kind:"internal"}, image:Uni},
    "universityAllYouNeed" : {link:{to:"uni/directory", kind:"internal"}, image:TileImageStore["UniversityEarlyAccessScheme"]},
    "careerVR" : {link:{to:"careerVR", kind:"internal"}, image:CareerImages["ANIMATOR"]},
    "careersNews" : {link:{to:"news", kind:"internal"}, image:News},
    "openDays" : {link:{to:"uni/openDays", kind:"internal"}, image:TileImageStore["UniversityOpenDays"]},
    "uniStories" : {link:{to:"uni/stories", kind:"internal"}, image:TileImageStore["UniversityAtsi"]},
    "contactingAnEmployer" : {link:{to:"contactEmployer", kind:"internal"}, image:CallingABoss},
    "apprenticeships" : {link:{to:"apprenticeships", kind:"internal"}, image:TileImageStore["StudyingAtTafe"]},
    "eWorkExperience" : {link:{to:"eWorkExperience", kind:"internal"}, image:TileImageStore["TraineeshipsAndAprenticeships"]},
    "softSkills" : {link:{to:"softSkills", kind:"internal"}, image:SoftSkills},
    "communityService" : {link:{to:"communityService", kind:"internal"}, image:Community},
    "firstAid" : {link:{to:"firstAid", kind:"internal"}, image:TileImageStore["LearningCprAndFirstAid"]},
    "gapYear" : {link:{to:"uni/gapYear", kind:"internal"}, image:TileImageStore["UniversityInterstateAndOverseas"]},
}

interface LinkSectionProps extends RouteComponentProps { links:HomepageLink[] }

const LinkSectionComponent : React.FC<LinkSectionProps> = ({links, history}) => {
   const onLinkClick = (link:HomepageLink) => {
        const to = JobJumpLinkMap[link.toPage].link;
        EventTracking.trackUnauthEvent("clickCustomHomepageTile", JSON.stringify(link)).then(() => {
            if (to.kind === "external") {
                openLinkInNewTab(to.to);
            } else {
                history.push(`/${to.to}`)
            }
        });

    }
    return <DecoratedRow decorationType="diagonal-line">
        <Col>
            <Row>
                <Col><h1 className="text-white">Explore Your Career</h1></Col>
            </Row>
            <CardGrid numPerRow={3} prefix={"homepage-links"}>
                {links.map(l => 
                    <ImageCard 
                        key={l.title}
                        text={l.title} 
                        onClick={()=>onLinkClick(l)} 
                        imageUrl={JobJumpLinkMap[l.toPage].image} 
                    />)}
            </CardGrid>
       </Col>
    </DecoratedRow>
}

const NewsSectionComponent : React.FC<{school:string}> = ({school}) => {
    const fakeNews = [
        {id:0, title:"Excursion to Careers Market", body:"Reminder to all Year 10 to return you permission slip for the Careers Market.", years:[10], careers:[], date:"10 December"},
        {id:1, title:"Year 12 Interviews", body:"Year 12, please remember that you must make an appointment to see the Careers Adviser before the end of Term 3.", years:[12], careers:[], date:"12 December"},
        {id:2, title:"Year 10 Subject Selection", body:"If you are having trouble deciding what subjects to study in your HSC, please come and speak to me.", years:[10], careers:[], date:"12 December"},
        {id:4, title:"EY Accounting Scholarship", body:"If you are considering studying a Business degree, EY has a fantastic scholarship. If you're interested in applying please come see me.", years:[11,12], careers:[], date:"14 December"},
        {id:1, title:"Year 12 Interviews", body:"Year 12, please remember that you must make an appointment to see the Careers Adviser before the end of Term 3.", years:[12], careers:[], date:"18 December"},
        {id:2, title:"Year 10 Subject Selection", body:"If you are having trouble deciding what subjects to study in your HSC, please come and speak to me.", years:[10], careers:[], date:"20 December"},

    ];
    const [news,setNews] = React.useState<CaNewsItem[]>([]);
    const [yearFilter, setYearFilter] = React.useState<number>();
    const [showAll, setShowAll] = React.useState(false);
    const [filteredNews, setFilteredNews] = React.useState<CaNewsItem[]>([]);
    const [filterableYears, setFilterableYears] = React.useState<number[]>([]);
    React.useEffect(() => {
        SchoolHomepageAPI.getSchoolNews(school).then(n => {
            if (!n) {
                return;
            } 
            const upcomingnews =n.filter(i => new Date(i.date).getTime() >= Date.now());
            setNews(upcomingnews);
            const yearMap = upcomingnews.reduce((a, i) => i.years.reduce((b, y) => ({...b, [y]:true}), a), {} as {[k:string]:boolean});
            setFilterableYears(Object.keys(yearMap).map(s=>Number(s)));
        });
    }, [school]);
    React.useEffect(() => {
        if (!yearFilter) {
            setFilteredNews(news);
            return;
        }
        setFilteredNews(news.filter(n => n.years.includes(yearFilter)));
    }, [yearFilter, news, setFilteredNews]);
    return <>
        {news.length > 0 && <DecoratedRow fullHeight decorationType="circles-top">
            <Col className="careers-noticeboard-section">
                <Row className="mt-32">
                    <Col><h3>Careers Noticeboard</h3></Col>
                </Row>
                {filterableYears.length > 0 && <Row>
                    <Col>
                        <Button className="mr-16" glowing={yearFilter === undefined} variant={yearFilter === undefined ? "active" : "neutral"} onClick={()=>setYearFilter(undefined)}>Show All</Button>
                        {filterableYears.map(y =>
                            <Button glowing={yearFilter === y} className="mr-16" variant={yearFilter === y ? "active" : "neutral"} onClick={()=>setYearFilter(y)}>Year {y}</Button>
                        )}
                    </Col>
                </Row>}
                <Row className="mt-16">
                    <Col>
                        {filteredNews.slice(0, showAll ? filteredNews.length : 3).map((n,i) => 
                            <NoticeboardCard className="mb-32" newsItem={n} key={`news-item-${i}`} />)}
                    </Col>
                </Row>
                {filteredNews.length > 3 && <Row><Col>
                    <Button variant="neutral" className="pull-right" onClick={()=>setShowAll(a=>!a)}>{showAll ? "Show Less" : "See More"}</Button>
                </Col></Row>}
            </Col>
        </DecoratedRow>}
    </>
}

const ContactFooter : React.FC<{logoUrl?:string, homepage:SchoolHomepage}> = ({logoUrl, homepage}) =>
    <Row className="pt-128 mb-128">
        <Col className="text-center mb-16">
            {logoUrl && <img src={logoUrl} height={200} className="mb-64" />}
            <p>{homepage.caName !== "" && <>{homepage.caName}<br/></>} 
            {homepage.caName !== "" && <>{homepage.caTitle || "Careers Adviser"}<br/></>}
            {homepage.caAddress !== "" && <>{homepage.caAddress}<br/></>}
            {homepage.caPhone && <>P: {homepage.caPhone}<br/></>}
            {homepage.caEmail && <>E: <a href={`mailto: ${homepage.caEmail}`}>{homepage.caEmail}</a></>}</p>
        </Col>
    </Row>

const SchoolHomepageLayout : React.FC<SchoolHomepageLayoutProps> =
({layout, inBuilder, schoolId, logoUrl, heroConfig, ...props}) => {
    React.useEffect(() => {
        if (inBuilder) {
            return;
        }
        document.title = `${layout.schoolName} Careers`;
    }, [inBuilder, layout])
    return <>
        {inBuilder && <JobJumpMenu schoolConfig={layout} inBuilder logo={logoUrl} loggedIn={false} setUserDetails={()=>{}} logout={()=>{}} />}
        <HeroVideo title={`${layout.schoolName} Careers`} 
            imageUrl={heroConfig && heroConfig.kind === "customImage" ? heroConfig.imageUrl : undefined}
            videoUrl={!heroConfig ? 
                VideoLoops["JobJumpMain"] : 
                (heroConfig.kind === "video" ? 
                    VideoLoops[(heroConfig.loopName as VideoKeys|undefined) || "JobJumpMain"] : 
                    undefined)} />
        {layout.hasNoticeBoard && <NewsSectionComponent school={schoolId} />}
        <LinkSectionComponent links={layout.links} {...props} />
        <ContactFooter logoUrl={logoUrl} homepage={layout} />
    </>;
}

export default SchoolHomepageLayout;
