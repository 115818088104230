import React from "react";
import Button from "../Button";
import { Col, GridSizes, Row } from "../grid";
import RowCard from "../RowCard";
import "./expandableRow.css";

interface ExpandableRowCardProp {
    cols : {size:GridSizes, content:JSX.Element}[]
    noun?:string,
    startOpen?:boolean
}

const ExpandableRow : React.FC<ExpandableRowCardProp> = ({cols, noun, children, startOpen}) => {
    const [isOpen, setIsOpen] = React.useState(startOpen || false);
    return <RowCard><Col>
        <Row>
            {cols.map((c, i) => <Col key={i} md={c.size}>{c.content}</Col>)}
            <Col md={4} className="text-right">
                <Button variant="active" onClick={()=>setIsOpen(b=>!b)}>
                    {isOpen ? `Hide ${noun ? noun : ""}` : `Show ${noun ? noun : ""}`}
                </Button>
            </Col>
        </Row>
        <Row className={`mt-16 jobjump-expand-row jobjump-expand-row-${isOpen ? "show" : "hide"}`}>
            <Col>
               {children} 
            </Col>
        </Row>
    </Col> </RowCard>;
}

export default ExpandableRow;