import arrayMove from "array-move";
import { Referee } from "jobjump-types/Resume";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Button from "../../../components/Button";
import { Label, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { deleteFromList, SortableItemTemplate, SubsectionProps, SubsectionTemplate, updateObjectIndex } from "./common";

type SortableListItems = Partial<Referee> & {
    originalOrder: number
};

const SortableItem = SortableElement(
    ({ data, onChange, onDelete }: {data:SortableListItems, onChange:(t:Partial<Referee>)=>void, onDelete:()=>void}) => {
        return <SortableItemTemplate onDelete={onDelete}>
            <Row className="mt-8">
                <Col sm={4}>
                    <Label>Name</Label>
                    <TextField placeholder="Referee Name" value={data.name}
                        onChange={v=>onChange({...data, name:v})} />
                </Col>
                <Col sm={4}>
                    <Label>Company</Label>
                    <TextField placeholder="Company" value={data.company}
                        onChange={v=>onChange({...data, company:v})} />
                </Col>
                <Col sm={4}>
                    <Label>Position</Label>
                    <TextField placeholder="Position" value={data.position}
                        onChange={v=>onChange({...data, position:v})} />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Label>Phone</Label>
                    <TextField placeholder="Phone" value={data.phone}
                        onChange={v=>onChange({...data, phone:v})} />
                </Col>
                <Col sm={6}>
                    <Label>Email</Label>
                    <TextField placeholder="Email" value={data.email}
                        onChange={v=>onChange({...data, email:v})} />
                </Col>
            </Row>
        </SortableItemTemplate>
    }
)

const SortableList = SortableContainer(
    (props:{data:SortableListItems[], section:Partial<Referee>[], setSection:(r:Partial<Referee>[])=>void}) => <div>
        {props.data.map((t: SortableListItems, i: number) => {
            return <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                <SortableItem 
                    index={i} 
                    data={t} 
                    onChange={(t)=>props.setSection(updateObjectIndex(props.section, t, i))} 
                    onDelete={()=>props.setSection(deleteFromList(props.section, i))} />
            </div>
        })}
    </div>
);

const RefereeSection : React.FC<SubsectionProps<"referees">> = ({section, setSection, sectionConfig, setSectionConfig}) => 
    <SubsectionTemplate title="Referees"
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}
        buttons={[
            {text:"Referee", onClick: ()=>setSection([...section, {name:"", company:"", position:"", phone:"", email:""}])}
        ]}>
            <SortableList
                axis="xy"
                data={section.map((s,i) => ({...s, originalOrder:i}))}
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(section, oldIndex, newIndex);
                    setSection(order);
                }} 
                section={section}
                setSection={setSection} />
            {section.length === 0 && <Row className="mb-64 mt-64">
                <Col className="text-center">
                    <Button variant="active" onClick={()=>setSection(section.concat([{name:"", company:"", position:"", phone:"", email:""}]))}>
                        Add Referee
                    </Button>
                </Col>
            </Row>}
    </SubsectionTemplate>

export default RefereeSection;