import React, { useEffect } from "react";
import ReactGA, { FieldsObject } from "react-ga";
import { RouteComponentProps } from "react-router-dom";
import { EventTracking } from "./data/EventTracking";

ReactGA.initialize("UA-32172919-1");

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname);
      EventTracking.trackEvent("pageView", JSON.stringify(props.location));
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };
}