import React from "react";
import { NewsItem } from "jobjump-types/News";
import BaseCard from "./BaseCard";
import "./imageCard.css";
import "./newsCard.css";
import { getRandomImage } from "../../assets/GenericImages";
import Button from "../Button";
import ReactMarkdown from "react-markdown";
import { openLinkInNewTab } from "../../util";

interface NewsCardProps {
    item: NewsItem;
    index: number;
}

const NewsCard : React.FC<NewsCardProps> = ({item, index}) => {
    const [expandContent, setExpandContent] = React.useState(false);
    const [imageError, setImageError] = React.useState(false);
    const handleImageError = () => setImageError(true);
    return <BaseCard>
        <div className="jobjump-news-img">
            <img onError={handleImageError} src={item.imageUrl && !imageError ? item.imageUrl : getRandomImage(index)} className="jobjump-card-image" />
        </div>
        <div className="jobjume-news-body mt-32 mb-32">
            <small className="jobjump-news-date">{item.end ? new Date(Number(item.end)*1000).toDateString() : ""}</small>
            <p className="jobjump-news-lead truncate-lines-2">{item.url ? <a href={item.url}><strong>{item.title}</strong></a> : <strong>{item.title}</strong>}</p>
            <p className={`jobjump-news-body ${!expandContent ? "truncate-lines-4" : ""}`}>
                {item.is_markdown ? <ReactMarkdown source={item.body} /> : <span dangerouslySetInnerHTML={{__html:item.body}} />}
                {item.url && <Button variant="active" textButton onClick={()=>item.url && openLinkInNewTab(item.url)}>Find Out More</Button>}
            </p>
            <div className="text-right">
                <Button textButton variant="active" onClick={()=>setExpandContent(b=>!b)}>{expandContent ? "Hide" : "See More"}</Button>
            </div>
        </div>
    </BaseCard>
}

export default NewsCard;