import React from "react";
import { Svg, Path, Circle, Line, Rect, View } from "@react-pdf/renderer";

export type IconProps = {
    color: string
}

export const School : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="1.5" strokeLineCap="round" />
        <Path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4"  strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
    </Svg>

export const Tool : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="1.5" strokeLineCap="round"/>
        <Path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
    </Svg>

export const Certificate : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" stroke-linecap="round" strokeWidth="1.5"/>
        <Circle cx="15" cy="15" r="3" stroke={color} fill="none" strokeLineCap="round" strokeWidth="1.5"/>
        <Path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" stroke={color} fill="none" strokeLineCap="round"  strokeWidth="1.5"/>
        <Path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" stroke={color} fill="none" strokeLineCap="round"  strokeWidth="1.5"/>
        <Line x1="6" y1="9" x2="18" y2="9" stroke={color} fill="none" strokeLineCap="round" strokeWidth="1.5"/>
        <Line x1="6" y1="12" x2="9" y2="12" stroke={color} fill="none" strokeLineCap="round" strokeWidth="1.5"/>
        <Line x1="6" y1="15" x2="8" y2="15" stroke={color} fill="none" strokeLineCap="round" strokeWidth="1.5"/>
    </Svg>

export const IDCard : React.FC<IconProps> = ({color}) =>
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="1.5" />
        <Path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" strokeWidth="1.5" stroke={color} />
        <Path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" strokeWidth="1.5" stroke={color} />
        <Path d="M15 8l2 0" strokeWidth="1.5" stroke={color} />
        <Path d="M15 12l2 0" strokeWidth="1.5" stroke={color} />
        <Path d="M7 16l10 0" strokeWidth="1.5" stroke={color} />
    </Svg>

export const Trophy : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24" >
    <Path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <Path stroke={color} strokeWidth="1.5" d="M8 21l8 0" />
    <Path stroke={color} strokeWidth="1.5" d="M12 17l0 4" />
    <Path stroke={color} strokeWidth="1.5" d="M7 4l10 0" />
    <Path stroke={color} strokeWidth="1.5" d="M17 4v8a5 5 0 0 1 -10 0v-8" />
    <Path stroke={color} strokeWidth="1.5" d="M5 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    <Path stroke={color} strokeWidth="1.5" d="M19 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
    </Svg>


export const Briefcase : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24"stroke-linejoin="round">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none"  strokeWidth="1.5" strokeLineCap="round" />
        <Rect x="3" y="7" width="18" height="13" rx="2"  strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"  strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Line x1="12" y1="12" x2="12" y2="12.01"  strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M3 13a20 20 0 0 0 18 0"  strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
    </Svg> 

export const Rocket : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none"  strokeWidth="1.5" strokeLineCap="round" />
        <Path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="15" cy="9" r="1" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
    </Svg>

export const People : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="1.5" strokeLineCap="round"/>
        <Circle cx="9" cy="7" r="4" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M16 3.13a4 4 0 0 1 0 7.75" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
        <Path d="M21 21v-2a4 4 0 0 0 -3 -3.85" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" />
    </Svg>

export const Carousel : React.FC<IconProps> = ({color}) => 
    <Svg width="24" height="24" viewBox="0 0 24 24"stroke-linejoin="round">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none" strokeWidth="1.5"strokeLineCap="round" />
        <Circle cx="12" cy="12" r="6" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="5" cy="8" r="2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="12" cy="4" r="2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="19" cy="8" r="2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="5" cy="16" r="2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Circle cx="19" cy="16" r="2" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
        <Path d="M8 22l4 -10l4 10" strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round"  />
    </Svg>

export const TemplateIcon : React.FC<IconProps> = ({color}) =>
    <Svg width="24" height="24" viewBox="0 0 24 24">
        <Path stroke="none" d="M0 0h24v24H0z" fill="none"/>
        <Rect strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" x="4" y="4" width="16" height="4" rx="1" />
        <Rect strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" x="4" y="12" width="6" height="8" rx="1" />
        <Line strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" x1="14" y1="12" x2="20" y2="12" />
        <Line strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" x1="14" y1="16" x2="20" y2="16" />
        <Line strokeWidth="1.5" stroke={color} fill="none" strokeLineCap="round" x1="14" y1="20" x2="20" y2="20" />
    </Svg>

export const Twitter : React.FC<IconProps> = ({color}) => 
    <Svg viewBox="0 0 512 512">
        <Path fill={color} d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
    </Svg> 

export const LinkedIn : React.FC<IconProps> = ({color}) => 
    <Svg viewBox="0 0 448 512">
        <Path fill={color} d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" />
    </Svg> 

export const Instagram : React.FC<IconProps> = ({color}) => 
    <Svg viewBox="0 0 448 512">
        <Path fill={color} d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
    </Svg> 

export const Globe : React.FC<IconProps> = ({color}) =>
    <Svg viewBox="0 0 496 512">
        <Path fill={color} d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"/>
    </Svg> 

export const Envelope : React.FC<IconProps> = ({color}) =>
    <Svg viewBox="0 0 512 512">
        <Path fill={color} d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z" />
    </Svg> 

export const Pin : React.FC<IconProps> = ({color}) =>
    <Svg viewBox="0 0 384 512">
        <Path fill={color} d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z" />
    </Svg> 

export const Phone : React.FC<IconProps> = ({color}) =>
    <Svg viewBox="0 0 512 512">
        <Path fill={color} d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
    </Svg>  

export const otherSectionToIcon = (sectionName:string) =>
    sectionName === "LinkedIn" ? LinkedIn :
    sectionName === "Twitter" ? Twitter :
    sectionName === "Instagram" ? Instagram :
    Globe

export const IconWithText : React.FC<{height:string, Icon:React.FC<IconProps>}&IconProps> = ({Icon, height, children, ...props}) =>
    <View style={{display:"flex",flexDirection:"row"}}>
        <View style={{height, width:height}}>
            <Icon {...props}/>
        </View>
        <View style={{paddingLeft:"8px"}}>
            {children}
        </View>
    </View>