import React, { useEffect } from 'react';
import {CareerNames} from "jobjump-types/Careers";
import Careers from "../data/Careers";
import { openLinkInNewTab, getYoutubeParam } from '../util';
import { Row, Col } from '../components/grid';
import CareerImages from "../assets/CareerImages";
import FilterableGrid from '../components/FilterableGrid';
import ModalVideo from "react-modal-video";
import ContentWrapper from '../components/ContentWrapper';
import { EventTracking } from '../data/EventTracking';

const CareerVideos : React.FC<{}> =
    () => {
        const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
        const [videoID, setVideoID] = React.useState("");
        const [showVideo, setShowVideo] = React.useState(false);
        useEffect(() => {
            Careers.getCareerList().then((data) => {
                console.log(data);
                setCareerList(data)
            })
        }, []);
        return (
            <ContentWrapper 
                    title="Go Behind The Scenes In Your Career"
                    breadcrumbTitle="Career Videos"
                    subtitle={["Select a video below to learn more about it."]} >
                <Row><Col>
                    <FilterableGrid
                        numPerRow={3}
                        data={careerList.map(d => ({
                            imgUrl: CareerImages[d.name.toUpperCase()] || "",
                            text:d.name,
                            onClick: () => { 
                                EventTracking.trackEvent("clickVideoLink", String(d.id)).then(() => {
                                    Careers.getCareerDetails(d.id).then((d) => {
                                        const video = d.careerLinks.find((e) => e.kind === "video");   
                                        if (video) {
                                            const param = getYoutubeParam(video.url);
                                            if (param) {
                                                setVideoID(param);
                                                setShowVideo(true);
                                            } else {
                                                openLinkInNewTab(video.url);
                                            }
                                        }
                                    })
                                });
                            }
                        }))}
                    />
                </Col></Row>
                <ModalVideo channel='youtube' videoId={videoID} isOpen={showVideo} onClose={()=>setShowVideo(false)} />
            </ContentWrapper>
        );
    }

export default CareerVideos;