import React from "react";
import { ResumeFormChildrenProps, YesNoComponent } from "./ResumeCommonTypes";
import { Form } from "react-bootstrap";

const PersonalFolder : React.FC<ResumeFormChildrenProps> =
    ({ generalResume, updateGeneralResume }) =>
        <Form>
            <p>I have prepared a neat personal folder, including the following:</p>
            <h5>Resume:</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_resume}
                name="resume"
                onChange={(folder_resume) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_resume
                            }
                        }
                    })
                }
            />
            <h5>Cover Letter:</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_letter}
                name="letter"
                onChange={(folder_letter) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_letter
                            }
                        }
                    })
                }
            />
            <h5>Three References:</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_references}
                name="references"
                onChange={(folder_references) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_references
                            }
                        }
                    })
                }
            />
            <h5>Proof of Age (Copy of Birth Certificate):</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_proof_of_age}
                name="age"
                onChange={(folder_proof_of_age) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_proof_of_age
                            }
                        }
                    })
                }
            />
            <h5>Proof of my passion in this career :</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_project}
                name="project"
                onChange={(folder_project) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_project
                            }
                        }
                    })
                }
            />
            <h5>A recent report card :</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_report_card}
                name="report"
                onChange={(folder_report_card) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_report_card
                            }
                        }
                    })
                }
            />
            <h5>Awards:</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_awards}
                name="awards"
                onChange={(folder_awards) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_awards
                            }
                        }
                    })
                }
            />
            <h5>Spare copy of cover letter, resume, references to leave behind with them:</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_spare_resume}
                name="spare_resume"
                onChange={(folder_spare_resume) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_spare_resume
                            }
                        }
                    })
                }
            />
            <h5>Spare paper and pen (to write down any further information they give you in an interview) :</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_spare_paper}
                name="spare-paper"
                onChange={(folder_spare_paper) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_spare_paper
                            }
                        }
                    })
                }
            />
            <h5>Questions to ask potential employers :</h5>
            <YesNoComponent
                value={generalResume.generic_employment.folder.folder_questions}
                name="resume"
                onChange={(folder_questions) =>
                    updateGeneralResume({
                        ...generalResume,
                        generic_employment: {
                            ...generalResume.generic_employment,
                            folder: {
                                ...generalResume.generic_employment.folder,
                                folder_questions
                            }
                        }
                    })
                }
            />

        </Form>

export default PersonalFolder;