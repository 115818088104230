import { CareerInfo } from "jobjump-types/Careers";
import { CareerSubjectRecommendation } from "jobjump-types/Subjects";
import React from "react";
import { useParams } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import Careers from "../../data/Careers";
import Subjects from "../../data/Subjects";
import HSCSubjects from "../my_career/HSCSubjects";

const SubjectDetails : React.FC = () => {
    const {id} = useParams<{id:string}>();
    const [careerDetails, setCareerDetails] = React.useState<CareerInfo>();
    const [careerName, setCareerName] = React.useState<string>();
    const [subjectRecommendations, setSubjectRecommendations] = React.useState<CareerSubjectRecommendation[]>([]);

    React.useEffect(() => {
        Subjects.getSubjectsForCareer(String(id)).then(c => {
            setSubjectRecommendations(c);
        });
        Careers.getCareerDetails(Number(id)).then(setCareerDetails);
    }, [id]);

    React.useEffect(() => {
        Careers.getCareerList().then(l => {
            const c = l.find(c => String(c.id) === id);
            if (c) {
                setCareerName(c.name)
            }
        });
    }, [id]);
    return  <ContentWrapper 
            title={"Senior Subjects for "+(careerName||"")}>
            {careerDetails && <PageSectionRow>
                <Col>
                    <HSCSubjects subjectsItem={careerDetails.careerText.find(c => c.kind === "subject")} 
                        atarLinks={careerDetails.atarLink} subjectRecommendations={subjectRecommendations} />
                </Col>
            </PageSectionRow>}
    </ContentWrapper>
}

export default SubjectDetails;