const uploadFileWithSignedPOST = async (uploadDetails : {[k:string]:any}, file: File) : Promise<void> => {
    let form = new FormData();
    Object.keys(uploadDetails.fields).forEach(key => form.append(key, uploadDetails.fields[key]));
    form.append('file', file);
    console.log(form);
    const response = await fetch(uploadDetails.url, { method: 'POST', body: form });
    if (!response.ok) {
        console.log(response);
        return Promise.reject("Upload failed")
    }
    return Promise.resolve();
}

export {uploadFileWithSignedPOST};