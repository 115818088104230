// From https://linguinecode.com/post/responsive-grid-layout-react-component
import React from "react";
import "./grid.css";

type GridItemsAlignment =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline";

type GridJustify =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type GridSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface RowProps {
  alignItems?: GridItemsAlignment;
  expanded?: boolean;
  small?:boolean;
  verySmall?:boolean;
  justify?: GridJustify;
  className?: string;
}
const Row : React.FC<RowProps & React.ComponentProps<"div">> = ({ alignItems, children, expanded, small, verySmall, justify, className, ...props })=> {
    const classes: string =
      (className ? `${className} ` : "")  +
      "jj-row" +
      (expanded ? ` expanded` : "") +
      (small ? ` small` : "") +
      (verySmall ? ` very-small` : "") +
      (justify ? ` ${justify}` : "") +
      (alignItems ? ` ${"align-" + alignItems}` : "");
 
    return <div className={classes} {...props}>{children}</div>;
}

interface ColProps {
  lg?: GridSizes;
  md?: GridSizes;
  sm?: GridSizes;
  className?: string;
  noPadding?:boolean;
}

type ColumnProps = ColProps & React.ComponentProps<"div">;
const Col = React.forwardRef<HTMLDivElement, ColumnProps>(({ children, lg, md, sm, className, noPadding, ...props }, ref) => {
    const classes: string =
      (className ? `${className} ` : "")  +
      "jj-column" +
      (sm ? ` ${"jj-sm-" + sm}` : "") +
      (md ? ` ${"jj-md-" + md}` : "") +
      (lg ? ` ${"jj-lg-" + lg}` : "") +
      (noPadding ? " jj-column-no-padding" : "");
  
    return <div ref={ref} className={classes} {...props}>{children}</div>;
});

export {Row, Col};