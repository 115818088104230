import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { contrastingColor, HeaderProps } from "./CommonSections";
import { Envelope, IconWithText, otherSectionToIcon, Phone, Pin } from "./Icons";

const SimpleLHS : React.FC<HeaderProps> = ({resume:{styleConfig, personal_details}}) => {
    const textColour = contrastingColor(styleConfig.primaryColor);
    const style = StyleSheet.create({
        name: {
            fontSize: "28pt",
            textAlign: "center",
            lineHeight: 1,
            paddingBottom: "32px",
            color: textColour
        },
        sectionStyle : {
            height: "100vh",
            backgroundColor: styleConfig.primaryColor,
            color: textColour
        },
        item: {
            paddingBottom: "16px"
        },
        inner: {
            marginLeft: "16px",
            marginRight: "16px",
            marginTop: "16px",
        }
    });
    return <View style={style.sectionStyle}>
        <View style={style.inner}>
            {personal_details.name?.split(" ").map(seg =>
                <Text style={style.name}>{seg}</Text>
            )}
            {personal_details.address && personal_details.address !== "" ? 
                <IconWithText height="12pt" Icon={Pin} color={textColour}>
                    <Text style={style.item}>{personal_details.address}</Text>
                </IconWithText> : undefined}
            {personal_details.email && personal_details.email !== "" ? 
                <IconWithText height="12pt" Icon={Envelope} color={textColour}>
                    <Text style={style.item}>{personal_details.email}</Text>
                </IconWithText> : undefined}
            {personal_details.phone && personal_details.phone !== "" ? 
                <IconWithText height="12pt" Icon={Phone} color={textColour}>
                    <Text style={style.item}>{personal_details.phone}</Text>
                </IconWithText> : undefined}
            {personal_details.other?.map(o =>
                o.value !== "" ? 
                    <IconWithText height="12pt" Icon={otherSectionToIcon(o.key)} color={textColour}>
                        <Text style={style.item}>{o.value}</Text>
                    </IconWithText> : undefined
            )}
            {personal_details.languages && personal_details.languages !== "" ? 
                <View>
                    <Text>Languages Spoken:</Text> 
                    <Text style={style.item}>{personal_details.languages}</Text>
                </View> : undefined}
        </View>
    </View>
}

export default SimpleLHS;