import React from "react";
import ReactPDF, { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import { Resume, SectionConfig, TitleAndDetails } from "jobjump-types/Resume";
import { UnorderedList, UnorderedListItem } from "../../work_experience/eWorkExperienceCertificate";
const Bitter = require("../../../assets/fonts/Bitter-Regular.ttf");
const BitterBold = require("../../../assets/fonts/Bitter-Bold.ttf");
const Inter = require("../../../assets/fonts/Inter-Regular.ttf");
const InterBold = require("../../../assets/fonts/Inter-Bold.ttf");
const Lato = require("../../../assets/fonts/Lato-Regular.ttf");
const LatoBold = require("../../../assets/fonts/Lato-Bold.ttf");
const Poppins = require("../../../assets/fonts/Poppins-Regular.ttf");
const PoppinsBold = require("../../../assets/fonts/Poppins-Bold.ttf");
const Roboto = require("../../../assets/fonts/Roboto-Regular.ttf");
const RobotoBold = require("../../../assets/fonts/Roboto-Bold.ttf");
const Indie = require("../../../assets/fonts/IndieFlower-Regular.ttf");
const ShadowsIntoLight = require("../../../assets/fonts/ShadowsIntoLight-Regular.ttf");
const Abril = require("../../../assets/fonts/AbrilFatface-Regular.ttf");
const ArchitectsDaughter = require("../../../assets/fonts/ArchitectsDaughter-Regular.ttf");
const DancingScript = require("../../../assets/fonts/DancingScript-VariableFont_wght.ttf");
const Dosis = require("../../../assets/fonts/Dosis-VariableFont_wght.ttf");
const Inconsolata = require("../../../assets/fonts/Inconsolata-VariableFont_wdth,wght.ttf");
const Italianno = require("../../../assets/fonts/Italianno-Regular.ttf");
const OpenSans = require("../../../assets/fonts/OpenSansCondensed-Light.ttf");
const OpenSansBold = require("../../../assets/fonts/OpenSansCondensed-Bold.ttf");
const Pacifico = require("../../../assets/fonts/Pacifico-Regular.ttf");
const Playfair = require("../../../assets/fonts/PlayfairDisplay-VariableFont_wght.ttf");
const Satisfy = require("../../../assets/fonts/Satisfy-Regular.ttf");
const Teko = require("../../../assets/fonts/Teko-Regular.ttf");
const TeckoBold = require("../../../assets/fonts/Teko-Bold.ttf");

export const loadFont = (font:string) => {
    switch (font) {
        case "Bitter": {
            Font.register({ family: "Bitter", src: Bitter });
            Font.register({ family: "Bitter", src: BitterBold, fontWeight: "bold" });
            break;
        }
        case "Inter": {
            Font.register({ family: "Inter", src: Inter });
            Font.register({ family: "Inter", src: InterBold, fontWeight: "bold" });
            break;
        }
        case "Lato": {
            Font.register({ family: "Lato", src: Lato });
            Font.register({ family: "Lato", src: LatoBold, fontWeight: "bold" });
            break;
        }
        case "Poppins": {
            Font.register({ family: "Poppins", src: Poppins });
            Font.register({ family: "Poppins", src: PoppinsBold, fontWeight: "bold" });
            break;
        }
        case "Roboto": {
            Font.register({ family: "Roboto", src: Roboto });
            Font.register({ family: "Roboto", src: RobotoBold, fontWeight: "bold" });
            break;
        }
        case "Indie": {
            Font.register({ family: "Indie", src: Indie });
            break;
        }
        case "ShadowsIntoLight": {
            Font.register({ family: "ShadowsIntoLight", src: ShadowsIntoLight });
            break;
        }
        case "ShadowsIntoLight": {
            Font.register({ family: "ShadowsIntoLight", src: ShadowsIntoLight });
            break;
        }
        case "Abril": {
            Font.register({ family: "Abril", src: Abril });
            break;
        }
        case "Architects Daughter": {
            Font.register({ family: "Architects Daughter", src: ArchitectsDaughter });
            break;
        }
        case "Dancing Script": {
            Font.register({ family: "Dancing Script", src: DancingScript });
            break;
        }
        case "Dosis": {
            Font.register({ family: "Dosis", src: Dosis });
            break;
        }
        case "Inconsolata": {
            Font.register({ family: "Inconsolata", src: Inconsolata });
            break;
        }
        case "Open Sans": {
            Font.register({ family: "Open Sans", src: OpenSans });
            Font.register({ family: "Open Sans", src: OpenSansBold, fontWeight: "bold" });
            break;
        }
        case "Italianno": {
            Font.register({ family: "Italianno", src: Italianno });
            break;
        }
        case "Pacifico": {
            Font.register({ family: "Pacifico", src: Pacifico });
            break;
        }
        case "Playfair": {
            Font.register({ family: "Playfair", src: Playfair });
            break;
        }
        case "Satisfy": {
            Font.register({ family: "Satisfy", src: Satisfy });
            break;
        }
        case "Teko": {
            Font.register({ family: "Teko", src: Teko });
            Font.register({ family: "Teko", src: TeckoBold, fontWeight: "bold" });
            break;
        }
    }
}

const Item : React.FC = ({ children }) => {
    const itemStyle = StyleSheet.create({
        item: {
          flexDirection: 'row',
        },
        bulletPoint: {
          width: 10,
        },
        itemContent: {
          flex: 1,
        },
    });
    return <View style={itemStyle.item}>
      <Text style={itemStyle.bulletPoint}>•</Text>
      <Text style={itemStyle.itemContent}>{children}</Text>
    </View>
}

export const DocumentGrid : React.FC = ({children}) => {
    const tableStyle = StyleSheet.create({
        tableWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap"
        }
    });
    return <View style={tableStyle.tableWrapper}>
        {children}
    </View>;
}

type DocumentCellProps = {
    size: 1|2|3|4|5|6|7|8|9|10|11|12
}

export const DocumentCell : React.FC<DocumentCellProps> = ({size, children}) => {
    const documentCellStyle = {
        1 : {
            flex: "0 0 8.33333%"
        },
        2 : {
            flex: "0 0 16.66667%"
        },
        3 : {
            flex: "0 0 25%"
        },
        4 : {
            flex: "0 0 33.33333%"
        },
        5 : {
            flex: "0 0 41.66667%"
        },
        6 : {
            flex: "0 0 50%"
        },
        7 : {
            flex: "0 0 58.33333%"
        },
        8 : {
            flex: "0 0 66.66667%"
        },
        9 : {
            flex: "0 0 75%"
        },
        10 : {
            flex: "0 0 83.33333%"
        },
        11 : {
            flex: "0 0 91.66667%"
        },
        12 : {
            flex: "0 0 100%"
        },
    }
    return <View style={documentCellStyle[size]}>
        {children}
    </View>
}
  
export type ResumeSectionProp<Style extends ReactPDF.Styles> = {
    style : Style,
    resume : Resume
}


export const SummarySection = 
<Style extends ReactPDF.Styles>({resume:{career_goal}, style} : ResumeSectionProp<Style>) =>
    <View style={style.sectionStyle} wrap={false}>
        <Text>{career_goal}</Text> 
    </View>

export const EducationHistory = 
<Style extends ReactPDF.Styles>({resume:{education_history}, style} : ResumeSectionProp<Style>) => {
    const tableStyle = StyleSheet.create({
        tableHeader: {
            fontWeight: "bold"
        }
    });
    const showMarks = education_history.subjects.reduce((a, e) => a || (e.mark !== undefined && e.mark !== ""), false);
    return <View style={style.sectionStyle} wrap={false}>
        <Text style={style.heading2}>{education_history.schoolName}</Text>
        <DocumentGrid>
            <DocumentCell size={6}><Text style={tableStyle.tableHeader}>Subject</Text></DocumentCell>
            {showMarks ? <DocumentCell size={2}><Text style={tableStyle.tableHeader}>Mark</Text></DocumentCell> : undefined}
            <DocumentCell size={4}><Text style={tableStyle.tableHeader}></Text></DocumentCell>
        </DocumentGrid>
        {education_history.subjects.map(s => <DocumentGrid>
           <DocumentCell size={6}><Text>{s.name}</Text></DocumentCell>
           {showMarks ? <DocumentCell size={2}><Text>{s.mark || ""}</Text></DocumentCell> : undefined}
           <DocumentCell size={4}><Text>{s.comment || ""}</Text></DocumentCell>
        </DocumentGrid>)}
    </View>
}


export const EmploymentHistory = 
<Style extends ReactPDF.Styles>({resume:{employment_details}, style} : ResumeSectionProp<Style>) =>
    <View style={style.sectionStyle}>
        {employment_details.map(e => 
            <View style={{paddingBottom:"16px"}}>
                <DocumentGrid>
                    <DocumentCell size={6}><Text style={style.heading2}>{e.company}</Text></DocumentCell>
                    <DocumentCell size={6}><Text style={style.heading2}>{e.dateStart} - {e.dateEnd || ""}</Text></DocumentCell>
                </DocumentGrid>
                <Text style={style.smallText}>{e.kind}</Text>
                <Text>{e.description}</Text>
            </View>
        )}
    </View>

export const Skills = 
<Style extends ReactPDF.Styles>({resume:{skills}, style} : ResumeSectionProp<Style>) =>
    <View wrap={false} style={style.sectionStyle}>
        {skills.map(s => !s.hidden && <Item>{s.title} {s.details && s.details !== "" && ` - ${s.details}`}</Item>)}
    </View>

export const TitleAndDesc = <Style extends ReactPDF.Styles>({items, style} : 
    Omit<ResumeSectionProp<Style>, "resume"> & {items:TitleAndDetails[]}) =>
    <View style={style.sectionStyle} wrap={false}>
        {items.map(i => <View wrap={false}><Item>{i.title} {i.details && i.details !== "" && ` - ${i.details}`}</Item></View>)}
    </View>

export const Referees = 
<Style extends ReactPDF.Styles>({resume:{referees}, style} : ResumeSectionProp<Style>) =>
    <View style={style.sectionStyle}>
        {referees.map(r => <View wrap={false}>
            <Text style={style.heading2}>{r.name} - {r.position && r.position !== "" && r.position} {r.company && r.company !== "" && `(${r.company})`}</Text>
            <DocumentGrid>
                {r.phone !== undefined && r.phone !== "" ? <DocumentCell size={6}><Text>Phone: {r.phone}</Text></DocumentCell> : undefined} 
                {r.email !== undefined && r.email !== "" ? <DocumentCell size={6}><Text>Email: {r.email}</Text></DocumentCell> : undefined} 
            </DocumentGrid>
        </View>)}
    </View>

export const EWorkExperience = 
<Style extends ReactPDF.Styles>({resume:{eWorkExperience}, style} : ResumeSectionProp<Style>) =>
    <View style={style.styleSection}>
        <Text>In my spare time, I've completed a project relating to this career. I have:</Text>
        <UnorderedList>
            {eWorkExperience.map(m => <UnorderedListItem>{m.text}</UnorderedListItem>)}
        </UnorderedList>
    </View>

export type SectionProps<T extends ReactPDF.Styles> = {
    section: SectionConfig,
    defaultTitle: string,
    style: T,
    color: string
}

export type OrderedResumeSectionProps<T extends ReactPDF.Styles> = {
    style: T,
    resume: Resume,
    SectionComponent: React.FC<SectionProps<T>>
}

export const OrderedResumeSections : React.FC<OrderedResumeSectionProps<{heading1:any}>> = ({resume, style, SectionComponent}) =>
    <View>
        {resume.order.map(s => 
            s.name === "awards" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Awards" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <TitleAndDesc items={resume.awards} style={style} /> 
                </SectionComponent> :
            s.name === "achievements" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Achievements" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <TitleAndDesc items={resume.achievements} style={style} /> 
                </SectionComponent> :
            s.name === "qualifications" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Qualifications" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <TitleAndDesc items={resume.qualifications} style={style} /> 
                </SectionComponent> :
            s.name === "hobbies" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Hobbies" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <TitleAndDesc items={resume.hobbies} style={style} />
                </SectionComponent> :
            s.name === "career_goal" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Career Goal" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <SummarySection resume={resume} style={style} />
                </SectionComponent> :
            s.name === "education_history" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Education History" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <EducationHistory resume={resume} style={style} />
                </SectionComponent> :
            s.name === "employment_details" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Employment History" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <EmploymentHistory resume={resume} style={style} />
                </SectionComponent> :
            s.name === "referees" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Referees" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <Referees resume={resume} style={style} />
                </SectionComponent> :
            s.name === "skills" && !s.hidden ? 
                <SectionComponent key={s.name} defaultTitle="Skills" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <Skills resume={resume} style={style} />
                </SectionComponent> :
            s.name === "eWorkExperience" && !s.hidden && resume.eWorkExperience.length > 0 ?
                <SectionComponent key={s.name} defaultTitle="eWork Experience Project" section={s} style={style} color={resume.styleConfig.primaryColor}>
                    <EWorkExperience resume={resume} style={style} />
                </SectionComponent> :
            undefined
        )}
    </View>

export type HeaderProps = {
    resume: Resume
}

export const contrastingColor = (color:string) =>
    luma(color) >= 165 ? '#000000' : '#ffffff'

const luma =(color:string) => {
    const rgb = hexToRGBArray(color);
    return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}

const hexToRGBArray = (colorWithHash:string) =>
{
    let color = colorWithHash.replace("#", "");
    if (color.length === 3)
        color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
    else if (color.length !== 6)
        throw('Invalid hex color: ' + color);
    let rgb = [];
    for (var i = 0; i <= 2; i++)
        rgb[i] = parseInt(color.substr(i * 2, 2), 16);
    return rgb;
}