import React, { useEffect } from 'react';
import {CareerNames} from "jobjump-types/Careers";
import Careers  from "../../data/Careers";
import { Col } from '../../components/grid';
import CareerImages from "../../assets/CareerImages";
import { RouteComponentProps } from 'react-router';
import FilterableGrid from '../../components/FilterableGrid';
import PageSectionRow from '../../components/PageSectionRow';
import ContentWrapper from '../../components/ContentWrapper';

const WorkExperienceDirectoryListing : React.FC<RouteComponentProps> =
    ({history}) => {
        const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
        useEffect(() => {
            Careers.getCareerList().then((data) => {
                console.log(data);
                setCareerList(data)
            })
        }, []);
        return (
            <ContentWrapper
                    title="Real Work Experiences Directory"
                    subtitle={["Looking for some real experience in your favourite career?", "Select from below to find places to do it!"]}>
                <PageSectionRow><Col>
                    <FilterableGrid
                        numPerRow={3}
                        data={careerList.map(d => ({
                            text:d.name,
                            imgUrl:CareerImages[d.name.toUpperCase()] || "",
                            onClick:() => history.push("/workExperience/"+d.id)
                        }))}
                    />
                </Col></PageSectionRow>
            </ContentWrapper>
        );
    }

export default WorkExperienceDirectoryListing;