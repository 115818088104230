import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { Col, Row } from "../components/grid";
import JobJumpLogo from "../assets/JobJump-Logo.png";

const JobJumpFooter : React.FC<{}> =
    () => {
        const d = new Date();
        const currentYear = d.getFullYear();
        return <>
            <Row expanded className="det-ack pt-16 pb-16">
                <Col className="text-center text-white">
                    Acknowledgement to the NSW Department of Education 
                </Col>
            </Row>
            <Row className="mt-32 mb-32">
                <Col className="text-left mb-16" sm={12} md={6}>
                    <img src={JobJumpLogo} height={90} />
                </Col>
                <Col className="text-right" sm={12} md={6}>
                    <span className="block">&copy; 2014-{currentYear} JobJump Pty Ltd. All Rights Reserved.</span>
                    <span className="block"><Link to="/tos">Terms of Service &amp; Privacy Policy</Link> | <Link to="/license">School Licence Terms</Link></span>
                </Col>
            </Row>
        </>;
    }
export default JobJumpFooter;