import {RegistrationDetails} from "jobjump-types/User";
import { RouteComponentProps } from "react-router";

const emptyRegistration : RegistrationDetails = {
    firstName : "",
    lastName: "",
    email : "",
    registrationKind : "student",
    year : 9,
    gender : "m",
    aboriginal : false,
    password : "",
    schoolID : "",
    schoolPassword : "",
    careers : [],
    unsure : true,
    subjects : []
}

export interface RegistrationStepProps extends RouteComponentProps {
    details : RegistrationDetails,
    setRegistrationDetails : (details:RegistrationDetails)=>void,
    onStepCompletion: () => void
}

export {emptyRegistration}