import qs from "query-string";
import {HeroConfiguration, SchoolHomepage, SchoolHomepageResponse, SchoolUploadedFile} from "jobjump-types/SchoolHomePage";
import { fetchHandleServerStatusJSON } from "./Utils";

const JsonPost = {
    method:"POST",
    headers: {
        "Content-Type": "application/json"
    }
}

interface UploadLink {
    uploadDetails: {[k:string]:any},
    key: string
}

const CaSchoolHomepage = {
    initaliseSchoolSite : (args:{shortName:string, schoolName:string}) : Promise<SchoolHomepage> =>
        fetch("/api/advisers/homepage/init", {...JsonPost, body:JSON.stringify(args)}).then(res=>res.json()),
    schoolSiteConfig : () : Promise<SchoolHomepageResponse|undefined> => 
        fetch("/api/advisers/homepage/siteConfig").then(r=>r.json().catch(()=>{return undefined})),
    updateSchoolSite : (args:SchoolHomepage) => 
        fetch("/api/advisers/homepage/siteConfig", {...JsonPost, body:JSON.stringify(args)}),
    deleteSchoolSite : () : Promise<void> => 
        fetch("/api/advisers/homepage/siteConfig", {method:"DELETE"}).then(r=>{}),
    getLogoLink : () : Promise<string|undefined> =>
        fetch("/api/advisers/homepage/logo").then(res=>res.text().catch(()=>{return undefined})),
    confirmLogoUpload : (args:{key:string}) : Promise<{logoUrl:string}> =>
        fetch("/api/advisers/homepage/logoUpload",{...JsonPost, body: JSON.stringify(args) }).then(res=>res.json()),
    getDocumentUploadLink : (args:{mimeType:string, fileName:string, fileSize:number}) : Promise<UploadLink|undefined> =>
        fetchHandleServerStatusJSON(`/api/advisers/homepage/uploadDocumentLink?${qs.stringify(args)}`), 
    confirmDocumentUpload : (fileName:string, displayName:string, key:string, category:number) : Promise<SchoolUploadedFile> =>
        fetch("/api/advisers/homepage/fileUpload", {...JsonPost, body: JSON.stringify({fileName,displayName,key,category}) }).then(res=>res.json()),
    confirmBulletinUpload : (fileName:string, displayName:string, key:string) : Promise<SchoolUploadedFile> =>
        fetch("/api/advisers/homepage/bulletinUpload", {...JsonPost, body: JSON.stringify({fileName,displayName,key}) }).then(res=>res.json()),
    getUploadedFiles : () : Promise<SchoolUploadedFile[]> =>
        fetch("/api/advisers/homepage/uploadedFiles").then(r=>r.json()),
    getDownloadLinkForFile : (fileId:string) : Promise<string> =>
        fetch(`/api/advisers/homepage/file/${fileId}`).then(res=>res.text()),
    deleteUploadedFiles: (fileId:string) : Promise<void> => 
        fetch(`/api/advisers/homepage/file/${fileId}`, {method:"DELETE"}).then(r=>{}),
    getDocumentCategories: () : Promise<{id:number, name:string}[]> =>
        fetch("/api/advisers/homepage/fileCategory").then(r=>r.json()),
    putDocumentCategories: (name:string) : Promise<number> =>
        fetch("/api/advisers/homepage/fileCategory", {...JsonPost, body:JSON.stringify({name})}).then(r=>r.json()),
    renameDocumentCategory: (id:number, name:string) : Promise<void> =>
        fetch("/api/advisers/homepage/fileCategory/"+String(id), {...JsonPost, body:JSON.stringify({name})}).then(()=>{}),
    deleteDocumentCategory: ({id, destinationId}:{id:number, destinationId:number}) : Promise<void> =>
        fetch("/api/advisers/homepage/fileCategory/"+String(id), {method:"DELETE", headers:{"Content-Type": "application/json"}, body:JSON.stringify({destinationId})}).then(()=>{}),
    checkShortName : (shortName:string) : Promise<boolean> =>
        fetch("/api/advisers/homepage/shortName/"+shortName).then(r=>r.json()),
    getSchoolPageHero : () : Promise<HeroConfiguration> =>
        fetch("/api/advisers/homepage/homepageHero").then(r=>r.json()),
    confirmSchoolImageUpload : (args:{key:string}) : Promise<HeroConfiguration> =>
        fetch("/api/advisers/homepage/imageBanner",{...JsonPost, body: JSON.stringify(args) }).then(res=>res.json()),
    setSchoolHero : (args:HeroConfiguration) : Promise<HeroConfiguration> =>
        fetch("/api/advisers/homepage/homepageHero",{...JsonPost, body: JSON.stringify(args) }).then(res=>res.json()),

};

export default CaSchoolHomepage;
