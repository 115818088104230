import Agriculture from "./atar-category/agriculture.jpg";
import Architecture from "./atar-category/architecture.jpg";
import Arts from "./atar-category/arts.jpg";
import Business from "./atar-category/business.jpg";
import Combined from "./atar-category/combined-degrees.jpg";
import Communications from "./atar-category/communications.jpg";
import Creative from "./atar-category/creative.jpg";
import EduCombined from "./atar-category/education-combined.jpg";
import Edu from "./atar-category/education.jpg";
import Engineering from "./atar-category/engineering.jpg";
import Environmental from "./atar-category/environmental.jpg";
import Health from "./atar-category/health.jpg";
import HumanMovement from "./atar-category/human-movement.jpg";
import IT from "./atar-category/it.jpg";
import LawCombined from "./atar-category/law-combined.jpg";
import Law from "./atar-category/law.jpg";
import MedCombined from "./atar-category/med-combined.jpg";
import Med from "./atar-category/med.jpg";
import Nursing from "./atar-category/nursing.jpg";
import Pathway from "./atar-category/pathway.jpg";
import Science from "./atar-category/science.jpg";
import SingleDegree from "./atar-category/single-degrees.jpg";
import SocialSci from "./atar-category/social-sci.jpg";
import SocialWork from "./atar-category/social-work.jpg";
import Tourism from "./atar-category/tourism.jpg"; 

const AtarImages : {[k:string]:string} = {
    "AGRICULTURE & ENVIRONMENT": Agriculture,
    "ARCHITECTURE & CONSTRUCTION": Architecture,
    "ARTS": Arts,
    "BUSINESS": Business,
    "COMMUNICATIONS": Communications,
    "CREATIVE ARTS": Creative,
    "ENVIRONMENT": Environmental,
    "EDUCATION": Edu,
    "EDUCATION COMBINED": EduCombined,
    "ENGINEERING": Engineering,
    "HEALTH": Health,
    "HUMAN MOVEMENT, PE, SPORT": HumanMovement,
    "INFORMATION TECHNOLOGY": IT,
    "LAW": Law,
    "LAW COMBINED": LawCombined,
    "MEDICINE OR MEDICAL SCIENCE": Med,
    "MEDICINE OR MEDICAL SCIENCE COMBINED": MedCombined,
    "HEALTH, MEDICINE & NURSING": Nursing,
    "SCIENCE": Science,
    "SOCIETY & CULTURE": SocialSci,
    "SOCIAL WORK": SocialWork,
    "TOURISM AND HOSPITALITY": Tourism,
    "PATHWAY OPTIONS": Pathway,
    "SINGLE DEGREES ACROSS VARIOUS FIELDS": SingleDegree,
    "COMBINED DEGREES": Combined
};

export default AtarImages;