import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";

const year = (new Date()).getFullYear();

const WhatToWrite: React.FC<{}> =
    () => <ContentWrapper
            title="Writing a Cover Letter & Resume"
            subtitle={["Need to prepare a Cover Letter and Resume? This will get you started."]}
            breadcrumbs={[
                {name:"Contacting an Employer", link:"/contactEmployer"}, 
            ]}>
        <PageSectionRow>
            <Col>
                <h2>Cover Letter</h2>
                <p><strong>Here is an example of the main format of a Cover Letter and a Resume when seeking a position in a company. Note that the advice in the bracketed areas in italics is to guide you on what you may need to write. If you are a school leaver it is wise to send out your letters and resumes by July/August of your last year of school. When you write to a company you can also add a couple of copies of references.<br />
                </strong></p>
                <p>103 Pleasant Street				<em>(Your number and street address here)</em><br />
                    Townview NSW 2999				<em>	(Your suburb, state and post code here)</em><br />
                    M: 0433 999 888				<em>	(Mobile phone)</em><br />
                    E: jane.student@gmail.com			<em>	(Email address)</em><br />
                </p>
                <p>12 December {year}				<em>(The date you are writing this letter)</em><br />
                </p>
                <p>Ms Smith					<em>(Name of person you are writing to)</em><br />
                    The Manager					<em>(The position of person you are writing to)</em><br />
                    ACE Company Pty Ltd				<em>(The company’s name)</em><br />
                    298 Best Avenue				<em>(The company’s number and street)</em><br />
                    Greatville NSW 2998<em> (The company’s suburb, state &amp; post code)</em><br />
                </p>
                <p>Dear Ms Smith,</p>
                <p>I wish to apply to your company for a position as a …………………..<em>(name the career and its level eg: apprentice; trainee; qualified)</em>  for …………….<em>(state here when you seek to undertake the position, eg: to begin at the end of this year; to begin in February)</em>.</p>
                <p>I have demonstrated the qualities you require for this position. I am a person who is ………………<em>(state here the Personal Requirements that you possess and that the employer is seeking)</em>. Recently I completed work at ………………<em>(Name the company and its suburb location)</em> where I gained more knowledge and skill in this career. Some of the duties I performed included……………………… <em>(Provide here the types of jobs you did on work experience that are the skills this employer may be seeking in the job they may have)</em>. The training I have successfully undertaken was ……………………………..<em>(Name here any course(s) related to this career and where you studied it and to what level, eg: Certificate 2, 3, 4 Diploma, Degree)</em>. </p>
                <p>My true passion has been to work in this field and as proof of this, in my spare time I have …………………..(<em>write here some of the great things you did as described in the eWorkExperience project)</em>. </p>
                <p>I am writing to your company as it has a strong reputation for excellence in this field and I look forward to the opportunity to develop my abilities to their fullest potential.</p>
                <p>Find attached a copy of my resume which outlines in more detail the skills and experience I possess.</p>
                <p>I welcome the chance to meet with you to discuss the opportunity of working with your company. If you wish to contact me please reply using any of the contact details I have provided above.</p>
                <p>Yours sincerely,</p>
                <p>……………………<em>(Sign your name here)</em><br />
                    ……………………<em>(Type your name here)</em></p>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Resume</h2>
                <p><strong>Name:</strong> 		Jane Student<br />
                    <strong>Address:</strong> 	103 Pleasant Street		<em>(Your number and street address here)</em><br />
                    Townview NSW 2999		<em>(Your suburb, state and post code here)</em><br />
                    <strong>Ph: </strong>0433 999 888			<em>(Mobile phone)</em><br />
                    <strong>Email:</strong> 		jane.student@gmail.com	<em>(Email address)</em></p>
                <p><strong>Personal Goal</strong><br />
                    I aim to be………………………………….<em>(write here your ambition. For example, I aim to run one of Sydney’s most successful fine dining restaurants)</em>.</p>
                <p><strong>Education</strong><br />
                    {year} Townview TAFE<br />
                    Completed Certificate 3 Hospitality<br />
                    Modules included: ……………….<em>(List the modules you completed successfully)</em></p>
                <p>{year - 7} to {year - 1}	Townview High School<br />
                    Completed the Higher School Certificate.<br />
                    Subjects studied: English, Mathematics, Biology, Modern History, Food Technology, Visual Arts.</p>
                <p><em>(Have you done a First Aid course? If so, write in here).</em></p>
                <p><strong>Employment History</strong><br />
                    June {year - 2} to the present. Employed as a kitchen hand at Best Dining Restaurant, Townview. Duties included………………….<em>(write here the jobs you did)</em>.</p>
                <p><em>(You can include any voluntary work you have done here too).</em></p>
                <p><strong>Demonstrated Personal Qualities</strong><br />
                    I am a person who is:<br />
                    …………………………..<em>(write here the Personal Requirements for this career and in particular the qualities the employer may be seeking if you are replying to their advertisement)</em>.</p>
                <p><strong>Interests and Hobbies</strong><br />
                    …………………………..<em>(write more detail about your eWorkExperience project for this career)</em>.<br />
                    ………………………….<em>(write the other hobbies and sports you have and the teams and clubs you belong to. Have you had any successes or achievements such as being Captain, won an award, been grand finalists?)</em></p>
                <p><strong>Referees</strong> <br />
                    Name: <br />
                    Address:	<br />
                    Phone No:<br />
                </p>
                <p>Name:<br />
                    Address:<br />
                    Phone No:</p>
                <p>Name:<br />
                    Address:<br />
                    Phone No:</p>
                <p><em>(List above up to 3 people who will support your application. They can not be 	your personal friends or any relative. Make sure to get in touch with these people to let them know an employer may call them to ask them about<br />
                    what you are like as a person and what work qualities you possess).</em></p>
            </Col>
        </PageSectionRow>
    </ContentWrapper>

export default WhatToWrite;