import React from "react";
import {Row, Col} from "../../../components/grid";
import ExpandableRowCard from "../../../components/cards/ExpandableRowCard";
import CaStudentDetails from "../../../data/CaStudentDetails";
import { StudentReceivedMessages } from "jobjump-types/CaStudents";

const SentMessages : React.FC<{studentID:string}> =
({studentID}) => {
    const [rawData, setRawData] = React.useState<StudentReceivedMessages[]>([]);
    React.useEffect(() => {
        CaStudentDetails.getStudentReceivedMessages(studentID).then(setRawData);
    }, [studentID]);
    return <Row><Col>
        {rawData.length === 0 && <p>Student has not received messages.</p>}
        {rawData.map((m,i) =>
            <ExpandableRowCard 
                key={i}
                cols={[
                    {size:2, content:<>{(new Date(m.timestamp * 1000)).toDateString()}</>},
                    {size:6, content:<>{m.subject}</>},
                ]}>
                    <span dangerouslySetInnerHTML={{__html:m.body}}></span>
            </ExpandableRowCard>
        )}
    </Col></Row>
}

export default SentMessages;