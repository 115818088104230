import { IconX } from "@tabler/icons";
import React from "react";
import ReactModal from "react-modal";
import ReactPlayer from 'react-player'
import "./videoModal.css";

interface ModalProps {
    vimeoId: string,
    show: boolean,
    onHide: ()=>void,
}

const VideoModal : React.FC<ModalProps> = ({vimeoId, show, onHide, children}) =>
    <ReactModal
        className={"jobjump-video-modal"}
        overlayClassName="jobjump-modal-overlay"
        isOpen={show} 
        onRequestClose={onHide}>
        <>
            <div className={"text-right"}>
                <IconX className="video-close-button" height={50} width={50} onClick={onHide} />
            </div>
            <ReactPlayer playing width="95vw" height="45vh" url={`https://vimeo.com/${vimeoId}`} controls />
            <div className="text-right mt-16">
                {children}
            </div>
        </>
    </ReactModal>

export default VideoModal;