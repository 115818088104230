import _ from "lodash";
import CityAtNight from "./generic-images/city-at-night.jpg";
import Collaboration from "./generic-images/collaboration.jpg";
import ComputerCoffee from "./generic-images/computer-coffee.jpg";
import CrossingRoad from "./generic-images/crossing-road.jpg";
import FeetOnPavement from "./generic-images/feet-on-pavement.jpg";
import Ginza from "./generic-images/ginza-crossing.jpg";
import GirlOnComputer from "./generic-images/girl-on-computer.jpg";
import Meeting from "./generic-images/meeting.jpg";
import PedestrianMall from "./generic-images/pedestrian-mall.jpg";
import PointingComputer from "./generic-images/pointing-computer.jpg";
import Shadows from "./generic-images/shadows.jpg";
import Skyscraper from "./generic-images/skyscraper.jpg";
import StreetPhotographer from "./generic-images/street-photographer.jpg";
import Talking from "./generic-images/talking.jpg";
import TunnelAtNight from "./generic-images/tunnel-at-night.jpg";
import Tunnel from "./generic-images/tunnel.jpg";
import WalkingAcrossRoad from "./generic-images/walking-across-road.jpg";

const images = _.shuffle([
    CityAtNight,
    Collaboration,
    ComputerCoffee,
    CrossingRoad,
    FeetOnPavement,
    Ginza,
    GirlOnComputer,
    Meeting,
    PedestrianMall,
    PointingComputer,
    Shadows,
    Skyscraper,
    StreetPhotographer,
    Talking,
    TunnelAtNight,
    Tunnel,
    WalkingAcrossRoad,
]);

const getRandomImage = (index:number) : string => images[index % images.length]

const GenericImages = {
    CityAtNight,
    Collaboration,
    ComputerCoffee,
    CrossingRoad,
    FeetOnPavement,
    Ginza,
    GirlOnComputer,
    Meeting,
    PedestrianMall,
    PointingComputer,
    Shadows,
    Skyscraper,
    StreetPhotographer,
    Talking,
    TunnelAtNight,
    Tunnel,
    WalkingAcrossRoad,
}

export {GenericImages, getRandomImage};