import _ from "lodash";
import React from "react";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { UserDetailsProps } from "../../layout/Routes";
import { openLinkInNewTab } from "../../util";
import { CategoryTypes, LinkTo, uniCategoryPrettyNames, uniConfig } from "./UniLinkData";

type TableEntry = {logo:string, name:string, links:LinkTo[]};

const UniDirectory : React.FC<UserDetailsProps> = ({userDetails}) => {
    const [currentSelection, setCurrentSelection] = React.useState<CategoryTypes>("scholarships");
    const [currentTableEntries, setCurrentTableEntries] = React.useState<TableEntry[]>([]);
    const [states, setStates] = React.useState<string[]>([userDetails.state, ...(userDetails.additionalStates || [])]);
    React.useEffect(() => {
        const val = userDetails.state === "NSW" ? ["NSW", "ACT"] : [userDetails.state]
        setStates([...val, ...(userDetails.additionalStates || [])]);
    }, [userDetails])

    React.useEffect(() => {
        const newTableEntries = Object.entries(uniConfig).reduce((a, [name, c]) => {
            const isInState = states.find(s => c.states.includes(s));
            if (c[currentSelection].length === 0 || !isInState) {
                return a;
            }
            return a.concat([{logo:c.image, name, links:c[currentSelection] }]);
        }, [] as TableEntry[]);
        setCurrentTableEntries(newTableEntries);
    }, [currentSelection, states]);
    const updateStates = React.useCallback((state:string) => 
        setStates(s => s.includes(state) ? s.filter(x => x !== state) : [...s, state])
    , [setStates]);
    return <ContentWrapper 
            title="University Directory"
            subtitle={["Select a topic below to find out more from each university."]}>
        <PageSectionRow>
            <Col className="text-center">
                {["NSW", "ACT", "VIC", "QLD", "SA", "NT", "WA", "TAS"].map((s, i) =>
                    <Button className="ml-16 mt-8" 
                        key={`state-${i}`} 
                        variant={states.includes(s) ? "active" : "neutral"} 
                        onClick={()=>updateStates(s)}>
                        {s}
                    </Button>
                )}
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col className="text-center">
                {(Object.entries(uniCategoryPrettyNames) as [CategoryTypes, string][]).map(([cat, name]) =>
                    <Button className="ml-16 mt-8" variant={currentSelection === cat ? "active" : "neutral"} onClick={()=>setCurrentSelection(cat)}>
                        {name}
                    </Button>
                )}
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col className="text-center">
                {(states.includes("NSW") || states.includes("ACT")) && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.uac.edu.au/future-applicants/scholarships-and-schemes/schools-recommendation-schemes")}>
                    Schools Recommendation Scheme  {states.length > 1 ? "(NSW & ACT)":""}
                </Button>}
                {(states.includes("NSW") || states.includes("ACT")) && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.uac.edu.au/current-applicants/educational-access-schemes")}>
                    EAS {states.length > 1 ? "(NSW & ACT)":""}
                </Button>}
                {states.includes("VIC") && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.vtac.edu.au/seas.html")}>
                    SEAS {states.length > 1 ? "(VIC)":""}
                </Button>}
                {states.includes("VIC") && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.vtac.edu.au/who/seas/other.html")}>
                    Other Access Schemes {states.length > 1 ? "(VIC)":""}
                </Button>}
                {(states.includes("NSW") || states.includes("ACT")) && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("http://uac.edu.au/undergraduate/offers/defer.shtml")}>
                    Deferment {states.length > 1 ? "(NSW & ACT)":""}
                </Button>}
               {states.includes("VIC") && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.vtac.edu.au/institutions/deferment.html")}>
                    Deferment {states.length > 1 ? "(VIC)":""}
                </Button>} 
                {states.includes("VIC") && <Button className="ml-16 mt-8" variant={"neutral"} onClick={()=>openLinkInNewTab("https://www.toorongmarnong.com/")}>
                    Toorong Marnong (First Nations) {states.length > 1 ? "(VIC)":""}
                </Button>}
            </Col>
        </PageSectionRow>
        <CardTable
            data={_.orderBy(currentTableEntries, "name")}
            columns={[
                {
                    width:2,
                    id:"logo",
                    Cell:({row})=> 
                        <div style={{width:"100%", textAlign:"center"}}>
                            <img src={row.original.logo} 
                                style={{maxHeight:"45px", maxWidth:"100%"}} />

                        </div>
                },
                {
                    width:4,
                    id:"name",
                    accessor: "name"
                },
                {
                    width:6,
                    id:"buttons",
                    Cell:({row})=>(console.log(row), <div className="text-right">{row.original.links.map((l:LinkTo) => <Button variant="active" className="ml-8" onClick={()=>openLinkInNewTab(l.link)}>{l.text || "Click Here"}</Button>)}</div>)
                }
            ]}
        />
    </ContentWrapper>
}

export default UniDirectory;