import React from "react";
import { Row, Col } from "../../components/grid";
import { NewsItem } from "jobjump-types/News";
import NewsCard from "../../components/cards/NewsCard";
import ShowNItemsCardGrid from "../../components/ShowNItems";

interface NewsSectionProps {
    news: NewsItem[];
}

const NewsSection: React.FC<NewsSectionProps> =
    ({ news }: NewsSectionProps) =>
        <Row>
            <Col>
                {news.length === 0 ? <></>
                    :
                    <ShowNItemsCardGrid numPerRow={3} base={6} prefix="my-career-news">
                        {news.sort((a, b) => Number(a.start) - Number(b.start))
                            .map((n,i) => <NewsCard index={i} item={n} />)}
                    </ShowNItemsCardGrid>}
            </Col>
        </Row>

export default NewsSection;
