import Accountant from "./career-tiles/accountant.jpg";
import Acting from "./career-tiles/acting.jpg";
import Aeronautical from "./career-tiles/aeronautical-eng.jpg";
import AirTrafficController from "./career-tiles/air-traffic-control.jpg";
import AnimalAttendant from "./career-tiles/animal-attendant.jpg";
import Animator from "./career-tiles/animator.jpg";
import ArchitectureDraft from "./career-tiles/architecture-draft.jpg";
import Architecture from "./career-tiles/architecture.jpg";
import Archeologist from "./career-tiles/arrcheologist.jpg";
import Artist from "./career-tiles/artist.jpg";
import Astronomer from "./career-tiles/astronomer.jpg";
import Bar from "./career-tiles/bar.jpg";
import Beauty from "./career-tiles/beauty.jpg";
import Bricklayer from "./career-tiles/bricklayer.jpg";
import Builder from "./career-tiles/builder.jpg";
import CabinetMaker from "./career-tiles/cabinet-maker.jpg";
import CameraOperator from "./career-tiles/camera-operator.jpg";
import Carpenter from "./career-tiles/carpenter.jpg";
import Chef from "./career-tiles/chef.jpg";
import ChildCare from "./career-tiles/child-care.jpg";
import Chiro from "./career-tiles/chiro.jpg";
import CivilEngineer from "./career-tiles/civ-eng.jpg";
import Concierge from "./career-tiles/concierge.jpg";
import Councillor from "./career-tiles/councillor.jpg";
import Criminologist from "./career-tiles/criminologist.jpg";
import Dance from "./career-tiles/dance.jpg";
import DentalTech from "./career-tiles/dental-tech.jpg";
import Dentist from "./career-tiles/dental.jpg";
import DieselMech from "./career-tiles/diesel-mech.jpg";
import Dietitian from "./career-tiles/dietitian.jpg";
import DigitalMedia from "./career-tiles/digial-media.jpg";
import Electrician from "./career-tiles/electrician.jpg";
import ElectronicEng from "./career-tiles/electronic-eng.jpg";
import EnvironmentalSci from "./career-tiles/env.jpg";
import EventPlanner from "./career-tiles/event.jpg";
import Farming from "./career-tiles/farming.jpg";
import Fashion from "./career-tiles/fashion.jpg";
import FilmDirector from "./career-tiles/film-director.jpg";
import FireFighter from "./career-tiles/fire-fighter.jpg";
import Fitness from "./career-tiles/fitness.jpg";
import FlightAttendant from "./career-tiles/flight-attendant.jpg";
import Forensic from "./career-tiles/forensic.jpg";
import Freight from "./career-tiles/freight.jpg";
import GamesDev from "./career-tiles/games-dev.jpg";
import GeneralPrac from "./career-tiles/gp.jpg";
import GraphicDesign from "./career-tiles/graphic.jpg";
import Hairdresser from "./career-tiles/hair-dresser.jpg";
import Historian from "./career-tiles/historian.jpg";
import HotelManager from "./career-tiles/hotel-manager.jpg";
import IndustrialDesigner from "./career-tiles/industrial-design.jpg";
import InteriorDesign from "./career-tiles/interior-design.jpg";
import IT from "./career-tiles/it.jpg";
import Journalist from "./career-tiles/journalist.jpg";
import Landscape from "./career-tiles/landscape.jpg";
import LabTech from "./career-tiles/latb-tech.jpg";
import LawClerk from "./career-tiles/law-clerk.jpg";
import Lawyer from "./career-tiles/lawyer.jpg";
import Locksmith from "./career-tiles/locksmit.jpg";
import Marketing from "./career-tiles/marketing.jpg";
import Mechanic from "./career-tiles/mechanic.jpg";
import MechanicalEng from "./career-tiles/mechanical-eng.jpg";
import MechatronicEng from "./career-tiles/mechatronic-eng.jpg";
import MedicalSci from "./career-tiles/medical-sci.jpg";
import MetalFab from "./career-tiles/metal-fab.jpg";
import Midwife from "./career-tiles/midwife.jpg";
import Mining from "./career-tiles/mining.jpg";
import Museum from "./career-tiles/museum.jpg";
import Musician from "./career-tiles/musician.jpg";
import Navy from "./career-tiles/navy.jpg";
import NurseEnrolled from "./career-tiles/nurse-enrolled.jpg";
import Nurse from "./career-tiles/nurse.jpg";
import OccTherapy from "./career-tiles/occ-therapy.jpg";
import OfficeClerk from "./career-tiles/office-clerk.jpg";
import Optomotrist from "./career-tiles/optomotrist.jpg";
import PanelBeater from "./career-tiles/panel-beater.jpg";
import Paramedic from "./career-tiles/paramedic.jpg";
import ParkRanger from "./career-tiles/park-ranger.jpg";
import PetGroomer from "./career-tiles/pet-groomer.jpg";
import Pharmacist from "./career-tiles/pharmacist.jpg";
import Photographer from "./career-tiles/photographer.jpg";
import Physio from "./career-tiles/physio.jpg";
import Pilot from "./career-tiles/pilot.jpg";
import Plumber from "./career-tiles/plumber.jpg";
import Police from "./career-tiles/police.jpg";
import PrimaryTeacher from "./career-tiles/primary-teacher.jpg";
import Printer from "./career-tiles/printer.jpg";
import Pysch from "./career-tiles/psych.jpg";
import Radiographer from "./career-tiles/radiographer.jpg";
import RealEstate from "./career-tiles/real-estate.jpg";
import Refridgeration from "./career-tiles/refridgeration-mech.jpg";
import Retail from "./career-tiles/retail.jpg";
import SecondaryTeacher from "./career-tiles/secondary-teacher.jpg";
import Security from "./career-tiles/security.jpg";
import ShopFitter from "./career-tiles/shop-fitter.jpg";
import SignWriter from "./career-tiles/sign-writer.jpg";
import SocailWorker from "./career-tiles/social-worker.jpg";
import Soldier from "./career-tiles/soilder.jpg";
import SoundTech from "./career-tiles/sound-tech.jpg";
import SpeechPath from "./career-tiles/speech-path.jpg";
import Sport from "./career-tiles/sport.jpg";
import Surveyor from "./career-tiles/surveyor.jpg";
import Tiler from "./career-tiles/tiler.jpg";
import ToolMaking from "./career-tiles/tool-making.jpg";
import TownPlanner from "./career-tiles/town-planner.jpg";
import Translator from "./career-tiles/translator.jpg";
import TravelAgent from "./career-tiles/travel-agent.jpg";
import VetNurse from "./career-tiles/vet-nurse.jpg";
import Vet from "./career-tiles/vet.jpg";
import WebDesign from "./career-tiles/web-design.jpg";
import Writer from "./career-tiles/writer.jpg";
import Zoo from "./career-tiles/zoo.jpg"; 
import Agriculture from "./atar-category/agriculture.jpg";
import ArchitectureCat from "./atar-category/architecture.jpg";
import Arts from "./atar-category/arts.jpg";
import Business from "./atar-category/business.jpg";
import Creative from "./atar-category/creative.jpg";
import Edu from "./atar-category/education.jpg";
import Engineering from "./atar-category/engineering.jpg";
import Environmental from "./atar-category/environmental.jpg";
import Health from "./atar-category/health.jpg";
import ITCat from "./atar-category/it.jpg";
import Law from "./atar-category/law.jpg";
import Tourism from "./atar-category/tourism.jpg"; 
import Chemical from "./additional-category-images/chemical.jpg";
import Food from "./additional-category-images/food-sci.jpg";
import Mechanical from "./additional-category-images/mechanical.jpg";
import Paper from "./additional-category-images/paper.jpg";
import Textiles from "./additional-category-images/textiles.jpg"
import Transport from "./additional-category-images/transport.jpg"
import Wood from "./additional-category-images/wood.jpg"

const CareerImages : {[k:string]:string} = {
    "ACCOUNTANT" : Accountant,
    "ACTOR" : Acting,
    "AERONAUTICAL MECHANIC" : Aeronautical,
    "AIR TRAFFIC CONTROLLER" : AirTrafficController,
    "ANIMAL ATTENDANT" : AnimalAttendant,
    "ANIMATOR" : Animator,
    "ARCHAEOLOGIST" : Archeologist,
    "ARCHITECT" : Architecture,
    "ARCHITECT DRAFTER" : ArchitectureDraft,
    "ARTIST" : Artist,
    "ASTRONOMER" : Astronomer,
    "BARISTA & DRINKS" : Bar,
    "BEAUTY THERAPIST" : Beauty,
    "BRICKLAYER" : Bricklayer,
    "BUILDER" : Builder,
    "CABINETMAKER" : CabinetMaker,
    "CAMERA OPERATOR" : CameraOperator,
    "CARPENTER" : Carpenter,
    "CHEF" : Chef,
    "CHILDCARE WORKER" : ChildCare,
    "CHIROPRACTOR" : Chiro,
    "CIVIL ENGINEER" : CivilEngineer,
    "CONCIERGE" : Concierge,
    "COUNSELLOR" : Councillor,
    "CRIMINOLOGIST" : Criminologist,
    "DANCE" : Dance,
    "DENTAL TECHNICIAN" : DentalTech,
    "DENTIST" : Dentist,
    "DIESEL MECHANIC" : DieselMech,
    "DIETITIAN" : Dietitian,
    "DIGITAL MEDIA" : DigitalMedia,
    "ELECTRICIAN" : Electrician,
    "ELECTRICAL ENGINEER" : ElectronicEng,
    "ENVIRONMENT" : EnvironmentalSci,
    "EVENTS" : EventPlanner,
    "FARMING & AGRICULTURE" : Farming,
    "FASHION" : Fashion,
    "FILM DIRECTOR" : FilmDirector,
    "FIREFIGHTER" : FireFighter,
    "FITNESS" : Fitness,
    "FLIGHT ATTENDANT" : FlightAttendant,
    "FORENSICS" : Forensic,
    "FREIGHT & LOGISTICS" : Freight,
    "GAMES DESIGNER" : GamesDev,
    "MEDICINE" : GeneralPrac,
    "GRAPHIC DESIGNER" : GraphicDesign,
    "HAIRDRESSER" : Hairdresser,
    "HISTORIAN" : Historian,
    "HOTEL MANAGER" : HotelManager,
    "INDUSTRIAL DESIGNER" : IndustrialDesigner,
    "INTERIOR DESIGNER" : InteriorDesign,
    "COMPUTING & IT" : IT,
    "JOURNALIST" : Journalist,
    "LABORATORY ASSISTANT/TECHNICIAN" : LabTech,
    "LANDSCAPER" : Landscape,
    "LAW CLERK" : LawClerk,
    "LAWYER" : Lawyer,
    "LOCKSMITH" : Locksmith,
    "MARKETING" : Marketing,
    "MECHANIC" : Mechanic,
    "MECHANICAL ENGINEER" : MechanicalEng,
    "MECHATRONIC ENGINEER" : MechatronicEng,
    "MEDICAL SCIENTIST" : MedicalSci,
    "METAL FABRICATOR" : MetalFab,
    "MIDWIFE" : Midwife,
    "MINING" : Mining,
    "MUSEUM CURATOR" : Museum, 
    "MUSICIAN" : Musician,
    "NAVY" : Navy,
    "NURSING" : Nurse,
    "NURSING ENROLLED" : NurseEnrolled,
    "OCCUPATIONAL THERAPIST" : OccTherapy,
    "OFFICE CLERK" : OfficeClerk,
    "OPTOMETRIST" : Optomotrist,
    "PANEL BEATER" : PanelBeater,
    "PARAMEDIC" : Paramedic,
    "PARK RANGER" : ParkRanger,
    "PET GROOMER" : PetGroomer,
    "PHARMACIST" : Pharmacist,
    "PHOTOGRAPHER" : Photographer,
    "PHYSIOTHERAPIST" : Physio,
    "PILOT" : Pilot,
    "PLUMBER" : Plumber,
    "POLICE OFFICER (STATE)" : Police,
    "PRIMARY TEACHER" : PrimaryTeacher,
    "PRINTER" : Printer,
    "PSYCHOLOGY" : Pysch,
    "RADIOGRAPHER" : Radiographer,
    "REAL ESTATE" : RealEstate,
    "REFRIGERATION MECHANIC" : Refridgeration,
    "RETAIL" : Retail,
    "SECONDARY TEACHER" : SecondaryTeacher,
    "SECURITY OFFICER" : Security,
    "SHOP FITTER" : ShopFitter,
    "SIGNWRITER" : SignWriter,
    "SOCIAL WORKER" : SocailWorker,
    "ARMY" : Soldier,
    "SOUND TECHNICIAN" : SoundTech,
    "SPEECH PATHOLOGIST" : SpeechPath,
    "SPORT AND RECREATION" : Sport,
    "SURVEYOR" : Surveyor,
    "TILER" : Tiler,
    "TOOL MAKER" : ToolMaking,
    "TOWN PLANNER" : TownPlanner,
    "TRANSLATOR" : Translator,
    "TRAVEL" : TravelAgent,
    "VET" : Vet,
    "VETERINARY NURSE" : VetNurse,
    "WEB DESIGNER" : WebDesign,
    "WRITER" : Writer,
    "ZOOLOGIST" :  Zoo,
    "AGRICULTURE, ANIMAL AND PRIMARY INDUSTRIES": Agriculture,
    "ARCHITECTURE, BUILDING, CONSTRUCTION AND PLANNING": ArchitectureCat,
    "ART, DESIGN, MUSIC AND ENTERTAINMENT": Creative,
    "BUSINESS, ADMINISTRATION AND SALES": Business,
    "CHEMICAL AND MATERIALS": Chemical,
    "COMPUTING AND INFORMATION TECHNOLOGY": ITCat,
    "EDUCATION": Edu,
    "ENGINEERING AND TECHNICAL": Engineering,
    "FOOD PRODUCTION": Food,
    "FURNITURE AND WOOD":Wood,
    "HEALTH, MEDICINE, COMMUNITY AND BEAUTY": Health,
    "HOSPITALITY AND TOURISM": Tourism,
    "LAW, POLICING AND DEFENCE": Law,
    "LITERATURE, ARTS AND SOCIAL SCIENCE": Arts,
    "METAL, ELECTRICAL AND AUTOMOTIVE": Mechanical,
    "SCIENCE AND MATHEMATICS": Environmental,
    "PRINTING AND PAPER": Paper,
    "TEXTILES, CLOTHING AND FOOTWEAR": Textiles,
    "TRANSPORT AND STORAGE": Transport,
};

export default CareerImages;