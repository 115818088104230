import React, { PropsWithChildren, Ref } from "react";
import "./menu.css";
import JobJumpLogo from "../assets/logo.svg";
import JobJumpLogin from "./JobJumpLogin";
import { Link } from "react-router-dom";
import { UserDetails } from "jobjump-types/User";
import { Col, Row } from "../components/grid";
import "../components/cards/baseCard.css";
import { IconMenu } from "@tabler/icons";
import { SchoolHomepage } from "jobjump-types/SchoolHomePage";
import BaseCard from "../components/cards/BaseCard";

interface JobJumpMenuProps {
    loggedIn : boolean,
    setUserDetails : (d:UserDetails) => void,
    logout : () => void,
    userDetails? : UserDetails,
    logo?:string,
    schoolConfig?:SchoolHomepage,
    schoolId?:string,
    inBuilder?:boolean
}

interface SubMenu {
    name: string,
    url?: string,
    localPage?: string
    shouldDisplay?:(userDetails?:UserDetails)=>boolean
}

const Menu : [string, SubMenu[]][] = [
    ["Explore", [
        {name:"CareerVR", localPage:"careerVR"},
        {name:"Career Wiz Quiz", localPage:"cwiz"},
        {name:"My Career", localPage:"myCareer"},
        {name:"Videos", localPage:"careerVideos"},
        {name:"Bullseyes", localPage:"bullseyes"},
        {name:"News", localPage:"news"},
    ]],
    ["Subjects", [
        {name:"Subjects", localPage:"subjectSelection"},
    ]],
    ["ATAR", [
        {name:"Search", localPage:"atar"},
        {name:"Preferences", localPage:"atar/preferences"},
        {name:"Scholarships", localPage:"atar/scholarships"},
        {name:"Plan B", localPage:"atar/plan-b"},
    ]],
    ["Uni", [
        {name:"Open Days", localPage:"uni/openDays"},
        {name:"Stories", localPage:"uni/stories"},
        {name:"Institutes", localPage:"uni/profiles"},
        {name:"All You Need", localPage:"uni/directory"},
        {name:"UCAT", url:"https://www.ucat.edu.au/"},
        {name:"Interstate & Overseas", localPage:"uni/interstate"},
        {name:"Prelim Uni", localPage:"uni/preferences", shouldDisplay:(u)=> {
            return false
            /*return u ? 
                (u.year > 10 || ["teacher","advisor"].includes(u.registrationType)) 
                : false*/
        } },
    ]],
    ["TAFE", [
        {name:"Search", localPage:"tafe-courses"},
        {name:"First Nations", localPage:"tafe-first-nations"},
        {name:"USI", url:"https://www.usi.gov.au/Students/Pages/default.aspx"},
    ]],
    ["Jobs", [
        {name:"Find My Job Ads", localPage:"jobs"},
        {name:"Contacting an Employer", localPage:"contactEmployer"},
        {name:"LinkedIn", url:"http://linkedin.com/"},
        {name:"Apprenticeships & Traineeships", localPage:"apprenticeships"},
        {name:"Defence Jobs", url:"http://www.defencejobs.gov.au/"},
    ]],
    ["Experience", [
        {name: "eWork Experience", localPage: "eWorkExperience"},
        {name: "Real Work Experiences Directory", localPage: "workExperience"},
        {name: "Work Experience Papers", localPage:"workExperience/papers"},
        {name: "Soft Skills", localPage: "softSkills"},
        //{name: "Entrepreneurial", localPage: "eWorkExperience"},
        {name:"Work Safety", localPage:"workSafety"},
        {name:"Community Service", localPage:"communityService"},
        {name:"First Aid", localPage:"firstAid"},
        {name:"Gap Year", localPage:"uni/gapYear"},
 
    ]],
    ["Pay", [
        {name:"Find Earnings", localPage:"earnings"},
        {name:"Tax File Number", url:"https://www.ato.gov.au/Individuals/Tax-file-number/Apply-for-a-TFN/"},
        {name:"Money Help", url:"https://www.moneysmart.gov.au/life-events-and-you/under-25s"},
    ]],
    ["Resume", [
        {name:"Resume", localPage:"resume"},
        {name:"Plan", localPage:"plan"},
        {name:"Pre-Work Check", localPage:"workCheck"},
    ]],
];

const MenuItem  = React.forwardRef<HTMLDivElement, PropsWithChildren<{}>>((props, ref) =>
    <div ref={ref} className={`jj-menu-item`}>
        {props.children}
    </div>)

const TopMenuToggle : React.FC<{text:string, lg?:boolean, inBuilder?:boolean, content:(closeMenu:()=>void)=>JSX.Element}> = 
({content, text, lg, inBuilder}) => {
    const [showDropdown, setShowDropdown] = React.useState(false);
    const ref : Ref<HTMLDivElement> = React.useRef(null);
    React.useEffect(() => {
        window.addEventListener("click", (e) => {
            if (ref.current !== null && ref.current.contains(e.target as Node)) {
                return;
            }
            setShowDropdown(false);    
        });
    }, [setShowDropdown]);
    return <>
        <MenuItem ref={ref}>
            <a className="menu-text" onClick={(e)=>{!inBuilder && setShowDropdown(s => !s);}}>{text}</a>
            <div onClick={(e)=>e.preventDefault()} className={`
                jobjump-menu-popout 
                ${showDropdown ? "show-dropdown" : "hide-dropdown"}
                ${lg ? "jobjump-menu-popout-lg" : ""}
                `}>
                {content(()=>setShowDropdown(false))}
            </div>
        </MenuItem>
    </>;
}

const JobJumpMenu : React.FC<JobJumpMenuProps> = 
    ({loggedIn, setUserDetails, logout, userDetails, logo, schoolConfig, inBuilder} : JobJumpMenuProps) => {
        const [showMainMenu, setShowMainMenu] = React.useState(false);
        const [showMobileMenu, setShowMobileMenu] = React.useState(false);
        const showMySchool = schoolConfig && (schoolConfig.hasBulletin || schoolConfig.hasOwnBulletins || schoolConfig.hasFiles);
        const showMyFiles = schoolConfig && schoolConfig.hasFiles;
        const showBulletins = schoolConfig && (schoolConfig.hasBulletin || schoolConfig.hasOwnBulletins);
        const hidLinkIfInBuilder = (link:string) => inBuilder ? "" : link;
        const ref = React.useRef<HTMLDivElement>(null);
        React.useEffect(() => {
            window.addEventListener("click", (e) => {
                if (ref.current !== null && ref.current.contains(e.target as Node)) {
                    return;
                }
                setShowMainMenu(false);    
            });
        }, [setShowMainMenu]);
        return <>
            <nav className="jobjump-mobile-nav mt-16">
                <Row>
                    <Col sm={8} className="text-left">
                        <Link to={hidLinkIfInBuilder("/")}><img className="jobjump-logo" alt="JobJump Logo" src={!logo || logo === "" ? JobJumpLogo : logo} /></Link>
                    </Col>
                    <Col sm={4} className="text-right">
                        <MenuItem><a className="menu-text" onClick={()=>!inBuilder && setShowMobileMenu(m=>!m)}>Menu <IconMenu /></a></MenuItem>
                    </Col>
                </Row>
                {showMobileMenu && !inBuilder && 
                    <BaseCard className="jobjump-full-nav-dropdown text-left">
                        <Row className="text-left">
                                <Col className="mb-16" sm={12}><Link to="/whereis">Quick Links</Link></Col>
                            </Row>
                        <Row className="text-left">
                                <Col className="mb-16" sm={12}><Link to="/helpVideo">Help Videos</Link></Col>
                            </Row>
                        <Row className="text-left">
                                {!loggedIn && <Col className="mb-16" sm={12}><Link to="/register">I'm New</Link></Col>}
                            </Row>
                        <Row className="text-left">
                                {!loggedIn && <Col className="mb-16" sm={12}><Link to="/login">Login</Link></Col>}
                            </Row>
                        <Row className="text-left">
                                {loggedIn && <Col className="mb-16" sm={12}><Link to="/details">Update Details</Link></Col>}
                            </Row>
                        <Row className="text-left">
                                {loggedIn && <Col className="mb-16" sm={12}><a onClick={()=>{logout()}}>Log Out</a></Col>}
                            </Row>
                        <Row className="text-left">
                                {loggedIn && userDetails?.registrationType === "advisor" &&
                                    <Col className="mb-16"><Link to="/admin">Careers Advisers Dashboard</Link></Col>}
                        </Row>
                        <Row>
                            {showMySchool && <Col sm={12}>
                                <h4>My School</h4>
                                <span className="jobjump-menu-subitem">
                                    {showBulletins && <span className="block">
                                        <p><Link to={`/school/bulletins`}>My Bulletins</Link></p>
                                    </span>}
                                    {showMyFiles && <span className="block">
                                        <p><Link to={`/school/files`}>My Files</Link></p>
                                    </span>}
                                </span>
                            </Col>}
                            {Menu.map(([menuName, items]) => <Col sm={12}>
                                <h4>{menuName}</h4>
                                {items.map(({name, url, localPage, shouldDisplay})=>
                                    (!shouldDisplay || shouldDisplay(userDetails)) &&
                                        <span className="jobjump-menu-subitem">
                                            {url && <p><a onClick={()=>setShowMainMenu(false)} href={url} target="_blank">{name}</a></p>}
                                            {localPage && <p><Link onClick={()=>setShowMainMenu(false)} to={`/${localPage}`}>{name}</Link></p>}
                                        </span>
                                )}
                            </Col>)}
                        </Row>
                    </BaseCard>}                
            </nav>
            <nav className="jobjump-full-nav mt-16">
                <div className="nav-row">
                    <div className="jobjump-menu-lhs text-center">
                        <MenuItem><Link to={hidLinkIfInBuilder("/whereis")} className="menu-text">Quick Links</Link></MenuItem>
                        <MenuItem><Link to={hidLinkIfInBuilder("/helpVideo")} className="menu-text">Help Videos</Link></MenuItem>
                        {showMySchool && <TopMenuToggle text="My School" inBuilder={inBuilder} content={(closeWindow) => 
                            <div className="jobjump-base-card text-left jobjump-submenu">
                                {showBulletins && <span className="block jobjump-menu-subitem">
                                    <p><Link onClick={closeWindow} to={`/school/bulletins`}>My Bulletins</Link></p>
                                </span>}
                                {showMyFiles && <span className="block jobjump-menu-subitem">
                                    <p><Link onClick={closeWindow} to={`/school/files`}>My Files</Link></p>
                                </span>}
                            </div>}
                        />}
                        {!schoolConfig && <MenuItem><Link className="menu-text" to="/features">Features</Link></MenuItem>}
                    </div>
                    <div className="jobjump-menu-logo text-center">
                        <Link to={hidLinkIfInBuilder("/")}><img className="jobjump-logo" alt="JobJump Logo" src={!logo || logo === "" ? JobJumpLogo : logo} /></Link>
                    </div>
                    <div className="jobjump-menu-rhs text-center">
                        {!loggedIn && <MenuItem><Link to={hidLinkIfInBuilder("/register")} className="menu-text">I'm New</Link></MenuItem>}
                        {!loggedIn && <TopMenuToggle lg text="Login" inBuilder={inBuilder} content={(closeWindow) =>
                            <div className="jobjump-base-card jobjump-submenu text-left">
                                <JobJumpLogin setUserDetails={(d)=>{closeWindow(); setUserDetails(d)}} />
                            </div>} />}
                       {userDetails && userDetails.registrationType === "advisor" && <MenuItem><Link className="menu-text" to="/admin">Dashboard</Link></MenuItem>} 
                        {loggedIn && userDetails &&
                            <TopMenuToggle text={`Hi, ${userDetails.first_name}`} inBuilder={inBuilder} content={(closeWindow=>
                                <div className="jobjump-base-card text-left jobjump-submenu">
                                    <span className="block jobjump-menu-subitem"><p><Link onClick={closeWindow} to="/details">Update Details</Link></p></span>
                                    <span className="block jobjump-menu-subitem"><p><a onClick={()=>{closeWindow(); logout()}}>Log Out</a></p></span>
                                </div>)} />}
                        <MenuItem ref={ref}><a className="menu-text" onClick={()=>!inBuilder && setShowMainMenu(m=>!m)}>Menu <IconMenu /></a></MenuItem>
                    </div>
                </div>
                <div className={`jobjump-full-nav-dropdown jobjump-base-card ${showMainMenu ? "show" : "hide"}-dropdown`}>
                    <Row>
                        {Menu.map(([menuName, items]) => <Col>
                            <h4>{menuName}</h4>
                            {items.map(({name, url, localPage, shouldDisplay})=>
                                (!shouldDisplay || shouldDisplay(userDetails)) &&
                                    <span className="jobjump-menu-subitem">
                                        {url && <p><a onClick={()=>setShowMainMenu(false)} href={url} target="_blank">{name}</a></p>}
                                        {localPage && <p><Link onClick={()=>setShowMainMenu(false)} to={`/${localPage}`}>{name}</Link></p>}
                                    </span>
                            )}
                        </Col>)}
                    </Row>
                </div>
            </nav>
        </>;
    }

export default JobJumpMenu;