import { IconBulb } from "@tabler/icons";
import React from "react";
import { Label, TextArea } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { SubsectionProps, SubsectionTemplate } from "./common";

const CareerGoalSection : React.FC<SubsectionProps<"career_goal">> = ({section, setSection, sectionConfig, setSectionConfig}) => 
    <SubsectionTemplate title="Career Goal" sectionSetting={sectionConfig} updateSectionSetting={setSectionConfig} >
        <Row className="mt-8">
            <Col>
                <Label>My ambition is to...</Label>
            </Col>
        </Row>
        <Row>
            <Col>
                <TextArea value={section} onChange={setSection} />
            </Col>
        </Row>
        <Row>
            <Col>
                <small><IconBulb /> Write here your goal such as "I plan be one of Sydney's most reputable and highly skilled Chefs".</small>
            </Col>
        </Row>
    </SubsectionTemplate>

export default CareerGoalSection;