import React from "react";
import { NewsItem } from "jobjump-types/News";
import News from "../data/News";
import { CareerNames } from "jobjump-types/Careers"
import Careers from "../data/Careers";
import moment from 'moment';
import PageSectionRow from "../components/PageSectionRow";
import { Col } from "../components/grid";
import { Label, MultiSelect, TextField } from "../components/Form";
import CardGrid from "../components/CardGrid";
import NewsCard from "../components/cards/NewsCard";
import ContentWrapper from "../components/ContentWrapper";

const MyNews : React.FC<{}> =
    () => {
        const [startDate, setStartDate] = React.useState(moment());
        const [endDate, setEndDate] = React.useState(moment().add(12, "months"));
        const [possibleCareers, setPossibleCareers] = React.useState<CareerNames[]>([]);
        const [careers, setCareers] = React.useState<{label:string, value:string, career:CareerNames}[]>([]);
        const [news, setNews] = React.useState<NewsItem[]>([]);
        React.useEffect(() => {
            const startUnix = startDate.unix();
            const endUnix = endDate.unix();
            if (isNaN(startUnix) || startUnix < 0 || isNaN(endUnix) || endUnix < 0) {
                return;
            }
            News.getNewsForTimePeriod(startDate.unix(), endDate.unix(), careers.map(({career:{id}})=>id)).then(n => setNews(n.sort((a:NewsItem,b:NewsItem)=>Number(a.end) > Number(b.end) ? 1 : -1)));
        }, [startDate, endDate, careers, possibleCareers])
        React.useEffect(() => {
            Careers.getCareerList().then(setPossibleCareers);
        }, []);
        return <ContentWrapper
                title="Career News"
                subtitle={["What's going on for your favourite careers? Use the search below to find news items."]} >
                <PageSectionRow>
                    <Col>
                        <Label>Careers:</Label>
                        <MultiSelect
                            name="career"
                            value={careers}
                            data={possibleCareers.map(c=>({career:c, label:c.name, value:String(c.id)}))}
                            onChange={v=>setCareers([...v])}
                        />
                    </Col>
                </PageSectionRow>
                <PageSectionRow>
                    <Col md={6}>
                        <Label>Start Date:</Label>
                        <TextField dateField value={startDate.format("YYYY-MM-DD")} 
                            onChange={(value)=> setStartDate(moment(value))} />
                    </Col>
                    <Col md={6}>
                        <Label>End Date:</Label>
                        <TextField dateField value={endDate.format("YYYY-MM-DD")} 
                            onChange={(value)=> setEndDate(moment(value))} />
                    </Col>
                </PageSectionRow>
                <PageSectionRow>
                    <Col>
                        <CardGrid numPerRow={3} prefix="news">
                            {news.map((n,i) =>
                                <NewsCard item={n} index={i} />
                            )}
                        </CardGrid>
                    </Col>
                </PageSectionRow>
        </ContentWrapper>;
    }

export default MyNews;