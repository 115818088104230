import { HeroConfiguration, HomepageLinkTo, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import CardGrid from "../../../components/CardGrid";
import BaseCard from "../../../components/cards/BaseCard";
import { Label, Radio, Select, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import {  CirclePicker, ChromePicker, ColorChangeHandler } from "react-color";
import Button from "../../../components/Button";
import ImageCard from "../../../components/cards/ImageCard";
import Dropzone from "react-dropzone";
import VideoLoops, { SmallVideoLoops, VideoKeys } from "../../../assets/VideoLoops";
import "./builder.css";
import CaSchoolHomepage from "../../../data/CaSchoolHomepage";
import axios from "axios";
import _ from "lodash";
import { uploadFileWithSignedPOST } from "../../../data/FileUploadUtil";

type SchoolDetailsProps = {
    schoolConfig : SchoolHomepage,
    updateConfig :  React.Dispatch<React.SetStateAction<SchoolHomepage>>,
    save: (newConfig:SchoolHomepage)=>Promise<Response>,
    heroSettings : HeroConfiguration,
    setHeroSettings : React.Dispatch<React.SetStateAction<HeroConfiguration>> 
}

type PossiblePagesT = {
    [k in HomepageLinkTo]: string;
};

const PossiblePages : PossiblePagesT  = {
    "atar": "Find My Uni Course",
    "tafe": "Find My TAFE Course",
    "helpfulLinks": "It's All Here",
    "careerExplore": "Explore My Career",
    "quiz": "Find My Career Quiz",
    "bullseyes": "Bullseyes",
    "resume" : "Build My Resume",
    "subjects" : "Find My Senior Subjects",
    "eWorkExperience" : "Do eWork Experience",
    "jobAds" : "Find A Job",
    "universityInstitutes" : "University Insitutes",
    "universityAllYouNeed" : "University Guide",
    "openDays" : "University Open Days",
    "uniStories" : "University Stories",
    "earnings" : "See Career Earnings",
    "careersVideos" : "Watch Career Videos",
    "careerVR" : "Careers VR",
    "careersNews" : "My Careers News",
    "contactingAnEmployer" : "How To Contact an Employer",
    "apprenticeships" : "Apprenticeships & Traineeships",
    "softSkills" : "Test My Soft Skills",
    "communityService" : "Community Service",
    "firstAid" : "First Aid",
    "gapYear" : "Gap Year",
};


const VideoLoopNames : {[k in VideoKeys]:string} = {
    "JobJumpMain":"JobJump Default Loop",
    "Environment":"Environmental",
    "Girls":"Girls",
    "Indigenous":"Indigenous",
    "Male":"Males in Non-Traditional Roles",
    "Multicultural":"Multicultural",
    "STEM":"Science and Engineering"
};

const PageContents : React.FC<SchoolDetailsProps> = ({schoolConfig, updateConfig, save, heroSettings, setHeroSettings}) => {
    const colorList = [
        "#C31F1F",
        "#C44949",
        "#EA6FC3",
        "#AD49C4",
        "#7449C4",
        "#495CC4",
        "#49C4C4",
        "#49C492",
        "#4FC449",
        "#09A701",
        "#EEF335",
        "#E0830D",
    ]
    const [showAdvancedColor, setShowAdvancedColor] = React.useState(colorList.includes(schoolConfig.schoolColour || ""));
    const [backgroundImageUrl, setBackgroundImageUrl] = React.useState<string>();
    const [backgroundUploadFile, setBackgroundUploadFile] = React.useState<File>();

    React.useEffect(() => {
        if (!backgroundImageUrl && heroSettings.imageUrl) {
            setBackgroundImageUrl(heroSettings.imageUrl);
        }
    }, [backgroundImageUrl, heroSettings, setBackgroundImageUrl]);

    React.useEffect(() => {
        if (!backgroundUploadFile) {
            return;
        }
        CaSchoolHomepage.getDocumentUploadLink({mimeType:backgroundUploadFile.type, fileName:backgroundUploadFile.name, fileSize:backgroundUploadFile.size}).catch(e => console.log(e)).then(details => {
            if (!details) { return; }
            const {uploadDetails, key} = details;
            uploadFileWithSignedPOST(uploadDetails, backgroundUploadFile).then(() => {
                CaSchoolHomepage.confirmSchoolImageUpload({ key }).then(heroConfig => {
                    setHeroSettings(heroConfig);
                    setBackgroundImageUrl(heroConfig.imageUrl);
                });
            }).catch(console.log);
        });
    }, [setBackgroundImageUrl, setHeroSettings, backgroundUploadFile])


    const handleColorUpdate : ColorChangeHandler = React.useCallback(({hex}) => {
        updateConfig({...schoolConfig, schoolColour:hex});
        save({...schoolConfig, schoolColour:hex});
    }, [schoolConfig, updateConfig, save]);
    const deleteBackground = React.useCallback(() => {
        setBackgroundImageUrl(undefined);
        setBackgroundUploadFile(undefined);
    }, []);
    const updateHeroVideo = React.useCallback((loopName:string) => {
        CaSchoolHomepage.setSchoolHero({kind:"video", loopName}).then(h=>{setHeroSettings(h); setBackgroundImageUrl(undefined);});
    }, [setHeroSettings]);
    const updateHeroImage = React.useCallback(() => {
        CaSchoolHomepage.setSchoolHero({kind:"customImage", imageUrl:backgroundImageUrl}).then(hero=>{
            if (!hero.imageUrl) {
                CaSchoolHomepage.setSchoolHero({kind:"video", loopName:"JobJumpMain"}).then(setHeroSettings);
                return;
            }
            setHeroSettings(hero);
            setBackgroundImageUrl(hero.imageUrl);
        });
    }, [setHeroSettings, backgroundImageUrl]);
 
    return <Row><Col>
        <h2>School Colour</h2>
        <p>This is the primary colour used throughout the site. You can choose from one of the preset colours, or use the Advanced Picker to pick any colour. You can use the <strong>Preview</strong> button above to see what the colour looks like.</p>
        <Row className="mb-16">
            <Col className="text-center">
                {!showAdvancedColor && <div style={{display:"inline-block"}}><CirclePicker color={schoolConfig.schoolColour} colors={colorList} onChange={handleColorUpdate} /></div>}
                {showAdvancedColor && <div style={{display:"inline-block"}}><ChromePicker color={schoolConfig.schoolColour} disableAlpha onChange={handleColorUpdate}/></div>}
            </Col>
        </Row>
        <Row className="mt-8">
            <Col className="text-center">
                <Button className="ml-16" variant="active" onClick={()=>setShowAdvancedColor(a=>!a)}>
                    {showAdvancedColor ? "Hide" : "Show"} Advanced Picker
                </Button>
            </Col>
        </Row>
        <Row><Col>
            <h2>Background Video/Image</h2>
            <p>This sets the video loop image used as a background at the top of your careers website. If you chooes to upload an image, we recommend using a high resolution image.</p>
            <CardGrid numPerRow={3} prefix="loop">
                {Object.entries(SmallVideoLoops).map(([key, url]) =>
                    <ImageCard videoUrl={url}>
                        <h4><Radio 
                            onChange={()=>updateHeroVideo(key)}
                            checked={heroSettings.loopName === key}
                            name="background" 
                            label={VideoLoopNames[key as VideoKeys]} /></h4>
                    </ImageCard>
                )}
                <BaseCard style={{height:"100%"}} className="text-center">
                    <span className="background-upload"><Dropzone onDrop={(fs)=>setBackgroundUploadFile(fs[0])}>
                        {({getRootProps, getInputProps}) => (
                            <>{!backgroundImageUrl && !heroSettings.imageUrl && <div {...getRootProps()} className="upload-zone">
                                <input {...getInputProps()} />
                                <p>Drag your custom background image here, or click here to select a file.</p>
                            </div>}
                            {(backgroundImageUrl || heroSettings.imageUrl) && <div className="upload-zone">
                                <img width="100%" src={backgroundImageUrl || heroSettings.imageUrl} alt="" />
                                <Button variant="neutral" onClick={deleteBackground}>Clear</Button>
                            </div>}
                            </>
                        )}
                    </Dropzone></span>
                    <h4 className="mt-16"><Radio 
                        onChange={updateHeroImage} 
                        checked={heroSettings.kind === "customImage"}
                        name="background" 
                        label="My Custom Image" /></h4>
                </BaseCard>
            </CardGrid>
        </Col></Row>
        <h2>Careers Adviser Contact Information</h2>
        <p>This information is displayed at the bottom of your school's careers website.</p>
        <Row><Col>
            <Label>Careers Adviser's Name</Label>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.caName} onChange={(caName)=>updateConfig(c=>({...c, caName}))} />
        </Col></Row>
        <Row><Col>
            <Label>Careers Adviser's Title</Label>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.caTitle || "Careers Adviser"} onChange={(caTitle)=>updateConfig(c=>({...c, caTitle}))} />
            <small>For example; Careers Adviser or Transitions and Pathways</small>
        </Col></Row>
        <Row><Col>
            <Label>Careers Adviser's Address</Label>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.caAddress} onChange={(caAddress)=>updateConfig(c=>({...c, caAddress}))} />
        </Col></Row>
        <Row><Col>
            <Label>Careers Adviser's Phone Number</Label>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.caPhone || ""} onChange={(caPhone)=>updateConfig(c=>({...c, caPhone:caPhone === "" ? undefined : caPhone}))} />
        </Col></Row>
        <Row><Col>
            <Label>Careers Adviser's Email Address</Label>
            <TextField onBlur={()=>save(schoolConfig)} value={schoolConfig.caEmail || ""} onChange={(caEmail)=>updateConfig(c=>({...c, caEmail:caEmail === "" ? undefined : caEmail}))} />
        </Col></Row>
        <Row><Col>
            <h2>Homepage Links</h2>
            <p>The JobJump careers hompeage has 6 links to showcase the various special features you wish JobJump to offer your students and parents. Use the dropdown below to select your favourite features. Once done, you can click the <strong>Preview</strong> button above to see the changes.</p>
            <CardGrid numPerRow={3} prefix="homepage-links-edit">
                {schoolConfig.links.map((l, i) =>
                    <BaseCard key={`hometile${i}`}>
                        <Select
                            defaultValue={({label:l.title, value:l.toPage})}
                            onBlur={()=>save(schoolConfig)} 
                            onChange={v => {
                                if (!v) { return }
                                updateConfig(c=> {
                                    const link = {toPage:v.value as HomepageLinkTo, title:v.label};
                                    const links = [...c.links];
                                    links[i] = link;
                                    return {...c, links}
                                });
                            }}
                            options={_.sortBy(Object.entries(PossiblePages), ([k,n])=>n)
                                .map(([link, title])=>({label:title, value:link}))} />
                    </BaseCard>
                )}
            </CardGrid>
        </Col></Row>
        <Row><Col>
            <h2>{schoolConfig.active ? "Deactivate" : "Activate"} School Site</h2>
            <p>This setting allows you to disable or re-enable your school site.</p>
            <Radio name="deactivate" 
                onBlur={()=>save(schoolConfig)}
                className="block"
                checked={schoolConfig.active} 
                label="I want my school's careers site to be active"
                onChange={()=>updateConfig(s=>({...s, active:true}))} />
            <Radio name="deactivate" 
                className="block"
                onBlur={()=>save(schoolConfig)}
                checked={!schoolConfig.active} 
                label="I want my school's careers site to be deactivated"
                onChange={()=>updateConfig(s=>({...s, active:false}))} />
        </Col></Row>
        {/*<Row><Col>
            <h2>Facebook Page</h2>
            <p>You can display the most recent posts to your careers Facebook page, if you have one. To do so, ...</p>
        </Col></Row>*/}
    </Col></Row>
}

export default PageContents