import { CareerCategory, CareerNames } from "jobjump-types/Careers";
import React from "react";
import { RouteComponentProps } from "react-router";
import CareerImages from "../../assets/CareerImages";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import FilterableGrid from "../../components/FilterableGrid";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import Careers from "../../data/Careers";
import Resume, {ResumeKinds} from "../../data/Resume";
import ExampleResume from "../../assets/my_job_ads/example-resume.jpg";
import MakeResumeJJ from "../../assets/my_job_ads/make-resume-jj.jpg";
import WritingAResume from "../../assets/my_job_ads/writing-a-resume.jpg";

type SelectionState = "select" | "career" | "category";

type Props = RouteComponentProps & {title:string}

const NewResumeForm : React.FC<Props> = ({title, history}) => {
    const [selectionState, setSelectionState] = React.useState<SelectionState>("select");
    const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
    const [categoryList, setCategoryList] = React.useState<CareerCategory[]>([]);

    React.useEffect(() => {
        Careers.getCareerList().then(setCareerList);
    }, []);

    React.useEffect(() => {
        Careers.getCareerCategories().then(setCategoryList);
    }, []);

    const createResume = React.useCallback((type:ResumeKinds, id?:number) => {
        Resume.createResume(type, id).then(resumeId => history.push("/resume/"+resumeId));
    }, []);

    if (selectionState === "select") {
        return <>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>{title}</h3>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col className="mt-32">
                    <CardGrid numPerRow={3} prefix={"resume-select"}>
                        <ImageCard imageUrl={ExampleResume} text="I want a resume for a particular career" onClick={()=>setSelectionState("career")} /> 
                        <ImageCard imageUrl={MakeResumeJJ} text="I want a resume for a career area" onClick={()=>setSelectionState("category")} /> 
                        <ImageCard imageUrl={WritingAResume} text="I want a general resume" onClick={()=>createResume("general")} /> 
                    </CardGrid>
                </Col>
            </PageSectionRow>
        </>
    }

    if (selectionState === "career") {
        return <>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Select your career below to begin</h3>
                </Col>
            </PageSectionRow>
            <Row className="mt-8 mb-8">
                <Col className="text-right">
                    <Button textButton variant="negative" onClick={()=>setSelectionState("select")}>Go Back</Button>
                </Col>
            </Row>
            <PageSectionRow>
                <Col>
                    <FilterableGrid 
                        hideHeading
                        numPerRow={3}
                        data={careerList.map(c =>({
                            imgUrl: CareerImages[c.name.toUpperCase()],
                            text: c.name,
                            onClick:()=> createResume("career", c.id)
                        }))} 
                    />
                </Col>
            </PageSectionRow>
        </>
    }

    if (selectionState === "category") {
        return <>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Select your category below to begin</h3>
                </Col>
            </PageSectionRow>
            <Row className="mt-8 mb-8">
                <Col className="text-right">
                    <Button textButton variant="negative" onClick={()=>setSelectionState("select")}>Go Back</Button>
                </Col>
            </Row>
            <PageSectionRow>
                <Col>
                    <FilterableGrid 
                        hideHeading
                        numPerRow={3}
                        data={categoryList.map(c =>({
                            imgUrl: CareerImages[c.name.toUpperCase()],
                            text: c.name,
                            onClick:()=> createResume("category", Number(c.id))
                        }))} 
                    />
                </Col>
            </PageSectionRow>
        </>
    }

    return <></>;
}

export default NewResumeForm;