import React from "react";
import PageHero, {PageHeroProps} from "../PageHero";

export type ContentWrapperProps = Omit<PageHeroProps, "breadcrumbs"> & {
    breadcrumbs?:PageHeroProps["breadcrumbs"], 
    breadcrumbTitle?:string,
    heroChildren?:React.ReactNode
    noBottomMargin?:boolean
}

const ContentWrapper : React.FC<ContentWrapperProps> = ({children, noBottomMargin, heroChildren, breadcrumbs, breadcrumbTitle, title, ...props}) => {
    React.useEffect(() => {
        document.title = `JobJump - ${breadcrumbTitle || title}`;
    }, [title, breadcrumbTitle])
    return <div className={"mt-64"+(noBottomMargin === true ? "" : " mb-128")}>
        <PageHero
            title={title}
            breadcrumbs={[
                ...(breadcrumbs || []),
                {name:breadcrumbTitle || title, link:"#"}
            ]} {...props}>{heroChildren}</PageHero>
        {children}
    </div>
}

export default ContentWrapper;
