import React from "react";

type CheckProps = {
    onChange?: (checked:boolean)=>void,
    label:string,
    block?:boolean
} & Omit<React.ComponentProps<"input">, "onChange">;

const Check : React.FC<CheckProps> = ({onChange, block, className, label, ...props}) => 
    <span className={"jobjump-radio-button "+(block?"jobjump-radio-block ":"")+(className || "")}><input type="checkbox"
        onChange={({currentTarget:{checked}})=>onChange && onChange(checked)} {...props} /> {label}</span>

export default Check;