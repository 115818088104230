import React from "react";
import CaStudentDetails from "../../data/CaStudentDetails";
import { SentMessages } from "jobjump-types/CaStudents";
import CaAdminLayout from "./CaAdminLayout";
import { RouteComponentProps } from "react-router";
import PageSectionRow from "../../components/PageSectionRow";
import { Row, Col } from "../../components/grid";
import ExpandableRow from "../../components/cards/ExpandableRowCard";
import { Label, TextField } from "../../components/Form";

const CaSendingRecord : React.FC<RouteComponentProps> =
(props) => {
    const [rawData, setRawData] = React.useState<SentMessages[]>([]);
    const [filteredData, setFilterData] = React.useState<SentMessages[]>([]);
    const [filterTerm, setFilterTerm] = React.useState("");
    React.useEffect(() => {
        CaStudentDetails.getSentMessages().then(setRawData);
    }, []);
    React.useEffect(() => {
        setFilterData(rawData.filter(d => 
            d.subject.match(`.*${filterTerm}.*`) ||
            d.body.match(`.*${filterTerm}.*`)
        ))
    }, [rawData, filterTerm]);
    return <CaAdminLayout
        active="message"
        title="Message History"
        subtitle={["See what messages you've sent to students using JobJump."]} {...props} >
        <PageSectionRow>
            <Col className="text-center">
                <h3>Message Sending History</h3>
                <span>Below is a history of messages you have sent to students using JobJump. You can search using the search box, and see more details of a message by pressing the "Show" button.</span>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <Row>
                    <Col>
                        <Label>Search:</Label>
                        <TextField value={filterTerm} onChange={setFilterTerm}  />
                    </Col>
                </Row>
            </Col>
        </PageSectionRow>
        <Row className="mt-8">
            <Col>
                {filteredData.length === 0 && <h5>No messages.</h5>}
                {filteredData.map((m,i) =>
                    <ExpandableRow 
                        cols={[
                            {size:3, content:<>{(new Date(m.timestamp * 1000)).toDateString()}</>},
                            {size:5, content:<>{m.subject}</>}
                        ]}>
                        <span dangerouslySetInnerHTML={{__html:m.body}}></span>
                    </ExpandableRow>
                )}
            </Col>
        </Row>
    </CaAdminLayout>
}

export default CaSendingRecord;