import { AtarCategory } from "jobjump-types/ATAR";
import _ from "lodash";
import React from "react";
import AtarImages from "../../assets/AtarImages";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import AtarAPI from "../../data/ATAR";
import { StagePropsBase } from "./common";

const CategorySelect : React.FC<StagePropsBase> = ({response, setResponse, onContinue}) => {
    const [categories, setCategories] = React.useState<AtarCategory[]>([]);
    const [valid, setValid] = React.useState(true);
    React.useEffect(() => {
        AtarAPI.getAtarCategories().then(setCategories);
    }, [response.selectedInsitutes]);
    const onClick = () => {
        if (response.categories.length > 0) {
            onContinue();
        } else {
            setValid(false);
        }
    }
    const updatePrefs = (id:string) => {
        setResponse(r=>({
            ...r, 
            categories: r.categories.includes(id) ? 
                r.categories.filter(i=>i!==id) : 
                r.categories.concat(r.categories.length < 3 ? [id] : [])
        }))
    };
    return <>
        <PageSectionRow>
            <Col className="text-center">
                <h3>To begin, select up to 3 fields you are interested in studying.</h3>
                {!valid && <Alert textOnly variant="negative" text="Please select at least one category to continue" />}
            </Col>
        </PageSectionRow>
        <Row>
            <Col className="mt-16 text-right">
                <Button variant="active" onClick={onClick}>Continue</Button>
            </Col>
        </Row>
        <PageSectionRow>
            <Col>
                <CardGrid prefix="category-select" numPerRow={3}>
                    {_.sortBy(categories, c=>c.name).map(c => 
                        <ImageCard 
                            key={c.name}
                            className={response.categories.includes(c.id) ? "filter-grid-select-selected" : "filter-grid-select-unselected"}
                            imageUrl={AtarImages[c.name.toUpperCase()] || ""}
                            onClick={()=>updatePrefs(c.id)}
                            text={c.name} />
                    )}
                </CardGrid>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col className="text-center">
                <Button variant="active" onClick={onClick}>Continue</Button>
            </Col>
        </PageSectionRow>
    </>
}

export default CategorySelect;