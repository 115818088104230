import React from "react";
import { CareerInfo } from "jobjump-types/Careers";
import Career from "../../data/Careers";
import {Row, Col} from "../../components/grid";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'
import "./resume.css";
import { ResumeFormProps } from "./ResumeCommonTypes";
import CareerGoalsPlanSection from "./CareerGoalPlan";
import EducationHistory from "./EducationHistory";
import EducationPlans from "./EducationPlans";
import PersonalFolder from "./PersonalFolder";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";

const ResumeForm: React.FC<ResumeFormProps> =
    (props) => {
        const { careerID, categoryID, resumeKind, saveResume } = props;
        const [careerDetails, setCareerDetails] = React.useState<CareerInfo | null>(null);
        const [careerName, setCareerName] = React.useState("the future");
       React.useEffect(() => {
            if (resumeKind === "career" && careerID) {
                Career.getCareerDetails(Number(careerID)).then(setCareerDetails);
                Career.getCareerList().then(l => {
                    const c = l.find(c => c.id === Number(careerID));
                    c && setCareerName(c.name);
                })
            }
            if (resumeKind === "category" && categoryID) {
                Career.getCareerCategoryDetails(categoryID).then(setCareerDetails);
                Career.getCareerCategories().then(l => {
                    const c = l.find(c => c.id === categoryID);
                    c && setCareerName(c.name)
                })
            }
        }, [careerID, categoryID, resumeKind]);
        return <ContentWrapper
                title={`My Plan for ${careerName}`}
                breadcrumbTitle={careerName}
                breadcrumbs={[
                    {name:"My Plan", link:"/plan"},
                ]}>
            <PageSectionRow>
                <Col>
                    <h2>My Plan</h2>
                </Col>
            </PageSectionRow>
            <Row className="mt-8">
                <Col>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} className="resume-card-head goals" eventKey="plan">
                                My Chosen Career
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="plan">
                                <Card.Body>
                                    <CareerGoalsPlanSection  {...props}  careerInfo={careerDetails} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> 
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} className="resume-card-head education" eventKey="senior-sub">
                                My Senior Subjects
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="senior-sub">
                                <Card.Body>
                                    <EducationHistory {...props} careerInfo={careerDetails} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
 
                    </Accordion>
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} className="resume-card-head tertiary" eventKey="post">
                                My Post-School Study &amp; Apprenticeships
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="post">
                                <Card.Body>
                                    <EducationPlans {...props} careerInfo={careerDetails} />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> 
                    <Accordion>
                        <Card>
                            <Accordion.Toggle as={Card.Header} className="resume-card-head employment" eventKey="folder">
                                My Personal Folder
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="folder">
                                <Card.Body>
                                    <PersonalFolder {...props} careerInfo={careerDetails}/>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion> 
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-2 mb-2">
                    <Button className="mr-1" variant="outline-secondary" onClick={saveResume}>
                        <FontAwesomeIcon icon={faSave}/> Save
                    </Button>
                </Col>
            </Row>
        </ContentWrapper>;
    }

export default ResumeForm;