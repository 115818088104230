import React, { useEffect } from 'react';
import {CareerNames} from "jobjump-types/Careers";
import Careers  from "../../data/Careers";
import { Col } from '../../components/grid';
import CareerImages from "../../assets/CareerImages";
import { RouteComponentProps } from 'react-router';
import FilterableGrid from '../../components/FilterableGrid';
import PageSectionRow from '../../components/PageSectionRow';
import ContentWrapper from '../../components/ContentWrapper';

const WorkExperienceListing : React.FC<RouteComponentProps> =
    ({history}) => {
        const [careerList, setCareerList] = React.useState<CareerNames[]>([]);
        useEffect(() => {
            Careers.getCareerList().then((data) => {
                console.log(data);
                setCareerList(data)
            })
        }, []);
        return (
            <ContentWrapper
                    title="eWork Experiences"
                    subtitle={["Want to try out a career? Select from below to see some activities to get you started."]}>
                <PageSectionRow><Col>
                    <FilterableGrid
                        numPerRow={3}
                        data={careerList.map(d => ({
                            text:d.name,
                            imgUrl:CareerImages[d.name.toUpperCase()] || "",
                            onClick:() => history.push("/eWorkExperience/"+d.id)
                        }))}
                    />
                </Col></PageSectionRow>
            </ContentWrapper>
        );
    }

export default WorkExperienceListing;