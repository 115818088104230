import React from "react";
import JobJumpLogin, { JobJumpLoginProps } from "../layout/JobJumpLogin";
import { Col, Row } from "../components/grid";

const LoginPage : React.FC<JobJumpLoginProps> =
(props) => <>
    <Row><Col>
        <JobJumpLogin {...props} />
    </Col></Row>
</>
    


export default LoginPage;