import React from "react";
import {Col} from "../../components/grid";
import PageHero from "../../components/PageHero";
import FindCareer from "../../assets/homepage/tiles/find-my-career-tool.jpg";
import FindCareer2 from "../../assets/homepage/tiles/student-finding-a-career.jpg";
import Money from "../../assets/homepage/tiles/student-money-help.jpg";
import OpenDays from "../../assets/homepage/tiles/university-open-days.jpg";
import Adj from "../../assets/homepage/tiles/university-adjustment-factors.jpg";
import Eas from "../../assets/homepage/tiles/university-early-access-scheme.jpg";
import Def from "../../assets/homepage/tiles/university-deferment.jpg";
import Exch from "../../assets/homepage/tiles/university-overseas-exchange.jpg";
import Accommodation from "../../assets/homepage/tiles/university-accommodation.jpg";
import Interstate from "../../assets/homepage/tiles/university-interstate-and-overseas.jpg";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { openLinkInNewTab } from "../../util";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";

const images : string[] = [
    FindCareer, FindCareer2, Money, OpenDays, Adj, Eas, Def, Exch, Accommodation, Interstate
]

const links : {name:string, to:string}[] = [
    {to:"http://www.afs.org.au/aus_en/home", name:"AFS Australia"},
    {to:"http://www.antipodeans.com.au/", name:"Antipodeans Abroad"},
    {to:"http://www.aupairamerica.com.au/", name:"Au Pair in America"},
    {to:"http://www.campamerica.com.au/", name:"Camp America"},
    {to:"http://www.ccusa.com/", name:"Camp Counselors USA"},
    {to:"http://www.culturalcare.com.au/", name:"Cultural Care Au Pair"},
    {to:"http://www.ef.com/master/hsy/", name:"EF"},
    {to:"http://www.hopenhome.org/", name:"Hope &amp; Home"},
    {to:"http://www.iep.com.au/", name:"IEP Australia"},
    {to:"http://www.interexchange.org/", name:"International Student Volunteers Inc."},
    {to:"http://www.kidsgivingback.org/", name:"Kids Giving Back"},
    {to:"http://www.lattitude.org.au/", name:"Lattitude Global Volunteering"},
    {to:"http://www.letzlive.org/", name:"Letz Live"},
    {to:"http://www.madventurer.com/", name:"Madventurer"},
    {to:"http://www.projects-abroad.com.au/", name:"Projects Abroad"},
    {to:"http://www.raleighinternational.org/ausnz/", name:"Raleigh International"},
    {to:"http://www.sts-education.com.au/australia/", name:"STS Student Exchange"},
    {to:"http://www.owh.com.au/", name:"Student Flights"},
    {to:"http://www.scce.com.au/", name:"Southern Cross Cultural Exchange"},
    {to:"http://www.studentexchange.org.au/", name:"Student Exchange Australia"},
    {to:"http://www.studentplacement.com.au/", name:"Student Placement Australia"},
    {to:"http://www.youthworks.net/year13", name:"Year 13 Youthworks Gap Year"},
    {to:"http://www.youngendeavour.gov.au/", name:"Young Endeavour"},
    {to:"http://www.yfu.com.au/", name:"Youth for Understanding International Exchange"},
]

const GapYear : React.FC<{}> =
    () =>
        <ContentWrapper title="Gap Year"
            subtitle={["Ever considered taking a gap year or going as an exchange student? Check out the links below."]} >
            <PageSectionRow>
                <Col>
                    <CardGrid numPerRow={3} prefix="gap">
                        {links.map((l, i) =>
                            <ImageCard
                                key={`gap-tile-${i}`}
                                text={l.name}
                                onClick={()=>openLinkInNewTab(l.to)}
                                imageUrl={images[i % images.length]} />
                        )}
                    </CardGrid>
                </Col>
            </PageSectionRow>
        </ContentWrapper>

export default GapYear;