import React from "react";
import { Row, Col } from "../../components/grid";
import { CareerLink, CareerText, CareerATARLink } from "jobjump-types/Careers";
import { openLinkInNewTab } from "../../util";
import { RouteComponentProps } from "react-router";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";

interface TertiaryProps extends RouteComponentProps {
    atarLinks: CareerATARLink[],
    tafeLinks: CareerLink[],
    otherTertiary: CareerLink[]
    tertiaryText?: CareerText
}

const Tertiary: React.FC<TertiaryProps> =
    ({ atarLinks, tafeLinks, otherTertiary, tertiaryText, history }: TertiaryProps) =>
        <>
            <Row><Col>
                <h5>Here are the courses for your career:</h5>
                <CardGrid prefix="tertiary" numPerRow={2}>
                    {atarLinks.map((l, i) =>
                        <ImageCard
                            key={`atar-${i}`}
                            text="University Courses"
                            onClick={()=>history.push(`/atar/${l.atarLink}`)} />
                    )}
                    {tafeLinks.map((l, i) =>
                        <ImageCard
                            key={`tafe-${i}`}
                            text={l.title || "TAFE"}
                            onClick={()=>openLinkInNewTab(l.url)} />
                    )}
                    {otherTertiary.map((l, i) =>
                        <ImageCard
                            key={`oth-${i}`}
                            text={l.title || "Private College"}
                            onClick={()=>openLinkInNewTab(l.url)} />
                    )} 
                </CardGrid>
            </Col></Row>
            <Row><Col>
                {tertiaryText ? <div dangerouslySetInnerHTML={{ __html: tertiaryText.content }}></div> : <></>}
            </Col></Row>
        </>

export default Tertiary;