import React from "react";
import { Row, Col } from "../../components/grid";
import { CareerLink, CareerText } from "jobjump-types/Careers";
import { openLinkInNewTab } from "../../util";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { EventTracking } from "../../data/EventTracking";

interface JobVacanciesProps {
    jobLinks: CareerLink[],
    yellowPages?: CareerText,
    otherJobText?: CareerText,
    sydneyJobs: CareerLink[],
    careerId : number
}

const JobVacanciesContents : React.FC<JobVacanciesProps> = 
({careerId, jobLinks, yellowPages, otherJobText, sydneyJobs}) => {
    const openLink = (link:string) => {
        EventTracking.trackEvent("openJobLink", String(careerId))
    }
    return <>
        {jobLinks.length > 0 ? <>
            <Row>
                <Col>
                    <h5>For job ads close to you, use the buttons below:</h5>
                </Col>
            </Row>
            <CardGrid prefix="local-jobs" numPerRow={2}>
                {jobLinks.map((l, i) =>
                    <ImageCard imageUrl={l.imageUrl} text={l.title || "Click Here"} onClick={()=>openLinkInNewTab(l.url)} />
                )}
            </CardGrid></>
            : <></>}
        {sydneyJobs.length > 0 && 
        <>
            <Row>
                <Col>
                    <h5>You may need to look in your nearest capital city for employment. Use the links below:</h5>
                </Col>
            </Row>
            <CardGrid prefix="syd-jobs" numPerRow={2}>
                {sydneyJobs.map((l, i) =>
                    <ImageCard imageUrl={l.imageUrl} text={l.title || "Click Here"} onClick={()=>openLinkInNewTab(l.url)} />
                )}
            </CardGrid></>}
        <Row><Col>
            {otherJobText ? <div dangerouslySetInnerHTML={{ __html: otherJobText.content }}></div> : <></>}
            {yellowPages ? <h5>You should also seek out companies by doing a Google search for the keywords: {yellowPages.content}</h5> : <></>}
            <p>Also, have you tried asking ALL of your relatives, friends and contacts such as coach and neighbours? They may have leads.</p>
        </Col></Row> 
    </>
}

const JobVacancies: React.FC<JobVacanciesProps> =
    (props) => <JobVacanciesContents {...props} />

export default JobVacancies;
export {JobVacanciesContents};
