import { IconBulb } from "@tabler/icons";
import arrayMove from "array-move";
import { EmploymentHistory } from "jobjump-types/Resume";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Alert from "../../../components/Alert";
import Button from "../../../components/Button";
import { Label, TextField, TextArea, Select } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { SubsectionProps, SubsectionTemplate, deleteFromList, SortableItemTemplate, updateObjectIndex } from "./common";

type SortableListItems = EmploymentHistory & {
    originalOrder: number
};

const SortableItem = SortableElement(
    ({ data, onChange, onDelete }: {data:SortableListItems, onChange:(t:EmploymentHistory)=>void, onDelete:()=>void}) => {
        return <SortableItemTemplate onDelete={onDelete}>
            <span><Row className="mt-8">
                <Col sm={6}>
                    <Label>Company</Label>
                    <TextField placeholder="Company Name" value={data.company} 
                        onChange={v => onChange({...data, company:v})} />
                </Col>
                <Col sm={6}>
                    <Label>Type of Work</Label>
                    <Select value={{label:data.kind, value:data.kind}} 
                        options={[
                            {label:"Paid", value: "Paid"},
                            {label:"Volunteer", value: "Volunteer"},
                            {label:"Work Experience", value:"Work Experience"},
                            {label:"Work Placement", value:"Work Placement"},
                        ]}
                        onChange={v => v && onChange({...data, kind:v.value as EmploymentHistory["kind"]})} />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <Label>Start Date</Label>
                    <TextField placeholder="Start Date" value={data.dateStart} 
                        onChange={v => onChange({...data, dateStart:v})} />
                </Col>
                <Col sm={6}>
                    <Label>End Date</Label>
                    <TextField placeholder="End Date" value={data.dateEnd} 
                        onChange={v => onChange({...data, dateEnd:v})} />
                    <small>Leave blank if ongoing</small>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>Description</Label>
                    <TextArea value={data.description} 
                        onChange={v => onChange({...data, description:v})} />
                </Col>
            </Row></span>
        </SortableItemTemplate>
    }
)

const SortableList = SortableContainer(
    (props:{data:SortableListItems[], section:EmploymentHistory[], setSection:(r:EmploymentHistory[])=>void}) => <div>
        {props.data.map((t: SortableListItems, i: number) => {
            return <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                <SortableItem 
                    index={i} 
                    data={t} 
                    onChange={(t)=>props.setSection(updateObjectIndex(props.section, t, i))} 
                    onDelete={()=>props.setSection(deleteFromList(props.section, i))} />
            </div>
        })}
    </div>
);

const EmploymentHistorySection : React.FC<SubsectionProps<"employment_details">> = ({section, setSection, sectionConfig, setSectionConfig }) => 
    <SubsectionTemplate
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}
        title="Employment History"
        buttons={[
            {text:"Experience", onClick:()=>setSection([
                ...section, 
                {kind:"Paid", company:"", description:"", dateStart:""}
            ])}
        ]}>
            <SortableList
                axis="xy"
                data={section.map((s,i) => ({...s, originalOrder:i}))}
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(section, oldIndex, newIndex);
                    setSection(order);
                }} 
                section={section}
                setSection={setSection} />
            {section.length === 0 && <Row className="mb-64 mt-64">
                <Col className="text-center">
                    <Alert Icon={IconBulb} variant="active" text="Everyone has done some form of work - you just may not have realised it. Work doesn't always 
                        have to be paid. It can be anything including Work Experience, Community Service and volunteering. 
                        Volunteering it can include things such as; selling raffle tickets for a school fundraiser, helping 
                        your sport team by washing the jerseys, assisting your neighbour by mowing their lawn or going to 
                        work with a relative and watching what they do or helping them out. If you don't think you have 
                        done any of this, you should make it a priority to do so."/>
                    <Button className="mt-16" variant="active" onClick={()=>setSection(section.concat([{kind:"Paid", company:"", description:"", dateStart:""}]))}>
                        Add Experience
                    </Button>
                </Col>
            </Row>}
            {/*<Row className="mt-32">
                <Col><h4>Other Experience</h4></Col>
                {/* This is the eWork experience text }
                <Col className="text-right" sm={4}><IconEye /></Col>
            </Row>
            <Row>
                <Col>
                    <TextField onChange={()=>{}} />
                </Col>
            </Row>*/}
        </SubsectionTemplate>

export default EmploymentHistorySection;