import React from "react";
import { Row, Col } from "../../components/grid";
import { CareerATARLink, CareerText } from "jobjump-types/Careers";
import { CareerSubjectRecommendation, Subject } from 'jobjump-types/Subjects';
import stripHtml from "string-strip-html";
import { stringOfSubjectRecommendation } from "../mySubjects/MySubjects";
import Button from "../../components/Button";
import { useHistory } from "react-router";
import SubjectsAPI, { StudentSubjectPreferenceInput, StudentSubjectPreferences } from "../../data/Subjects";
import Modal from "../../components/Modal";
import { Check, Label, Select, TextField } from "../../components/Form";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import { SortableItemTemplate, updateObjectIndex } from "../resume/sections/common";
import Alert from "../../components/Alert";

type SortableListItems = Partial<StudentSubjectPreferences> & {
    originalOrder: number
};

const SortableItem = SortableElement(
    ({ data, onChange, subject, onDelete }: {data:SortableListItems, onDelete:()=>void, onChange:(t:SortableListItems)=>void, subject:Subject[]}) => {
        return <SortableItemTemplate onDelete={onDelete}>
            <Row className="mt-8">
                <Col sm={6}>
                    <Select value={data.subject_id ? {label:data.name ?? subject.find(s => s.id === data.subject_id)?.name ?? "", value:String(data.subject_id)} : {label:"",value:""}} 
                        options={subject.map(s => ({label:s.name, value:String(s.id)}))}
                        onChange={v => v && onChange({...data, subject_id:Number(v.value), name:v.label})} />
                </Col>
                <Col sm={2}>
                    <TextField value={data.unit ?? ""} disabled />
                </Col>
                <Col sm={2}>
                    <TextField value={data.category === "ATAR" || data.category === "VET" ? "Yes" : data.category ? "No" : ""} disabled />
                </Col>
                <Col sm={2}>
                    <Check checked={data.checked ?? false} onChange={() => onChange({ ...data, checked: !data.checked })} label={""} />
                </Col>

            </Row>
        </SortableItemTemplate>
    }
)

const SortableList = SortableContainer(
    (props:{data:SortableListItems[], onChange:(r:SortableListItems[])=>void, subjects:Subject[]}) => <div>
        {props.data.map((t: SortableListItems, i: number) => {
            if (i === 6) {
                return <>
                    <Row>
                        <Col sm={12} className="pt-4">
                            <h4>Back Ups</h4>
                        </Col>
                    </Row>
                    <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                        <SortableItem 
                            subject={props.subjects}
                            index={i} 
                            data={t} 
                            onDelete={() => props.onChange(updateObjectIndex(props.data, {originalOrder:t.originalOrder}, i))}
                            onChange={(t)=> props.onChange(updateObjectIndex(props.data, t, i))} />
                    </div>
                </>
            }
            return <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                <SortableItem 
                    subject={props.subjects}
                    index={i} 
                    data={t} 
                    onDelete={() => props.onChange(updateObjectIndex(props.data, {originalOrder:t.originalOrder}, i))}
                    onChange={(t)=> props.onChange(updateObjectIndex(props.data, t, i))} />
            </div>
        })}
    </div>
);


const ChosenHSCSubjects : React.FC = () => {
    const [subjects, setSubjects] = React.useState<Subject[]>([]);
    const [chosenSubjects, setChosenSubjects] = React.useState<SortableListItems[]>([]);
    React.useEffect(() => {
        SubjectsAPI.getSubjects().then(l => setSubjects(l.filter(s => !s.teach_out && s.category !== "Y10")))
    }, []);
    const updatePreferences = React.useCallback(() => {
        SubjectsAPI.getStudentPreferences().then(p => {
            const maxSize = p.length > 8 ? p.length : 8;
            const filled : SortableListItems[] = new Array(maxSize);
            for (let i = 0; i < maxSize; ++i) {
                filled[i] = {...(p[i] ?? {}), originalOrder:i} 
            }
            setChosenSubjects(filled);
        });
    }, [setChosenSubjects]);
    React.useEffect(() => {
        updatePreferences()
    }, []);
    const updateList = React.useCallback((l:Partial<SortableListItems>[]) => {
        const input : StudentSubjectPreferenceInput[] = l.filter(v => v.subject_id !== undefined).map((v, i) => ({
            subject_id: v.subject_id ?? -1, // shouldn't happen
            rank: i,
            checked: v.checked ?? false,
        }));
        SubjectsAPI.updateStudentPreferences(input).then(updatePreferences);
    }, []);
    const hasSelect = chosenSubjects.find(s => s.subject_id !== undefined) !== undefined;
    const noEnglish = !chosenSubjects.find(s => s.name?.match("nglish") && !s.name?.match("xtension"))
    console.log(chosenSubjects, hasSelect, noEnglish);
    return <>
            <Row>
                <Col>
                    <h3>My Subject Preferences</h3>
                    <h4>Fill in the subjects you wish to study in Year 11/12 in the table below.</h4>
                    <div>Use the &quot;Checked&quot; column to mark off when you have spoken to someone, such as your Careers Adviser or teacher of the subject.</div>
                    <div>When completing this, refer to the list of subjects your school offers.</div>
                    {hasSelect && noEnglish && <Alert variant="negative" text={"You must select a level of English"} textOnly></Alert>}
                </Col>
            </Row>
            <Row style={{paddingLeft:"56px", paddingRight:"56px"}}>
                <Col sm={6}>
                    <Label>Subject</Label>
                </Col>
                <Col sm={2}>
                    <Label>Units</Label>
                </Col>
                <Col sm={2}>
                    <Label>ATAR</Label>
                </Col>
                <Col sm={2}>
                    <Label>Checked</Label>
                </Col>
            </Row>
            <SortableList
                axis="xy"
                distance={0}
                data={chosenSubjects}
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(chosenSubjects, oldIndex, newIndex);
                    setChosenSubjects([...order]);
                }} 
                onChange={updateList}
                subjects={subjects.filter(s => !chosenSubjects.find(c => c.subject_id === s.id))} />
            <Row>
                <Col className="mt-2">
                    <strong>If you intend on recieving an ATAR you should check your about choices with your Careers Adviser or the person at your school responsible for subject selection.</strong>
                </Col>
            </Row>
    </>
}

interface HSCSubjectsProps {
    subjectsItem?: CareerText,
    subjectRecommendations:CareerSubjectRecommendation[],
    atarLinks:CareerATARLink[]
}

const HSCSubjects: React.FC<HSCSubjectsProps> =
    ({ subjectsItem, subjectRecommendations, atarLinks }: HSCSubjectsProps) => {
        const [subjectText, setSubjectText] = React.useState<string>();
        const [showSubjectChoice, setShowSubjectChoice] = React.useState(false);
        React.useEffect(() => {
            if (!subjectsItem || subjectsItem.content === "") {
                setSubjectText("You can study anything that interests you.");
            } else {
                setSubjectText(stripHtml(subjectsItem.content));
            }
        }, [subjectsItem]);
        const history = useHistory();
        return <>
            <Row>
                <Col>
                    {subjectRecommendations.length === 0 && subjectText && <><strong>{subjectText}</strong><br/></>}
                    {subjectRecommendations && subjectRecommendations.length > 0 && <div>
                        <h4>You could study:</h4>
                        <ul>
                            {subjectRecommendations.map((s, i)=><li key={`sub-${i}`}>{stringOfSubjectRecommendation(s)}</li>)}
                        </ul>
                    </div>}
                    {atarLinks.length > 0 && <div>You should also check the University course listings for subject suggestions. {atarLinks.map(({atarLink}) => 
                        <Button onClick={()=>history.push(`/atar/${atarLink}`)} variant="active" textButton>See Here</Button>)}
                    </div>}
                    <strong>Tips:</strong>
                    <ol>
                        <li>Discuss the subjects you wish to pick with your Careers Adviser, teachers, your parents
                        and also students who have done well in the subject.</li>
                        <li>Find the topics that will be taught in this subject. Do you like most of what you will learn? </li>
                        <li>Remember once you start your senior subjects it can sometimes be hard to change them. Choose what you
                            like and do well in for the next two years.
                        </li>
                    </ol>
                    <>You may be able to study and develop skills in your career through 
                        an TVET or VET course. Check with your Careers 
                        Adviser or Principal if that is possible and your options if you 
                        want an ATAR.</>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <Button onClick={()=>setShowSubjectChoice(true)} variant="active">Choose My Subjects</Button>
                </Col>
            </Row>
            <Modal show={showSubjectChoice} onHide={()=>setShowSubjectChoice(false)}>
                <ChosenHSCSubjects />
            </Modal>
        </>
    }


export default HSCSubjects;