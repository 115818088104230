import React from "react";
import Button from "./Button";
import {Col} from "./grid";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import "./videoList.css";
import PageSectionRow from "./PageSectionRow";
import { TextField } from "./Form";
import { IconLink } from "@tabler/icons";
import CardGrid from "./CardGrid";
import ImageCard from "./cards/ImageCard";
import VideoModal from "./VideoModal";
import copy from "copy-to-clipboard";

export interface VideoList {
    title: string,
    subtitle?: string,
    key: string,
    videoId : string,
    imageUrl?:string
}

interface VideoSelectionProps extends RouteComponentProps {
    videos : VideoList[],
}


const VideoSelection : React.FC<VideoSelectionProps> =
({location, videos}) => {
    const args  = qs.parse(location.search);
    const video = args.video && Array.isArray(args.video) ? args.video[0] : args.video;
    const [currentVideo, setCurrentVideo] = React.useState<VideoList>();
    const [searchTerm, setSearchTerm] = React.useState("");
    const [filteredList, setFilteredList] = React.useState<VideoList[]>(videos);
    const sortStrings = (a:VideoList, b:VideoList) => {
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
        }
        if (b.title.toLowerCase() > a.title.toLowerCase()) {
            return -1;
        }
        return 0;
    };
    React.useEffect(() => {
        if (!video) {
             return;
        }
        setCurrentVideo(videos.find(v => v.key === video));
    }, [video, setCurrentVideo, videos]);
    React.useEffect(() => {
        if (searchTerm === "") {
            setFilteredList([...videos.sort(sortStrings)]);
            return;
        }
        setFilteredList([...videos.filter(v=>v.title.toUpperCase().match(`.*${searchTerm.toUpperCase()}.*`)).sort(sortStrings)]);
    }, [searchTerm]);
    return <><PageSectionRow>
        <Col>
            <TextField value={searchTerm} onChange={setSearchTerm} placeholder="Search..." />
        </Col>
    </PageSectionRow>
    <PageSectionRow>
        <Col>
            <CardGrid numPerRow={4} prefix="video">
                {filteredList.map(t=> 
                    <ImageCard imageUrl={t.imageUrl} text={t.title} onClick={()=>setCurrentVideo(t)}>{t.subtitle}</ImageCard>
                )}
            </CardGrid>
        </Col>
    </PageSectionRow>
    {currentVideo && <VideoModal vimeoId={currentVideo.videoId} show={currentVideo !== undefined} onHide={()=>setCurrentVideo(undefined)}>
        <Button variant="neutral" onClick={()=>copy(`https://jobjump.com.au${location.pathname}?video=${currentVideo.key}`)}>
            <IconLink /> Copy Link
        </Button>
    </VideoModal>}
    </>;
}

export default VideoSelection;