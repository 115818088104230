import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router";
import CaStudentDetails from "../../data/CaStudentDetails";
import TimePicker from "react-time-picker";
import moment from "moment";
import "./time-picker.css"
import CaAdminLayout from "./CaAdminLayout";
import { Check, Label, TextArea, TextField } from "../../components/Form";
import { Col, Row } from "../../components/grid";
import Button from "../../components/Button";
import PageSectionRow from "../../components/PageSectionRow";

interface RecordInterviewMatch {
    studentID : string,
    interviewID?: string
}

const RecordInterview : React.FC<RouteComponentProps<RecordInterviewMatch>> =
    (props) => {
        const {history, match : { params : {studentID, interviewID}}} = props
        const [studentName, setStudentName] = useState("");
        const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
        const [startTime, setStartTime] = useState();
        const [endTime, setEndTime] = useState();
        const [comment, setComment] = useState("");
        const [sendToStudent, setSendToStudent] = useState(false);
        useEffect(() => {
            CaStudentDetails.studentDetails(studentID).then(d => 
                setStudentName(`${d.student.first_name} ${d.student.last_name}`)
            );
        }, [studentID])
        useEffect(() => {
            if (!interviewID) {
                return;
            }
            CaStudentDetails.getStudentInterview(interviewID).then(r => {
                setDate(r.date);
                setStartTime(r.start_time as any);
                setEndTime(r.end_time as any);
                setComment(r.comment);
            })
        }, [interviewID]);
        const submit = React.useCallback(() => {
            if (interviewID) {
                CaStudentDetails.updateInterview(interviewID, {
                    id:interviewID,
                    date,
                    start_time:startTime || "",
                    end_time:endTime || "",
                    comment
                }).then(()=>history.push("/admin/student/"+studentID));
            } else {
                CaStudentDetails.recordStudentInterview({
                    student: studentID,
                    date,
                    start_time : startTime || "",
                    end_time : endTime || "",
                    comment,
                    sendToStudent
                }).then(() =>
                    history.push("/admin/student/"+studentID)
                );
            }
            
        }, [studentID, interviewID, date, startTime, endTime, comment, sendToStudent]);
        return <CaAdminLayout
            active=""
            title={studentName ? `${studentName} - Interview` : "Record Interview"} {...props}>
                <PageSectionRow>
                    <Col className="text-center">
                        <h3>Record an Interview</h3>
                        <span>Use the form below to record details of your interview. You can retrieve this later in the "Interview Records" section, or by clicking on the name of the student.</span>
                    </Col> 
                </PageSectionRow>
                <PageSectionRow>
                    <Col md={3}>
                        <Label>Student Name</Label>
                        <TextField disabled value={studentName} onChange={()=>{}} />
                    </Col>
                    <Col md={3}>
                        <Label>Interview Date</Label>
                        <TextField dateField value={date} onChange={setDate} />
                    </Col>
                    <Col md={3}>
                        <Label>Start Time</Label>
                        <TimePicker
                            value={startTime}
                            onChange={setStartTime}
                            disableClock={true}
                            maxDetail="minute"
                        />
                    </Col>
                    <Col md={3}>
                        <Label>End Time</Label>
                        <TimePicker
                            value={endTime}
                            onChange={setEndTime}
                            disableClock={true}
                            maxDetail="minute"
                            amPmAriaLabel="Select AM/PM"
                        />
                    </Col>
                </PageSectionRow>
                <Row>
                    <Col>
                        <Label>Interview Notes</Label>
                        <TextArea value={comment} onChange={setComment} />
                    </Col>
                </Row>
                {!interviewID && <Row className="mt-16 text-right">
                    <Col>
                        <Check label="Send Copy to Student?" checked={sendToStudent} onClick={()=>setSendToStudent(s => !s)} />
                    </Col>
                </Row>}
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button variant="active" className="mr-8" onClick={submit}>Submit</Button>
                        <Button variant="neutral" onClick={window.print}>Print</Button>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col>
                        <h3 className="mb-8">Prompts - Questions to Ask Your Student</h3>
                        <ol>
                            <li className="mb-8">How can I help you today? What would you like to know?</li>
                            <li className="mb-8">Do you have a particular career in mind?</li>
                            <li className="mb-8">If you have a career in mind, what or who influenced you towards wanting to do this career?</li>
                            <li className="mb-8">Have you research it by watching a video about it, reading about it, doing work experience in it?</li>
                            <li className="mb-8">If you don't know of a career you like, do you know a particular career field that you would like to work in, like hospitality and tourism, mechanical etc</li>
                            <li className="mb-8">Do you have a favourite subject at school?</li>
                            <li className="mb-8">What things do you like doing outside of the school?</li>
                            <li className="mb-8">What level of education would you like to study to that is your optimum level? Uni, TAFE, Diploma, Cert 4, 3, 2 on the job training?</li>
                            <li className="mb-8">Did you want an ATAR?</li>
                            <li className="mb-8">Do you feel that the career you picked is suitable for your skills and personality?</li>
                            <li className="mb-8">Do you need to develop further in areas of your skills and personality to enter and develop in the career you have chosen? If so, what things do you think can or should be done?</li>
                            <li className="mb-8">If you have more than one career in mind, from your point of view, what do you see are the good points and the weaker points for each?</li>
                            <li className="mb-8">Where do you see yourself in 1, 3 and 5 years time?</li>
                            <li className="mb-8">What resources do you wish for me to provide you?</li>
                            <li className="mb-8">Do you feel there are any obstacles or worries you have in reaching you goals?</li>
                            <li className="mb-8">Who are the key people you feel will help you towards reaching your goals?</li>
                            <li className="mb-8">What steps over time do we need to take towards reaching your goals?</li>
                            <li className="mb-8">Do you feel your current results will give some level of assurance that you will enter the tertiary training you plan to go into?</li>
                            <li className="mb-8">If not, as a backup, do you have alternative ways to enter this tertiary training?</li>
                            <li className="mb-8">Using the My Career menu option on JobJump with its sequence of 7 steps towards your chosen career, can you demonstrate you have done each of the 7 steps?</li> 
                        </ol>
                    </Col>
                </Row>
        </CaAdminLayout>;
    }

export default RecordInterview;