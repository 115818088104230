import qs from "query-string";
import { fetchIgnoreAuth } from "./Utils";

const EventTracking = {
    trackEvent : (eventTag:string, eventMeta:string) =>
        fetchIgnoreAuth(`/api/log/event?${qs.stringify({eventTag,eventMeta})}`).catch(console.log),
    trackUnauthEvent : (eventTag:string, eventMeta:string) =>
        fetch(`/api/log/eventUnauth?${qs.stringify({eventTag,eventMeta})}`).catch(console.log)
}

export {EventTracking};