import React from "react";
import {RouteComponentProps} from "react-router-dom";
import { GeneralResume, CareerResume, ResumeKinds } from "jobjump-types/OldResume";
import Resume from "../../data/OldResume";
import ResumeForm from "./ResumeForm";

const emptyYesNo = {
    value : "" as "",
    what : "",
    who : "",
    when : ""
}

const emptyGeneralResume = {
    generic_goals : {
        have_usi : {...emptyYesNo},
        have_tfn : {...emptyYesNo},
        leaving_year : "12",
    },
    generic_subjects : {
        want_atar : "" as "",
        subjects : []
    },
    generic_employment : {
        history : [],
        folder : {
            folder_resume : { ...emptyYesNo},
            folder_letter : { ...emptyYesNo},
            folder_references : { ...emptyYesNo},
            folder_proof_of_age : { ...emptyYesNo},
            folder_project : { ...emptyYesNo},
            folder_report_card : { ...emptyYesNo},
            folder_awards : { ...emptyYesNo},
            folder_spare_resume : { ...emptyYesNo},
            folder_spare_paper : { ...emptyYesNo},
            folder_questions : { ...emptyYesNo},
        }
    },
    generic_tertiary : {
        eas : false,
        scholarship : false,
        scholarship_desc : "",
        principal_recommend : false
    },
    generic_awards : {
        interests : [],
        awards : [],
        referees : []
    },
    generic_details : {
        first : "",
        last : "",
        address : "",
        phone : "",
        email : "", 
        dob : "",
        gender : "f" as "m"|"f",
        langs : ""
    }
};

const emptyCareerResume : CareerResume = {
    career_goals : {
        ambition : "",
        video : { ...emptyYesNo},
        news : { ...emptyYesNo},
        work_exp : { ...emptyYesNo},
        project : { ...emptyYesNo},
        prospect : "",
        white_card : { ...emptyYesNo},
        rsa : { ...emptyYesNo},
        first_aid : { ...emptyYesNo},
        umat : { ...emptyYesNo}
    },
    career_qualities : [],
    career_tertiary : [{
        tertiary_tertiary_type : "",
        tertiary_degree_type : "",
        tertiary_course_name : "",
        tertiary_institute_name : "",
        tertiary_check_requirements : { ...emptyYesNo },
        tertiary_accomodation : { ...emptyYesNo },
        tertiary_done_quizzes : { ...emptyYesNo },
        tertiary_find_employers : { ...emptyYesNo },
        tertiary_contact_employers : { ...emptyYesNo },
        tertiary_job_vacancies : { ...emptyYesNo },
        tertiary_know_how_to_talk : { ...emptyYesNo },
        tertiary_know_how_to_present: { ...emptyYesNo }
    }]
};

interface ResumeEditorParams {
    kind : ResumeKinds;
    categoryID? : string,
    careerID? : string
}

const ResumeEditor : React.FC<RouteComponentProps<ResumeEditorParams>> =
    ({match}) => {
        const {kind, categoryID, careerID} = match.params;
        const [generalResume, setGeneralResume] = React.useState<GeneralResume>({...emptyGeneralResume});
        const [careerResume, setCareerResume] = React.useState<CareerResume>({...emptyCareerResume});

        React.useEffect(() => { Resume.getUserGenericResume().then(r => r && setGeneralResume(r)); }, []);
        React.useEffect(() => {
            if (kind === "unsure") {
                Resume.getUnsureResume().then(r => r ? setCareerResume(r) : undefined);
            }
            if (kind === "category" && categoryID) {
                Resume.getCategoryResume(categoryID).then(r => r ? setCareerResume(r) : undefined);
            }
            if (kind === "career" && careerID) {
                Resume.getCareerResume(careerID).then(r => r ? setCareerResume(r) : undefined);
            }
        }, [careerID, categoryID, kind]);
        const saveResume = React.useCallback(() => {
            if (generalResume && careerResume) {
                Resume.saveResume({
                    general_info : JSON.stringify(generalResume),
                    career_info : JSON.stringify(careerResume),
                    resume_type : kind,
                    career_id : careerID,
                    category_id : categoryID
                });
            }
       }, [generalResume, careerResume, careerID, categoryID, kind]);

        return <ResumeForm
            careerID={careerID}
            categoryID={categoryID}
            resumeKind={kind}
            generalResume={generalResume}
            careerResume={careerResume}
            saveResume={saveResume}
            updateGeneralResume={setGeneralResume}
            updateCareerResume={setCareerResume}
        />;
    }

export default ResumeEditor;
