import React from "react";
import {Row, Col} from "../../components/grid";
import { AtarCategory, AtarResult } from "jobjump-types/ATAR";
import AtarAPI from "../../data/ATAR";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";

import queryString from "query-string";

import ATARTable from "./ATARTable";
import { IconPrinter } from "@tabler/icons";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";

interface UrlParam {
    category_id: string
}

const MyAtarListing: React.FC<UserDetailsProps & RouteComponentProps<UrlParam>> =
    (props) => {
        const  {match,history} = props;
        const { atar, inst } = queryString.parse(history.location.search) as { atar?: number, inst?:number };
        const category = match.params.category_id;
        const [data, setData] = React.useState<AtarResult[]>([]);
        const [chosenStates, setChosenStates] = React.useState<string[]>([]);
        const [atarCategory, setAtarCategory] = React.useState<AtarCategory>();

        React.useEffect(() => {
            AtarAPI.getAtarCategories().then(r => setAtarCategory(r.find(c=>String(c.id)===category)));
        }, [category]);

        React.useEffect(() => {
            if (chosenStates.length === 0) {
                return;
            }
            AtarAPI.getAtarList(category, chosenStates, inst).catch(e=>{console.log(e); return []}).then((d: AtarResult[]) => {
                let data = d;
                if (atar) {
                    data = d.filter((r) => {
                        if (isNaN(Number(r.atar))) { return true }
                        return Number(r.atar) <= atar;
                    });
                }
                setData(data)
            });
        }, [category, chosenStates, atar, inst]);

        return <ContentWrapper
                title={`My ATAR - ${atarCategory ? atarCategory.name : ""}`}
                subtitle={["Trying to find the right course for you? Use the table below to find your course."]}
                breadcrumbs={[
                    {name:"My ATAR", link:"/atar"},
                ]}>
            <Row>
                <Col className="text-right">
                    <Button variant="neutral" onClick={()=>window.print()}>
                        <IconPrinter /> Print
                    </Button>
                </Col>
            </Row>
            <ATARTable showSearch data={data} chosenStates={chosenStates} setChosenStates={setChosenStates} {...props} />
            <Row>
                <Col>
                    <small><strong>Acknowledgement</strong></small><br />
                    <small>Data for employment rates is provided by Australian Government Department of Education, Skills and Employment, which fund the QILT suite of surveys program.</small>
                </Col>
            </Row>
        </ContentWrapper>
    }

export default MyAtarListing;