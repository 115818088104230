import React from "react";
import { Student, StudentListing } from "jobjump-types/CaStudents";
import { RouteComponentProps } from "react-router";
import PageSectionRow from "../../components/PageSectionRow";
import CaStudentDetails from "../../data/CaStudentDetails";
import UserDetailsAPI from "../../data/UserDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import {IconEdit, IconTrash} from "@tabler/icons";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

type TableData = {student:Student, interview_count:number};

const CaAdminHome : React.FC<RouteComponentProps> = ({...props}) => {
    const [schoolName, setSchoolName] = React.useState("");
    const [data, setData] = React.useState<TableData[]>([]);
    const [studentToDelete, setStudentToDelete] = React.useState<Student>();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
    React.useEffect(() => {
        CaStudentDetails.listStudents().then((l) => {
            const data : TableData[] = l.map((s:StudentListing) =>
                ({student:s, interview_count:s.interview_count})
            );
            setData(data);
        });
    }, []);
    const doDelete = React.useCallback(() => {
        if (studentToDelete) {
            CaStudentDetails.deleteStudent(studentToDelete.id).then(() => {
                setData(data.filter(d => d.student.id !== studentToDelete.id));
                setShowDeleteConfirmation(false);
                setStudentToDelete(undefined);
            })
        }
    }, [studentToDelete, data])
    React.useEffect(() => {
        UserDetailsAPI.getDetails().then(d => setSchoolName(d.school_name));
    })
    return <CaAdminLayout 
        active="students"
        title={`${schoolName} Dashboard`} 
        {...props}>
        <>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Management</h3>
                    <span>Below is a list of all your students currently registered for JobJump. You can click on their names to see more information about them.</span>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        showYear13
                        additionalColumns={[
                            { Header:"Interviews", accessor:"interview_count", width:1, disableSortBy:true },
                            { 
                                width:1,
                                id: "interviewButton", 
                                Header:"Record",
                                disableSortBy:true,
                                Cell: ({row : {original : {student}}}) => 
                                    <IconEdit
                                        className="ca-table-icon"
                                        onClick={()=>props.history.push("/admin/interview/"+student.id)} />
                            },
                            { 
                                width:1,
                                id: "deleteButton", 
                                disableSortBy:true,
                                Cell: ({row}) => 
                                    <IconTrash
                                        className="ca-table-icon"
                                        onClick={()=>{
                                            setStudentToDelete(row.original.student);
                                            setShowDeleteConfirmation(true);
                                        }} />
                            },
                        ]}
                    />

                </Col>
            </PageSectionRow>
            <Modal show={showDeleteConfirmation} onHide={()=>setShowDeleteConfirmation(false)} title="Confirm Deletion">
                <Row>
                    <Col>
                        <h4>Do you want delete {studentToDelete && `${studentToDelete.first_name} ${studentToDelete.last_name}`}?</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <Button variant="negative" onClick={()=>setShowDeleteConfirmation(false)}>Close</Button>
                        <Button className="ml-8" variant="active" onClick={doDelete}>Delete</Button>
                    </Col>
                </Row>
            </Modal>
        </>

    </CaAdminLayout>
}
    


export default CaAdminHome;