import { IconX } from "@tabler/icons";
import React from "react";
import ReactModal from "react-modal";
import { Col, Row } from "../grid";
import "./modal.css";

interface ModalProps {
    show: boolean,
    onHide: ()=>void,
    disallowClose?:boolean,
    title?: string,
    size?:"sm"|"md"|"lg"
}

const Modal : React.FC<ModalProps> = ({show, onHide, disallowClose, children, title, size}) =>
    <ReactModal
        className={"jobjump-modal jobjump-base-card "+(size ? "jobjump-modal-"+size : "jobjump-modal-md")}
        overlayClassName="jobjump-modal-overlay"
        isOpen={show} 
        onRequestClose={onHide}>
            {(title || !disallowClose) && <Row className="mb-16">
                {title && <Col sm={11}><h3>{title}</h3></Col>}
                {!disallowClose && <Col sm={title ? 1 : 12} className="text-right"><IconX className="cursor-pointer" onClick={onHide} /></Col>}
            </Row>}
            {children}
    </ReactModal>

export default Modal;