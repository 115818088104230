import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { DocumentCell, DocumentGrid, HeaderProps } from "./CommonSections";
import { IconWithText, Pin, Envelope, Phone, otherSectionToIcon } from "./Icons";

const SimpleCentreHeader : React.FC<HeaderProps> = ({resume:{styleConfig, personal_details}}) => {
    const style = StyleSheet.create({
        name: {
            fontSize: "28pt",
            marginBottom: "16px",
            textAlign: "center",
            color: styleConfig.primaryColor
        },
        centreText : {
            textAlign: "center",
            paddingBottom: "16px"
        }
    });
    return <View style={style.centreText}>
        <Text style={style.name}>{personal_details.name}</Text>
        <DocumentGrid>
            {personal_details.address && personal_details.address !== "" ? 
                <DocumentCell size={6}>
                    <IconWithText height="12pt" Icon={Pin} color={"#000000"}>
                        <Text>{personal_details.address}</Text>
                    </IconWithText>
                </DocumentCell> : undefined}
            {personal_details.email && personal_details.email !== "" ? 
                <DocumentCell size={6}>
                    <IconWithText height="12pt" Icon={Envelope} color={"#000000"}>
                        <Text>{personal_details.email}</Text>
                    </IconWithText>
                </DocumentCell> : undefined}
            {personal_details.phone && personal_details.phone !== "" ? 
                <DocumentCell size={6}>
                    <IconWithText height="12pt" Icon={Phone} color={"#000000"}>
                        <Text>{personal_details.phone}</Text>
                    </IconWithText>
                </DocumentCell> : undefined}
            {personal_details.other?.map(o =>
                o.value !== "" ?
                    <DocumentCell size={6}>
                        <IconWithText height="12pt" Icon={otherSectionToIcon(o.key)} color={"#000000"}>
                            <Text>{o.value}</Text>
                        </IconWithText>
                    </DocumentCell> : undefined
            )}
        </DocumentGrid>
        {personal_details.languages && personal_details.languages !== "" ? 
            <Text>Languages Spoken: {personal_details.languages}</Text> : undefined}
    </View>
}

export default SimpleCentreHeader;