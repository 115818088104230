import qs from "query-string";
import { DestinationSurveyResponse } from "jobjump-types/DestinationSurvey";
import { fetchWithAuth } from "./Utils";

const DestinationSurveyAPI = {
    hasCompletedSurvey : (token:string) : Promise<boolean> =>
        fetchWithAuth("/api/survey/completed?"+qs.stringify({token})).then(r=>r.json()),
    
    submitSurvey : (user_token:string, user_id:string, response:DestinationSurveyResponse) =>
        fetchWithAuth("/api/survey/submit?"+qs.stringify({user_token,user_id,data:JSON.stringify(response)})),

    submitAnonSurvey : (school_token:string, school_id:string, first:string, last:string, email:string, aboriginal:boolean, response:DestinationSurveyResponse) =>
        fetchWithAuth("/api/survey/anonSubmit?"+qs.stringify({ school_token, school_id, first, last, email, aboriginal, data:JSON.stringify(response) })),
    
    submitSendingFrequency : (school:string, token:string, numSends:number) => 
        fetchWithAuth(`/api/survey/setFrequency?${qs.stringify({school,token,numSends})}`),

    getCurrentFrequency : (school:string, token:string) : Promise<{numSends:number|null}> =>
        fetchWithAuth("/api/survey/currentSendCount?"+qs.stringify({school,token})).then(r=>r.json()),
};

export default DestinationSurveyAPI;