import React from "react";
import YouTube from "react-youtube";
import { Col, Row } from "../components/grid";
import { openLinkInNewTab } from "../util";
import "./apprentice.css";
import Button from "../components/Button";
import PageSectionRow from "../components/PageSectionRow";
import RowCard from "../components/RowCard";
import ContentWrapper from "../components/ContentWrapper";
import { EventTracking } from "../data/EventTracking";

type LinkType = "interactive"|"hardCopy";

type ApprDetails = {
    [k in LinkType]: string;
} & {
    name: string;
};

const details: ApprDetails[] = [
    {
        name: "Automotive",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Automotive%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Automotive"
    },
    {
        name: "Aged Care",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Aged%20Care%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Aged%20Care"
    },
    {
        name: "Animal Care",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Animal%20Care%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Animal%20Care%20and%20Management"
    },
    {
        name: "Business Services",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Business%20Services%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Business%20Services"
    },
    {
        name: "Early Childhood",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Early%20Childhood%20Education%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Early%20Childhood%20Education%20and%20Care"
    },
    {
        name: "Building & Construction",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Building%20and%20Construction%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Building%20and%20Construction"
    },
    {
        name: "Dental Assistant",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Dental%20Assisting%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Dental%20Assisting"
    },
    {
        name: "Electrical & Electronic",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Electrical%20and%20Electronic%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Electrical%20and%20Electronic"
    },
    {
        name: "Engineering",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Engineering%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Engineering"
    },
    {
        name: "Waste Management",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Waste%20Management%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Waste%20Management"
    },
    {
        name: "Financial Services",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Financial%20Services%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Financial%20Services"
    },
    {
        name: "Community Recreation",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Community%20Recreation%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Community%20Recreation"
    },
    {
        name: "Food Processing",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Food%20Processing%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Food%20Processing"
    },
    {
        name: "Warehousing",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Warehousing%20Logistics%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Warehousing%20and%20Supply%20Chain%20Logistics"
    },
    {
        name: "Hairdressing",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Hairdressing%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Hairdressing"
    },
    {
        name: "Allied Health",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Allied%20Health%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Allied%20Health"
    },
    {
        name: "Horticulture",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Horticulture%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Horticulture"
    },
    {
        name: "Hospitality",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Hospitality%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Hospitality"
    },
    {
        name: "IT",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Information%20Technology%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Information%20Technology"
    },
    {
        name: "Mechanical Engineering",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Mechanical%20Engineering%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Mechanical%20Engineering"
    },
    {
        name: "Metal Fabrication",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Metal%20Fabrication%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Metal%20Fabrication"
    },
    {
        name: "Drilling",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Drilling%20Operations%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Drilling%20Operations"
    },
    {
        name: "Mining & Quarrying",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Mining%20and%20Quarrying%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Mining%20and%20Quarrying"
    },
    {
        name: "Finishing (Plaster, Tiling and Painting)",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Finishing%20Trades%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Finishing%20Trades%20-%20Plastering,%20Tiling%20and%20Painting"
    },
    {
        name: "Plumbing",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Plumbing%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Plumbing"
    },
    {
        name: "Printing",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Printing%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Printing"
    },
    {
        name: "Retail Services",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Retail%20Services%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Retail%20Services"
    },
    {
        name: "Spatial Information and Surveying",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Spatial%20Information%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Spatial%20Information%20Services%20and%20Surveying"
    },
    {
        name: "Telecommunication",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Telecommunications%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Telecommunications"
    },
    {
        name: "Tourism",
        hardCopy:"https://content.apprenticeships.gov.au/sites/default/files/2023-08/Tourism%20Quiz.pdf",
        interactive:"https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Tourism"
    },
    {
        name: "Water Industry",
        hardCopy: "https://content.apprenticeships.gov.au/sites/default/files/2023-08/Water%20Quiz.pdf",
        interactive: "https://www.apprenticeships.gov.au/apprentices/how-to-find-and-prepare-for-an-apprenticeship/check-your-literacy-and-numeracy-skills/Water%20Industry"
    }
]

const Apprenticeships: React.FC<{}> = () =>  {
    const clickQuiz = (name:string, link:string) => {
        EventTracking.trackEvent("litNumeracyClick",name);
        openLinkInNewTab(link);
    }
    return <ContentWrapper 
            title="Apprenticeships & Traineeships"
            subtitle={["Thinking of doing an Apprenticeship or Traineeship? Check out the resources and quizzes below."]} >
        <PageSectionRow>
            <Col className="text-center" md={6}>
                <h2>What's It About?</h2>
                <h5>Have a look at the following links, or watch the video to learn more.</h5>
                <Button variant="active" onClick={()=>openLinkInNewTab("https://www.skillsroad.com.au/training-study/apprenticeships")}>
                    Skills Road
                </Button>
            </Col>
            <Col md={6}>
                <YouTube videoId="N_NyfgNlGUw" />
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Apprenticeship/Traineeship Quizzes</h2>
                <h5>Find the apprenticeship or traineeship you're interested in below, and take the quiz to see if you're suited.</h5>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                {details.sort((a, b) => a.name > b.name ? 1 : -1).map((d, i) =>
                    <RowCard key={`appr-${i}`}>
                        <Col md={6}>
                            {d.name}
                        </Col>
                        <Col md={6} className="text-right">
                            <Button variant="neutral" className="mr-8" onClick={()=>clickQuiz(d.name, d.hardCopy)}>
                                Hard Copy Quiz
                            </Button>
                            <Button variant="active" onClick={()=>clickQuiz(d.name, d.interactive)}>
                                Interactive Quiz
                            </Button>
                        </Col>
                    </RowCard>)}
            </Col>
        </Row>
    </ContentWrapper>
}

export default Apprenticeships;