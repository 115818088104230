import React, { useEffect } from 'react';
import { BrowserRouter as Router, RouteComponentProps, withRouter } from "react-router-dom";
import JobJumpMenu from './layout/JobJumpMenu';
import 'bootstrap/dist/css/bootstrap.min.css';
import JobJumpFooter from './layout/JobJumpFooter';
import User from "./data/User";
import { UserDetails } from 'jobjump-types/User';
import "./styles/layout.css";
import "./styles/text.css";
import "./styles/padding.css";
import { HeroConfiguration, SchoolHomepage } from 'jobjump-types/SchoolHomePage';
import SchoolHomepageAPI from './data/SchoolHomepage';
import JobJumpRouter from "./layout/Routes";
import ThemeProvider from './components/ThemeProvider';
import 'react-popper-tooltip/dist/styles.css';

const getSubDomainString = (href:string) =>
href.split(".")[0] || "jobjump"

const ScrollToTop : React.FC<RouteComponentProps> = ({ location, history, children }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, [history]);
  console.log(location.pathname);

  return <>{children}</>;
}

const ScrollToTopElem = withRouter(ScrollToTop);

const App: React.FC = () => {
  const [userDetails, setUserDetails] = React.useState<UserDetails>();
  const [schoolConfig, setSchoolConfig] = React.useState<SchoolHomepage>();
  const [schoolId, setSchoolId] = React.useState<string>();
  const [logoUrl, setLogoUrl] = React.useState<string>();
  const [heroConfig, setHeroConfig] = React.useState<HeroConfiguration>();
  const subDomain = getSubDomainString(window.location.hostname);
  const isInSubDomain = !["jobjump", "www", "stage", "localhost"].includes(subDomain);

  useEffect(() => {
    if (!isInSubDomain) { return }
    SchoolHomepageAPI.getSiteConfig({ shortCode: subDomain }).then(c=>{setSchoolConfig(c && c.homepage); setSchoolId(c && c.schoolId)});
  }, [isInSubDomain, subDomain, setSchoolConfig])

  useEffect(() => {
    if (!isInSubDomain) { return }
    SchoolHomepageAPI.getLogo({ shortCode: subDomain }).then(setLogoUrl);
  }, [isInSubDomain, subDomain, setLogoUrl])

  useEffect(() => {
      if (!isInSubDomain) { return }
      SchoolHomepageAPI.getHero({shortCode:subDomain}).then(setHeroConfig);
  }, [isInSubDomain, subDomain, setHeroConfig])

  // When the application first loads, check if we're already logged in from a previous session
  useEffect(() => {
    const setLogin = () => {
      if (userDetails) {
        return;
      }
      User.getUserDetails().then(d => {
        if ((!d && !userDetails) || (JSON.stringify(userDetails) === JSON.stringify(d))) {
          return;
        }
        setUserDetails(d);
        if (!d) {
          return;
        }
        SchoolHomepageAPI.getSiteConfig({schoolId:String(d.schoolId)}).then(v => {
          if (v) {
            setSchoolConfig(v.homepage);
            setSchoolId(v.schoolId);
          }
        });
      });
    };
    setLogin();
    const loginTimeout = setTimeout(setLogin, 3000);
    return () => clearTimeout(loginTimeout);
  }, [userDetails, setUserDetails]);

  const logout = () => {
    User.logout().then(() => {
      setUserDetails(undefined);
    }).catch(() => setUserDetails(undefined));
  }

  if (isInSubDomain && !schoolId) {
    return <></>;
  }

  return <Router>
    <ThemeProvider primaryColor={schoolConfig ? schoolConfig.schoolColour : undefined}>
      <JobJumpMenu
        loggedIn={userDetails !== undefined}
        setUserDetails={setUserDetails}
        logout={logout}
        logo={logoUrl}
        schoolConfig={schoolConfig}
        schoolId={schoolId}
        userDetails={userDetails}
      />
      <div className="main-container">
        <ScrollToTopElem>
          <JobJumpRouter
              userDetails={userDetails}  
              setUserDetails={setUserDetails}
              schoolConfig={schoolConfig}
              logoUrl={logoUrl}
              heroConfig={heroConfig}
              schoolId={schoolId}
              isInSubDomain={isInSubDomain} />
        </ScrollToTopElem>
      </div>
      <JobJumpFooter />
    </ThemeProvider>
  </Router>; 
}

export default App;
