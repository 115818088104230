import React from "react";
import {Badge} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type Data = {[k:string]:any};

interface LabelGroupProps {
    data : (Data|string)[],
    onDelete : (d:Data|string)=>void,
    textKey? : keyof Data
}

const textForElement = (data:(Data|string), lookup:keyof Data|undefined) : string|undefined =>
    typeof data === "string" ? data : data[lookup || ""] 

const LabelGroup : React.FC<LabelGroupProps> =
    ({data, onDelete, textKey}) => 
        <>
            {data.map(d =>
                <Badge pill variant="light" key={textForElement(d, textKey)}>
                    {textForElement(d, textKey)}&nbsp;<FontAwesomeIcon icon={faTimes} onClick={()=>onDelete(d)} />
                </Badge>
            )}
        </>

export default LabelGroup;