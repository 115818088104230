import React from "react";
import {Row, Col} from "../../../components/grid";
import { Accordion} from "react-bootstrap";
import { Student, TertiaryEmailInfo } from "jobjump-types/CaStudents";
import CaStudentDetails  from "../../../data/CaStudentDetails";
import ContactInstsModal from "./ContactInstsModal";
import { AtarResult } from "jobjump-types/ATAR";
import {CoursePreferences} from "jobjump-types/Courses";
import {getMostRelevantOffering} from "../../tafe_courses/CourseListing";
import TafeContactModal from "../../tafe_courses/ContactModal";
import { TafeCourseEntry, TafeCourseOffering } from "jobjump-types/TAFE";
import { filterOfferingList } from "../../../data/TAFE";
import CardTable from "../../../components/CardTable";
import { IconMailOpened, IconQuestionMark } from "@tabler/icons";
import Button from "../../../components/Button";

interface AtarPrefsProps {
    studentDetails : Student
}

const AtarPrefs : React.FC<AtarPrefsProps> =
    ({studentDetails}) => {
        const [atarPrefs, setAtarPrefs] = React.useState<CoursePreferences[]>([]);
        const [sendRecord, setSendRecord] = React.useState<string[]>([]);
        const [selectedCourses, setSelectedCourses] = React.useState<AtarResult[]>([]);
        const [showContactModal, setShowContactModal] = React.useState(false);
        const [tertiaryEmailInfo, setTertiaryEmailInfo] = React.useState<TertiaryEmailInfo>();
        const [showTafeContactModal, setShowTafeContactModal] = React.useState(false);
        const [tafeCourse, setTafeCourse] = React.useState<TafeCourseEntry>();
        React.useEffect(() => {
            CaStudentDetails.studentIndvidualAtarPreferences(studentDetails.id).then(setAtarPrefs);
        }, [studentDetails]);
        React.useEffect(() => {
            CaStudentDetails.studentTertiarySendRecord(studentDetails.id).then(setSendRecord);
        }, [studentDetails]);
        React.useEffect(() => {
            CaStudentDetails.tertiaryEmailInfo().then(setTertiaryEmailInfo);
        }, []);
        if (!tertiaryEmailInfo) { return <></> }
        return <Row className="mt-16"><Col>
            {atarPrefs.length > 0 && <Row>
                <Col>
                    <CardTable
                        data={atarPrefs} 
                        selectFns={{
                            onSelect:(t)=> {
                                if (t.tafeCourse || 
                                    (t.uniCourse && 
                                        !tertiaryEmailInfo.participatingInsts.includes(t.uniCourse.institution))) { 
                                        return; 
                                }
                                if (!t.uniCourse) {return;}
                                if (selectedCourses.find(s => t.uniCourse && s.id === t.uniCourse.id) !== undefined) {
                                    setSelectedCourses(s=>s.filter(i => t.uniCourse && i.id !== t.uniCourse.id))
                                } else {
                                    setSelectedCourses(s=>s.concat([t.uniCourse as AtarResult]))
                                }
                            },
                            haveSelection:()=>selectedCourses.length>0,
                            isSelected:(t)=> {
                                if (t.tafeCourse) {
                                    return false;
                                }
                                if (t.uniCourse) {
                                    return selectedCourses.find(s => s.id === (t.uniCourse as AtarResult).id) !== undefined;
                                }
                                return false;
                            },
                            selectNone:()=>setSelectedCourses([]),
                            selectAll:()=>{
                                const all = atarPrefs .filter(p => p.uniCourse && tertiaryEmailInfo.participatingInsts.includes(p.uniCourse.institution))
                                    .map(a => a.uniCourse as AtarResult);
                                setSelectedCourses(all);
                            },
                            allSelected:()=>{
                                const num = atarPrefs .filter(p => p.uniCourse && tertiaryEmailInfo.participatingInsts.includes(p.uniCourse.institution)).length;
                                return selectedCourses.length === num;
                            }
                        }}
                        columns={[
                            {
                                Header: "Rank",
                                id : "order",
                                width: 1,
                                Cell : ({row : {original : {order}}}) => Number(order) + 1
                            },
                            {
                                Header : "Code",
                                id : "courseID",
                                width:1,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => {
                                    if (uniCourse) {
                                        return <a href={uniCourse.url} rel="noopener noreferrer" target="_blank">{uniCourse.id}</a>
                                    } else if (tafeCourse) {
                                        const offering = getMostRelevantOffering(tafeCourse);    
                                        return <>
                                            {tafeCourse.tafe_id} {offering && <IconQuestionMark className="circle-icon" onClick={() => {
                                                setTafeCourse(tafeCourse);
                                                setShowTafeContactModal(true);
                                            }} />}
                                        </>
                                    }
                                    return <></>
                                }
                            },
                            {
                                Header : "Course Name",
                                width:3,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => {
                                    if (uniCourse) { return <>{sendRecord.includes(uniCourse.id) && <IconMailOpened />} {uniCourse.name}</>; }
                                    if (tafeCourse) {
                                        const now = new Date(Date.now());
                                        const offering = filterOfferingList(tafeCourse.offerings)
                                        if (offering.length > 1) {
                                            return <Accordion>
                                                <Accordion.Toggle as="span" eventKey={`${tafeCourse.jj_id}-links`}>
                                                    <a style={{color:"#007bff", cursor:"pointer"}}>{tafeCourse.course_name}</a><br />
                                                    <small>({offering.length} study modes)</small>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey={`${tafeCourse.jj_id}-links`}>
                                                    <ul>{offering.map((o:TafeCourseOffering) => 
                                                        <li><a href={o.url} target="_blank" rel="noopener noreferrer">
                                                            {o.mode}&nbps;
                                                            {o.anytime ? 
                                                                "(Anytime)" : 
                                                                    (new Date(o.startDate)).getMilliseconds() < now.getMilliseconds() ? "" : `(Start: ${o.startDate})`
                                                            }
                                                        </a></li>
                                                    )}</ul>
                                                </Accordion.Collapse>
                                            </Accordion>
                                        }
                                        if (offering.length === 1) {
                                            return <a href={offering[0].url} target="_blank" rel="noopener noreferrer">
                                                {tafeCourse.course_name}
                                            </a>
                                        }
                                        return <span>{tafeCourse.course_name}<br /><small>No current study modes</small></span>;
                                    }
                                    return <></>;
                                }
                            },
                            {
                                Header : "Uni/TAFE",
                                width:2,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => 
                                    uniCourse ? uniCourse.institution : (tafeCourse ? "TAFE" : "")
                            },
                            {
                                Header : "Campus",
                                width:3,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => 
                                    uniCourse ? uniCourse.campus : (tafeCourse ? tafeCourse.college : "")
                            },
                            {
                                Header : "State",
                                width:1,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => 
                                    uniCourse ? uniCourse.state : "NSW" 

                            },
                            {
                                Header : "ATAR",
                                width:1,
                                Cell : ({row : {original : {tafeCourse, uniCourse}}}) => 
                                    uniCourse ? uniCourse.atar : "" 

                            },
                        ]}
                        renderToolbar={()=><Row>
                            <Col className="text-right">
                                <Button variant="active" onClick={()=>setShowContactModal(selectedCourses.length > 0)}>
                                    Contact Selected Institutes
                                </Button>
                            </Col>
                        </Row>}
                    />
                </Col>
            </Row>}
            {atarPrefs.length === 0 && <Row>
                <Col>
                    <p>Student has not made a course selection yet.</p>
                </Col>
            </Row>}
            <ContactInstsModal 
                show={showContactModal} 
                onHide={()=>setShowContactModal(false)} 
                studentDetails={studentDetails}
                courseSelections={selectedCourses}
                setCourseSelections={setSelectedCourses}
                sendingDetails={tertiaryEmailInfo}
                onSuccess={() => {
                    const newCourses = selectedCourses.map(c => c.jj_id);
                    setSendRecord(sendRecord.concat(newCourses));
                    setSelectedCourses([]);
                }}
            />
            {tafeCourse && <TafeContactModal
                show={showTafeContactModal}
                onHide={()=>setShowTafeContactModal(false)}
                course={tafeCourse} />}
        </Col></Row>;
    }

export default AtarPrefs;