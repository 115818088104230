import {CaNewsItem} from "jobjump-types/CaNews";

const JsonPost = {
    method:"POST",
    headers: {
        "Content-Type": "application/json"
    }
}
interface NewsInput {
    title: string,
    body: string,
    years: number[],
    careers: number[],
    date: string,
    dontSend: boolean
}

const CaSchoolNews = {
    addNewsItem : (args:NewsInput) : Promise<void> =>
        fetch("/api/advisers/homepage/news", {...JsonPost, body:JSON.stringify(args)}).then(r=>{}),
    updateNewsItem : (args:Omit<NewsInput, "dontSend">, id:number) : Promise<void> =>
        fetch("/api/advisers/homepage/news/"+String(id), {...JsonPost, body:JSON.stringify(args)}).then(r=>{}),
    deleteNewsItem : (id:number) : Promise<void> => 
        fetch("/api/advisers/homepage/news/"+String(id), {method:"DELETE"}).then(r=>{}),
    getNews : () : Promise<CaNewsItem[]> =>
        fetch("/api/advisers/homepage/news").then(r=>r.json()),
};

export default CaSchoolNews;