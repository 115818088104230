import React from "react";
import { makeClassName } from "../util";
import "./baseCard.css";

export type BaseCardProps = React.ComponentProps<"div">;

const BaseCard : React.FC<BaseCardProps> = ({className, children, ...props}) =>
    <div className={makeClassName(className, ["jobjump-base-card"])} {...props}>
        {children}
    </div>

export default BaseCard;