import React from "react";

type TextAreaProps = {
    onChange: (value:string)=>void
} & Omit<React.ComponentProps<"textarea">, "onChange">;

const TextArea : React.FC<TextAreaProps> = ({className, onChange, ...props}) => 
    <textarea className={"jobjump-form-component "+className} onChange={({currentTarget:{value}})=>onChange(value)} {...props}>
    </textarea>

export default TextArea;