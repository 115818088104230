import React from 'react';
import {Row, Col} from "../components/grid";

const Order : React.FC<{}> =
() =>
<><Row><Col>
  <h2>Order JobJump</h2>
  <p>
    JobJump covers your students from Year 7 to Year 13, their parents, support people to the student 
    (e.g. older siblings), and all your staff. We support multiple careers advisers and other nominated
    support staff for the Careers Advisers' Dashboard at no extra cost.</p> 
  <p>
    Included is delivery of latest news in bulletin format. Master (uni, TAFE, colleges and general) and Subject
    Careers News parceled into subject KLAs.
  </p>
  <p>
    When students register, careers news from the bulletins on their chosen careers is emailed to them when the
    news occurs to the end of Year 13.
  </p>
  <p>
    JobJump is priced based on your school size. Please see the table below for your school's price.
  </p>
  <table className="table-striped">
    <thead>
      <tr>
        <th>School Size</th>
        <th>Price</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>500+ Students</td>
        <td>$400 Total (inc GST)</td>
      </tr>
      <tr>
        <td>250-500 Students</td>
        <td>$235 Total (inc GST)</td>
      </tr> 
      <tr>
        <td>Below 250</td>
        <td>$170 Total (inc GST)</td>
      </tr> 
    </tbody> 
  </table>
  <h4 className="mt-16">To order JobJump, contact us (<a href="mailto:contact@jobjump.com.au">contact@jobjump.com.au</a>)</h4>
</Col></Row></>

export default Order;