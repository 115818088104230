import React from "react";
import CardGrid from "../components/CardGrid";
import ImageCard from "../components/cards/ImageCard";
import { Col } from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";
import { openLinkInNewTab } from "../util";
import FirstAid1 from "../assets/homepage/tiles/university-ucat.jpg";
import FirstAid2 from "../assets/homepage/tiles/learning-cpr-and-first-aid.jpg";
import ContentWrapper from "../components/ContentWrapper";

const FirstAid : React.FC = () =>
    <ContentWrapper
            title="First Aid Courses" 
            subtitle={["Want or need a First Aid certificate? Check out the providers below."]} >
        <PageSectionRow>
            <Col>
                <h4>Select from the following First Aid training providers:</h4>
            </Col>
        </PageSectionRow>
        <CardGrid prefix="first-aid" numPerRow={2}>
            <ImageCard imageUrl={FirstAid1} text="St John" onClick={()=>openLinkInNewTab("http://stjohn.org.au/first-aid-training")} />
            <ImageCard imageUrl={FirstAid2} text="Red Cross" onClick={()=>openLinkInNewTab("https://firstaid.redcross.org.au/provide-first-aid/")} />
        </CardGrid>
    </ContentWrapper>

export default FirstAid;