import { IconBulb, IconEye } from "@tabler/icons";
import React from "react";
import { SubsectionProps, SubsectionTemplate } from "./common";

const EWorkExperienceSection : React.FC<SubsectionProps<"eWorkExperience">> = ({section, sectionConfig, setSectionConfig}) =>
    <SubsectionTemplate title="eWork Experience Project"
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}>
            <div className="mb-16"><small>
                <IconBulb /> Your eWork Experience Project has been automatically added here for inclusion in your resume. If you wish to remove it, you can use the <IconEye /> icon above to hide it.
            </small></div>
        <div>
            In my eWork Experience project I:
            <ul>
                {section.map(m => <li key={`ework-res-${m.id}`}>{m.text}</li>)}
            </ul>
        </div>
    </SubsectionTemplate>

export default EWorkExperienceSection;