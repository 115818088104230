import React from "react";
import {Row, Col} from "../../components/grid";
import { AtarCategory, AtarResult } from "jobjump-types/ATAR";
import AtarAPI from "../../data/ATAR";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";

import ATARTable from "./ATARTable";
import { IconPrinter } from "@tabler/icons";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";

type Props = {
    kind: "scholarships"|"plan-b"
} & RouteComponentProps<{category_id:string}> & UserDetailsProps

const ContactUniListing: React.FC<Props> =
    (props) => {
        const  {match} = props;
        const category = match.params.category_id;
        const [data, setData] = React.useState<AtarResult[]>([]);
        const [chosenStates, setChosenStates] = React.useState<string[]>([]);
        const [atarCategory, setAtarCategory] = React.useState<AtarCategory>();

        React.useEffect(() => {
            AtarAPI.getAtarCategories().then(r => setAtarCategory(r.find(c=>String(c.id)===category)));
        }, [category]);

        React.useEffect(() => {
            if (chosenStates.length === 0) {
                return;
            }
            AtarAPI.getAtarList(category, chosenStates).catch(e=>{console.log(e); return []}).then((d: AtarResult[]) => {
                let data = d;
                setData(data)
            });
        }, [category, chosenStates]);

        const title = props.kind === "scholarships" ? "Scholarships" : "Plan B";
        const lower = props.kind === "scholarships" ? "scholarships" : "Plan B";

        return <ContentWrapper
                title={`${title} ${atarCategory ? `For ${atarCategory.name}` : ""}`}
                subtitle={[`Find your course below to get more information about ${lower}.`]}
                breadcrumbs={[
                    {name:title, link:`/atar/${props.kind}`},
                ]}>
            <ATARTable showDetailsOf={props.kind} showSearch data={data} chosenStates={chosenStates} setChosenStates={setChosenStates} {...props} />
            <Row>
                <Col>
                    <small><strong>Acknowledgement</strong></small><br />
                    <small>Data for employment rates is provided by Australian Government Department of Education, Skills and Employment, which fund the QILT suite of surveys program.</small>
                </Col>
            </Row>
        </ContentWrapper>
    }

export default ContactUniListing;