import React from "react";
import { makeClassName } from "../util";
import BaseCard, { BaseCardProps } from "./BaseCard";
import "./card-button.css";

type Props = {
    onClick?:()=>void,
    variant?:"active"|"negative",
    isSelected?:boolean,
}

const CardButton : React.FC<BaseCardProps & Props> = ({variant, onClick, className, isSelected, children, ...props}) => {
    const newClassName = makeClassName(className, [
        "jobjump-card-button",
        "jobjump-card-button-"+variant,
        isSelected ? "jobjump-card-button-selected" : ""
    ]);
    return <BaseCard className={newClassName} onClick={onClick} {...props}>
        {children}
    </BaseCard>
}
export default CardButton;