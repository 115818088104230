import { fetchWithAuth } from "./Utils";

export interface UniContentCategory {
    id:number,
    name:string
}

export interface UniContentEntry {
    title:string,
    url:string,
    image:string,
    institute:string,
    career:string
}

export type ContentKind = "stories_video"

const Uni = {
    getCategories : () : Promise<UniContentCategory[]> =>
        fetchWithAuth("/api/uni/categories").then(r => r.json()).catch(console.log),
    getContent : (kind:ContentKind, id:number) : Promise<UniContentEntry[]> =>
        fetchWithAuth(`/api/uni/content/${kind}/${id}`).then(r => r.json()).catch(console.log),
    getContentForInst : (id:number) : Promise<UniContentEntry[]> =>
        fetchWithAuth(`/api/uni/content/uni/${id}`).then(r => r.json()).catch(console.log),
}

export default Uni;