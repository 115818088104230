import React from "react";
import ReactPlayer from "react-player";
import { RouteComponentProps } from "react-router-dom";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { openLinkInNewTab } from "../../util";

type Props = {
    title:string,
    blurb:string[],
    videoId?:string,
    worksheetLink?:string,
    completionText?:string,
    links : {text:string, externalLink?:string, internalLink?:string, onClick?:()=>void, imgUrl?:string}[]
}

const WorkCheckSubpageTemplate : React.FC<RouteComponentProps & Props> = ({title, blurb, videoId, worksheetLink, completionText, links, history}) => 
    <ContentWrapper breadcrumbs={[{name:"Pre-Work Check", link:"workCheck"}]} title={title} subtitle={blurb}>
        {videoId && <PageSectionRow>
            <Col>
                <ReactPlayer width="95%" url={`https://vimeo.com/${videoId}`} controls />
            </Col>
        </PageSectionRow>}
        {worksheetLink && <PageSectionRow>
            <Col className="text-center">
                <h4>Complete this activity using the this worksheet and the links below.</h4>
                <Button variant="active" className="mr-8" onClick={()=>openLinkInNewTab(worksheetLink)}>Download Worksheet</Button>
            </Col>
        </PageSectionRow>}
        {links.length > 0 && <PageSectionRow className="text-center">
            <Col>
                <h3>Get Started</h3>
                <div className="mb-32">Use the links below to complete the activity in the worksheet.</div>
                {completionText && <div className="mb-16">To mark this activity as complete you must attempt the worksheet and {completionText}.</div>}
                <CardGrid numPerRow={3} prefix="work-check">
                    {links.map(({text, externalLink, internalLink, onClick, imgUrl}, i)=>
                        <ImageCard
                            imageUrl={imgUrl}
                            text={text}    
                            onClick={()=> externalLink ? openLinkInNewTab(externalLink) :
                                internalLink ? history.push(internalLink) : 
                                onClick ? onClick() : undefined} />
                    )}
                </CardGrid>
                
            </Col>
        </PageSectionRow>}
        {!videoId && !worksheetLink && links.length === 0 && <PageSectionRow>
            <Col className="text-center">
                <h3>Coming Soon</h3>
            </Col>
        </PageSectionRow>}
    </ContentWrapper>


export default WorkCheckSubpageTemplate