import { StudentLegacyPlanListing } from "jobjump-types/CaStudents";
import {GeneralResume, CareerResume, ResumeKinds} from "jobjump-types/OldResume";
import qs from "query-string";
import { fetchWithAuth } from "./Utils";

export default {
    getAllLegacyPlans : () : Promise<StudentLegacyPlanListing[]> => 
        fetchWithAuth(`/api/advisers/plan/`).then(r=>r.text()).then(s=> JSON.parse(s)),
    getStudentLegacyCareerPlan : (user_id:string, career_id?:string, category_id?:string) : Promise<CareerResume> => {
        const kind = career_id ? "career" : category_id ? "category" : "unsure";
        const id = kind === "career" ? career_id : category_id;
        return fetchWithAuth(`/api/advisers/plan/loadSpecific/${user_id}/${kind}/${id}`).then(r=>r.text()).then(s=> JSON.parse(s))
    },
    getStudentLegacyGeneralPlan : (user_id:string) : Promise<GeneralResume> => 
        fetchWithAuth(`/api/advisers/plan/loadGeneral/${user_id}`).then(r=>r.text()).then(d=> { return JSON.parse(d); })
    ,
    saveLegacyPlan : (
        general_info:GeneralResume, 
        career_info:CareerResume, 
        kind:ResumeKinds, 
        user_id:string, 
        category_id:string|undefined, 
        career_id:string|undefined
    ) : Promise<{}> => 
       fetchWithAuth(`/api/advisers/plan/save/${user_id}/${kind}/${kind === "career" ? career_id : category_id}?${qs.stringify({general_info:JSON.stringify(general_info), career_info:JSON.stringify(career_info)})}`),

};
