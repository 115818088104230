import React from "react";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import PageSectionRow from "../../components/PageSectionRow";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "../../components/grid";
import { StudentCwizResult } from "jobjump-types/CaStudents";
import { IconDiamond, IconSearch } from "@tabler/icons";
import Cwiz, { CwizResult } from "../../data/Cwiz";
import Modal from "../../components/Modal";
import CardTable from "../../components/CardTable";
import _ from "lodash";

const CaCwizResults : React.FC<RouteComponentProps> =
    (props) => {
        const [data, setData] = React.useState<StudentCwizResult[]>([]);
        const [quizResultToShow, setQuizResultToShow] = React.useState<StudentCwizResult>();
        const [chosenQuizResponse, setChosenQuizResponse] = React.useState<CwizResult[]>([]);
        React.useEffect(() => {
            CaStudentDetails.getQuizResults().then(setData);
        }, []);
        React.useEffect(() => {
            if (!quizResultToShow) {
                setChosenQuizResponse([]);
                return;
            }
            Cwiz.getCwizResult({
                personality:quizResultToShow.personality_id,
                tertiary:quizResultToShow.tertiary_id,
                category:quizResultToShow.category_id,
                subject:quizResultToShow.subject_id,
                skills:quizResultToShow.skills,
                values:quizResultToShow.values}).then(r=>setChosenQuizResponse(r.sort((a,b)=> {
                    if (a.count === b.count) {
                        if (a.career_name < b.career_name) {
                            return -1;
                        } else if (a.career_name > b.career_name) {
                            return 1;
                        }
                        return 0
                    } else if (a.count > b.count) {
                        return -1;
                    } else {
                        return 1
                    }
                })));
        }, [quizResultToShow])
        return <CaAdminLayout active="careers" title="Career Wiz Quiz Results" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Career Quiz Results</h3>
                    <span>Below are the results of the Career Wiz Quiz for your students.</span>
                    <span>You can search the various categories to find students, or use the <IconSearch /> button to see an individual's results in detail.</span>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        hideEmail
                        additionalColumns={[
                                {
                                    Header:"Field", 
                                    accessor:"category_name"
                                },
                                {
                                    Header:"Personality", 
                                    accessor:"personality_name"
                                },
                                {
                                    Header:"Subject", 
                                    accessor:"subject_name"
                                },
                                {
                                    Header:"Tertiary",
                                    accessor:"tertiary_name"
                                },
                                {
                                    id:"seeMore",
                                    Cell:({row:{original}})=><IconSearch className="pointer" onClick={()=>setQuizResultToShow(original)} />
                                }
                        ]} />
                </Col>
            </PageSectionRow>
            <Modal title="Career Wiz Quiz Result" show={quizResultToShow !== undefined} onHide={()=>setQuizResultToShow(undefined)}>
                <Row>
                    <Col>
                        <h4>Career Field:</h4>{quizResultToShow?.category_name || "Not Selected"}
                    </Col>
                    <Col>
                        <h4>Personality:</h4>{quizResultToShow?.personality_name || "Not Selected"}
                    </Col>
                    <Col>
                        <h4>Favourite Subject:</h4>{quizResultToShow?.subject_name || "Not Selected"}
                    </Col>
                    <Col>
                        <h4>Tertiary Level:</h4>{quizResultToShow?.tertiary_name || "Not Selected"}
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col>
                        <h4>Skills:</h4>
                        <ul>
                            {(quizResultToShow?.skills || []).map(s => <li key={s}>{s}</li>)}
                        </ul>
                    </Col>
                    <Col>
                        <h4>Values:</h4>
                        <ul>
                            {(quizResultToShow?.values || []).map(v => <li key={v}>{v}</li>)}
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col>
                        <h4>Career Matches:</h4>
                        <CardTable
                            hideHeader
                            data={chosenQuizResponse}
                            columns={[
                                {
                                    id:"diamonds",
                                    Cell:({row:{original}})=><div>
                                        {Array(Number(original.count)).fill(0).map(i=><IconDiamond className="cwiz-svg" key={`${original.count}-${i}`} />)}
                                    </div>,
                                    width:2
                                },
                                {
                                    id:"name",
                                    Cell:({row:{original}})=><h4>{original.career_name}</h4>,
                                    width:5,
                                }
                            ]} />
                    </Col>
                </Row>
            </Modal>
        </CaAdminLayout>;
    }

export default CaCwizResults;