import React from "react";
import { Row } from "../grid";
import { makeClassName } from "../util";
import "../cards/baseCard.css";
import "./rowCard.css";

const RowCard : React.FC<React.ComponentProps<"div"> & {selected?:boolean, selectable?:boolean}> = ({children, className, selected, selectable, ...props}) => {
    const classStr = makeClassName(className, [
        "mb-16",
        "jobjump-base-card",
        "jobjump-row-card",
        selectable ? "jobjump-row-card-selectable" : "",
        selected ? "jobjump-row-card-selected" : ""
    ]);
    return <Row className={classStr} {...props}>{children}</Row>
}

export default RowCard;