import React from "react";
import ReactEcharts from 'echarts-for-react';
import CaAdminLayout from "./CaAdminLayout";
import {Row, Col} from "../../components/grid";
import Button from "../../components/Button";
import { Form, Modal, Table } from "react-bootstrap";
import { DestinationSurveyResult,DestinationNames, prettyDestinationName} from "jobjump-types/DestinationSurvey";
import CaDestinationSurvey from "../../data/CaDestinationSurvey";
import { useTable, useFilters, useSortBy, Column } from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp, faSort } from "@fortawesome/free-solid-svg-icons";
import XLSX from "xlsx";
import { RouteComponentProps } from "react-router";
import PageSectionRow from "../../components/PageSectionRow";
import { IconTrash } from "@tabler/icons";

type DestinationTable = {[K in DestinationNames]:number};

const emptyDestinationTable : DestinationTable = {
    "University" : 0,
    "TAFE" : 0,
    "Private College" : 0,
    "Apprenticeship" : 0,
    "Traineeship" : 0,
    "Full-Time Employment" : 0,
    "Part-Time Employment" : 0,
    "Job Seeking" : 0,
    "Gap Year" : 0,
    "Other" : 0
}

type AtarTableCategories = 
    | "95+"
    | "95-90"
    | "90-85"
    | "85-80"
    | "80-75"
    | "75-70"
    | "70-65"
    | "65-60"
    | "60-55"
    | "55-50"
    | "Below 50"
    | "No ATAR"

type ATARTable = {[K in AtarTableCategories]:number}

const emptyAtarTable : ATARTable = {
    "95+": 0,
    "95-90": 0,
    "90-85": 0,
    "85-80": 0,
    "80-75": 0,
    "75-70": 0,
    "70-65": 0,
    "65-60": 0,
    "60-55": 0,
    "55-50": 0,
    "Below 50": 0,
    "No ATAR": 0,
}

const SummaryTable : React.FC<{titles:string[], data:[string,number][]}> =
    ({titles, data}) =>
        <Table>
            <thead>
                {titles.map(t => <th key={t}>{t}</th>)}
            </thead>
            <tbody>
                {data.map(([s,v] : [string,number]) =>
                    <tr><td>{s}</td><td>{v}</td></tr>
                )}
            </tbody>
        </Table>

const generateSpreadsheet = (year:number, atarTable:ATARTable, destinationTable:DestinationTable, results:DestinationSurveyResult[]) => {
    const atarSheet = [["ATAR Range", "Count"]].concat(Object.entries(atarTable).map(([k,v])=>[k,String(v)]));
    const destSheet = [["Destination", "Count"]].concat(Object.entries(destinationTable).map(([k,v])=>[k,String(v)]));
    const summarySheetData = [
        ...destSheet,
        [],
        ...atarSheet
    ];
    const destinationSheetData = [
        ["First Name", "Last Name", "Aboriginal", "Destination", "Course Name", "Course Kind", 
            "Course Institute", "Course Category", "Scholarship", "Scholarship Name", "Early Offer", 
            "Work Kind", "Employer", "Other Response", "Received ATAR", "ATAR"]
        ].concat(results.map(({first_name, last_name, aboriginal, response_data}) => [
            first_name, last_name, JSON.stringify(aboriginal), response_data.destination_kind, response_data.course_name,
            response_data.course_kind, response_data.course_inst, response_data.course_category, response_data.scholarship,
            response_data.scholarship_name, response_data.early_offer, response_data.work_kind, response_data.employer, response_data.other_answer,
            response_data.got_atar, response_data.atar_value 
        ]))

    const vetSheetData = [
        ["First Name", "Last Name", "VET Subjects", "EVET Name", "Related to Destination", "VET Helped Entry", "VET Credit Transfer"]
    ].concat(results.filter(r => r.response_data.studied_vet !== "").map(({first_name, last_name, response_data})=>
        [first_name, last_name, response_data.vet_subjects.join(","), response_data.evet_name, response_data.vet_relate, response_data.vet_help_entry, response_data.vet_credit_transfer]
    ));

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(summarySheetData), "01 Summary");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(destinationSheetData), "02 Destination");
    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(vetSheetData), "03 VET");

    XLSX.writeFile(wb, `${year} Destination Survey.xlsx`);
}

type DetailsTableProps = {
    data: DestinationSurveyResult[],
    deleteData: (id:string)=>void,
}

const DestinationDetailsTable : React.FC<DetailsTableProps> =
    ({data, deleteData}) => {
        const [deleteCandidate, setDeleteCandidate] = React.useState<string>();
        const columns  = [
            {accessor:"first_name",Header:"First Name"},
            {accessor:"last_name",Header:"Last Name"},
            {
                accessor:(d:DestinationSurveyResult)=>
                    prettyDestinationName(d.response_data.destination_kind), 
                Header: "Destination"
            },
            {
                accessor:(d:DestinationSurveyResult)=>
                    d.response_data.course_inst !== "" ? d.response_data.course_inst :
                        d.response_data.employer,
                Header:"Place of Study/Work"
            },
            {
                accessor:({response_data:{course_category}}:DestinationSurveyResult) =>
                    course_category === "Please select from below" ? "" : course_category,
                Header:"Study/Work Type"
            },
            {
                accessor:({response_data:{course_name, other_answer, work_kind, course_kind}}:DestinationSurveyResult)=> 
                    course_name !== "" ? (`${course_kind} ${course_name}`) :
                        other_answer !== "" ? other_answer : work_kind,
                Header: "Details"
            },
            {
                accessor:"response_data.atar_value",
                Header: "ATAR"
            },
            {
                id: "delete",
                Cell: ({row:{original}}:{row:{original:DestinationSurveyResult}}) => <>
                    <Button variant="active" textButton onClick={()=>setDeleteCandidate(original.id)}>
                        <IconTrash />
                    </Button>
                </>
            }
        ];
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable<DestinationSurveyResult>({ columns, data, }, useFilters, useSortBy);
        return <React.Fragment>
            <Table {...getTableProps()} striped bordered hover>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                    <div>{column.canFilter ? 
                                        column.Filter ? column.render('Filter') :
                                            <input type="text" style={{width:"100%"}} value={column.filterValue}
                                                onChange={(e:React.FormEvent<HTMLInputElement>) =>
                                                    column.setFilter(e.currentTarget.value)} />
                                    :<></>}</div>
                                    <span>
                                        &nbsp;{column.isSorted
                                            ? column.isSortedDesc
                                                ? <FontAwesomeIcon icon={faSortDown} />
                                                : <FontAwesomeIcon icon={faSortUp} />
                                            : <FontAwesomeIcon icon={faSort} />}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (<>
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        </>)
                    })}
                </tbody>
            </Table>
            <Modal show={deleteCandidate !== undefined} onHide={()=>setDeleteCandidate(undefined)}>
                <Row className="mt-32">
                    <Col>
                        <h4>Are you sure you want to delete this record?</h4>
                    </Col>
                </Row>
                <Row className="mb-32">
                    <Col className="text-right">
                        <Button variant="neutral" onClick={()=>setDeleteCandidate(undefined)} className="mr-8">Cancel</Button>
                        <Button variant="negative" onClick={()=>{deleteCandidate && deleteData(deleteCandidate); setDeleteCandidate(undefined);}}>Delete</Button>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>;
    }

// TODO: The first and last year of results should be reported by the backend
const surveyStartYear = 2017;
const mostRecentSurveyYear = new Date(Date.now()).getFullYear() - 1;

const CaDestinationSurveyResults : React.FC<RouteComponentProps> = (props) => {
    const [resultYear, setResultYear] = React.useState(mostRecentSurveyYear);
    const [surveyResults,setSurveyResults] = React.useState<DestinationSurveyResult[]|undefined>();
    const [destinationSummaryTable, setDestinationSummaryTable] = React.useState<DestinationTable>();
    const [atarSummaryTable, setAtarSummaryTable] = React.useState<ATARTable>();
    const [surveyLink, setSurveyLink] = React.useState<string>();
    React.useEffect(() => {
        CaDestinationSurvey.getSchoolDestinationSurveyLink().then(l => {
            if (l && l !== "") {
                setSurveyLink(`https://jobjump.com.au${l}`);
            }
        });
    }, []);
    const getData = React.useCallback(() => {
        CaDestinationSurvey.getSurveyResults(resultYear).then(results => {
            if (!results) { 
                setSurveyResults(undefined); 
                setDestinationSummaryTable(undefined);
                setAtarSummaryTable(undefined);
                return;
            }
            setDestinationSummaryTable(results.reduce((a, r) => {
                const dest : DestinationNames = prettyDestinationName(r.response_data.destination_kind);
                a[dest] += 1;
                return a;
            }, {...emptyDestinationTable}));
            setAtarSummaryTable(results.reduce((a, r) => {
                const atar = Number(r.response_data.atar_value);
                if (isNaN(atar)) {
                    a["No ATAR"] += 1;
                } else if (atar > 95) {
                    a["95+"] += 1;
                } else if (atar > 90) {
                    a["95-90"] += 1
                } else if (atar > 85) {
                    a["90-85"] += 1
                } else if (atar > 80) {
                    a["85-80"] += 1
                } else if (atar > 75) {
                    a["80-75"] += 1
                } else if (atar > 70) {
                    a["75-70"] += 1
                } else if (atar > 65) {
                    a["70-65"] += 1
                } else if (atar > 60) {
                    a["65-60"] += 1
                } else if (atar > 55) {
                    a["55-50"] += 1
                } else {
                    a["Below 50"] += 1
                } 
                return a;
            }, {...emptyAtarTable}))
            setSurveyResults(results);
        })
    }, [resultYear]);

    React.useEffect(() => getData(), [getData])

    const deleteData = React.useCallback((id:string) => {
        CaDestinationSurvey.deleteRecord(id).then(()=>getData());
    }, [getData]);

    const exportClick = React.useCallback(() => {
        if (atarSummaryTable && destinationSummaryTable && surveyResults) {
            generateSpreadsheet(resultYear, atarSummaryTable, destinationSummaryTable, surveyResults);
        }
    }, [resultYear, atarSummaryTable, destinationSummaryTable, surveyResults]);
    return <CaAdminLayout active="destination" title="Destination Survey" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Your School Destination Survey</h3>
                    <span>Here you can see the results and manage your school's destination survey.</span>
                    {surveyLink && <span>Your school's suvery URL is: <a href={surveyLink}>{surveyLink}</a>. You can share this URL with students who may not have a JobJump account, 
                        or no longer have access to their registered email address. You can also use this link to manually enter responses.</span>}
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col sm={8}><h3>Destination Survey Results for {resultYear} leavers</h3></Col>
                <Col>
                    <Form.Group>
                        <label>Show results for year:</label>
                        <Form.Control as="select" value={String(resultYear)}
                            onChange={e => setResultYear(Number(e.currentTarget.value))}>
                            {Array.from(Array(mostRecentSurveyYear - surveyStartYear + 1).keys()).map(i =>
                                <option key={`${i}-year-opt`} value={String(i + surveyStartYear)}>
                                    {i + surveyStartYear}
                                </option>
                            )}
                        </Form.Control>
                    </Form.Group>
                    {surveyResults && <Button variant="active" onClick={exportClick}>Export as Spreadsheet</Button>}
                </Col>
            </PageSectionRow>
            {surveyResults && atarSummaryTable && destinationSummaryTable && <><Row>
                <Col sm={6}>
                    <ReactEcharts
                        option={{
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {c} ({d}%)'
                            },
                            legend: {
                                orient: "horizontal",
                                type: "scroll",
                                bottom: 0
                            },
                            series: {
                                type: 'pie',
                                name: "",
                                label : {
                                    show: false
                                },
                                normal: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    show: false
                                },
                                labelLine: {
                                    normal: {
                                        show: false
                                    }
                                },
                                data: Object.entries(destinationSummaryTable).map(([k,v] : [string, number]) =>
                                    ({name:k as string, value:v})
                                )
                            }
                        }}
                    />
                </Col>
                <Col sm={6}>
                    <ReactEcharts
                        option={{
                            tooltip: {
                                trigger: 'item',
                                formatter: '{b} : {c}'
                            },
                            legend: {
                                orient: "horizontal",
                                type: "scroll",
                                bottom: 0
                            },
                            yAxis: {
                                type:"category",
                                data:(Object.keys(atarSummaryTable))
                            },
                            xAxis : {},
                            series: {
                                type: "bar",
                                name: "",
                                data: Object.keys(atarSummaryTable).map(k => atarSummaryTable[k as AtarTableCategories])
                            }
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={6}>
                    <SummaryTable titles={["Destination","Count"]} data={Object.entries(destinationSummaryTable)} />
                </Col>
                <Col sm={6}>
                    <SummaryTable titles={["ATAR Range","Count"]} data={Object.entries(atarSummaryTable)} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <DestinationDetailsTable data={surveyResults} deleteData={deleteData} />
                </Col>
            </Row></>}
            {!(surveyResults || atarSummaryTable || destinationSummaryTable) && <Row>
                <Col>
                    <h4>There are no destination results recorded for {resultYear}.</h4> 
                </Col>
            </Row>}
    </CaAdminLayout>;
}

export default CaDestinationSurveyResults;