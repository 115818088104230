import React from 'react';
import {Row, Col} from "../../components/grid";
import { useHistory } from 'react-router';
import ContentWrapper from '../../components/ContentWrapper';
import { CareerSubjectRecommendation } from 'jobjump-types/Subjects';
import CareerGrid from '../../components/CareerGrid';

const stringOfSubjectRecommendation = (s:CareerSubjectRecommendation) => {
    const list = !s.additional_subjects ? "" : (s.additional_subjects.length === 1 ? s.additional_subjects[0] : 
        [s.additional_subjects.slice(0, -1).join(', '), s.additional_subjects.slice(-1)[0]].join(s.additional_subjects.length < 2 ? '' : ' or '));
 
    if (s.kind === "single") {
        return s.subject_name || "";
    }
    if (s.kind === "option") {
        return `${s.content||""} ${list}`; 
    }
    if (s.kind === "scaled") {
        return `${s.subject_name||""} ${s.content||""} (${list})`;
    }
    if (s.kind === "text") {
        return s.content||"";
    }
}

const MySubjects : React.FC<{}> = () => {
    const history = useHistory();
    return (
        <ContentWrapper 
                title="Need to figure out what senior subjects to study?"
                breadcrumbTitle="Subjects"
                subtitle={["Select a career to find out some possible choices."]} >
            <Row><Col>
                <CareerGrid numPerRow={4} onCareerSelect={c => history.push("/subjectSelection/"+c.id)} />
            </Col></Row>
        </ContentWrapper>
    );
}

export default MySubjects;
export {stringOfSubjectRecommendation};