import React from "react";
import Button from "../../../components/Button";
import { Label, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { SubsectionProps, SubsectionTemplate, deleteFromList, updateObjectIndex, SortableItemTemplate } from "./common";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { TitleAndDetails } from "jobjump-types/Resume";
import arrayMove from "array-move";

type SortableListItems = TitleAndDetails & {
    originalOrder: number
};

const SortableItem = SortableElement(
    ({ data, onChange, onDelete }: {data:SortableListItems, onChange:(t:TitleAndDetails)=>void, onDelete:()=>void}) => {
        return <SortableItemTemplate onDelete={onDelete}>
            <Row className="mb-8">
                <Col>
                    <TextField value={data.title} onChange={v=>onChange({...data, title:v})} />
                </Col>
                <Col>
                    <TextField value={data.details} onChange={v=>onChange({...data, details:v})} />
                </Col>
            </Row>
        </SortableItemTemplate>
    }
)

const SortableList = SortableContainer(
    (props:{data:SortableListItems[], section:TitleAndDetails[], setSection:(r:TitleAndDetails[])=>void}) => <div>
        {props.data.map((t: SortableListItems, i: number) => {
            return <div id={`sortable-resume-item-${t.originalOrder}`} key={"key-" + t.originalOrder}>
                <SortableItem 
                    index={i} 
                    data={t} 
                    onChange={(t)=>props.setSection(updateObjectIndex(props.section, t, i))} 
                    onDelete={()=>props.setSection(deleteFromList(props.section, i))} />
            </div>
        })}
    </div>
);

const AwardsSection : React.FC<SubsectionProps<"awards">> = ({setSection, section, sectionConfig, setSectionConfig}) =>
    <SubsectionTemplate title="Awards"
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}
        buttons={[
            {text: "Award", onClick: ()=>setSection([...section, {title:""}])},
        ]}>
            <Row style={{paddingLeft:"56px", paddingRight:"56px"}}>
                <Col>
                    <Label>Title</Label>
                </Col>
                <Col>
                    <Label>Description</Label>
                </Col>
            </Row>
            <SortableList
                axis="xy"
                data={section.map((s,i) => ({...s, originalOrder:i}))}
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(section, oldIndex, newIndex);
                    setSection(order);
                }} 
                section={section}
                setSection={setSection} />
            {section.length === 0 && <Row className="mb-64 mt-64">
                <Col className="text-center">
                    <Button variant="active" onClick={()=>setSection(section.concat([{title:""}]))}>
                        Add Award
                    </Button>
                </Col>
            </Row>}
        </SubsectionTemplate>
    
export default AwardsSection;