import { IconHeart } from "@tabler/icons";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import { CareerPreference, CareerPrefKind } from "../../data/CareerPreference";
import { CwizStates } from "./common";

type Props = {
    prefs: CareerPreference[],
    deletePref: (kind:CareerPrefKind, id:number)=>Promise<void>,
    goToPage: (currentState:CwizStates)=>void
}

const CareerPrefsPage : React.FC<Props & RouteComponentProps> = ({prefs, deletePref, history, goToPage}) => {
    const [deleteCandidate, setDeleteCandidate] = React.useState<CareerPreference>();
    const doDelete = () => {
        if (!deleteCandidate) {
            return;
        }
        deletePref(deleteCandidate.kind, deleteCandidate.id).then(()=>setDeleteCandidate(undefined));
    }
    return <>
        <Row small>
            <Col className="text-center">
                <h4>Below are your current career choices. You can explore more about them, or update them if you change your mind.</h4>
            </Col>
        </Row>
        <Row style={{maxWidth:"1000px"}}>
            <Col className="mt-16">
                <CardTable
                    data={prefs}
                    columns={[
                        {
                            id:"heart",
                            Cell:(({row:{original}})=><IconHeart className="fill-icon" onClick={()=>setDeleteCandidate(original)} />),
                            width:1
                        },
                        {
                            id:"name",
                            Cell:(({row:{original}})=><h4>{original.name}</h4>),
                            width:9
                        },
                        {
                            id:"explore",
                            width:2,
                            Cell:({row:{original}})=><div className="text-right">
                                <Button variant="active" onClick={()=>history.push(`/myCareer/${
                                    original.kind === "gu" ? original.jobjump_id : original.id}`)}>Explore</Button>
                            </div>
                        }
                    ]} />
            </Col>
        </Row>
        <Row style={{maxWidth:"1000px"}}>
            <Col className="text-right mt-32">
                <Button variant="active" onClick={()=>goToPage("home")}>Continue Quiz</Button>
            </Col>
        </Row>
        <Modal title="Are You Sure?" show={deleteCandidate !== undefined} onHide={()=>setDeleteCandidate(undefined)}>
            <Row>
                <Col className="text-center">
                    <h4>Are you sure you want to remove {deleteCandidate?.name}?</h4>
                </Col>
            </Row>
            <Row>
                <Col className="text-right mt-16">
                    <Button variant="negative" className="mr-8" onClick={()=>setDeleteCandidate(undefined)}>Cancel</Button>
                    <Button variant="active" onClick={doDelete}>Confirm</Button>
                </Col>
            </Row>
            
        </Modal>
    </>
}
    
export default CareerPrefsPage;    