import { Resume } from "jobjump-types/Resume";
import React from "react";
import { RouteComponentProps } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import Careers from "../../data/Careers";
import CaResume from "../../data/CaResume";
import CaStudentDetails from "../../data/CaStudentDetails";
import { ResumeResponse } from "../../data/Resume";
import ResumeBuilder from "../resume/ResumeBuilder";

const CaResumeEditor : React.FC<RouteComponentProps<{id:string, student:string}>> = ({match:{params:{id,student}}}) => {
    const [resume, setResume] = React.useState<ResumeResponse>();
    const [resumeName, setResumeName] = React.useState<string>();
    const [studentName, setStudentName] = React.useState<string>() 

    React.useEffect(() => {
        CaStudentDetails.studentDetails(student).then(d => setStudentName(`${d.student.first_name} ${d.student.last_name}`))
    }, [student]);

    React.useEffect(() => {
        CaResume.getResume(Number(id)).then(r => {
            if (!r.resume || !r.contentId) {
                return;
            }
            setResume(r);
            if (r.contentId.kind === "career") {
                Careers.getCareerList().then(l => 
                    setResumeName(l.find(c => c.id === r.contentId?.id)?.name)
                )
            }
            if (r.contentId.kind === "category") {
                Careers.getCareerCategories().then(l =>
                    setResumeName(l.find(c => c.id === String(r.contentId?.id))?.name)
                )
            }
        })
    }, []);

    const updateResume = React.useCallback((resume:Resume) => {
        setResume(r => r ? ({...r, resume}) : r)
    },[setResume]); 

    const save = React.useCallback(() => {
        if (!resume || !resume.resume) {
            return Promise.reject("No resume available");
        }
        return CaResume.saveResume(Number(id), resume.resume).then(()=>{return});
    }, [resume]);

    return <ContentWrapper title={`${studentName || ""} Resume${resumeName ? ` - ${resumeName}`: ""}`}>
        {resume && resume.resume && resume.contentId && <ResumeBuilder resume={resume.resume} content={resume.contentId} setResume={updateResume} save={save} />}
    </ContentWrapper>
} 

export default CaResumeEditor;