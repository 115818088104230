import {DestinationSurveyResult} from "jobjump-types/DestinationSurvey";
import { fetchWithAuth } from "./Utils";

const CaDestinationSurvey = {
    getSurveyResults : (year:number) : Promise<DestinationSurveyResult[]> =>
        fetchWithAuth(`/api/advisers/survey/destinationSurveyResults/${year}`).then(r=>r.json()).catch(console.log),
    getSchoolDestinationSurveyLink : () : Promise<string> =>
        fetchWithAuth("/api/advisers/survey/schoolSurveyLink").then(r=>r.text()),
    deleteRecord: (id:string) : Promise<void> =>
        fetchWithAuth("/api/advisers/survey/"+id, {method:"DELETE"}).then(r=>{})
}

export default CaDestinationSurvey;