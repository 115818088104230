import { WorkExperienceProjectResponse } from "jobjump-types/WorkExperience";
import { StyleSheet, Document, Page, View, Text, Font } from '@react-pdf/renderer';
import React from "react";
import { UnorderedList, UnorderedListItem } from "./eWorkExperienceCertificate";
import { DocumentCell, DocumentGrid } from "../resume/resume_templates/CommonSections";
const Lato = require("../../assets/fonts/Lato-Regular.ttf");
const LatoBold = require("../../assets/fonts/Lato-Bold.ttf");


const RatingSection : React.FC<{title:string}> = ({title, children}) => {
    const style = StyleSheet.create({
        numberWrapper: {
            borderLeft:"1px solid #334155", 
            borderBottom:"1px solid #334155", 
            display:"flex", 
            flexDirection:"row"
        },
        numberContainer: {
            borderRight:"1px solid #334155", 
            padding:"4px 4px 4px 4px",
            flex: "0 0 20%",
            textAlign:"center"
        }
    });
    return <View style={{borderTop:"1px solid #334155", borderBottom:"1px solid #334155"}}>
        <DocumentGrid>
            <DocumentCell size={9}><Text style={{paddingLeft:"8px", paddingTop:"8px", fontWeight:"bold"}}>{title}</Text></DocumentCell>
            <DocumentCell size={3}>
                <View style={style.numberWrapper}>
                    <Text style={style.numberContainer}>1</Text>
                    <Text style={style.numberContainer}>2</Text>
                    <Text style={style.numberContainer}>3</Text>
                    <Text style={style.numberContainer}>4</Text>
                    <Text style={style.numberContainer}>5</Text>
                </View>
            </DocumentCell>
        </DocumentGrid>
        {children}
    </View>
}

const SkillsSection : React.FC<{title:string, description:string}> = ({title, description}) =>
    <RatingSection title={title}>
        <Text wrap style={{padding:"8px 8px 8px 8px"}}>{description}</Text>
    </RatingSection>

const genereateReference = (studentName:string, eWorkExperienceProjectProgress:WorkExperienceProjectResponse) => {
    Font.register({family:"Lato", src:Lato});
    Font.register({family:"Lato", src:LatoBold, fontWeight:"bold"});
    const style = StyleSheet.create({
        page: {
            lineHeight: 1.5,
            padding: "32px 32px 32px 32px",
            fontSize: 12,
            fontFamily:"Lato"
        },
        heading: {
            fontSize: 18,
            textAlign: "center",
            fontWeight:"bold",
            lineHeight: 2
        },
        subheading: {
            fontSize: 16,
            fontWeight:"bold",
            textAlign: "center",
            lineHeight: 2.5,
            marginTop:"16px"
        },
        bold : {
            fontWeight: "bold"
        },
        lineBox: {
            borderBottomColor: "#1A1A1A",
            borderStyle:"dotted",
            borderBottomWidth: 2,
            marginRight: "8px",
            height: 36 
        },
        borderedSection : {
            borderRadius:"3px", 
            border:"1px solid #334155"
        },
        borderedSectionHeading: {
            backgroundColor:"#e2e8f0", 
            borderBottom:"1px solid #334155", 
            padding:"8px 8px 8px 8px",
            fontWeight: "bold"
        }
    });
    return <Document>
        <Page wrap size={"A4"} style={style.page}>
            <View>
                <Text style={style.heading}>Work Experience Employer Reference - {studentName}</Text>
                <View style={[style.borderedSection]}>
                    <Text style={style.borderedSectionHeading}>Employment Skills - Employer Notes</Text>
                    <Text style={{padding:"8px 8px 0px 8px"}} wrap>
                        Please rate the student on their ability to demonstrate the following skills.
                    </Text>
                    <Text wrap style={{padding:"4px 8px 8px 7px"}}>
                        Cicle the score for each skill, with 5 being the highest and meaning the studend showed strong performance and 1 meaning they need for significant improvement. 
                    </Text>
                    <SkillsSection 
                        title="Attendance and punctuality" 
                        description="No absences, always punctual. Often arrived early, prepared to stay back if a task had to be done." />
                    <SkillsSection 
                        title="Appearance and presentation" 
                        description="Takes pride in appearance and presentation; sets an example to others." />
                    <SkillsSection 
                        title="Working with others" 
                        description="Excellent communication; actively helped others, showed responsibility and ability to lead." />
                    <SkillsSection 
                        title="Communication and interpersonal skills" 
                        description="Excellent communication and 'people skills'. Confident and articulate; listens well." />
                    <SkillsSection 
                        title="Time management" 
                        description="Works well independently. Able to set goals, prioritise tasks and achieve objectives in good time." />
                    <SkillsSection 
                        title="Attitude and motivation" 
                        description="Highly motivated and keen to take on tasks assigned. Attitude was consistently excellent." />
                    <SkillsSection 
                        title="Ability to follow instructions and use initiative" 
                        description="Ability to follow instructions and use initiative" />
                    <SkillsSection 
                        title="Ability to learn" 
                        description="Learns quickly; keen to achieve new skills and to enhance knowledge of workplace tasks." />
                    <SkillsSection 
                        title="Positive self-attitude" 
                        description="Confident, enthusiastic, energetic and persistent in achieving outcomes." />
                    <SkillsSection 
                        title="Quality of work" 
                        description="Work was of consistently high quality; appeared to take pride in completing work well." />
                </View>

                {eWorkExperienceProjectProgress.length > 0 && 
                    <View style={[style.borderedSection, {marginTop:"16px"}]}>
                        <Text style={style.borderedSectionHeading}>eWork Experience Project - Employer Comment</Text>
                        <RatingSection title="">
                            <View style={{padding:"8px 8px 8px 8px"}}>
                                <Text style={{paddingBottom:"8px"}}>As part of their preparation for Work Experience the student has completed a project based around their desired career.</Text>
                                <Text style={{paddingBottom:"8px"}}>Ask the student to show you their project, and provide a rating above out of 5.</Text>
                                <Text style={{paddingBottom:"8px"}}>In the project, the student:</Text>
                                <UnorderedList>
                                    {eWorkExperienceProjectProgress.map(p => <UnorderedListItem>{p.text}</UnorderedListItem>)}
                                </UnorderedList>
                                <Text style={[style.bold, {borderTop:"1px solid #334155", paddingTop:"8px"}]}>Employer Comment:</Text>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                                <View style={style.lineBox}></View>
                            </View>
                        </RatingSection>
                    </View>}
                <View style={[style.borderedSection, {marginTop:"16px"}]}>
                    <Text style={style.borderedSectionHeading}>Employer Details</Text>
                    <View style={{padding:"8px 8px 8px 8px"}}>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Employer Name</Text>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <Text>Employer Signature</Text>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Company Name</Text>
                            </DocumentCell>
                            <DocumentCell size={6}>
                                <Text>Company Address</Text>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <View style={style.lineBox}></View>
                            </DocumentCell>
                        </DocumentGrid>
                        <DocumentGrid>
                            <DocumentCell size={6}>
                                <Text>Date</Text>
                            </DocumentCell>
                        </DocumentGrid>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
}

export default genereateReference;