import React from "react";
import {SchoolDetails} from "jobjump-types/User";
import User from "../../data/User";
import { RegistrationStepProps } from "./RegistrationTypes";
import { Col, Row } from "../../components/grid";
import { Label, Select, TextField } from "../../components/Form";
import Button from "../../components/Button";
import PageSectionRow from "../../components/PageSectionRow";
import Alert from "../../components/Alert";
import ContentWrapper from "../../components/ContentWrapper";
import "./registration.css";
import Progress from "../../components/Progress";
import RegistrationProgress from "./RegistrationProgress";

const School : React.FC<RegistrationStepProps> =
    ({details, setRegistrationDetails, onStepCompletion}) => {
        const [schoolList, setSchoolList] = React.useState<SchoolDetails[]>([]);
        const [school, setSchool] = React.useState<SchoolDetails>();
        const [password, setPassword] = React.useState("");
        const [invalid, setInvalid] = React.useState(false);
        React.useEffect(() => {
            User.getSchoolDetails().then(setSchoolList)
        }, [])
        const nextButtonClick = React.useCallback(() => {
            if (!school || password === "") { setInvalid(true); return }
            User.checkSchoolLogin(school.id, password).then(r => {
                if (r) {
                    console.log(school);
                    setRegistrationDetails({...details, schoolID : school.id, schoolPassword : password});
                    onStepCompletion();
                } else {
                    setInvalid(true);
                }
            });
        }, [school,password,details, onStepCompletion, setRegistrationDetails]);
        return <ContentWrapper
                title="Hi There!"
                breadcrumbTitle="Registration"
                subtitle={[
                    "We can't wait to get started helping you out with all things careers!",
                    "Before we get started, we just need to get some details from you. These details will help us customise the site to careers that interest you.",
                ]}>
            <Row style={{maxWidth:600}}>
                <Col className="text-center">
                    <RegistrationProgress stepsTaken={[]} />
                </Col>
            </Row>
            <PageSectionRow><Col className="school-registration-page">
                {invalid && <Alert textOnly variant="negative" text="The school name or password you have entered is incorrect." />}
                <h5>To begin with, we need to know your school. Please enter your school and it's JobJump password below. If you don't know your school's password, speak to your Careers Adviser.</h5>
            </Col></PageSectionRow>
            <PageSectionRow><Col className="school-registration-page">
                <form onSubmit={(e:any)=>{e.preventDefault(); e.stopPropagation(); nextButtonClick();}}>

                    <Row>
                        <Col>
                            <Label>School Name</Label>
                            <Select
                                options={schoolList.map(s=>({label:s.name, value:s.id}))}
                                placeholder="Type to find your school here..."
                                onChange={(v)=>v && setSchool({id:v.value, name:v.label})} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>School Password</Label>
                            <TextField passwordField placeholder="School Password..." value={password} onChange={setPassword} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p><small>Please check with your parents/guardian before you register. By registering with JobJump, you agree to the Terms of Service.</small></p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="active" onClick={()=>nextButtonClick()}>Continue</Button>
                        </Col>
                    </Row>
                </form>
            </Col></PageSectionRow>
        </ContentWrapper>;
    }

export default School;