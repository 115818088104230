import React from "react";
import {Row, Col, GridSizes} from "../../components/grid";
import { AtarResult } from "jobjump-types/ATAR";
import { CoursePreferences } from "jobjump-types/Courses";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";

import { useTable, useSortBy, useExpanded, Column, Row as TableRow, useFilters, TableState, FilterTypes, FilterType } from "react-table";

import "./atar-table.css";
import "./atar-icons.css";
import { openLinkInNewTab } from "../../util";
import CoursePreferenceAPI from "../../data/CoursePreferences";
import copy from 'copy-to-clipboard';

import {EventTracking} from "../../data/EventTracking";
import { IconArrowsSort, IconClipboard, IconHeart, IconInfoCircle, IconSortAscending, IconSortDescending } from "@tabler/icons";
import RowCard from "../../components/RowCard";
import Modal from "../../components/Modal";
import { Check, Label, Select, TextField } from "../../components/Form";
import Alert from "../../components/Alert";
import { UserDetailsProps } from "../../layout/Routes";
import { usePopperTooltip } from 'react-popper-tooltip';
import _ from "lodash";
import { uniConfig } from "../uni/UniLinkData";
import { Link } from "react-router-dom";
import { getDistance } from "geolib";
import { UserDetails } from "jobjump-types/UserDetails";
import UserDetailsAPI from "../../data/UserDetails";
import Cell, { CellSizes } from "../../components/RowCard/RowCardCells";

interface AtarDescriptionHoverProps {
    value: string,
};

const AtarCodeDesc : {[k:string]:string} = {
    "N/A":"This course has used selection criteria other than ATAR, or is not offered to Year 12 applicants, or that a combination of ATAR and additional selection criteria was used.",
    "new":"This is a new course, there is no previous ATAR.",
    "Contact Institute":"Contact the institution for admission information.",
    "<5":"There were less than 5 ATAR-only based offers for this course. No ATAR was reported.",
    "Apply Direct":"You must apply directly to the institute listed, not through UAC.",
    "RC":"ATAR is not the primary selection method for this course and additional selection tasks are required (eg. test, interview, folio).",
    "NC":"New course, there is no previous ATAR",
    "NP":"No ATAR provided by the institution",
    "NR":"There is no available ATAR",
    "NN": "ATAR is unavailable. Contact the insitution.",
    "NO":"Entry to this course is based on additional criteria"
}

export const HoverElem : React.FC<{content:string|JSX.Element, hover:JSX.Element, hasHover?:boolean, hideUnderline?:boolean}> = ({content, hasHover, hover, hideUnderline}) => {
        const {
            getArrowProps,
            getTooltipProps,
            setTooltipRef,
            setTriggerRef,
            visible,
          } = usePopperTooltip();
        if (hasHover) {
            return <>{content}</>;
        }
        return <>
            <div ref={setTriggerRef} className={!hideUnderline ? "hoverable-text" : ""}>
                {content}
            </div>
            {visible && <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' }) as any}>
                {hover}
                <div {...getArrowProps({ className: 'tooltip-arrow' }) as any}></div>
            </div>}
        </>;

}

const AtarDescriptionHover: React.FC<AtarDescriptionHoverProps> =
    ({ value }: AtarDescriptionHoverProps) => 
        <HoverElem content={value} hasHover={!(value in AtarCodeDesc)} hover={<div>
            <small>{AtarCodeDesc[value] || ""}</small>
        </div>} />
    

const QiltHover: React.FC<{value:string, explination?:string}> =
    ({ value, explination }: {value:string, explination?:string}) => 
        <HoverElem content={value} hasHover={!(value in AtarCodeDesc)} hover={<div>
            {explination && <small>{explination}</small>}
            {!explination && <small>{value} of students at this institute found full time employment in this field.<br/> Data Source: QILT</small>}
        </div>} />


type MessageType = "plan-b" | "scholarships" | "both" | "general";

const AskAQuestionPrompt : React.FC<{course:AtarResult, show:boolean, onHide: ()=>void, initialMessageType?:MessageType}> =
({course, show, onHide, initialMessageType}) => { 
    const [messageType, setMessageType] = React.useState<MessageType|undefined>(initialMessageType);
    const goToLink = React.useCallback(async () => {
        if (course.contact_link) {
            await EventTracking.trackEvent("uni_enquire", String(course.id));
            openLinkInNewTab(course.contact_link);
        }
    }, [course]);
    return <Modal show={show} onHide={() => { onHide(); setMessageType(undefined); }} title="Ask Question">
        {!initialMessageType && <Row>
            <Col>
                <h4>What type of question would you like to ask?</h4>
                <Select 
                    options={[
                        {value:"scholarships", label:"Scholarships for this degree"},
                        {value:"plan-b", label:"Plan B for this degree"},
                        {value:"both", label:"Scholarships & Plan B for this degree"},
                        {value:"general", label:"Other"},
                    ]}
                    onChange={(result)=>result && setMessageType(result.value as MessageType)}
                />
            </Col>
        </Row>}
        {messageType === "scholarships" && <>
        <Row>
            <Col>
                    <p><strong>You should ask:</strong></p>
                    <p>I would like to find out if there are any scholarships available for {course.name}?</p>
                    <Button variant="active" className="float-right pb-2" onClick={()=>
                        copy(`I would like to find out if there are any scholarships available for ${course.name}?`)}>
                        <IconClipboard /> Copy Question
                    </Button>
            </Col>
        </Row> 
        </>}
        {messageType === "plan-b" && <>
        <Row>
            <Col>
                    <p><strong>You should ask:</strong></p>
                    <p>What are the alternative pathways to get into {course.name}?</p>
                    <Button variant="active" className="float-right pb-2" onClick={()=>
                        copy(`What are the alternative pathways to get into ${course.name}?`)}>
                        <IconClipboard /> Copy Question
                    </Button>
            </Col>
        </Row> 
        </>}
        {messageType === "both" && <>
        <Row>
            <Col>
                    <p><strong>You should ask:</strong></p>
                    <p>I would like to find out if there are any scholarships available for {course.name}?</p>
                    <p>I would also like to know what are the alternative pathways to get into {course.name}?</p>
                    <Button variant="active" className="float-right pb-2" onClick={()=>
                        copy(
`I would like to find out if there are any scholarships available for ${course.name}? 
I would also like to know what are the alternative pathways to get into ${course.name}?`)}>
                        <IconClipboard /> Copy Question
                    </Button>
            </Col>
        </Row> 
        </>}
        {messageType !== undefined && <Row>
            <Col>
                {messageType !== "general" && <p>Use the "Copy Question" button above, then click the "GO TO ASK A QUESTION" button to complete the university's enquiry form. You can then paste this question into their enquiry box.</p>}
                <Button variant="active" onClick={goToLink}>
                    Go To Ask Question
                </Button>
                <Alert textOnly variant="negative" className="mt-16" text="The contact link above will take you to the institution's 
                    website which will ask for your contact information. If you are under the age of 18, please
                    check you have permission from your parent/carer before using this form." />
            </Col>
        </Row>}
    </Modal>;
}

const AdmissionCenterName : {[k:string]:string} = {
    "NSW":"UAC",
    "VIC":"VTAC",
    "QLD":"QLD"
}

const AtarProfileModal : React.FC<{show:boolean, onHide:()=>void, result?:AtarResult}> = ({show, onHide, result}) => {
    const currentProfiles = result && result.atar_profile && _.sortBy((result.atar_profile?.filter(f=>!f.is_preliminary) || []), f=>f.year);
    const futureProfiles = result && result.atar_profile && _.sortBy((result.atar_profile?.filter(f=>f.is_preliminary) || []), f=>f.year);
    const currentProfile = (currentProfiles || [])?.slice(-1)[0];
    const futureProfile = (futureProfiles || [])?.slice(-1)[0];
    const showFuture = (futureProfile && currentProfile && futureProfile.year > currentProfile.year) || (futureProfile && !currentProfile);
    return <Modal show={show} onHide={onHide} title="ATAR Profile">
        {futureProfile && showFuture && <Row small>
            <Col className="mb-32 text-center">
                <h4>{futureProfile.year} Indicitive Selection Rank</h4>
                <div>This is the selection rank taken from the December {futureProfile.year-1} and Janauary {futureProfile.year} offer rounds. 
                    It may be ahead of what is displayed on {AdmissionCenterName[result?.state || "NSW"]}'s course listing, as these are often updated later in the year.</div>
                <RowCard>
                    <Col sm={8}>Lowest Selection Rank</Col>
                    <Col>{futureProfile.lowest_sr}</Col>
                </RowCard>
            </Col>
        </Row>}
        {currentProfile && <Row small>
            <Col className="text-center">
                <h4>Selection Rank ({currentProfile.year} intake)</h4>
                <div>A Selection Rank is the ATAR plus any adjustment factors.</div>
                <RowCard>
                    <Col sm={8}>Lowest Selection Rank</Col>
                    <Col>{currentProfile.lowest_sr}</Col>
                </RowCard>
                <RowCard>
                    <Col sm={8}>Median Selection Rank</Col>
                    <Col>{currentProfile.median_sr}</Col>
                </RowCard>
                <RowCard>
                    <Col sm={8}>Hightest Selection Rank</Col>
                    <Col>{currentProfile.highest_sr}</Col>
                </RowCard>
            </Col>
        </Row>}
        {currentProfile && <Row small>
            <Col className="mt-32 text-center">
                <h4>ATAR ({currentProfile.year} intake)</h4>
                <div>The ATARs of applicants to this course, without any adjustment factors.</div>
                <RowCard>
                    <Col sm={8}>Lowest ATAR</Col>
                    <Col>{currentProfile.lowest_atar}</Col>
                </RowCard>
                <RowCard>
                    <Col sm={8}>Median ATAR</Col>
                    <Col>{currentProfile.median_atar}</Col>
                </RowCard>
                <RowCard>
                    <Col sm={8}>Hightest ATAR</Col>
                    <Col>{currentProfile.highest_atar}</Col>
                </RowCard>
            </Col>
        </Row>}
    </Modal>
}
    



type ATARTableProps = {
    showSearch?:boolean, 
    data:AtarResult[],
    setChosenStates?:(s:string[])=>void,
    chosenStates?:string[]
    simpleView?:boolean,
    showDetailsOf?: MessageType,
    noDefaultFilter?:boolean
} & RouteComponentProps & UserDetailsProps;

type AugmentedAtarResult = AtarResult & {
    distance?:number
}

const ATARTable : React.FC<ATARTableProps> =
    ({ showSearch, data, chosenStates, setChosenStates, simpleView, userDetails, history, showDetailsOf, noDefaultFilter }) => {
        const [showHSCSubjects, setShowHSCSubjects] = React.useState(false);

        const [preferences, setPreferences] = React.useState<CoursePreferences[]>([]);
        const [gotPrefences, setGotPreferences] = React.useState(false);

        const [showQuestionModal, setShowQuestionModal] = React.useState(false);
        const [questionCourse, setQuestionCourse] = React.useState<AtarResult>();

        const [showAtarProfile, setShowAtarProfile] = React.useState<AtarResult>();
        const [detailedUser, setDetailedUser] = React.useState<UserDetails>();
        const [augmentedData, setAugmentedData] = React.useState<AugmentedAtarResult[]>([]);

        const [levels, setLevels] = React.useState<string[]>([]);

        React.useEffect(() => {
            const levels : string[] = data.reduce((a, v) => 
                a.includes(v.level) ? a : a.concat([v.level])
            , [] as string[])
            setLevels(levels.sort((a, b) => {
                const order = ["Bachelor Degree", "Associate Degree", "Diploma & Advanced Diploma"];
                const indexA = order.indexOf(a);
                const indexB = order.indexOf(b);
                return indexA - indexB;
            }));
        }, [data]);

        React.useEffect(() => {
            UserDetailsAPI.getDetails().then(setDetailedUser);
        }, [])

        React.useEffect(() => {
            const states = [userDetails.state, ...(userDetails.additionalStates || [])];
            setChosenStates && setChosenStates(states.includes("NSW") ? states.concat(["ACT"]) : states);
        }, [userDetails]);

        React.useEffect(() => {
            if (detailedUser) {
                setAugmentedData(data.map(d => ({
                    ...d,
                    distance: d.location && detailedUser.school_location ? getDistance(
                        {lat:detailedUser.school_location.x, lon:detailedUser.school_location.y},
                        {lat:d.location.x, lon:d.location.y}
                    ) : undefined
                })))
            }
        }, [detailedUser, data])

        React.useEffect(() => {
            CoursePreferenceAPI.getCoursePreferences().then((d) => { setPreferences(d); setGotPreferences(true) });
        }, []);

        React.useEffect(() => {
            if (gotPrefences) {
                CoursePreferenceAPI.saveCoursePreferences(preferences.map((p, i) => {
                    return { 
                        course_id: p.uniCourse ? Number(p.uniCourse.jj_id) : undefined, 
                        tafe_id: p.tafeCourse ? Number(p.tafeCourse.jj_id) : undefined, 
                        preference_order: i 
                    };
                }));
            }
        }, [preferences, gotPrefences])

        let columns : Column<AugmentedAtarResult>[] = [
            {
                id: "like-course",
                width: 1,
                Cell: ({ row }: { row: any }) => {
                    const inPreferences = preferences.find((s) => s.uniCourse && s.uniCourse.jj_id === row.original.jj_id) !== undefined;
                    return <span onClick={() => {
                        const newPrefs = [...preferences];
                        if (inPreferences) {
                            const idx = newPrefs.findIndex((p) => p.uniCourse && p.uniCourse.jj_id === row.original.jj_id);
                            newPrefs.splice(idx, 1);
                        } else {
                            if (preferences.length < 20) {
                                newPrefs.push({ uniCourse: { ...row.original }, order: newPrefs.length });
                            }
                        }
                        setPreferences(newPrefs);
                    }}>
                        <IconHeart className={inPreferences ? "fill-icon" : undefined} />
                    </span>
                }
            },
           {
                Header: 'Code',
                width: 2,
                accessor: "id",
                Cell: ({ row }: { row: any }) => {
                    return <a href={row.original.url} rel="noopener noreferrer" target="_blank">{row.original.id} ({row.original.admission_centre})</a>;
                }
            },
            {
                Header: 'Course Name',
                id: "name",
                width: simpleView ? 4 : 6,
                accessor: "name",
                Cell: ({ row }: { row: any }) => {
                    if (row.original.instUrl) {
                        return <a href={row.original.instUrl} rel="noopener noreferrer" target="_blank">{row.original.name}</a>;
                    }
                    return row.original.name;
                }
            },
            { 
                id:"institute",
                Header: 'Institute', 
                width: simpleView ? 4 : 6, 
                accessor:(row:any)=>`${row.institution} (${row.campus})`,
                Cell:({row:{original}})=> <div>
                    {!simpleView && Object.values(uniConfig).find(c=>c.id === String(original.inst_id)) ?
                    <Link to={`/uni/profiles/${original.inst_id}`}>{original.institution} ({original.campus})</Link> : 
                    <>{original.institution} ({original.campus})</>} 
                    {original.distance && <><br/><small>({Math.round(original.distance/1000)} kms away)</small></>}
                </div>,
            },
            {
                id:"distance",
                accessor:(row)=>row.distance,
                show:false,
                sortType: (rowA:TableRow<AugmentedAtarResult>, rowB:TableRow<AugmentedAtarResult>) => {
                    if (!detailedUser || !detailedUser.school_location) { 
                        const strA = `${rowA.original.institution} (${rowA.original.campus})`
                        const strB = `${rowB.original.institution} (${rowB.original.campus})`
                        return strA.localeCompare(strB) as (1|-1|0);
                    }
                    const distanceA = rowA.original.distance;
                    const distanceB = rowB.original.distance;
                    // Handle undefined location from course
                    if (!distanceA && distanceB) {
                        return 1;
                    } else if (!distanceB && distanceA) {
                        return -1
                    } 
                    // Have to do a separate if to appease typescript
                    if (!distanceA || !distanceB) {
                        return 0;
                    }
                    if (distanceA > distanceB) {
                        return 1;
                    }
                    if (distanceB > distanceA) {
                        return -1;
                    }
                    return 0;
                },
            },
            { Header: 'State', accessor: "state", width:2, },
            { 
                id:"level", 
                accessor:"level", 
                show:false,
                filter:"includes"
            }
        ];
        if (!simpleView && !showDetailsOf) {
            columns.push({
                id: "jobs",
                Header: "% Hired",
                width:2,
                sortDescFirst:true,
                accessor:(d)=>d.employment_rate,
                Cell: ({ row }: { row: any }) => {
                    return row.original.employment_rate ?
                        <QiltHover value={`${row.original.employment_rate}%`} /> : 
                        <QiltHover value={"N/A"} explination={"Too few responses to report"} /> 
                }
            });

        }
        if (showDetailsOf === "scholarships") {
            columns.push({
                id:"scholarship-link",
                Header:"General Info",
                width:3,
                Cell: ({row:{original}} : {row: {original:AtarResult}}) => {
                    const [uniName, config] = Object.entries(uniConfig).find(([n, c]) => Number(c.id) === original.inst_id) || [undefined, undefined];
                    if (!config) {
                        return <></>;
                    }
                    return <div>
                        {config.scholarships.map((s, i) => <div key={i}>
                            <Button variant="active" textButton onClick={()=>openLinkInNewTab(s.link)}>{s.text || "Scholarship Info"}</Button>
                        </div>)}
                    </div>
                }
            })
        }
        if (showDetailsOf === "plan-b") {
            columns.push({
                id:"plan-b",
                Header:"General Info",
                width:3,
                Cell: ({row:{original}} : {row: {original:AtarResult}}) => {
                    const [uniName, config] = Object.entries(uniConfig).find(([n, c]) => Number(c.id) === original.inst_id) || [undefined, undefined];
                    if (!config) {
                        return <></>;
                    }
                    return <div>
                        {config.alternativeEntry.map((s, i) => <div key={i}>
                            <Button variant="active" textButton onClick={()=>openLinkInNewTab(s.link)}>{s.text || "Plan B Info"}</Button>
                        </div>)}
                    </div>
                }
            })
        }
        if (!simpleView) {
            columns.push({
                id: "ask-a-question",
                Header: "Ask Question",
                width: showDetailsOf ? 4 :3,
                Cell: ({ row }: { row: any }) => {
                    return row.original.contact_link ? <Button textButton={showDetailsOf===undefined} variant="active" style={{cursor:"pointer"}} onClick={() => {
                        setQuestionCourse(row.original);
                        setShowQuestionModal(true);
                    }}> Ask {showDetailsOf === "plan-b" ? "About Plan B" : showDetailsOf === "scholarships" ? "About Scholarships" : "Question"}</Button> : <></>   
                }
            });
        }
        if (!showDetailsOf) {
            columns.push({
                Header: 'LSR/GA',
                accessor: "atar",
                width: 2,
                Cell: ({ row }) => {
                    if (!isNaN(Number(row.original.atar))) {
                        return <>{row.original.atar} {row.original.atar_profile.length > 0 && <IconInfoCircle size={18} className="pointer" onClick={()=>setShowAtarProfile(row.original)} />}</>;
                    }
                    return <AtarDescriptionHover value={row.original.atar} />
                }
            });
        }
        const initialState : Partial<TableState<AugmentedAtarResult>> = {
            sortBy: [{ id: 'atar', desc: true }],
            filters: noDefaultFilter ? [] : [{id : 'level', value:["Bachelor Degree"] } ]
        };
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
            setFilter,
            toggleSortBy,
            state: {
                filters
            }
        } = useTable({ columns, data:augmentedData, initialState }, useFilters, useSortBy, useExpanded);

        const institutions = Object.keys(rows.map(d => d.original.institution).reduce((a, i) => ({...a, [i]:true}), {} as {[k:string]:boolean})).sort();

        return <>
            {showSearch && <Row>
                <Col sm={6}>
                    <Label>Search By Course Name:</Label>
                    <TextField placeholder="Course Name" onChange={(value)=>setFilter("name", value)} />
                </Col>
                <Col>
                    <Label>Search By Institute:</Label>
                    <Select 
                        placeholder="Institute" 
                        options={[{label:"Any", value:""}]
                            .concat(institutions.map(i=>({label:i, value:i})))} 
                        onChange={(value)=>setFilter("institute", value ? value.value : "")} />
                </Col>
            </Row>}
            <Row className="pt-16 mb-16">
                {showSearch && <Col sm={6}>
                    <Label>Select a Course Level:</Label><br />
                    {levels.map((s: string) =>
                            <Check key={s} label={s} className="ml-8" checked={filters.find(f => f.id === "level")?.value.includes(s) || false}
                                onChange={(checked) => {
                                    const level = s;
                                    const levels = filters.find(f => f.id === "level")?.value;
                                    if (!levels) {
                                        return
                                    }
                                    let newLevels = [...levels];
                                    if (checked) {
                                        newLevels = newLevels.concat(level);
                                    } else {
                                        newLevels = newLevels.filter((e) => e !== level);
                                    }
                                    setFilter("level", newLevels);
                                }}
                            />
                        )}
                </Col>}
                {!simpleView && chosenStates && setChosenStates && <Col>
                    <Label>Select States:</Label><br />
                    {["NSW", "ACT", "VIC", "QLD", "SA", "NT", "WA", "TAS"].map((s: string) =>
                            <Check key={s} label={s} className="ml-8" checked={chosenStates.find((e) => s === e) !== undefined}
                                onChange={(checked) => {
                                    const stateName = s;
                                    let newStates = [...chosenStates];
                                    if (checked) {
                                        newStates = newStates.concat(stateName);
                                    } else {
                                        newStates = newStates.filter((e) => e !== stateName);
                                    }
                                    setChosenStates(newStates);
                                }}
                            />
                        )}
                </Col>}
            </Row>
            {!showDetailsOf && <Row>
                <Col sm={4}>
                    <Button variant="active" style={{width:"100%"}} className="mb-16" onClick={() => history.push("/atar/preferences")}>
                        <h4>See My Preferences</h4>
                    </Button>
                </Col>
                <Col sm={4}>
                    <Button variant="active" style={{width:"100%"}} className="mb-16" onClick={() => setShowHSCSubjects(!showHSCSubjects)}>
                        <h4>Show Senior Subjects</h4>
                    </Button>
                </Col>
                <Col sm={4}>
                    <Button variant="active" style={{width:"100%"}} className="mb-16" onClick={() => toggleSortBy("distance", false, false)}>
                        <h4>Show Courses Near Me</h4>
                    </Button>
                </Col>
            </Row>}
            {!simpleView && showSearch && <Row className="mt-16">
                <Col>
                    <h4>
                        {preferences.length === 0 ? <>Click the <IconHeart /> to save your course preferences.</>  :
                            <>You <IconHeart className="fill-icon" /> {preferences.length} courses so far.</>}
                        </h4>
                </Col>
            </Row>}
            <Row className="mt-16" {...getTableProps()}>
                <Col>
                    {headerGroups.map((headerGroup,i)=> 
                            <Row>
                                {headerGroup.headers.map((column, i) => (
                                    column.show !== false && <Cell {...column.getHeaderProps(column.getSortByToggleProps())} sz={column.width as CellSizes}>
                                        {column.render('Header')}
                                        {column.canSort && <span>
                                            &nbsp;{column.isSorted
                                                ? column.isSortedDesc
                                                    ? <IconSortDescending />
                                                    : <IconSortAscending />
                                                : <IconArrowsSort />}
                                        </span>}
                                    </Cell>
                                ))}
                            </Row>
                        )}
                    <Row {...getTableBodyProps()}><Col>
                        {rows.map((row, i) => {
                            prepareRow(row)
                            const hasSubjects = row.original.prerequisite_subjects !== "" ||
                                row.original.assumed_subjects !== "" ||
                                row.original.recommended_subjects !== "" ||
                                row.original.additional_admission_info;

                            return <RowCard {...row.getRowProps()} key={`rows-${i}`}>
                                <Row>
                                    {row.cells.map((cell, i) => cell.column.show !== false && <Cell sz={cell.column.width as CellSizes} {...cell.getCellProps()}>{cell.render('Cell')}</Cell>)}
                                </Row>
                                {hasSubjects && showHSCSubjects && <Row>
                                    <Col sm={12}>
                                        <p>
                                            {row.original.prerequisite_subjects !== "" && row.original.prerequisite_subjects !== null ? <><strong>Prerequisite: </strong>{row.original.prerequisite_subjects} </> : <></>}
                                            {row.original.assumed_subjects !== "" && row.original.assumed_subjects !== null ? <><strong>Assumed: </strong>{row.original.assumed_subjects} </> : <></>}
                                            {row.original.recommended_subjects !== "" && row.original.recommended_subjects !== null ? <><strong>Recommended: </strong>{row.original.recommended_subjects} </> : <></>}
                                        </p>
                                        {row.original.additional_admission_info ?
                                            <p>There is additional admission information for this course. Please consult the course information page <a href={row.original.url} rel="noopener noreferrer" target="_blank">here</a>.</p>
                                            : <></>}                                            
                                    </Col>
                                </Row>}
                            </RowCard>})} 
                    </Col></Row>
                </Col>
            </Row>
            {questionCourse && <AskAQuestionPrompt
                course={questionCourse}
                show={showQuestionModal}
                initialMessageType={showDetailsOf}
                onHide={()=>setShowQuestionModal(false)}
            />}
            <AtarProfileModal show={showAtarProfile !== undefined} onHide={()=>setShowAtarProfile(undefined)} result={showAtarProfile} />
        </>
    }

export default ATARTable;