import React from "react";
import { Row, Col } from "../../components/grid";
import { openLinkInNewTab } from "../../util";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import ContentWrapper from "../../components/ContentWrapper";

const interstateUnis = [
     {name:"New South Wales", url:"http://www.uac.edu.au/", short:"NSW"},
     {name:"Queensland", url:"http://www.qtac.edu.au/", short:"QLD"},
     {name:"South Australia & Northern Territory", url:"http://www.satac.edu.au/", short:"SA & NT"},
     {name:"Tasmania", url:"http://www.utas.edu.au/", short:"TAS"},
     {name:"Victoria", url:"http://www.vtac.edu.au/", short:"VIC"},
     {name:"Western Australia", url:"http://www.tisc.edu.au/", short:"WA"},
]

const InterstateOverseas: React.FC<{}> =
    () =>
        <ContentWrapper title="Study Interstate or Overseas" subtitle={["Want to study in another state? Or another country?"]}>
            <Row className="mb-16"><Col>
                <h2>Interstate University Admissions</h2>
            </Col></Row>
            <Row className="mb-16"><Col>
                <CardGrid numPerRow={3} prefix="interstate-uni">
                    {interstateUnis.map(({name,url,short}) => <ImageCard text={name} key={short} onClick={()=>openLinkInNewTab(url)} />)}
                </CardGrid>
            </Col></Row>
            <Row className="mb-16"><Col>
                <h2>Overseas University Admissions</h2>
            </Col></Row>
            <Row>
                <Col md={6}>
                    <h3>USA</h3>
                    <ul>
                        <li><a href="https://www.collegeboard.org/">College Board</a></li>
                        <li><a href="http://www.commonapp.org/">Common App</a></li>
                        <li><a href="https://educationusa.state.gov/your-5-steps-us-study">Education USA - Your 5 Steps to US Study</a></li>
                        <li><a href="https://sat.collegeboard.org/">SAT</a></li>
                    </ul>
                </Col>
                <Col md={6}>
                    <h3>UK</h3>
                    <ul>
                        <li><a href="https://www.ucas.com/">International Applicants</a></li>
                        <li><a href="http://www.undergraduate.study.cam.ac.uk/international-students">Cambridge International Applications</a></li>
                        <li><a href="http://www.ox.ac.uk/admissions/graduate/international-applicants">Oxford International Applications</a></li>
                        <li><a href="http://www.admissionstestingservice.org/for-test-takers/bmat/">BMAT</a></li>
                    </ul>
                </Col>
            </Row>
        </ContentWrapper>

export default InterstateOverseas;