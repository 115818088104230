import { fetchWithAuth } from "./Utils";

export type CareerPrefKind = "jobjump"|"gu";

type BulkInput = {
    kind: CareerPrefKind,
    id: number
}

export type CareerPreference = {
    kind : "gu",
    id:number,
    name:string,
    jobjump_id:number,
    jobjump_name:string
} | {
    kind : "jobjump",
    id:number,
    name:string
}

const CareerPreferences = {
    bulkAdd : (prefs:BulkInput[]) =>
        fetchWithAuth("/api/careerPreferences/bulkFavourite", {
            method:"POST",
            headers:{"Content-Type": "application/json"},
            body:JSON.stringify(prefs)
        }),
    addCareer : (kind:CareerPrefKind, id:number) =>
        fetchWithAuth(`/api/careerPreferences/favourite/${kind}/${id}`, {
            method:"POST"
        }),
    deleteCareer : (kind:CareerPrefKind, id:number) =>
        fetchWithAuth(`/api/careerPreferences/favourite/${kind}/${id}`, {
            method:"DELETE"
        }),
    getPreferences : () : Promise<CareerPreference[]> => 
        fetchWithAuth(`/api/careerPreferences/favourite`).then(r=>r.json())
}

export default CareerPreferences;