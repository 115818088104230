import { Institute } from "jobjump-types/ATAR";
import React from "react";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { Radio } from "../../components/Form";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import AtarAPI from "../../data/ATAR";
import { uniConfig } from "../uni/UniLinkData";
import { StagePropsBase } from "./common";

const PriorityUniversities = [
    "Charles Sturt University",
    "University of Wollongong"
];

const sortUnis = (instA:Institute, instB:Institute) => {
    const nameA = instA.name;
    const nameB = instB.name;
    if (PriorityUniversities.includes(nameA) || PriorityUniversities.includes(nameB)) {
        if (PriorityUniversities.includes(nameA) &&  PriorityUniversities.includes(nameB)) {
            return nameA === nameB ? 0 : nameA < nameB ? -1 : 1;
        } else if (PriorityUniversities.includes(nameA)) {
            return -1;
        } else {
            return 1;
        }
    }
    return nameA === nameB ? 0 : nameA < nameB ? -1 : 1;
}

const InstituteSelect : React.FC<StagePropsBase> = ({response, setResponse, userDetails, onContinue}) => {
    const [institutes, setInstitues] = React.useState<Institute[]>([]);
    const [valid, setValid] = React.useState(true);
    React.useEffect(() => {
        AtarAPI.getInstitutes(response.categories).then(insts => {
            setInstitues(insts.filter(i => 
                i.state === userDetails.state && (Object.values(uniConfig).find(c => String(c.id) == String(i.id)) != undefined)
                ).sort(sortUnis))
        });
    }, [response.categories]);
    const onClick = () => {
        if (response.selectedInsitutes.length === 0) {
            setValid(false);
        } else {
            onContinue();
        }
    }
    const updatePrefs = (id:number) => {
        setResponse(r=>({
            ...r, 
            selectedInsitutes:r.selectedInsitutes.includes(id) ?
                r.selectedInsitutes.filter(i=>i!==id) :
                r.selectedInsitutes.concat(r.selectedInsitutes.length < 3 ? [id] : [])
        }));
    }
    return <>
    <PageSectionRow>
        <Col className="text-center">
            <h3>The Unis you're interested in can guide you for your tertiary journey.</h3>
        </Col>
    </PageSectionRow>
    <Row small>
        <Col className="mt-32 text-center">
            <h4>Is it ok for Unis you select to contact you?</h4>
            <Radio className="block" name="privacy" checked={response.contactConsent} onChange={(c)=>setResponse(r=>!r?r:({...r, contactConsent:c}))} 
                label="Unis I select may contact me about my choices. I am over the age of 16."/>
            <Radio className="block" name="privacy" checked={!response.contactConsent} onChange={(c)=>setResponse(r=>!r?r:({...r, contactConsent:!c}))} 
                label="I do not want to be contacted by any Unis."/>
        </Col>
    </Row>
    <PageSectionRow>
        <Col className="text-center mt-16">
            <h3>Select up to 3 Unis you are interested in studying at below.</h3>
            {!valid && <Alert textOnly variant="negative" text="Please select at least one of the options" />}
        </Col>
    </PageSectionRow>
    <Row>
            <Col className="text-right mt-16">
            <Button variant="active" onClick={onClick}>Continue</Button>
        </Col>
    </Row>
    <PageSectionRow>
        <Col>
            <CardGrid prefix="inst-select" numPerRow={3}>
                {institutes.map((i)=>
                    <ImageCard 
                        key={i.id}
                        objectFitKind="contain"
                        className={response.selectedInsitutes.includes(Number(i.id)) ? "filter-grid-select-selected" : "filter-grid-select-unselected"}
                        imageUrl={Object.values(uniConfig).find(c=>String(c.id)===String(i.id))?.image}
                        onClick={()=>updatePrefs(Number(i.id))}
                        text={i.name} />
                )}
            </CardGrid>
        </Col>
    </PageSectionRow>
    <PageSectionRow>
        <Col className="text-center">
            <Button variant="active" onClick={onClick}>Continue</Button>
        </Col>
    </PageSectionRow>
</>

} 

export default InstituteSelect;