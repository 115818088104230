import React from "react";
import { makeClassName } from "../util";
import BaseCard from "./BaseCard";
import "./imageCard.css";

interface ImageCardProps {
    imageUrl?:string
    videoUrl?:string,
    text?:string,
    onClick?:()=>void,
    className?:string,
    objectFitKind?: "cover"|"contain"
    selected?:boolean,
    selectedGlow?:boolean,
    noPadding?:boolean,
    disabled?:boolean
}

const ImageCard : React.FC<ImageCardProps> = ({imageUrl, videoUrl, text, onClick, children, className, objectFitKind, selected, selectedGlow, noPadding, disabled}) => 
    <BaseCard onClick={onClick} 
        className={makeClassName(className, [
            "text-center", "jobjump-image-card", 
            onClick&&!disabled?"pointer":"", 
            text?"":"jobjump-full-image-card", 
            selected?selectedGlow?"image-card-selected-glow":"image-card-selected":"",
            disabled?"jobjump-image-card-disabled":"",
            noPadding?"image-card-no-padding":""])}>
        {imageUrl && <img src={imageUrl} className={makeClassName("jobjump-card-image", [objectFitKind || "cover"])}></img>}
        {videoUrl && <video autoPlay muted loop width="100%"> <source type="video/mp4" src={videoUrl} /> </video>}
        {text && <h4 className={"mt-32 mb-16"}>{text}</h4>}
        {children}
    </BaseCard>

export default ImageCard;
