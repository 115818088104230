import React from "react";
import UpdateDetailsLayout from "./Layout";
import User from "../../data/User";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "../../components/grid";
import Button from "../../components/Button";
import { Label, TextField } from "../../components/Form";
import Alert from "../../components/Alert";

const ChangePassword : React.FC<RouteComponentProps> =
(props) => {
    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [error, setError] = React.useState<string>();
    const [submitComplete, setSubmitComplete] = React.useState<boolean>(false);
    const submit = React.useCallback(() => {
        if (newPassword.length < 6) {
            setSubmitComplete(true);
            setError("New password is too short. Must be at least 6 characters.")
            return;
        }
        if (newPassword !== confirmPassword) {
            setSubmitComplete(true);
            setError("New Password and Confirm New Password do not match")
            return;
        }
        User.changePassword({oldPassword, newPassword}).catch(e=>setError(JSON.stringify(e))).then(() => setSubmitComplete(true));
 
    }, [oldPassword, newPassword, confirmPassword])
    return <UpdateDetailsLayout title={"My Password"} subtitle={["Use the form below to reset your password."]} pageKey="password" {...props}>
       {submitComplete && error && <Alert textOnly variant="negative" text={`An error occurred: ${error}`} />}
        <Row>
            <Col>
                <form>
                    <Row><Col>
                        <Label>Old Password</Label>
                        <TextField passwordField value={oldPassword} onChange={(value)=>setOldPassword(value)} />
                    </Col></Row>
                    <Row><Col>
                        <Label>New Password</Label>
                        <TextField passwordField value={newPassword} onChange={(value)=>setNewPassword(value)} />
                    </Col></Row>
                    <Row><Col>
                        <Label>Confirm New Password</Label>
                        <TextField passwordField value={confirmPassword} onChange={(value)=>setConfirmPassword(value)} />
                    </Col></Row>
               </form>
            </Col>
        </Row>
        <Row>
            <Col className="text-right mt-16">
                <Button variant="active" onClick={submit}>Submit</Button>
            </Col>
        </Row>
    </UpdateDetailsLayout> 
}

export default ChangePassword;
