import { TAFECareers } from "jobjump-types/Careers";
import React from "react";
import { RouteComponentProps } from "react-router";
import CareerImages from "../../assets/CareerImages";
import ContentWrapper from "../../components/ContentWrapper";
import FilterableGrid from "../../components/FilterableGrid";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import Careers from "../../data/Careers";
import { openLinkInNewTab } from "../../util";

const TafeCareerLinks : React.FC<RouteComponentProps> = ({history}) => {
    const [careers, setCareers] = React.useState<TAFECareers[]>([]);
    React.useEffect(() => {
        Careers.getTafeCareers().then(setCareers);
    }, [])
    return <ContentWrapper title="TAFE Course Search" subtitle={["Want to study at TAFE? Find your career below to get all the info on courses for you."]}>
        <PageSectionRow>
            <Col>
                <FilterableGrid
                    data={careers.map(c => ({
                        imgUrl: CareerImages[c.name.toUpperCase()],
                        text:c.name,
                        onClick:()=>openLinkInNewTab(c.tafe_url)
                    }))}
                    numPerRow={3} />
            </Col>
        </PageSectionRow>
    </ContentWrapper>
}

export default TafeCareerLinks;