import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { contrastingColor, DocumentCell, DocumentGrid, HeaderProps } from "./CommonSections";
import { Envelope, IconWithText, otherSectionToIcon, Phone, Pin } from "./Icons";

const ColouredBlockCompactHeader : React.FC<HeaderProps> = ({resume:{styleConfig, personal_details, career_goal}}) => {
    const textColour = contrastingColor(styleConfig.primaryColor);
    const style = StyleSheet.create({
        name: {
            fontSize: "28pt",
            marginBottom: "16px",
            textAlign: "center",
            color: textColour
        },
        sectionStyle : {
            color: textColour,
            margin: "-16px -16px 16px -16px",
            padding: "16px 16px 16px 16px",
            backgroundColor: styleConfig.primaryColor
        }
    });
    return <View style={style.sectionStyle}>
        <Text style={style.name}>{personal_details.name}</Text>
        <DocumentGrid>
            <DocumentCell size={8}>
                <View style={{paddingRight:"8px"}}>
                    {career_goal !== "" && <Text>{career_goal}</Text>}
                    {personal_details.languages && personal_details.languages !== "" ? 
                        <Text style={{paddingTop:"16px"}}>Languages Spoken: {personal_details.languages}</Text> : undefined}
                </View>
            </DocumentCell>
            <DocumentCell size={4}>
                <View style={{paddingLeft:"8px", textAlign:"right"}}>
                    {personal_details.address && personal_details.address !== "" ? 
                        <IconWithText height="12pt" Icon={Pin} color={textColour}>
                            <Text>{personal_details.address}</Text>
                        </IconWithText> : undefined}
                    {personal_details.email && personal_details.email !== "" ? 
                        <IconWithText height="12pt" Icon={Envelope} color={textColour}>
                            <Text>{personal_details.email}</Text>
                        </IconWithText> : undefined}
                    {personal_details.phone && personal_details.phone !== "" ? 
                        <IconWithText height="12pt" Icon={Phone} color={textColour}>
                            <Text>{personal_details.phone}</Text>
                        </IconWithText> : undefined}
                    {personal_details.other?.map(o =>
                        o.value !== "" ?
                            <IconWithText height="12pt" Icon={otherSectionToIcon(o.key)} color={textColour}>
                                <Text>{o.value}</Text>
                            </IconWithText> : undefined
                    )}
                </View>
            </DocumentCell>
        </DocumentGrid>
    </View>
}

export default ColouredBlockCompactHeader;