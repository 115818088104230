import { Resume } from "jobjump-types/Resume";
import React from "react";
import { RouteComponentProps } from "react-router";
import ContentWrapper from "../../components/ContentWrapper";
import Careers from "../../data/Careers";
import ResumeAPI, { ResumeResponse } from "../../data/Resume";
import ResumeBuilder from "./ResumeBuilder";

const ResumeBuilderWrapper : React.FC<RouteComponentProps<{id:string}>> = ({match:{params:{id}}}) => {
    const [resume, setResume] = React.useState<ResumeResponse>();
    const [resumeName, setResumeName] = React.useState<string>();

    React.useEffect(() => {
        ResumeAPI.getResume(Number(id)).then(r => {
            if (!r.contentId || !r.resume) {
                return;
            }
            setResume(r);
            if (r.contentId.kind === "career") {
                Careers.getCareerList().then(l => 
                    setResumeName(l.find(c => c.id === r.contentId?.id)?.name)
                )
            }
            if (r.contentId.kind === "category") {
                Careers.getCareerCategories().then(l =>
                    setResumeName(l.find(c => c.id === String(r.contentId?.id))?.name)
                )
            }
        })
    }, []);

    const updateResume = React.useCallback((resume:Resume) => {
        setResume(r => r ? ({...r, resume}) : r)
    },[setResume]); 

    const save = React.useCallback(() => {
        if (!resume || !resume.resume) {
            return Promise.reject("No resume found");
        }
        return ResumeAPI.saveResume(Number(id), resume.resume);
    }, [resume]);

    return <ContentWrapper title={`My Resume${resumeName ? ` - ${resumeName}`: ""}`}>
        {resume && resume.resume && resume.contentId && <ResumeBuilder resume={resume.resume} content={resume.contentId} setResume={updateResume} save={save} />}
    </ContentWrapper>
} 

export default ResumeBuilderWrapper;