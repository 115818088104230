import React from "react";
import {RowProps, Row, Col} from "../grid";
import "./diagonalLine.css";
import "./svgColoring.css";
import "./circles.css";
import "./confetti.css";
import { makeClassName } from "../util";
import { SettingsAttributes } from "docx/build/file/settings";
import { faWindows } from "@fortawesome/free-brands-svg-icons";

export type DecorationType = "diagonal-line"|"circles-top"|"circles-bottom"|"confetti";

interface DecoratedRowProps {
    decorationType: DecorationType,
    noNegativeMargins?: boolean //Only applies to diagonal-line
    fullHeight?:boolean //Only applies to circles
}

const ConfettiLHSCompact : React.FC = () =>
<svg xmlns="http://www.w3.org/2000/svg" className="compact-confetti" width="100%" viewBox="0 0 60.521 245.255" preserveAspectRatio="xMinYMin">
  <g id="Polygon_1" data-name="Polygon 1" transform="translate(0 96)" fill="none">
    <path d="M30,0,40,17,30,34H10L0,17,10,0Z" stroke="none"/>
    <path d="M 11.71583557128906 3 L 3.480541229248047 17 L 11.71583557128906 31 L 28.28416442871094 31 L 36.51945877075195 17 L 28.28416442871094 3 L 11.71583557128906 3 M 10 0 L 30 0 L 40 17 L 30 34 L 10 34 L 0 17 L 10 0 Z" stroke="none" fill="#49c4c4"/>
  </g>
  <g id="square" transform="translate(-318.07 86.882)">
    <g id="Group_150" data-name="Group 150" transform="translate(-1 -8)">
      <g id="Group_147" data-name="Group 147">
        <path id="Path_770" data-name="Path 770" d="M359.188,165.873a1,1,0,0,1-.391-.08l-23.851-10.165a1,1,0,0,1-.231-1.7L347.326,143.9a1,1,0,0,1,1.013-.138L372.19,153.93a1,1,0,0,1,.231,1.7L359.81,165.656A1,1,0,0,1,359.188,165.873Zm-21.914-11.427,21.758,9.273,10.83-8.607L348.1,145.839Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_148" data-name="Group 148">
        <path id="Path_771" data-name="Path 771" d="M335.4,155.552a1,1,0,0,1-.967-1.257l6.175-23.211a1.009,1.009,0,0,1,.348-.529l12.554-9.891a1,1,0,0,1,1.586,1.043l-6.175,23.211a1,1,0,0,1-.347.528l-12.555,9.892A1,1,0,0,1,335.4,155.552Zm7.056-23.632-5.319,19.99,9.937-7.829,5.318-19.989Zm5.5,12.74h0Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_149" data-name="Group 149">
        <path id="Path_772" data-name="Path 772" d="M371.8,155.85a1.006,1.006,0,0,1-.391-.08L347.556,145.6a1,1,0,0,1-.574-1.178l6.269-23.433a1,1,0,0,1,1.357-.662L378.46,130.5a1,1,0,0,1,.574,1.179l-6.27,23.433a1,1,0,0,1-.966.741Zm-22.66-11.744,21.992,9.373L376.878,132l-21.994-9.373Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="triangle" transform="translate(-519.57 48.882)">
    <path id="Path_776" data-name="Path 776" d="M315.57,45.543l34.965-24.425,6.168,42.2Z" transform="translate(223 -70)" fill="#49c4c4"/>
  </g>
</svg>


const ConfettiRHSCompact : React.FC = () =>
<svg xmlns="http://www.w3.org/2000/svg" className="compact-confetti" width="100%" viewBox="0 0 60.505 239.92" preserveAspectRatio="xMinYMin">
  <g id="square" transform="translate(356.449 214.664) rotate(-171)">
    <g id="Group_150" data-name="Group 150" transform="translate(-1 -8)">
      <g id="Group_147" data-name="Group 147">
        <path id="Path_770" data-name="Path 770" d="M359.188,165.873a1,1,0,0,1-.391-.08l-23.851-10.165a1,1,0,0,1-.231-1.7L347.326,143.9a1,1,0,0,1,1.013-.138L372.19,153.93a1,1,0,0,1,.231,1.7L359.81,165.656A1,1,0,0,1,359.188,165.873Zm-21.914-11.427,21.758,9.273,10.83-8.607L348.1,145.839Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_148" data-name="Group 148">
        <path id="Path_771" data-name="Path 771" d="M335.4,155.552a1,1,0,0,1-.967-1.257l6.175-23.211a1.009,1.009,0,0,1,.348-.529l12.554-9.891a1,1,0,0,1,1.586,1.043l-6.175,23.211a1,1,0,0,1-.347.528l-12.555,9.892A1,1,0,0,1,335.4,155.552Zm7.056-23.632-5.319,19.99,9.937-7.829,5.318-19.989Zm5.5,12.74h0Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_149" data-name="Group 149">
        <path id="Path_772" data-name="Path 772" d="M371.8,155.85a1.006,1.006,0,0,1-.391-.08L347.556,145.6a1,1,0,0,1-.574-1.178l6.269-23.433a1,1,0,0,1,1.357-.662L378.46,130.5a1,1,0,0,1,.574,1.179l-6.27,23.433a1,1,0,0,1-.966.741Zm-22.66-11.744,21.992,9.373L376.878,132l-21.994-9.373Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="cross" transform="translate(211.937 313.201) rotate(-171)">
    <g id="Group_154" data-name="Group 154">
      <g id="Group_152" data-name="Group 152">
        <path id="Path_774" data-name="Path 774" d="M202.664,69.381a1,1,0,0,1-1-1V43.833a1,1,0,0,1,2,0V68.381A1,1,0,0,1,202.664,69.381Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_153" data-name="Group 153">
        <path id="Path_775" data-name="Path 775" d="M215.385,58H190.07a1,1,0,0,1,0-2h25.315a1,1,0,1,1,0,2Z" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <path id="Path_777" data-name="Path 777" d="M294.982,37.4,284.254,48.125,295.8,59.668,284.254,71.212,295.8,82.755,284.882,93.671l10.1,10.1" transform="translate(322.765 242.391) rotate(-171)" fill="none" stroke="#49c4c4" stroke-width="3"/>
</svg>



const ConfettiLHS : React.FC = () => <svg className="confetti confetti-lhs" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 311.021 210.714" preserveAspectRatio="xMinYMin">
<g id="Group_1245" data-name="Group 1245" transform="translate(-188.57 -8.118)">
  <g id="square" transform="translate(121 60)">
    <g id="Group_150" data-name="Group 150" transform="translate(-1 -8)">
      <g id="Group_147" data-name="Group 147">
        <path id="Path_770" data-name="Path 770" d="M359.188,165.873a1,1,0,0,1-.391-.08l-23.851-10.165a1,1,0,0,1-.231-1.7L347.326,143.9a1,1,0,0,1,1.013-.138L372.19,153.93a1,1,0,0,1,.231,1.7L359.81,165.656A1,1,0,0,1,359.188,165.873Zm-21.914-11.427,21.758,9.273,10.83-8.607L348.1,145.839Z" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_148" data-name="Group 148">
        <path id="Path_771" data-name="Path 771" d="M335.4,155.552a1,1,0,0,1-.967-1.257l6.175-23.211a1.009,1.009,0,0,1,.348-.529l12.554-9.891a1,1,0,0,1,1.586,1.043l-6.175,23.211a1,1,0,0,1-.347.528l-12.555,9.892A1,1,0,0,1,335.4,155.552Zm7.056-23.632-5.319,19.99,9.937-7.829,5.318-19.989Zm5.5,12.74h0Z" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_149" data-name="Group 149">
        <path id="Path_772" data-name="Path 772" d="M371.8,155.85a1.006,1.006,0,0,1-.391-.08L347.556,145.6a1,1,0,0,1-.574-1.178l6.269-23.433a1,1,0,0,1,1.357-.662L378.46,130.5a1,1,0,0,1,.574,1.179l-6.27,23.433a1,1,0,0,1-.966.741Zm-22.66-11.744,21.992,9.373L376.878,132l-21.994-9.373Z" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="cross">
    <g id="Group_154" data-name="Group 154">
      <g id="Group_152" data-name="Group 152">
        <path id="Path_774" data-name="Path 774" d="M202.664,69.381a1,1,0,0,1-1-1V43.833a1,1,0,0,1,2,0V68.381A1,1,0,0,1,202.664,69.381Z" fill="#49c4c4" stroke="#49c4c4" className="jobjump-svg-fill jobjump-svg-stroke" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_153" data-name="Group 153">
        <path id="Path_775" data-name="Path 775" d="M215.385,58H190.07a1,1,0,0,1,0-2h25.315a1,1,0,1,1,0,2Z" fill="#49c4c4" stroke="#49c4c4" className="jobjump-svg-fill jobjump-svg-stroke" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="triangle" transform="translate(-12.5 -13)">
    <path id="Path_776" data-name="Path 776" d="M315.57,45.543l34.965-24.425,6.168,42.2Z" fill="#49c4c4" className="jobjump-svg-fill"/>
  </g>
  <path id="Path_777" data-name="Path 777" d="M294.982,37.4,284.254,48.125,295.8,59.668,284.254,71.212,295.8,82.755,284.882,93.671l10.1,10.1" transform="translate(-19 114)" className="jobjump-svg-stroke" fill="none" stroke="#49c4c4" stroke-width="3"/>
</g>
</svg>

const ConfettiRHS : React.FC = () => <svg className="confetti confetti-rhs" xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 223.1 270.296" preserveAspectRatio="xMinYMin">
<g id="Group_1247" data-name="Group 1247" transform="translate(385.532 308.201) rotate(-171)">
  <g id="square" transform="translate(-67.851 105.45)">
    <g id="Group_150" data-name="Group 150" transform="translate(-1 -8)">
      <g id="Group_147" data-name="Group 147">
        <path id="Path_770" data-name="Path 770" d="M359.188,165.873a1,1,0,0,1-.391-.08l-23.851-10.165a1,1,0,0,1-.231-1.7L347.326,143.9a1,1,0,0,1,1.013-.138L372.19,153.93a1,1,0,0,1,.231,1.7L359.81,165.656A1,1,0,0,1,359.188,165.873Zm-21.914-11.427,21.758,9.273,10.83-8.607L348.1,145.839Z" fill="#49c4c4" stroke="#49c4c4"  className="jobjump-svg-fill jobjump-svg-stroke" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_148" data-name="Group 148">
        <path id="Path_771" data-name="Path 771" d="M335.4,155.552a1,1,0,0,1-.967-1.257l6.175-23.211a1.009,1.009,0,0,1,.348-.529l12.554-9.891a1,1,0,0,1,1.586,1.043l-6.175,23.211a1,1,0,0,1-.347.528l-12.555,9.892A1,1,0,0,1,335.4,155.552Zm7.056-23.632-5.319,19.99,9.937-7.829,5.318-19.989Zm5.5,12.74h0Z" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_149" data-name="Group 149">
        <path id="Path_772" data-name="Path 772" d="M371.8,155.85a1.006,1.006,0,0,1-.391-.08L347.556,145.6a1,1,0,0,1-.574-1.178l6.269-23.433a1,1,0,0,1,1.357-.662L378.46,130.5a1,1,0,0,1,.574,1.179l-6.27,23.433a1,1,0,0,1-.966.741Zm-22.66-11.744,21.992,9.373L376.878,132l-21.994-9.373Z" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="cross" transform="translate(188.265 38.017)">
    <g id="Group_154" data-name="Group 154">
      <g id="Group_152" data-name="Group 152">
        <path id="Path_774" data-name="Path 774" d="M202.664,69.381a1,1,0,0,1-1-1V43.833a1,1,0,0,1,2,0V68.381A1,1,0,0,1,202.664,69.381Z" fill="#49c4c4" stroke="#49c4c4" className="jobjump-svg-fill jobjump-svg-stroke" stroke-miterlimit="10" stroke-width="1"/>
      </g>
      <g id="Group_153" data-name="Group 153">
        <path id="Path_775" data-name="Path 775" d="M215.385,58H190.07a1,1,0,0,1,0-2h25.315a1,1,0,1,1,0,2Z" fill="#49c4c4" stroke="#49c4c4" className="jobjump-svg-fill jobjump-svg-stroke" stroke-miterlimit="10" stroke-width="1"/>
      </g>
    </g>
  </g>
  <g id="triangle" transform="translate(-107.955 -15.623)">
    <path id="Path_776" data-name="Path 776" d="M315.57,45.543l34.965-24.425,6.168,42.2Z" className="jobjump-svg-fill" fill="#49c4c4"/>
  </g>
  <path id="Path_777" data-name="Path 777" d="M294.982,37.4,284.254,48.125,295.8,59.668,284.254,71.212,295.8,82.755,284.882,93.671l10.1,10.1" transform="translate(-88.46 79.628)" className="jobjump-svg-stroke" fill="none" stroke="#49c4c4" stroke-width="3"/>
</g>
</svg>

const CirclesTop : React.FC<{height?:number|string}> = ({height}) => 
<svg xmlns="http://www.w3.org/2000/svg"  width="100%" height={height} viewBox="0 0 1568 600" preserveAspectRatio="xMinYMin">
  <g id="BG" opacity="0.5">
    <circle id="Oval" cx="370" cy="370" r="370" transform="translate(-255.889 -184.382)" className="jobjump-svg-fill" fill="#49c4c4" opacity="0.1"/>
    <circle id="Oval-2" data-name="Oval" cx="395.5" cy="395.5" r="395.5" transform="translate(910.311 -315.684)" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-width="1" opacity="0.1"/>
    <circle id="Oval-3" data-name="Oval" cx="20" cy="20" r="20" transform="translate(1380.711 236.065)" className="jobjump-svg-fill" fill="#49c4c4" opacity="0.1"/>
    <circle id="Oval-4" data-name="Oval" cx="286" cy="286" r="286" transform="translate(960.4 413.462)" className="jobjump-svg-stroke" fill="none" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="5" opacity="0.1"/>
    <circle id="Oval-5" data-name="Oval" cx="286" cy="286" r="286" transform="translate(200.356 -315.684)" className="jobjump-svg-stroke"fill="none" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="5" opacity="0.1"/>
  </g>
</svg>

const CirclesBottom : React.FC<{height?:number|string}> = ({height}) =>
<svg xmlns="http://www.w3.org/2000/svg" width="100%" height={height} viewBox="0 0 1568 600" preserveAspectRatio="xMinYMin">
  <g id="BG" transform="translate(1568 1033) rotate(180)" opacity="0.5">
    <circle id="Oval" cx="369.765" cy="369.765" r="369.765" transform="translate(-255.889 -184.265)" className="jobjump-svg-fill" fill="#49c4c4" opacity="0.1"/>
    <circle id="Oval-2" data-name="Oval" cx="395.249" cy="395.249" r="395.249" transform="translate(910.311 -315.484)" className="jobjump-svg-fill jobjump-svg-stroke" fill="#49c4c4" stroke="#49c4c4" stroke-width="1" opacity="0.1"/>
    <circle id="Oval-3" data-name="Oval" cx="19.987" cy="19.987" r="19.987" transform="translate(1380.711 235.915)" className="jobjump-svg-fill" fill="#49c4c4" opacity="0.1"/>
    <circle id="Oval-4" data-name="Oval" cx="285.818" cy="285.818" r="285.818" transform="translate(960.4 413.2)" className="jobjump-svg-stroke" fill="none" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="5" opacity="0.1"/>
    <circle id="Oval-5" data-name="Oval" cx="285.818" cy="285.818" r="285.818" transform="translate(200.356 -315.484)" className="jobjump-svg-stroke" fill="none" stroke="#49c4c4" stroke-miterlimit="10" stroke-width="5" opacity="0.1"/>
  </g>
</svg>


const DiagonalLine : React.FC<Omit<DecoratedRowProps, "decorationType"> & RowProps> = 
({className, children, noNegativeMargins, ...props}) =>
    <Row expanded className={makeClassName(className, [
      `jobjump-decorated-row-diagonal-line`,
      noNegativeMargins ? `no-negative-margin` : ""
    ])} {...props}>
      <div className="diagonal-wrapper">{children}</div>
    </Row>

const Confetti : React.FC<RowProps> = 
({className, children, ...props}) => {
    const [svgHeight, setSvgHeight] = React.useState<number>();
    const [contentHeight, setContentHeight] = React.useState<number>();
    const svgWatcher = React.useRef<HTMLSpanElement>(null);
    const contentWatcher = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const setNewHeight = () => {
        setSvgHeight(svgWatcher.current?.clientHeight);
        setContentHeight(contentWatcher.current?.clientHeight);
      }
      setNewHeight();
      const intervals = setInterval(()=>setNewHeight(), 500);
      return ()=>clearTimeout(intervals)
    }, [svgWatcher, contentWatcher, setSvgHeight, setContentHeight])

    React.useEffect(() => {
      window.addEventListener("resize", () => {
        setSvgHeight(svgWatcher.current?.clientHeight);
        setContentHeight(contentWatcher.current?.clientHeight);
      })
    }, [svgWatcher, contentWatcher, setSvgHeight, setContentHeight])

    return <Row expanded style={{height:Math.max(svgHeight || 0, contentHeight || 0)}} className={`jobjump-decorated-row-confetti ${className}`} {...props}>
        <Col sm={2} md={3} className="text-center confetti-wrapper">
            <span className="confetti-outer confetti-outer-lhs"><ConfettiLHS /></span>
         </Col>
        <Col ref={contentWatcher} sm={8} md={6}>{children}</Col>
        <Col sm={2} md={3} className="text-center confetti-wrapper">
          <span ref={svgWatcher} className="confetti-outer confetti-outer-rhs"><ConfettiRHS /></span>
         </Col>
    </Row>
}

const Circles : React.FC<RowProps & {top?:boolean, fullHeight?:boolean}> = 
({className, children, top, fullHeight, ...props}) => {
    const [contentHeight, setContentHeight] = React.useState<number>();
    const heightWatcher = (element:HTMLSpanElement) => {
      if (element) {
        const elementHeight = element.getBoundingClientRect().height
        setContentHeight(elementHeight + 128)
      }
    };
    return <div style={{"height": contentHeight}} className={`jobjump-decorated-row-circles ${className}`} {...props}>
      <Row className="circle-content">
        <span ref={heightWatcher}>
          {children}
        </span>
      </Row>
      <Row expanded style={{height:"100%"}}>
        <Col noPadding>{top ? <CirclesTop height={fullHeight ? "100%" : contentHeight} /> : <CirclesBottom height={fullHeight ? "100%" : contentHeight} />}</Col>
      </Row>

    </div>

}

const DecoratedRow : React.FC<DecoratedRowProps & RowProps> = ({decorationType, fullHeight, ...props}) => 
  decorationType === "diagonal-line" ? <DiagonalLine {...props} /> :
  decorationType === "confetti" ? <Confetti {...props} /> :
  decorationType === "circles-top" ? <Circles top fullHeight={fullHeight} {...props} /> :
  decorationType === "circles-bottom" ? <Circles fullHeight={fullHeight} {...props} /> :
  <></>;

export default DecoratedRow;