import React from "react";
import { RouteComponentProps } from "react-router";
import { Col } from "../components/grid";
import Expo from "../assets/community-service-img/expo.jpg";
import Debating from "../assets/community-service-img/debating.jpg";
import Musical from "../assets/community-service-img/musical.jpg";
import Planting from "../assets/community-service-img/planting.jpg";
import Speaking from "../assets/community-service-img/speaking.jpg";
import Sport from "../assets/community-service-img/sport.jpg";
import Vol from "../assets/community-service-img/vol.jpg";
import FirstAid from "../assets/homepage/tiles/learning-cpr-and-first-aid.jpg"
import PageSectionRow from "../components/PageSectionRow";
import CardGrid from "../components/CardGrid";
import ImageCard from "../components/cards/ImageCard";
import ContentWrapper from "../components/ContentWrapper";

const CommunityService : React.FC<RouteComponentProps> = (props) => 
    <ContentWrapper 
            title="Community Service"
            subtitle={[
                "Community service can be a valuable addition to your resume. Need some inspiration? Check out some ideas below."
            ]}>
        <PageSectionRow>
            <Col>
                <CardGrid numPerRow={3} prefix="community"> 
                    <ImageCard imageUrl={Expo} text="School Events">
                        <ul className="text-left">
                            <li>Excursions and field trips</li>
                            <li>Open days</li>
                            <li>Hosting visitors from another town or country.</li>
                            <li>Attending careers expos</li>
                        </ul> 
                    </ImageCard>
                    <ImageCard imageUrl={Vol} text="Community Events">
                        <ul className="text-left">
                            <li>Clubs</li>
                            <li>Parades (fashion, music)</li>
                            <li>Cultural and religious meetings</li>
                        </ul> 
                    </ImageCard>                    
                    <ImageCard imageUrl={Sport} text="Sport">
                        <ul className="text-left">
                            <li>Sporting teams</li>
                            <li>Sporting carnivals</li>
                        </ul> 
                    </ImageCard>
                    <ImageCard imageUrl={Musical} text="Creative">
                        <ul className="text-left">
                            <li>Design</li>
                            <li>Act</li>
                            <li>Play music</li>
                            <li>Construct</li>
                            <li>Build</li>
                        </ul> 
                    </ImageCard>
                    <ImageCard imageUrl={Speaking} text="Leadership">
                        <ul className="text-left">
                            <li>SRC membership</li>
                            <li>Address school assemblies</li>
                            <li>Lead groups or teams</li>
                        </ul> 
                    </ImageCard>
                    <ImageCard imageUrl={Debating} text="Representing the School or Community">
                        <ul className="text-left"> <li>Debating</li>
                            <li>Science comps</li>
                            <li>Mathematics comps</li>
                            <li>Musicals</li>
                            <li>Fundraising</li>
                        </ul>
                    </ImageCard>
                    <ImageCard imageUrl={Planting} text="Service to the School or Community">
                        <ul className="text-left">
                            <li>Library monitor</li>
                            <li>Grounds maintenance</li>
                            <li>Agricultural plot attendant</li>
                            <li>Computer maintenance</li>
                            <li>Canteen service</li>
                            <li>Office runner</li>
                            <li>School club membership</li>
                        </ul> 
                    </ImageCard>
                    <ImageCard imageUrl={FirstAid} text="Awards & Further Study">
                        <ul className="text-left">
                            <li>Certificate Of Merit</li>
                            <li>St Johns Ambulance or Red Cross First Aid Training</li>
                            <li>OHS Certificate</li>
                            <li>Boat licence</li>
                            <li>Car licence</li>
                        </ul> 
                    </ImageCard>
                </CardGrid>
            </Col>
        </PageSectionRow>
    </ContentWrapper>

export default CommunityService;