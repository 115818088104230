import { CareerLinkType } from "jobjump-types/Careers";
import _ from "lodash";
import React from "react";
import { SubjectImages } from "../../assets/QuizImages";
import FilterableGrid from "../../components/FilterableGrid";
import { Col, Row } from "../../components/grid";
import { CwizInfoType } from "../../data/Cwiz";
import { CwizProps } from "./common";

const CwizSubjects : React.FC<CwizProps & {subjects:CwizInfoType[]}> = ({subjects, response: results, updateResponse: updateResults}) => {
    return <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Subjects</h2>
                <h3>Select your favourite subject from below.</h3>
            </Col>
        </Row>
        <Row>
            <Col className={"mt-32"}>
                <FilterableGrid
                    numPerRow={4}
                    data={subjects.map(s => ({
                        text: s.name,
                        imgUrl: SubjectImages[s.name],
                        onClick: ()=>updateResults({...results, subject:s.id})
                    }))} />
            </Col>
        </Row>
    </>;
}

export default CwizSubjects;