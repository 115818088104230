import { NewsItem } from "jobjump-types/News";
import React from "react";
import { Col } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import Badge from "../../components/Badge";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import NewsCard from "../../components/cards/NewsCard";
import CardTable from "../../components/CardTable";
import ContentWrapper from "../../components/ContentWrapper";
import PageSectionRow from "../../components/PageSectionRow";
import ShowNItemsCardGrid from "../../components/ShowNItems";
import Careers from "../../data/Careers";
import News from "../../data/News";
import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import WritingAResume from "../../assets/my_job_ads/writing-a-resume.jpg";
import Checklist from "../../assets/homepage/checklist.jpg";
import { WorkExperienceDirectoryEntry } from "jobjump-types/WorkExperience";
import WorkExperienceAPI from "../../data/WorkExperience";
import { Row } from "../../components/grid";
import _ from "lodash";

const NewsSection : React.FC<{careerID:string}> =
    ({careerID}) => {
        const [news, setNews] = React.useState<NewsItem[]>([]);
        React.useEffect(() => {
            News.getNewsForCareer(careerID).then(setNews);
        }, [careerID])
        return <>
            {news.length > 0 && <PageSectionRow>
                <Col>
                    <h2>More Opportunities</h2>
                    <h3>Look below to find workshops, competitions, open days and tours for your chosen career.</h3>
                    {news.length > 0 && <ShowNItemsCardGrid numPerRow={3} base={6} prefix="careerNews">
                        {news.map((n,i) => 
                            <NewsCard item={n} index={i} />
                        )}
                    </ShowNItemsCardGrid>}
                </Col>
            </PageSectionRow>}
        </>
    }

const WorkExperienceDirectoryDetails : React.FC<RouteComponentProps<{career:string}>> = ({history, match}) => {
    const [careerName, setCareerName] = React.useState("");
    const [careerText, setCareerText] = React.useState<string>();
    const [directoryListing, setDirectoryListing] = React.useState<WorkExperienceDirectoryEntry[]>([]);
    React.useEffect(() => {
        Careers.getCareerList().then(l => {
            const c = l.find(c => c.id === Number(match.params.career));
            if (c) { setCareerName(c.name) }
        })
    }, [match.params.career]);
    React.useEffect(() => {
        Careers.getCareerDetails(Number(match.params.career)).then(d => setCareerText(d.careerText.find(t => t.kind === "yellow_pages")?.content));
    }, [match.params.career]);
    React.useEffect(() => {
        WorkExperienceAPI.getDirectoryEntries(Number(match.params.career)).then(setDirectoryListing);
    }, [match.params.career])
    const [advice, otherDirectoryListings] = _.partition(directoryListing, (v) => v.category === "Advice");
    return <ContentWrapper
            title={"Real Work Experiences - "+careerName}
            breadcrumbTitle={careerName}
            subtitle={["Want some real experience with "+careerName+"?", "Find a place below to do it!"]}
            breadcrumbs={[{name:"Work Experiences", link:"/workExperience"}]}>
        <PageSectionRow>
            <Col>
                <h2>Where To Start?</h2>
                {advice[0]?.description || ""}
            </Col>
        </PageSectionRow>
        {(otherDirectoryListings.length > 0 || careerText) && <><PageSectionRow>
            <Col>
                <h2>Places To Consider</h2>
                {careerText && <div className="mb-61">
                    You can find places to do work experience by searching the internet with the keywords: {careerText}
                </div>}
                {otherDirectoryListings.length > 0 && <CardTable
                    data={directoryListing.filter(l => l.category !== "Advice")}
                    columns={[
                        {
                            width:10,
                            id:"text",
                            accessor: "text",
                            Cell: ({row:{original}})=>
                                <div className="text-left">
                                    <a href={original.url} target="_blank">{original.description}</a>
                                </div>
                        },
                        {
                            width:2,
                            id:"buttons",
                            Cell:({row})=><div className="text-right">
                                <Badge variant={row.original.category === "Work Experience" ? "active" : "negative"}>
                                    {row.original.category === "Other" ? "Experiences" : row.original.category}
                                </Badge>
                            </div>
                        }
                    ]}
                />}
            </Col>
        </PageSectionRow>
        </>}
        <PageSectionRow>
            <Col>
                <h2>What Next?</h2>
                <CardGrid prefix="whats-next" numPerRow={3}>
                    <ImageCard imageUrl={CallingABoss} text="What Do I Say?" onClick={()=>history.push("/workExperience/phone")} />
                    <ImageCard imageUrl={WritingAResume} text="What Do I Write?" onClick={()=>history.push("/workExperience/write")} />
                    <ImageCard imageUrl={Checklist} text="Work Experience Papers" onClick={()=>history.push("/workExperience/papers")} />
                </CardGrid>
            </Col>
        </PageSectionRow>
        <Row className="mt-16">
            <Col>
                <h4>Doing Work Experience indepently of your school?</h4>
                <div>If you want to do work experience independently of your school and the Boss needs Insurance Coverage, then say you can arrange it by
                    contacting Work Health Options (WHO - previously I.C. Frith). <a href="http://www.workhealthoptions.com.au/services/broking-services/work-experience">Click Here </a>
                    for details.
                </div>
            </Col>
        </Row>
        <NewsSection careerID={match.params.career} />
    </ContentWrapper>
}

export default WorkExperienceDirectoryDetails;