import React from "react";
import "./cells.css"

export type CellSizes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24;

interface ColProps {
  sz: CellSizes;
  className?: string;
}

type CellProp = ColProps & React.ComponentProps<"div">;

const Cell = React.forwardRef<HTMLDivElement, CellProp>(({ children, sz, className, ...props }, ref) => {
    const classes: string =
      (className ? `${className} ` : "")  +
      "jj-rowcard-cell" +
      (sz ? ` ${"jj-cell-" + sz}` : "");
  
    return <div ref={ref} className={classes} {...props}>{children}</div>;
});


export default Cell;