import React from "react";
import { IconArrowsSort, IconSortAscending, IconSortDescending } from "@tabler/icons";
import { Column, Filters, IdType, Row as TableRow, TableState, useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import Button from "../Button";
import RowCard from "../RowCard";
import { Col, GridSizes, Row } from "../grid";

interface CardTableProps<D extends object> {
    data : D[],
    columns: Column<D>[],
    hideHeader?:boolean,
    initialState?:Partial<TableState>,
    renderToolbar? : (rows:TableRow<D>[])=>JSX.Element,
    renderFilters? : (globalFilter:string, 
        setGlobalFilter:(filterValue:any)=>void, 
        filters:Filters<D>, 
        setFilter:(columnId:IdType<D>, updater:any)=>void)=>JSX.Element,
    selectFns? : {
        selectAll:()=>void, 
        selectNone:()=>void, 
        onSelect:(t:D)=>void, 
        isSelected:(a:D)=>boolean,
        haveSelection:()=>boolean,
        allSelected:()=>boolean,
    }
}

const CardTable = <D extends object>({data, columns, renderToolbar, renderFilters, selectFns, initialState, hideHeader} : CardTableProps<D>) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        setGlobalFilter,
        setFilter,
        state : {
            globalFilter,
            filters
        }
    } = useTable<D>({ columns, data, initialState }, useFilters, useGlobalFilter, useSortBy)

    if (rows.length === 0) {
        return <Row className="mt-32"><Col>
            {renderFilters && <Row className="mb-16"><Col>
                {renderFilters(globalFilter,setGlobalFilter,filters,setFilter)}
            </Col></Row>}
            <Row><Col className="text-center"><h5>Nothing to display</h5></Col></Row> 
            {renderToolbar && <Row><Col>{renderToolbar(rows)}</Col></Row>}
        </Col></Row>
    }

    return <Row {...getTableProps()} className="mt-32"><Col>
        {renderFilters && <Row className="mb-16"><Col>
            {renderFilters(globalFilter,setGlobalFilter,filters,setFilter)}
        </Col></Row>}
        {!hideHeader && headerGroups.map((group, i) => 
            <Row className="mb-16" key={`header-row-${i}`}>
                {group.headers.map(column => {
                    let props = column.getHeaderProps(column.getSortByToggleProps());
                    const colWidth = column.width;
                    const width : GridSizes|undefined = colWidth && colWidth > 0 && colWidth < 12 ? 
                        (Number(colWidth) as GridSizes) : undefined;
 
                    return <Col className="jobjump-table-header" md={width} {...props}>
                        {column.render('Header')}
                        {column.canSort && <span>
                            &nbsp;{column.isSorted
                                ? column.isSortedDesc
                                    ? <IconSortDescending />
                                    : <IconSortAscending />
                                : <IconArrowsSort />}
                        </span>}
                    </Col>
                })}
            </Row>
        )}
        <Row {...getTableBodyProps()}><Col>
            {rows.map(row => {
                prepareRow(row);
                return <RowCard 
                    selectable={selectFns !== undefined}
                    onClick={()=>selectFns && selectFns.onSelect(row.original)}
                    selected={selectFns && selectFns.isSelected(row.original)}
                    className={selectFns && selectFns.isSelected(row.original) ? "selected-row-card" : undefined}>
                        {row.cells.map(cell => {
                            const colWidth = cell.column.width;
                            const width : GridSizes|undefined = colWidth && colWidth > 0 && colWidth < 12 ? 
                                (Number(colWidth) as GridSizes) : undefined;
                            return <Col md={width} {...cell.getCellProps()}>{cell.render('Cell')}</Col>
                        })}
                </RowCard>
            })}
        </Col></Row>
        <Row>
            <Col md={6}>
                {selectFns && <>
                    <Button variant="active" className="mr-8" disabled={selectFns.allSelected()} onClick={selectFns.selectAll}>Select All</Button>
                    <Button variant="active" className="ml-8" disabled={!selectFns.haveSelection()} onClick={selectFns.selectNone}>Select None</Button>
                </>}
            </Col>
            <Col className="text-right">
                {renderToolbar && renderToolbar(rows)}
            </Col>
        </Row>
    </Col></Row>;
}

export default CardTable;