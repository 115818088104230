import React from "react";
import { Col, Row } from "../../components/grid";
import { RouteComponentProps } from "react-router";
import { CareerInfo } from "jobjump-types/Careers";
import Careers from "../../data/Careers";
import { NewsItem } from "jobjump-types/News";
import News from "../../data/News";
import NewsSection from "./NewsSection";
import VideoSection from "./VideoSection";
import Requirements from "./Requirements";
import WorkExperienceDetails from "./WorkExperienceDetails";
import Tertiary from "./TertiaryCourses";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import PageSectionRow from "../../components/PageSectionRow";
import Vol from "../../assets/community-service-img/vol.jpg";
import SearcJob from "../../assets/homepage/tiles/searching-for-a-job.jpg";
import CareerImages from "../../assets/CareerImages";
import Modal from "../../components/Modal";
import { JobVacanciesContents } from "./JobVacancies";
import HSCSubjects from "./HSCSubjects";
import ContentWrapper from "../../components/ContentWrapper";
import { CareerSubjectRecommendation } from "jobjump-types/Subjects";
import Subjects from "../../data/Subjects";
import { EventTracking } from "../../data/EventTracking";
import PersonUsingGoogle from "../../assets/homepage/person-using-google.png";

interface MyCareerMatch {
    careerID: string
}

const MyCareerDetails: React.FC<RouteComponentProps<MyCareerMatch>> =
    (props: RouteComponentProps<MyCareerMatch>) => {
        const {match, history, location} = props
        const {params:{careerID}} = match;
        const [careerDetails, setCareerDetails] = React.useState<CareerInfo>();
        const [news, setNews] = React.useState<NewsItem[]>([]);
        const [showWorkExp, setShowWorkExp] = React.useState(false);
        const [showJobModal, setShowJobModal] = React.useState(false);
        const [careerName, setCareerName] = React.useState("");
        const [subjectRecommnedations, setSubjectRecommendations] = React.useState<CareerSubjectRecommendation[]>([]);
        React.useEffect(() => {
            const timeout = setTimeout(() => {
                EventTracking.trackEvent("viewCareer", careerID);
            }, 1000*180); //Spend 2 mins on page
            return () => clearTimeout(timeout);
        }, []);
        React.useEffect(() => {
            Subjects.getSubjectsForCareer(careerID).then(setSubjectRecommendations)
        }, [])
        React.useEffect(() => {
            Careers.getCareerDetails(Number(careerID)).then((d) => {
                setCareerDetails(d);
            });
        }, [careerID]);
        React.useEffect(() => {
            News.getNewsForCareer(careerID).then((d) => setNews(d));
        }, [careerID]);
        React.useEffect(() => {
            Careers.getCareerList().then(l => {
                const c = l.find(d => d.id === Number(careerID));
                if (c) {
                    setCareerName(c.name);
                }
            })
        }, [careerID]);
        const numberOffset = news.length > 0 ? 0 : -1;
        return <ContentWrapper
                title={"Steps to "+careerName}
                breadcrumbs={[
                    {name:"Explore Your Career", link:"/myCareer"},
                ]}>
            {news.length > 0 && <PageSectionRow>
                <Col>
                    <h2>1. What's the News for {careerName}?</h2>
                    <NewsSection news={news} />
                </Col>
            </PageSectionRow>}
            {careerDetails && <>
            <PageSectionRow>
                <Col>
                    <h2>{2 + numberOffset}. Check Out What It's Like</h2>
                    <VideoSection careerId={careerID} careerName={careerName} videoItem={careerDetails.careerLinks.filter(f=>f.kind === "video" || f.kind === "vr_video")} />
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <h2>{3 + numberOffset}. Am I Suited?</h2>
                    <Requirements requirementItem={careerDetails.personalRequirements} />
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <h2>{4 + numberOffset}. What Subjects Should I Take?</h2>
                    <Row>
                        <Col>
                            {careerDetails && <HSCSubjects subjectRecommendations={subjectRecommnedations} atarLinks={careerDetails.atarLink} subjectsItem={careerDetails.careerText.find(f=>f.kind==="subject")} />}
                        </Col>
                    </Row>
                </Col>
            </PageSectionRow>
 
            <PageSectionRow>
                <Col>
                    <h2>{5 + numberOffset}. Work Experience</h2>
                    <CardGrid numPerRow={3} prefix="career-what-next">
                        <ImageCard imageUrl={PersonUsingGoogle} text="eWorkExperience" onClick={()=>history.push("/eWorkExperience/"+careerID)} />
                        <ImageCard imageUrl={careerName && CareerImages[careerName.toUpperCase()]} text="Real Work Experiences" onClick={()=>history.push("/workExperience/"+careerID)} />
                        <ImageCard imageUrl={Vol} text="Voluntary Experience" onClick={()=>history.push("/communityService")} />
                    </CardGrid>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <h2>{6 + numberOffset}. What Are My Post-School Study Options?</h2>
                    <Tertiary
                        atarLinks={careerDetails.atarLink}
                        tafeLinks={careerDetails.careerLinks.filter((f) => f.kind === "tafe")}
                        otherTertiary={careerDetails.careerLinks.filter((f) => f.kind === "tertiary")}
                        tertiaryText={careerDetails.careerText.find((f) => f.kind === "tertiary")}
                        history={history}
                        location={location}
                        match={match}
                    /> 
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <h2>{7 + numberOffset}. Land The Job</h2>
                    <CardGrid numPerRow={3} prefix="career-what-next">
                        <ImageCard imageUrl={SearcJob} text="Job Vacancies" onClick={()=>setShowJobModal(true)} />
                        <ImageCard imageUrl={CallingABoss} text="Contacting an Employer" onClick={()=>history.push("/contactEmployer")} />
                    </CardGrid>
                </Col>
            </PageSectionRow>
 
            </>}
            <Modal title="Work Expereince" onHide={()=>setShowWorkExp(false)} show={showWorkExp}>
                <Row>
                    <Col>
                        <WorkExperienceDetails />
                    </Col>
                </Row>
            </Modal>
            <Modal show={showJobModal} 
                onHide={()=>setShowJobModal(false)} 
                size="lg" 
                title={`Find Me A Job As ${careerName && ["A","E","I","O","U"].includes(careerName) ? "An" : "A"} ${careerName}`}>
                <Row>
                    <Col>
                        {careerDetails && <JobVacanciesContents
                            careerId={Number(careerID)}
                            jobLinks={careerDetails.careerLinks.filter(f => f.kind === "job")}
                            sydneyJobs={careerDetails.careerLinks.filter(f => f.kind === "capital_city_jobs")}
                            yellowPages={careerDetails.careerText.find(f => f.kind === "yellow_pages")}
                            otherJobText={careerDetails.careerText.find(f => f.kind === "job")}
                        />}
                    </Col>
                </Row>
            </Modal>
        </ContentWrapper>
    }

export default MyCareerDetails;