import {GeneralResume, CareerResume, SavedResume, SaveResumeDetails} from "jobjump-types/OldResume";
import queryString from "query-string";
import { fetchWithAuth } from "./Utils";

const Resume = {
    getUserResumes : () : Promise<SavedResume[]> => 
        fetchWithAuth("/api/legacy/resume/list").then((r) => r.json()).catch(console.log),
    getUserGenericResume : () : Promise<GeneralResume|undefined> =>
        fetchWithAuth("/api/legacy/resume/loadGeneral").then(r=>r.text()).catch(console.log).then(data => {
            if (!data || data === "") {
                return undefined;
            }
            try {
                return Promise.resolve(JSON.parse(data));
            } catch (e) {
                return undefined;
            }
        }),
    getSpecificResume : (kind:string, id:string) : Promise<CareerResume> => {
        return fetchWithAuth(`/api/legacy/resume/loadSpecific/${kind}/${id}`).then(r=>r.text()).then((s) =>
            s === "" ? undefined : JSON.parse(s)
        )
    },
    getCategoryResume : (category_id : string) : Promise<CareerResume> => {
        return Resume.getSpecificResume("category", category_id)
    },
    getCareerResume : (career_id : string) : Promise<CareerResume> => {
        return Resume.getSpecificResume("career", career_id)
    },
    getUnsureResume : () : Promise<CareerResume> => {
        return Resume.getSpecificResume("unsure", "0")
    },
    saveResume : (details : SaveResumeDetails) => {
        const {general_info, career_info} = details;
        let body = queryString.stringify({general_info, career_info});
        const id = 
            details.resume_type === "career" ? details.career_id : 
                details.resume_type === "category" ? details.category_id : "0";
        return fetchWithAuth(`/api/legacy/resume/save/${details.resume_type}/${id}?${body}`).catch(console.log)        
    }
};

export default Resume;