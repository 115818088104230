import { IconTrash } from "@tabler/icons";
import React from "react";
import { Label, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import { SubsectionProps, SubsectionTemplate, SubectionTemplateProps, DeleteButton, updateIndex, deleteFromList } from "./common";

const PersonalDetailsSection : React.FC<SubsectionProps<"personal_details">> = ({section, setSection, sectionConfig, setSectionConfig}) => {
    return <SubsectionTemplate
        sectionSetting={sectionConfig}
        updateSectionSetting={setSectionConfig}

        disallowChanges 
        hideDragHandle
        title="My Details"
        buttons={[
            section.address === undefined ?
            {text:"Address", onClick:()=>setSection({...section, address:""})} : undefined,
            section.languages === undefined ?
            {text:"Languages", onClick:()=>setSection({...section, languages:""})} : undefined,
            (!section.other || section.other && section.other.find(o=>o.key === "Website") === undefined) ?
            {text:"Website", onClick:()=>setSection({...section, other:[...(section.other||[]), {key:"Website", value:""}]})} : undefined,
            (!section.other || section.other && section.other.find(o=>o.key === "LinkedIn") === undefined) ?
            {text:"LinkedIn", onClick:()=>setSection({...section, other:[...(section.other||[]), {key:"LinkedIn", value:""}]})} : undefined,
            (!section.other || section.other && section.other.find(o=>o.key === "Instagram") === undefined) ?
            {text:"Instagram", onClick:()=>setSection({...section, other:[...(section.other||[]), {key:"Instagram", value:""}]})} : undefined,
            (!section.other || section.other && section.other.find(o=>o.key === "Twitter") === undefined) ?
            {text:"Twitter", onClick:()=>setSection({...section, other:[...(section.other||[]), {key:"Twitter", value:""}]})} : undefined
        ].filter(c => c !== undefined) as SubectionTemplateProps["buttons"]}>
            <Row>
                <Col sm={3}>
                    <Label>Name</Label>
                </Col>
                <Col>
                    <TextField value={section.name} placeholder="Name" onChange={v=>setSection({...section, name:v})} />
                </Col>
            </Row>
            <Row className="mt-8">
                <Col sm={3}>
                    <Label>Email</Label>
                </Col>
                <Col>
                    <TextField value={section.email} placeholder="Email" onChange={v=>setSection({...section, email:v})} />
                </Col>
            </Row>
            <Row className="mt-8">
                <Col sm={3}>
                    <Label>Phone</Label>
                </Col>
                <Col>
                    <TextField value={section.phone} placeholder="Phone" onChange={v=>setSection({...section, phone:v})} />
                </Col>
            </Row>
            {section.address !== undefined && <Row className="mt-8" alignItems="center" justify="center">
                <Col sm={3}>
                    <Label>Address</Label>
                </Col>
                <Col>
                    <TextField value={section.address} placeholder="Address" onChange={v=>setSection({...section, address:v})} />
                </Col>
                <div style={{flex: "0 0 24px"}}>
                    <IconTrash style={{cursor:"pointer"}} onClick={()=>setSection({...section, address:undefined})} />
                </div>
            </Row>}
            {section.languages !== undefined && <Row className="mt-8" alignItems="center" justify="center">
                <Col sm={3}>
                    <Label>Languages</Label>
                </Col>
                <Col>
                    <TextField value={section.languages} placeholder="Languages" onChange={v=>setSection({...section, languages:v})} />
                </Col>
                <div style={{flex: "0 0 24px"}}>
                    <IconTrash style={{cursor:"pointer"}} onClick={()=>setSection({...section, languages:undefined})} />
                </div>
            </Row>}
            {(section.other || []).map((o, i) =>
                <Row className="mt-8" key={`details-other-${i}`} alignItems="center" justify="center">
                    <Col sm={3}>
                        <Label>{o.key}</Label>
                    </Col>
                    <Col>
                        <TextField value={o.value} placeholder={o.key} 
                            onChange={v=>setSection({...section, other: updateIndex(section.other||[], "value", v, i)})} />
                        
                    </Col>
                    <div style={{flex: "0 0 24px"}}>
                        <IconTrash style={{cursor:"pointer"}} onClick={()=>setSection({...section, other:deleteFromList(section.other || [], i)})} />
                    </div>
                </Row>
            )}
        </SubsectionTemplate>
}

export default PersonalDetailsSection;