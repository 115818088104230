import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { Resume } from "jobjump-types/Resume";
import React from "react";
import { OrderedResumeSections, SectionProps } from "./CommonSections";
import { Briefcase, Carousel, Certificate, IDCard, IconWithText, People, Rocket, School, TemplateIcon, Tool, Trophy } from "./Icons";

const Section : React.FC<SectionProps<{heading1:any}>> = ({section, defaultTitle, style, color, children}) => {
    const sectionStyle = StyleSheet.create({
        section: {
            borderBottom: `1px solid ${color}`
        },
    });
    const Icon = section.name === "awards" ? 
            Certificate :
        section.name === "career_goal" ?
            Rocket :
        section.name === "education_history" ?
            School :
        section.name === "employment_details" ?
            Briefcase :
        section.name === "hobbies" ?
            Carousel :
        section.name === "achievements" ?
            Trophy :
        section.name === "referees" ?
            People :
        section.name === "skills" ?
            Tool :
        section.name === "qualifications" ?
            IDCard :
        section.name === "eWorkExperience" ?
            TemplateIcon :
            undefined;
    return <View wrap={section.name === "employment_details"}>
        <View style={sectionStyle.section}>
            {Icon && <IconWithText Icon={Icon} color={color} height={"22pt"}>
                <Text style={style.heading1}>{section.alias || defaultTitle}</Text>
            </IconWithText>}
        </View>
        {children}
    </View>
}

const IconBodyCompact : React.FC<{resume:Resume}> = ({resume}) => {
    const color = resume.styleConfig.primaryColor /*"#49c4c4"*/;
    const style = StyleSheet.create({
        page: {
            padding: "32px 32px 32px 32px",
            fontSize: "12pt",
            lineHeight: "1.5",
            fontFamily: resume.styleConfig.font !== "" ? resume.styleConfig.font : undefined
        },
        heading1: {
            fontSize: "22pt",
            marginBottom: "8px",
            color: color,
        },
        heading2: {
            fontSize: "16pt",
        },
        smallText: {
            fontSize: "10pt",
        },
        sectionStyle: {
            paddingTop: "16px",
            paddingBottom: "16px",
        }
    });
    return <OrderedResumeSections resume={resume} style={style} SectionComponent={Section} />
}

export default IconBodyCompact;