import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { Resume, SectionConfig } from "jobjump-types/Resume";
import React from "react";
import { OrderedResumeSections } from "./CommonSections";

type SectionProps<T extends ReactPDF.Styles> = {
    section: SectionConfig,
    defaultTitle: string,
    style: T,
}

const Section : React.FC<SectionProps<{heading1:any}>> = ({section, defaultTitle, style, children}) =>
    <View wrap={section.name === "employment_details"}>
        <Text style={style.heading1}>{section.alias || defaultTitle}</Text>
        {children}
    </View>

const DoubleLineBody : React.FC<{resume:Resume}> = ({resume}) => {
    const style = StyleSheet.create({
        page: {
            padding: "32px 32px 32px 32px",
            fontSize: "12pt",
            lineHeight: "1.5",
            fontFamily: resume.styleConfig.font !== "" ? resume.styleConfig.font : undefined
        },
        heading1: {
            fontSize: "22pt",
            paddingTop: "8px",
            borderTop: `1px solid ${resume.styleConfig.primaryColor}`,
            borderBottom: `1px solid ${resume.styleConfig.primaryColor}`,
            color: resume.styleConfig.primaryColor 
        },
        heading2: {
            fontSize: "16pt",
        },
        smallText: {
            fontSize: "10pt",
        },
        sectionStyle: {
            paddingTop: "16px",
            paddingBottom: "16px",
        }
    })
    return <OrderedResumeSections resume={resume} style={style} SectionComponent={Section} />;
}

export default DoubleLineBody;