import React from "react";
import { RouteComponentProps } from "react-router";
import qs from "query-string";
import { DestinationSurveyResponse, emptyDestinationResponse } from "jobjump-types/DestinationSurvey";
import {Row, Col} from "../../components/grid";
import Subjects from "../../data/Subjects";
import { Subject } from "jobjump-types/Subjects";
import AtarAPI from "../../data/ATAR";
import { Institute, AtarCategory } from "jobjump-types/ATAR";
import DestinationSurveyAPI from "../../data/DestinationSurvey";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { Check, Label, Select, TextField } from "../../components/Form";
import Alert from "../../components/Alert";
import _ from "lodash";
import CourseAsyncSelect from "./CourseAsyncSelect";

interface QuestionSectionProps {
    response : DestinationSurveyResponse,
    setDestinationResponse : (d:DestinationSurveyResponse)=>void
}

interface YesNoProps extends QuestionSectionProps {
    sectionKey : keyof DestinationSurveyResponse,
    question : string
}

const YesNo : React.FC<YesNoProps> =
({response, setDestinationResponse, sectionKey, question}) => 
    <div>
        <Label>{question}</Label>
        <Check block name={sectionKey} checked={response[sectionKey] === "true"}  label="Yes" type="radio"
            onChange={(e)=>setDestinationResponse({...response, [sectionKey]:"true"})} />
        <Check block name={sectionKey} checked={response[sectionKey] === "false"}  label="No" type="radio"
        onChange={(e)=>setDestinationResponse({...response, [sectionKey]:"false"})} />
    </div>

const handleInputChange = (key:keyof DestinationSurveyResponse, response:DestinationSurveyResponse, setDestinationResponse:(d:DestinationSurveyResponse)=>void) => 
    (v:string| {label:string, value:string} | null) => v && setDestinationResponse({...response, [key]: typeof v === "string" ? v : v.value})

const UniQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) => {
    const [institutes, setInstitutes] = React.useState<Institute[]>([]);
    const [categories, setCategories] = React.useState<AtarCategory[]>([]);
    React.useEffect(() => {
        AtarAPI.getInstitutes().then(i=> setInstitutes(_.uniqBy(i, "name")));
    }, []);
    React.useEffect(() => {
        AtarAPI.getAtarCategories().then(setCategories);
    }, []);
    return <div>
        <Row><Col>
            <Label>Where are you studying?</Label>
            <Select value={{value:response.course_inst, label:response.course_inst}}
                placeholder="Please select an institute"
                options={_.orderBy(institutes.map(i => ({value:i.name, label:i.name})), "value")}
                onChange={handleInputChange("course_inst", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>Which of the following categories does your course fall into?</Label>
            <Select value={{value:response.course_category, label:response.course_category}}
                placeholder="Please select a category"
                options={categories.map(c => ({value:c.name, label:c.name}))}
                onChange={handleInputChange("course_category", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>What is the name of your course?</Label>
            <CourseAsyncSelect
                value={response.course_name}
                onChange={handleInputChange("course_name", response, setDestinationResponse)}
                institute={response.course_inst}
                category={response.course_category} />
        </Col></Row>
        <Row><Col>
            <YesNo response={response} setDestinationResponse={setDestinationResponse} sectionKey="early_offer"
                question="Did you receive an early offer?" />
            <YesNo response={response} setDestinationResponse={setDestinationResponse} sectionKey="scholarship"
                question="Did you receive a scholarship?" />
            {response.scholarship === "true" && <Row><Col>
                <Label>What is the name of the scholarship?</Label>
                <TextField value={response.scholarship_name}
                    onChange={handleInputChange("scholarship_name", response, setDestinationResponse)} />
            </Col></Row>}
        </Col></Row>
    </div>
}

const TafeCourseKinds : {[k:string]:string} = {
    "cert2": "Certificate 2",
    "cert3": "Certificate 3",
    "cert4": "Certificate 4",
    "diploma": "Diploma",
    "advDiploma": "Advanced Diploma",
    "degree": "Degree",
}

const TafePrivateCollegeQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) =>
    <div>
        <Row><Col>
            <Label>What is the name of your course?</Label>
            <TextField value={response.course_name} 
                onChange={handleInputChange("course_name", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>What kind of course are you studying?</Label>
            <Select value={{value:response.course_kind, label:TafeCourseKinds[response.course_kind] || ""}} 
                options={Object.entries(TafeCourseKinds).map(([value, label])=>({value,label}))}
                onChange={handleInputChange("course_kind", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>Where are you studying?</Label>
            <TextField value={response.course_inst} 
                onChange={handleInputChange("course_inst", response, setDestinationResponse)} />
        </Col></Row>
    </div>

const ApprenticeQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) =>
    <div>
        <Row><Col>
            <Label>What kind of apprenticeship/traineeship are you doing? (For example, Electrical apprenticeship or Business Admin Traineeship)</Label>
            <TextField value={response.work_kind} 
                onChange={handleInputChange("work_kind", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>What is the name of the company you're working for?</Label>
            <TextField value={response.employer} 
                onChange={handleInputChange("employer", response, setDestinationResponse)} />
        </Col></Row>
    </div>

const WorkingQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) =>
    <div>
        <Row><Col>
            <Label>What kind of work are you doing?</Label>
            <TextField value={response.work_kind} 
                onChange={handleInputChange("work_kind", response, setDestinationResponse)} />
        </Col></Row>
        <Row><Col>
            <Label>What is the name of the company you're working for?</Label>
            <TextField value={response.employer} 
                onChange={handleInputChange("employer", response, setDestinationResponse)} />
        </Col></Row>
    </div>

const OtherQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) =>
    <div>
        <Row><Col>
            <Label>Describe what you are doing</Label>
            <TextField value={response.other_answer} 
                onChange={handleInputChange("other_answer", response, setDestinationResponse)} />
        </Col></Row>
    </div>


const AtarQuestion : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) =>
    <Row><Col>
        <YesNo sectionKey="got_atar" question="Did you get an ATAR?" {...{response, setDestinationResponse}} />
        {response.got_atar === "true" && <div>
            <Label>
                My ATAR was:<br />
                <small><strong>Note: Our school uses this information purely for statistical purposes.</strong></small>
            </Label>
            <TextField value={response.atar_value} onChange={handleInputChange("atar_value", response, setDestinationResponse)} />
        </div>}
    </Col></Row>

const VetQuestions : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) => {
    const [subjects, setSubjects] = React.useState<Subject[]>([]);
    React.useEffect(() => {
        Subjects.getVETSubjects().then(setSubjects);
    }, [])
    return <Row><Col>
        <YesNo sectionKey="studied_vet" question="Did you study a VET course?" {...{response, setDestinationResponse}} />
        {response.studied_vet === "true" && <>
            <div className="mt-8">
                <Label>Which of the following courses did you study?</Label>
                {subjects.map(s => 
                    <Check block key={s.id} checked={response.vet_subjects.includes(s.name)} label={s.name}
                        onChange={(checked) => 
                            setDestinationResponse({
                                ...response, 
                                vet_subjects : checked ? 
                                    response.vet_subjects.concat([s.name]) :
                                    response.vet_subjects.filter(n => n !== s.name)
                            })
                        }
                    />
                )}
            </div>
            {response.vet_subjects.includes("EVET") && <Row><Col>
                <Label>What is the name of the EVET course?</Label>
                <TextField value={response.evet_name} onChange={handleInputChange("evet_name", response, setDestinationResponse)} />
            </Col></Row>}
            <YesNo sectionKey="vet_relate" 
                question="Does your current post school training or employment relate to the VET or EVET course(s) you studied in the HSC?" 
                response={response}
                setDestinationResponse={setDestinationResponse}
            />
            <YesNo 
                sectionKey="vet_help_entry"
                question="Did doing a VET course help you in obtaining entry into your current post school training or post school employment?" 
                response={response}
                setDestinationResponse={setDestinationResponse}
            />
        </>}
    </Col></Row>
}

const DestinationKindQuestion : React.FC<QuestionSectionProps> =
({response, setDestinationResponse}) => {
    const [noneOfTheAbove, setNoneOfTheAbove] = React.useState(false);
    return <div>
        <Row><Col>
            <Label>Have you received an offer for any of the following:</Label>
            {[["uni", "University"], ["tafe", "TAFE  (excluding Apprenticeships and Traineeships)"], ["private-college", "Private College (excluding Apprenticeships and Traineeships)"],
                ["apprenticeship", "Apprenticeship"], ["traineeship", "Traineeship"]].map(([key, label]) => 
                <Check block type="radio" name="destinationKind" checked={response.destination_kind === key} key={key} label={label}
                    onChange={()=>{setNoneOfTheAbove(false);setDestinationResponse({...response,destination_kind:key})}} />
            )}
            <Check type="radio" block checked={noneOfTheAbove} label="None of the above"
                onChange={()=>{setNoneOfTheAbove(true); setDestinationResponse({...response, destination_kind:""})}} />
        </Col></Row>
        {noneOfTheAbove && <Row><Col>
            <Label>Which of the following best describes what you are doing?</Label>
            {[["full-time","Full-time work"], ["part-time", "Part-time work"], ["job-seeking", "Looking for work"], 
                ["gap-year", "Gap year"], ["other", "Other"]].map(([key, label]) => 
                <Check type="radio" block name="destinationKind" checked={response.destination_kind === key} key={key} label={label}
                    onChange={()=>{setDestinationResponse({...response,destination_kind:key})}} />
            )}
        </Col></Row>}
    </div>
}

const DestinationSurvey : React.FC<RouteComponentProps<{}>> =
({location: {search}}) => {
    const {user_id, token, school_token, school_id} = qs.parse(search);
    const isAnonLink = !user_id && !token;
    const [response, setResponse] = React.useState<DestinationSurveyResponse>(emptyDestinationResponse);
    const [firstName, setFirstName] = React.useState<string>("");
    const [lastName, setLastName] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");
    const [aboriginal, setAboriginal] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [alreadyCompleted, setAlreadyCompleted] = React.useState(false);
    React.useEffect(() => {
        if (typeof token === "string") {
            DestinationSurveyAPI.hasCompletedSurvey(token).then(setAlreadyCompleted);
        }
    }, [token]);
    const submit = React.useCallback(() => {
        if (isAnonLink && (firstName === "" || lastName === "" || email === "")) {
            setErrorMessage("Please enter your name and email address.");
            return;
        }        
        if (response.destination_kind === "") {
            setErrorMessage("Please select the best option that describes what you're doing.")
            return;
        }
        if ((response.destination_kind === "uni" && 
                (response.course_category === "" || response.course_inst === "" || 
                response.course_name === "" || response.early_offer === "" || response.scholarship === "")) 
            || (["tafe", "private-college"].includes(response.destination_kind) && 
                (response.course_kind === "" || response.course_inst === "" || response.course_name === "")
            )
        ) {
            setErrorMessage("Please enter your course details");
            return;
        }
        if (["apprenticeship", "traineeship", "full-time", "part-time"].includes(response.destination_kind) && 
            (response.work_kind === "" || response.employer === "")) 
        {
            setErrorMessage("Please enter your work/apprenticeship details");
            return;
        }
        if (response.destination_kind === "other" && response.other_answer === "") {
            setErrorMessage("Please provide more information about what you're doing.")
            return;
        }
        if (response.got_atar === "") {
            setErrorMessage("Please answer the ATAR question.");
            return;
        }
        if (response.studied_vet === "") {
            setErrorMessage("Please answer the VET question.");
            return;
        }
        if (response.studied_vet === "yes" && 
            (response.vet_subjects.length === 0 || response.vet_help_entry === "" || response.vet_credit_transfer === ""))
        {
            setErrorMessage("Please answer all the VET questions.");
            return;
        }
        if (response.five_year === "") {
            setErrorMessage("Please indicate if we can contact you in five years.")
            return;
        }
        setErrorMessage("");
        if (typeof token === "string" && typeof user_id === "string") {
            DestinationSurveyAPI.submitSurvey(token, user_id, response).then(()=>setAlreadyCompleted(true));
            
        }
        if (typeof school_token === "string" && typeof school_id === "string") {
            DestinationSurveyAPI.submitAnonSurvey(school_token, school_id, firstName, lastName, email, aboriginal, response)
                .then(()=>setAlreadyCompleted(true));
        }
    }, [firstName, lastName, aboriginal, email, response, isAnonLink, school_id, school_token, token, user_id])
    if ((!user_id && !token) && (!school_token && !school_id)) {
        return <ContentWrapper title={"Destination Survey"} >
            <h3>Invalid address. Please double check the link you clicked, and if you copied and pasted it make sure you captured the full address.</h3>
        </ContentWrapper>
    }
    if (alreadyCompleted) {
        return <ContentWrapper title={"Destination Survey"} >
            <h3>Thank you, you have completed the survey.</h3>
        </ContentWrapper>
    }
    return <ContentWrapper title={"Destination Survey"}>
        <Row><Col>
            <p className="lead">Our school is doing a very quick check to see what you are doing this year. Your answers help us plan ahead for students in future years at our School.</p> 
            <p className="lead">Could you please take a minute now to answer a few questions.</p> 
            <p className="lead">Tick the most appropriate option from the list below and add a few basic details and click Submit.</p>
        </Col></Row>
        {errorMessage !== "" && <Alert text={errorMessage} variant="negative" />}
        <Row><Col>
            {isAnonLink && <div>
                <Row><Col>
                    <Label>First Name</Label>
                    <TextField value={firstName} onChange={(value)=>setFirstName(value)} />
                </Col></Row> 
                <Row><Col>
                    <Label>Last Name</Label>
                    <TextField value={lastName} onChange={(value)=>setLastName(value)} />
                </Col></Row> 
                <Row><Col>
                    <Label>Email</Label>
                    <TextField value={email} onChange={(value)=>setEmail(value)} />
                </Col></Row> 
                <Row><Col>
                    <Label>Are you of Aboriginal or Torres Strait Islander background?</Label>
                    <Check block checked={aboriginal} type="radio" name="aboriginal" 
                        onChange={(e)=>setAboriginal(true)} label="Yes" /> 
                    <Check block checked={!aboriginal} type="radio" name="aboriginal" 
                        onChange={(e)=>setAboriginal(false)} label="No" /> 
                </Col></Row> 
            </div>}
            <DestinationKindQuestion response={response} setDestinationResponse={setResponse} />
            {response.destination_kind === "uni" && <UniQuestions response={response} setDestinationResponse={setResponse} />}
            {(response.destination_kind === "tafe" || response.destination_kind === "private-college") && 
                <TafePrivateCollegeQuestions response={response} setDestinationResponse={setResponse} />}
            {(response.destination_kind === "apprenticeship" || response.destination_kind === "traineeship") && 
                <ApprenticeQuestions response={response} setDestinationResponse={setResponse} />}
            {(response.destination_kind === "full-time" || response.destination_kind === "part-time") && 
                <WorkingQuestions response={response} setDestinationResponse={setResponse} />}
            {response.destination_kind === "other" && <OtherQuestions response={response} setDestinationResponse={setResponse} />}
            <AtarQuestion response={response} setDestinationResponse={setResponse} />
            <VetQuestions response={response} setDestinationResponse={setResponse} />
            <Row><Col>
                <YesNo response={response} setDestinationResponse={setResponse} sectionKey="five_year"
                    question="We would really like to email you to see what you're doing in five years. Is this ok?*"/>
                <small>*By selecting Yes JobJump will retain your email address for this purpose alone for a further five years.</small>
            </Col></Row>
        </Col></Row>
        <Row>
            <Col sm={3}>
                <Button variant="active" onClick={submit}>Submit</Button>
            </Col>
        </Row>
    </ContentWrapper>;
}

export default DestinationSurvey;