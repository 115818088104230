import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { EventTracking } from "../../data/EventTracking";

const WhatToSayOnThePhone : React.FC<{}> =
() => {
      React.useEffect(() => {
          const timeout = setTimeout(() => {
              EventTracking.trackEvent("interviewPrepView", "whatToSayOnPhone");
          }, 1000*180); //Spend 2 mins on page
          return () => clearTimeout(timeout);
      }, []);
      return <ContentWrapper 
            title="What To Say on the Phone"
            subtitle={[
              "Calling an employer? Here's some tips to get you going."
            ]}
            breadcrumbs={[
              {name:"Contacting an Employer", link:"/contactEmployer"}, 
            ]}>
            <PageSectionRow>
              <Col>
                <h2>Handy Tips</h2>
                <p>
                  <ul>
                    <li>Make sure you have a place that you can talk where it is quiet.</li>
                    <li>Have a list of the companies and their phone numbers. Prepare it on a Directory Sheet. Have a pen ready to write down any important information</li>
                    <li>Keep a copy of your resume nearby to jog your memory should they ask you for some facts about yourself.</li>
                    <li>Select 6 companies that are not your main ones. Call them first to practice, so you if make a mistake or stumble it won't be to a company that mattered to you.</li>
                    <li>Remember that it is normal to be nervous. After you do the first couple, you will be a natural. Practice what you want to say before you start making your calls.</li>
                    <li>Speak clearly.</li>
                    <li>Remember to always say “Thanks” even if you have difficult people to talk to. There is always difficult people in the world. Just because a few aren’t polite to you does not mean that you need to be put off or impolite back to them.</li>
                  </ul>
                </p>
              </Col>
            </PageSectionRow>
            <PageSectionRow>
              <Col>
                <h2>Example Conversation</h2>
                <p><strong>You say:</strong><br />
                  “Hello. My name is …………………. and I am calling to see if your company may be looking for any new ……………………………..<em>(say here the name of your position, eg apprentice chef)</em> for ……………………….<em>(say here when for, eg, for the end of the year)</em>. <br />
                  May I speak to the manager please?”</p>
                <p>The chances are that you may be talking to the receptionist at this point. If you do speak to them and the manager is not in, then do 3 things. Leave a message with your return mobile and land line numbers and when you will be available in working hours to receive a call back. Secondly, ask when the manager may be available. Thirdly, ask if there is normally a good time in the week to catch the manager. This is how it may go.</p>
                <p><strong>They say:</strong><br />
                  “Sorry. The manager isn’t in at the moment. Would you like me to take a message to pass on to them?”</p>
                <p><strong>You say:</strong><br />
                  “Thank you. Could you say that …………………. <em>(say your  name)</em> and I am calling to see if your company may be looking for any new ……………………………..<em>(say here the name of your position, eg apprentice chef)</em> for ……………………….<em>(say here when for, eg, for the end of the year)</em>. My phone numbers you can catch me on are ……………………..”<em>(say your phone numbers)</em>. <br />
                  “Could I ask when is the next time that the manager will be available and also when in the week is normally a good time to catch them?”</p>
                <p>Armed with these facts which you must write down in your details column, you will be sure to catch the manager. Don’t be put off. They are busy people. Bosses are usually happy to give advice when they can find the time to chat with you.</p>
                <p>When you do speak directly with the manager, start again with your original words.<br />
                  <strong>You say:</strong><br />
                  “Hello. My name is …………………. and I am calling to see if your company may be looking for any new ……………………………..<em>(say here the name of your position, eg apprentice chef)</em> for ……………………….”<em>(say here when for, eg, for the end of the year</em>). </p>
                <p><strong>At this point the manager will give you one of three replies.</strong><br />
                  <strong>1.</strong><br />
                  <strong>They say:</strong><br />
                  “Sorry. I can’t help you.”<br />
                  <strong>You say:</strong><br />
                  “Thank you. By any chance do you have any leads I can follow up? “<em>(They may well know of other companies that do hire people in your career. If they do have suggestions, be ready to write them down)</em>. </p>
                <p><strong>OR</strong></p>
                <p><strong>2. </strong><br />
                  <strong>They say:</strong><br />
                  “Yes. There may be a possibility we will be looking for new staff. We just aren’t sure yet.”<br />
                  <strong>You say:</strong><br />
                  “When may you be more certain if you will be taking on new workers?”<br />
                  They may say one of two things:<br />
                  <strong>A. </strong>They will give a month in the year when they will be more certain. For example they may say they will be certain by October. If that is the case, then you make sure to call by late September. The reason for this is that they may be recruiting from the 1st October. If you were to call them later in October, they may well have selected the new employee by the time you call. <br />
                  <strong>B.</strong>	They may alternatively say, “We don’t have a particular month in the year. At this stage we are likely to hire someone when the need arises”. If this is the case then you say: “Is it OK if I can call once a month to check if any positions come up?” Nine times out of ten the manager says to you that this is fine.</p>
                <p><strong>OR</strong></p>
                <p><strong>3. </strong><br />
                  <strong>They say:</strong><br />
                  “Yes. We will be hiring new employees.”<br />
                  <strong>You say:</strong><br />
                  “How should I go about applying? Do you know when, to whom and where to apply?”<br />
                  Make sure to get down every detail they give you.</p>
                <p>Remember, after any call, no matter what the result, say “Thank you”. It costs nothing to be polite and it keeps you being a real business-like young adult.</p>
              </Col>
            </PageSectionRow>
        </ContentWrapper>
} 
  

export default WhatToSayOnThePhone;