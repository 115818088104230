import React from "react";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import Cwiz, { CwizInfoType, CwizResult } from "../../data/Cwiz";
import { initalResults } from "./common";
import {CwizResponse } from "jobjump-types/Cwiz";
import CwizSubjects from "./Subjects";
import CwizTertiary from "./Tertiary";

const BullseyeResult : React.FC<{subject?:CwizInfoType, tertiary?:CwizInfoType, results:CwizResult[], reset:()=>void}> =
({subject, tertiary, reset, results}) => <>
    <PageSectionRow>
        <Col className="text-center">
            <h2>Results for {subject && subject.name} (Level: {tertiary && tertiary.name})</h2>
        </Col>
    </PageSectionRow>
    <PageSectionRow>
        <Col className="text-right">
            <Button variant="neutral" onClick={()=>window.print()}>Print</Button>
            <Button className="ml-8" variant="neutral" onClick={reset}>Start Again</Button>
        </Col>
    </PageSectionRow>
    <PageSectionRow>
        <Col>
        {results.map(m => <div key={m.career_name} className="mt-64 ml-64">
            <a href={m.career_url} target="_blank"><h3 className="cwiz-career">{m.career_name}</h3></a>
        </div>)}
        </Col>
    </PageSectionRow>
</>

const Bullseyes : React.FC = () => {
    const [response, setResponse] = React.useState<CwizResponse>(initalResults); 
    const [subjects, setSubjects] = React.useState<CwizInfoType[]>([]);
    const [tertiary, setTertiary] = React.useState<CwizInfoType[]>([]);
    const [results, setResults] = React.useState<CwizResult[]>([]);
    React.useEffect(() => {
        Cwiz.getCwizTertiary().then(setTertiary);
    }, []);
    React.useEffect(() => {
        Cwiz.getCwizSubjects().then(setSubjects);
    }, []);
    React.useEffect(() => {
        if (response.subject && response.tertiary) {
            Cwiz.getCwizResult(response).then(setResults);
        }
    })
    return <ContentWrapper title="Bullseyes" subtitle={["Find a career based on a subject of interest"]}>
        {!response.subject && <CwizSubjects goToPage={()=>{}} subjects={subjects} response={response} updateResponse={setResponse} />}
        {response.subject && !response.tertiary && <CwizTertiary goToPage={()=>{}} tertiary={tertiary} response={response} updateResponse={setResponse} />}
        {response.subject && response.tertiary && results.length > 0 && 
            <BullseyeResult 
                subject={subjects.find(s=>s.id === response.subject)}
                tertiary={tertiary.find(t=>t.id === response.tertiary)}
                reset={()=>{setResponse(initalResults); setResults([])}}
                results={results} />}
    </ContentWrapper>
}

export default Bullseyes;