import { IconPlayerPlay } from "@tabler/icons";
import React from "react"; 
import { makeClassName } from "../util";
import "./clippedImage.css";

type Props = {
    direction: "left" | "right",
}

const ClippedImage : React.FC<Props & React.ComponentProps<"img">> = 
({className, onClick, direction, ...props}) => {
    const classNames = makeClassName(className, [
        `jobjump-clipped-5-${direction}`,
        onClick ? `jobjump-clipped-clickable` : ""
    ]);
    return <div className={classNames} onClick={onClick}>
        <img {...props} onClick={onClick} />
        {onClick && <IconPlayerPlay fill="white" strokeWidth={0} width={70} height={70} className="play-icon" />}
    </div>
}

export default ClippedImage;
