import React, { PropsWithChildren } from "react";
import { ResumeKinds, GeneralResume, CareerResume, YesNoResponse } from "jobjump-types/OldResume";

import { CareerInfo } from "jobjump-types/Careers";
import { Form, Card } from "react-bootstrap";

export interface ResumeFormProps {
    careerID?: string,
    categoryID?: string,
    resumeKind: ResumeKinds,
    generalResume: GeneralResume,
    careerResume: CareerResume,
    saveResume: () => void,
    updateGeneralResume: (g: GeneralResume) => void,
    updateCareerResume: (c: CareerResume) => void
}

export interface ResumeFormChildrenProps extends ResumeFormProps {
    careerInfo: CareerInfo | null
}

interface YesNoComponentProp {
    onChange: (r: YesNoResponse) => void,
    name: string,
    value: YesNoResponse
}

const YesNoComponent: React.FC<YesNoComponentProp> =
    ({ onChange, name, value }) => {
        const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
            const newValue = e.currentTarget.value as "yes" | "no" | "";
            onChange({ ...value, value: newValue });
        }
        return <div>
            <Form.Check inline type="radio" name={name} value="yes" label="Yes" checked={value.value === "yes"} onChange={handleChange} />
            <Form.Check inline type="radio" name={name} value="no" label="No" checked={value.value === "no"} onChange={handleChange} />
        </div>
    }

interface ShowCareerInfoDetailsProps {
    toggleSection : React.FC<{onToggle : ()=>void}>
}

const ShowCareerInfoDetails: React.FC<PropsWithChildren<ShowCareerInfoDetailsProps>> =
    ({toggleSection : ToggleSection, children}) => {
        const [show, setShow] = React.useState(false);
        return <div>
            <ToggleSection onToggle={()=>setShow(!show)} />
            {show && <Card><Card.Body>
                {children}
            </Card.Body></Card>}
        </div>
    }

export {YesNoComponent, ShowCareerInfoDetails}

