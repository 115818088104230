import _ from "lodash";
import React from "react";
import CardButton from "../../components/cards/CardButton";
import { Col, Row } from "../../components/grid";
import { CwizInfoType } from "../../data/Cwiz";
import { CwizProps } from "./common";

const CwizTertiary : React.FC<CwizProps & {tertiary:CwizInfoType[]}> = ({tertiary, response: results, updateResponse: updateResults}) => {
    return <>
        <Row small>
            <Col className="mt-32 text-center">
                <h2>My Level Of Study</h2>
                <h3>Select the option below that describes the level of training you wish to take.</h3>
            </Col>
        </Row>
        <Row small>
            <Col className={"mt-32"}>
                {tertiary.map(t =>
                    <CardButton isSelected={results.tertiary === t.id} variant="active" className="mb-16" onClick={()=>updateResults({...results, tertiary:t.id})} key={`t-${t.id}`}>
                        <h4>{t.name}</h4>
                        <p>{t.description || ""}</p>
                    </CardButton>
                )}
            </Col>
        </Row>
    </>;
}

export default CwizTertiary;