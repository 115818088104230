import {AtarCategory, AtarResult, Institute} from "jobjump-types/ATAR";
import queryString from "query-string";
import { fetchWithAuth } from "./Utils";

const AtarAPI = {
    getAtarCategoriesForInst : (inst:string) : Promise<AtarCategory[]> =>
        fetchWithAuth(`/api/atar/inst/${inst}/categories`).then(r => r.json()).catch(console.log),

    getAtarCategories : () : Promise<AtarCategory[]> => 
        fetchWithAuth("/api/atar/categories").then(r => r.json()).catch(console.log),

    getAtarSubcategories: () : Promise<string[]> =>
        fetchWithAuth("/api/atar/subcategories").then(r => r.json()).catch(console.log),

    getAtarList : (category:string|string[], states:string[], inst?:number|number[]) : Promise<AtarResult[]> => {
        const params = queryString.stringify({
            career : JSON.stringify(category),
            states : JSON.stringify(states),
            inst : JSON.stringify(inst || [])
        });
        return fetchWithAuth("/api/atar/courses?"+params).then(r=>r.json())
    },

    getAtarDegreeSearch : (text:string, states:string[], inst?:number) : Promise<AtarResult[]> => {
        const params = queryString.stringify({
            text,
            states : JSON.stringify(states),
            inst
        });
        return fetchWithAuth("/api/atar/courses/degree?"+params).then(r=>r.json())
    },

    courseSearch : (institution:string, category:string, searchTerm:string) : Promise<AtarResult[]> => 
        fetchWithAuth("/api/atar/courseSearch?"+queryString.stringify({institution,category,searchTerm})).then(r=>r.json()),

    getInstitutes : (categories?:string[]) : Promise<Institute[]> =>
        fetchWithAuth("/api/atar/institutes?"+queryString.stringify({categories:JSON.stringify(categories)})).then(r=>r.json()).catch(console.log)
};

export default AtarAPI;