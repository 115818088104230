import React from "react";
import { RouteComponentProps } from "react-router";
import User from "../data/User";
import qs from "query-string";
import PageHero from "../components/PageHero";
import PageSectionRow from "../components/PageSectionRow";
import { Col, Row } from "../components/grid";
import Button from "../components/Button";
import ContentWrapper from "../components/ContentWrapper";

const UnsubscribePage : React.FC<RouteComponentProps> =
    ({history, location : {search}}) => 
        <ContentWrapper title="Unsubscribe">
            <PageSectionRow>
                <Col>
                    <h4>Aww, we're sorry to see you go! If you continue and wish to unsubscribe from JobJump email news, please press the button below.</h4>
                    <p><strong>Note:</strong> Once you've unsubscribed, you will no longer receive any email news updates from JobJump on careers of interest to you. 
                        This action is <strong>irreversible</strong>.</p>
                    <p>Should you still wish to unsubscribe, you will still be able to log in should you wish to the JobJump website.</p> 
                </Col>
            </PageSectionRow>
            <Row>
                <Col className="text-center">
                    <Button variant="active"
                        onClick={() => {
                            const args = qs.parse(search);
                            const {subID, addr} = args;
                            if (typeof subID === "string" && typeof addr === "string") {
                                User.unsubscribe(subID, addr).then(() => history.push("/"));
                            }
                        }}> 
                            Unsubscribe
                    </Button>
                </Col>
            </Row>
        </ContentWrapper>

export default UnsubscribePage;