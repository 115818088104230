import { IconDiamond, IconHeart, IconInfoCircle } from "@tabler/icons";
import _ from "lodash";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { CwizResult } from "../../data/Cwiz";
import { openLinkInNewTab } from "../../util";
import { CwizProps } from "./common";
import { CareerPreference, CareerPrefKind } from "../../data/CareerPreference";

const KindToName : {[k:string]:string} = {
    "personality": "Personality",
    "subject": "Favourite Subject",
    "category": "Skills & Values"
}

type ResultProps = {
    results:CwizResult[]; 
    prefs:CareerPreference[];
    addCareer:(kind:CareerPrefKind, id:number)=>void,
    deleteCareer:(kind:CareerPrefKind, id:number)=>void,
}

type ResultSectionProps = {
    numDiamonds:number, 
    matches:CwizResult[], 
    goToCareer:(id:number)=>void, 
    prefs:CareerPreference[], 
    onHeartClick:(id:number)=>void,
    showByDefault: boolean
}

const ResultsSection : React.FC<ResultSectionProps> = ({numDiamonds, matches, prefs, onHeartClick, goToCareer, showByDefault}) => {
    const [show, setShow] = React.useState(showByDefault);
    return <><Row>
        <Col>
            <h2 className="mb-8" style={{display:"inline-block"}}>
                {Array(Number(numDiamonds)).fill(0).map(i=><IconDiamond size={50} className="cwiz-svg" key={`${numDiamonds}-${i}`} />)}
                &nbsp;
                {Number(numDiamonds) === 3 && "Very Strong Choices"}
                {Number(numDiamonds) === 2 && "Strong Choices"}
                {Number(numDiamonds) === 1 && "Good Choices"}
                &nbsp;
            </h2>
            <Button className="ml-16" style={{display:"inline-block"}} variant="active" textButton onClick={()=>setShow(b=>!b)}>{show ? "Hide" : "Show"}</Button>
        </Col>
        
    </Row>
    <Row style={{display:show?undefined:"none"}}>
        <Col>
            <CardTable
                hideHeader
                data={matches}
                columns={[
                    {
                        width:1,
                        id:"heart",
                        Cell: ({row:{original}}) => <span onClick={()=>onHeartClick(original.career_id)} className="pointer">
                            <IconHeart className={prefs.find(p=>p.kind === "gu" && p.id === original.career_id ) ? "fill-icon" : undefined} />
                        </span>
                    },
                    {
                        width:5,
                        id:"career_name",
                        Cell:({row:{original}})=><a href={original.career_url} target="_blank"><h4 style={{color:"var(--primary-color)"}}>{original.career_name}</h4></a>
                    },
                    {
                        width:4,
                        id:"reason",
                        Cell:({row:{original:{origin}}})=><small>Based on your: {origin.map(o=>KindToName[o]).join(", ")}</small>
                    },
                    {
                        width:2,
                        id:"buttons",
                        Cell:({row:{original}})=><div className="text-right">
                            <Button variant="active" onClick={()=>goToCareer(original.jobjump_id)}>See Next Steps</Button>
                        </div>
                    }
                ]} />
        </Col>
    </Row>
    </>
}

const CwizResults : React.FC<CwizProps & ResultProps & RouteComponentProps> = ({results, prefs, addCareer, deleteCareer, goToPage, history}) => {
    const [matches, setMatches] = React.useState<{[k:number]:CwizResult[]}>({});
    React.useEffect(() => {
        setMatches(results.reduce((a, l) => ({
            ...a,
            [l.count]: (a[l.count] || []).concat(l)
        }), {} as {[k:number]:CwizResult[]}))
    }, [results]);
    const onHeartClick = (id:number) => {
        if (prefs.find(p => p.kind==="gu" && p.id === id)) {
            deleteCareer("gu", id);
        } else {
            addCareer("gu", id);
        }
    }
    return <>
        {Object.keys(matches).length > 0 && <PageSectionRow>
            <Col className="text-right">
                <Button variant="neutral" className="mr-8" onClick={()=>window.print()}>Print</Button>
                <Button variant="active" onClick={()=>goToPage("home")}>Continue Quiz</Button>
            </Col>
        </PageSectionRow>}
        {Object.keys(matches).length > 0 && <PageSectionRow>
            <Col className="text-center">
                <h3>Below are some career options based on your choices so far.</h3> 
                <h4>Use the <IconHeart /> to save anything you're interested in.</h4>
            </Col>
        </PageSectionRow>}
        {Object.keys(matches).length === 0 && <PageSectionRow>
            <Col className="text-center">
                <h4>There are no results for your selection. You may want to chose a different training level to find careers.</h4>
                <Button variant="active" onClick={()=>goToPage("tertiary")}>Choose Another Training Level</Button>
                <Button variant="active" className="ml-8" onClick={()=>goToPage("home")}>Start Again</Button>
            </Col>
        </PageSectionRow>}
        
        {(Object.keys(matches).sort().reverse()).map(count => <PageSectionRow key={`count-${count}`}>
            <Col>
                <ResultsSection
                    numDiamonds={Number(count)}
                    matches={matches[Number(count)]}
                    goToCareer={(id:number)=>history.push(`/myCareer/${id}`)}
                    onHeartClick={onHeartClick}
                    prefs={prefs}
                    showByDefault={(count === "3") || 
                        (count === "2" && (!matches[3] || matches[3].length === 0)) || 
                        (count === "1" && (!matches[2] || matches[2].length === 0) && (!matches[3] || matches[3].length === 0))} />
            </Col>
        </PageSectionRow>)}
        {Object.keys(matches).length > 0 && <PageSectionRow>
            <Col className="text-center">
                <Button variant="active" onClick={()=>goToPage("home")}>Continue Quiz</Button>
            </Col>
        </PageSectionRow>}
    </>;
}

export default CwizResults;