import React from "react";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "../../components/grid";
import HeroVideoRow from "../../components/HeroVideo";
import vidUrl from "../../assets/slider/video.mp4";
import DecoratedRow from "../../components/DecoratedRow";
import ImageCard from "../../components/cards/ImageCard";
import FindMyCareer from "../../assets/homepage/tiles/find-my-career-tool.jpg";
import HelpfulLinks from "../../assets/homepage/tiles/everything-you-need.jpg"
import TafeLink from "../../assets/homepage/tiles/studying-at-tafe.jpg"
import Bullseyes from "../../assets/homepage/tiles/atar-career-journey-2.jpg"
import MyCareer from "../../assets/school-homepage/my-career.png";
import GradCap from "../../assets/school-homepage/grad-cap.png";
import JobJumpLogoLG from "../../assets/logo.svg";
import Button from "../../components/Button";
import ClippedImage from "../../components/ClippedImage";
import TileImageStore from "../../assets/JobJumpPageImages";
import "./homepage.css";
import VideoModal from "../../components/VideoModal";
import PartnerSlider from "./PartnerSlider";
import { EventTracking } from "../../data/EventTracking";

const VideoIDs = {
    "ATAR" : "511905927",
    "MyCareer": "511905399",
    "Other": "512838743"
}

const logTileClick = (path:string, history:RouteComponentProps["history"]) => {
    EventTracking.trackUnauthEvent("clickHomepageTile", path).then(()=>history.push(path))
}

const Homepage : React.FC<RouteComponentProps> = (props) => {
    const {history} = props;
    const [currentVideo, setCurrentVideo] = React.useState<string>();
    React.useEffect(() => {
        document.title = "JobJump";
    }, []);
    return <>
        <HeroVideoRow title="Careers, Information and You." subtitle="Made Simple." videoUrl={vidUrl} />
        <DecoratedRow decorationType="diagonal-line">
            <Col>
                <Row>
                    <Col><h1 className="text-white">Accomplish Anything</h1></Col>
                </Row>
                <Row>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="Find My Career Quiz" onClick={()=>logTileClick("/cwiz", history)} imageUrl={FindMyCareer} /></Col>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="Explore My Career" onClick={()=>logTileClick("/myCareer", history)} imageUrl={MyCareer} /></Col>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="It's All Here" onClick={()=>logTileClick("/whereis", history)} imageUrl={HelpfulLinks} /></Col>
                </Row>
                <Row>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="Bullseyes" onClick={()=>logTileClick("/bullseyes", history)} imageUrl={Bullseyes} /></Col>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="Find My Uni Course" onClick={()=>logTileClick("/atar", history)} imageUrl={GradCap} /></Col>
                    <Col className="mt-32" sm={12} md={4}><ImageCard text="Find My TAFE Course" onClick={()=>logTileClick("/tafe-course", history)} imageUrl={TafeLink} /></Col>
                </Row>
            </Col>
        </DecoratedRow>
        <Row className="video-start">
            <Col>
                <h2>Some Of The People We Work With</h2>
            </Col>
        </Row>
        <Row className="mt-16">
            <Col>
                <PartnerSlider />
            </Col>
        </Row>
        <Row className="mt-64">
            <Col md={6} className="vertical-text">
                <span>
                    <h2>ATAR Course Search</h2>
                    <p>JobJump offers the most advanced ATAR Course Search. Students can quickly find the course they want 
                        for the ATAR they exepct. They can see any prerequsites or recommened senior subjects. Students
                        can save their course preferences, making their university application a breeze!</p>
                    <Button textButton variant="active" onClick={()=>history.push("/features")}>Find Out More</Button>
                </span>
            </Col>
            <Col md={6} className="text-center p-64">
                <ClippedImage onClick={()=>setCurrentVideo(VideoIDs["ATAR"])} direction="left" width="100%" src={TileImageStore["UniversityOpenDays"]} />
            </Col>
        </Row>
        <Row className="mt-64">
            <Col md={6} className="text-center p-64">
                <ClippedImage onClick={()=>setCurrentVideo(VideoIDs["MyCareer"])}  direction="right" width="100%" src={TileImageStore["StudentFindingACareer"]} />
            </Col>
            <Col md={6} className="vertical-text">
                <span>
                    <h2>Explore careers</h2>
                    <p>Go from knowing nothing to landing the job with JobJump's career exploration and scaffolded plan. 
                    JobJump offers career videos, eWork Experience projects, senior subject advice, tertiary courses 
                    and job listings in over 120 career areas.</p>
                    <Button textButton variant="active" onClick={()=>history.push("/features")}>Find Out More</Button>
                </span> 
            </Col>
        </Row>
        <Row className="mt-64 mb-64">
            <Col md={6} className="vertical-text">
                <span>
                    <h2>And so much more!</h2>
                    <p>That's just the begining. JobJump also includes a comprehensive university guide, 
                        scaffolded resumes for 120 careers, eWork Experience projects, TAFE course search,
                        Career VR videos.</p>
                    <Button textButton variant="active" onClick={()=>history.push("/features")}>Find Out More</Button>
                </span>
            </Col>
            <Col md={6} className="text-center p-64">
                <ClippedImage direction="left" onClick={()=>setCurrentVideo(VideoIDs["Other"])} width="100%" src={TileImageStore["UniversityScholarships"]} />
            </Col>
        </Row>
        <DecoratedRow decorationType="circles-bottom">
            <Col className="text-center pb-128">
                <img src={JobJumpLogoLG} height={150} className="mt-32 mb-64" />
                <p className="mb-8">550+ NSW High Schools, 120,000+ students and parents are registered and enjoying the benefits of JobJump!</p>
                <p className="mb-8">Why not find out what it’s all about? We can help you find the answers to your questions to really make your career happen!</p> 
                <p className="mb-8">It’s fun, fast and easy to find what you want in a couple of clicks.</p>
                <Button variant="active" onClick={()=>history.push("/features")}>Find Out More &amp; Order JobJump Now</Button>
            </Col>
        </DecoratedRow>
        {currentVideo && <VideoModal vimeoId={currentVideo} show={currentVideo !== undefined} onHide={()=>setCurrentVideo(undefined)} />}
    </>;
}

export default Homepage;