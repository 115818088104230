import { IconHeart, IconMailOpened } from "@tabler/icons";
import { StudentCareerRecord, StudentCwizResult, StudentDetails, StudentResumeListing, StudentSubject } from "jobjump-types/CaStudents";
import React from "react";
import { RouteComponentProps } from "react-router";
import CareerImages from "../../../assets/CareerImages";
import { getRandomImage } from "../../../assets/GenericImages";
import CardGrid from "../../../components/CardGrid";
import ImageCard from "../../../components/cards/ImageCard";
import RowCard from "../../../components/RowCard";
import { Col, Row } from "../../../components/grid";
import PageSectionRow from "../../../components/PageSectionRow";
import CaStudentDetails from "../../../data/CaStudentDetails";
import CaAdminLayout from "../CaAdminLayout";
import AtarPrefs from "./AtarPrefs";
import InterviewRecords from "./InterviewRecords";
import SentMessages from "./SentMessages";
import CaResume from "../../../data/CaResume";
import CardTable from "../../../components/CardTable";
import { Check } from "../../../components/Form";

type StudentDetailsMatchParam = {
    studentID : string
}

type StudentProfilePageProps = RouteComponentProps<StudentDetailsMatchParam>;

const StudentProfilePage : React.FC<StudentProfilePageProps> = (props) => {
    const {match : {params : {studentID}}, history} = props;
    const [studentDetails, setStudentDetails] = React.useState<StudentDetails>() 
    const [studentQuizResults, setStudentQuizResults] = React.useState<StudentCwizResult>();
    const [careers, setCareers] = React.useState<StudentCareerRecord[]>([]);
    const [resumes, setResumes] = React.useState<StudentResumeListing[]>([]);
    const [subjects, setSubjects] = React.useState<StudentSubject[]>([]);
    React.useEffect(() => {
        CaStudentDetails.studentDetails(studentID).then(setStudentDetails)
    }, [studentID]);
    React.useEffect(()=> {
        CaStudentDetails.getStudentsCareers(studentID).then(setCareers);
    }, [studentID]);
    React.useEffect(()=> {
        CaStudentDetails.getStudentQuizResults(studentID).then(setStudentQuizResults);
    }, [studentID]);
    React.useEffect(() => {
        CaResume.getStudentResumes(studentID).then(setResumes);
    }, [studentID]);
    React.useEffect(() => {
        CaStudentDetails.individualStudentSubjects(Number(studentID)).then(setSubjects);
    }, [studentID])
    return <CaAdminLayout 
        active="student"
        title={`${studentDetails ? `${studentDetails.student.first_name} ${studentDetails.student.last_name}` : ""} - Profile`}
        {...props}> 
        {careers.length > 0 && <PageSectionRow>
            <Col className="text-center">
                <h2>Students Saved Careers</h2>
                <p>Below are all the careers this student has expressed an interest in while using JobJump</p>
                <CardGrid numPerRow={2} prefix="careers">
                    {careers.map(c => <RowCard key={c.career}>{c.career}</RowCard>)}
                </CardGrid>
            </Col>
        </PageSectionRow>}
        {studentQuizResults && <PageSectionRow>
            <Col className="text-center">
                <h2>Students Careers Wiz Quiz Reponse</h2>
                <p>Below shows the results from the Career Wiz Quiz</p>
                <Row>
                    <Col>
                        <RowCard><h4>Career Field:</h4><br />{studentQuizResults.category_name || "No Response"}</RowCard>
                    </Col>
                    <Col>
                        <RowCard><h4>Personality:</h4><br />{studentQuizResults.personality_name || "No Response"}</RowCard>
                    </Col>
                    <Col>
                        <RowCard><h4>Subject:</h4><br />{studentQuizResults.subject_name || "No Response"}</RowCard>
                    </Col>
                    <Col>
                        <RowCard><h4>Tertiary Level:</h4><br />{studentQuizResults.tertiary_name || "No Response"}</RowCard>
                    </Col>
                </Row>
            </Col>
        </PageSectionRow>}
        {resumes.length > 0 && <PageSectionRow>
            <Col className="text-center">
                <h2>Student Resume</h2>
                <p>Below are the resumes this student has created. You may click on them to view and edit.</p>
                <CardGrid numPerRow={3} prefix="resumes">
                    {resumes.map((c, i) => 
                        <ImageCard key={i} text={c.resume_name} imageUrl={CareerImages[c.resume_name.toUpperCase()] || getRandomImage(0)} 
                            onClick={()=>history.push(`/admin/resume/${studentID}/${c.resume_id}`)} />
                    )}
                </CardGrid>
            </Col>
        </PageSectionRow>}
        {subjects.length > 0 && <PageSectionRow>
            <Col>
                <div className="text-center">
                    <h2>Senior Subject Preferences</h2>
                </div>
                <CardTable
                    data={subjects}
                    columns={[
                        {
                            'accessor': 'subject_name',
                            'Header': 'Subject',
                            width: 6,
                            disableSortBy:true
                        },
                        {
                            'accessor': 'subject_unit',
                            'Header': 'Units',
                            width: 2,
                            disableSortBy:true
                        },
                        {
                            'accessor': 'subject_category',
                            'Header': 'Category',
                            width: 2,
                            disableSortBy:true
                        },
                        {
                            'accessor': 'checked',
                            'Header': 'Checked',
                            Cell: ({row:{original}}) => 
                                <Check checked={original.subject_checked ?? false} disabled label="" />,
                            width: 2,
                            disableSortBy:true
                        }
                    ]} />
            </Col>
        </PageSectionRow>}
        {studentDetails && studentDetails.legacyPlans.length > 0 && <PageSectionRow>
            <Col className="text-center">
                <h2>Student Plans</h2>
                <p>Below are the plans this student has created. You may click on them to view and edit.</p>
                <CardGrid numPerRow={3} prefix="legacyPlan">
                    {studentDetails.legacyPlans.map((c, i) => 
                        !c.id ? <ImageCard key={i} text="Basic Pan" imageUrl={getRandomImage(0)} onClick={()=>history.push(`/admin/plan/${studentID}/unsure`)} /> :
                        <ImageCard text={c.name} imageUrl={CareerImages[c.name.toUpperCase()]} key={i}
                            onClick={()=>history.push(`/admin/plan/${studentID}/${c.is_career ? "career" : "category"}/${c.id}`)} />
                    )}
                </CardGrid>
            </Col>
        </PageSectionRow>}
        <PageSectionRow>
            <Col>
                <h2 className="text-center">Course Preferences</h2>
                <h5>Course selections made in the ATAR or TAFE search sections, using the <IconHeart /> button, appear below.</h5>
                <h5>You can send a message directly to participating institutes, by clicking on the row of the course you wish to enquire about, then
                    pressing the "Contact Selected Institutes" button.</h5>
                <h5>A <IconMailOpened /> icon indicates that a message has already been sent about that course.</h5>
                {studentDetails && <AtarPrefs studentDetails={studentDetails.student} />}
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2 className="text-center">Student Interview History</h2>
                {studentDetails && <InterviewRecords studentID={studentDetails.student.id} {...props} />}
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2 className="text-center">Student Message History</h2>
                {studentDetails && <SentMessages studentID={studentDetails.student.id} {...props} />}
            </Col>
        </PageSectionRow>
        {/*<PageSectionRow>
            <Col>
                <h2 className="text-center">Student News History</h2>
                <h5>...</h5>
            </Col>
        </PageSectionRow>*/}
    </CaAdminLayout>;
}

export default StudentProfilePage;
