import { fetchWithAuth } from "./Utils";

export type UniResponse = {
    selectedInsitutes: number[],
    additionalInformation: string[],
    categories: string[],
    additionalComments:string,
    contactConsent:boolean
}
const UniContactPreferences = {
    setPreferences : (prefs:UniResponse) =>
        fetchWithAuth("/api/uniContactPreferences", {
            method:"POST",
            headers:{"Content-Type": "application/json"},
            body:JSON.stringify(prefs)
        }),
    getPreferences : () : Promise<UniResponse|undefined> => 
        fetchWithAuth(`/api/uniContactPreferences`).then(r=>r.json())
}

export default UniContactPreferences;