import React from "react";
import PageSectionRow from "../../components/PageSectionRow";
import { Col } from "../../components/grid";
import { RouteComponentProps } from "react-router";
import {CareerCategory, CareerNames} from "jobjump-types/Careers";
import Careers from "../../data/Careers";
import CareerImages from "../../assets/CareerImages";
import { openLinkInNewTab } from "../../util";
import queryString from "query-string";
import ImageCard from "../../components/cards/ImageCard";
import CardGrid from "../../components/CardGrid";
import ContentWrapper from "../../components/ContentWrapper";

const MyCareerListing : React.FC<RouteComponentProps> =
    ({history, location} : RouteComponentProps) => {
        const args = queryString.parse(location.search);
        const [careerCategories, setCareerCategories] = React.useState<CareerCategory[]>([])
        React.useEffect(() => {
            Careers.getCareerCategories().then((d) => setCareerCategories(d));
        }, []);
        let category = args.category ? careerCategories.find(c => c.id === args.category) : undefined;
        return <ContentWrapper
                title="Explore Your Career"
                subtitle={["Select a career field to get started"]} >
            <PageSectionRow><Col>
                <CardGrid prefix="career-selector" numPerRow={3}>
                    {category && category.careers
                        .sort((a: CareerNames, b: CareerNames) => a.name > b.name ? 1 : -1)
                        .map(c =>
                            <ImageCard
                                key={`${c.name}-mycareer`}
                                imageUrl={CareerImages[c.name.toUpperCase()] || ""}
                                text={c.name}
                                onClick={()=>{history.push("/myCareer/"+String(c.id))}} />
                        )
                            
                    }
                    {category && <ImageCard
                        key={`${category}-see-more`}
                        imageUrl={CareerImages[category.name.toUpperCase()] || ""}
                        text="See More"
                        onClick={()=>{category && openLinkInNewTab(category.url)}}
                    />}
                    {!category && careerCategories.map(c =>
                        <ImageCard
                            key={`${c.name}-mycareer`}
                            imageUrl={CareerImages[c.name.toUpperCase()] || ""}    
                            text={c.name}
                            onClick={()=>{history.push("/myCareer?"+queryString.stringify({"category" : c.id}))}} />)
                    }
                </CardGrid>
            </Col></PageSectionRow>
        </ContentWrapper>;
    }

export default MyCareerListing;