import JobJumpMain from "./slider/video.mp4";
import STEM from "./slider/stem.mp4";
import Environment from "./slider/environmental.mp4";
import Girls from "./slider/girls.mp4";
import Indigenous from "./slider/indigenous.mp4";
import Multicultural from "./slider/multicultural.mp4";
import Male from "./slider/male-non-traditional.mp4";
import STEMSmall from "./slider/stem-small.mp4";
import EnvironmentSmall from "./slider/environmental-small.mp4";
import GirlsSmall from "./slider/girls-small.mp4";
import IndigenousSmall from "./slider/indigenous-small.mp4";
import MulticulturalSmall from "./slider/multicultural-small.mp4";
import MaleSmall from "./slider/male-non-traditional-small.mp4";
import JobJumpMainSmall from "./slider/video-small.mp4";

export type VideoKeys = keyof typeof VideoLoops;

const VideoLoops = { 
    "JobJumpMain" : JobJumpMain,
    "STEM": STEM,
    "Girls": Girls,
    "Environment": Environment,
    "Indigenous": Indigenous,
    "Multicultural": Multicultural,
    "Male": Male
}

const SmallVideoLoops : {[k in VideoKeys]: string} = { 
    "JobJumpMain" : JobJumpMainSmall,
    "STEM": STEMSmall,
    "Girls": GirlsSmall,
    "Environment": EnvironmentSmall,
    "Indigenous": IndigenousSmall,
    "Multicultural": MulticulturalSmall,
    "Male": MaleSmall,
}

export default VideoLoops;
export {SmallVideoLoops};