import React from "react";

type RadioProps = {
    onChange: (checked:boolean)=>void,
    name:string,
    label:string
} & Omit<React.ComponentProps<"input">, "onChange" | "name">;

const Radio : React.FC<RadioProps> = ({onChange, className, name, label, ...props}) => 
    <span className={"jobjump-radio-button "+(className || "")}><input type="radio" name={name} 
        onChange={({currentTarget:{checked}})=>onChange(checked)} {...props} /> {label}</span>

export default Radio;