import React from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import "./popoutHover.css";

type Props = {
    elem : JSX.Element,
    hasUnderline?:boolean,
    className?:string
}

const PopoutHover : React.FC<Props> = ({elem, hasUnderline, className, children}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
        } = usePopperTooltip();
    const hoverClass = "hoverable" + (hasUnderline ? " hoverable-text " : " ") + (className || "")
    return <>
        <div ref={setTriggerRef} className={hoverClass}>{elem}</div>
        {visible && <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' }) as any}>
            {children}
            <div {...getArrowProps({ className: 'tooltip-arrow' }) as any}></div>
        </div>}
    </>;
}

export default PopoutHover;