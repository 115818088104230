import React from "react";
import {Row, Col} from "../../components/grid";
import { StudentNeed } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { IconAmbulance, IconAward, IconBackhoe, IconBuildingHospital, IconCurrencyDollar, IconGlassFull, IconHome, IconId, IconPercentage, IconPlayerTrackNext, IconSchool, IconTicket, TablerIcon } from "@tabler/icons";
import PageSectionRow from "../../components/PageSectionRow";
import Button from "../../components/Button";
import { RouteComponentProps } from "react-router";

type Status = {name:string, icon:TablerIcon, statusName:string};
const statuses : Status[] = [
    {name:"ATAR", icon:IconPercentage, statusName:"atar"},
    {name:"USI", icon:IconId, statusName:"usi"},
    {name:"TFN", icon:IconCurrencyDollar, statusName:"tfn"},
    {name:"Scholarship", icon:IconAward, statusName:"scholarship"},
    {name:"EAS", icon:IconSchool, statusName:"eas"},
    {name:"Early Offer (SRS)", icon:IconPlayerTrackNext, statusName:"early-offer"},
    {name:"UCAT", icon:IconBuildingHospital, statusName:"umat"},
    {name:"White Card", icon:IconTicket, statusName:"white-card"},
    {name:"RSA", icon:IconGlassFull, statusName:"rsa"},
    {name:"First Aid", icon:IconAmbulance, statusName:"first-aid"},
    {name:"Accommodation", icon:IconHome, statusName:"accommodation"},
    {name:"Aprenticeship", icon:IconBackhoe, statusName:"apprentice"},
];

const CaStudentNeeds : React.FC<RouteComponentProps> =
    (props) => {
        const [need, setNeed] = React.useState("");
        const [data, setData] = React.useState<StudentNeed[]>([]);
        React.useEffect(() => {
            if (need !== "") {
                CaStudentDetails.studentNeeds(need).then(setData);
            }
        }, [need]);
        return <CaAdminLayout 
            active="needs"
            title="Needs"
            subtitle={["Select from a category below to find students with that need."]}
        {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Needs</h3>
                    <span>Use the buttons below to select a category. A list will appear that shows students who have indiciated they require this in their resume/plan.</span>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                {statuses.slice(0,6).map((s, i) => 
                    <Col sm={2} key={`l1-${i}`}><Button style={{width:"100%"}} className="mr-8" variant={need === s.statusName ? "active" : "neutral"} onClick={()=>setNeed(s.statusName)}>
                        {s.icon} {s.name}
                    </Button></Col>
                )}
            </PageSectionRow>
            <Row className="mt-8">
                {statuses.slice(6,12).map((s, i) => 
                <Col sm={2} className="text-center" key={`l2-${i}`}><Button style={{width:"100%"}} className="mr-8" variant={need === s.statusName ? "active" : "neutral"} onClick={()=>setNeed(s.statusName)}>
                    {s.icon} {s.name}
                </Button></Col>
                )}
            </Row>
            <PageSectionRow>
                <Col>
                    {need === "" && <h5>Please select from above to get started.</h5>}
                    {need !== "" && <CaStudentTable
                        data={data}
                        additionalColumns={[
                            {accessor:"value", Header:"Need?"}
                        ]}
                    />}
                </Col>
            </PageSectionRow>
        </CaAdminLayout>
    }

export default CaStudentNeeds;