import React from "react";
import {CareerNames} from "jobjump-types/Careers";
import CareerAPI from "../../data/Careers";
import { Col, Row } from "../../components/grid";
import Button from "../../components/Button";
import ContentWrapper from "../../components/ContentWrapper";
import { RouteComponentProps } from "react-router";
import _ from "lodash";
import CareerImages from "../../assets/CareerImages";
import FilterGridSelect from "./FilterGridSelect";
import "./careerSelect.css";
import Modal from "../../components/Modal";
import { EventTracking } from "../../data/EventTracking";
import RegistrationProgress from "./RegistrationProgress";
import CareerPreferences from "../../data/CareerPreference";
import CareerSelectBox, { SelectOption } from "./CareerSelectBox";

const CareerSelect : React.FC<RouteComponentProps> =
    ({history}) => {
        const [careers, setCareers] = React.useState<CareerNames[]>([]);
        const [showConfirm, setShowConfirm] = React.useState(false);
        const [selectedCareers, setSelectedCareers] = React.useState<SelectOption[]>([]);
        const [hasClickedTile, setHasClickedTile] = React.useState(false);
        const [hasUsedSearch, setHasUsedSearch] = React.useState(false);
        const recordTileClick = () => {
            if (hasClickedTile) {return;}
            EventTracking.trackEvent("regoTileClick", "true");
            setHasClickedTile(true);
        }
        const recordSearch = () => {
            if (hasUsedSearch) {return;}
            EventTracking.trackEvent("regoSearchUse", "true");
            setHasUsedSearch(true);
        }
        React.useEffect(() => {
            CareerAPI.getCareerList().then(careers => {
                setCareers(careers);
            });
        }, []);
        const nextButtonClick = () => {
            if (selectedCareers.length === 0) {
                setShowConfirm(true);
                return;
            }
            const subscriptions = selectedCareers.map(s => ({
                kind:s.career.kind === "goodEdu" ? "gu" : "jobjump" as "gu"|"jobjump",
                id : s.career.goodEdu?.id || s.career.jobjump?.id || -1
            }));
            CareerPreferences.bulkAdd(subscriptions).then(() => history.push("/"));
        }
        const setUnsure = () => {
            EventTracking.trackEvent("regoUnsureClick", "true");
            history.push("/");
        }
        return <ContentWrapper
                title="What kind of career interests you?"
                subtitle={["JobJump will keep you up to date with all the latest careers news, such as events, open days and scholarships."]}
                breadcrumbTitle="Registration">
            <Row style={{maxWidth:600}}>
                <Col className="text-center">
                    <RegistrationProgress stepsTaken={["School", "Details"]} />
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <h3>Search for any career you're interested in:</h3>
                </Col>
            </Row>
            <Row style={{maxWidth:"800px"}}>
                <Col>
                    <CareerSelectBox value={selectedCareers}
                        onChange={(value)=> {
                            recordSearch(); 
                            setSelectedCareers([...value])}
                        } />
                </Col>
            </Row>
            <Row className="mt-32 text-right" style={{maxWidth:"800px"}}>
                <Col>
                    <Button variant="active" onClick={nextButtonClick}>Complete Registration</Button>
                </Col>
            </Row>
            <Row className="mt-8 text-right" style={{maxWidth:"800px"}}>
                <Col>
                    <Button variant="negative" textButton onClick={setUnsure}>I'm not sure. Can I tell you later?</Button>
                </Col>
            </Row>
            <Row className="mt-32">
                <Col className="text-center">
                    <h3>or, select your favourite careers using the images below.</h3>
                    <FilterGridSelect data={careers} imageMap={CareerImages} numPerRow={4} 
                        selectionList={selectedCareers.map(({career})=>career.jobjump?.id !== undefined ? career.jobjump.id : career.goodEdu?.jobjump_id)
                            .filter(c=>c!==undefined) as number[]} 
                        onSelect={(id:number) => {
                            recordTileClick();
                            const isSelected = selectedCareers.find(({career}) => career.jobjump?.id === id || career.goodEdu?.jobjump_id === id);
                            if (isSelected) {
                                CareerAPI.deleteFavouriteCareer(isSelected.career.goodEdu?.id || isSelected.career.jobjump?.id || -1);
                            } else {
                                const career = careers.find(c => c.id === id);
                                if (!career) { return }
                                setSelectedCareers(selectedCareers.concat([{label:career.name, value:`jj-${career.id}`, career:{kind:"jobjump", jobjump:{...career}}}]));
                            }
                        }} />
                </Col>
            </Row>
            <Modal title="Are you sure?" show={showConfirm} onHide={()=>setShowConfirm(false)}>
                <Row>
                    <Col>
                        <h4>You haven't picked any careers yet! Are you sure you want to continue?</h4>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button variant="active" onClick={setUnsure}>Yes, I'll set my careers later</Button>
                        <Button className="ml-8" variant="active" onClick={()=>setShowConfirm(false)}>No, take me back</Button>
                    </Col>
                </Row>
            </Modal>
        </ContentWrapper>
    }

export default CareerSelect;