import qs from "query-string";
import { fetchWithAuth } from "./Utils";

const JsonPost = {
    method:"POST",
    headers: {
        "Content-Type": "application/json"
    }
}

interface UploadLink {
    uploadDetails: {[k:string]:any},
    key: string
}

export interface StudentFile {
    id:number,
    category:string,
    career?:{id:number, name:string},
    title?:string
}

const StudentFileUpload = {
    getUploadLink: (args:{fileType:string, fileName:string}) : Promise<UploadLink|undefined> =>
        fetchWithAuth("/api/files/getUploadLink?"+qs.stringify(args)).then(r=>r.json()),
    confirmUpload : (args:{key:string, category:string, careerId?:number, title?:string}) =>
        fetchWithAuth("/api/files/", {...JsonPost, body:JSON.stringify(args)}),
    getDownloadLink: (id:number) : Promise<string|undefined> => 
        fetchWithAuth("/api/files/link/"+id).then(r=>r.text()),
    deleteFile: (id:number) => 
        fetchWithAuth("/api/files/"+id, {method:"DELETE"}),
    getAllFiles : () : Promise<StudentFile[]> =>
        fetchWithAuth("/api/files/all").then(r=>r.json())
}

export default StudentFileUpload;