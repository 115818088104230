import arrayMove from "array-move";
import { Resume, SectionConfig } from "jobjump-types/Resume";
import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Col, Row } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { ResumeResponse } from "../../data/Resume";
import GenerateResume from "./GenerateResume";
import AwardsSection from "./sections/Awards";
import CareerGoalSection from "./sections/CareerGoal";
import { updateObjectIndex } from "./sections/common";
import EducationHistory from "./sections/EducationHistory";
import EmploymentHistorySection from "./sections/EmploymentHistory";
import HobbiesSection from "./sections/Hobbies";
import PersonalDetailsSection from "./sections/PersonalDetails";
import RefereeSection from "./sections/Referee";
import SkillsSection from "./sections/Skills";
import "./resume.css";
import EWorkExperienceSection from "./sections/EWorkExperience";
import AchievementsSection from "./sections/Achievements";
import QualificationsSection from "./sections/Qualifications";

type Props = {
    resume: Resume,
    content: Exclude<ResumeResponse["contentId"], undefined>,
    setResume: (r:Resume)=>void,
    save: ()=>Promise<void>
}

const SortableItem = SortableElement(
    ({ resume, section, setResume, content }: Props & {section:SectionConfig}) =>
        section.name === "awards" ? <AwardsSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.awards} 
            setSection={s => setResume({...resume, awards:s})} /> :
        section.name === "career_goal" ? <CareerGoalSection 
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.career_goal} 
            setSection={s => setResume({...resume, career_goal:s})} /> :
        section.name === "education_history" ? <EducationHistory 
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.education_history} 
            setSection={s => setResume({...resume, education_history:s})} /> :
        section.name === "employment_details" ? <EmploymentHistorySection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.employment_details} 
            setSection={s => setResume({...resume, employment_details:s})} /> :
        section.name === "hobbies" ? <HobbiesSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.hobbies} 
            setSection={s => setResume({...resume, hobbies:s})} /> :
        section.name === "achievements" ? <AchievementsSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.achievements} 
            setSection={s => setResume({...resume, achievements:s})} /> :
        section.name === "qualifications" ? <QualificationsSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.qualifications} 
            setSection={s => setResume({...resume, qualifications:s})} /> :

        section.name === "referees" ? <RefereeSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.referees} 
            setSection={s => setResume({...resume, referees:s})} /> :
        section.name === "skills" ? <SkillsSection
            sectionConfig={section}
            setSectionConfig={(config) => 
                setResume({
                    ...resume, 
                    order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                })}
            content={content} 
            section={resume.skills} 
            setSection={s => setResume({...resume, skills:s})} /> :
        section.name === "eWorkExperience"  ? 
                <EWorkExperienceSection
                    sectionConfig={section}
                    setSectionConfig={(config) => 
                        setResume({
                            ...resume, 
                            order:updateObjectIndex(resume.order, config, resume.order.findIndex(i => i.name === section.name))
                        })}
                    content={content} 
                    section={resume.eWorkExperience} 
                    setSection={s => setResume({...resume})} /> :
            <></>
);


const SortableList = SortableContainer(
    (props: Props) => <div>
        {props.resume.order.filter(o => !(o.name === "eWorkExperience" && props.resume.eWorkExperience.length === 0)).map((r: SectionConfig, i: number) => {
            return <div id={`sortable-resume-item-${r.name}`} key={"key-" + r.name}><SortableItem index={i} section={r} {...props} /></div>
        })}
    </div>
);

const ResumeBuilder :  React.FC<Props> = ({resume, content, setResume, save}) => { 
    React.useEffect(() => {
        const interval = setInterval(() => {
            save();
            clearInterval(interval);
        }, 500);
        return () => clearInterval(interval);
    }, [resume])
    return <>
    <PageSectionRow>
        <Col>
            <h2>Resume Style</h2>
        </Col>
    </PageSectionRow> 
    <GenerateResume resume={resume} setResume={setResume} save={save} />
    <PageSectionRow>
        <Col>
            <h2>Resume Details</h2>
        </Col>
    </PageSectionRow>
    <Row className="mt-8">
        <Col>
            <PersonalDetailsSection 
                sectionConfig={{hidden:false, name:"awards" /* this shoud never be used */}}
                setSectionConfig={()=>{}}
                content={content} 
                section={resume.personal_details} 
                setSection={s => setResume({...resume, personal_details:s})} />
            <SortableList 
                axis="xy"
                useWindowAsScrollContainer
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(resume.order, oldIndex, newIndex);
                    setResume({...resume, order});
                }} 
                resume={resume}
                content={content}
                save={save}
                setResume={setResume}
            />
        </Col>
    </Row>
    </>
}

export default ResumeBuilder;