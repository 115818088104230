import React from "react";
import {Col} from "../../components/grid";
import RowCard from "../../components/RowCard";
import ContentWrapper from "../../components/ContentWrapper";
import PageSectionRow from "../../components/PageSectionRow";
import { UserDetailsProps } from "../../layout/Routes";
import _ from "lodash";
import Button from "../../components/Button";
import { uniConfig } from "./UniLinkData";
import { openLinkInNewTab } from "../../util";

const OpenDays : React.FC<UserDetailsProps> = ({userDetails}) => {
    const states = [userDetails.state, ...(userDetails.additionalStates||[])];
    const data = Object.entries(uniConfig).filter(([n, d])=>d.events.length > 0);
    return <ContentWrapper 
            title="University Open Days" 
            subtitle={["Want to find out more about courses or institutes you're interested? Why not attend their open day!"]} >
        <PageSectionRow>
            <Col>
                {_.sortBy(data, ([n,d])=>n).map(([name, uni]) => 
                    uni.states.find(s=>states.includes(s)) &&
                        <RowCard key={name}>
                            <Col sm={2}>
                                {uni.image && <img src={uni.image} style={{maxHeight:"45px"}} />}
                            </Col>
                            <Col sm={3}>{name}</Col>
                            <Col sm={5}>
                                <ul>
                                    {uni.events.map((e, i) => 
                                        <li key={`${name}-${i}`}>
                                            <a target="_blank" href={e.url}>{`${e.date ? e.date.toDateString()+" - " : ""} ${e.campus || "Click Here"}`}</a>
                                        </li>
                                    )}
                                </ul>
                            </Col>
                            <Col sm={2}>
                                {uni.virtualTour.length > 0 && 
                                    <Button variant="active" onClick={()=>openLinkInNewTab(uni.virtualTour[0].link)}>Virtual Tour</Button>}
                            </Col>
                        </RowCard>
                )}
            </Col>
        </PageSectionRow>
    </ContentWrapper>
}

export default OpenDays;