import {  View, Text, StyleSheet } from "@react-pdf/renderer";
import { Resume } from "jobjump-types/Resume";
import React from "react";
import {  DocumentGrid, DocumentCell, SectionProps, OrderedResumeSections } from "./CommonSections";

const Section : React.FC<SectionProps<{heading1:any}>> = ({section, defaultTitle, style, children}) =>
    <View wrap={section.name === "employment_details"} style={{paddingBottom: "32px"}}>
        <DocumentGrid>
            <DocumentCell size={4}>
                <Text style={style.heading1}>{section.alias || defaultTitle}</Text>
            </DocumentCell>
            <DocumentCell size={8}>
                {children}
            </DocumentCell>
        </DocumentGrid>
    </View>

const ModernTitles : React.FC<{resume:Resume}> = ({resume}) => {
    const style = StyleSheet.create({
        page: {
            padding: "32px 32px 32px 32px",
            fontSize: "12pt",
            lineHeight: "1.5",
            fontFamily: resume.styleConfig.font !== "" ? resume.styleConfig.font : undefined
        },
        heading1: {
            fontSize: "18pt",
            color: resume.styleConfig.primaryColor,
            textAlign: "right"
        },
        heading2: {
            fontSize: "14pt",
        },
        smallText: {
            fontSize: "10pt",
        },
        sectionStyle: {
            paddingLeft: "16px"
        }
    })
    return <OrderedResumeSections resume={resume} style={style} SectionComponent={Section} />;
}

export default ModernTitles;