import {NewsItem} from "jobjump-types/News";
import queryString from "query-string";
import { fetchWithAuth } from "./Utils";

const News = {
    getNewsForCareer : (careerID : string) : Promise<NewsItem[]> =>
        fetchWithAuth("/api/news/career/"+careerID).then(r=>r.json()).catch(console.log),
    getNewsForTimePeriod : (start :number, end : number, careers : number[]) : Promise<NewsItem[]> => {
        const args = {start, end, careers:JSON.stringify(careers), kla: JSON.stringify([])};
        return fetchWithAuth("/api/news/calendar?"+queryString.stringify(args)).then(r=>r.json()).catch(console.log)
    }

}

export default News;