import React from "react";
import { Row, Col } from "../components/grid";

const License: React.FC<{}> =
    () => <Row><Col>
        <div className="page-header">
            <h1>www.jobjump.com.au License Agreement</h1>
        </div>
        <h4>THE PARTIES AGREE</h4>
        <h4>1 Grant of License</h4>
        <ol>
            <li><p>On the date specified in <strong>ITEM 3</strong> the Licensee has full access to the Website described in <strong>ITEM 2</strong> for the licence term described in <strong>ITEM 4</strong> on the terms set out in this Licence Agreement on the Terms and Conditions  (including the Privacy Policy) available on the Website. This access is provided through the provision of a unique username and password to the Licensee. </p></li>
            <li><p>The Website at all times remains in the sole and exclusive control and management of the Licensor and the Licensee may use/have access to the Website in accordance with the Terms and Conditions stipulated by the Licensor on the Website. </p></li>
            <li><p>This Licence is personal to the Licensee and is not capable of being assigned or transferred without the Licensor's prior written consent.  The Licensee will not allow anything to be done to impair the value of the Website.</p></li>
        </ol>
        <h4>2 License Fee and Service Fee</h4>
        <ol>
            <li><p>The Licensee will pay to the Licensor a licence fee for access to the Website as described in <strong>ITEM 5</strong> (the "Licence Fee") payable yearly in advance on or before the Licence Commencement Date.</p></li>
        </ol>
        <h4>3 Termination</h4>
        <ol>
            <li><p>If the Licensee terminates the Licence Agreement before the expiry of the Licence Term, the Licensee will forfeit the remaining paid Licence Fee.</p></li>
            <li><p>If the Licensee defaults in observing any term of this Agreement or those stipulated on the Website, the Licensor may give the Licensee <strong><i>seven (7)</i></strong> days written notice within which to rectify any such default.  If the Licensee fails to rectify the default within that period the Licensor may at any time thereafter terminate the Licence Agreement.</p></li>
        </ol>
        <h4>4 Indemnity</h4>
        <ol>
            <li><p>The Licensee indemnifies the Licensor in relation to all claims, demands, actions, orders, and other expenses which the Licensee may suffer or incur in connection with use of the Website.</p></li>
        </ol>
        <h4>5	Miscellaneous</h4>
        <ol>
            <li><p>This Agreement shall be governed by the law of New South Wales</p></li>
            <li><p>Any notice given under this Agreement must be delivered by email to <a href="mailto:contact@jobjump.com.au">contact@jobjump.com.au</a>.</p></li>
        </ol>
    </Col></Row>

export default License;