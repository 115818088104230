import React from "react";
import {Row,Col} from "../../components/grid";
import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import ColdCalling from "../../assets/my_job_ads/cold-calling.jpg";
import CommonQuestions from "../../assets/my_job_ads/common-interview-question.jpg";
import ExampleResume from "../../assets/my_job_ads/example-resume.jpg";
import MakeResumeJJ from "../../assets/my_job_ads/make-resume-jj.jpg";
import MeetingEmployer from "../../assets/my_job_ads/meeting-employer.jpg";
import WritingAResume from "../../assets/my_job_ads/writing-a-resume.jpg";
import { openLinkInNewTab } from '../../util';
import ModalVideo from "react-modal-video";
import 'react-modal-video/css/modal-video.min.css';
import { RouteComponentProps } from "react-router";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";
import { EventTracking } from "../../data/EventTracking";

const ContactAnEmployer : React.FC<RouteComponentProps> = ({history}) => {
    const [showCommonQuestions, setShowCommonQuestions] = React.useState(false);
    const [showColdCalling, setShowColdCalling] = React.useState(false);
    const openColdCalling = () => {
        EventTracking.trackEvent("watchColdCalling", "");
        setShowColdCalling(true);
    }
    const openInterviewPrep = () => {
        EventTracking.trackEvent("watchCommonQuestsion", "");
        setShowCommonQuestions(true);
    }
    return <ContentWrapper 
            title="Contacting an Employer"
            subtitle={["Found a job or company you want to work for? Here's how to land it!"]}>
        <PageSectionRow>
            <Col>
                <h2>Preparing a Resume and Cover Letter</h2>
                <h5>Before you apply for any job, you need a resume and cover letter.</h5>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                <CardGrid prefix="resume" numPerRow={3}>
                    <ImageCard imageUrl={MakeResumeJJ} text="Resume Builder" onClick={()=>history.push("/resume")} />
                    <ImageCard imageUrl={ExampleResume} text="Example Resume" onClick={()=>openLinkInNewTab(require("../../assets/docs/ResumeTemplate1.pdf"))} />
                    <ImageCard imageUrl={WritingAResume} text="Writing a Resume & Letter" onClick={()=>history.push("/whatToWrite")} />
                </CardGrid>
            </Col>
        </Row>
        <PageSectionRow>
            <Col>
                <h2>Contacting a Boss</h2>
                <h5>Now you've got your resume, you can start contacting employers.</h5>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                <CardGrid prefix="resume" numPerRow={2}>
                    <ImageCard imageUrl={CallingABoss} text="Calling A Boss" onClick={()=>history.push("/onThePhone")} />
                    <ImageCard imageUrl={ColdCalling} text="Cold Calling" onClick={openColdCalling} />
                </CardGrid>
            </Col>
        </Row>
        <PageSectionRow>
            <Col>
                <h2>The Interview</h2>
                <h5>What should I expect in an interview?</h5>
            </Col>
        </PageSectionRow>
        <Row>
            <Col>
                <CardGrid prefix="resume" numPerRow={2}>
                    <ImageCard imageUrl={CommonQuestions} text="Common Interview Questions" onClick={openInterviewPrep} />
                    <ImageCard imageUrl={MeetingEmployer} text="My Interview Questions" onClick={()=>history.push("/myQuestions")} />
                </CardGrid>
            </Col>
        </Row>
        <ModalVideo 
            channel="youtube" 
            videoId="uiSYKFN1P5E" 
            isOpen={showColdCalling} 
            onClose={()=>setShowColdCalling(false)} />
        <ModalVideo 
            channel="youtube" 
            videoId="OA8sZMkKuy0" 
            isOpen={showCommonQuestions} 
            onClose={()=>setShowCommonQuestions(false)} />
    </ContentWrapper>
}

export default ContactAnEmployer;
