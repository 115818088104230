import qs from "query-string";
import {StudentListing, StudentCareerRecord, StudentNeed, StudentSubject, 
    StudentLeavingYear, StudentTertiary, StudentDetails,
    StudentAtarPreferences, TertiaryEmailInfo, StudentInterviewRecord, StudentInterviewArgs,
    SentMessages,
    StudentReceivedMessages,
    StudentInterviewList,
    StudentCwizResult,
    StudentUniContact,
    StudentProjectRecord,
    StudentPreworkCheckStatus,
} from "jobjump-types/CaStudents";
import { fetchWithAuth } from "./Utils";
import { CoursePreferences } from "jobjump-types/Courses";

const CaStudentDetails = {
    listStudents : () : Promise<StudentListing[]> =>
        fetchWithAuth("/api/advisers/students/list").then(r=>r.json()).catch(console.log),
    studentCareers : () : Promise<StudentCareerRecord[]> =>
        fetchWithAuth("/api/advisers/students/careers").then(r=>r.json()).catch(console.log),
    studentNeeds : (need_type : string) : Promise<StudentNeed[]> =>
        fetchWithAuth(`/api/advisers/students/needs/${need_type}`).then(r=>r.json()).catch(console.log),
    studentSubjects : () : Promise<StudentSubject[]> =>
        fetchWithAuth("/api/advisers/students/subjects").then(r=>r.json()).catch(console.log),
    individualStudentSubjects : (id:number) : Promise<StudentSubject[]> =>
        fetchWithAuth("/api/advisers/students/subjects/"+String(id)).then(r=>r.json()).catch(console.log),
    studentLeavingYear : () : Promise<StudentLeavingYear[]> =>
        fetchWithAuth("/api/advisers/students/leavingYear").then(r=>r.json()).catch(console.log),
    studentTertiary : () : Promise<StudentTertiary[]> =>
        fetchWithAuth("/api/advisers/students/tertiary").then(r=>r.json()).catch(console.log),
    studentDetails : (student_id:string) : Promise<StudentDetails> =>
        fetchWithAuth(`/api/advisers/students/details/${student_id}`).then(r=>r.json()).catch(console.log),
    studentAtarPrefs : () : Promise<StudentAtarPreferences[]> =>
        fetchWithAuth("/api/advisers/students/atarPreferences").then(r=>r.json()).catch(console.log),
    studentIndvidualAtarPreferences : (student:string) : Promise<CoursePreferences[]> =>
        fetchWithAuth(`/api/advisers/students/atarPreferences/${student}`).then(r=>r.json()).catch(console.log),
    studentTertiarySendRecord : (student_id:string) : Promise<string[]> =>
        fetchWithAuth(`/api/advisers/students/tertiarySendRecord/${student_id}`).then(r=>r.json()).catch(console.log),
    tertiaryEmailInfo : () : Promise<TertiaryEmailInfo> =>
        fetchWithAuth("/api/advisers/tertiaryEmail/info").then(r=>r.json()).catch(console.log),
    studentInterviewRecord : (student:string) : Promise<StudentInterviewRecord[]> =>
        fetchWithAuth(`/api/advisers/interviews/getStudent/${student}`).then(r=>r.json()).catch(console.log),
    deleteStudentInterview : (id:string) : Promise<{}> =>
        fetchWithAuth(`/api/advisers/interviews/delete/${id}`),
    getAllStudentInterviews : () : Promise<StudentInterviewList[]> =>
        fetchWithAuth(`/api/advisers/interviews/all`).then(r=>r.json()).catch(console.log),
    sendTertiaryMessage : (
        studentID:string, 
        caName:string, 
        studentName:string, 
        studentYear:string, 
        toList:string[], 
        course:string[],
        extraInfo:string[],
        extraText:string
    ) : Promise<{}> => {
        const args = { studentID, caName, studentName, studentYear, toList:JSON.stringify(toList), course:JSON.stringify(course), extraInfo:JSON.stringify(extraInfo), extraText };
        return fetchWithAuth("/api/advisers/tertiaryEmail/sendEmail?"+qs.stringify(args))
    },
    recordStudentInterview : (args:StudentInterviewArgs) : Promise<{}> => 
        fetchWithAuth("/api/advisers/interviews/record?"+qs.stringify(args)),
    getStudentInterview : (interviewID:string) : Promise<StudentInterviewRecord> =>
        fetchWithAuth("/api/advisers/interviews/"+interviewID).then(r=>r.json()),
    updateInterview : (interviewID:string, args:StudentInterviewRecord) =>
        fetchWithAuth("/api/advisers/interviews/update/"+interviewID, {
            method:"POST",
            headers:{"Content-Type": "application/json"},
            body: JSON.stringify(args)
        }),
    deleteStudent : (student:string) : Promise<{}> =>
        fetchWithAuth("/api/advisers/students/delete/"+student),
    sendMessage : (args:{subject:string, message:string, students:string[]}) : Promise<{}> =>
        fetchWithAuth("/api/advisers/messaging/send?"+qs.stringify({...args,students:JSON.stringify(args.students)})),
    getSentMessages : () : Promise<SentMessages[]> =>
        fetchWithAuth("/api/advisers/messaging/list").then(r=>r.json()),
    getStudentReceivedMessages : (studentID : string) : Promise<StudentReceivedMessages[]> =>
        fetchWithAuth("/api/advisers/messaging/studentMessage/"+studentID).then(r=>r.json()),
    getQuizResults : () : Promise<StudentCwizResult[]> =>
        fetchWithAuth("/api/advisers/students/quizResults").then(r=>r.json()),
    getStudentQuizResults : (studentID:string) : Promise<StudentCwizResult|undefined> =>
        fetchWithAuth("/api/advisers/students/quizResults/"+studentID).then(r=>r.json()),
    getStudentsCareers : (studentID:string) : Promise<StudentCareerRecord[]> =>
        fetchWithAuth("/api/advisers/students/careers/"+studentID).then(r=>r.json()).catch(console.log),
    getStudentUniContactPendingApprovals : () : Promise<StudentUniContact[]> =>
        fetchWithAuth("/api/advisers/uniContact/pendingRequests").then(r=>r.json()).catch(console.log),

    getStudentUniPrefs : () : Promise<StudentUniContact[]> =>
        fetchWithAuth("/api/advisers/uniContact/").then(r=>r.json()).catch(console.log),

    approveStudentContact : (args:{user_id:number}[])  =>
        fetchWithAuth("/api/advisers/uniContact/approve", {
            method:"POST",
            headers:{"Content-Type": "application/json"},
            body: JSON.stringify(args)
        }).catch(console.log),
    removeStudentContactApproval : (args:{user_id:number})  =>
        fetchWithAuth("/api/advisers/uniContact/approve", {
            method:"DELETE",
            headers:{"Content-Type": "application/json"},
            body: JSON.stringify(args)
        }).catch(console.log), 
    getStudentWorkExperienceProjects : () : Promise<StudentProjectRecord[]> =>
        fetchWithAuth("/api/advisers/students/projects/").then(r=>r.json()).catch(console.log),
    downloadStudentProject : (id:number) : Promise<string|undefined> =>
        fetchWithAuth("/api/advisers/students/projects/download/"+id).then(r=>r.text()),
    preworkCheckStatus : () : Promise<StudentPreworkCheckStatus[]> =>
        fetchWithAuth("/api/advisers/students/preWorkCheck").then(r=>r.json()),
  

};

export default CaStudentDetails;