const openLinkInNewTab = (url:string) => { window.location.href = url };

const requirementListFromHTML = (htmlStr:string) : string[] => {
    const domParser = new DOMParser();
    const htmlDoc = domParser.parseFromString(htmlStr, 'text/html');
    const lis = htmlDoc.getElementsByTagName("li");
    const arr : HTMLLinkElement[] = Array.prototype.slice.call( lis );
    return arr.map((l) => l.innerText);
}

const linksInHtml = (htmlStr:string) : string[] => {
    const domParser = new DOMParser();
    const htmlDoc = domParser.parseFromString(htmlStr, 'text/html');
    const lis = htmlDoc.getElementsByTagName("a");
    const arr : HTMLLinkElement[] = Array.prototype.slice.call( lis );
    return arr.map((l) => l.href);
}

const getYoutubeParam = (url:string) : string | null => {
    const u = new URL(url);
    if ((u.host === "www.youtube.com" || u.host === "youtube.com") && u.searchParams.get("v") !== null) {
        return u.searchParams.get("v");
    }
    return null;
}

export {openLinkInNewTab, requirementListFromHTML, linksInHtml, getYoutubeParam};