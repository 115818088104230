import { HeroConfiguration, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import { RouteComponentProps } from "react-router";
import ThemeProvider from "../../../components/ThemeProvider";
import SchoolHomepageLayout from "../../school_homepage/Layout";

type SchoolDetailsProps = {
    schoolConfig : SchoolHomepage,
    heroConfig : HeroConfiguration,
    schoolId : string,
    logoUrl?:string
}

const PreviewSchoolSite : React.FC<SchoolDetailsProps & RouteComponentProps> = ({schoolConfig, schoolId, logoUrl, heroConfig, ...props}) => {
    return <ThemeProvider onlyToChildren primaryColor={schoolConfig.schoolColour}>
        <SchoolHomepageLayout 
            layout={schoolConfig}
            heroConfig={heroConfig}
            logoUrl={logoUrl}
            schoolId={schoolId}
            inBuilder
            {...props} />
    </ThemeProvider> 
}

export default PreviewSchoolSite;