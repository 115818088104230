import React from "react";
import Select, { GroupTypeBase, SelectComponentsConfig } from "react-select";
import deepEqual from "fast-deep-equal";

type MultiSelectProps<T extends {label:string, value:string}> = {
    name:string,
    className?:string,
    data: T[],
    onChange: (v:T[])=>void,
    value:T[],
    components?: SelectComponentsConfig<T, true, GroupTypeBase<T>>;
};

function MultiSelect<T extends {label:string, value:string}>({name, components, className, data, onChange, value}:MultiSelectProps<T>) {
    return <Select 
        isMulti
        hideSelectedOptions
        name={name}
        components={components}
        options={data}
        onChange={(v, action) => {
            if (action.action === "select-option" && action.option) {
                onChange(value.concat([action.option]))
            } else if (action.action === "remove-value" && action.removedValue) {
                onChange(value.filter(v=>!deepEqual(v, action.removedValue)))
            } else if (action.action === "clear") {
                onChange([]);
            }
        }}
        value={value}
        className={"jobjump-form-select "+className} />;
}

export default MultiSelect;

