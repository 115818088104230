import { Resume, SectionConfig } from "jobjump-types/Resume";
import React from "react";
import { PDFViewer, StyleSheet, Document, Page, View, PDFDownloadLink } from '@react-pdf/renderer';
import { Col, Row } from "../../components/grid";
import MinimalResume from "./resume_templates/MinimalBody";
import IconsResume from "./resume_templates/IconsCenteredBody";
import ModernTitles from "./resume_templates/ModernBody";
import { Label, Select } from "../../components/Form";
import { ChromePicker, CirclePicker, ColorChangeHandler } from "react-color";
import Button from "../../components/Button";
import MinimalHeader from "./resume_templates/MinimalHeader";
import SimpleCentreHeader from "./resume_templates/SimpleCentreHeader";
import ColouredBlockHeader from "./resume_templates/ColouredBlockHeader";
import CompactHeader from "./resume_templates/CompactHeader";
import ColouredBlockCompactHeader from "./resume_templates/ColouredBlockCompactHeader";
import { contrastingColor, DocumentCell, DocumentGrid, loadFont } from "./resume_templates/CommonSections";
import SimpleLHS from "./resume_templates/SimpleLHS";
import DoubleLineBody from "./resume_templates/DoubleLinesBody";
import ColouredBackCentreBody from "./resume_templates/ColouredBackCentreBody";
import ColouredBackLeftBody from "./resume_templates/ColouredBackLeftBody";
import IconBodyCompact from "./resume_templates/IconsCompactBody";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import { CenteredHeader, ColouredBackLHS, ColouredBoxCenterSimpleHeader, ColouredCompactModernTitle, CompactColouredBoxLHS, CompactColouredDoubleLine, CompactIconsLHS, DoubleLineLHS, IconCompactColouredBlock, IconsFull, Minimal, ColouredBlockHeader as ColouredBlockHeaderSVG, MinimalHeader as MinimalHeaderSVG, CompactColouredBlockHeader, CompactHeader as CompactHeaderSVG, LHSHeader, CompactIconBody, IconBody, MinimalBody, DoubleLineBody as DoubleLineBodySvg, ColouredBackTitleLeft, ColouredBackTitleCentre, ModernTitleBody, WordDocSVG } from "./resume_thumbs/Thumbs";
import Modal from "../../components/Modal";
import { openLinkInNewTab } from "../../util";
import { components, OptionProps, SingleValueProps } from "react-select";
import _ from "lodash";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import BaseCard from "../../components/cards/BaseCard";
import { DefaultSectionNames, SortableItemTemplate } from "./sections/common";
import { generateResume } from "./GenerateWordDoc";
import {saveAs} from "file-saver";
import Alert from "../../components/Alert";

const generateResumeDocument = (resume:Resume) => {
    if (resume.styleConfig.font !== "") {
        loadFont(resume.styleConfig.font);
    }
    const headersWithCareerGoals = ["coloured-block", "compact", "compact-coloured"];
    const lhsLayouts = ["simple-lhs"];
    const isLhsLayout = lhsLayouts.includes(resume.styleConfig.headerStyle)
    const modifiedResume = headersWithCareerGoals.includes(resume.styleConfig.headerStyle) ?
        {...resume, order:resume.order.filter(s => s.name !== "career_goal")} : resume;
    const style = StyleSheet.create({
        page: {
            padding: isLhsLayout ? "0px 0px 0px 0px" : "16px 16px 16px 16px",
            fontSize: "12pt",
            lineHeight: "1.5",
            fontFamily: resume.styleConfig.font !== "" ? resume.styleConfig.font : undefined
        },
        lhsContentContainer: {
            paddingTop: "16px",
            paddingLeft: "16px",
            paddingRight: "16px"
        },
    });

    const header = resume.styleConfig.headerStyle === "minimal" ?
            <MinimalHeader resume={modifiedResume} /> :
        resume.styleConfig.headerStyle === "simple-centre" ?
            <SimpleCentreHeader resume={modifiedResume} /> :
        resume.styleConfig.headerStyle === "coloured-block" ?
            <ColouredBlockHeader resume={modifiedResume} /> :
        resume.styleConfig.headerStyle === "compact" ?
            <CompactHeader resume={modifiedResume} /> :
        resume.styleConfig.headerStyle === "compact-coloured" ?
            <ColouredBlockCompactHeader resume={modifiedResume} /> :
        resume.styleConfig.headerStyle === "simple-lhs" ?
            <SimpleLHS resume={modifiedResume} /> :
            undefined;

    const body =  resume.styleConfig.style === "minimal" ?
        <MinimalResume resume={modifiedResume} /> :
    resume.styleConfig.style === "modern-title" ?
        <ModernTitles resume={modifiedResume} /> :
    resume.styleConfig.style === "icons" ?
        <IconsResume resume={modifiedResume} /> :
    resume.styleConfig.style === "icons-compact" ?
        <IconBodyCompact resume={modifiedResume} /> :
    resume.styleConfig.style === "double-line" ?
        <DoubleLineBody resume={modifiedResume} /> :
    resume.styleConfig.style === "colour-back-left" ?
        <ColouredBackLeftBody resume={modifiedResume} /> :
    resume.styleConfig.style === "colour-back-centre" ?
        <ColouredBackCentreBody resume={modifiedResume} /> :
        undefined;

    if (isLhsLayout) {
        return <Document>
            <Page wrap size="A4" style={style.page} >
                <DocumentGrid>
                    <DocumentCell size={4}>
                        <View fixed>
                            {header && header}
                        </View>
                    </DocumentCell>
                    <DocumentCell size={8}>
                        <View style={style.lhsContentContainer}>
                            {body && body}
                        </View>
                    </DocumentCell>
                </DocumentGrid>
            </Page>
        </Document>
    }

    return <Document>
        <Page wrap size="A4" style={style.page}>
            {header && header}
            {body && body}
        </Page>
    </Document>
}

const ColorPicker : React.FC<{resume:Resume, handleColorUpdate:ColorChangeHandler}> = ({resume, handleColorUpdate}) => {
    const [showAdvancedColor, setShowAdvancedColor] = React.useState(false);
    const colorList = [
        "#C31F1F",
        "#C44949",
        "#EA6FC3",
        "#AD49C4",
        "#7449C4",
        "#495CC4",
        "#49C4C4",
        "#49C492",
        "#4FC449",
        "#09A701",
        "#EEF335",
        "#E0830D",
    ]
    return <>
        <Row>
            <Col>
                <Label>Primary Colour</Label>
            </Col>
        </Row>
        {showAdvancedColor && <Row>
            <Col>
                <div style={{display:"block"}}><ChromePicker color={resume.styleConfig.primaryColor} disableAlpha onChange={handleColorUpdate}/></div>
                <Button variant="neutral" className="mt-16" onClick={()=>setShowAdvancedColor(!showAdvancedColor)}>Show Less Colours</Button>
            </Col>
        </Row>}
        {!showAdvancedColor && <Row>
            <Col>
                <div style={{display:"block"}}><CirclePicker color={resume.styleConfig.primaryColor} colors={colorList} onChange={handleColorUpdate} /></div>
                <Button variant="neutral" className="mt-16" onClick={()=>setShowAdvancedColor(!showAdvancedColor)}>More Colour Options</Button>
            </Col>
        </Row>}
    </>
}


const FontSelector : React.FC<{resume:Resume, setFont:(font:string)=>void}> =({resume, setFont}) => {
    const fonts = _.sortBy([
        {value:"Roboto", label:"Roboto", font:"roboto"},
        {value:"Poppins", label:"Poppins", font:"poppins"},
        {value:"Lato", label:"Lato", font:"lato"},
        {value:"Bitter", label:"Bitter", font:"bitter"},
        {value:"Inter", label:"Inter", font:"inter"},
        {value:"Indie", label:"Indie Flower", font:"indieFlower"},
        {value:"ShadowsIntoLight", label:"Shadows Into Light", font:"shadowsIntoLight"},
        {value:"Abril", label:"Abril", font:"abril"},
        {value:"Architects Daughter", label:"Acrchitects Daughter", font:"architect"},
        {value:"Dancing Script", label:"Dancing Script", font:"dancing"},
        {value:"Dosis", label:"Dosis", font:"dosis"},
        {value:"Inconsolata", label:"Inconsolata", font:"inconsolata"},
        {value:"Italianno", label:"Italianno", font:"italianno"},
        {value:"Open Sans", label:"Open Sans", font:"open-sans"},
        {value:"Pacifico", label:"Pacifico", font:"pacifico"},
        {value:"Playfair", label:"Playfair", font:"playfair"},
        {value:"Satisfy", label:"Satisfy", font:"satisfy"},
        {value:"Teko", label:"Teko", font:"teko"},
    ], "value");
    const Option : React.FC<OptionProps<{value:string,label:string}, false>> = (props) =>  
        <div className={fonts.find(f => f.value === props.data.value)?.font}>
            <components.Option {...props} />
        </div>

    const SingleValue : React.FC<SingleValueProps<{value:string,label:string}>> = (props) =>
        <div className={fonts.find(f => f.value === props.data.value)?.font}>
            <components.SingleValue {...props} />
        </div>

    return <Row><Col>
        <Label>Font</Label>
        <Select 
            isDisabled={resume.styleConfig.headerStyle === "word" && resume.styleConfig.style === "word"}
            options={fonts}
            value={fonts.find(f => f.value === resume.styleConfig.font)}
            components={{Option, SingleValue}}
            onChange={(v)=>v && setFont(v.value)} />
    </Col></Row>
}

const SortableItem = SortableElement(
    ({ section }:  {section:SectionConfig}) =>
        <BaseCard className="mb-8" style={{padding:"8px 8px 8px 8px"}}>
            <SortableItemTemplate>
                <Row className="mb-8">
                    <Col noPadding>
                        {section.alias || DefaultSectionNames[section.name]}
                    </Col>
                </Row>
            </SortableItemTemplate>
        </BaseCard>
)

const SortableList = SortableContainer(
    (props: {resume:Resume, setResume:(r:Resume)=>void}) => <div>
        {props.resume.order.map((r: SectionConfig, i: number) => {
            return <div id={`sortable-resume-item-${r.name}`} key={"key-" + r.name}><SortableItem index={i} section={r} {...props} /></div>
        })}
    </div>
);

const OrderSections : React.FC<{resume:Resume, setResume:(r:Resume)=>void}> = ({resume, setResume}) => 
    <Row>
        <Col>
            <Label>Section Order</Label>
            <SortableList 
                axis="xy"
                useDragHandle
                onSortEnd={({oldIndex, newIndex}) => {
                    const order = arrayMove(resume.order, oldIndex, newIndex);
                    setResume({...resume, order});
                }} 
                resume={resume}
                setResume={setResume}
            />
        </Col>
    </Row>



const GenerateResume : React.FC<{resume:Resume, setResume:(r:Resume)=>void, save:()=>Promise<void>}> = ({resume, setResume, save}) => {
    const [showAdvancedEditor, setShowAdvancedEditor] = React.useState(false);
    const [showPreview, setShowPreview] = React.useState(false);
    const [resumeDoc, setResumeDoc] = React.useState<JSX.Element>();
    const [saveSuccess, setSaveSuccess] = React.useState(false);
    const [saveError, setSaveError] = React.useState<string>();
    
    const handleColorUpdate : ColorChangeHandler = ({hex}) => {
        setResume({...resume, styleConfig:{...resume.styleConfig, primaryColor:hex}});
    };

    React.useEffect(() => {
        setResumeDoc(generateResumeDocument(resume));
    }, [resume])

    const headerColor = contrastingColor(resume.styleConfig.primaryColor) === "#000000" ? "#555555" : "#F6F6F6";

    const saveWordDoc = () => {
        generateResume(resume,(blob:Blob) => {
            saveAs(blob, "resume.docx");
        })
    }

    const saveButton = React.useCallback(() => {
        save()
            .catch(error => {
                setSaveError(error);
                setTimeout(()=>setSaveError(undefined), 40*1000);
                return true
            }) 
            .then(r =>{
                if (!r) {
                    setSaveSuccess(true);
                    setTimeout(()=>setSaveSuccess(false), 5*1000);
                }
            })
    }, [save, setSaveError, setTimeout, setSaveSuccess]);

    const openPreview = React.useCallback(() => {
        save().then(() => setShowPreview(true));
    }, [save]);

    return <>
        <Row className="mt-8">
            <Col sm={3}>
                <Row className="mb-8"><Col>
                    <FontSelector resume={resume} setFont={(font)=>setResume({...resume, styleConfig:{...resume.styleConfig, font}})} />
                </Col></Row>
                <Row className="mb-8"><Col>
                    <ColorPicker resume={resume} handleColorUpdate={handleColorUpdate} />
                </Col></Row>
                <Row className="mb-8"><Col>
                    <OrderSections resume={resume} setResume={setResume} />
                </Col></Row>
            </Col>
            {!showAdvancedEditor && <Col sm={9}>
                <CardGrid prefix="resume-styles" numPerRow={5}>
                    <ImageCard className="resume-template-card" selectedGlow
                        selected={resume.styleConfig.headerStyle === "simple-lhs" && resume.styleConfig.style === "colour-back-left"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-lhs", style:"colour-back-left"}})}>
                        <ColouredBackLHS primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "simple-centre" && resume.styleConfig.style === "colour-back-centre"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-centre", style:"colour-back-centre"}})}>
                        <ColouredBoxCenterSimpleHeader primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "compact-coloured" && resume.styleConfig.style === "modern-title"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "compact-coloured", style:"modern-title"}})}>
                        <ColouredCompactModernTitle primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "simple-centre" && resume.styleConfig.style === "colour-back-left"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-centre", style:"colour-back-left"}})}>
                        <CompactColouredBoxLHS primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "compact-coloured" && resume.styleConfig.style === "double-line"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "compact-coloured", style:"double-line"}})}>
                        <CompactColouredDoubleLine primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "simple-lhs" && resume.styleConfig.style === "icons-compact"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-lhs", style:"icons-compact"}})}>
                        <CompactIconsLHS primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "simple-lhs" && resume.styleConfig.style === "double-line"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-lhs", style:"double-line"}})}>
                        <DoubleLineLHS primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "coloured-block" && resume.styleConfig.style === "icons-compact"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "coloured-block", style:"icons-compact"}})}>
                        <IconCompactColouredBlock primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "simple-centre" && resume.styleConfig.style === "icons"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-centre", style:"icons"}})}>
                        <IconsFull primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>            
                    <ImageCard className="resume-template-card" selectedGlow 
                        selected={resume.styleConfig.headerStyle === "minimal" && resume.styleConfig.style === "minimal"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "minimal", style:"minimal"}})}>
                        <Minimal primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                    </ImageCard>
                    <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "word" && resume.styleConfig.headerStyle === "word"}
                        onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "word", headerStyle:"word"}})}>
                            <div className="mt-16 mb-32"><WordDocSVG /></div>
                            <h4 className="mt-8">Simple Word Document</h4>
                    </ImageCard>
                </CardGrid>
            </Col>}
            {showAdvancedEditor && <Col sm={9}>
                <Row>
                    <Col>
                        <h4>Heading Style</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardGrid prefix="resume-styles-header" numPerRow={6}>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "simple-centre"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-centre"}})}>
                                <CenteredHeader primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "coloured-block"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "coloured-block"}})}>
                                <ColouredBlockHeaderSVG primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "minimal"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "minimal"}})}>
                                <MinimalHeaderSVG primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "compact-coloured"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "compact-coloured"}})}>
                                <CompactColouredBlockHeader primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "compact"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "compact"}})}>
                                <CompactHeaderSVG primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.headerStyle === "simple-lhs"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, headerStyle: "simple-lhs"}})}>
                                <LHSHeader primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                        </CardGrid>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Body Style</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardGrid prefix="resume-styles-header" numPerRow={4}>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "icons-compact"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "icons-compact"}})}>
                                <CompactIconBody primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "icons"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "icons"}})}>
                                <IconBody primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "minimal"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "minimal"}})}>
                                <MinimalBody primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "double-line"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "double-line"}})}>
                                <DoubleLineBodySvg primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "colour-back-left"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "colour-back-left"}})}>
                                <ColouredBackTitleLeft primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "colour-back-centre"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "colour-back-centre"}})}>
                                <ColouredBackTitleCentre primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>
                            <ImageCard className="resume-template-card" selectedGlow selected={resume.styleConfig.style === "modern-title"}
                                onClick={()=>setResume({...resume,styleConfig:{...resume.styleConfig, style: "modern-title"}})}>
                                <ModernTitleBody primaryColor={resume.styleConfig.primaryColor} titleColor={headerColor} />
                            </ImageCard>

                        </CardGrid>
                    </Col>
                </Row>
            </Col>}
        </Row>
        {saveSuccess && <Row className="mt-16 text-center">
            <Col>
                <Alert variant="active" text="Resume successfully saved" textOnly />
            </Col>
        </Row>}
        {saveError && <Row className="mt-16 text-center">
            <Col>
                <Alert variant="negative" text={`Error while saving resume: ${saveError}`} textOnly />
            </Col>
        </Row>}
        <Row className="mt-16">
            <Col className="text-center">
                <Button variant="neutral" onClick={()=>setShowAdvancedEditor(a=>!a)}>
                    {showAdvancedEditor ? "Less Options" : "More Options"}
                </Button>
                {/*<Button variant="active" className="ml-8" onClick={saveButton}>Save</Button>*/}
                {resume.styleConfig.style !== "word" && resume.styleConfig.headerStyle !== "word" && 
                    <Button variant="active" className="ml-8" onClick={openPreview}>Save, Preview and Download</Button>}
                {resume.styleConfig.style === "word" && resume.styleConfig.headerStyle === "word" && 
                    <Button variant="active" className="ml-8" onClick={()=>saveWordDoc()}>Download Document</Button>}
            </Col>
        </Row>
        <Modal show={showPreview} onHide={()=>setShowPreview(false)} size="lg">
            <Row>
                <Col className="text-right">
                    {resumeDoc && <PDFDownloadLink document={resumeDoc} fileName="resume.pdf">
                        {({url, loading}) => !loading && url && <Button variant="active" onClick={()=>openLinkInNewTab(url)}>Download</Button>}
                    </PDFDownloadLink>}
                </Col>
            </Row>
            <Row className="mt-16">
                <Col sm={3}>
                    <Row>
                        <Col>
                            <FontSelector resume={resume} setFont={(font)=>setResume({...resume, styleConfig:{...resume.styleConfig, font}})} />
                        </Col>
                    </Row>
                    <Row className="mt-32">
                        <Col>
                            <ColorPicker resume={resume} handleColorUpdate={handleColorUpdate} />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {resumeDoc && <PDFViewer width="100%" height="800">
                        {resumeDoc}
                    </PDFViewer>}
                </Col>
            </Row>
        </Modal>
    </>
}

export default GenerateResume;