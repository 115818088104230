import React, { PropsWithChildren } from "react";
import { RegistrationStepProps } from "./RegistrationTypes";
import {RegistrationDetails}  from "jobjump-types/User";
import User from "../../data/User";
import PageSectionRow from "../../components/PageSectionRow";
import { Col, Row } from "../../components/grid";
import { Check, Label, Select, TextField } from "../../components/Form";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import ContentWrapper from "../../components/ContentWrapper";
import RegistrationProgress from "./RegistrationProgress";

type StringFieldNames<T> = { [K in keyof T]: T[K] extends string|number ? K : never }[keyof T];

interface DetailsInputProps {
    details:RegistrationDetails, 
    update:(d:RegistrationDetails)=>void,
    field : StringFieldNames<RegistrationDetails>,
    title : string,
    type? : string,
    valid : boolean,
    invalidText?: string
    isPassword?:boolean
}

const DetailsSelectInput : React.FC<DetailsInputProps & {options:{value:string,label:string}[]}> = 
({details, update, field, title, valid, invalidText, options}) =>
    <Row><Col>
        <Label>{title}</Label>
        <Select
            placeholder={title}
            options={options}
            onChange={(value) => update({
                ...details,
                [field] : 
                    value ? (typeof(details[field]) === "number" ? Number(value.value) : value.value) : undefined
            })} />
        {!valid && <Alert textOnly variant="negative" text={invalidText || "Please enter a value"} />}
    </Col></Row>

const DetailsTextInput : React.FC<PropsWithChildren<DetailsInputProps>> = 
({details, update, field, title, isPassword, valid, invalidText}) =>
    <Row><Col>
        <Label>{title}</Label>
        <TextField 
            placeholder={title}
            emailField={field==="email"}
            passwordField={isPassword}
            value={String(details[field])}
            onChange={(value) => update({
                ...details,
                [field] : 
                    typeof(details[field]) === "number" ? Number(value) : value
            })} />
        {!valid && <Alert textOnly variant="negative" text={invalidText || "Please enter a value"} />}
    </Col></Row>

const Details : React.FC<RegistrationStepProps> =
    ({details, setRegistrationDetails, onStepCompletion, history}) => {
        const [confirmPassword, setConfirmPassword] = React.useState("");
        const [showAlreadyRegistered, setShowAlreadyRegistered] = React.useState(false);
        const [validatedFields, setValidatedFields] = React.useState<{[k in StringFieldNames<RegistrationDetails>|"passwordLen"]:boolean}>({
            firstName: true, lastName: true, gender: true, registrationKind: true, 
            year:true, email: true, password: true, schoolID:true, schoolPassword:true, passwordLen:true
        });
        const [buttonPressed, setButtonPressed] = React.useState(false);
        const [showForgotPassword, setShowForgotPassword] = React.useState(false);
        const nextButtonClick = () => {
            setValidatedFields(Object.keys(validatedFields).reduce((a, k) => {
                switch (k) {
                    case "firstName":
                    case "lastName":
                    case "gender":
                    case "registrationKind":
                    case "year":
                        if (details[k] === "") { return {...a, [k] : false}}
                        break;
                    case "email":
                        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]+)+$/;
                        if (!filter.test(details[k])) {
                            return { ...a, [k] : false}
                        }
                        break;
                    case "password":
                        if (details.password !== confirmPassword) { return {...a, password:false}}
                        break;
                    case "passwordLen":
                        if (details.password.length < 6) { return {...a, passwordLen:false}}
                        break;
                }
                return {...a, [k]: true};

            }, validatedFields));
            setButtonPressed(true);
        };
        // Watch field validation and when everything is valid continue.
        // Validation only occurs on button press
        React.useEffect(() => {
            if (buttonPressed) {
                const valid = Object.entries(validatedFields).reduce((a, [s,b]) => a && b, true)
                if (valid) {
                    User.hasPassword(details.email).then(r => {
                       if (r === "InvalidAccount") { onStepCompletion(); return } 
                       setButtonPressed(false);
                       setShowAlreadyRegistered(true);
                    });
                }
            }
        }, [validatedFields, buttonPressed, onStepCompletion, details.email])
        const resetPassword = React.useCallback(() => {
            User.forgotPassword(details.email).then(() => {
                setShowAlreadyRegistered(false);
                setShowForgotPassword(true);
            })
        }, [details.email, setShowForgotPassword, setShowAlreadyRegistered]);
        return <ContentWrapper
                title="A Little About You"
                breadcrumbTitle="Registration"
                subtitle={[
                    "We'd like to know a little more about you. Please fill in the details below.",
                    "Remember, JobJump never shares any of this information with third parties outside your school."
                ]} >
            <Row style={{maxWidth:600}}>
                <Col className="text-center">
                    <RegistrationProgress stepsTaken={["School"]} />
                </Col>
            </Row>
            <PageSectionRow><Col className="school-registration-page">
                <form onSubmit={(e:any)=>{e.preventDefault(); e.stopPropagation(); nextButtonClick();}}>
                    <DetailsTextInput details={details} title="First Name" field="firstName" 
                        update={setRegistrationDetails} valid={validatedFields["firstName"]} />
                    <DetailsTextInput details={details} title="Last Name" field="lastName" 
                        update={setRegistrationDetails} valid={validatedFields["lastName"]} />
                    <DetailsTextInput details={details} title="Email" field="email" 
                        update={setRegistrationDetails} valid={validatedFields["email"]} 
                        invalidText="Please enter a valid email address" /> 
                    <DetailsTextInput isPassword details={details} title="Personal Password"
                        field="password" update={setRegistrationDetails} 
                        valid={validatedFields["password"] && validatedFields["passwordLen"]} 
                        invalidText={!validatedFields["password"] ? 
                            "Exact passwords do not match" : 
                            "Password must be at least 6 characters"
                        } /> 
                    <Row><Col>
                        <Label>Confirm Personal Password</Label>
                        <TextField passwordField value={confirmPassword} onChange={setConfirmPassword} />
                    </Col></Row>
                    <DetailsSelectInput details={details} title="Are you a:" field="registrationKind" 
                        update={setRegistrationDetails} valid={validatedFields["registrationKind"]} options={[
                            {label:"Student", value:"student"},
                            {label:"Parent", value:"parent"},
                            {label:"Teacher", value:"teacher"},
                        ]} />
                    {details.registrationKind !== "teacher" && 
                        <DetailsSelectInput 
                            details={details} 
                            title={(details.registrationKind === "parent" ? "Child's " : "")+"School Year"}
                            field="year" 
                            update={setRegistrationDetails}
                            valid={validatedFields["year"]}
                            options={[7,8,9,10,11,12].map(i => ({value:String(i), label:`Year ${i}`}))} />}
                    <Row><Col>
                        <Check 
                            label="I am of Aboriginal or Torres Strait Islander background"
                            checked={details.aboriginal}
                            onChange={(aboriginal)=>setRegistrationDetails({ ...details, aboriginal })} />
                    </Col></Row>
                    <Row><Col className="text-right">
                        <Button variant="active" disabled={buttonPressed} onClick={()=>nextButtonClick()}>Continue</Button>
                    </Col></Row>
                </form>
            </Col></PageSectionRow>
            <Modal show={showAlreadyRegistered} onHide={()=>{}} title="Account Already Exists">
                <Row>
                    <Col className="text-center" >
                        <h4>Hey {details.firstName}!</h4> 
                        <h4>You already have an account under the email:</h4>
                        <h4>'{details.email}'</h4>
                        <h4>Select what you want to do below.</h4>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-ceneter">
                        <Button variant="active" onClick={resetPassword}>I've Forgotten My Password</Button>
                        <Button className="ml-8" variant="active" onClick={()=>history.push("/login")}>Take Me To Login</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal show={showForgotPassword} onHide={()=>{}} title="I've Forgotten My Password">
                <Row><Col>
                    A password reset link has been emailed '{details.email}'. Please follow the instructions to reset your password.
                </Col></Row>
                <Row><Col className="text-right">
                    <Button variant="active" onClick={()=>history.push("/")}>Continue</Button>
                </Col></Row>
            </Modal>
        </ContentWrapper>
    }

export default Details;