import React from "react";
import { UserDetailsProps } from "../layout/Routes";

type Props = {
    nswContent:JSX.Element,
    vicContent:JSX.Element
}

const StateBasedPage : React.FC<UserDetailsProps & Props> = ({userDetails, nswContent, vicContent}) =>
    userDetails.state === "NSW" ? nswContent : 
    userDetails.state === "VIC" ? vicContent :
    <></>

export default StateBasedPage;