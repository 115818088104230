import React from "react";
import {Row, Col} from "../../components/grid";
import {AtarCategory, AtarResult} from "jobjump-types/ATAR";
import AtarAPI from "../../data/ATAR";
import { RouteComponentProps } from "react-router";
import NumericInput from 'react-numeric-input';
import queryString from "query-string";
import AtarImages from "../../assets/AtarImages";
import "./atar-picker.css";
import FilterableGrid from "../../components/FilterableGrid";
import PageSectionRow from "../../components/PageSectionRow";
import { Check, TextField } from "../../components/Form";
import Button from "../../components/Button";
import _ from "lodash";
import ATARTable from "./ATARTable";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";


const MyATARCategories : React.FC<UserDetailsProps & RouteComponentProps> =
    (props) => {
        const {history} = props;
        const [atarCaregories, setAtarCategories] = React.useState<AtarCategory[]>([]);
        const [atarKeyWords, setAtarKeywords] = React.useState<string[]>([]);
        const [expectedAtar, setExpectedAtar] = React.useState(99.95);
        const [wantAtar, setNotWantingAtar] = React.useState(true);
        const [searchMode, setSearchMode] = React.useState<"category"|"text">("category");
        const [degreeSearch, setDegreeSearch] = React.useState("");
        const [degreeName, setDegreeName] = React.useState("");
        const [chosenStates, setChosenStates] = React.useState<string[]>([]);
        const [data, setData] = React.useState<AtarResult[]>([]);
        React.useEffect(() => {
            AtarAPI.getAtarCategories().then(d => {
                setAtarCategories(d)
            });
        }, []);
        React.useEffect(() => {
            AtarAPI.getAtarSubcategories().then(setAtarKeywords);
        }, []);
        React.useEffect(() => {
            if (degreeName === "" || chosenStates.length === 0) {
                return;
            }
            AtarAPI.getAtarDegreeSearch(degreeName, chosenStates.map(s=>s==="NSW & ACT" ? "NSW" : s)).catch(e=>{console.log(e); return []}).then((d: AtarResult[]) => {
                let data = d;
                if (expectedAtar) {
                    data = d.filter((r) => {
                        if (isNaN(Number(r.atar))) { return true }
                        return Number(r.atar) <= expectedAtar;
                    });
                }
                setData(data)
            });
        }, [degreeName, expectedAtar, chosenStates]);
        const searchDegree = React.useCallback(() => {
            console.log(degreeSearch);
            if (degreeSearch.toUpperCase().replace("BACHELOR", "").replace("BACHELO", "").replace("BACHEL", "").replace("BACHEL", "").replace("BACHE", "").replace("BACH", "").replace("BAC", "").length < 3) {
                setDegreeName("");
                return;
            }
            setDegreeName(degreeSearch);
        }, [degreeSearch, setDegreeName]);
        return <ContentWrapper
                title="My ATAR"
                subtitle={["Trying to find the right course for you? Enter your expected ATAR below and choose the category that best suits your career."]} >
            <PageSectionRow>
                <Col className="text-center">
                    <h4>If my ATAR is</h4>
                    <NumericInput 
                        value={expectedAtar} 
                        max={99.95} 
                        min={30} 
                        step={0.05} 
                        disabled={!wantAtar}
                        onChange={(value) => 
                            value ? setExpectedAtar(value) : null
                        } 
                    />
                </Col>
            </PageSectionRow>
            <Row>
                <Col className="text-center">
                    <Check type="checkbox" 
                        checked={!wantAtar} 
                        onChange={(e) => {setNotWantingAtar(!e)}}
                        label="I'm not sure"
                    />
                </Col>
            </Row>
            <PageSectionRow>
                <Col className="text-center">
                    <h4>What degree can I do for:</h4>
                </Col>
            </PageSectionRow>
            <Row>
                <Col className="text-center">
                    <Button glowing={searchMode === "category"} variant={searchMode === "category" ? "active" : "neutral"} onClick={()=>setSearchMode("category")} className="mr-8">Search By Category</Button>
                    <Button glowing={searchMode === "text"} variant={searchMode === "text" ? "active" : "neutral"} onClick={()=>setSearchMode("text")} className="mr-8">Search By Degree Name</Button>
                </Col>
            </Row>
            {searchMode === "category" && <Row className="mt-16"><Col>
                <FilterableGrid
                    categories={atarKeyWords}
                    data={atarCaregories.map(c => 
                        ({
                            imgUrl:AtarImages[c.name.toUpperCase()] || "",
                            text:c.name,
                            subcategories:c.subcategories, 
                            onClick:()=>{history.push({
                                    pathname: "/atar/"+c.id,
                                    search: wantAtar ? "?"+queryString.stringify({atar : expectedAtar}) : ""
                                });}
                        })
                    )}
                    numPerRow={3}
                    hideHeading
                />
            </Col></Row>}
            {searchMode === "text" && <><Row className="mt-16"><Col className="flex">
                <form onSubmit={(e)=>{e.preventDefault(); searchDegree();}} className="flex-grow">
                    <label>Search for all or part of a degree name</label>
                    <TextField value={degreeSearch} onChange={setDegreeSearch} placeholder="Search Degree Name..." />
                </form>
                <Button variant="active" onClick={searchDegree}>Search</Button>
            </Col>
            </Row>
            {degreeName !== "" && <ATARTable data={data} chosenStates={chosenStates} setChosenStates={setChosenStates} {...props} />}
            </>}
        </ContentWrapper>
    }

export default MyATARCategories;