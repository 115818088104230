import { IconPencil, IconSearch, IconTrash } from "@tabler/icons";
import { StudentInterviewList, StudentInterviewRecord } from "jobjump-types/CaStudents";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import PageSectionRow from "../../components/PageSectionRow";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";

const InterviewHistory : React.FC<RouteComponentProps> = (props) => {
    const [data, setData] = React.useState<StudentInterviewList[]>([]);
    const [viewInterview, setViewInterview] = React.useState<StudentInterviewList>();
    const [deleteCandidate, setDeleteCandidtate] = React.useState<string>();
    const getData = React.useCallback(() => CaStudentDetails.getAllStudentInterviews().then(setData), [setData]);
    React.useEffect(() => {
        getData();
    }, []);
    const deleteInterview = React.useCallback(() => {
        if (!deleteCandidate) {return; }
        CaStudentDetails.deleteStudentInterview(deleteCandidate).then(()=>getData());
    }, [setDeleteCandidtate, deleteCandidate]);
    return <CaAdminLayout active="students" title="Student Interview History" {...props}>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Student Interview Record</h3>
                <span>Below lists all interviews you have had with students. You can search using the search boxes. Use the maginfying glass to see the interview details.</span>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <CaStudentTable
                    data={data}
                    csvExport={{
                        headers: [
                            {key:"name", label:"Name"},
                            {key:"year", label:"Year"},
                            {key:"date", label:"Date"},
                            {key:"start", label:"Start Time"},
                            {key:"end", label:"End Time"},
                            {key:"notes", label:"Notes"}
                        ],
                        generateData: (data) => data.map(d => ({
                            name: `${d.student.first_name} ${d.student.last_name}`,
                            year: d.student.year,
                            date: d.interview.date,
                            start: d.interview.start_time,
                            end: d.interview.end_time,
                            notes: d.interview.comment
                        }))
                    }}
                    hideEmail
                    additionalColumns={[
                        {
                            Header:"Date",
                            accessor:(r:StudentInterviewList)=>r.interview.date
                        },
                        {
                            Header:"View",
                            id:"view",
                            Cell:({row:{original}})=><IconSearch onClick={()=>setViewInterview(original)} />
                        },
                        {
                            Header:"Edit",
                            id:"edit",
                            Cell:({row:{original}})=><IconPencil onClick={()=>props.history.push(`/admin/interview/${original.student.id}/${original.interview.id}`)} />
                        },
                        {
                            id:"delete",
                            Header:"Delete",
                            Cell:({row:{original}})=>
                                <IconTrash onClick={()=>setDeleteCandidtate(original.interview.id)} />
                        },
                    ]} />
            </Col>
        </PageSectionRow>
        <Modal show={viewInterview !== undefined} onHide={()=>setViewInterview(undefined)}>
            {viewInterview && <Row><Col>
                <Row>
                    <Col>
                        <h4>{viewInterview.student.first_name} {viewInterview.student.last_name} - 
                        {viewInterview.interview.date} ({viewInterview.interview.start_time}-{viewInterview.interview.end_time})</h4>
                    </Col>
                </Row>
                <Row className="mt-8">
                    <Col>
                        <p style={{whiteSpace:"pre-line"}}>{viewInterview.interview.comment}</p>
                    </Col>
                </Row>
            </Col></Row>}
        </Modal>
        <Modal show={deleteCandidate !== undefined} onHide={()=>setDeleteCandidtate(undefined)} title="Confirm Delete">
            <Row>
                <Col>
                    <h4>Are you sure you want to delete this recrod?</h4>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <Button variant="neutral" onClick={()=>setDeleteCandidtate(undefined)} className="mr-8">Cancel</Button>
                    <Button variant="negative" onClick={deleteInterview}>Delete</Button>
                </Col>
            </Row>
        </Modal>
    </CaAdminLayout>
}

export default InterviewHistory;