import {UserDetails, SchoolDetails, RegistrationDetails, HasPasswordKind} from "jobjump-types/User";
import qs from "query-string";
import { fetchWithAuth, fetchHandleServerStatus, fetchIgnoreAuth } from "./Utils";

const User = {
    getUserDetails : () : Promise<UserDetails|undefined> => 
        fetchIgnoreAuth("/api/user/details").catch(()=>undefined).then(r=>r&&r.json()),
    getSchoolDetails : () : Promise<SchoolDetails[]> =>
        fetchWithAuth("/api/schools/list").then(r=>r.json()).catch(console.log),

    login : (email:string, password:string) : Promise<UserDetails> => {
        let body = qs.stringify({email, password});
        return fetchHandleServerStatus("/api/user/login?"+body).then(r=>r.json())
    },
    register : (details:RegistrationDetails) : Promise<{}> => {
        const body = qs.stringify({details:JSON.stringify(details)});
        return fetchWithAuth("/api/user/register?"+body)
    },
    unsubscribe : (subID : string, emailAddr : string) : Promise<{}> =>
        fetchWithAuth("/api/user/unsubscribe?"+qs.stringify({subID, emailAddr})),
    forgotPassword : (email:string) : Promise<{}> =>
        fetchWithAuth("/api/user/forgotPassword?"+qs.stringify({email})),
    resetPassword : (subscriber_id:string, email:string, school_id:string, token:string, password:string) : Promise<{}> =>
        fetchWithAuth("/api/user/resetPassword?"+qs.stringify({subscriber_id, email, school_id, token, password })),
    logout : () : Promise<{}> => fetchWithAuth("/api/user/logout"),
    partialRegistration : 
        (args:{id:string, token:string, firstName:string, lastName:string, password:string, gender:string}) : Promise<{}> => 
            fetchWithAuth("/api/user/partialRegistration?"+qs.stringify(args)),
    hasPassword:(email:string) : Promise<HasPasswordKind> =>
        fetchWithAuth(`/api/user/hasPassword?${qs.stringify({email})}`).then(r =>r.text() as unknown as HasPasswordKind),
    checkSchoolLogin:(schoolID:string, password:string) : Promise<boolean> =>
        fetchWithAuth(`/api/schools/verify?${qs.stringify({schoolID,password})}`).then(r=>r.json()),
    changePassword:(args:{newPassword:string, oldPassword:string}) =>
        fetchWithAuth(`/api/user/changePassword?${qs.stringify(args)}`),
};

export default User;