import {CareerNames, CareerInfo, CareerCategory, CareerLinkType, TAFECareers, SpecificCareer} from "jobjump-types/Careers";
import { fetchWithAuth } from "./Utils";
import qs from "query-string";

export type CareerSpecalisation = {
    id: number,
    name: string,
    description: string,
    url: string,
    stats: {
        age: string,
        salary: string,
        growth: string
    }
}

const Careers = {
    getCareerList : () : Promise<CareerNames[]> => 
        fetchWithAuth("/api/careers/").then((response) => response.json()).catch((console.log)),

    getCareerDetails : (careerID : number) : Promise<CareerInfo> => 
        fetchWithAuth("/api/careers/info/"+String(careerID)).then((response) => response.json()).catch(console.log),

    getCareerCategories : (args?:{hasLinksFor?:CareerLinkType[]}) : Promise<CareerCategory[]> =>
        fetchWithAuth("/api/careers/categories?"+qs.stringify(args || {})).then(r=>r.json()).catch(console.log),

    getCareerCategoryDetails : (categoryID:string) : Promise<CareerInfo> =>
        fetchWithAuth("/api/careers/categories/info/"+categoryID).then((response) => response.json()).catch(console.log),

    getCareerSpecalisiations : (careerID:string) : Promise<CareerSpecalisation[]> =>
        fetchWithAuth("/api/careers/specialisations/"+careerID).then((response) => response.json()).catch(console.log),

    getTafeCareers : () : Promise<TAFECareers[]> =>
        fetchWithAuth("/api/careers/tafeCareers").then((response) => response.json()).catch(console.log),
    
    getSpecificCareers : () : Promise<SpecificCareer[]> => 
        fetchWithAuth("/api/careers/specificCareers").then((response) => response.json()).catch(console.log),

    getUsersCareers : () : Promise<CareerNames[]> => 
        fetchWithAuth("/api/careers/users").then((response) => response.json()).catch((console.log)),

    //TODO: move this to Cwiz when it gets merged
    setFavouriteCareer : (id:number) : Promise<void> =>
        fetchWithAuth("/api/quiz/favourite_career/"+id, {method:"POST"}).then(()=>{}),
    deleteFavouriteCareer : (id:number) : Promise<void> =>
        fetchWithAuth("/api/quiz/favourite_career/"+id, {method:"DELETE"}).then(()=>{})

};

export default Careers;