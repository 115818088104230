import { IconX } from "@tabler/icons";
import { CareerNames, SpecificCareer } from "jobjump-types/Careers";
import _ from "lodash";
import React from "react";
import { MultiValueProps, GroupTypeBase, components, OptionProps } from "react-select";
import CareerImagesSmall from "../../assets/CareerImageSmall";
import { MultiSelect } from "../../components/Form";
import CareerAPI from "../../data/Careers";

export type Career = {
    kind: "jobjump"|"goodEdu",
    jobjump?: CareerNames,
    goodEdu?: SpecificCareer,
}

export type SelectOption = {label:string, value:string, career:Career};

const CareerMultiValue : React.FC<MultiValueProps<SelectOption, GroupTypeBase<SelectOption>>> = (props) => {
    const jjCareerName = props.data.career.jobjump?.name || props.data.career.goodEdu?.jobjump_name;
    return <div className="careerMultiValue">
        <div className="multiValueImage">
            <img src={jjCareerName && CareerImagesSmall[jjCareerName.toUpperCase()]} />
        </div>
        <div className="ml-8">
            {props.data.label}
        </div>
        <div className="multiValueClose text-right ml-8 mr-8">
            <IconX className="pointer" {...props.removeProps} width={16} height={16} />
        </div>
    </div>
}

const CareerOption : React.FC<OptionProps<SelectOption, true, GroupTypeBase<SelectOption>>> = (props) => {
    const jjCareerName = props.data.career.jobjump?.name || props.data.career.goodEdu?.jobjump_name;
    return <components.Option {...props}>
        <div className="careerOption">
            <div className="multiValueImage ml-8 mr-8">
                <img src={jjCareerName && CareerImagesSmall[jjCareerName.toUpperCase()]} />
            </div>
            <div>{props.label}</div>
        </div>
    </components.Option>
}

type Props = {
    value:SelectOption[],
    onChange:(v:SelectOption[])=>void
}

const CareerSelectBox : React.FC<Props> = ({value, onChange}) => {
    const [careerOptions, setCareerOptions] = React.useState<SelectOption[]>([]);
    React.useEffect(() => {
        CareerAPI.getCareerList().then(careers => {
            setCareerOptions(o => _.sortBy(
                o.concat(careers.map(c => ({label:c.name, value:`jj-${c.id}`, career:({kind:"jobjump", jobjump:c})}))), 
                (c=>c.label)));
        });
    }, []);
    React.useEffect(() => {
        CareerAPI.getSpecificCareers().then(specificCareers=> {
            setCareerOptions(o => _.sortBy(
                o.concat(specificCareers.filter(c=>c.jobjump_name!==c.name).map(c => ({label:c.name, value:`ge-${c.id}`, career:({kind:"goodEdu", goodEdu:c})}))), 
                (c=>c.label)));
        });
    }, []);
    return <MultiSelect name="career-subscribe" value={value}
        components={{
            MultiValue:CareerMultiValue,
            Option:CareerOption
        }}
        data={careerOptions}
        onChange={onChange} /> 
}

export default CareerSelectBox;