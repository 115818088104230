import { IconCircleCheck } from "@tabler/icons";
import React from "react";
import CardTable from "../components/CardTable";
import ContentWrapper from "../components/ContentWrapper";
import DecoratedRow from "../components/DecoratedRow";
import { Col, Row } from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";

const Tick = () => <IconCircleCheck stroke={1.5} height={40} width={40} />

const Features : React.FC = () => 
    <ContentWrapper title="JobJump Features and Pricing" noBottomMargin>
        <PageSectionRow>
            <Col className="mt-64 text-center">
                <h2>JobJump is your comprehensive destination for all things Careers.</h2>
                <p style={{"fontSize":"24px"}}>Engage your students, parents and wider school community with JobJump's comprehensive features. Put career information at your fingertips, and help your students get where they want to go!</p> 
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <CardTable 
                    data={[
                        { feature: "", smallContent: "$220", mediumContent: "$290", largeContent: "$490"},
                        { feature: "Unlimited students, parents, student mentors, teachers and careers advisers" },
                        { feature: "School branded website with custom domain" },
                        { feature: "Unlimited custom folders with unlimited documents" },
                        { feature: "Scholarships advice - per degree - direct uni link"},
                        { feature: "Alternative pathway information - per degree - direct uni advice link"},
                        { feature: "Lessons and highly interactive activities"},
                        { feature: "Automated and super fast destination survey"},
                        { feature: "Customised student information (e.g. careers, course choices)"},
                        { feature: "Weekly newsletter"},
                        { feature: "Record student interactions (archived for 7 years)"},
                        { feature: "Email students by career or year, or individually"},
                        { feature: "112 careers eWork Experiences for students of any ability"},
                        { feature: "Customised career quiz across 550 career options"},
                        { feature: "Transition plans for 112 careers"},
                        { feature: "Customised Resume Builder across 112 careers"},
                        { feature: "Generic Resume builder"},
                        { feature: "Advanced ATAR course search"},
                        { feature: "Employment outcomes for degrees integrated into ATAR Course Search"},
                        { feature: "Senior subject requirements and recommendations for each degree in ATAR Course Search"},
                        { feature: "Senior Subject selector across 112 careers"},
                        { feature: "Interactive Subject Bullseyes with Job Descriptions" },
                        { feature: "Virtual reality videos across over 100 careers"},
                        { feature: "Student university stories"},
                        { feature: "University information directory"},
                        { feature: "Localised job listings across 112 careers"},
                        { feature: "Real work experience directory"},
                        { feature: "1-on-1 Training Sessions"}, 
                    ]}
                    columns={[
                        {
                            id:"feature",
                            Cell: ({row:{original}}) => <div>{original.feature}</div>
                        },
                        {
                            id:"small-school",
                            Header: () => <div className="text-center">
                                <strong className="block">Premium Plus</strong>
                                <strong>Schools &lt; 250 Students</strong>
                            </div>,
                            Cell: ({row:{original}}) => <div className="text-center">{original.smallContent ? original.smallContent : <Tick />}</div>
                        },   
                        {
                            id:"medium-school",
                            Header: () => <div className="text-center">
                                <strong className="block">Premium Plus</strong>
                                <strong>Schools 250 - 500 Students</strong>
                            </div>,
                            Cell: ({row:{original}}) => <div className="text-center">{original.mediumContent ? original.mediumContent : <Tick />}</div>
                        }, 
                        {
                            id:"large-school",
                            Header: () => <div className="text-center">
                                <strong className="block">Premium Plus</strong>
                                <strong>Schools 500+ Students</strong>
                            </div>,
                            Cell: ({row:{original}}) => <div className="text-center">{original.largeContent ? original.largeContent : <Tick />}</div>
                        }
                    ]} />
            </Col>
        </PageSectionRow>        
        <DecoratedRow decorationType="circles-bottom">
            <Col className="mt-128">
                <Row className="mt-64">
                    <Col className="text-center">
                        <h3>Want to know more? Get in touch!</h3>
                        <p>
                            <a href="mailto:contact@jobjump.com.au">contact@jobjump.com.au</a><br />
                        </p>
                    </Col>
                </Row>
 

            </Col>
        </DecoratedRow>
    </ContentWrapper>

export default Features;