import React from "react";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { UserDetailsProps } from "../../layout/Routes";

import WorkSafetyQuiz from "../../assets/homepage/tiles/work-safety-quiz.jpg"
import Project from "../../assets/homepage/tiles/studying-at-tafe.jpg";
import Resume from "../../assets/homepage/tiles/my-resume-for-careers.jpg";
import Books from "../../assets/homepage/tiles/atar-choosing-hsc-subjects.jpg";
import SoftSkillsImg from "../../assets/homepage/tiles/getting-you-tax-file-number.jpg";
import CallingABoss from "../../assets/my_job_ads/calling_a_boss.jpg";
import CommonQuestions from "../../assets/my_job_ads/common-interview-question.jpg";
import WritingAResume from "../../assets/my_job_ads/writing-a-resume.jpg";
import MyCareer from "../../assets/school-homepage/my-career.png";
import { RouteComponentProps } from "react-router";
import { openLinkInNewTab } from "../../util";
import PlanAPI, { PreworkStatus } from "../../data/Plan";
import { EventTracking } from "../../data/EventTracking";

const WorkCheck : React.FC<UserDetailsProps & RouteComponentProps> = ({history}) => {
    const [status, setStatus] = React.useState<PreworkStatus>()
    React.useEffect(() => {
        PlanAPI.getPreworkStatus().then(setStatus);
    }, []);
    const openWHS = () => {
        EventTracking.trackEvent("whsClick", "").then(() => {
            openLinkInNewTab("https://smartmove.safetyline.wa.gov.au/");
            setStatus(s => !s? s : ({...s, doneWhs:true}));
        })
    }

    return <ContentWrapper title="Pre-Work Check">
        <PageSectionRow>
            <Col>
                <CardGrid numPerRow={3} prefix="work-check">
                    <ImageCard text="Know My Career" imageUrl={MyCareer} selected={status?.doneCareer} onClick={()=>history.push("/workCheck/knowCareer")} />
                    <ImageCard text="eWork Experience Project" selected={status?.doneProject} imageUrl={Project} onClick={()=>history.push("/workCheck/eWorkExperience")}/>
                    <ImageCard text="Employment Skills" imageUrl={WritingAResume} selected={status?.doneEmploymentSkills} onClick={()=>history.push("/workCheck/employmentSkills")} /> 
                    <ImageCard text="Soft Skills" selected={status?.doneSoftSkills} imageUrl={SoftSkillsImg} onClick={()=>history.push("/workcheck/softSkills")}/>
                    <ImageCard text="Literacy & Numeracy" selected={status?.doneLitNum} imageUrl={Books} onClick={() => history.push("/workCheck/literacyNumeracy")}/>
                    <ImageCard text="Resume" selected={status?.doneResume} imageUrl={Resume} onClick={()=>history.push("/workCheck/resume")} />
                    <ImageCard text="Cold Canvassing" selected={status?.doneColdCall} imageUrl={CallingABoss} onClick={()=>history.push("/workCheck/coldCall")}/>
                    <ImageCard text="The Interview" selected={status?.doneInterview} imageUrl={CommonQuestions} onClick={()=>history.push("/workCheck/interview")}/>
                    <ImageCard text="WHS" imageUrl={WorkSafetyQuiz} selected={status?.doneWhs} onClick={()=>history.push("/workCheck/whs")} />
                </CardGrid>
            </Col>
        </PageSectionRow>
    </ContentWrapper>
}

export default WorkCheck;