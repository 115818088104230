import {UserDetails, UserSubscriptions} from "jobjump-types/UserDetails";
import { fetchWithAuth } from "./Utils";
import qs from "query-string";

const UserDetailsAPI = {
    getDetails : () : Promise<UserDetails> =>
        fetchWithAuth("/api/userDetails/getDetails").then(r => r.json()).catch(console.log),
    getSubscriptions : () : Promise<UserSubscriptions> =>
        fetchWithAuth("/api/userDetails/userSubscriptions").then(r => r.json()).catch(console.log),
    updateDetails : (args:UserDetails) =>
        fetchWithAuth("/api/userDetails/updateDetails?"+qs.stringify(args)).then(r => r.json()).catch(console.log),
    updateCareerSubscriptions : (careers:number[]) =>
        fetchWithAuth("/api/userDetails/updateCareerSubscription?"+qs.stringify({careers:JSON.stringify(careers)})).then(r => r.json()).catch(console.log),
    updateSubjectSubscriptions : (subjects:number[]) =>
        fetchWithAuth("/api/userDetails/updateSubjectSubscription?"+qs.stringify({subjects:JSON.stringify(subjects)})).then(r => r.json()).catch(console.log)
}

export default UserDetailsAPI;