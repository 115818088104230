import React from "react";
import CardGrid from "../components/CardGrid";
import ImageCard from "../components/cards/ImageCard";
import { Col } from "../components/grid";
import PageSectionRow from "../components/PageSectionRow";
import ImageA from "../assets/homepage/tiles/traineeships-and-aprenticeships.jpg";
import ImageB from "../assets/homepage/tiles/studying-at-tafe.jpg";
import { openLinkInNewTab } from "../util";
import ContentWrapper from "../components/ContentWrapper";
import { UserDetailsProps } from "../layout/Routes";
import StateBasedPage from "../components/StateBasedPage";
import { getRandomImage } from "../assets/GenericImages";
import { RouteComponentProps } from "react-router";

const WorkSafeteyNSW : React.FC<RouteComponentProps> = ({history}) =>
    <PageSectionRow>
        <Col>
            <CardGrid numPerRow={3} prefix="safety">
                <ImageCard imageUrl={ImageA} text="Work Safe Quiz" onClick={()=>openLinkInNewTab("http://smartmove.safetyline.wa.gov.au/")} />
                <ImageCard imageUrl={ImageB} text="Go2Workplacement" onClick={()=>openLinkInNewTab("http://go2workplacement.com/auth/login/?returnUrl=%2F")} />
                <ImageCard imageUrl={getRandomImage(2)} text="WHS Quiz" onClick={()=>history.push("/whsQuiz")} />
            </CardGrid>
        </Col>
    </PageSectionRow>

const vicLinks = [
    ["Safe@Work Module" , "https://www.education.vic.gov.au/school/students/beyond/Pages/generalmodule.aspx"],
    ["PDF Student Guide" , "https://www.education.vic.gov.au/Documents/school/students/beyond/printablestudentguide.pdf"],
    ["Safe@Work Industry Modules" , "https://www.education.vic.gov.au/school/students/beyond/Pages/industrymodule.aspx"],
    ["Safe@Work Self Assessment Test" , "https://www.education.vic.gov.au/school/students/beyond/Pages/quiz.aspx"],
    ["Safe@Work Review Module" , "https://www.education.vic.gov.au/school/students/beyond/Pages/reviewmodule.aspx"],
    ["Safe@Work Review Module PDF" , "https://www.education.vic.gov.au/Documents/school/students/beyond/printablereviewmodule.pdf"],
    ["Workplace Hazards" , "https://www.education.vic.gov.au/school/students/beyond/Pages/workplacehazardfact.aspx"],
]
const WorkSafeteyVic : React.FC<RouteComponentProps> = ({history}) =>
    <PageSectionRow>
        <Col>
            <CardGrid numPerRow={3} prefix="safety">
                {vicLinks.map(([name, link], i) =>
                    <ImageCard imageUrl={i === 0 ? ImageA : i === 1 ? ImageB : getRandomImage(i)} key={i} text={name} onClick={()=>openLinkInNewTab(link)} />
                )}
                <ImageCard imageUrl={getRandomImage(vicLinks.length)} text={"WHS Quiz"} onClick={()=>history.push("/whsQuiz")} />
            </CardGrid>
        </Col>
    </PageSectionRow>

const WorkSafety : React.FC<UserDetailsProps & RouteComponentProps> = (props) => <ContentWrapper title="Work Safety">
    <StateBasedPage
        nswContent={<WorkSafeteyNSW {...props} />}
        vicContent={<WorkSafeteyVic {...props} />}
        {...props} />
</ContentWrapper>

export default WorkSafety;