import React from "react";
import "./form.css";

type TextFieldProp = {
    onChange?:(value:string)=>void
    passwordField?:boolean
    dateField?:boolean
    emailField?:boolean
} & Omit<React.ComponentProps<"input">, "onChange">;

const TextField : React.FC<TextFieldProp> = ({onChange,passwordField,dateField,className,emailField, ...props}) => 
    <input type={passwordField ? "password" : dateField ? "date" : emailField ? "email" : "text"}
        className={`jobjump-form-component jobjump-text-input ${className}`}
        onChange={onChange ? ({currentTarget:{value}})=>onChange(value) : undefined}
        {...props} />

export default TextField;