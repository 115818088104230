import React from "react";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { IconSearch } from "@tabler/icons";
import { StudentProjectRecord } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import { openLinkInNewTab } from "../../util";

const StudentProjects : React.FC<RouteComponentProps> = (props) => {
    const [data, setData] = React.useState<StudentProjectRecord[]>([]);
    React.useEffect(() => {
        CaStudentDetails.getStudentWorkExperienceProjects().then(setData);
    }, []);
    const downloadFile = (id:number) => {
        CaStudentDetails.downloadStudentProject(id).then(i => i && openLinkInNewTab(i));
    }
    return <CaAdminLayout active="careers" title="Student eWork Experience" {...props}>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Student eWork Experience</h3>
                <span>Below are eWork Experience projects uploaded by your students. You can use <IconSearch /> to download the project.</span>
            </Col>
        </PageSectionRow>
        <PageSectionRow> 
            <Col>
                <CaStudentTable
                    data={data}
                    additionalColumns={[
                        { Header:"Career", accessor:(r)=>r.careerName, width:3 },
                        { Header:"View", width:1, Cell:({row:{original}})=><IconSearch className="pointer" onClick={()=>downloadFile(original.fileId)} /> },
                    ]} />
            </Col>
        </PageSectionRow>
    </CaAdminLayout>
}

export default StudentProjects;