import React from "react";
import {Row, Col, GridSizes} from "../../components/grid";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from "array-move";
import { CoursePreferences } from "jobjump-types/Courses";
import { Link } from "react-router-dom";
import "./preferences.css";
import copy from 'copy-to-clipboard';
import CoursePreferenceAPI from "../../data/CoursePreferences";
import {getMostRelevantOffering} from "../tafe_courses/CourseListing";
import Button from "../../components/Button";
import BaseCard from "../../components/cards/BaseCard";
import { IconClipboard, IconTrash } from "@tabler/icons";
import ContentWrapper from "../../components/ContentWrapper";

interface CourseCardProps {value:CoursePreferences, onDelete? : ()=>void};
const CourseCard : React.FC<CourseCardProps> =
    ({value, onDelete}  : CourseCardProps) =>  {
        const tafeOffering = value.tafeCourse && getMostRelevantOffering(value.tafeCourse);
        return <>
            {value.uniCourse && <BaseCard className={"mt-16 pref-card"}>
                <Row>
                    <Col md={10}>
                        <h4>
                            {value.order + 1}. <a href={value.uniCourse.url} rel="noopener noreferrer" target="_blank">{value.uniCourse.id}</a>
                            &nbsp;<IconClipboard style={{cursor:"pointer"}} onClick={()=>{value.uniCourse && copy(value.uniCourse.id)}} />
                        </h4>
                        <h4>{value.uniCourse.name}</h4>
                        <p>{value.uniCourse.institution} - {value.uniCourse.campus} ({value.uniCourse.state})</p>
                    </Col>
                    <Col>
                        <p>Previous Cut Off: {value.uniCourse.atar}</p>
                        {onDelete ? 
                            <span><IconTrash onClick={() => {onDelete()}}/></span>
                        : <></>}
                    </Col>
                </Row>
            </BaseCard>}
            {value.tafeCourse && <BaseCard className={"mt-16 pref-card"}>
                <Row>
                    <Col md={10}>
                        <h4>
                            {value.order + 1}.
                        </h4>
                        <h4>TAFE - {tafeOffering ? <a href={tafeOffering.url} rel="noopener noreferrer" target="_blank">{value.tafeCourse.course_name}</a> : value.tafeCourse.course_name}</h4>
                        <p>{value.tafeCourse.college}</p>
                    </Col>
                    <Col>
                        {onDelete ? 
                            <span><IconTrash onClick={() => {onDelete()}}/></span>
                        : <></>}
                    </Col>
                </Row>
            </BaseCard>}
        </>
    }


const SortableItem = SortableElement(
    ({ value, onDelete }: { value: CoursePreferences, onDelete:()=>void }) =>
        <CourseCard value={value} onDelete={onDelete} />
);

const SortableList = SortableContainer(
    ({ items, onDelete }: { items: CoursePreferences[], onDelete : (index:number)=>void }) =>
        <Row><Col>
            {items.map((p: CoursePreferences, i: number) => {
                return <SortableItem key={"key-" + String(i)} index={i} value={p} onDelete={() => onDelete(i)} />
            })}
        </Col></Row>
);

const MyPreferences: React.FC<{}> =
    () => {
        //const stateList = ["NSW", "VIC", "QLD"];
        const [stateList, setStateList] = React.useState<string[]>([]);
        const [preferences, setPreferences] = React.useState<CoursePreferences[]>([]);
        const [preferencesAreSet, setPreferencesAreSet] = React.useState(false);
        const [stateFilter, setStateFilter] = React.useState("");
        React.useEffect(() => {
            CoursePreferenceAPI.getCoursePreferences().then((a) => { setPreferences(a); setPreferencesAreSet(true); });
        }, []);
        React.useEffect(() => {
            // Prevent saving the [] default when view loads
            if (preferencesAreSet) {
                const newPrefs = preferences.map((p,i) => {
                    return { 
                        course_id : p.uniCourse && Number(p.uniCourse.jj_id), 
                        tafe_id : p.tafeCourse && Number(p.tafeCourse.jj_id),
                        preference_order: i 
                    };
                });
                CoursePreferenceAPI.saveCoursePreferences(newPrefs);
            }
        }, [preferences, preferencesAreSet])
        React.useEffect(() => {
            const states = preferences.reduce((a, p) => {
                if (p.tafeCourse) { return {...a, "tafe":true} }
                if (!p.uniCourse || p.uniCourse.state in a) { return a }
                return {...a, [p.uniCourse.state]:true};
            }, {} as {[k:string]:any});
            setStateList(Object.keys(states));
        }, [preferences])
        const sortedPreferences = preferences.sort((a, b) => a.order - b.order);
        return <ContentWrapper
                title="My Course Preferences"
                subtitle={["Your saved courses, ready for you here. Drag a course to place it in preference order."]} >
            {stateList.length > 1 && <Row>
                    <Col md={12/((stateList.length+1)) as GridSizes}>
                        <Button style={{width:"100%"}} variant={stateFilter === "" ? "active" : "neutral"} onClick={()=>setStateFilter("")}>
                            Show All
                        </Button>
                    </Col>
                {stateList.map((s) => 
                    <Col md={12/((stateList.length+1)) as GridSizes} key={s}>
                        <Button style={{width:"100%"}} variant={stateFilter === s ? "active" : "neutral"} onClick={()=>setStateFilter(stateFilter === s ? "" : s)}>
                            {stateFilter === s ? "Hide" : "See"} {s.toLocaleUpperCase()} Preferences
                        </Button>
                    </Col>
                )}
            </Row>}
            <Row><Col>
                {sortedPreferences.length === 0 ? 
                    <h4>You have not yet chosen any preferences. You can do so by pressing the like button next to any course in <Link to="/atar">My Atar</Link> or <Link to="/tafe-courses">TAFE Course Search</Link>.</h4> 
                    : <></>
                }
                {stateFilter === "" ?
                        <SortableList 
                            axis="xy"
                            items={sortedPreferences} 
                            onDelete={(i) => {
                                let newList = [...preferences];
                                newList.splice(i,1);
                                setPreferences(newList.map((p, order) => { return { ...p, order }}));
                            }}
                            distance={25}
                            onSortEnd={({oldIndex, newIndex}) => {
                                const newArray = arrayMove(preferences, oldIndex, newIndex).map((p, order) => {
                                    return {...p, order};
                                });
                                setPreferences(newArray);
                            }} 
                        />
                    : 
                        <Row><Col>
                            {preferences.filter(p => stateFilter === "tafe" && p.tafeCourse || (p.uniCourse && p.uniCourse.state === stateFilter)).slice(0,5).map((p, i) =>
                                <CourseCard key={`course-${i}`} value={p} />
                            )} 
                        </Col></Row>
                    } 
            </Col></Row>
        </ContentWrapper>
    }

export default MyPreferences;