import { IconPrinter, IconSquareCheck } from "@tabler/icons";
import React from "react";
import ReactToPrint from "react-to-print";
import { EventTracking } from "../../data/EventTracking";
import User from "../../data/User";
import Alert from "../Alert";
import Button from "../Button";
import { Radio } from "../Form";
import { Row, Col } from "../grid";
import Modal from "../Modal";
import "./certificate.css";
import generateCertificate from "./Certificate";
import { PDFViewer } from "@react-pdf/renderer";

export interface QuestionsInput {
    title : string,
    certificate? : string,
    correct? : string,
    incorrect? : string,
    key : string,
}

interface Questions extends QuestionsInput {
    order : number
}

const TwoChoice : React.FC<{question:Questions, currentValue?:boolean, setValue:(v:boolean) => void}> = 
    ({question, currentValue, setValue}) => {
        const correctE = 
            <Radio className="block" name={question.key} label={question.correct || "Yes"} checked={currentValue === true} onChange={() => setValue(true)} />;
        const incorrectE = 
            <Radio className="block" name={question.key} label={question.incorrect || "No"} checked={currentValue === false} onChange={() => setValue(false)} />;

        return <Row><Col>
            {question.order === 0 ? correctE : incorrectE}
            {question.order === 0 ? incorrectE : correctE}
        </Col></Row>
    }

type ResponseObject = {[k:string]:boolean}

type YesNoQuizProps = {
    questions: QuestionsInput[],
    completedEventTag: string,
    certificateTitle:string,
    certificateSecondLine:string,
}

const YesNoQuiz : React.FC<YesNoQuizProps> =
    ({questions, completedEventTag, certificateSecondLine, certificateTitle}) => {
        const [quizResponse, setQuizResponse] = React.useState<ResponseObject>({});
        const [showCeriticate, setShowCertificate] = React.useState(false);
        const [showIncorrect, setShowIncorrect] = React.useState(false);
        const [notAnswered, setNotAnswered] = React.useState(false);
        const [userName, setUserName] = React.useState("Name Here");
        const [orderedQuestions, setOrderedQuestions] = React.useState<Questions[]>([]);
        const [certificate, setCertificate] = React.useState<JSX.Element>();
        React.useEffect(() => {
            setOrderedQuestions(questions.map(q => ({
                ...q,
                order: Math.floor(Math.random() * Math.floor(2))
            })))
        }, [questions])
        React.useEffect(() => {
            User.getUserDetails().then(d => d && setUserName(`${d.first_name}  ${d.last_name}`));
        }, []);
        const validateQuiz = () => {
            const answeredQuestions = questions.reduce((a,q) => a && q.key in quizResponse, true);
            if (!answeredQuestions) {
                setNotAnswered(true);
                return;
            }
            const validQuiz = questions.reduce((a, q) => a && q.key in quizResponse && quizResponse[q.key], true);
            if (validQuiz) {
                EventTracking.trackEvent(completedEventTag, "true");
                setCertificate(generateCertificate(
                    userName, 
                    certificateTitle, 
                    certificateSecondLine, 
                    questions.map(q => q.certificate).filter(q => q !== undefined) as string[]));

                setShowCertificate(true);
                setShowIncorrect(false);
                setNotAnswered(false);
            } else {
                setShowIncorrect(true);
                setNotAnswered(false);
            }
        }
        const certificateRef = React.useRef();
        return <> {orderedQuestions.map((q, i) => {
            return <Row key={`quest-${i}`}>
                <Col>
                    <strong>{q.title}</strong>
                    {notAnswered && !(q.key in quizResponse) &&
                        <Alert textOnly variant="negative" text="You have not answered this question." />
                    }
                    {showIncorrect && quizResponse[q.key] === false &&
                        <Alert textOnly variant="negative" text="This answer is incorrect." />
                    }
                    <TwoChoice 
                        question={q} 
                        currentValue={quizResponse[q.key]}
                        setValue={(v) => {
                            const newVal = {...quizResponse}
                            newVal[q.key] = v;
                            setQuizResponse(newVal);
                        }}
                    />
                </Col>
            </Row>    
        }
        )}
        <Row>
            <Col className="text-right">
                <Button variant="active" onClick={validateQuiz}>Get My Certificate</Button>
            </Col>
        </Row>
        <Modal title="Certificate" show={showCeriticate} onHide={()=>{setShowCertificate(false)}} size="lg">
            {certificate && <Row className="mt-16">
                <Col>
                    <PDFViewer width="100%" height="800">
                            {certificate}
                    </PDFViewer>
                </Col>
            </Row>}
        </Modal>
    </>
    }

export default YesNoQuiz;