import { IconPencil, IconEye, IconGripVertical, IconGridDots, IconTrash, IconEyeOff } from "@tabler/icons";
import { Resume, SectionConfig } from "jobjump-types/Resume";
import React from "react";
import { SortableHandle } from "react-sortable-hoc";
import Button from "../../../components/Button";
import BaseCard from "../../../components/cards/BaseCard";
import { Label, TextField } from "../../../components/Form";
import { Col, Row } from "../../../components/grid";
import Modal from "../../../components/Modal";
import PageSectionRow from "../../../components/PageSectionRow";
import { ResumeResponse } from "../../../data/Resume";
import "./resume-sections.css";

export const DefaultSectionNames : {[k in SectionConfig["name"]]: string} = {
    "awards" : "Awards",
    "career_goal" : "Career Goal",
    "education_history" : "Education History",
    "employment_details" : "Employment History",
    "hobbies" : "Hobbies",
    "achievements" : "Achievements",
    "skills" : "Skills",
    "referees" : "Referees",
    "eWorkExperience": "eWork Experience Project",
    "qualifications": "Qualifications"
}

export type SubectionTemplateProps = {
    title: string,
    buttons? : {
        text:string,
        onClick?:()=>void
    }[],
    disallowChanges?:boolean,
    hideDragHandle?:boolean,
    sectionSetting: SectionConfig,
    updateSectionSetting: (s:SectionConfig)=>void
}

export type SubsectionProps<A extends keyof Resume> = {
    section: Resume[A],
    content: Exclude<ResumeResponse["contentId"], undefined>,
    setSection: (r:Resume[A])=>void,
    sectionConfig: SectionConfig,
    setSectionConfig: (c:SectionConfig)=>void
}

const deleteFromList = (oldArray: any[], index:number) => {
    const newArray = [...oldArray];
    newArray.splice(index, 1);
    return newArray;
}

function updateIndex<A extends object, B extends keyof A>(oldArray:A[], key:B, value: A[B], index:number) {
    const newArray = [...oldArray];
    newArray[index][key] = value;
    return newArray;
}

function updateObjectIndex<A extends object>(oldArray:A[], obj:A, index:number) {
    if (index === -1) {
        return oldArray;
    }
    const newArray = [...oldArray];
    newArray[index] = {...obj};
    return newArray;
}

const DragHandleLarge = SortableHandle(() => <span style={{cursor:"grab"}}><IconGridDots /></span>);
const DragHandle = SortableHandle(() => <span style={{cursor:"grab"}}><IconGripVertical /></span>);

const DeleteButton : React.FC<{onClick:()=>void}> = ({onClick}) =>
    <div className="text-right">
        <Button textButton onClick={onClick} variant="negative">Delete</Button>
    </div>

const SubsectionTemplate : React.FC<SubectionTemplateProps> = 
({title, buttons, children, disallowChanges, hideDragHandle, sectionSetting, updateSectionSetting}) => {
    const [showRename, setShowRename] = React.useState(false);
    const [newName, setNewName] = React.useState("");
    return <PageSectionRow className={sectionSetting.hidden ? "hidden-resume-section" : ""}>
        <Col>
            <BaseCard>
                <Row>
                    <Col>
                        <h3>
                            {!hideDragHandle && <span className="mr-8"><DragHandleLarge /></span>} 
                            {sectionSetting.alias && sectionSetting.alias !== "" ? sectionSetting.alias : title}
                        </h3>
                    </Col>
                    {!disallowChanges && <Col className="text-right" sm={1}>
                        <IconPencil onClick={()=>setShowRename(true)} />&nbsp;
                        {sectionSetting.hidden ? 
                            <IconEyeOff onClick={()=>updateSectionSetting({...sectionSetting, hidden:false})} /> :
                            <IconEye onClick={()=>updateSectionSetting({...sectionSetting, hidden:true})} />
                        }
                    </Col>}
                </Row>
                {children} 
                {buttons && buttons.length > 0 && <Row className="mt-32">
                    <Col>
                        <span className="mr-16">Add: </span>
                        {buttons.map((b, i) =>
                            <Button key={i} textButton variant="active" onClick={b.onClick} className="mr-32">
                                {b.text}
                            </Button>
                        )}
                    </Col>
                </Row>}
            </BaseCard>
        </Col>
        <Modal show={showRename} onHide={()=>setShowRename(false)}>
            <Row>
                <Col>
                    <Label>Section Name</Label>
                    <TextField value={newName} onChange={setNewName} />
                </Col>
            </Row>
            <Row className="mt-16">
                <Col className="text-right">
                    <Button variant="neutral" onClick={()=>{
                        updateSectionSetting({...sectionSetting, alias:undefined})
                        setShowRename(false);
                    }}>Reset Name</Button>
                    <Button className="ml-8" variant="active" onClick={()=>{
                        updateSectionSetting({...sectionSetting, alias:newName})
                        setNewName("");
                        setShowRename(false);
                    }}>Confirm</Button>
                </Col>
            </Row>
        </Modal>
    </PageSectionRow>
}

const SortableItemTemplate : React.FC<{onDelete?:()=>void, AlternativeDeleteIcon?:React.FC<{onClick:()=>void}>}> = ({children, onDelete, AlternativeDeleteIcon}) => <Row>
    <Col>
        <Row justify="center" alignItems="center">
            <div style={{flex: "0 0 24px"}}><DragHandle /></div>
            <Col>
                {children}
            </Col>
            {onDelete && <div style={{flex: "0 0 24px"}}>
                {AlternativeDeleteIcon && <AlternativeDeleteIcon onClick={onDelete} />}
                {!AlternativeDeleteIcon && <IconTrash style={{cursor:"pointer"}} onClick={onDelete} />}
            </div>}
        </Row>
    </Col>
</Row>

export {DragHandle, DeleteButton, SubsectionTemplate, deleteFromList, updateIndex, updateObjectIndex, SortableItemTemplate};