import React from "react";
import UpdateDetailsLayout from "./Layout";
import { UserDetails } from "jobjump-types/UserDetails";
import UserDetailsAPI from "../../data/UserDetails";
import { CareerNames } from "jobjump-types/Careers";
import CareerAPI from "../../data/Careers";
import "./changeSubscription.css";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "../../components/grid";
import { Radio } from "../../components/Form";
import MultiSelect from "../../components/Form/MultiSelect";
import Button from "../../components/Button";
import Alert from "../../components/Alert";
import CareerPreferencesAPI, { CareerPreference } from "../../data/CareerPreference";
import CareerSelectBox, { SelectOption } from "../registration/CareerSelectBox";


const UpdateSubscriptions : React.FC<RouteComponentProps> =
(props) => {
    const [details, setDetails] = React.useState<UserDetails>();
    const [careerPrefs, setCareerPrefs] = React.useState<SelectOption[]>([]);
    const [error, setError] = React.useState<string>();
    const [submitComplete, setSubmitComplete] = React.useState<boolean>(false);

    React.useEffect(() => {
        CareerPreferencesAPI.getPreferences().then(p =>
            setCareerPrefs(p.map(i => ({
                label:i.name, 
                value:`${i.kind === "gu" ? "ge": "jj"}-${i.id}`, 
                career:{
                    kind:i.kind==="gu"?"goodEdu":"jobjump",
                    jobjump:i.kind==="jobjump" ? {
                        name:i.name,
                        id:i.id,
                        demand_index:0
                    } : undefined,
                    goodEdu:i.kind === "gu" ? {
                       id:i.id,
                       name:i.name,
                       jobjump_id:i.jobjump_id,
                       jobjump_name:i.jobjump_name 
                    } : undefined
                }
            }))))
    }, []);

    React.useEffect(() => {
        UserDetailsAPI.getDetails().then(setDetails)
    }, []);

    React.useEffect(() => {
        if (!details) { return }
        UserDetailsAPI.updateDetails(details).catch(e => setError(e.toString()));
    }, [details]);

    const submit = React.useCallback(async () => {
        const subscriptions = careerPrefs.map(s => ({
            kind:s.career.kind === "goodEdu" ? "gu" : "jobjump" as "gu"|"jobjump",
            id : s.career.goodEdu?.id || s.career.jobjump?.id || -1
        }));
        const careers = CareerPreferencesAPI.bulkAdd(subscriptions);
        let detailsUpdate : undefined|Promise<any> = undefined;
        if (details) {
            detailsUpdate = UserDetailsAPI.updateDetails(details).catch(e => setError(e.toString()));
        }
        careers.then(() => (detailsUpdate || Promise.resolve()).then(() => setSubmitComplete(true)));
    }, [careerPrefs, details, setSubmitComplete, setError])

    return <UpdateDetailsLayout pageKey="news" 
        subtitle={["JobJump sends you relevant careers news based on your interests. Changed your mind about what you want? No problem! Use the settings below to update your news preferences."]} 
        title={"My News"} {...props}>
        {submitComplete && error && <Alert textOnly variant="negative" text={`An error occurred: ${error}`} />}
        {details && <Row>
            <Col>
                <h4>Do you want ot receive JobJump careers news?</h4>
                <Radio className="block" label="I want to receive the latest news about my favourite careers" 
                    name="want-email" checked={!details.unsubscribe} onChange={()=>setDetails({...details, unsubscribe:!details.unsubscribe})} /> 
                <Radio className="block" name="want-email" label="I don't want to receive the the latest careers news" checked={details.unsubscribe}
                    onChange={()=>setDetails({...details, unsubscribe:!details.unsubscribe})} />
            </Col>
        </Row>}
        <Row className="mt-16">
            <Col>
                <h4>Current News Choices</h4>
                <p>Use the search box below to find the careers that interest you to subscribe to them. You can remove careers to unsubscribe from them.</p>
            </Col>
        </Row>
        <Row className="mt-8"> <Col><h4>Careers</h4></Col></Row>
        <Row>
            <Col>
                <CareerSelectBox value={careerPrefs} onChange={(value)=> { setCareerPrefs([...value])} } />
            </Col>
        </Row>
        <Row className="mt-16 text-right">
            <Col>
                <Button variant="active" onClick={submit}>Submit</Button>
            </Col>
        </Row>
    </UpdateDetailsLayout> 
}

export default UpdateSubscriptions;