import React from "react";
import LazyLoad from "react-lazyload";
import Button from "../Button";
import { Col, Row } from "../grid";
import "./heroVideo.css";

interface HeroVideoProps {
    title: string,
    subtitle?: string,
    buttonText?:string,
    onButtonClick?:()=>void,
    videoUrl?:string,
    imageUrl?:string
}

const HeroVideo : React.FC<HeroVideoProps> = ({title, subtitle, buttonText, onButtonClick, videoUrl, imageUrl}) => {
    return <Row expanded>
        <Col>
            <section className="Splash">
                <div className="video-container">
                {videoUrl && <LazyLoad><video autoPlay muted loop>
                    <source type="video/mp4" src={videoUrl} />
                </video></LazyLoad>}
                {imageUrl && <img src={imageUrl} />}
                </div>
                <div className="video-float-mt-5">
                    <Row>
                        <Col className="text-center">
                            <h1 className="hero-video-text">{title}</h1>
                            {subtitle && <h2 className="hero-video-text">{subtitle}</h2>}
                        </Col>
                    </Row>
                    {buttonText && onButtonClick && <Row>
                        <Col className="text-center">
                            <Button variant="active" onClick={onButtonClick}>{buttonText}</Button>
                        </Col>
                    </Row>}
                </div>
            </section>
        </Col>
    </Row>
}

export default HeroVideo;