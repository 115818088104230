import { HeroConfiguration, SchoolHomepage } from "jobjump-types/SchoolHomePage";
import React from "react";
import { RouteComponentProps } from "react-router";
import SchoolHomepageAPI from "../../data/SchoolHomepage";
import Layout from "./Layout";

interface MatchParams {
    shortCode: string
}

type SchoolHomepageWrapperProps = RouteComponentProps<MatchParams>;

const SchoolHomepageWrapper : React.FC<SchoolHomepageWrapperProps> = (props) => {
    const shortCode = props.match.params.shortCode;
    const [schoolConfig, setSchoolConfig] = React.useState<SchoolHomepage>();
    const [schoolId, setSchoolId] = React.useState<string>();
    const [heroVideo, setHeroVideo] = React.useState<HeroConfiguration>();
    React.useEffect(() => {
        SchoolHomepageAPI.getSiteConfig({shortCode}).then(c=>{setSchoolConfig(c && c.homepage); setSchoolId(c&&c.schoolId)});
    }, [shortCode, setSchoolConfig]);
    React.useEffect(() => {
        SchoolHomepageAPI.getHero({shortCode}).then(setHeroVideo);
    }, [shortCode, setHeroVideo])
    if (!schoolConfig || !schoolId) { return <></> }
    return <Layout layout={schoolConfig} schoolId={schoolId} heroConfig={heroVideo} {...props} />
}

export default SchoolHomepageWrapper;