import React from "react";
import {Row, Col} from "../components/grid";
import Modal from "../components/Modal";
import Button from "../components/Button";
import "./login.css";
import User from "../data/User";
import { UserDetails } from "jobjump-types/User";
import { TextField } from "../components/Form";
import Alert from "../components/Alert";

export interface JobJumpLoginProps {
    setUserDetails : (d:UserDetails) => void,
}

const JobJumpLogin : React.FC<JobJumpLoginProps> =
    ({setUserDetails} : JobJumpLoginProps) => {
        const [hasPassword, setHasPassword] = React.useState("unknown");
        const [userEmail, setUserEmail] = React.useState("");
        const [userPassword, setUserPassword] = React.useState("");
        const [invalidLogin, setInvalidLogin] = React.useState(false);
        const [showForgotPassword, setShowForgotPassword] = React.useState(false);

        const emailDefocus = () => {
            User.hasPassword(userEmail).then((hasPassword) => {
                if (!hasPassword) { return; }
                switch (hasPassword) {
                    case "InvalidAccount" : setHasPassword("invalidAccount"); break;
                    case "NoPassword" : setHasPassword("noPassword"); break;
                    case "HasPassword" : setHasPassword("hasPassword"); break;
                }
            });
        }

        const submitForm = React.useCallback(() => { 
            console.log("Do a thing");
            if (userEmail !== "" && userPassword !== "") {
                User.login(userEmail, userPassword).catch(()=>{setInvalidLogin(true); return undefined}).then(d=>d && setUserDetails(d));
            }
        }, [setInvalidLogin, setUserDetails, userEmail, userPassword]);
        return <>
            <Row><Col>
                <h4>Welcome Back</h4>
            </Col></Row>
            <form className="login-form" onSubmit={(e:any)=>{e.preventDefault(); e.stopPropagation(); submitForm();}}>
                <Row><Col>
                <TextField 
                    value={userEmail !== "" ? userEmail : undefined} 
                    onChange={setUserEmail}
                    onBlur={emailDefocus}
                    emailField
                    placeholder="Enter email" />
                <TextField 
                        className="mt-8"
                        value={userPassword !== "" ? userPassword : undefined}
                        onChange={setUserPassword}
                        passwordField
                        placeholder="Enter password" />
                </Col></Row>
                {invalidLogin &&
                    <Row className="mt-16"><Col>
                        <Alert textOnly variant="negative" text={`Invalid password. Try again or press "Forgot Password" to reset.`} />
                    </Col></Row>}
                {hasPassword === "noPassword" && 
                    <Row className="mt-16"><Col>
                        <Alert textOnly variant="negative" text={`From 2016 JobJump requires all users to have a password. Press the button 
                        below to be emailed a link to set your password. Follow the instructions
                        in the email to set your password.`} />
                        <Button variant="active" className="mt-8" onClick={()=>setShowForgotPassword(true)}>Create Password</Button>
                    </Col></Row>}
               {hasPassword === "invalidAccount" && 
                <Row className="mt-16"><Col>
                    <Alert textOnly variant="negative" text={`Invalid email address. Please check your email address. If you are new, click "I'm New" above.`} />
                </Col></Row>}
                {hasPassword !== "noPassword" && <Row className="mt-16"><Col className="text-left">
                    <Button fullWidth className="pt-16 pb-16" variant="active" onClick={submitForm}>Continue</Button>
                </Col></Row>}
                {userEmail !== "" && <Row className="mt-16"><Col className="text-left">
                     <Button variant="neutral" textButton className="forgot-password" onClick={()=>setShowForgotPassword(true)}>Forgot Password</Button>
                </Col></Row>}
            </form>
            <Modal show={showForgotPassword} onHide={()=>setShowForgotPassword(false)} title="Forgot Passsword">
                <Row>
                    <Col>
                        To reset your JobJump password, press confirm. You will be emailed a link with details on resetting your password.
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button variant="active" onClick={()=>{User.forgotPassword(userEmail).then(()=>setShowForgotPassword(false))}}>Confirm</Button>
                    </Col>
               </Row>
            </Modal>
        </>;
    }

export default JobJumpLogin;