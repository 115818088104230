import React from "react";
import Progress from "../../components/Progress";

type PossibleSteps = "School"|"Details"|"Careers";

type Props = {
    stepsTaken: PossibleSteps[]
}

const RegistrationProgress : React.FC<Props> = ({stepsTaken}) =>
    <Progress steps={[
        {title:"School", active:stepsTaken.includes("School")}, 
        {title:"Details", active:stepsTaken.includes("Details")}, 
        {title:"Careers", active:stepsTaken.includes("Careers")}
    ]} />

export default RegistrationProgress;