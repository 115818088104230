const fetchHandleServerStatusJSON = (url:RequestInfo, opts?:RequestInit) =>
    fetch(url,opts).then(r=>{
        if (r.status === 403 || r.status === 500) {
            return Promise.reject(`${r.status} - ${r.body}`);
        }
        return Promise.resolve(r.json())
    })

const fetchHandleServerStatus = (url:RequestInfo, opts?:RequestInit) =>
    fetch(url,opts).then(r=>{
        if (r.status === 403 || r.status === 500) {
            return Promise.reject(`${r.status} - ${r.body}`);
        }
        return Promise.resolve(r)
    })

const fetchIgnoreAuth = (url:RequestInfo, opts?:RequestInit) =>
    fetchHandleServerStatus(url, opts).then(r=>{
        if (r.status === 401) {
            return Promise.reject("Invalid Login");
        }
        return Promise.resolve(r);
    })

const fetchWithAuth = (url:RequestInfo, opts?:RequestInit) =>
    fetchHandleServerStatus(url, opts).then(r=>{
        if (r.status === 401) {
            window.location.href = "/login";
            return Promise.reject("Invalid Login");
        }
        return Promise.resolve(r);
    })

export {fetchHandleServerStatus, fetchHandleServerStatusJSON, fetchWithAuth, fetchIgnoreAuth};