import React from "react";
import UpdateDetailsLayout from "./Layout";
import { UserDetails } from "jobjump-types/UserDetails";
import UserDetailsAPI from "../../data/UserDetails";
import User from "../../data/User";
import { RouteComponentProps } from "react-router";
import { Col, Row } from "../../components/grid";
import Button from "../../components/Button";
import { Label, Select, TextField } from "../../components/Form";
import Alert from "../../components/Alert";

const UpdateDetails : React.FC<RouteComponentProps> =
(props) => {
    const [details, setDetails] = React.useState<UserDetails>();
    const [originalEmail, setOriginalEmail] = React.useState<string>();
    const [error, setError] = React.useState<string>();
    const [submitComplete, setSubmitComplete] = React.useState<boolean>(false);
    React.useEffect(() => {
        UserDetailsAPI.getDetails().then((d) => { setDetails(d); setOriginalEmail(d.email); })
    }, []);
    const submit = React.useCallback(() => {
        if (!details) { return; }
        const filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]+)+$/;
        if (filter.test(details.email)) {
            User.hasPassword(details.email).then(r => {
                console.log(originalEmail, r);
                if (details.email === originalEmail || r === "InvalidAccount") {
                    console.log("Updating profile!");
                    UserDetailsAPI.updateDetails(details).catch(e => setError(e.toString())).then(() => { setSubmitComplete(true); setOriginalEmail(details.email); });
                } else {
                    setError("Email address already associated with another account. If this is your email address, please use the forgot password link on the login screen to recover that account.")
                    setSubmitComplete(true);
                }
            });
            return;
        }
        setSubmitComplete(true);
        setError("Invalid email address");
    }, [details, setSubmitComplete, setError, originalEmail])
    return <UpdateDetailsLayout 
        subtitle={["Something needs changing? Use the form below to update your details."]}
        title={"Update Details"} pageKey="details" {...props}>
        {submitComplete && error && <Alert textOnly variant="negative" text={`An error occurred: ${error}`}/>}
        <Row>
            <Col>
                {details && <form>
                    <Row><Col>
                        <Label>First Name</Label>
                        <TextField value={details.first} onChange={(first)=>setDetails({...details,first})} />
                    </Col></Row>
                    <Row><Col>
                        <Label>Last Name</Label>
                        <TextField value={details.last} onChange={((last)=>setDetails({...details,last}))} />
                    </Col></Row>
                    <Row><Col>
                        <Label>Email</Label>
                        <TextField emailField value={details.email} onChange={((email)=>setDetails({...details,email}))} />
                    </Col></Row>
                    {(details.registration_type !== "teacher" && details.registration_type !== "advisor") && <Row><Col>
                        <Label>Year</Label>
                        <Select options={[7,8,9,10,11,12,13].map(i => ({label:`Year ${i}`, value:String(i)}))} value={{label:`Year ${details.year}`, value:String(details.year)}} 
                            onChange={(v)=>v&&setDetails({...details,year:Number(v.value)})} />
                    </Col></Row>}
                </form>}
            </Col>
        </Row>
        <Row>
            <Col className="text-right mt-16">
                <Button variant="active" onClick={submit}>Submit</Button>
            </Col>
        </Row>
    </UpdateDetailsLayout> 
}

export default UpdateDetails;