import React from "react";
import {Row, Col} from "../../../components/grid";
import Button from "../../../components/Button";
import { StudentInterviewRecord } from "jobjump-types/CaStudents";
import CaStudentDetails  from "../../../data/CaStudentDetails";
import { RouteComponentProps } from "react-router";
import CardTable from "../../../components/CardTable";
import { IconPencil, IconSearch, IconTrash } from "@tabler/icons";
import { TextField } from "../../../components/Form";
import Modal from "../../../components/Modal";

interface InterviewRecordsProps extends RouteComponentProps {
    studentID : string
}

const InterviewRecords : React.FC<InterviewRecordsProps> =
    ({studentID, history}) => {
        const [interviewRecords, setInterviewRecords] = React.useState<StudentInterviewRecord[]>([])
        const [interviewToShow, setInterviewToShow] = React.useState<StudentInterviewRecord>();
        React.useEffect(() => {
            CaStudentDetails.studentInterviewRecord(studentID).then(setInterviewRecords)
        }, [studentID])
        const deleteInterview = React.useCallback((id) => {
            CaStudentDetails.deleteStudentInterview(id).then(() => {
                setInterviewRecords([...interviewRecords].filter(d => d.id !== id))
            })
        },[interviewRecords])
        return <Row><Col>
            {interviewRecords.length > 0 && <Row>
                <Col>
                    <CardTable
                        data={interviewRecords} 
                        columns={[
                            {
                                accessor : "date",
                                Header: "Date",
                                width:3
                            },
                            {
                                id : "time",
                                Header : "Time",
                                width:3,
                                Cell: (d) => `${d.row.original.start_time} - ${d.row.original.end_time}`
                            },
                            {
                                Header:"View",
                                width: 2,
                                id:"view",
                                Cell:({row:{original}})=><IconSearch onClick={()=>setInterviewToShow(original)} />
                            },
                            {
                                Header:"Edit",
                                width: 2,
                                id:"edit",
                                Cell:({row:{original}})=><IconPencil className="text-right" onClick={()=>history.push(`/admin/interview/${studentID}/${original.id}`)} />
                            },
                            {
                                id : "delete",
                                Header: "Delete",
                                width: 2,
                                Cell : (d) => <IconTrash className="text-right" onClick={()=>deleteInterview(d.row.original.id)} />,
                                defaultCanFilter : false,
                                defaultCanSort : false
                            }
                        ]}
                        renderToolbar={()=><Row>
                            <Col className="text-right">
                                <Button variant="active" onClick={()=>history.push(`/admin/interview/${studentID}`)}>
                                    Record Interview
                                </Button>
                            </Col>
                        </Row>}
                        renderFilters={(globalFilter, setGlobalFilter) => 
                            <Row>
                                <Col>
                                    <TextField value={globalFilter} onChange={setGlobalFilter} placeholder="Searc=..." />
                                </Col>
                            </Row>
                        }
                    />
                </Col>
            </Row>}
            {interviewRecords.length === 0 && <Row>
                <Col>
                    <p>No interviews recorded for student yet.</p>
                    <Button variant="active" className="text-center" onClick={()=>history.push(`/admin/interview/${studentID}`)}>
                        Record Interview
                    </Button>
                </Col>
            </Row>}
            <Modal show={interviewToShow !== undefined} onHide={()=>setInterviewToShow(undefined)}>
                {interviewToShow && <Row><Col>
                    <Row>
                        <Col>
                            <h4>{interviewToShow.date} ({interviewToShow.start_time}-{interviewToShow.end_time})</h4>
                        </Col>
                    </Row>
                    <Row className="mt-8">
                        <Col>
                            <p style={{whiteSpace:"pre-line"}}>{interviewToShow.comment}</p>
                        </Col>
                    </Row>
                </Col></Row>}
            </Modal>
        </Col></Row>
    }

export default InterviewRecords;