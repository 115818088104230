import React from "react";
import { RouteComponentProps } from "react-router-dom";
import ContentWrapper, { ContentWrapperProps } from "../../components/ContentWrapper";
import SecondaryMenu from "../../components/SecondaryMenu";
import { DestinationIcon, MsgRecordsIcon, SiteManagementIcon, StudentCareersIcon, StudentCoursePrefsIcon, StudentManagmentIcon, StudentNeedsIcon, StudentSubjectsIcon } from "../../components/SVGLibrary/Icons";

const CaAdminLayout : React.FC<RouteComponentProps & Omit<ContentWrapperProps, "breadcrumbs"> & {noBreadcrumb?:boolean, active:string}> =
    ({children, title, subtitle, decorationType, noBreadcrumb, active, ...props}) =>
        <ContentWrapper breadcrumbs={noBreadcrumb ? undefined : [
                {name:"Careers Advisers Dashboard", link:"/admin"},
            ]} {...{title,subtitle,decorationType}}>
            <SecondaryMenu 
                activePage={active}
                title="Select Your Destination"
                secondaryLink={[{name:"Resources", link:"/admin/resources"}, {name:"Help Videos", link:"/admin/help"}]}
                menuItems={[
                    { name:"Students", icon:StudentManagmentIcon, highlighted:true, borderTop:false, pageKey:"students", subItems:[
                        {name:"Dashboard", link:"/admin"},
                        {name:"Interview Records", link:"/admin/interviews"},
                        {name:"Pre-Work Check", link:"/admin/preWorkCheck"},
                        {name:"Leaving Year", link:"/admin/leavingYear"},
                    ]},
                    { name:"Careers", icon:StudentCareersIcon, highlighted:true, borderTop:false, pageKey:"careers", subItems: [
                        {name:"Student's Careers", link:"/admin/careers"},
                        {name:"Student's Resumes", link:"/admin/resumes"},
                        {name:"eWork Experience Projects", link:"/admin/projects"},
                        {name:"Career Wiz Quiz Results", link:"/admin/quiz"},
                    ]},
                    { name:"Course Preferences", icon:StudentCoursePrefsIcon, highlighted:true, borderTop:false, pageKey:"prefs", subItems: [
                        {name:"Course Preferences", link:"/admin/preferences"},
                        {name:"Prelim Uni", link:"/admin/uniContact"},
                    ] },
                    { name:"Subjects", icon:StudentSubjectsIcon, highlighted:true, borderTop:false, pageKey:"subjects", linkTo:{localLink:"/admin/subjects"}},
                    { name:"Needs", icon:StudentNeedsIcon, highlighted:true, borderTop:false, pageKey:"needs", linkTo:{localLink:"/admin/needs"}},
                    { name:"Destination", icon:DestinationIcon, highlighted:true, borderTop:false, pageKey:"destination", linkTo:{localLink:"/admin/destination"}},
                    { name:"Msg History", icon:MsgRecordsIcon, highlighted:true, borderTop:false, pageKey:"message", linkTo:{localLink:"/admin/messageHistory"}},
                    { name:"Site Management", icon:SiteManagementIcon, highlighted:true, borderTop:false, pageKey:"admin", subItems:[
                        {name:"Careers Noticeboard", link:"/admin/noticeboard"},
                        {name:"School Documents/Bulletins", link:"/admin/files"},
                        {name:"Manage School Site", link:"/admin/homepageSetup"},
                    ]},
                ]}
                {...props} />
            {children}
        </ContentWrapper>

export default CaAdminLayout;