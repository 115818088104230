import React from "react";
import { StudentPreworkCheckStatus } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import PageSectionRow from "../../components/PageSectionRow";
import { Col, Row } from "../../components/grid";
import PopoutHover from "../../components/PopoutHover";
import { IconBriefcase, IconClipboardList, IconFall, IconFile, IconMessageCircle, IconNotebook, IconPhone, IconShirt, IconTools, IconVideo } from "@tabler/icons";
import "./preworkStatus.css";

const StudentPreWorkCheck : React.FC<RouteComponentProps> =
    (props) => {
        const [data, setData] = React.useState<StudentPreworkCheckStatus[]>([]);
        React.useEffect(() => {
            CaStudentDetails.preworkCheckStatus().then(setData);
        }, []);
        return <CaAdminLayout active="students" title="Student Pre-Work Check" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Pre-Work Check</h3>
                    <span>Below is a list of your students and their progress through the Pre-Work Check section. You can hover on an icon to see what section it represents.</span>
                    <Row verySmall>
                        <Col className="mt-32">
                            <h4>Legend:</h4>
                            <Row>
                                <Col sm={3} className="text-center"><IconVideo /></Col>
                                <Col sm={9} className="text-left">Know My Career</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconTools /></Col>
                                <Col sm={9} className="text-left">eWork Experience Project</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconClipboardList /></Col>
                                <Col sm={9} className="text-left">Employment Skills</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconBriefcase /></Col>
                                <Col sm={9} className="text-left">Soft Skills</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconNotebook /></Col>
                                <Col sm={9} className="text-left">Literacy &amp; Numeracy</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconFile /></Col>
                                <Col sm={9} className="text-left">Resume</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconPhone /></Col>
                                <Col sm={9} className="text-left">Cold Canvassing</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconMessageCircle /></Col>
                                <Col sm={9} className="text-left">The Interview</Col>
                            </Row>
                            <Row>
                                <Col sm={3} className="text-center"><IconFall /></Col>
                                <Col sm={9} className="text-left">WHS</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        additionalColumns={[
                            { 
                                Header:"Progress", 
                                id:"progress",
                                width:4,
                                Cell:({row:{original}})=><>
                                    <PopoutHover className="mr-8" elem={<IconVideo className={original.workCheckStatus.doneCareer ? "active-icon" : "deactivated-icon"}/>}>Know My Career</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconTools className={original.workCheckStatus.doneProject ? "active-icon" : "deactivated-icon"}/>}>eWork Experience Project</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconClipboardList className={original.workCheckStatus.doneEmploymentSkills ? "active-icon" : "deactivated-icon"}/>}>Employment Skills</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconBriefcase className={original.workCheckStatus.doneSoftSkills ? "active-icon" : "deactivated-icon"}/>}>Soft Skills Quiz</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconNotebook className={original.workCheckStatus.doneLitNum ? "active-icon" : "deactivated-icon"}/>}>Literacy &amp; Numeracy Quiz</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconFile className={original.workCheckStatus.doneResume ? "active-icon" : "deactivated-icon"}/>}>Resume</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconPhone className={original.workCheckStatus.doneColdCall ? "active-icon" : "deactivated-icon"}/>}>Cold Calling</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconMessageCircle className={original.workCheckStatus.doneInterview ? "active-icon" : "deactivated-icon"}/>}>The Interview</PopoutHover>
                                    <PopoutHover className="mr-8" elem={<IconFall className={original.workCheckStatus.doneWhs ? "active-icon" : "deactivated-icon"} />}>WHS Quiz</PopoutHover>
                                </>
                            },
                        ]} />
                </Col>
            </PageSectionRow>


        </CaAdminLayout>
    }

export default StudentPreWorkCheck;