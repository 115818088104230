import AtarCareerJourney from "./homepage/tiles/atar-career-journey-2.jpg"
import AtarChoosingHscSubjects from "./homepage/tiles/atar-choosing-hsc-subjects.jpg"
import CareerAdviserDashboard from "./homepage/tiles/career-adviser-dashboard.jpg"
import CareerNews from "./homepage/tiles/career-news.jpg"
import CareerVideos from "./homepage/tiles/career-videos.jpg"
import EverythingYouNeed from "./homepage/tiles/everything-you-need.jpg"
import FindMyCareerTool from "./homepage/tiles/find-my-career-tool.jpg"
import GetAJobInTheDefence from "./homepage/tiles/get-a-job-in-the-defence.jpg"
import GettingYouTaxFileNumber from "./homepage/tiles/getting-you-tax-file-number.jpg"
import HowToUse from "./homepage/tiles/how-to-use.jpg"
import HscLessonPlans from "./homepage/tiles/hsc-lesson-plans.jpg"
import HscWhatJobsPay from "./homepage/tiles/hsc-what-jobs-pay.jpg"
import JobGrowthDecline from "./homepage/tiles/job-growth-decline.jpg"
import JobJumpPoster from "./homepage/tiles/job-jump-poster.jpg"
import LearningCprAndFirstAid from "./homepage/tiles/learning-cpr-and-first-aid.jpg"
import MyCareersFacebook from "./homepage/tiles/my-careers-facebook.jpg"
import MyResumeForCareers from "./homepage/tiles/my-resume-for-careers.jpg"
import ParentHighSchoolCareer from "./homepage/tiles/parent-high-school-career.jpg"
import SchoolLoginClassroom from "./homepage/tiles/school-login-classroom.jpg"
import SearchingForAJob from "./homepage/tiles/searching-for-a-job.jpg"
import StudentFindingACareer from "./homepage/tiles/student-finding-a-career.jpg"
import StudentMoneyHelp from "./homepage/tiles/student-money-help.jpg"
import StudyingAtTafe from "./homepage/tiles/studying-at-tafe.jpg"
import TraineeshipsAndAprenticeships from "./homepage/tiles/traineeships-and-aprenticeships.jpg"
import UniqueStudentIdentifier from "./homepage/tiles/unique-student-identifier.jpg"
import UniversityAccommodation from "./homepage/tiles/university-accommodation.jpg"
import UniversityAdjustmentFactors from "./homepage/tiles/university-adjustment-factors.jpg"
import UniversityAlternativeEntry from "./homepage/tiles/university-alternative-entry.jpg"
import UniversityAtsi from "./homepage/tiles/university-atsi-2.jpg"
import UniversityDeferment from "./homepage/tiles/university-deferment.jpg"
import UniversityEarlyAccessScheme from "./homepage/tiles/university-early-access-scheme.jpg"
import UniversityEarlyEntrySrs from "./homepage/tiles/university-early-entry-srs.jpg"
import UniversityInterstateAndOverseas from "./homepage/tiles/university-interstate-and-overseas.jpg"
import UniversityOpenDays from "./homepage/tiles/university-open-days.jpg"
import UniversityOverseasExchange from "./homepage/tiles/university-overseas-exchange.jpg"
import UniversityScholarships from "./homepage/tiles/university-scholarships.jpg"
import UniversityUcat from "./homepage/tiles/university-ucat.jpg"
import WorkExperiance from "./homepage/tiles/work-experiance.jpg"
import WorkSafetyQuiz from "./homepage/tiles/work-safety-quiz.jpg" 
import GradCap from "./school-homepage/grad-cap.png";
import CommonInterviewQuestions from "./my_job_ads/common-interview-question.jpg";
import CallingABoss from "./my_job_ads/calling_a_boss.jpg";
import UniCampus1 from "./uni-campus-1.jpg";
import UniCampus2 from "./uni-campus-2.jpg";
import StudentsTalking from "./students.jpg";

const TileImageStore = {
   "AtarSearch":GradCap,
   "AtarCareerJourney" : AtarCareerJourney,
   "AtarChoosingHscSubjects" : AtarChoosingHscSubjects,
   "CareerAdviserDashboard" : CareerAdviserDashboard,
   "CareerNews" : CareerNews,
   "CareerVideos" : CareerVideos,
   "EverythingYouNeed" : EverythingYouNeed,
   "FindMyCareerTool" : FindMyCareerTool,
   "GetAJobInTheDefence" : GetAJobInTheDefence,
   "GettingYouTaxFileNumber" : GettingYouTaxFileNumber,
   "HowToUse" : HowToUse,
   "HscLessonPlans" : HscLessonPlans,
   "HscWhatJobsPay" : HscWhatJobsPay,
   "JobGrowthDecline" : JobGrowthDecline,
   "JobJumpPoster" : JobJumpPoster,
   "LearningCprAndFirstAid" : LearningCprAndFirstAid,
   "MyCareersFacebook" : MyCareersFacebook,
   "MyResumeForCareers" : MyResumeForCareers,
   "ParentHighSchoolCareer" : ParentHighSchoolCareer,
   "SchoolLoginClassroom" : SchoolLoginClassroom,
   "SearchingForAJob" : SearchingForAJob,
   "StudentFindingACareer" : StudentFindingACareer,
   "StudentMoneyHelp" : StudentMoneyHelp,
   "StudyingAtTafe" : StudyingAtTafe,
   "TraineeshipsAndAprenticeships" : TraineeshipsAndAprenticeships,
   "UniqueStudentIdentifier" : UniqueStudentIdentifier,
   "UniversityAccommodation" : UniversityAccommodation,
   "UniversityAdjustmentFactors" : UniversityAdjustmentFactors,
   "UniversityAlternativeEntry" : UniversityAlternativeEntry,
   "UniversityAtsi" : UniversityAtsi,
   "UniversityDeferment" : UniversityDeferment,
   "UniversityEarlyAccessScheme" : UniversityEarlyAccessScheme,
   "UniversityEarlyEntrySrs" : UniversityEarlyEntrySrs,
   "UniversityInterstateAndOverseas" : UniversityInterstateAndOverseas,
   "UniversityOpenDays" : UniversityOpenDays,
   "UniversityOverseasExchange" : UniversityOverseasExchange,
   "UniversityScholarships" : UniversityScholarships,
   "UniversityUcat" : UniversityUcat,
   "WorkExperiance" : WorkExperiance,
   "WorkSafetyQuiz" : WorkSafetyQuiz, 
   "CommonInterviewQuestions": CommonInterviewQuestions,
   "CallingABoss": CallingABoss,
   "UniCampus1": UniCampus1,
   "UniCampus2": UniCampus2,
   "StudentsTalking": StudentsTalking
}

export default TileImageStore;