import ScholarshipsImg from "../../assets/homepage/tiles/university-scholarships.jpg";
import BonusPointsImg from "../../assets/homepage/tiles/university-adjustment-factors.jpg";
import AltEntryImg from "../../assets/homepage/tiles/university-alternative-entry.jpg";
import ATSIImg from "../../assets/homepage/tiles/university-atsi-2.jpg";
import ExchangeImg from "../../assets/homepage/tiles/university-overseas-exchange.jpg";
import AccommodationImg from "../../assets/homepage/tiles/university-accommodation.jpg";
import VirtualTourImg from "../../assets/homepage/tiles/university-open-days.jpg";
import EarlyEntryImg from "../../assets/homepage/tiles/university-early-entry-srs.jpg";
import TileImageStore from "../../assets/JobJumpPageImages";
import UniLogos from "../../assets/UniLogos";
import {GenericImages} from "../../assets/GenericImages";

export type LinkTo = {
    link : string,
    text? : string
};

export type CategoryTypes = 
    "scholarships" | 
    "bonusPoints" | 
    "alternativeEntry" | 
    "earlyEntry" |
    "atsi" | 
    "exchange" | 
    "accommodation" | 
    "virtualTour" ;

const uniCategoryPrettyNames : {[k in CategoryTypes]:string} = {
    "scholarships": "Scholarships",
    "earlyEntry": "Early Entry",
    "bonusPoints": "Adjustment Factors" ,
    "alternativeEntry": "Alternative Entry", 
    "virtualTour": "Virtual Tour",
    "atsi": "First Nations", 
    "accommodation": "Accommodation",
    "exchange": "Overseas Exchange", 
}

const uniCategoryImages : {[k in CategoryTypes]:string[]} = {
    "scholarships": [ScholarshipsImg, TileImageStore["GettingYouTaxFileNumber"]],
    "bonusPoints": [BonusPointsImg, TileImageStore["StudentMoneyHelp"]],
    "alternativeEntry": [AltEntryImg], 
    "earlyEntry": [EarlyEntryImg, GenericImages.FeetOnPavement],
    "virtualTour": [VirtualTourImg],
    "atsi": [ATSIImg], 
    "accommodation": [AccommodationImg],
    "exchange": [ExchangeImg], 
}

type UniEvent = {
    date?: Date,
    url: string,
    campus?:string
};
 
export type UniConfig = {
    [k in CategoryTypes]: LinkTo[];
} & {id:string, image:string, states:string[], abbr:string, events:UniEvent[]};

const uniConfig : {[k:string] : UniConfig} = {
    "Australian Catholic University" : {
        id:"175",
        abbr: "ACU",
        states: ["NSW", "VIC", "ACT", "WA", "QLD"],
        image:UniLogos["ACU"],
        "scholarships" : [{
            link : "http://www.acu.edu.au/study_at_acu/courses/scholarships_and_financial_support",
            text : "Summary"
        }, 
        {
            link : "http://www.acu.edu.au/study_at_acu/courses/scholarships_and_financial_support/Scholarships_and_Bursaries_Browser",
            text : "Undergraduate"
        }
        ], 
        "bonusPoints" : [{
            link : "http://www.acu.edu.au/study_at_acu/alternate_entry/accessacu",
            text : "Summary"
        }        ], 
        "alternativeEntry" : [{
            link : "http://www.acu.edu.au/study_at_acu/alternate_entry",
        }], 
        "earlyEntry" : [{
            text: "ACU Guarantee",
            link: "https://www.acu.edu.au/study-at-acu/admission-pathways/acu-guarantee"
        }], 
        "atsi" : [{
            link : "http://www.acu.edu.au/about_acu/faculties,_institutes_and_centres/centres/centre_for_indigenous_education_and_research",
        }], 
        "exchange" : [{
            link : "http://www.acu.edu.au/courses/other_courses/exchange_and_study_abroad",
        }], 
        "accommodation" : [{
            link : "http://www.acu.edu.au/international/living_in_australia/accommodation",
        }], 
        "virtualTour" : [{
            link : "https://www.acu.edu.au/student-life/experience-uni-before-you-start/explore-your-local-campus/campus-tours",
        }], 
        events: [
            {
                campus:"Blacktown",
                date: new Date("3 August 2024"),
                url: "https://www.acu.edu.au/student-life/experience-uni-before-you-start/open-day"
            },
            {
                campus:"North Sydney",
                date: new Date("31 August 2024"),
                url: "https://www.acu.edu.au/student-life/experience-uni-before-you-start/open-day"
            },
            {
                campus:"Canberra",
                date: new Date("25 August 2024"),
                url: "https://www.acu.edu.au/student-life/experience-uni-before-you-start/open-day"
            },
            {
                campus:"Strathfield",
                date: new Date("17 August 2024"),
                url: "https://www.acu.edu.au/student-life/experience-uni-before-you-start/open-day"
            }
        ]
    },
    "Australian National University" : {
        id:"177",
        abbr: "ANU",
        states: ["ACT"],
        image:UniLogos["ANU"],
        "scholarships" : [{
            link : "http://students.anu.edu.au/scholarships/",
            text : "Summary"
        }], 
        "bonusPoints" : [{
            link : "http://www.anu.edu.au/study/apply/anu-adjustment-factors",
            text : "Summary"
        }], 
        "alternativeEntry" : [{
            link : "https://www.anu.edu.au/study/apply/anu-college-pathway-programs",
        }], 
        "earlyEntry" : [], 
        "atsi" : [{
            link : "http://indigenous.anu.edu.au/",
        }], 
        "exchange" : [{
            link : "http://students.anu.edu.au/manage/exchange/",
        }], 
        "accommodation" : [{
            link : "http://rcc.anu.edu.au/",
        }], 
        "virtualTour" : [{
            link : "https://www.youtube.com/watch?v=LrByokzunww",
        }], 
        events: [
            { campus:"Canberra", date: new Date("16 March 2024"), url: "https://openday.anu.edu.au" }]
   },
    "Charles Sturt University" : {
        id:"181",
        abbr: "CSU",
        states: ["NSW"],
        image:UniLogos["CSU"],
        "scholarships" : [{
            link : "http://futurestudents.csu.edu.au/help-with-costs/scholarships",
            text : "Summary"
        }, 
        {
            link : "http://futurestudents.csu.edu.au/help-with-costs/scholarships/csu-scholarships-and-grants",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "http://futurestudents.csu.edu.au/study-options/pathways/regional-bonus",
            text : "Summary"
        }], 
        "alternativeEntry" : [{
            link : "http://www.csu.edu.au/oncampus/getting-to-uni",
        }], 
        "earlyEntry" : [{
            text: "Advantage",
            link: "https://study.csu.edu.au/study-options/pathways/charles-sturt-advantage"
        }], 
        "atsi" : [{
            link : "http://futurestudents.csu.edu.au/unilife/indigenous",
        }], 
        "exchange" : [{
            link : "http://www.csu.edu.au/csuglobal/home",
        }], 
        "accommodation" : [{
            link : "https://accommodation.csu.edu.au/home",
        }], 
        "virtualTour" : [
            {link:"https://study.csu.edu.au/"}
        ], 
        events: [
            {
                campus:"Port Macquarie",
                date: new Date("4 August 2024"),
                url: "https://study.csu.edu.au/life/events"
            },
            {
                campus:"Wagga Wagga",
                date: new Date("28 July 2024"),
                url: "https://study.csu.edu.au/life/events"
            },
            {
                campus:"Albury-Wodonga",
                date: new Date("11 August 2024"),
                url: "https://study.csu.edu.au/life/events"
            },
            {
                campus:"Bathurst",
                date: new Date("18 August 2024"),
                url: "https://study.csu.edu.au/life/events"
            },
            {
                campus:"Orange",
                date: new Date("25 August 2024"),
                url: "https://study.csu.edu.au/life/events"
            }
        ] 
    },    
    "CQ" : {
        id: "",
        abbr: "CQ",
        states: ["NSW", "SA", "WA", "QLD"],
        image: UniLogos["CQ"],
        "scholarships": [],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            {
                link:"https://www.youtube.com/watch?v=wfd7Kt6DF44"
            }
        ],
        events: [
        ]
    },
    "Grifith University": {
        id: "",
        abbr: "Griffith",
        states: ["NSW", "QLD"],
        image: UniLogos["Griffith"],
        "scholarships": [],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            {link:"https://www.youtube.com/watch?v=B8lXH_W8VOU"}
        ],
        events: [
            { campus:"Gold Coast", date: new Date("11 August 2024"), url: "http://griffith.edu.au/open-day" }
        ]
    },
    "Macquarie University" : {
        id:"187",
        abbr: "Macq",
        states: ["NSW"],
        image:UniLogos["Mq"],
        "scholarships" : [{
            link : "https://students.mq.edu.au/admin/scholarships-and-financial-support",
            text : "Summary"
        },
        {
            link : "https://www.mq.edu.au/study/why-study-here/scholarships/domestic-scholarships-and-grants",
            text : "Undergraduate"
        } 
        ], 
        "bonusPoints" : [{
            link : "http://www.mq.edu.au/study/high-school-students/getting-into-university/bonus-points",
            text : "Summary"
        }, 
        {
            link : "https://www.mq.edu.au/study/high-school-students/getting-into-university/adjustment-factors/academic-advantage/adjustment-factors-calculator",
            text : "Calculator"
        }
        ], 
        "alternativeEntry" : [{
            link : "https://www.mq.edu.au/study/non-school-applicants/getting-into-university/entry-pathways",
        }], 
        "earlyEntry" : [{
            text: "Leaders and Achievers Early Entry",
            link: "https://www.mq.edu.au/study/high-school-students/getting-into-university/entry-schemes-and-pathways"
        }, {
            text: "SRS",
            link: "https://www.uac.edu.au/future-applicants/scholarships-and-schemes/schools-recommendation-schemes"
        }], 
        "atsi" : [{
            link : "http://www.mq.edu.au/study/high-school-students/getting-into-university/entry-schemes-and-pathways/indigenous-students",
        }], 
        "exchange" : [{
            link : "http://students.mq.edu.au/opportunities/student_exchange/",
        }], 
        "accommodation" : [{
            link : "http://www.mq.edu.au/about/campus-services-and-facilities/macquarie-university-accommodation",
        }], 
        "virtualTour" : [
            {link:"https://www.youtube.com/watch?v=0g0L1sTTfSU"}
        ], 
        events:[{ date: new Date("10 August 2024"), url: "https://openday.mq.edu.au/" }]
    },    
    "National Art School": {
        id: "",
        abbr: "NAS",
        states: ["NSW"],
        image: UniLogos["NAS"],
        "scholarships": [],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            {link:"https://www.youtube.com/watch?v=xC5aiG83MDg"}
        ],
        events:[{  url: "https://nas.edu.au/open-day/" }]
    },
    "University of Wollongong" : {
        id:"197",
        abbr: "UOW",
        states: ["NSW"],
        image:UniLogos["Wollongong"],
        "scholarships" : [{
            link : "http://www.uow.edu.au/about/scholarships/index.html",
            text : "Summary"
        }, {
            link : "https://scholarships.uow.edu.au/scholarships/search",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.uow.edu.au/study/high-school/apply/",
            text : "Summary"
        }, 
        {
            link : "https://solss.uow.edu.au/owa/sid/hsc_bonus_points.display_bp_by_hsc_course",
            text : "Calculator"
        }
        ], 
        "alternativeEntry" : [{
            link : "https://www.uow.edu.au/study/mature/pathways/",
        }], 
        "earlyEntry" : [{
            link:"https://www.uow.edu.au/study/high-school/uow-early-admission/"
        }], 
        "atsi" : [{
            link : "http://www.uow.edu.au/wic/index.html",
        }], 
        "exchange" : [{
            link : "http://www.uow.edu.au/student/exchange/index.html",
        }], 
        "accommodation" : [{
            link : "http://www.uow.edu.au/about/accommodation/index.html",
        }], 
        "virtualTour" : [{
            link : "https://www.uow.edu.au/about/locations/campus-map/virtual-tours/",
        }], 
        events:[
            {
                campus: "Wollongong",
                date: new Date("22 June 2024"),
                url: "http://openday.uow.edu.au/"
            }
        ] 
   },    
    "Southern Cross University" : {
        id:"188",
        abbr: "SCU",
        states: ["NSW", "QLD"],
        image:UniLogos["SouthernCross"],
        "scholarships" : [{
            link : "http://www.scu.edu.au/scholarships/",
            text : "Summary"
        }, {
            link : "https://www.scu.edu.au/scholarships/undergraduate-scholarships/",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.scu.edu.au/study-at-scu/help-and-contact/frequently-asked-questions-faqs/applying-qtac-and-uac/",
            text : "Summary"
        }], 
        "alternativeEntry" : [{
            link : "https://www.scu.edu.au/study-at-scu/high-school-students/entry-pathways/",
        }], 
        "earlyEntry" : [{
            text:"Star Early Offer",
            link:"https://www.scu.edu.au/star-early-offer/"
        }], 
        "atsi" : [{
            link : "https://www.scu.edu.au/current-students/services-and-support/indigenous-australian-student-services-iass/",
        }], 
        "exchange" : [{
            link : "https://www.scu.edu.au/current-students/global-experience/",
        }], 
        "accommodation" : [{
            link : "https://www.scu.edu.au/current-students/services-and-support/accommodation/",
        }], 
        "virtualTour" : [
            {
                text:"All Campuses",
                link:"https://www.scu.edu.au/study-at-scu/why-scu/locations/"
            },
            {
                link : "https://www.scu.edu.au/study-at-scu/why-scu/locations/gold-coast/",
                text : "Gold Coast" 
            },
            {
                link : "https://www.scu.edu.au/study-at-scu/why-scu/locations/lismore/",
                text : "Lismore" 
            },
            {
                link : "https://www.scu.edu.au/study-at-scu/why-scu/locations/coffs-harbour/",
                text : "Coffs Harbour" 
            },
            {
                link : "https://www.youtube.com/watch?v=Ev3G4eLK4kA",
                text : "NMSC" 
            },
        ], 
        events:[
            {
                date: new Date("4 August 2024"),
                campus: "Northern Rivers",
                url: "https://www.scu.edu.au/open-day"
            },
            {
                date: new Date("11 August 2024"),
                campus: "Coffs Harbour",
                url: "https://www.scu.edu.au/open-day"
            },
            {
                date: new Date("18 August 2024"),
                campus: "Gold Coast",
                url: "https://www.scu.edu.au/open-day"
            },

        ]
   },    
    "University of Canberra" : {
        id:"189",
        abbr: "UC",
        states: ["ACT"],
        image:UniLogos["Canberra"],
        "scholarships" : [{
            link : "http://www.canberra.edu.au/scholarships",
            text : "Summary"
        }, {
            link : "http://search.canberra.edu.au/s/search.html?collection=domestic-scholarships&profile=_default&form=scholarship-list&query=!padrenull&sort=dmetaprovider&num_ranks=5&f.Level+of+study%7Clevels=Undergraduate",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.canberra.edu.au/future-students/get-into-uc/admissions-programs/year-12-adjustment-factors",
            text : "Summary"
        }], 
        "alternativeEntry" : [{
            link : "http://www.canberra.edu.au/future-students/applications/apply-now/alternative-entry",
        }], 
        "earlyEntry" : [{
            text:"SRS",
            link:"https://www.canberra.edu.au/future-students/apply-now/alternative-entry/schools-recommendation-scheme"
        }], 
        "atsi" : [{
            link : "https://www.canberra.edu.au/on-campus/health-and-support/medical-counselling/services-for-aboriginal-people-and-torres-strait-islanders",
        }], 
        "exchange" : [{
            link : "http://www.canberra.edu.au/study-abroad/travel-os",
        }], 
        "accommodation" : [{
            link : "http://www.canberra.edu.au/residences",
        }], 
        "virtualTour" : [{
            link : "https://www.canberra.edu.au/on-campus/campus-tours/virtual-tours",
        }], 
        events:[ { date: new Date("14 September 2024"), url: "https://www.canberra.edu.au/openday" }]
   },    
    "University of New England" : {
        id:"190",
        abbr: "UNE",
        states: ["NSW"],
        image:UniLogos["UNE"],
        "scholarships" : [{
            link : "http://www.une.edu.au/study/scholarships",
            text : "Summary"
        }, {
            link : "http://www.une.edu.au/scholarships/school-leaver",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.une.edu.au/info-for/for-high-schools/adjustment-factors",
            text : "Summary"
        }], 
        "alternativeEntry" : [{
            link : "http://www.une.edu.au/study/study-online/entry-pathways",
        }], 
        "earlyEntry" : [{
            link:"https://www.une.edu.au/study/study-on-campus/get-early-entry"
        }], 
        "atsi" : [{
            link : "http://www.une.edu.au/info-for/indigenous-matters",
        }], 
        "exchange" : [{
            link : "http://www.une.edu.au/current-students/my-course/exchange",
        }], 
        "accommodation" : [{
            link : "http://www.une.edu.au/campus-life/une-accommodation",
        }], 
        "virtualTour" : [{
            link : "https://www.une.edu.au/campus-life",
        }], 
        events:[
            {
                date: new Date("3 May 2024"),
                url: "https://www.une.edu.au/openday"
            },
        ]
   },    
    "UNSW" : {
        id:"4832",
        abbr: "UNSW",
        states: ["NSW"],
        image:UniLogos["UNSW"],
        "scholarships" : [{
            link : "https://scholarships.online.unsw.edu.au/index.html",
            text : "Summary"
        }, {
            link : "http://www.scholarships.unsw.edu.au/scholarships/search?for=HIGH_SCHOOL_LEAVERS&show=all",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "http://www.unsw.edu.au/domestic-undergraduate/hsc-plus",
            text : "Summary"
        }, 
        {
            link : "http://iaro.online.unsw.edu.au/pls/non/hsc_bonus_points.main",
            text : "Calculator"
        }
        ], 
        "alternativeEntry" : [{
            link : "http://www.unsw.edu.au/future-students/domestic-undergraduate/getting-you-into-unsw/high-school-students",
            text : "Click Here"
        }], 
        "earlyEntry" : [{
            text:"Gateway Program",
            link:"https://www.futurestudents.unsw.edu.au/year-12"
        }], 
        "atsi" : [{
            link : "http://www.nuragili.unsw.edu.au/",
        }], 
        "exchange" : [{
            link : "http://www.international.unsw.edu.au/outbound-opportunities/student-exchange/",
        }], 
        "accommodation" : [{
            link : "http://rc.unsw.edu.au/",
        }], 
        "virtualTour" : [
            {
                link : "http://360tour.unsw.edu.au/",
            }
        ], 
         events:[
            {
                date: new Date("7 September 2024"),
                url: "https://www.unsw.edu.au/openday"
            }
        ]
    },    
    "UNSW (ADFA)": {
        id: "",
        abbr: "ADFA",
        states: ["NSW", "VIC"],
        image: UniLogos["ADFA"],
        "scholarships": [],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            {link:"https://www.youtube.com/watch?v=IWI74RY9IlA"}
        ],
        events:[
            {
                url: "https://www.defence.gov.au/ADFA/ADFAOpenDay.asp"
            }
        ]
    },
    "University of Newcastle" : {
        id:"193",
        abbr: "UON",
        states: ["NSW"],
        image:UniLogos["Newcastle"],
        "scholarships" : [{
            link : "http://www.newcastle.edu.au/future-students/undergraduate-study/costs-and-scholarships",
            text : "Summary"
        }, {
            link : "https://www.newcastle.edu.au/scholarships#filter=level_undergraduate_first",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "http://www.newcastle.edu.au/study/undergraduate/getting-in/entry-schemes-and-bonus-points",
            text : "Summary"
        }, 
        {
            link : "https://www.newcastle.edu.au/study/undergraduate/getting-in/entry-schemes/subject-performance-scheme",
            text : "Calculator"
        }
        ], 
        "alternativeEntry" : [{
            link : "http://www.newcastle.edu.au/study/undergraduate/getting-in/other-pathways-and-study-options",
        }], 
        "earlyEntry" : [{
            text:"SRS",
            link:"https://www.newcastle.edu.au/study/undergraduate/getting-in/entry-schemes"
        }], 
        "atsi" : [{
            link : "https://www.newcastle.edu.au/study/pathways/yapug",
        }], 
        "exchange" : [{
            link : "http://www.newcastle.edu.au/international/study-with-us/study-abroad-and-exchange/study-overseas",
        }], 
        "accommodation" : [{
            link : "http://www.newcastle.edu.au/current-students/campus-environment/campus-life/accommodation",
        }], 
        "virtualTour" : [ 
            {link:"https://www.youtube.com/watch?v=u_O7gVbqV6w"}
        ], 
        events:[
            {
                campus: "Central Coast",
                date: new Date("17 August 2024"),
                url: "https://www.newcastle.edu.au/study/open-day"
            },
            {
                campus: "Newcastle",
                date: new Date("31 August 2024"),
                url: "https://www.newcastle.edu.au/study/open-day"
            }
        ] 
   },    
    "University of Sydney" : {
        id:"194",
        abbr: "USYD",
        states: ["NSW"],
        image:UniLogos["USYD"],
        "scholarships" : [{
            link : "http://sydney.edu.au/scholarships/",
            text : "Summary"
        }, {
            link : "https://www.sydney.edu.au/scholarships/domestic/bachelors-honours.html",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "http://sydney.edu.au/future-students/domestic/undergraduate/access-sydney/flexible-entry.shtml",
            text : "Summary"
        } 
        ], 
        "alternativeEntry" : [{
            link : "http://sydney.edu.au/future-students/domestic/undergraduate/access-sydney/",
        }], 
        "earlyEntry" : [], 
        "atsi" : [{
            link : "http://sydney.edu.au/study/academic-support/aboriginal-and-torres-strait-islander-support.html",
        }], 
        "exchange" : [{
            link : "http://sydney.edu.au/current_students/student_exchange/index.shtml",
        }], 
        "accommodation" : [{
            link : "http://sydney.edu.au/current_students/accommodation/",
        }], 
        "virtualTour" : [{
            link : "http://tour.sydney.edu.au/",
        }], 
        events:[
            {
                date: new Date("31 August 2024"),
                url: "https://openday.sydney.edu.au"
            },
        ]
    },    
    "University of Technology Sydney" : {
        id:"195",
        abbr: "UTS",
        states: ["NSW"],
        image:UniLogos["UTS"],
        "scholarships" : [{
            link : "https://www.uts.edu.au/current-students/opportunities/scholarships-prizes-and-awards/scholarships-uts?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
            text : "Summary"
        }, {
            link : "https://www.uts.edu.au/study/scholarships?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.uts.edu.au/study/undergraduate/admission-requirements/admissions-schemes?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update ",
            text : "Summary"
        }, 
        {
            link : "https://www.uts.edu.au/study/undergraduate/high-school-students/year-12-bonus-scheme?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
            text : "Calculator"
        }
        ], 
        "alternativeEntry" : [{
            link : "https://www.uts.edu.au/study/undergraduate/non-current-school-leavers/entry-pathways?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
        }], 
        "earlyEntry" : [{
            text:"SRS",
            link:"https://www.uts.edu.au/study/undergraduate/essential-information/educational-access-schemes/schools?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update"
        }, {
            link:"https://www.uts.edu.au/future-students/undergraduate/admission-requirements/admissions-schemes/uts-early-entry-program?utm_source=jobjump&utm_medium=other&utm_campaign=august-update"
        }], 
        "atsi" : [{
            link : "https://www.uts.edu.au/study/indigenous-australians?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
        }], 
        "exchange" : [{
            link : "https://www.uts.edu.au/current-students/opportunities/global-exchange/what-global-exchange?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
        }], 
        "accommodation" : [{
            link : "https://www.uts.edu.au/current-students/support/uts-housing-service?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
        }], 
        "virtualTour" : [{
            link : "https://www.uts.edu.au/study/undergraduate/why-uts/experience-uts-vr?utm_source=jobjump&utm_medium=sponsorship&utm_campaign=august-update",
        }], 
        events:[
            {
                date: new Date("31 August 2024"),
                url: "https://www.uts.edu.au/study/open-day"
            }
        ] 
   },    
    "Notre Dame" : {
        id:"261",
        abbr: "ND",
        states: ["NSW", "WA"],
        image:UniLogos["NotreDame"],
        "scholarships" : [{
            link : "https://www.notredame.edu.au/current-students/opportunities/scholarships",
            text : "Summary"
        }], 
        "bonusPoints" : [], 
        "alternativeEntry" : [], 
        "earlyEntry" : [], 
        "atsi" : [], 
        "exchange" : [], 
        "accommodation" : [], 
        "virtualTour" : [{
            link:"https://www.youtube.com/watch?v=4qmAKBQY4NM"
        }], 
        events:[
            {
                date: new Date("31 August 2024"),
                url: "https://www.notredame.edu.au/engage/visit-us/openday"
            },
        ] 
   },    
    "Western Sydney University" : {
        id:"196",
        abbr: "WSU",
        states: ["NSW"],
        image:UniLogos["WSU"],
        "scholarships" : [{
            link : "https://www.westernsydney.edu.au/informationabout/scholarships_home",
            text : "Summary"
        }, {
            link : "https://www.westernsydney.edu.au/opportunities/scholarships/important-dates",
            text : "Undergraduate"
        }], 
        "bonusPoints" : [{
            link : "https://www.westernsydney.edu.au/future/study/how-to-apply/bonus-points/subject-bonus-points.html",
            text : "Summary & Calculator"
        }], 
        "alternativeEntry" : [{
            link : "https://www.westernsydney.edu.au/future/future_students_home/pathways-to-uws",
        }], 
        "earlyEntry" : [{
            text:"True Reward",
            link: "https://www.westernsydney.edu.au/future/study/application-pathways/hsc-true-reward.html" 
        }], 
        "atsi" : [{
            link : "https://www.westernsydney.edu.au/badanami/badanami_centre_for_indigenous_education/future_students",
        }], 
        "exchange" : [{
            link: "https://www.westernsydney.edu.au/globalmobility/goglobal",
            text: ""
        }], 
        "accommodation" : [{
            link : "http://future.uws.edu.au/future_students_home/studentlife/accommodation",
        }], 
        "virtualTour" : [{
            link : "https://www.westernsydney.edu.au/future/student-life/events/campus-tours.html",
        }], 
        events:[
            {
                date: new Date("30 June 2024"),
                url: "https://www.westernsydney.edu.au/openday"
            }
        ]
   },    
   /*"Melbourne Inst Tech" : {
        id:"243",
        states: ["NSW", "VIC"],
        image:UniLogos["MIT"],
        "scholarships" : [{
            link : "http://www.mit.edu.au/study-with-us/scholarships",
            text : "Summary"
        }], 
        "bonusPoints" : [], 
        "alternativeEntry" : [], 
        "earlyEntry" : [], 
        "atsi" : [], 
        "exchange" : [], 
        "accommodation" : [], 
        "virtualTour" : [], 
 
   },*/
   "Deakin" : {
        id:"4755",
        abbr: "Deakikn",
        states: ["VIC"],
        image:UniLogos["Deakin"],
        "scholarships" : [{
            link : "https://www.deakin.edu.au/courses/ike/scholarships",
        }], 
        "bonusPoints" : [{
            link : "https://www.deakin.edu.au/courses/entry-pathways/admission-and-selection-adjustment-factors",
        }], 
        "alternativeEntry" : [{
            link: "https://www.deakin.edu.au/courses/entry-pathways"
        }], 
        "earlyEntry" : [], 
        "atsi" : [{
            link:"https://www.deakin.edu.au/courses/fees-scholarships/scholarships/find-a-scholarship/indigenous-student-success-program"
        }], 
        "exchange" : [{
            link:"https://www.deakin.edu.au/life-at-deakin/why-study-at-deakin/overseas-study"
        }], 
        "accommodation" : [{
            link: "https://www.deakin.edu.au/life-at-deakin/accommodation"
        }], 
        "virtualTour" : [{
            text: "Melbourne",
            link: "https://www.deakin.edu.au/locations/melbourne-burwood-campus"
        },
        {
            text: "Waurn Ponds (Geelong)",
            link: "https://www.deakin.edu.au/locations/geelong-waurn-ponds-campus"
        },
        {
            text: "Waterfront (Geelong)",
            link:"https://www.deakin.edu.au/locations/geelong-waterfront-campus"
        },
        {
            text: "Warrnambool",
            link: 'https://www.deakin.edu.au/locations/warrnambool-campus'
        },
        {
            text: "Online",
            link: "https://www.deakin.edu.au/study-online"
        }], 
        events: [
            {url:"https://openday.deakin.edu.au/"}
        ]
   },
   "Monash" : {
        id:"4757",
        abbr: "Monash",
        states: ["VIC"],
        image:UniLogos["Monash"],
        "scholarships" : [{
            link : "https://www.monash.edu/study/fees-scholarships/scholarships",
        }], 
        "bonusPoints" : [{
            link:"https://www.monash.edu/admissions",
        }], 
        "alternativeEntry" : [{
            link: "https://www.monash.edu/access/admissions-schemes"
        }], 
        "earlyEntry" : [{
            link: "https://www.monash.edu/study/how-to-apply/entry-schemes"
        }], 
        "atsi" : [{
            link: "https://www.monash.edu/indigenous-australians/study/admissions-and-pathways/miap-inap"
        }], 
        "exchange" : [{
            link: "https://www.monash.edu/study-abroad/outbound/exchange"
        }], 
        "accommodation" : [{
            link:"https://www.monash.edu/study/student-life/accommodation?id=1992104"
        }], 
        "virtualTour" : [{
            link: "https://www.monash.edu/virtual-tours"
        }], 
        events: [
            {url:"https://www.monash.edu/open-day"}
        ] 
   },
   "RMIT" : {
        id:"4736",
        abbr: "RMIT",
        states: ["VIC"],
        image:UniLogos["RMIT"],
        "scholarships" : [{
            link : "https://www.rmit.edu.au/students/work-study-opportunities/scholarships",
        }], 
        "bonusPoints" : [{
            link: "https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/entry-requirements/admissions-transparency"
        }], 
        "alternativeEntry" : [{
            link:"https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/pathways"
        }], 
        "earlyEntry" : [{
            link:"https://www.rmit.edu.au/study-with-us/applying-to-rmit/local-student-applications/equity-access-schemes/types-of-equity-access"
        }], 
        "atsi" : [{
            link:"https://www.rmit.edu.au/students/support-and-facilities/student-support/aboriginal-and-torres-strait-islander-students"
        }], 
        "exchange" : [{
            link:"https://www.rmit.edu.au/students/work-study-opportunities/global-study-and-work"
        }], 
        "accommodation" : [{
            link:"https://www.rmit.edu.au/students/support-and-facilities/student-support/accommodation"
        }], 
        "virtualTour" : [{
            text: "Melbourne City",
            link: "https://www.rmit.edu.au/about/our-locations-and-facilities/locations/melbourne-city-campus"
        },
        {
            text:"Melbourne Bundoora",
            link:"https://www.rmit.edu.au/about/our-locations-and-facilities/locations/melbourne-bundoora-campus"
        },
        {
            text:"Melbourne Brunswick",
            link:"https://www.rmit.edu.au/about/our-locations-and-facilities/locations/melbourne-brunswick-campus"
        },
        {
            text: "Regional",
            link:"https://www.rmit.edu.au/about/our-locations-and-facilities/locations/regional-sites"
        }], 
        events: [
            {url:"https://www.rmit.edu.au/openday"}
        ]
   },

   "Swinburn" : {
        id:"4759",
        abbr: "Swinburn",
        states: ["VIC"],
        image:UniLogos["Swinburne"],
        "scholarships" : [{
            link : "https://www.swinburne.edu.au/study/options/scholarships/",
        }], 
        "bonusPoints" : [{
            link: "https://www.swinburne.edu.au/courses/planning-your-future/admission-requirements/"
        }], 
        "alternativeEntry" : [{
            link:"https://www.swinburne.edu.au/study/degrees/pathways/"
        }], 
        "earlyEntry" : [], 
        "atsi" : [{
            link:"https://www.swinburne.edu.au/about/our-university/indigenous-matters/indigenous-student-support/"
        }], 
        "exchange" : [{
            link:"https://www.swinburne.edu.au/life-at-swinburne/study-abroad-exchange/"
        }], 
        "accommodation" : [{
            link:"https://www.swinburne.edu.au/current-students/student-services-support/accommodation/"
        }], 
        "virtualTour" : [{
            link:"https://www.swinburne.edu.au/virtual-tour/360/photos/"
        }], 
        events: [
            {url:"https://www.swinburne.edu.au/openday/"}
        ] 
   },

   "University of Melbourne" : {
        id:"4876",
        abbr: "UM",
        states: ["VIC"],
        image:UniLogos["Melbourne"],
        "scholarships" : [{
            link : "https://scholarships.unimelb.edu.au/",
        }], 
        "bonusPoints" : [{
            link: "https://study.unimelb.edu.au/study-with-us/programs-for-high-achievers/university-of-melbourne-extension-program/what-UMEP-offers/atar-contribution"
        }], 
        "alternativeEntry" : [{
            link: "https://study.unimelb.edu.au/how-to-apply/special-entry-access-schemes"
        }], 
        "earlyEntry" : [{
            link: "https://study.unimelb.edu.au/how-to-apply/special-entry-access-schemes"
        }], 
        "atsi" : [{
            link: "https://study.unimelb.edu.au/study-with-us/programs-for-indigenous-students"
        }], 
        "exchange" : [{
            link: "https://www.swinburne.edu.au/current-students/study-abroad-exchange/"
        }], 
        "accommodation" : [{
            link: "https://study.unimelb.edu.au/your-experience/accommodation"
        }], 
        "virtualTour" : [{
            link: "https://www.youtube.com/watch?v=l_cgsdW2vVw"
        }], 
        events: [
            {url:"https://study.unimelb.edu.au/opendays?in_c=study_openday"}
        ] 
   },

   "Victoria University" : {
        id:"4747",
        abbr: "VU",
        states: ["VIC"],
        image:UniLogos["Victoria"],
        "scholarships" : [{
            link : "https://www.vu.edu.au/study-at-vu/fees-scholarships/scholarships",
        }], 
        "bonusPoints" : [{
            link : "https://www.vu.edu.au/study-at-vu/how-to-apply/admissions-at-vu/atar-profile",
        }], 
        "alternativeEntry" : [{
            link : "https://www.vu.edu.au/study-at-vu/how-to-apply/special-admission-programs/vu-guaranteed",
        }], 
        "earlyEntry" : [{
            link: "https://www.vu.edu.au/about-vu/news-events/news/early-entry-program-means-less-stress-more-success-for-regional-students"
        }], 
        "atsi" : [{
            link : "https://www.vu.edu.au/about-vu/university-profile/moondani-balluk/our-aboriginal-torres-strait-islander-profile/aboriginal-torres-strait-islander-students",
        }], 
        "exchange" : [{
            link : "https://www.vu.edu.au/current-students/careers-opportunities/study-overseas/exchange-programs",
        }], 
        "accommodation" : [{
            link : "https://www.vu.edu.au/current-students/campus-life/housing-accommodation",
        }], 
        "virtualTour" : [{
            link: "https://www.youtube.com/playlist?list=PL3y_wByS2sRPafspC45HiKnnLqzejFYqs"
        }], 
        events: [
            {url:"https://openday.vu.edu.au/"}
        ]
 
   },

   "Federation University" : {
        id:"4745",
        abbr: "Fed",
        states: ["VIC"],
        image:UniLogos["Federation"],
        "scholarships" : [{
            link : "https://federation.edu.au/current-students/assistance-support-and-services/scholarships",
        }], 
        "bonusPoints" : [], 
        "alternativeEntry" : [{
            link : "https://federation.edu.au/future-students/study-at-federation/which-course/pathways",
        }], 
        "earlyEntry" : [], 
        "atsi" : [{
            link : "https://federation.edu.au/about-us/our-university/indigenous-matters/aboriginal-education-centre/student-information",
        }], 
        "exchange" : [{
            link : "https://federation.edu.au/international/education-partnerships/study-abroad-and-exchange-programs/outgoing-student",
        }], 
        "accommodation" : [{
            link : "https://federation.edu.au/about-us/facilities-and-services/campus-life/accommodation",
        }], 
        "virtualTour" : [{
            link: "https://federation.edu.au/future-students/study-at-federation/virtual-tours?utm_source=redirect&utm_medium=web&utm_campaign=virtualtour"
        }], 
        events: [
            {url:"https://federation.edu.au/future-students/study-at-federation/open-day?utm_source=redirect&utm_medium=web&utm_campaign=openday"}
        ] 
   },
   "La Trobe University" : {
        id:"4765",
        abbr: "La Trobe",
        states: ["VIC", "NSW"],
        image:UniLogos["LaTrobe"],
        "scholarships" : [{
            link : "https://www.latrobe.edu.au/scholarships",
        }], 
        "bonusPoints" : [{
            link : "https://www.latrobe.edu.au/study/apply/pathways",
        }], 
        "alternativeEntry" : [{
            link : "https://www.latrobe.edu.au/study/apply/undergrad/vtac/vtac-entry-access-scheme",
        }], 
        "earlyEntry" : [{
            link: "https://www.latrobe.edu.au/study/aspire"
        }], 
        "atsi" : [{
            link : "https://www.latrobe.edu.au/study/undergrad/resources/indigenous-students",
        }], 
        "exchange" : [{
            link : "https://www.latrobe.edu.au/courses/mba/study-tours-and-exchange",
        }], 
        "accommodation" : [{
            link : "https://www.latrobe.edu.au/accommodation",
        }], 
        "virtualTour" : [{
            link: "https://www.latrobe.edu.au/shepparton/about",
            text: "Shepparton"
        },
        {
            link:"https://www.latrobe.edu.au/melbourne/virtual-tour",
            text:"Melbourne"
        },
        {
            link: "https://www.latrobe.edu.au/bendigo/about",
            text: "Bendigo"
        },
        {
            link: "https://www.latrobe.edu.au/albury-wodonga/about/tour",
            text: "Albury Wodonga"
        },
        {
            link:"https://www.latrobe.edu.au/mildura/about/location",
            text:"Mildura"
        }
    ], 
    events: [ ]
   },
   "University of Western Australia": {
        "id": "5045",
        "abbr": "UWA",
        "states": [ "WA" ],
        "image": UniLogos["UWA"],
        "scholarships": [
            { "link": "https://www.uwa.edu.au/study/Scholarships/Explore", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.uwa.edu.au/study/How-to-apply/Admission-entry-pathways/Experience-based-entry" }
        ],
        "earlyEntry": [
            { "link": "https://www.uwa.edu.au/study/How-to-apply/Early-Offers" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://vimeo.com/52038874" }
        ],
        "events": []
    },
    "University of South Australia": {
        "id": "5032",
        "abbr": "USA",
        "states": [ "SA" ],
        "image": UniLogos["USA"],
        "scholarships": [
            { "link": "https://i.unisa.edu.au/students/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.unisa.edu.au/Study-at-UniSA/Study-with-us/adjustment-factors", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.unisa.edu.au/Study-at-UniSA/Study-with-us/Entry-pathways/#year12" }
        ],
        "earlyEntry": [
            { "link": "https://study.unisa.edu.au/accelerate/" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "Charles Darwin University": {
        "id": "4860",
        "abbr": "CD",
        "states": [ "NT", "SA", "NSW" ],
        "image": UniLogos["CharlesDarwin"],
        "scholarships": [
            { "link": "https://www.cdu.edu.au/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.cdu.edu.au/study/essentials/study-pathways/pathways-study" }
        ],
        "earlyEntry": [
            { "link": "https://www.cdu.edu.au/study/essentials/study-pathways/pathways-study" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [ ],
        "events": []
    },
    "University of Tasmania": {
        "id": "4859",
        "abbr": "UTAS",
        "states": [ "TAS", "NSW" ],
        "image": UniLogos["UTAS"],
        "scholarships": [
            { "link": "https://www.utas.edu.au/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.utas.edu.au/college/courses/upp", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.utas.edu.au/study/pathways-to-university" }
        ],
        "earlyEntry": [
            { "link": "https://www.utas.edu.au/study/schools-recommendation-program" }
        ],
        "atsi": [{"link": 'https://www.utas.edu.au/uni-life/aboriginal-students-riawunna'}],
        "exchange": [{"link": "https://www.utas.edu.au/study/learning-abroad"}],
        "accommodation": [{"link": "https://www.utas.edu.au/uni-life/accommodation"}],
        "virtualTour": [{"link": "https://www.utas.edu.au/about/campuses/tours"}],
        "events": []
    },
    "Australian Maritime College": {
        "id": "176",
        "abbr": "AMC",
        "states": [ "TAS" ],
        "image": UniLogos["AMC"],
        "scholarships": [
            { "link": "https://www.amc.edu.au/study/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [{"link":"https://www.amc.edu.au/study/current-students/student-support"}],
        "exchange": [],
        "accommodation": [{"link":"https://www.amc.edu.au/study/current-students/student-support"}],
        "virtualTour": [{"link":"https://tours.utas.edu.au/#amc"}],
        "events": []
    },
    "Murdoch University": {
        "id": "5046",
        "abbr": "MU",
        "states": [ "WA" ],
        "image": UniLogos["Murdoch"],
        "scholarships": [
            { "link": "https://www.murdoch.edu.au/study/fees-scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.murdoch.edu.au/study/pathways-to-uni" }
        ],
        "earlyEntry": [
            { "link": "https://www.murdoch.edu.au/study/how-to-apply/year-12-early-offer-program" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://vimeo.com/409614519" }
        ],
        "events": []
    },
    "University of Adelaide": {
        "id": "5031",
        "abbr": "UA",
        "states": [ "SA" ],
        "image": UniLogos["Adelaide"],
        "scholarships": [
            { "link": "https://www.adelaide.edu.au/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.adelaide.edu.au/study/undergraduate/how-to-apply/adjustment-factors", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.adelaide.edu.au/study/undergraduate/entry-pathways" }
        ],
        "earlyEntry": [
            { "link": "https://www.adelaide.edu.au/headstart/" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [ ],
        "events": []
    },
    "University of Queensland": {
        "id": "4725",
        "abbr": "UQ",
        "states": [ "QLD" ],
        "image": UniLogos["UQLD"],
        "scholarships": [
            { "link": "https://scholarships.uq.edu.au", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://future-students.uq.edu.au/admissions/undergraduate/review-admission-schemes/subject-incentive-scheme", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://future-students.uq.edu.au/admissions/undergraduate/consider-your-pathway-options" }
        ],
        "earlyEntry": [
            { "link": "https://future-students.uq.edu.au/admissions/undergraduate/review-admission-schemes" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "Queensland University of Technology": {
        "id": "4726",
        "abbr": "QUT",
        "states": [ "QLD" ],
        "image": UniLogos["QUT"],
        "scholarships": [
            { "link": "https://www.qut.edu.au/study/fees-and-scholarships/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.qut.edu.au/study/applying/adjustment-schemes", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.qut.edu.au/study/applying/adjustment-schemes" }
        ],
        "earlyEntry": [
            { "link": "https://www.qut.edu.au/study/applying/adjustment-schemes" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://www.youtube.com/watch?v=ES-PV20x3Bs" }
        ],
        "events": []
    },
    "Flinders University": {
        "id": "5033",
        "abbr": "Flinders",
        "states": [ "SA" ],
        "image": UniLogos["Flinders"],
        "scholarships": [
            { "link": "https://students.flinders.edu.au/finances-and-admin/support/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.flinders.edu.au/study/pathways/year-12-entry", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.flinders.edu.au/study/pathways" }
        ],
        "earlyEntry": [
            { "link": "https://www.flinders.edu.au/study/pathways/year-12-entry/year-11-grades-admission-pathway" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://vimeo.com/52038874" }
        ],
        "events": []
    },
    "Edith Cowan University": {
        "id": "5042",
        "abbr": "EC",
        "states": [ "WA" ],
        "image": UniLogos["Edith"],
        "scholarships": [
            { "link": "https://www.ecu.edu.au/scholarships/overview", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.ecu.edu.au/future-students/course-entry/experience-based-entry-scheme" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [ ],
        "events": []
    },
    "Curtin University": {
        "id": "5043",
        "abbr": "Curtin",
        "states": [ "WA" ],
        "image": UniLogos["Curtin"],
        "scholarships": [
            { "link": "https://scholarships.curtin.edu.au", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://study.curtin.edu.au/applying/pathways" }
        ],
        "earlyEntry": [
            { "link": "https://study.curtin.edu.au/applying/pathways" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://www.youtube.com/watch?v=3RAqh8SHaQc" }
        ],
        "events": []
    },
    "University of Southern Queensland": {
        "id": "4723",
        "abbr": "USQ",
        "states": [ "QLD" ],
        "image": UniLogos["USQ"],
        "scholarships": [
            { "link": "https://www.usq.edu.au/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.usq.edu.au/study/degrees/pathway-programs" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://youtu.be/_jlUfjts6WM" }
        ],
        "events": []
    },
    "Torrens University Australia": {
        "id": "4814",
        "abbr": "Torrens",
        "states": [ "SA", "QLD", "NSW" ],
        "image": UniLogos["Torrens"],
        "scholarships": [
            { "link": "https://www.torrens.edu.au/apply-online/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [
            { "link": "https://www.torrens.edu.au/apply-online/early-entry-program" }
        ],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [
            { "link": "https://youtu.be/8cipPZDdQDQ" }
        ],
        "events": []
    },
    "James Cook University": {
        "id": "4714",
        "abbr": "JC",
        "states": [ "QLD" ],
        "image": UniLogos["JamesCook"],
        "scholarships": [
            { "link": "https://www.jcu.edu.au/scholarships-@-jcu", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://www.jcu.edu.au/pathways-to-university" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "Bond University": {
        "id": "4718",
        "abbr": "Bond",
        "states": [ "QLD" ],
        "image": UniLogos["Bond"],
        "scholarships": [
            { "link": "https://bond.edu.au/future-students/study-bond/how-apply/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://bond.edu.au/future-students/study-bond/how-apply/application-process/english-pathways-and-diploma-application" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "University of the Sunshine Coast": {
        "id": "4727",
        "abbr": "USC",
        "states": [
            "QLD"
        ],
        "image": UniLogos["SunshinCoast"],
        "scholarships": [
            { "link": "https://www.usc.edu.au/study/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [
            { "link": "https://www.usc.edu.au/study/how-do-i-apply/pathways-to-study/access-usc", "text": "Summary" }
        ],
        "alternativeEntry": [
            { "link": "https://www.usc.edu.au/study/how-do-i-apply/pathways-to-study/non-year-12-entry" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "Engineering Institute of Technology": {
        "id": "5048",
        "abbr": "EIT",
        "states": [ "WA" ],
        "image": UniLogos["EIT"],
        "scholarships": [
            { "link": "https://oncampus.eit.edu.au/scholarships.html", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    },
    "Christian Heritage College": {
        "id": "4722",
        "abbr": "CHC",
        "states": [ "QLD" ],
        "image": UniLogos["CHC"],
        "scholarships": [
            { "link": "https://chc.edu.au/scholarships", "text": "Summary" }
        ],
        "bonusPoints": [],
        "alternativeEntry": [
            { "link": "https://chc.edu.au/credit-transfer" }
        ],
        "earlyEntry": [],
        "atsi": [],
        "exchange": [],
        "accommodation": [],
        "virtualTour": [],
        "events": []
    }
}

export {uniConfig, uniCategoryPrettyNames, uniCategoryImages};