import React from "react";
import { StudentLeavingYear } from "jobjump-types/CaStudents";
import CaStudentDetails from "../../data/CaStudentDetails";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import PageSectionRow from "../../components/PageSectionRow";
import { Col } from "../../components/grid";

const CaLeavingYearTable : React.FC<RouteComponentProps> =
    (props) => {
        const [data, setData] = React.useState<StudentLeavingYear[]>([]);
        React.useEffect(() => {
            CaStudentDetails.studentLeavingYear().then(setData);
        }, []);
        return <CaAdminLayout active="students" title="Student Leaving Year" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Student Intended Leaving Year</h3>
                    <span>Below lists the intended leaving year each student has selected in the reusme and plan builder.</span>
                </Col>
            </PageSectionRow>
            <PageSectionRow>
                <Col>
                    <CaStudentTable
                        data={data}
                        additionalColumns={[
                            { 
                                Header:"Leaving Year", 
                                accessor:"year",
                            },
                        ]} />
                </Col>
            </PageSectionRow>


        </CaAdminLayout>
    }

export default CaLeavingYearTable;