import { View, Text, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import { contrastingColor, HeaderProps } from "./CommonSections";
import { IconWithText, Pin, Envelope, Phone, otherSectionToIcon } from "./Icons";

const ColouredBlockHeader : React.FC<HeaderProps> = ({resume:{styleConfig, personal_details, career_goal}}) => {
    const textColour = contrastingColor(styleConfig.primaryColor);
    const style = StyleSheet.create({
        name: {
            fontSize: "28pt",
            marginBottom: "16px",
            textAlign: "center",
            color: textColour
        },
        sectionStyle : {
            margin: "-16px -16px 0px -16px",
            paddingBottom: "16px",
        },
        sectionNameBlock: {
            color: textColour,
            backgroundColor: styleConfig.primaryColor,
            padding: "16px 16px 16px 16px"
        },
        sectionContactBlock: {
            backgroundColor: "#404040",
            display:"flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignContent: "center",
            justifyContent: "center",
            paddingTop: "16px",
            paddingBottom: "16px",
            paddingLeft: "8px",
            paddingRight: "8px",
        },
        item: {
            paddingLeft: "8px",
            paddingRight: "8px",
            color: "white"
        }
    });
    return <View style={style.sectionStyle}>
        <View style={style.sectionNameBlock}>
            <Text style={style.name}>{personal_details.name}</Text>
            {career_goal && career_goal !== "" ?
                <Text>{career_goal}</Text> : undefined}
            {personal_details.languages && personal_details.languages !== "" ? 
                <Text style={{paddingTop: "16px"}}>Languages Spoken: {personal_details.languages}</Text> :undefined}
        </View>
        <View style={style.sectionContactBlock}>
            {personal_details.address && personal_details.address !== "" ? 
                <View style={style.item}>
                    <IconWithText height="12pt" Icon={Pin} color={"#ffffff"}>
                        <Text>{personal_details.address}</Text>
                    </IconWithText>
                </View> : undefined}
            {personal_details.email && personal_details.email !== "" ? 
                <View style={style.item}>
                    <IconWithText height="12pt" Icon={Envelope} color={"#ffffff"}>
                        <Text>{personal_details.email}</Text>
                    </IconWithText>
                </View> : undefined}
            {personal_details.phone && personal_details.phone !== "" ? 
                <View style={style.item}>
                    <IconWithText height="12pt" Icon={Phone} color={"#ffffff"}>
                        <Text>{personal_details.phone}</Text>
                    </IconWithText>
                </View> : undefined}
            {personal_details.other?.map(o =>
                o.value !== "" ?
                    <View style={style.item}>
                        <IconWithText height="12pt" Icon={otherSectionToIcon(o.key)} color={"#ffffff"}>
                            <Text>{o.value}</Text>
                        </IconWithText> 
                    </View> : undefined
            )}
        </View>
    </View>
}

export default ColouredBlockHeader;