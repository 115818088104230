import { fetchWithAuth } from "./Utils";
import {WorkExperienceDirectoryEntry, WorkExperienceProject, WorkExperienceProjectResponse} from "jobjump-types/WorkExperience";

const WorkExperienceAPI = {
    getProject: (career:number) : Promise<WorkExperienceProject> => 
        fetchWithAuth("/api/workExperience/project/"+String(career)).then(d => d.json()),
    getProjectStatus: (career:number) : Promise<WorkExperienceProjectResponse> =>
        fetchWithAuth("/api/workExperience/project/currentState/"+String(career)).then(d => d.json()),
    updateStepProgress: (career:number, stepId:number) : Promise<{}> =>
        fetchWithAuth("/api/workExperience/project/currentState/"+String(career)+"/"+String(stepId), {method:"POST"}),
    getDirectoryEntries: (career:number) : Promise<WorkExperienceDirectoryEntry[]> =>
        fetchWithAuth("/api/workExperience/directory/"+String(career)).then(d => d.json()),
}

export default WorkExperienceAPI;
