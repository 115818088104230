import { IconEdit, IconSearch, IconTrash } from "@tabler/icons";
import { CaNewsItem } from "jobjump-types/CaNews";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import CardGrid from "../../components/CardGrid";
import CardTable from "../../components/CardTable";
import { Check, Label, TextField } from "../../components/Form";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import PageSectionRow from "../../components/PageSectionRow";
import CaSchoolNews from "../../data/CaSchoolNews";
import CaAdminLayout from "./CaAdminLayout";
import ReactQuil from "react-quill";
import 'react-quill/dist/quill.snow.css';
import { Converter } from "showdown";
import ReactMarkdown from 'react-markdown';
import { SchoolHomepage } from "jobjump-types/SchoolHomePage";
import CaSchoolHomepage from "../../data/CaSchoolHomepage";
import Alert from "../../components/Alert";
import Turndown from "turndown";

const emptyCaNewsItem : CaNewsItem = {
    id:0,
    body:"",
    title:"",
    date:"",
    careers:[],
    years:[]
}

const NoticeBoard : React.FC<RouteComponentProps> = (props) => {
    const [allMessages, setAllMessages] = React.useState<CaNewsItem[]>([]);
    const [filteredMessages, setFilteredMessages] = React.useState<CaNewsItem[]>([]); 
    const [showOldNotices, setShowNotices] = React.useState(false);
    const [currentNewsItem, setCurrentNewsItem] = React.useState<CaNewsItem>();
    const [showViewModal, setShowViewModal] = React.useState(false);
    const [showEditModal, setShowEditModal] = React.useState(false);
    const [isNew, setIsNew] = React.useState(false);
    const [deleteCandidate, setDeleteCandidtate] = React.useState<CaNewsItem>();
    const [validatedForm,setValidatedForm] = React.useState({title:true, body:true, date:true});
    const [siteConfig, setSiteConfig] = React.useState<SchoolHomepage>();
    const [dontSend, setDontSend] = React.useState(false);

    React.useEffect(() => {
        CaSchoolHomepage.schoolSiteConfig().then(c => setSiteConfig(c?.homepage));
    }, [])

    React.useEffect(()=>{
        CaSchoolNews.getNews().then(setAllMessages);
    }, []);

    React.useEffect(() => {
        if (showOldNotices) {
            setFilteredMessages(allMessages);
        } else {
            setFilteredMessages(allMessages.filter(m => new Date(m.date) >= new Date(Date.now())));
        }
    }, [allMessages, showOldNotices])

    const enableNoticeboard = () => {
        if (!siteConfig) {
            return;
        }
        CaSchoolHomepage.updateSchoolSite({...siteConfig, hasNoticeBoard:true}).then(() => setSiteConfig({...siteConfig, hasNoticeBoard:true}));
    };

    const editSubmit = React.useCallback(() => {
        if (!currentNewsItem) {
            return;
        }
        const converter = new Turndown();
        const {id, ...news} = currentNewsItem;
        const valid = news.title!==""&&news.body!==""&&news.date!=="";
        news.body = converter.turndown(news.body);
        setValidatedForm({title:news.title!=="", body:news.body!=="", date:news.date!==""});
        if (!valid) {
            return;
        }
        if (isNew) {
            CaSchoolNews.addNewsItem({...news, dontSend}).then(()=>
                CaSchoolNews.getNews().then(setAllMessages)
            );
        } else {
            CaSchoolNews.updateNewsItem(news, id).then(()=>
                CaSchoolNews.getNews().then(setAllMessages)
            );
        }
        setShowEditModal(false);
    }, [setAllMessages, isNew, currentNewsItem, setShowEditModal]);

    const deleteSubmit = React.useCallback((id:number)=> {
        CaSchoolNews.deleteNewsItem(id).then(()=> {
            CaSchoolNews.getNews().then(setAllMessages);
            setDeleteCandidtate(undefined);
        });
    }, [setAllMessages, setDeleteCandidtate]);

    const setItemForEdit = (item:CaNewsItem) => {
        const converter = new Converter();
        const newItem = {...item, body:converter.makeHtml(item.body)};
        setCurrentNewsItem(newItem);
        setIsNew(false);
        setShowEditModal(true);
    }; 

    return <CaAdminLayout 
        active="admin"
        title="Careers Noticeboard" 
        subtitle={["Add and update the Careers Noticeboard on your school's career page."]} 
        {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Your Careers Notices</h3>
                    <h5>Below are you career notices that are visible on your school's careers site. To add a new notice, use the <strong>"Add New"</strong> button.</h5>
                    {allMessages.length - filteredMessages.length > 0 && <small>You have {allMessages.length - filteredMessages.length} archived messages. Use the <strong>"Show Archived Messages"</strong> button to see them.</small>}
                </Col>
            </PageSectionRow>
            {siteConfig && !siteConfig.hasNoticeBoard && <PageSectionRow>
                <Col className="text-center">
                    <Alert textOnly variant="negative" text="You have not enabled the school noticeboard on your School's Site. Messages posted here will be emailed to students, but will not appear on your site. Use the button below to active your noticeboard." />
                    <Button variant="active" onClick={enableNoticeboard}>Activate My Noticeboard</Button>
                </Col>
            </PageSectionRow>}
            <PageSectionRow>
                <Col>
                    <CardTable
                        data={filteredMessages}
                        columns={[
                            {Header:"Title", accessor:"title", width:3},
                            {Header:"Date", accessor:(r)=>(new Date(r.date)).toDateString(), width:3},
                            {Header:"Years", width:3, accessor:(r)=>r.years.length === 0 ? "All" : r.years.map(y=>String(y)).join(", ")},
                            {Header:"View", id:"view", width:1,Cell:({row})=><IconSearch onClick={()=>{setCurrentNewsItem(row.original); setShowViewModal(true);}} />},
                            {Header:"Edit", id:"edit", width:1,Cell:({row})=><IconEdit onClick={()=>{setItemForEdit(row.original)}}/>},
                            {Header:"Delete", id:"delete", width:1,Cell:({row})=><IconTrash onClick={()=>setDeleteCandidtate(row.original)} />},
                        ]} 
                        renderFilters={(globalFilter, setGlobalFilter)=><Row>
                            <Col><TextField value={globalFilter} onChange={setGlobalFilter} placeholder="Search..." /></Col>
                        </Row>}
                        renderToolbar={()=><Row>
                            <Col className="text-right">
                                <Button variant="active" className="mr-8" onClick={()=>{setCurrentNewsItem(emptyCaNewsItem); setIsNew(true); setShowEditModal(true)}}>Add New</Button>
                                <Button variant="neutral" onClick={()=>setShowNotices(b=>!b)}>{showOldNotices ? "Hide" : "Show"} Archived Notices</Button>
                            </Col>
                        </Row>}
                    />
                </Col>
            </PageSectionRow>
            <Modal title={currentNewsItem && currentNewsItem.title} show={showViewModal} onHide={()=>setShowViewModal(false)}>
               {currentNewsItem && <><Row>
                    <Col>{currentNewsItem.date}</Col>
                </Row>
                <Row>
                    <Col>
                        <ReactMarkdown>{currentNewsItem.body}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        Years: {currentNewsItem.years.length === 0 ? "All" : currentNewsItem.years.map(String).join(", ")}
                    </Col>
                </Row>
                </>}
            </Modal>
            <Modal title={isNew ? "Add New Notice To School's Careers Homepage" : "Edit"} show={showEditModal} onHide={()=>setShowEditModal(false)}>
                <Row><Col>
                    Enter your news to appear on your school's careers homepage into the boxes below, add an expiry date and select the relevant year groups using the buttons. Then press the <strong>"Submit"</strong> button.
                </Col></Row>
                {currentNewsItem && <>
                    <Row>
                        <Col>
                            <Label>Message Heading</Label>
                            <TextField value={currentNewsItem.title} onChange={(title)=>setCurrentNewsItem(n=>n?({...n, title}):n)} placeholder="Notice Title" />
                            {!validatedForm.title && <small>Please enter a title</small>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label>Message Content</Label>
                            <ReactQuil value={currentNewsItem.body} onChange={(body)=>setCurrentNewsItem(n=>n?({...n,body}):n)} />
                            {!validatedForm.body && <small>Please enter some content</small>}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <Label>Expiry Date</Label>
                            <TextField dateField value={currentNewsItem.date} onChange={(date)=>setCurrentNewsItem(n=>n?({...n,date}):n)} />
                            {!validatedForm.date && <small>Please select a date</small>}
                        </Col>
                        <Col md={6}>
                            <Label>Applies To Years</Label>
                            <CardGrid numPerRow={3} prefix="year-picker">
                                <Button fullWidth glowing={currentNewsItem.years.length===0} variant={currentNewsItem.years.length === 0 ? "active" : "neutral"} onClick={()=>setCurrentNewsItem(n=>n?{...n,years:[]}:n)}>All</Button>
                                {[9,10,11,12].map(y=>
                                    <Button glowing={currentNewsItem.years.includes(y)} fullWidth key={y} variant={currentNewsItem.years.includes(y) ? "active" : "neutral"} onClick={()=>{
                                        if (currentNewsItem.years.includes(y)) {
                                            setCurrentNewsItem(n=>n?{...n, years:n.years.filter(x=>x!==y)}:n);
                                        } else {
                                            setCurrentNewsItem(n=>n?{...n,years:n.years.concat([y])}:n)
                                        } }}>
                                            {y}
                                    </Button>
                                )}
                            </CardGrid>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Check block label="Do not email this message to students" checked={dontSend} onChange={()=>setDontSend(d=>!d)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Button variant="negative" onClick={()=>{setCurrentNewsItem(undefined); setShowEditModal(false); setIsNew(false);}}>
                                Cancel
                            </Button>
                            <Button variant="active" className="ml-8" onClick={editSubmit}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </>}
            </Modal>
            <Modal title={"Confirm Deletion"} show={deleteCandidate !== undefined} onHide={()=>setDeleteCandidtate(undefined)}>
                <Row>
                    <Col>{deleteCandidate && <h4>Are you sure you want to delete {deleteCandidate.title}</h4>}</Col>
                </Row>
                <Row>
                    <Col>
                        <Button variant="neutral" className="mr-8" onClick={()=>setDeleteCandidtate(undefined)}>Cancel</Button>
                        {deleteCandidate && <Button variant="active" onClick={()=>deleteSubmit(deleteCandidate.id)}>Delete</Button>}
                    </Col>
                </Row>
            </Modal>
    </CaAdminLayout>;
}

export default NoticeBoard;