import { StudentResumeListing } from "jobjump-types/CaStudents";
import { Resume } from "jobjump-types/Resume";
import { ResumeResponse } from "./Resume";
import { fetchWithAuth } from "./Utils";

export default {
    getAllResumes : () : Promise<StudentResumeListing[]> => 
        fetchWithAuth(`/api/advisers/resume/`).then(r=>r.json()),
    getStudentResumes : (user_id:string) : Promise<StudentResumeListing[]> => 
        fetchWithAuth(`/api/advisers/resume/student/${user_id}`).then(r=>r.json()),
    getResume : (resume_id:number) : Promise<ResumeResponse> =>
        fetchWithAuth(`/api/advisers/resume/${resume_id}`).then(r=>r.json()), 
    saveResume : (id:number, resume:Resume) =>
        fetchWithAuth(`/api/advisers/resume/${id}`, {
            method:"POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(resume)
        }).catch(console.log),
};
