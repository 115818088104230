import React from "react";
import { Col } from "../components/grid";
import { openLinkInNewTab } from "../util";
import ImageCard from "../components/cards/ImageCard";
import PageSectionRow from "../components/PageSectionRow";
import TAFEATSI from "../assets/homepage/tiles/university-atsi-2.jpg";
import CIT from "../assets/uni-logos/CIT.jpg";
import ContentWrapper from "../components/ContentWrapper";
import CardGrid from "../components/CardGrid";
import UniLogos from "../assets/UniLogos";
import StateBasedPage from "../components/StateBasedPage";
import { UserDetailsProps } from "../layout/Routes";

const TAFENSW : React.FC = () =>
    <PageSectionRow>
        <Col md={6}>
            <ImageCard objectFitKind="contain" imageUrl={TAFEATSI} text="TAFE NSW" onClick={()=>openLinkInNewTab("https://www.tafensw.edu.au/courses/aboriginal-education")} />
        </Col>
        <Col md={6}>
            <ImageCard objectFitKind="contain" imageUrl={CIT} text="Canberra Institute of Techology" onClick={()=>openLinkInNewTab("https://cit.edu.au/study/cit_yurauna")} />
        </Col>
    </PageSectionRow>

const TAFEVic : React.FC = () =>
    <PageSectionRow>
        <Col><CardGrid numPerRow={3} prefix="tafe-vic">
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["BendigoTAFE"]} onClick={()=>openLinkInNewTab("http://www.bendigotafe.edu.au/Students/Pages/kooriesupport.aspx")} text="Bendigo TAFE" />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["BoxHillTAFE"]} text="Box Hill TAFE" onClick={()=>openLinkInNewTab("https://www.boxhill.edu.au/study-with-us/support-services/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["ChisholmTAFE"]} text="Chisholm TAFE" onClick={()=>openLinkInNewTab("https://www.chisholm.edu.au/students/support-services")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["GippslandTAFE"]} text="Gippsland TAFE" onClick={()=>openLinkInNewTab("https://www.tafegippsland.edu.au/study_with_us/studentsupport")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["Federation"]} text="Federation Uni" onClick={()=>openLinkInNewTab("https://federation.edu.au/about-us/our-university/indigenous-matters")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["TheGordon"]} text="The Gordon" onClick={()=>openLinkInNewTab("https://www.thegordon.edu.au/future-students/student-services/aboriginal-education")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["HolmseglenTAFE"]} text="Holmesglen" onClick={()=>openLinkInNewTab("https://holmesglen.edu.au/Students/Student-Wellbeing/Indigenous-student-support/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["Kangan"]} text="Kangan" onClick={()=>openLinkInNewTab("https://www.kangan.edu.au/students/student-support/indigeneous-support")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["MelbourenPoly"]} text="Melbourne Polytechnic" onClick={()=>openLinkInNewTab("https://www.melbournepolytechnic.edu.au/students/services-and-support/koorie-services-centre/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["RMIT"]} text="RMIT" onClick={()=>openLinkInNewTab("https://www.rmit.edu.au/students/support-and-facilities/student-support/aboriginal-and-torres-strait-islander-students")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["TAFESouthWestVic"]} text="South West TAFE" onClick={()=>openLinkInNewTab("https://www.swtafe.edu.au/students/services-support/koorie-support")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["SuniTAFE"]} text="Suni TAFE" onClick={()=>openLinkInNewTab("https://www.sunitafe.edu.au/why-sunitafe/dulka-yuppata-indigenous-training-centre/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["VictoriaPoly"]} text="Victoria Polytechnic" onClick={()=>openLinkInNewTab("https://www.vu.edu.au/about-vu/university-profile/moondani-balluk-indigenous-academic-unit/our-aboriginal-torres-strait-islander-profile/aboriginal-torres-strait-islander-students")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["Swinburne"]} text="Swinburne" onClick={()=>openLinkInNewTab("https://www.swinburne.edu.au/life-at-swinburne/student-support-services/indigenous-student-services/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["WilAngliss"]} text="William Angliss" onClick={()=>openLinkInNewTab("https://www.angliss.edu.au/current-students/learning-and-student-support/")} />
            <ImageCard objectFitKind="contain" imageUrl={UniLogos["WodongaTAFE"]} text="Wodonga TAFE" onClick={()=>openLinkInNewTab("https://www.wodongatafe.edu.au/services/koorie-liaison-officer")} />
        </CardGrid></Col>
    </PageSectionRow>

const TAFE : React.FC<UserDetailsProps> = (props) => 
    <ContentWrapper title="TAFE First Nations Information" breadcrumbTitle="First Nations">
        <StateBasedPage
            nswContent={<TAFENSW />}
            vicContent={<TAFEVic />}
            {...props} />
    </ContentWrapper>

export default TAFE;