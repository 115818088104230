import React from "react";
import { RouteComponentProps } from "react-router";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";
import { uniConfig } from "./UniLinkData";

const UniProfile : React.FC<UserDetailsProps & RouteComponentProps> = ({userDetails, history}) => {
    const states = [userDetails.state, ...(userDetails.additionalStates || [])];
    return <ContentWrapper 
            title="University Profiles"
            subtitle={["Chose your favourite university below to find out about scholarships, alternative entry, adjustment factors, virtual tours and more."]}>
        <CardGrid prefix="uni-list" numPerRow={3}>
            {Object.entries(uniConfig).map(([uniName, conf])=>
                conf.states.find(s => states.includes(s)) && conf.id !== "" &&
                    <ImageCard objectFitKind="contain" imageUrl={conf.image} text={uniName} onClick={()=>history.push(`/uni/profiles/${conf.id}`)} />
            )}
        </CardGrid>
    </ContentWrapper>
}

export default UniProfile;