import React from "react";
import ContentWrapper from "../../components/ContentWrapper";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { EventTracking } from "../../data/EventTracking";

const WhatToSayToABoss : React.FC<{}> =
() => {
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            EventTracking.trackEvent("interviewPrepView", "meetingBoss");
        }, 1000*30); //Spend 30sec on page
        return () => clearTimeout(timeout);
    }, []);
    return <ContentWrapper 
    title="Meeting a Potential Employer" 
    subtitle={["Here are some ideas on how to present yourself."]}
    breadcrumbs={[
        {name:"Contacting an Employer", link:"/contactEmployer"}, 
    ]}>
    <PageSectionRow>
      <Col>
        <p>Remember that going for an interview is a formal business situation. It is better to dress up than be too casual. First impressions do count. First impressions are made in the first 10 seconds of meeting a new person. For men if you don’t have a suit, it is wise to wear a collared shirt with a tie. No joggers. Neat hair. Remove any jewellery or studs. For women, it is wise to wear a buttoned, collared blouse and either a skirt or slacks. Neat hair. Minimal jewellery and make-up.</p>
        <p>Practice going to the place of the interview a few days before hand. Check the time it takes. Plan to arrive 15 minutes early so you don’t have to rush. Aim to walk in to the office where the interview is being held 5 minutes before the time of the interview. Take a watch or check the time on your mobile phone. Before the interview, put your mobile phone on silent or switch it off.</p>
        <p>Make sure you have investigated about the company well before the interview. You may well be asked why you may want to work for this company and do you know who they are and what they do. Most companies have a great deal of information on their website. They often also have free information like booklets on their front counter to take away and read. After you have done this, you should also ring the person with whom you are having the interview and ask any more of your questions about the job or ask for any more details about what the company does.</p>
      </Col>
    </PageSectionRow>
    <PageSectionRow>
      <Col>
        <h3>What to take</h3>
        <p>A big winner of something to take to an interview is some proof of your passion for your career. See Proven Passion above. As you get older and more skilled you can produce more challenging projects for yourself. </p>
        <p>Take in your neat Personal Folder:</p>
        <ul>
          <li>Cover letter</li>
          <li>Resume</li>
          <li>References (Preferably 3)</li>
          <li>Most Recent Report</li>
          <li>Merit Awards for achievements in or out of school (sport, First Aid, OHS).</li>
          <li>Copy of Birth Certificate</li>
          <li>Spare copy of cover letter, resume, references to leave behind with them.</li>
          <li>Spare paper and pen (to write down any further information they give you).</li>
          <li>A list of questions you want to ask them (about your training, uniform, hours, leave, pay).</li>
        </ul>
      </Col>
    </PageSectionRow>
  </ContentWrapper>
}
export default WhatToSayToABoss;