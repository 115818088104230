import React from "react";
import { Row, Col } from "../../components/grid";
import { CareerPersonalRequirement } from "jobjump-types/Careers";
import BaseCard from "../../components/cards/BaseCard";
import CardGrid from "../../components/CardGrid";

interface RequirementsProps {
    requirementItem: CareerPersonalRequirement[]
}

const Requirements: React.FC<RequirementsProps> =
    ({ requirementItem }: RequirementsProps) =>
        <>
            <Row>
                <Col>
                    <h5>Do you have the Personal Requirements that are mentioned below? Many bosses will seek such qualities in you.</h5>
                    <h5><strong>Big Tip:</strong> You should ask people you know  such as a coach, old family friend or teacher 
                         for a reference and ask them if they can say you have any of the following qualities.</h5>
                </Col>
            </Row>
            <CardGrid numPerRow={3} prefix="req">
                {requirementItem.map(({description}, i) =>
                    <BaseCard key={`req-${i}`}>{description}</BaseCard>
                )}
            </CardGrid>
        </>

export default Requirements;