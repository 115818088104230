import React from "react";
import { CareerLink } from "jobjump-types/Careers";
import { openLinkInNewTab, getYoutubeParam } from "../../util";
import ModalVideo from "react-modal-video";
import CardGrid from "../../components/CardGrid";
import ImageCard from "../../components/cards/ImageCard";
import CareerImages from "../../assets/CareerImages";
import { EventTracking } from "../../data/EventTracking";

interface VideoSectionProps {
    videoItem : CareerLink[]
    careerName : string,
    careerId:string
}

const VideoSection : React.FC<VideoSectionProps> =
    ({videoItem, careerName, careerId} : VideoSectionProps) => { 
        const [videoID, setVideoID] = React.useState("");
        const [showVideo, setShowVideo] = React.useState(false);
        const vrVids = videoItem.filter(f => f.kind === 'vr_video');
        const careerVideo = videoItem.filter(f => f.kind === 'video')[0];
        const videoLinkClick = (url:string) => {
            EventTracking.trackEvent("clickVideoLink", careerId).then(() => {
                const param = getYoutubeParam(url);
                if (param) {
                    setVideoID(param);
                    setShowVideo(true);
                } else {
                    openLinkInNewTab(url);
                }
            });
        };
        return <>
            {careerVideo && <>
            <CardGrid prefix="career-video" numPerRow={3}>
                <ImageCard imageUrl={careerVideo.imageUrl || CareerImages[careerName.toUpperCase()]} text={careerVideo.title || "Click Here"} onClick={()=>videoLinkClick(careerVideo.url)} />
                {vrVids.map(m =>
                    <ImageCard
                        imageUrl={m.imageUrl}
                        text={`VR - ${m.title || "See What It's All About"}`}
                        onClick={() => videoLinkClick(m.url)}
                    />
                )}
            </CardGrid>
            </>}
            <ModalVideo channel='youtube' videoId={videoID} isOpen={showVideo} onClose={()=>setShowVideo(false)} />
        </>
    }

export default VideoSection;
    