import { IconPencil, IconSearch, IconTrash } from "@tabler/icons";
import { SchoolHomepage, SchoolUploadedFile } from "jobjump-types/SchoolHomePage";
import React from "react";
import { RouteComponentProps } from "react-router";
import Button from "../../components/Button";
import CardTable from "../../components/CardTable";
import { Label, Select, TextField } from "../../components/Form";
import { Col, Row } from "../../components/grid";
import Modal from "../../components/Modal";
import PageSectionRow from "../../components/PageSectionRow";
import CaSchoolHomepage from "../../data/CaSchoolHomepage";
import CaAdminLayout from "./CaAdminLayout";
import Dropzone from "react-dropzone";
import "./fileManager.css";
import { openLinkInNewTab } from "../../util";
import Alert from "../../components/Alert";
import {uploadFileWithSignedPOST} from "../../data/FileUploadUtil";

const prettyFileSize = (bytes:number) => {
    if (bytes < 1024) {
        return `${bytes} bytes`;
    }
    if (bytes < (1024*1024)) {
        return `${Math.round(bytes / 1024)} kb`;
    }
    return `${Math.round(bytes / 1024 /1024)} mb`
}

const FileManager : React.FC<RouteComponentProps> = (props) => {
    const [categories, setCategories] = React.useState<{id:number, name:string}[]>([]);
    const [files, setFiles] = React.useState<SchoolUploadedFile[]>([]);
    const [showUpload, setShowUpload] = React.useState(false);
    const [newFileName, setNewFileName] = React.useState("");
    const [newFileCategory, setNewFileCategory] = React.useState<number>();
    const [newCategoryName, setNewCategoryName] = React.useState("");
    const [newFile, setNewFile] = React.useState<File>();
    const [deleteCandidate, setDeleteCandidate] = React.useState<SchoolUploadedFile>();
    const [isBulletinUpload, setIsBulletinUpload] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [renameCandidate, setRenameCandidate] = React.useState<number>();
    const [deleteCategoryCandidate, setDeleteCategoryCandidate] = React.useState<{id:number, name:string}>();
    const [openNewCategoryModal, setOpenNewCategoryModal] = React.useState(false);
    const [schoolSite, setSchoolSite] = React.useState<SchoolHomepage>();
    React.useEffect(() => {
        CaSchoolHomepage.schoolSiteConfig().then(c=>setSchoolSite(c?.homepage));
    }, []);
    React.useEffect(() => {
        CaSchoolHomepage.getDocumentCategories().then(v => setCategories(v || []));
    }, []);
    React.useEffect(()=>{
        CaSchoolHomepage.getUploadedFiles().then(setFiles);
    }, []);
    const closeModal = () => {
        setErrorMessage("");
        setNewCategoryName("");
        setNewFile(undefined);
        setNewFileName("");
        setNewFileCategory(undefined);
        setShowUpload(false);
    };
    const openDeleteCategory = (v:{id:number, name:string}) => setDeleteCategoryCandidate(v);
    const closeDeleteCategory = () => {
        setErrorMessage("");
        setNewFileCategory(undefined);
        setDeleteCategoryCandidate(undefined);
    }
    const deleteCategory = () => {
        if (!newFileCategory) {
            setErrorMessage("Please select a category");
            return;
        }
        if (!deleteCategoryCandidate) {
            return;
        }
        CaSchoolHomepage.deleteDocumentCategory({id:deleteCategoryCandidate.id, destinationId:newFileCategory}).then(() => {
            CaSchoolHomepage.getDocumentCategories().then(setCategories).then(() => {
                closeDeleteCategory();
            });
            CaSchoolHomepage.getUploadedFiles().then(setFiles);
        })
    }
    const openRenameCategory = (id:number) => setRenameCandidate(id);
    const closeRenameModal = () => {
        setErrorMessage("");
        setOpenNewCategoryModal(false);
        setRenameCandidate(undefined);
    }
    const renameOrCreateCategory = () => {
        if (newCategoryName === "") {
            setErrorMessage("Please enter a category name.");
            return;
        }
        if (!renameCandidate) {
            CaSchoolHomepage.putDocumentCategories(newCategoryName).then(() => {
                CaSchoolHomepage.getDocumentCategories().then(c => {
                    setCategories(c); 
                    closeRenameModal();
                })
            })
        } else {
            CaSchoolHomepage.renameDocumentCategory(renameCandidate, newCategoryName).then(() => {
                CaSchoolHomepage.getDocumentCategories().then(c => {
                    setCategories(c); 
                    closeRenameModal();
                })
                CaSchoolHomepage.getUploadedFiles().then(setFiles);
            })
        }
    }
    
    const uploadFile = React.useCallback(async () => {
        if (!newFile) {
            setErrorMessage(`Please put a file in the "File to Upload" section.`)
            return;
        }
        if (!isBulletinUpload && !newFileCategory) {
            setErrorMessage(`Please select a category from the "Category" dropdown. You can use the "New Category" option to create a new category.`)
            return;
        }
        if (newFileName === "") {
            setErrorMessage("Please enter the name you wish to be displayed for the file.")
            return;
        }
        let categoryId = newFileCategory;
        if (newFileCategory === -1) {
            if (newCategoryName === "") {
                setErrorMessage("Please enter the name of the new category you wish to create.")
                return;
            }
            categoryId = await CaSchoolHomepage.putDocumentCategories(newCategoryName);
        }
        CaSchoolHomepage.getDocumentUploadLink({mimeType:newFile.type, fileName:newFile.name, fileSize:newFile.size}).catch(e => setErrorMessage(e)).then(details => {
            if (!details) { return; }
            const {key, uploadDetails} = details;
            uploadFileWithSignedPOST(uploadDetails, newFile).then(() => {
                const finishUpload = () => {
                    CaSchoolHomepage.getUploadedFiles().then(setFiles);
                    closeModal();
                };
                if (isBulletinUpload) {
                    CaSchoolHomepage.confirmBulletinUpload(newFile.name, newFileName, key).then(finishUpload);
                } else {
                    CaSchoolHomepage.confirmDocumentUpload(newFile.name, newFileName, key, categoryId || -1).then(finishUpload);
                }
            }).catch(e => console.log(JSON.stringify(e)));
        });
    }, [newFile, newFileCategory, newFileCategory, newFileName, setErrorMessage])
    const openDocument = React.useCallback((id:string)=> {
        CaSchoolHomepage.getDownloadLinkForFile(id).then(l => openLinkInNewTab(l));
    }, []);

    const openUploadModal = (isBulletin:boolean) => {
        setIsBulletinUpload(isBulletin);
        setShowUpload(true);
    }

    const deleteDocument = React.useCallback(() => {
        if (!deleteCandidate) {
            return;
        }
        CaSchoolHomepage.deleteUploadedFiles(deleteCandidate.id).then(()=>{
            CaSchoolHomepage.getUploadedFiles().then(setFiles)
            setDeleteCandidate(undefined);
        });
    }, [deleteCandidate, setDeleteCandidate]);

    const activateFiles = () => {
        if (!schoolSite) {
            return;
        }
        const newConfig = {...schoolSite, hasFiles:true};
        CaSchoolHomepage.updateSchoolSite(newConfig).then(()=>setSchoolSite(newConfig));
    }
    const activateBulletins = () => {
        if (!schoolSite) {
            return;
        }
        const newConfig = {...schoolSite, hasOwnBulletins:true};
        CaSchoolHomepage.updateSchoolSite(newConfig).then(()=>setSchoolSite(newConfig));
    }
    return <CaAdminLayout active="admin" title="School Documents & Bulletin" {...props}>
            <PageSectionRow>
                <Col className="text-center">
                    <h3>Your School Documents</h3>
                    <h5>Below are your uploaded school documents and your bulletins that appear under the <strong>"My School"</strong> menu option on your school's careers site. To upload a new file, use the <strong>"Upload New File"</strong> button.</h5>
                </Col>
            </PageSectionRow>
            {schoolSite && !schoolSite.hasFiles && files.find(f=>f.category !== "Bulletin") && <PageSectionRow>
                <Col className="text-center">
                    <Alert textOnly variant="negative" text={`Your School Site currently has the "My Files" section hidden. Users cannot see files you upload. Use the button below to active this section of your site.`} />
                    <Button variant="active" onClick={activateFiles}>Activate The "My Files" Section</Button>
                </Col>
            </PageSectionRow>}
            {schoolSite && !schoolSite.hasFiles && files.find(f=>f.category === "Bulletin") && <PageSectionRow>
                <Col className="text-center">
                    <Alert textOnly variant="negative" text={`Your School Site currently has custom Bulletins disabled. Users cannot see bulletins you upload here. Use the button below to active this section of your site.`} />
                    <Button variant="active" onClick={activateBulletins}>Activate Custom Bulletins</Button>
                </Col>
            </PageSectionRow>}
            <PageSectionRow>
                <Col>
                    <CardTable
                        data={files}
                        columns={[
                            {Header:"File Name", accessor:"filename", width:3},
                            {Header:"Category", accessor:"category", width:3},
                            {Header:"Upload Time", accessor:(r)=>new Date(r.upload_time).toDateString(), width:2},
                            {Header:"Size", accessor:(r)=>prettyFileSize(r.size), width:2},
                            {Header:"View", id:"view", width:1, Cell:({row})=><IconSearch className="pointer" onClick={()=>openDocument(row.original.id)} />},
                            {Header:"Delete", id:"view", width:1, Cell:({row})=><IconTrash  className="pointer" onClick={()=>setDeleteCandidate(row.original)} />}
                        ]} 
                        renderToolbar={()=><Row>
                            <Col className="text-right">
                                <Button className="mr-8" variant="active" onClick={()=>openUploadModal(true)}>
                                    Upload Bulletin
                                </Button>
                                <Button variant="active" onClick={()=>openUploadModal(false)}>
                                    Upload Document
                                </Button>
                            </Col>
                        </Row>}
                        />
                </Col>
            </PageSectionRow>
            {categories.length > 0 && <PageSectionRow>
                <Col className="text-center">
                    <h3>Your Document Categories</h3>
                    <h5>Below are the categories used to organise your school documents. You will see these as headings in the "My Files" page on your school's homepage. Here you can rename or delete them.</h5>
                </Col>
            </PageSectionRow>}
            {categories.length > 0 && <PageSectionRow>
                <Col>
                    <CardTable
                        data={categories}
                        columns={[
                            {Header:"Name", accessor:"name", width:10},
                            {Header:"Rename", id:"edit", width:1, Cell:({row})=><IconPencil className="pointer" onClick={()=>openRenameCategory(row.original.id)} />},
                            {Header:"Delete", id:"view", width:1, Cell:({row})=><IconTrash className="pointer" onClick={()=>openDeleteCategory(row.original)} />},
                        ]} 
                        renderToolbar={()=><Row>
                            <Col className="text-right">
                                <Button className="mr-8" variant="active" onClick={()=>setOpenNewCategoryModal(true)}>
                                    Add New Category
                                </Button>
                            </Col>
                        </Row>} />
                </Col>
            </PageSectionRow>}
            <Modal title="Upload New File" show={showUpload} onHide={closeModal}>
                {errorMessage !== "" && <Row>
                    <Col>
                        <Alert textOnly variant="negative" text={errorMessage} />
                    </Col>
                </Row>}
               {!isBulletinUpload && <Row>
                    <Col>
                        <Label>Category</Label>
                        <Select options={[{label:"Create A New Category...", value:""}]
                                .concat(categories.map(c=>({label:c.name, value:String(c.id)})))}
                            onChange={(value)=>value && 
                                (value.value === "" ? setNewFileCategory(-1) : setNewFileCategory(Number(value.value)))} />
                        <small>Categories are used to organise your school documents. They appear as subheadings in the "My Files" section of your school's customised site. Select from a previously created category or use the "Create A New Category..." option to make a new one.</small>
                    </Col>
                </Row>}
                {newFileCategory === -1 && !isBulletinUpload && <Row>
                    <Col>
                        <Label>New Category Name</Label>
                        <TextField value={newCategoryName} onChange={setNewCategoryName} placeholder="New Category Name" />
                        <small>Categories are used to organise your school documents. Examples of categories may include "Work Experience Documents" or "Career Worksheets". Enter the name of the new category you want to create.</small>
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <Label>File Title</Label>
                        <TextField value={newFileName} onChange={setNewFileName} placeholder="File Title" />
                        <small>Enter the name of the file as you would like it to appear on your school's site.</small>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label>File To Upload</Label>
                        <Dropzone onDrop={(fs) =>setNewFile(fs[0])}>
                            {({getRootProps, getInputProps}) => (
                                <>{!newFile && <div {...getRootProps()} className="upload-zone">
                                    <input {...getInputProps()} />
                                    <p>Drag your file here, or click here to select a file.</p>
                                </div>}
                                {newFile && <div className="upload-zone">
                                    <p>File: {newFile.name} ({prettyFileSize(newFile.size)})</p>
                                    <Button variant="neutral" onClick={()=>setNewFile(undefined)}>Clear</Button>
                                </div>}
                                </>
                            )}
                        </Dropzone>
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button variant="active" onClick={uploadFile}>
                            Upload
                        </Button>
                        <Button variant="negative" className="ml-8" onClick={closeModal}>
                            Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal title="Delete Confirmation" show={deleteCandidate !== undefined} onHide={()=>setDeleteCandidate(undefined)}>
                <Row>
                    <Col>
                        {deleteCandidate && <h3>Are you sure you want to delete {deleteCandidate.filename}</h3>}
                    </Col>
                </Row>
                {deleteCandidate && <Row>
                    <Col className="text-right">
                        <Button className="mr-8" onClick={()=>setDeleteCandidate(undefined)} variant="negative">
                            Cancel
                        </Button>
                        <Button onClick={deleteDocument} variant="active">
                            Confirm
                        </Button>
                    </Col>
                </Row>}
            </Modal>
            <Modal title="Delete Category" show={deleteCategoryCandidate !== undefined} onHide={closeDeleteCategory}>
                {errorMessage !== "" && <Row>
                    <Col>
                        <Alert textOnly variant="negative" text={errorMessage} />
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        {deleteCategoryCandidate && <h3>Deleting Category "{deleteCategoryCandidate.name}"</h3>}
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col>
                        <h4>In order to delete this category, all files from this category will be placed into another category. Please select what category you would like to move the files to.</h4>
                        <Select options={categories.filter(c=>c.id !== deleteCategoryCandidate?.id).map(c=>({label:c.name, value:String(c.id)}))}
                            onChange={(value)=>value && 
                                (value.value === "" ? setNewFileCategory(-1) : setNewFileCategory(Number(value.value)))} />
 
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button className="mr-8" onClick={()=>closeDeleteCategory()} variant="negative">
                            Cancel
                        </Button>
                        <Button onClick={deleteCategory} variant="active">
                            Confirm
                        </Button>
        
                    </Col>
                </Row>
            </Modal>
            <Modal title={renameCandidate ? "Rename Category" : "New Category"} 
                show={renameCandidate !== undefined || openNewCategoryModal} 
                onHide={()=>{setRenameCandidate(undefined);setOpenNewCategoryModal(false)}}>
                {errorMessage !== "" && <Row>
                    <Col>
                        <Alert textOnly variant="negative" text={errorMessage} />
                    </Col>
                </Row>}
                <Row>
                    <Col>
                        <h4>Please enter a new name for this category:</h4>
                        <TextField value={newCategoryName} onChange={setNewCategoryName} />
                    </Col>
                </Row>
                <Row className="mt-16">
                    <Col className="text-right">
                        <Button className="mr-8" onClick={()=>{setRenameCandidate(undefined); setOpenNewCategoryModal(false)}} variant="negative">
                            Cancel
                        </Button>
                        <Button onClick={renameOrCreateCategory} variant="active">
                            Confirm
                        </Button>
        
                    </Col>
                </Row>
            </Modal>



        </CaAdminLayout>

}

export default FileManager;