import React from "react";
import FilterableGrid from "../../components/FilterableGrid";
import "./filterGridSelect.css";

interface FilterGridSelectProps {
    data : {name:string, id:number|string}[],
    imageMap? : {[k:string]:string},
    selectionList : number[],
    onSelect:(id:number)=>void
    numPerRow:number,
    noun?: string
}

const FilterGridSelect : React.FC<FilterGridSelectProps> =
    ({data, imageMap, selectionList, onSelect, noun, numPerRow}) =>
        (<FilterableGrid
            hideSearch
            data={data.map(c =>
                ({
                    extraClass: selectionList.includes(Number(c.id)) ? "filter-grid-select-selected" : "filter-grid-select-unselected",
                    text:c.name, 
                    imgUrl:imageMap ? imageMap[c.name.toUpperCase()] : undefined,
                        onClick:()=> onSelect(Number(c.id))
                })
            )}
            noun={noun}
            numPerRow={numPerRow} />)

export default FilterGridSelect;