import React from "react";
import { Col } from "../../components/grid";
import WhoLogo from "../../assets/who-logo.png";
import PageSectionRow from "../../components/PageSectionRow";
import ContentWrapper from "../../components/ContentWrapper";
import { UserDetailsProps } from "../../layout/Routes";
import StateBasedPage from "../../components/StateBasedPage";

const WorkExperienceNSW : React.FC<{}> = () =>
    <>
        <PageSectionRow>
            <Col>
                <h2>Before you use these forms, please note:</h2>
                <ul>
                    <li>These forms should only be used in consultation with your Careers Adviser.</li>
                    <li>These forms are for use by NSW Department of Education schools only.</li>
                </ul>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Work Experience Documents</h2>
                <ul>
                    <li><a href="https://education.nsw.gov.au/content/dam/main-education/teaching-and-learning/curriculum/career-learning-and-vocational-education/workplace-learning/guides-and-form/workplace-learning-guide-for-employers.pdf">The Workplace Learning Guide for Employers</a></li>
                    <li><a href="https://education.nsw.gov.au/about-us/rights-and-accountability/certificates-of-currency">Certificate of Currency</a></li>
                    <li><a href="https://education.nsw.gov.au/content/dam/main-education/teaching-and-learning/curriculum/career-learning-and-vocational-education/workplace-learning/guides-and-form/workplace-learning-guide-for-parents-carers.pdf">The Workplace Learning Guide for Parents and Carers</a></li>
                    <li><a href="https://education.nsw.gov.au/content/dam/main-education/teaching-and-learning/curriculum/career-learning-and-vocational-education/workplace-learning/guides-and-form/SPR-21.pdf">Student Placement Record Interactive</a></li>
                    <li><a href="https://education.nsw.gov.au/teaching-and-learning/curriculum/career-learning-and-vet/workplace-learning/keeping-students-safe#Prohibited0">Prohibited Activities</a></li>
                    <li><a href="https://education.nsw.gov.au/content/dam/main-education/teaching-and-learning/curriculum/career-learning-and-vocational-education/workplace-learning/guides-and-form/WPL-away-from-home-form2021.pdf">Staying Away from Home</a></li>
                    <li><a href="https://schoolsequella.det.nsw.edu.au/file/98d74119-62a3-45dc-82e4-32c93ef0a636/1/safety-and-emergency-procedure-card.pdf">Safety and Emergency Procedures - Student Contact Card</a></li>
                    <li><a href="https://education.nsw.gov.au/teaching-and-learning/curriculum/career-learning-and-vet/workplace-learning">For Careers Advisers</a></li>
                </ul>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Seen a business you would like to work for?</h2> 
                <h4>Why not try your new career in your own time by going on work experience to see if you like it.</h4>
                <p>
                    Work Health Options (WHO - previously I.C. Frith) offers Work Experience Protection Insurance for work 
                    with a host employer. The policy provides compensation in the event that the person is injured in the 
                    workplace or in the course of necessary travel between the person's normal residence and the place of 
                    their voluntary activities. Phone 02 8378 2160 or visit <a href="https://workhealthoptions.com.au">workhealthoptions.com.au</a> for more information.
                </p>
                <a href="https://workhealthoptions.com.au"><img alt="Work Health Options" src={WhoLogo} /></a>
                <h4>Work Experience Protection Insurance for unpaid Work Experience Persons</h4>
            </Col>
        </PageSectionRow>
    </>

const WorkExperienceVIC : React.FC<{}> = () =>
    <>
        <PageSectionRow>
            <Col>
                <h2>Before you use these forms, please note:</h2>
                <ul>
                    <li>These forms should only be used in consultation with your Careers Adviser.</li>
                    <li>These forms are for use by VIC Department of Education schools only.</li>
                </ul>
            </Col>
        </PageSectionRow>
        <PageSectionRow>
            <Col>
                <h2>Work Experience Documents</h2>
                <ul>
                <li><a href="http://www.workplacements.education.vic.gov.au/">Workplacement	</a></li>
                <li><a href="https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/WEarrangefrmamended.pdf">The Work Experience Arrangement Form</a></li>
                <li><a href="https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/WEtravaccfrmamended.pdf">Work Experience Travel/AccommodationArrangements Form</a></li>
                <li><a href="https://www.education.vic.gov.au/school/students/beyond/Pages/workplacehazardfact.aspx">Workplace Hazards Facts Sheet</a></li>
                <li><a href="https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/SWLarrangefrmamended.pdf">The Structured Workplace Learning Arrangement Form</a></li>
                <li><a href="https://www.education.vic.gov.au/Documents/school/teachers/teachingresources/careers/work/SWLtravaccfrmamended.pdf">The Structured Workplace Travel/Accommodation Arrangements Form</a></li>
                <li><a href="https://www.education.vic.gov.au/Documents/school/students/beyond/wltworkexp.pdf">The Parent/Carers Guide to Work Experience</a></li>
                <li><a href="https://www2.education.vic.gov.au/pal/work-experience/resources">Work Experience Guidelines for Employers</a></li>
                <li><a href="https://www.education.vic.gov.au/PAL/structured-workplace-learning-child-safe-standards-and-workplace-learning-fact-sheet-for-employers.pdf">Child Safe Standards and Workplace Learning - A Fact Sheet for Employers</a></li>
                <li><a href="https://www2.education.vic.gov.au/pal/work-experience/resources">Working With Animals - Summary of Student Experience</a></li>
                </ul>
            </Col>
        </PageSectionRow>
    </>

const WorkExperience : React.FC<UserDetailsProps> =
    (props) => 
        <ContentWrapper 
                title="Work Experience Resources" 
                subtitle={[
                    "When you go on work placement or work experience, here are the necessary forms.",
                ]}>
        <StateBasedPage
            nswContent={<WorkExperienceNSW />}
            vicContent={<WorkExperienceVIC />}
            {...props} />
        </ContentWrapper>

export default WorkExperience;