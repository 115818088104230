import React from "react";
import {emptyRegistration} from "./RegistrationTypes";
import {RegistrationDetails, UserDetails, isRegistrationDetails} from "jobjump-types/User";
import User from "../../data/User";
import School from "./School";
import Details from "./Details";
import { RouteComponentProps, Switch, Route } from "react-router";
import Alert from "../../components/Alert";
interface RegisterPageProps extends RouteComponentProps {
    setUserDetails : (d:UserDetails)=>void
}

interface RegistrationSwitchProps extends RouteComponentProps {
    registrationDetails:RegistrationDetails,
    setRegistrationDetails:(d:RegistrationDetails)=>void,
    onRegistrationComplete:()=>void,
}

const RegistrationPageSwitch : React.FC<RegistrationSwitchProps> =
    ({registrationDetails, setRegistrationDetails, onRegistrationComplete, ...props }) =>
            <Switch>
                <Route path="/register" exact>
                    <School 
                        details={registrationDetails}
                        setRegistrationDetails={(d:RegistrationDetails) => {setRegistrationDetails(d); props.history.push("/register/details", d);}}
                        onStepCompletion={()=>{}}
                        {...props}
                    />
                </Route>
                <Route path="/register/details" exact>
                    <Details 
                        details={registrationDetails}
                        setRegistrationDetails={setRegistrationDetails}
                        onStepCompletion={onRegistrationComplete}
                        {...props}
                    />
                </Route>
            </Switch>

const RegistrationPage : React.FC<RegisterPageProps> =
    (props) => {
        const {setUserDetails, ...locationProps} = props; 
        const {history, location} = locationProps;
        const [registrationDetails, setRegistrationDetails] = 
            React.useState(isRegistrationDetails(location.state) ? location.state : emptyRegistration);
        const [errorMessage, setErrorMessage] = React.useState("");
        const onRegistrationComplete = React.useCallback(() => {
            User.register(registrationDetails).then(() => {
                User.login(registrationDetails.email, registrationDetails.password).then((d) => {
                    setUserDetails(d);
                    history.push("/careerSelect");
                }).catch(() => {
                    setErrorMessage("Authentication Error");
                });
            }).catch(e => setErrorMessage(e))
        }, [registrationDetails, history, setUserDetails]); 
        const pathSegs = location.pathname.split("/");
        const currentStep = pathSegs[pathSegs.length - 1];
        if (currentStep !== "register" && registrationDetails.schoolID === "") { history.push("/register"); }
        return <>
            {errorMessage && <Alert textOnly variant="negative" text={`There was an issue with your registration. Error: ${errorMessage}`} />}
            <RegistrationPageSwitch
                registrationDetails={registrationDetails}
                setRegistrationDetails={setRegistrationDetails}
                onRegistrationComplete={onRegistrationComplete}
                {...locationProps}
            />
        </>;
    }

export default RegistrationPage;